import VisibilityIcon from "@mui/icons-material/Visibility";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SideNav from "../../../../../../component/layouts/sideNav";
import {
  BALANCE_QTY,
  BASIC_COST,
  CANCEL,
  CLEAR,
  GOODS_RECEIVED,
  GOODS_RECEIVED_DETAIL,
  GRAND_TOTAL,
  GRN_NO,
  ITEM_CATEGORY,
  ITEM_DESCRIPTION,
  ITEM_NAME,
  LOADING_CHARGES,
  MATERIAL_MANAGEMENT,
  PURCHASE_QTY,
  RATE,
  SAVE,
  SCHEDULED_QTY,
  STOCK_QTY,
  TAX,
  TAX_COST,
  TOTAL,
  TOTAL_COST,
  TRANSPORTATION_COST,
} from "../../../../../../resources/constant/strings/string";

import {
  getItemCategories,
  getItemList,
  getItems,
  getPurchaseOrders,
  getSiteCategories,
  getSites,
  getSupplierCategories,
  getSuppliers,
  getUoms,
  globalFilter,
  itemCategoryCellRenderer,
  itemCellRenderer,
} from "../../../../../../component/cellRenderers/renderers";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TableBody,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { APIMATERIALMGMT } from "../../../../../../apihandler/materialmgmt";
import { APIUTILS } from "../../../../../../apihandler/utils";
import {
  setAlertConfirmDeletion,
  setAlertTitleText,
} from "../../../../../../component/modal/alertModal";
import apply_icon from "../../../../../../resources/assets/images/apply_icon.svg";
import minus_icon from "../../../../../../resources/assets/images/minus_icon.svg";
import plus_icon from "../../../../../../resources/assets/images/plus_icon.svg";
import {
  ANTI_FLASH_WHITE,
  BLACK,
  CHARLESTON_GREEN,
  LIGHT_GREY_BACKGROUND,
  PRUSSIAN_BLUE,
  SPANISH_GREEN,
  WHITE,
} from "../../../../../../resources/constant/colors/theme";
import {
  PATH_GOODS_RECEIVED,
  PATH_GOODS_RECEIVED_DETAIL,
} from "../../../../../../resources/constant/url/url";
import EditModal from "./editModal";
import Modal from "./modal";
const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  color: SPANISH_GREEN,
  transform: "translate(-50%, -50%)",
  zIndex: 2,
};
export default function GoodsReceivedDetail() {
  let { id } = useParams();
  const [lastRowId, setLastRowId] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const isEdit = location.state?.isEdit;
  const addModel = id <= 0;
  const editModel = id > 0;

  const [disableFetch, setDisableFetch] = useState(false);
  const [linkPOChecked, setLinkPOChecked] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [poItems, setPOItems] = useState([]);
  const [formData, setFormData] = useState(null);
  const [editFormData, setEditFormData] = useState(null);
  const onApplyValue = (v) => {
    const newArr = [...rowItems];
    newArr[currentIndex].taxCost = Number.parseFloat(
      APIUTILS.Financial(Number(v.taxValue))
    );
    newArr[currentIndex].totalCost = Number.parseFloat(
      APIUTILS.Financial(Number(v.totalValue))
    );
    newArr[currentIndex].tax = v;
    let grandTotal = 0.0;
    dto.basicCost = 0.0;
    dto.taxCost = 0.0;
    dto.totalValue = 0.0;

    newArr.forEach((e) => {
      dto.basicCost += Number.parseFloat(APIUTILS.Financial(e.basicCost));
      dto.taxCost += Number.parseFloat(APIUTILS.Financial(e.taxCost));
      if(e.taxCost === 0)
        e.totalCost = e.basicCost;
      dto.totalCost += Number.parseFloat(APIUTILS.Financial(e.totalCost));
      grandTotal += Number.parseFloat(APIUTILS.Financial(e.totalCost));
    });

    grandTotal += Number.parseFloat(APIUTILS.Financial(dto.loadingCharge));
    grandTotal += Number.parseFloat(APIUTILS.Financial(dto.transportationCost));

    setDTO({
      ...dto,
      grandTotal: Number.parseFloat(APIUTILS.Financial(grandTotal)),
    });
    setRowItems(newArr);
  };

  const supplierCategories = getSupplierCategories();
  const suppliers = getSuppliers();
  const sites = getSites();
  const purchaseOrders = getPurchaseOrders();

  const poProps = {
    options: purchaseOrders,
    getOptionLabel: (option) => option?.name || "",
  };

  const supplierCategoryProps = {
    options: supplierCategories,
    getOptionLabel: (option) => option?.name || "",
  };

  const supplierProps = {
    options: suppliers,
    getOptionLabel: (option) => option?.name || "",
  };

  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value && name !== "description" && name !== "billNo") {
      if (isNaN(value)) setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    } else {
      setDTO({ ...dto, [name]: value });
    }
  };

  const itemCategories = getItemCategories();

  const itemCategoryProps = {
    options: itemCategories,
    getOptionLabel: (option) => option?.name || "",
  };

  const itemList = getItemList();
  const uomList = getUoms();

  const items = getItems();

  const itemProps = {
    options: items,
    getOptionLabel: (option) => option?.name || "",
  };

  const uomProps = {
    options: uomList,
    getOptionLabel: (option) => option?.name || "",
  };

  const siteCategories = getSiteCategories();
  const siteCategoryProps = {
    options: siteCategories,
    getOptionLabel: (option) => option?.name || "",
  };

  const onClear = () => {
    setAlertConfirmDeletion(
      "Confirmation needed",
      "Do you confirm on proceeding to clear?"
    ).then((consent) => {
      if (!consent.isConfirmed) return;
      window.location.reload();
    });
  };

  const fetchPO = (v) => {
    APIMATERIALMGMT.PurchaseOrderGRN(v)
      .then((resp) => resp.json())
      .then(async (resp) => {
        if (resp.statusCode === 200) {
          let grandTotal = 0.0;
          const newArr = [];
          dto.basicCost = 0.0;
          dto.taxCost = 0.0;
          dto.totalCost = 0.0;

          const reqdPOItems = [];
          let i = 0;
          let taskArray = [];
          for (const e of resp.response) {
            if (e.balanceQty <= 0) {
              continue;
            }
            i++;

            e.rowId = i;
            reqdPOItems.push(e.itemId);
            const it = items.filter((v) => v.value === e.itemId);
            if (it.length > 0) {
              e.itemCategoryId = it[0].dependentvalue;
            }
            const itemsInPO = itemList.filter((v) => v.id === e.itemId);
            if (itemsInPO.length > 0) {
              const uom = uomList.filter(
                (v) => v.value === itemsInPO[0].purchaseUOMId
              );
              if (uom.length > 0) {
                e.uomName = uom[0].name;
              }
            }

            e.balanceQty = e.purchaseQty = Number.parseFloat(
              APIUTILS.Financial(e.balanceQty)
            );
            e.basicCost = Number.parseFloat(
              APIUTILS.Financial(e.balanceQty * e.rate)
            );

            let taskN = APIUTILS.CalculateTax({
              taxIds: e.tax.taxIds,
              basicAmount: e?.basicCost,
            }).then(r => r.json())
            .then(response => {
              
            if (response.statusCode === 200) {
              e.taxCost = Number.parseFloat(
                APIUTILS.Financial(Number(response.response.taxValue))
              );
              e.totalCost = Number.parseFloat(
                APIUTILS.Financial(Number(response.response.totalValue))
              );
            }

            newArr.push(e);
            dto.basicCost += Number.parseFloat(APIUTILS.Financial(e.basicCost));
            dto.taxCost += Number.parseFloat(APIUTILS.Financial(e.taxCost));
            if(e.taxCost === 0)
              e.totalCost = e.basicCost;
            dto.totalCost += Number.parseFloat(APIUTILS.Financial(e.totalCost));
          });

            taskArray.push(taskN);
          }

          Promise.all(taskArray)
          .then(() => {          
            dto.basicCost = Number.parseFloat(APIUTILS.Financial(dto.basicCost));
            dto.taxCost = Number.parseFloat(APIUTILS.Financial(dto.taxCost));
            dto.totalCost = Number.parseFloat(APIUTILS.Financial(dto.totalCost));
            grandTotal = Number.parseFloat(
              APIUTILS.Financial(dto.totalCost)
            );
            grandTotal += Number.parseFloat(
              APIUTILS.Financial(dto.transportationCost)
            );
            grandTotal += Number.parseFloat(
              APIUTILS.Financial(dto.loadingCharge)
            );
            dto.purchaseOrderId = v;
            setDTO({
              ...dto,
              grandTotal: Number.parseFloat(APIUTILS.Financial(grandTotal)),
            });

            if (newArr.length <= 0) {
              setAlertTitleText(
                "Oops...",
                "All items have already been GRN'd... you can close this PO now"
              );
              return;
            }

            APIMATERIALMGMT.StockConversion({
              items: newArr,
            })
              .then((m) => m.json())
              .then((m) => {
                if (m.statusCode === 200) {
                  newArr.forEach((x) => {
                    const reqd = m.response.items.filter(
                      (y) => y.itemId === x.itemId
                    );
                    x.stockQty = reqd.length > 0 ? reqd[0].stockQty : 0;
                  });

                  newArr.sort((a, b) => a.id - b.id);

                  setRowItems((rowItems) => [].concat(newArr));
                }
              });

            reqdPOItems.sort((a, b) => a.id - b.id);
            setPOItems((poItems) => [].concat(reqdPOItems));
          });
        } else {
          setAlertTitleText("Oops...", resp.message);
        }
      });
  };

  const [dto, setDTO] = useState({
    id: 0,
    grnNo: "Auto Generated",
    date: dayjs(),
    billDate: dayjs(),
    supplierId: null,
    supplierCategoryId: null,
    siteCategoryId: 0,
    siteId: 0,
    grandTotal: 0,
    billNo: "",
    status: "",
    modifiedBy: 0,
    purchaseOrderId: 0,
    description: "",
    transportationCost: 0.0,
    loadingCharge: 0.0,
    basicCost: 0.0,
    taxCost: 0.0,
    totalCost: 0.0,
  });
  const [rowItems, setRowItems] = useState([]);
  const [rowId, setRowId] = useState(2);
  function addObjectToArray() {
    const newArr = [
      ...rowItems,
      {
        rowId: -1*Number((Math.random() * 10000000).toFixed(0)),
        id: 0,
        itemCategoryId: 0,
        itemId: 0,
        uomName: "",
        stockUomName: "",
        itemDescription: "",
        purchaseQty: 0.0,
        stockQty: 0,
        balanceQty: 0,
        scheduledQty: 0,
        isPOItem: false,
        rate: 0,
        discount: 0,
        basicCost: 0,
        taxCost: 0,
        tax: { taxIds: [] },
        totalCost: 0,
        status: "N",
      },
    ];
    setRowItems(newArr);
  }

  const removeObjectFromArray = (rowId) => {
      setRowItems(c => c.filter((x) => {
        return x.rowId !== rowId;
      }));
      setTimeout(()=>{
      let grandTotal = 0.0;
      dto.basicCost = 0.0;
      dto.taxCost = 0.0;
      dto.totalCost = 0.0;

      rowItems.forEach((e) => {
        dto.basicCost += Number.parseFloat(APIUTILS.Financial(e.basicCost));
        dto.taxCost += Number.parseFloat(APIUTILS.Financial(e.taxCost));
        if(e.taxCost === 0)
          e.totalCost = e.basicCost;
        dto.totalCost += Number.parseFloat(APIUTILS.Financial(e.totalCost));
        grandTotal += Number.parseFloat(APIUTILS.Financial(e.totalCost));
      });
      dto.basicCost = Number(APIUTILS.Financial(dto.basicCost));
      dto.taxCost = Number(APIUTILS.Financial(dto.taxCost));
      dto.totalCost = Number(APIUTILS.Financial(dto.totalCost));
      grandTotal += Number.parseFloat(APIUTILS.Financial(dto.loadingCharge));
      grandTotal += Number.parseFloat(APIUTILS.Financial(dto.transportationCost));
      setDTO({
        ...dto,
        grandTotal: Number.parseFloat(APIUTILS.Financial(grandTotal)),
      });
    },2000);
  }

  const clearArray = () => {
    setRowItems((x) => false);
    console.log("Rows count on clear", rowItems.length);
  };

  const changeRates = (v) => {
    const itemIds = [];
    rowItems.forEach((x) => {
      if (x.itemId > 0) {
        itemIds.push(x.itemId);
      }
    });

    if (itemIds.length <= 0) {
      return;
    }

    APIMATERIALMGMT.Rates({
      supplierId: v,
      date: dto.date,
      itemId: itemIds,
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) {
          setAlertTitleText("Oops...", resp.message);
          return;
        }

        let grandTotal = 0.0;
        dto.basicCost = 0.0;
        dto.taxCost = 0.0;
        dto.totalCost = 0.0;

        const poItems = rowItems.map((x) => {
          if (x.itemId > 0) {
            const reqdRate = resp.response.filter((y) => y.itemId === x.itemId);
            if (reqdRate.length > 0) {
              x.rate = reqdRate[0].rate;
              x.basicCost = Number.parseFloat(
                APIUTILS.Financial(x.balanceQty * x.rate)
              );
              x.taxCost = 0;
              x.totalCost = x.basicCost;
            } else {
              x.rate = 0;
              x.basicCost = 0;
              x.taxCost = 0;
              x.totalCost = 0;
            }
            return { ...x };
          }
        });

        poItems.forEach((e) => {
          dto.basicCost += Number.parseFloat(APIUTILS.Financial(e.basicCost));
          dto.taxCost += Number.parseFloat(APIUTILS.Financial(e.taxCost));
          if(e.taxCost === 0)
            e.totalCost = e.basicCost;
          dto.totalCost += Number.parseFloat(APIUTILS.Financial(e.totalCost));
          grandTotal += Number.parseFloat(APIUTILS.Financial(e.totalCost));
        });
        dto.basicCost = Number(APIUTILS.Financial(dto.basicCost));
        dto.taxCost = Number(APIUTILS.Financial(dto.taxCost));
        dto.totalCost = Number(APIUTILS.Financial(dto.totalCost));
        grandTotal += Number.parseFloat(APIUTILS.Financial(dto.loadingCharge));
        setDTO({
          ...dto,
          grandTotal: Number.parseFloat(APIUTILS.Financial(grandTotal)),
          goodsReceivedDetail: poItems,
        });

        setRowItems(poItems);
      });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    if (linkPOChecked) {
      if (
        poProps.options.filter(
          (x) =>
            x.dependentvalue === dto.siteId &&
            x.otherdependentvalue === dto.supplierId &&
            x.value === dto.purchaseOrderId
        ).length <= 0
      ) {
        setAlertTitleText(
          "Oops...",
          "Supplier, site and purchase order does not match"
        );
        return;
      }
    }

    dto.goodsReceivedDetail = rowItems.filter((m) => m.purchaseQty > 0);

    if (dto.goodsReceivedDetail.length <= 0) {
      setAlertTitleText("No items have been selected for purchase");
      return;
    }

    if (dto.billNo.length <= 0) {
      setAlertTitleText("Bill number is mandatory");
      return;
    }

    dto.goodsReceivedDetail.forEach((e) => {
      e.scheduledQty = 0.0;
      e.isPOItem = linkPOChecked > 0;
    });

    //api call to save goes here
    if (dto.id <= 0) {
      APIMATERIALMGMT.SaveGoodsReceived(dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setAlertTitleText(
              "Success",
              "The record was inserted successfully"
            );
            navigate(PATH_GOODS_RECEIVED + "/" + location.state.date);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    } else {
      APIMATERIALMGMT.UpdateGoodsReceived(id, dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setAlertTitleText("Success", "The record was updated successfully");
            navigate(PATH_GOODS_RECEIVED + "/" + location.state.date);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
          }
        });
    }
  }
  function isBefore(date1, date2) {
    return date1 < date2;
  }

  const gridRef = useRef();
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [supplierCategory, setSupplierCategory] = useState({});
  const [siteCategory, setSiteCategory] = useState({});
  const [supplier, setSupplier] = useState({});
  const [po, setPO] = useState({});
  const [site, setSite] = useState({});
  const [gridData, setGridData] = useState([]);

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId(event.api.getSelectedRows()[0].id);
    else setSelectedRowId(0);
  };

  const columnDefs = [
    {
      flex: 1.4,
      headerName: "Actions",
      field: "id",
      sortable: false,
      filter: false,
      hide: !isEdit,
      floatingFilter: false,
      cellRenderer: (params) =>
        lastRowId === params.data?.rowId ? (
          <div style={{ display: "flex", justifyContent: "left" }}>
            <IconButton
              aria-label="Add"
              onClick={() => {
                setCurrentIndex(params.data.rowId);
                setEditFormTitle("Add");
                setEditFormData({
                  id: 0,
                  itemCategoryId: 0,
                  itemId: 0,
                  uomId: 0,
                  uomName: "",
                  stockUomName: "",
                  itemDescription: "",
                  purchaseQty: 0.0,
                  stockQty: 0,
                  balanceQty: 0,
                  scheduledQty: 0,
                  isPOItem: linkPOChecked,
                  rate: 0,
                  discount: 0,
                  basicCost: 0,
                  taxCost: 0,
                  tax: { taxIds: [] },
                  totalCost: 0,
                  status: "N",
                });
                setEditOpenModal(true);
              }}
            >
              <AddCircleOutlineIcon
                style={{ color: PRUSSIAN_BLUE, width: "20px" }}
              />
            </IconButton>
            <IconButton
              aria-label="edit"
              onClick={() => {
                setCurrentIndex(params.data.rowId);
                setEditFormTitle("Edit");
                setEditFormData(params.data);
                setEditOpenModal(true);
              }}
            >
              <EditIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => {
                setAlertConfirmDeletion(
                  "Confirmation needed",
                  "Do you want to delete? Please confirm"
                ).then((consent) => {
                  if (!consent.isConfirmed) return;

                  const selectedData = gridRef.current.api.getSelectedRows();
                  gridRef.current.api.applyTransaction({
                    remove: selectedData,
                  });

                  setRowItems(rowItems.filter((x) => x.id !== params.data?.id));

                  setLastRowId(
                    gridRef.current.api.getDisplayedRowAtIndex(
                      gridRef.current.api.getLastDisplayedRow()
                    ).data.rowId
                  );

                  let grandTotal = 0.0;
                  dto.basicCost = 0.0;
                  dto.taxCost = 0.0;
                  dto.totalCost = 0.0;

                  rowItems
                    .filter((x) => x.id !== params.data?.id)
                    .forEach((e) => {
                      dto.basicCost += Number.parseFloat(
                        APIUTILS.Financial(e.basicCost)
                      );
                      dto.taxCost += Number.parseFloat(
                        APIUTILS.Financial(e.taxCost)
                      );
                      dto.totalCost += Number.parseFloat(
                        APIUTILS.Financial(e.totalCost)
                      );
                      grandTotal += Number.parseFloat(
                        APIUTILS.Financial(e.totalCost)
                      );
                    });
                  dto.basicCost = Number(APIUTILS.Financial(dto.basicCost));
                  dto.taxCost = Number(APIUTILS.Financial(dto.taxCost));
                  dto.totalCost = Number(APIUTILS.Financial(dto.totalCost));
                  grandTotal += Number.parseFloat(
                    APIUTILS.Financial(dto.loadingCharge)
                  );
                  grandTotal += Number.parseFloat(
                    APIUTILS.Financial(dto.transportationCost)
                  );
                  setDTO({
                    ...dto,
                    grandTotal: Number.parseFloat(
                      APIUTILS.Financial(grandTotal)
                    ),
                  });
                });
              }}
            >
              <DeleteForeverIcon
                style={{ color: PRUSSIAN_BLUE, width: "20px" }}
              />
            </IconButton>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "left" }}>
            <IconButton
              aria-label="edit"
              onClick={() => {
                setCurrentIndex(params.data.rowId);
                setEditFormTitle("Edit");
                setEditFormData(params.data);
                setEditOpenModal(true);
              }}
            >
              <EditIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
            </IconButton>
            <IconButton
              aria-label="edit"
              onClick={() => {
                setAlertConfirmDeletion(
                  "Confirmation needed",
                  "Do you want to delete? Please confirm"
                ).then((consent) => {
                  if (!consent.isConfirmed) return;

                  const selectedData = gridRef.current.api.getSelectedRows();
                  gridRef.current.api.applyTransaction({
                    remove: selectedData,
                  });

                  setRowItems(rowItems.filter((x) => x.id !== params.data?.id));

                  setLastRowId(
                    gridRef.current.api.getDisplayedRowAtIndex(
                      gridRef.current.api.getLastDisplayedRow()
                    ).data.rowId
                  );

                  let grandTotal = 0.0;
                  dto.basicCost = 0.0;
                  dto.taxCost = 0.0;
                  dto.totalCost = 0.0;

                  rowItems
                    .filter((x) => x.id !== params.data?.id)
                    .forEach((e) => {
                      dto.basicCost += Number.parseFloat(
                        APIUTILS.Financial(e.basicCost)
                      );
                      dto.taxCost += Number.parseFloat(
                        APIUTILS.Financial(e.taxCost)
                      );
                      dto.totalCost += Number.parseFloat(
                        APIUTILS.Financial(e.totalCost)
                      );
                      grandTotal += Number.parseFloat(
                        APIUTILS.Financial(e.totalCost)
                      );
                    });
                  dto.basicCost = Number(APIUTILS.Financial(dto.basicCost));
                  dto.taxCost = Number(APIUTILS.Financial(dto.taxCost));
                  dto.totalCost = Number(APIUTILS.Financial(dto.totalCost));
                  grandTotal += Number.parseFloat(
                    APIUTILS.Financial(dto.loadingCharge)
                  );
                  grandTotal += Number.parseFloat(
                    APIUTILS.Financial(dto.transportationCost)
                  );
                  setDTO({
                    ...dto,
                    grandTotal: Number.parseFloat(
                      APIUTILS.Financial(grandTotal)
                    ),
                  });
                });
              }}
            >
              <DeleteForeverIcon
                style={{ color: PRUSSIAN_BLUE, width: "20px" }}
              />
            </IconButton>
          </div>
        ),
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 1.5,
      headerName: "Item Category",
      field: "itemCategoryId",
      valueGetter: (params) => {
        return itemCategoryCellRenderer({ value: params.data.itemCategoryId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      flex: 1.5,
      headerName: "Item Name",
      field: "itemId",
      valueGetter: (params) => {
        return itemCellRenderer({ value: params.data.itemId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      flex: 1.6,
      headerName: "Item Description",
      field: "itemDescription",
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Purchase Quantity",
      field: "purchaseQty",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },

      filter: false,
      floatingFilter: false,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "UOM",
      field: "uomName",
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },

      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Stock Quantity",
      field: "stockQty",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },

    {
      headerName: "Rate",
      field: "rate",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Basic Cost",
      field: "basicCost",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Tax Cost",
      field: "taxCost",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Total Cost",
      field: "totalCost",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      flex: 0.8,
      headerName: "Tax",
      field: "id",
      sortable: false,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            aria-label="edit"
            onClick={() => {
              setCurrentIndex(params.data.rowId);
              setFormData(params.data);
              setOpenModal(true);
            }}
          >
            <VisibilityIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
          </IconButton>
        </div>
      ),
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };

  const onGridReady = (params) => {
    if (id > 0) {
      APIMATERIALMGMT.GoodsReceived(id)
        .then((resp) => resp.json())
        .then((resp) => {
          resp.response.goodsReceivedDetail =
            resp.response.goodsReceivedDetail.sort((a, b) => a.id - b.id);

          setLinkPOChecked(resp.response.purchaseOrderId > 0);

          const grnItems = resp.response.goodsReceivedDetail.map((e, i) => {
            if (e.tax === null) {
              e.tax = { taxIds: [] };
            }

            e.rowId = i;

            e.basicCost = Number(
              APIUTILS.Financial(e.rate * e.purchaseQty)
            );

            const it = items.filter((v) => v.value === e.itemId);
            if (it.length > 0) {
              e.itemCategoryId = it[0].dependentvalue;
            }
            const itemsInIndent = itemList.filter((v) => v.id === e.itemId);
            if (itemsInIndent.length > 0) {
              if (e.itemDescription === null || e.itemDescription?.length <= 0)
                e.itemDescription = itemsInIndent[0].description;
              let uom = uomList.filter(
                (v) => v.value === itemsInIndent[0].purchaseUOMId
              );
              if (uom.length > 0) e.uomName = uom[0].name;
            }

            return e;
          });

          let taskArray = [];

          for (const e of grnItems) {
            if (e.tax && e.tax.taxIds?.length <= 0) {
              continue;
            }

            let taskN = APIUTILS.CalculateTax({
              taxIds: e.tax?.taxIds,
              basicAmount: e?.basicCost,
            }).then(r => r.json())
            .then(taxResponse => {
              if (taxResponse.statusCode === 200) {
                e.taxCost = Number.parseFloat(
                  APIUTILS.Financial(Number(taxResponse.response.taxValue))
                );
                e.totalCost = Number.parseFloat(
                  APIUTILS.Financial(Number(taxResponse.response.totalValue))
                );
              }
    
              dto.basicCost += Number.parseFloat(APIUTILS.Financial(e.basicCost));
              dto.taxCost += Number.parseFloat(APIUTILS.Financial(e.taxCost));
              if(e.taxCost === 0)
                e.totalCost = e.basicCost;
              dto.totalCost += Number.parseFloat(APIUTILS.Financial(e.totalCost));
            });

            taskArray.push(taskN);
          }

          Promise.all(taskArray)
          .then(()=> {

          dto.basicCost = 0.0;
          grnItems.forEach((e) => {
            e.basicCost = Number(APIUTILS.Financial(e.basicCost));
            e.taxCost = Number(APIUTILS.Financial(e.taxCost));
            e.totalCost = Number(APIUTILS.Financial(e.totalCost));
            dto.basicCost += Number(e.basicCost);
          });

          const requiredSupplier = supplierProps.options.filter(
            (x) => x.value === resp.response.supplierId
          );

          setSupplier(
            requiredSupplier.length > 0
              ? requiredSupplier[0]
              : { value: 0, name: "" }
          );

          const requiredSupplierCategory = supplierCategoryProps.options.filter(
            (x) =>
              x.value ===
              (requiredSupplier.length > 0
                ? requiredSupplier[0].dependentvalue
                : 0)
          );
          setSupplierCategory(
            requiredSupplierCategory.length > 0
              ? requiredSupplierCategory[0]
              : { value: 0, name: "" }
          );

          const requiredSite = siteProps.options.filter(
            (x) => x.value === resp.response.siteId
          );
          setSite(
            requiredSite.length > 0 ? requiredSite[0] : { value: 0, name: "" }
          );

          const requiredSiteCategory = siteCategoryProps.options.filter(
            (x) =>
              x.value ===
              (requiredSite.length > 0 ? requiredSite[0].dependentvalue : 0)
          );
          setSiteCategory(
            requiredSiteCategory.length > 0
              ? requiredSiteCategory[0]
              : { value: 0, name: "" }
          );

          if (resp.response.purchaseOrderId > 0) {
            const requiredPO = poProps.options.filter(
              (x) => x.value === resp.response.purchaseOrderId
            );
            setPO(
              requiredPO.length > 0 ? requiredPO[0] : { value: 0, name: "" }
            );
          }

          let totalCost = 0.0;
          grnItems.forEach((e) => {
            totalCost += Number.parseFloat(APIUTILS.Financial(e.totalCost));
          });

          dto.totalCost = totalCost = Number(APIUTILS.Financial(totalCost));
          let grandTotal = totalCost;
          grandTotal += resp.response.transportationCost;
          grandTotal += resp.response.loadingCharge;

          grandTotal = Number(APIUTILS.Financial(grandTotal));

          gridRef.current.api.applyTransaction({
            add: grnItems,
          });

          setLastRowId(
            gridRef.current.api.getDisplayedRowAtIndex(
              gridRef.current.api.getLastDisplayedRow()
            ).data.rowId
          );

          if (resp.response.purchaseOrderId > 0) {
            APIMATERIALMGMT.PurchaseOrder(resp.response.purchaseOrderId)
              .then((poResponse) => poResponse.json())
              .then((poResponse) => {
                if (poResponse.statusCode === 200) {
                  const arr = [];
                  poResponse.response.poItems.forEach((e) => {
                    arr.push(e.itemId);
                  });

                  arr.sort((a, b) => b.id - a.id);

                  setPOItems((poItems) => [].concat(arr));
                }
              });
          }

          setDTO({
            ...dto,
            id: resp.response.id,
            grnNo: resp.response.grnNo,
            billNo: resp.response.billNo,
            date: resp.response.date.split("-").reverse().join("-"),
            billDate: resp.response.billDate.split("-").reverse().join("-"),
            supplierCategoryId:
              requiredSupplierCategory.length > 0
                ? requiredSupplierCategory[0].value
                : 0,
            siteCategoryId:
              requiredSiteCategory.length > 0
                ? requiredSiteCategory[0].value
                : 0,
            purchaseOrderId: resp.response.purchaseOrderId,
            supplierId: resp.response.supplierId,
            siteId: resp.response.siteId,
            grandTotal: grandTotal,
            status: resp.response.status,
            description: resp.response.description,
            transportationCost: resp.response.transportationCost,
            loadingCharge: resp.response.loadingCharge,
            basicCost: Number.parseFloat(APIUTILS.Financial(dto.basicCost)),
            taxCost: Number.parseFloat(APIUTILS.Financial(dto.taxCost)),
            totalCost: Number.parseFloat(APIUTILS.Financial(dto.totalCost)),
            goodsReceivedDetail: grnItems,
          });

          setRowItems((rowItems) =>
            [].concat(grnItems)
          );
        });
        });
    } else {
      gridRef.current.api.applyTransaction({ add: gridData });

      setLastRowId(
        gridRef.current.api.getDisplayedRowAtIndex(
          gridRef.current.api.getLastDisplayedRow()
        ).data.rowId
      );
    }
  };

  const [dataReady, setDataReady] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [viewOnlyModel, setViewOnlyModel] = useState(null);
  const [openEditModal, setEditOpenModal] = useState(false);
  const [editFormTitle, setEditFormTitle] = useState("Add");

  const onAdd = (data) => {
    APIMATERIALMGMT.Rates({
      supplierId: dto.supplierId,
      date: dto.date,
      itemId: [data.itemId],
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode === 200) {
          if(data.isPOItem){
            if (resp.response.length > 0) {
              data.rate = Number(resp.response[0].rate);
            } else {
              data.rate = data.rate || 0;
            }
          }
        }
        data.id = Number((Math.random() * 10000000).toFixed(0));
        data.totalCost = data.basicCost = Number(
          APIUTILS.Financial(data.rate * data.purchaseQty)
        );
        let grandTotal = 0.0;
        dto.basicCost = 0.0;
        dto.taxCost = 0.0;
        dto.totalCost = 0.0;

        const goodsReceivedDetail = rowItems.concat([data]);
        goodsReceivedDetail.forEach((x, i) => {
          x.rowId = i;
        });
        setRowItems([...rowItems, data]);

        goodsReceivedDetail.forEach((e) => {
          dto.basicCost += Number.parseFloat(APIUTILS.Financial(e.basicCost));
          dto.taxCost += Number.parseFloat(APIUTILS.Financial(e.taxCost));
          if(e.taxCost === 0)
            e.totalCost = e.basicCost;
          dto.totalCost += Number.parseFloat(APIUTILS.Financial(e.totalCost));
          grandTotal += Number.parseFloat(APIUTILS.Financial(e.totalCost));
        });
        dto.basicCost = Number(APIUTILS.Financial(dto.basicCost));
        dto.taxCost = Number(APIUTILS.Financial(dto.taxCost));
        dto.totalCost = Number(APIUTILS.Financial(dto.totalCost));
        grandTotal += Number.parseFloat(APIUTILS.Financial(dto.loadingCharge));
        setDTO({
          ...dto,
          grandTotal: Number.parseFloat(APIUTILS.Financial(grandTotal)),
          goodsReceivedDetail: goodsReceivedDetail,
        });
        try {
          const rowData = [];
          gridRef.current.api.forEachNode(function (node) {
            rowData.push(node.data);
          });
          gridRef.current.api.applyTransaction({
            remove: rowData,
          });
        } catch (e) {}
        try {
          gridRef.current.api.applyTransaction({ add: goodsReceivedDetail });
        } catch (e) {}

        setLastRowId(
          gridRef.current.api.getDisplayedRowAtIndex(
            gridRef.current.api.getLastDisplayedRow()
          ).data.rowId
        );
      });
  };

  const onUpdate = (data) => {
    data.basicCost = Number(APIUTILS.Financial(data.rate * data.purchaseQty));

    if (data.tax.taxIds.length <= 0) {
      data.totalCost = data.basicCost;
      let grandTotal = 0.0;
      dto.basicCost = 0.0;
      dto.taxCost = 0.0;
      dto.totalCost = 0.0;

      const goodsReceivedDetail = rowItems.map((obj) => {
        if (obj.rowId === data.rowId) {
          return { ...obj, ...data };
        }
        return obj;
      });

      goodsReceivedDetail.forEach((x, i) => {
        x.rowId = i;
      });

      goodsReceivedDetail.forEach((e) => {
        dto.basicCost += Number.parseFloat(APIUTILS.Financial(e.basicCost));
        dto.taxCost += Number.parseFloat(APIUTILS.Financial(e.taxCost));
        if(e.taxCost === 0)
          e.totalCost = e.basicCost;
        dto.totalCost += Number.parseFloat(APIUTILS.Financial(e.totalCost));
        grandTotal += Number.parseFloat(APIUTILS.Financial(e.totalCost));
      });
      dto.basicCost = Number(APIUTILS.Financial(dto.basicCost));
      dto.taxCost = Number(APIUTILS.Financial(dto.taxCost));
      dto.totalCost = Number(APIUTILS.Financial(dto.totalCost));

      setRowItems((r) => [].concat(goodsReceivedDetail));

      grandTotal += Number.parseFloat(APIUTILS.Financial(dto.loadingCharge));
      grandTotal += Number.parseFloat(
        APIUTILS.Financial(dto.transportationCost)
      );

      setDTO({
        ...dto,
        basicCost: dto.basicCost,
        totalCost: dto.totalCost,
        taxCost: dto.taxCost,
        grandTotal: Number.parseFloat(APIUTILS.Financial(grandTotal)),
        goodsReceivedDetail: goodsReceivedDetail,
      });
      try {
        const rowData = [];
        gridRef.current.api.forEachNode(function (node) {
          rowData.push(node.data);
        });
        gridRef.current.api.applyTransaction({
          remove: rowData,
        });
      } catch (e) {}
      try {
        gridRef.current.api.applyTransaction({ add: goodsReceivedDetail });
      } catch (e) {}

      setLastRowId(
        gridRef.current.api.getDisplayedRowAtIndex(
          gridRef.current.api.getLastDisplayedRow()
        ).data.rowId
      );
    } else {
      APIUTILS.CalculateTax({
        taxIds: data.tax.taxIds,
        basicAmount: data?.basicCost,
      })
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.statusCode !== 200) {
            setAlertTitleText("Oops...", "Cannot calculate tax");
            return;
          }

          data.taxCost = Number.parseFloat(
            APIUTILS.Financial(Number(resp.response.taxValue))
          );
          data.totalCost = Number.parseFloat(
            APIUTILS.Financial(Number(resp.response.totalValue))
          );

          let grandTotal = 0.0;
          dto.basicCost = 0.0;
          dto.taxCost = 0.0;
          dto.totalCost = 0.0;

          data.basicCost = Number(
            APIUTILS.Financial(data.rate * data.purchaseQty)
          );

          const goodsReceivedDetail = rowItems.map((obj) => {
            if (obj.rowId === data.rowId) {
              return { ...obj, ...data };
            }
            return obj;
          });

          goodsReceivedDetail.forEach((x, i) => {
            x.rowId = i;
          });

          goodsReceivedDetail.forEach((e) => {
            dto.basicCost += Number.parseFloat(APIUTILS.Financial(e.basicCost));
            dto.taxCost += Number.parseFloat(APIUTILS.Financial(e.taxCost));
            if(e.taxCost === 0)
              e.totalCost = e.basicCost;
            dto.totalCost += Number.parseFloat(APIUTILS.Financial(e.totalCost));
            grandTotal += Number.parseFloat(APIUTILS.Financial(e.totalCost));
          });

          setRowItems(goodsReceivedDetail);

          dto.basicCost = Number(APIUTILS.Financial(dto.basicCost));
          dto.taxCost = Number(APIUTILS.Financial(dto.taxCost));
          dto.totalCost = Number(APIUTILS.Financial(dto.totalCost));
          grandTotal += Number.parseFloat(
            APIUTILS.Financial(dto.loadingCharge)
          );
          grandTotal += Number.parseFloat(
            APIUTILS.Financial(dto.transportationCost)
          );

          setDTO({
            ...dto,
            basicCost: dto.basicCost,
            totalCost: dto.totalCost,
            taxCost: dto.taxCost,
            goodsReceivedDetail: goodsReceivedDetail,
            grandTotal: Number.parseFloat(APIUTILS.Financial(grandTotal)),
          });

          try {
            const rowData = [];
            gridRef.current.api.forEachNode(function (node) {
              rowData.push(node.data);
            });
            gridRef.current.api.applyTransaction({
              remove: rowData,
            });
          } catch (e) {}
          try {
            gridRef.current.api.applyTransaction({ add: goodsReceivedDetail });
          } catch (e) {}
          setLastRowId(
            gridRef.current.api.getDisplayedRowAtIndex(
              gridRef.current.api.getLastDisplayedRow()
            ).data.rowId
          );
        });
    }
  };

  return (
    <SideNav
      currentComponent={MATERIAL_MANAGEMENT}
      subComponent={GOODS_RECEIVED}
      subComponentPath={PATH_GOODS_RECEIVED}
      detailComponent={GOODS_RECEIVED_DETAIL}
    >
      {openModal && (
        <Modal
          style={{ align: "center" }}
          setOpenModal={setOpenModal}
          onApply={onApplyValue}
          rowIndex={currentIndex}
          //addModel={addModel}
          viewModel={viewOnlyModel}
          model={formData}
          formTitle={"Tax"}
        ></Modal>
      )}
      {openEditModal && (
        <EditModal
          formTitle={editFormTitle}
          setOpenModal={setEditOpenModal}
          model={editFormData}
          onAdd={onAdd}
          onUpdate={onUpdate}
          itemsInOriginal={poItems}
        ></EditModal>
      )}

      <div>
        <form>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            {(id <= 0 || isEdit) && (
              <Button
                style={{
                  backgroundColor: SPANISH_GREEN,
                  textTransform: "capitalize",
                  fontSize: 14,
                  color: WHITE,
                  minWidth: "180px",
                  minHeight: "45px",
                }}
                onClick={onFormSubmit}
              >
                {SAVE}
              </Button>
            )}
            {id <= 0 && (
              <Button
                variant="outlined"
                sx={{
                  borderColor: SPANISH_GREEN,
                }}
                style={{
                  backgroundColor: LIGHT_GREY_BACKGROUND,
                  textTransform: "capitalize",
                  fontSize: 14,
                  color: SPANISH_GREEN,
                  minWidth: "180px",
                  minHeight: "45px",
                  marginRight: 10,
                }}
                onClick={onClear}
              >
                {CLEAR}
              </Button>
            )}
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                //backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={() => {
                navigate(PATH_GOODS_RECEIVED + "/" + location.state.date);
              }}
            >
              {CANCEL}
            </Button>
          </div>
          <div
            style={{
              backgroundColor: LIGHT_GREY_BACKGROUND,
              borderRadius: "5px",
              minWidth: "90%",
              minHeight: "50px",
              padding: 20,
              marginBottom: 10,
              borderStyle: "solid",
              border: "1x",
              borderColor: "#eaeaea",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography
                color={CHARLESTON_GREEN}
                style={{
                  fontWeight: 600,
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "left",
                }}
              >
                {GRN_NO}
                {id > 0 ? ` ${dto.grnNo}` : " Auto Generated"}
              </Typography>
              <Typography
                color={CHARLESTON_GREEN}
                style={{
                  fontWeight: 600,
                  fontFamily: "Inter",
                  fontSize: "16px",
                  //fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "left",
                }}
              ></Typography>
            </div>
            <table style={{ width: "100%" }}>
              <TableBody>
                <tr>
                  <td>
                    <FormControlLabel
                      name="linkPO"
                      disabled={id > 0 || linkPOChecked}
                      control={<Checkbox color="success" />}
                      label="Link PO/In"
                      checked={linkPOChecked}
                      onChange={() => {
                        setLinkPOChecked(!linkPOChecked);
                      }}
                      style={{ userSelect: "none" }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {id > 0 ? (
                      <Autocomplete
                        filterOptions={globalFilter}
                        id={`autocomplete-2`}
                        {...supplierCategoryProps}
                        disabled={id > 0}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="supplierCategoryId"
                        value={supplierCategory}
                        isOptionEqualToValue={(o, v) => o.value === v.value}
                        defaultValue={
                          supplierCategoryProps.options.find(
                            (v) => v.value === dto.supplierCategoryId
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, supplierCategoryId: v?.value });
                            else
                              setDTO({
                                ...dto,
                                supplierCategoryId: Number(v?.value),
                              });
                          } else {
                            setDTO({ ...dto, supplierCategoryId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Supplier Category"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        filterOptions={globalFilter}
                        id={`autocomplete-2`}
                        {...supplierCategoryProps}
                        disabled={id > 0}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="supplierCategoryId"
                        defaultValue={
                          supplierCategoryProps.options.find(
                            (v) => v.value === dto.supplierCategoryId
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, supplierCategoryId: v?.value });
                            else
                              setDTO({
                                ...dto,
                                supplierCategoryId: Number(v?.value),
                              });
                          } else {
                            setDTO({ ...dto, supplierCategoryId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Supplier Category"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                  <td>
                    {id > 0 ? (
                      <Autocomplete
                        id={`autocomplete-4`}
                        {...supplierProps}
                        disabled={isEdit && linkPOChecked}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        value={supplier}
                        name="supplierId"
                        defaultValue={
                          supplierProps.options.find(
                            (v) => v.value === dto.supplierId
                          ) || {}
                        }
                        filterOptions={(o, s) => {
                          let options = globalFilter(o, s);
                          let newOptions = [];
                          options.forEach((element) => {
                            if (
                              element.dependentvalue === dto.supplierCategoryId
                            )
                              newOptions.push(element);
                          });
                          return newOptions;
                        }}
                        onChange={(e, v) => {
                          if (v && dto.supplierId !== 0 && !linkPOChecked) {
                            if (
                              rowItems.filter((x) => x.purchaseQty > 0)
                                .length <= 0
                            ) {
                              const reqdSupplier = suppliers.filter(
                                (x) => x.value === v?.value
                              );
                              if (reqdSupplier.length > 0)
                                setSupplier(reqdSupplier[0]);

                              changeRates(v?.value);
                            } else {
                              setAlertConfirmDeletion(
                                "Confirmation needed",
                                "Rates will change. Do you want to confirm"
                              ).then((consent) => {
                                if (!consent.isConfirmed) return;

                                if (v) {
                                  if (
                                    v.dependentvalue !== dto.supplierCategoryId
                                  )
                                    return;

                                  if (isNaN(v?.value))
                                    setDTO({ ...dto, supplierId: v?.value });
                                  else
                                    setDTO({
                                      ...dto,
                                      supplierId: Number(v?.value),
                                    });
                                } else {
                                  setDTO({ ...dto, supplierId: v?.value });
                                }

                                const reqdSupplier = suppliers.filter(
                                  (x) => x.value === v?.value
                                );
                                if (reqdSupplier.length > 0)
                                  setSupplier(reqdSupplier[0]);

                                changeRates(v?.value);
                              });
                            }
                          } else {
                            if (v) {
                              if (v.dependentvalue !== dto.supplierCategoryId)
                                return;

                              if (isNaN(v?.value))
                                setDTO({ ...dto, supplierId: v?.value });
                              else
                                setDTO({
                                  ...dto,
                                  supplierId: Number(v?.value),
                                });
                            } else {
                              setDTO({ ...dto, supplierId: v?.value });
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Supplier Name"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        id={`autocomplete-4`}
                        {...supplierProps}
                        disabled={id > 0}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="supplierId"
                        defaultValue={
                          supplierProps.options.find(
                            (v) => v.value === dto.supplierId
                          ) || {}
                        }
                        filterOptions={(o, s) => {
                          let options = globalFilter(o, s);
                          let newOptions = [];
                          options.forEach((element) => {
                            if (
                              element.dependentvalue === dto.supplierCategoryId
                            )
                              newOptions.push(element);
                          });
                          return newOptions;
                        }}
                        onChange={(e, v) => {
                          if (v && dto.supplierId !== 0 && !linkPOChecked) {
                            if (
                              rowItems.filter((x) => x.purchaseQty > 0)
                                .length <= 0
                            ) {
                              if (v) {
                                if (v.dependentvalue !== dto.supplierCategoryId)
                                  return;

                                if (isNaN(v?.value))
                                  setDTO({ ...dto, supplierId: v?.value });
                                else
                                  setDTO({
                                    ...dto,
                                    supplierId: Number(v?.value),
                                  });
                              } else {
                                setDTO({ ...dto, supplierId: v?.value });
                              }

                              const reqdSupplier = suppliers.filter(
                                (x) => x.value === v?.value
                              );
                              if (reqdSupplier.length > 0)
                                setSupplier(reqdSupplier[0]);

                              changeRates(v?.value);
                            } else {
                              setAlertConfirmDeletion(
                                "Confirmation needed",
                                "Rates will change. Do you want to confirm"
                              ).then((consent) => {
                                if (!consent.isConfirmed) return;

                                if (v) {
                                  if (
                                    v.dependentvalue !== dto.supplierCategoryId
                                  )
                                    return;

                                  if (isNaN(v?.value))
                                    setDTO({ ...dto, supplierId: v?.value });
                                  else
                                    setDTO({
                                      ...dto,
                                      supplierId: Number(v?.value),
                                    });
                                } else {
                                  setDTO({ ...dto, supplierId: v?.value });
                                }

                                changeRates(v?.value);
                              });
                            }
                          } else {
                            if (v) {
                              if (v.dependentvalue !== dto.supplierCategoryId)
                                return;

                              if (isNaN(v?.value))
                                setDTO({ ...dto, supplierId: v?.value });
                              else
                                setDTO({
                                  ...dto,
                                  supplierId: Number(v?.value),
                                });
                            } else {
                              setDTO({ ...dto, supplierId: v?.value });
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Supplier Name"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                  <td>
                    {id > 0 ? (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...siteCategoryProps}
                        disabled={id > 0}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="siteCategoryId"
                        defaultValue={
                          siteCategoryProps.options.find(
                            (v) => v.value === dto.siteCategoryId
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, siteCategoryId: v?.value });
                            else
                              setDTO({
                                ...dto,
                                siteCategoryId: Number(v?.value),
                              });
                          } else {
                            setDTO({ ...dto, siteCategoryId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Site Category"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...siteCategoryProps}
                        disabled={id > 0}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="siteCategoryId"
                        defaultValue={
                          siteCategoryProps.options.find(
                            (v) => v.value === dto.siteCategoryId
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, siteCategoryId: v?.value });
                            else
                              setDTO({
                                ...dto,
                                siteCategoryId: Number(v?.value),
                              });
                          } else {
                            setDTO({ ...dto, siteCategoryId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Site Category"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {id > 0 ? (
                      <Autocomplete
                        {...siteProps}
                        disabled={id > 0}
                        autoComplete
                        autoHighlight
                        autoSelect
                        value={site}
                        isOptionEqualToValue={(o, v) => o.value === v.value}
                        includeInputInList
                        name="siteId"
                        filterOptions={(o, s) => {
                          let options = globalFilter(o, s);
                          let newOptions = [];
                          options.forEach((element) => {
                            if (element.dependentvalue === dto.siteCategoryId)
                              newOptions.push(element);
                          });
                          return newOptions;
                        }}
                        defaultValue={
                          siteProps.options.find(
                            (v) => v.value === dto.siteId
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, siteId: v?.value });
                            else setDTO({ ...dto, siteId: Number(v?.value) });
                          } else {
                            setDTO({ ...dto, siteId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Site"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        {...siteProps}
                        disabled={id > 0}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="siteId"
                        filterOptions={(o, s) => {
                          let options = globalFilter(o, s);
                          let newOptions = [];
                          options.forEach((element) => {
                            if (element.dependentvalue === dto.siteCategoryId)
                              newOptions.push(element);
                          });
                          return newOptions;
                        }}
                        defaultValue={
                          siteProps.options.find(
                            (v) => v.value === dto.siteId
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, siteId: v?.value });
                            else setDTO({ ...dto, siteId: Number(v?.value) });
                          } else {
                            setDTO({ ...dto, siteId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Site"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="GRN Date"
                        disabled={id > 0 && !isEdit}
                        inputFormat="DD-MM-YYYY"
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dto.date}
                        maxDate={dayjs()}
                        onChange={(newValue) => {
                          //Cannot be before po date
                          setDTO({ ...dto, date: newValue });
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                  <td>
                    <TextField
                      inputProps={{
                        maxlength: 16,
                        style: { fontSize: 14 },
                      }}
                      variant="standard"
                      label="Bill No"
                      required
                      disabled={id > 0 && !isEdit}
                      style={{
                        minWidth: "200px",
                        minHeight: "48.5px",
                        width: "200px",
                      }}
                      value={dto.billNo}
                      name="billNo"
                      onChange={handleInputChange}
                      InputLabelProps={{
                        style: { color: "grey", fontSize: 14 },
                      }}
                    ></TextField>
                  </td>
                </tr>
                <tr>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Bill Date"
                        disabled={id > 0 && !isEdit}
                        inputFormat="DD-MM-YYYY"
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dto.billDate}
                        maxDate={dayjs()}
                        onChange={(newValue) => {
                          setDTO({ ...dto, billDate: newValue });
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                  <td>
                    <TextField
                      variant="standard"
                      disabled={true}
                      label="Grand Total"
                      style={{
                        minWidth: "200px",
                        minHeight: "48.5px",
                        width: "200px",
                      }}
                      name="grandTotal"
                      value={dto.grandTotal}
                      InputLabelProps={{
                        style: { color: "grey", fontSize: 14 },
                      }}
                      InputProps={{ style: { fontSize: 14 } }}
                    ></TextField>
                  </td>
                  <td>
                    <TextareaAutosize
                      variant="standard"
                      disabled={id > 0 && !isEdit}
                      name="description"
                      maxLength={128}
                      style={{
                        backgroundColor: LIGHT_GREY_BACKGROUND,
                        outlineColor: BLACK,
                        borderTop: "10px",
                        borderRight: "10px",
                        borderLeft: "10px",
                        marginTop: 15,
                        fontSize: 12,
                        fontFamily: "sans-serif",
                        minWidth: "200px",
                        minHeight: "33.5px",
                        width: "200px",
                        height: "33.5.5px",
                      }}
                      multiline="true"
                      value={dto.description}
                      onChange={handleInputChange}
                      maxRows={4}
                      minRows={2}
                      placeholder="Description"
                    ></TextareaAutosize>
                  </td>
                </tr>
                <tr>
                  <td>
                    {linkPOChecked ? (
                      <Autocomplete
                        {...poProps}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        disabled={isEdit}
                        name="purchaseOrderId"
                        defaultValue={
                          poProps.options.find(
                            (v) => v.value === dto.purchaseOrderId
                          ) || {}
                        }
                        filterOptions={(o, s) => {
                          let options = globalFilter(o, s);
                          let newOptions = [];
                          options.forEach((element) => {
                            if (
                              element.dependentvalue === dto.siteId &&
                              element.otherdependentvalue === dto.supplierId &&
                              (element.status === "P" || element.status === "N")
                            )
                              newOptions.push(element);
                          });
                          return newOptions;
                        }}
                        onChange={(e, v) => {
                          if (v) {
                            if (
                              v.dependentvalue !== dto.siteId ||
                              dto.supplierId <= 0
                            )
                              return;

                            const isPOChange = dto.purchaseOrderId > 0;
                            const prevPOId = dto.purchaseOrderId;

                            if (isNaN(v?.value))
                              setDTO({ ...dto, purchaseOrderId: v?.value });
                            else
                              setDTO({
                                ...dto,
                                purchaseOrderId: Number(v?.value),
                              });

                            if (isPOChange) {
                              setAlertConfirmDeletion(
                                "Confirmation needed",
                                "Items will change. Do you want to confirm?"
                              ).then((consent) => {
                                if (!consent.isConfirmed) {
                                  setDTO({ ...dto, indentId: prevPOId });
                                  return;
                                }

                                fetchPO(v?.value);
                              });
                            } else {
                              fetchPO(v?.value);
                            }
                          } else {
                            setDTO({ ...dto, purchaseOrderId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Purchase Order"
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              </TableBody>
            </table>
          </div>
        </form>
      </div>

      {/* below code is for purchase order dynamic row  addition deletion*/}
      {id > 0 && (
        <div className="App">
          <div className="ag-theme-alpine" style={{ height: "570px" }}>
            <AgGridReact
              copyGroupHeadersToClipboard={true}
              enableRangeSelection={true}
              columnDefs={columnDefs}
              ref={gridRef}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              paginationAutoPageSize={true} pagination={true}
              rowSelection="multiple"
              getRowId={(params) => params.data.id}
              onSelectionChanged={onRowSelectionChanged}
            ></AgGridReact>
          </div>
        </div>
      )}
      <div
        style={{
          backgroundColor: WHITE,
          borderRadius: "5px",
          minWidth: "90%",
          minHeight: "50px",
          marginBottom: 10,
          border: "0.1px solid #eaeaea",
          display: "flex",
          flexDirection: "column",
          // width: "100%",
          overflowX: "auto",
        }}
      >
        <div
          style={{
            width: "1580px",
          }}
        >
          {id <= 0 && (
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
              }}
            >
              <TableBody>
                <tr
                  style={{
                    backgroundColor: LIGHT_GREY_BACKGROUND,
                    borderBottom: "0.001pt solid #f0eff4",
                    borderTop: "0.001pt solid #f0eff4",
                  }}
                >
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {ITEM_CATEGORY}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {ITEM_NAME}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {ITEM_DESCRIPTION}
                    </Typography>
                  </th>
                  {linkPOChecked && (
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "Inter",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {SCHEDULED_QTY}
                      </Typography>
                    </th>
                  )}
                  {linkPOChecked && (
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "Inter",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {BALANCE_QTY}
                      </Typography>
                    </th>
                  )}
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {PURCHASE_QTY}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {STOCK_QTY}
                    </Typography>
                  </th>

                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {RATE}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {BASIC_COST}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {TAX}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 15,

                        fontWeight: 600,
                      }}
                    >
                      {TAX_COST}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {TOTAL_COST}
                    </Typography>
                  </th>
                  <th></th>
                  <td></td>
                </tr>
                <tr>
                  {rowItems?.length <= 0 && (
                    <IconButton
                      style={{ marginTop: 10 }}
                      id={`button-plus-default`}
                      aria-label="plus"
                      onClick={() => {
                        addObjectToArray();
                      }}
                    >
                      <img src={plus_icon} alt="" />
                    </IconButton>
                  )}
                </tr>
                {rowItems?.map((item, index) => {
                  return (
                    <tr key={item.rowId}
                      style={{
                        borderBottom: "0.001pt solid #f0eff4",
                      }}
                    >
                      <td>
                        <Autocomplete
                          filterOptions={globalFilter}
                          id={`autocomplete-1-${index}`}
                          {...itemCategoryProps}
                          variant="standard"
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          disabled={id > 0}
                          defaultValue={
                            itemCategoryProps.options.find(
                              (v) => v.value === rowItems[index].itemCategoryId
                            ) || {}
                          }
                          onChange={(e, v) => {
                            if (dto.supplierId <= 0) {
                              setAlertTitleText(
                                "Warning",
                                "Please Select Supplier..."
                              );
                              return;
                            }

                            let newArr = [...rowItems];
                            newArr[index].itemCategoryId = Number(v?.value);
                            setRowItems(newArr);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Item Category"
                              variant="standard"
                              style={{
                                minWidth: "190px",
                                minHeight: "38.5px",
                                width: "190px",
                                height: "38.5px",
                                marginBottom: 5,
                                marginLeft: 5,
                              }}
                              {...params}
                              InputLabelProps={{
                                style: {
                                  color: "grey",
                                  fontSize: 12,
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 12 },
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <Autocomplete
                          id={`autocomplete-2-${index}`}
                          {...itemProps}
                          variant="standard"
                          disabled={id > 0}
                          defaultValue={
                            itemProps.options.find(
                              (v) => v?.value === rowItems[index].itemId
                            ) || {}
                          }
                          filterOptions={(o, s) => {
                            let options = globalFilter(o, s);
                            let newOptions = [];
                            options.forEach((element) => {
                              if (
                                element.dependentvalue ===
                                rowItems[index].itemCategoryId
                              )
                                newOptions.push(element);
                            });

                            if (linkPOChecked) {
                              newOptions = newOptions.filter(
                                (x) => poItems.indexOf(x.value) !== -1
                              );
                            }

                            return newOptions;
                          }}
                          onChange={(e, v) => {
                            let newArr = [...rowItems];
                            newArr[index].itemId = Number(v?.value);
                            const filteredItems = itemList.filter(
                              (x) => x.id === newArr[index].itemId
                            );
                            if (filteredItems.length > 0) {
                              newArr[index].itemDescription =
                                filteredItems[0].description;
                              uomList.forEach((element) => {
                                if (
                                  element.value ===
                                  filteredItems[0].purchaseUOMId
                                )
                                  newArr[index].uomName = element.name;
                              });
                            }

                            if (linkPOChecked) {
                              setRowItems(newArr);
                            } else {
                              APIMATERIALMGMT.Rates({
                                supplierId: dto.supplierId,
                                date: dto.billDate,
                                itemId: [newArr[index].itemId],
                              })
                                .then((resp) => resp.json())
                                .then((resp) => {
                                  if (resp.statusCode === 200) {
                                    if (resp.response.length > 0) {
                                      newArr[index].rate = Number(
                                        resp.response[0].rate
                                      );
                                    } else {
                                      newArr[index].rate = 0;
                                    }
                                    newArr[index].basicCost = Number(
                                      APIUTILS.Financial(
                                        newArr[index].rate *
                                        newArr[index].purchaseQty
                                      )
                                    );
                                    setRowItems(newArr);
                                    let grandTotal = 0.0;
                                    dto.basicCost = 0.0;
                                    dto.taxCost = 0.0;
                                    dto.totalCost = 0.0;

                                    newArr.forEach((e) => {
                                      dto.basicCost += Number.parseFloat(
                                        APIUTILS.Financial(e.basicCost)
                                      );
                                      dto.taxCost += Number.parseFloat(
                                        APIUTILS.Financial(e.taxCost)
                                      );
                                      dto.totalCost += Number.parseFloat(
                                        APIUTILS.Financial(e.totalCost)
                                      );
                                      grandTotal += Number.parseFloat(
                                        APIUTILS.Financial(e.totalCost)
                                      );
                                    });
                                    dto.basicCost = Number(
                                      APIUTILS.Financial(dto.basicCost)
                                    );
                                    dto.taxCost = Number(
                                      APIUTILS.Financial(dto.taxCost)
                                    );
                                    dto.totalCost = Number(
                                      APIUTILS.Financial(dto.totalCost)
                                    );
                                    grandTotal += Number.parseFloat(
                                      APIUTILS.Financial(dto.loadingCharge)
                                    );
                                    grandTotal += Number.parseFloat(
                                      APIUTILS.Financial(dto.transportationCost)
                                    );
                                    setDTO({
                                      ...dto,
                                      grandTotal: Number.parseFloat(
                                        APIUTILS.Financial(grandTotal)
                                      ),
                                    });
                                  } else {
                                    setAlertTitleText(resp.message);
                                  }
                                });
                            }
                          }}
                          name="itemId"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Item Name"
                              variant="standard"
                              style={{
                                minWidth: "190px",
                                minHeight: "38.5px",
                                width: "190px",
                                height: "38.5px",
                              }}
                              {...params}
                              InputLabelProps={{
                                style: {
                                  color: "grey",
                                  fontSize: 12,
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 12 },
                              }}
                            />
                          )}
                        ></Autocomplete>
                      </td>
                      <td>
                        <TextareaAutosize
                          variant="standard"
                          label="Description"
                          maxLength={128}
                          disabled={id > 0}
                          style={{
                            outlineColor: BLACK,
                            borderTop: "10px",
                            borderRight: "10px",
                            borderLeft: "10px",
                            marginTop: 15,
                            fontSize: 12,
                            fontFamily: "sans-serif",
                            width: "120px",
                          }}
                          multiline="true"
                          maxRows={4}
                          minRows={2}
                          value={rowItems[index].itemDescription}
                          name="itemDescription"
                          onChange={(e) => {
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = e.target.value;
                            setRowItems(newArr);
                          }}
                          placeholder="Description"
                        ></TextareaAutosize>
                      </td>
                      {linkPOChecked && (
                        <td>
                          <TextField
                            variant="standard"
                            label="Scheduled Qty"
                            disabled={true}
                            
                            onWheel={(event) => event.target.blur()}
                            style={{
                              minWidth: "90px",
                              minHeight: "38.5px",
                              height: "38.5px",
                              width: "90px",
                              fontSize: 10,
                              marginBottom: 5,
                              marginLeft: 5,
                            }}
                            name="scheduledQty"
                            onChange={(e) => {
                              const { name, value } = e.target;
                              let newArr = [...rowItems];
                              newArr[index][e.target.name] = (
                                e.target.value
                              );
                              setRowItems(newArr);
                            }}
                            onBlur={(e) => {
                              let newArr = [...rowItems];
                              newArr[index][e.target.name] = Number(
                                e.target.value
                              );
                              setRowItems(newArr);
                            }}
                            value={rowItems[index].scheduledQty}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 12 },
                            }}
                            InputProps={{
                              style: { fontSize: 12 },
                            }}
                          ></TextField>
                        </td>
                      )}
                      {linkPOChecked && (
                        <td>
                          <TextField
                            variant="standard"
                            label="Balance Qty"
                            disabled={true}
                            
                            onWheel={(event) => event.target.blur()}
                            style={{
                              minWidth: "90px",
                              minHeight: "38.5px",
                              height: "38.5px",
                              width: "90px",
                              fontSize: 10,
                              marginBottom: 5,
                              marginLeft: 5,
                            }}
                            onChange={(e) => {
                              const { name, value } = e.target;
                              let newArr = [...rowItems];
                              newArr[index][e.target.name] = (
                                e.target.value
                              );
                              setRowItems(newArr);
                            }}
                            name="balanceQty"
                            onBlur={(e) => {
                              let newArr = [...rowItems];
                              newArr[index][e.target.name] = Number(
                                e.target.value
                              );
                              setRowItems(newArr);
                            }}
                            value={rowItems[index].balanceQty}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 12 },
                            }}
                            InputProps={{
                              style: { fontSize: 12 },
                            }}
                          ></TextField>
                        </td>
                      )}
                      <td>
                        <TextField
                          variant="standard"
                          label="Quantity"
                          disabled={id > 0}
                          
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "90px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "90px",
                            fontSize: 10,
                          }}
                          name="purchaseQty"
                          value={rowItems[index].purchaseQty}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = (
                              e.target.value
                            );
                            setRowItems(newArr);
                          }}
                          onBlur={(e) => {
                            let newArr = [...rowItems];
                            if (isNaN(e.target.value)) return;
                            newArr[index][e.target.name] = Number(
                              e.target.value
                            );
                            newArr[index].basicCost = Number(
                              APIUTILS.Financial(
                                newArr[index].purchaseQty *
                                newArr[index].rate
                              )
                            );

                            newArr[index].totalCost = newArr[index].basicCost;

                            APIMATERIALMGMT.StockConversion({
                              items: [
                                {
                                  itemId: newArr[index].itemId,
                                  purchaseQty: Number(e.target.value),
                                  stockQty: newArr[index].stockQty,
                                  balanceQty: newArr[index].balanceQty,
                                },
                              ],
                            })
                              .then((resp) => resp.json())
                              .then((resp) => {
                                if (resp.statusCode === 200) {
                                  newArr[index].stockQty = Number(
                                    resp.response.items[0].stockQty
                                  );
                                  
                                  if (newArr[index].basicCost === 0)
                                    newArr[index].taxCost = 0;

                                  if (
                                    newArr[index].tax !== null &&
                                    newArr[index].tax.taxIds.length > 0 &&
                                    newArr[index].basicCost !== 0
                                  ) {
                                    APIUTILS.CalculateTax({
                                      taxIds: newArr[index].tax.taxIds,
                                      basicAmount: newArr[index].basicCost,
                                    })
                                      .then((resp) => resp.json())
                                      .then((resp) => {
                                        if (resp.statusCode !== 200) {
                                          setAlertTitleText(
                                            "Oops...",
                                            "Cannot calculate tax"
                                          );
                                          return;
                                        }

                                        newArr[index].taxCost =
                                          Number.parseFloat(
                                            APIUTILS.Financial(
                                              Number(resp.response.taxValue)
                                            )
                                          );
                                        newArr[index].totalCost =
                                          Number.parseFloat(
                                            APIUTILS.Financial(
                                              Number(resp.response.totalValue)
                                            )
                                          );

                                        setRowItems((r) => [].concat(newArr));
                                        let grandTotal = 0.0;
                                        dto.basicCost = 0.0;
                                        dto.taxCost = 0.0;
                                        dto.totalCost = 0.0;

                                        newArr.forEach((e) => {
                                          dto.basicCost += Number.parseFloat(
                                            APIUTILS.Financial(e.basicCost)
                                          );
                                          dto.taxCost += Number.parseFloat(
                                            APIUTILS.Financial(e.taxCost)
                                          );
                                          dto.totalCost += Number.parseFloat(
                                            APIUTILS.Financial(e.totalCost)
                                          );
                                          grandTotal += Number.parseFloat(
                                            APIUTILS.Financial(e.totalCost)
                                          );
                                        });
                                        dto.basicCost = Number(
                                          APIUTILS.Financial(dto.basicCost)
                                        );
                                        dto.taxCost = Number(
                                          APIUTILS.Financial(dto.taxCost)
                                        );
                                        dto.totalCost = Number(
                                          APIUTILS.Financial(dto.totalCost)
                                        );
                                        grandTotal += Number.parseFloat(
                                          APIUTILS.Financial(dto.loadingCharge)
                                        );
                                        grandTotal += Number.parseFloat(
                                          APIUTILS.Financial(
                                            dto.transportationCost
                                          )
                                        );
                                        setDTO({
                                          ...dto,
                                          grandTotal: Number.parseFloat(
                                            APIUTILS.Financial(grandTotal)
                                          ),
                                        });
                                      });
                                  } else {
                                    setRowItems(newArr);
                                    let grandTotal = 0.0;
                                    dto.basicCost = 0.0;
                                    dto.taxCost = 0.0;
                                    dto.totalCost = 0.0;

                                    newArr.forEach((e) => {
                                      dto.basicCost += Number.parseFloat(
                                        APIUTILS.Financial(e.basicCost)
                                      );
                                      dto.taxCost += Number.parseFloat(
                                        APIUTILS.Financial(e.taxCost)
                                      );
                                      dto.totalCost += Number.parseFloat(
                                        APIUTILS.Financial(e.totalCost)
                                      );
                                      grandTotal += Number.parseFloat(
                                        APIUTILS.Financial(e.totalCost)
                                      );
                                    });
                                    dto.basicCost = Number(
                                      APIUTILS.Financial(dto.basicCost)
                                    );
                                    dto.taxCost = Number(
                                      APIUTILS.Financial(dto.taxCost)
                                    );
                                    dto.totalCost = Number(
                                      APIUTILS.Financial(dto.totalCost)
                                    );
                                    grandTotal += Number.parseFloat(
                                      APIUTILS.Financial(dto.loadingCharge)
                                    );
                                    grandTotal += Number.parseFloat(
                                      APIUTILS.Financial(dto.transportationCost)
                                    );
                                    setDTO({
                                      ...dto,
                                      grandTotal: Number.parseFloat(
                                        APIUTILS.Financial(grandTotal)
                                      ),
                                    });
                                  }
                                }
                              });
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 12 },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {rowItems[index].uomName}
                              </InputAdornment>
                            ),
                            style: { fontSize: 12 },
                          }}
                        ></TextField>
                      </td>
                      <td>
                        <TextField
                          variant="standard"
                          label="Stock Qty"
                          
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "90px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "90px",
                            fontSize: 10,
                            marginBottom: 5,
                            marginLeft: 5,
                          }}
                          name="stockQty"
                          onChange={(e) => {
                            const { name, value } = e.target;
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = (
                              e.target.value
                            );
                            setRowItems(newArr);
                          }}
                          onBlur={(e) => {
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = Number(
                              e.target.value
                            );
                            setRowItems(newArr);
                          }}
                          value={rowItems[index].stockQty}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 12 },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {rowItems[index].stockUomName}
                              </InputAdornment>
                            ),
                            style: { fontSize: 12 },
                          }}
                        ></TextField>
                      </td>

                      <td>
                        <TextField
                          variant="standard"
                          label="Rate"
                          disabled={(id > 0 && !isEdit) || linkPOChecked}
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "90px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "90px",
                            fontSize: 10,
                            marginBottom: 5,
                            marginLeft: 5,
                          }}
                          name="rate"
                          onChange={(e) => {
                            const { name, value } = e.target;
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = (
                              e.target.value
                            );
                            setRowItems(newArr);
                          }}
                          onBlur={(e) => {
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = Number(
                              e.target.value
                            );
                            newArr[index].basicCost = Number(
                              APIUTILS.Financial(
                                Number(e.target.value) *
                                newArr[index].purchaseQty
                              )
                            );
                            setRowItems(newArr);
                            let grandTotal = 0.0;
                            dto.basicCost = 0.0;
                            dto.taxCost = 0.0;
                            dto.totalCost = 0.0;

                            newArr.forEach((e) => {
                              dto.basicCost += Number.parseFloat(
                                APIUTILS.Financial(e.basicCost)
                              );
                              dto.taxCost += Number.parseFloat(
                                APIUTILS.Financial(e.taxCost)
                              );
                              dto.totalCost += Number.parseFloat(
                                APIUTILS.Financial(e.totalCost)
                              );
                              grandTotal += Number.parseFloat(
                                APIUTILS.Financial(e.totalCost)
                              );
                            });
                            dto.basicCost = Number(
                              APIUTILS.Financial(dto.basicCost)
                            );
                            dto.taxCost = Number(
                              APIUTILS.Financial(dto.taxCost)
                            );
                            dto.totalCost = Number(
                              APIUTILS.Financial(dto.totalCost)
                            );
                            grandTotal += Number.parseFloat(
                              APIUTILS.Financial(dto.loadingCharge)
                            );
                            grandTotal += Number.parseFloat(
                              APIUTILS.Financial(dto.transportationCost)
                            );
                            setDTO({
                              ...dto,
                              grandTotal: Number.parseFloat(
                                APIUTILS.Financial(grandTotal)
                              ),
                            });
                          }}
                          value={rowItems[index].rate}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 12 },
                          }}
                          InputProps={{
                            style: { fontSize: 12 },
                          }}
                        ></TextField>
                      </td>
                      <td>
                        <TextField
                          variant="standard"
                          label="Basic Cost"
                          disabled={true}
                          
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "90px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "90px",
                            fontSize: 10,
                            marginBottom: 5,
                            marginLeft: 5,
                          }}
                          name="basicCost"
                          onChange={(e) => {
                            const { name, value } = e.target;
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = (
                              e.target.value
                            );
                            setRowItems(newArr);
                          }}
                          onBlur={(e) => {
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = Number(
                              e.target.value
                            );
                            setRowItems(newArr);
                          }}
                          value={rowItems[index].basicCost}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 12 },
                          }}
                          InputProps={{
                            style: { fontSize: 12 },
                          }}
                        ></TextField>
                      </td>

                      <td>
                        <IconButton
                          disabled={id > 0}
                          style={{
                            marginTop: 10,
                            marginLeft: 10,
                            marginRight: 10,
                          }}
                          aria-label="plus"
                          onClick={() => {
                            setCurrentIndex(index);
                            if ((id > 0 && !isEdit) || linkPOChecked) {
                              setViewOnlyModel(true);
                            }
                            setFormData(rowItems[index]);
                            setOpenModal(true);
                          }}
                        >
                          <img src={apply_icon} alt="" />
                        </IconButton>
                      </td>
                      <td>
                        <TextField
                          variant="standard"
                          label="Tax Cost"
                          disabled={true}
                          
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "90px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "90px",
                            fontSize: 10,
                            marginBottom: 5,
                            marginLeft: 5,
                          }}
                          name="taxCost"
                          onChange={(e) => {
                            const { name, value } = e.target;
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = (
                              e.target.value
                            );
                            setRowItems(newArr);
                          }}
                          onBlur={(e) => {
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = Number(
                              e.target.value
                            );
                            setRowItems(newArr);
                          }}
                          value={rowItems[index].taxCost}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 12 },
                          }}
                          InputProps={{
                            style: { fontSize: 12 },
                          }}
                        ></TextField>
                      </td>
                      <td>
                        <TextField
                          variant="standard"
                          label="Total Cost"
                          disabled={true}
                          
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "90px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "90px",
                            fontSize: 10,
                            marginBottom: 5,
                            marginLeft: 5,
                          }}
                          name="totalCost"
                          onChange={(e) => {
                            const { name, value } = e.target;
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = (
                              e.target.value
                            );
                            setRowItems(newArr);
                          }}
                          onBlur={(e) => {
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = Number(
                              e.target.value
                            );
                            setRowItems(newArr);
                          }}
                          value={rowItems[index].totalCost}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 12 },
                          }}
                          InputProps={{
                            style: { fontSize: 12 },
                          }}
                        ></TextField>
                      </td>

                      <td>
                        {id <= 0 && index >= 0 && (
                          <IconButton
                            style={{ marginTop: 10 }}
                            aria-label="minus"
                            onClick={() => {
                              removeObjectFromArray(item.rowId);
                            }}
                          >
                            <img src={minus_icon} alt="" />
                          </IconButton>
                        )}
                      </td>
                      <td>
                        {id <= 0 && (
                          <IconButton
                            style={{ marginTop: 10 }}
                            aria-label="plus"
                            onClick={() => {
                              addObjectToArray();
                            }}
                          >
                            <img src={plus_icon} alt="" />
                          </IconButton>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </TableBody>
            </table>
          )}
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <TableBody>
              <tr style={{ height: "30px" }}></tr>
              <tr
                style={{
                  backgroundColor: ANTI_FLASH_WHITE,
                  height: "40px",
                }}
              >
                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      flexGrow: 0,
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                    }}
                  >
                    {TRANSPORTATION_COST}
                  </Typography>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <input
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setDTO({...dto, [name]: value});
                    }}
                    onWheel={(event) => event.target.blur()}
                    name="transportationCost"
                    value={dto.transportationCost}
                    onBlur={(e) => {
                      let grandTotal = 0.0;
                      dto.basicCost = 0.0;
                      dto.taxCost = 0.0;
                      dto.totalCost = 0.0;

                      rowItems.forEach((e) => {
                        dto.basicCost += Number.parseFloat(
                          APIUTILS.Financial(e.basicCost)
                        );
                        dto.taxCost += Number.parseFloat(
                          APIUTILS.Financial(e.taxCost)
                        );
                        dto.totalCost += Number.parseFloat(
                          APIUTILS.Financial(e.totalCost)
                        );
                        grandTotal += Number.parseFloat(
                          APIUTILS.Financial(e.totalCost)
                        );
                      });
                      dto.basicCost = Number(APIUTILS.Financial(dto.basicCost));
                      dto.taxCost = Number(APIUTILS.Financial(dto.taxCost));
                      dto.totalCost = Number(APIUTILS.Financial(dto.totalCost));
                      if (!isNaN(e.target.value))
                        dto.transportationCost = Number(e.target.value);

                      grandTotal += Number.parseFloat(
                        APIUTILS.Financial(dto.loadingCharge)
                      );
                      grandTotal += Number.parseFloat(
                        APIUTILS.Financial(dto.transportationCost)
                      );
                      setDTO({
                        ...dto,
                        grandTotal: Number.parseFloat(
                          APIUTILS.Financial(grandTotal)
                        ),
                      });
                    }}
                    disabled={id > 0 && !isEdit}
                    style={{ width: "100px" }}
                  ></input>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr
                style={{
                  backgroundColor: ANTI_FLASH_WHITE,
                  height: "40px",
                }}
              >
                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      flexGrow: 0,
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                    }}
                  >
                    {LOADING_CHARGES}
                  </Typography>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <input
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setDTO({...dto, [name]: value});
                    }}
                    onWheel={(event) => event.target.blur()}
                    name="loadingCharge"
                    value={dto.loadingCharge}
                    onBlur={(e) => {
                      let grandTotal = 0.0;
                      dto.basicCost = 0.0;
                      dto.taxCost = 0.0;
                      dto.totalCost = 0.0;

                      rowItems.forEach((e) => {
                        dto.basicCost += Number.parseFloat(
                          APIUTILS.Financial(e.basicCost)
                        );
                        dto.taxCost += Number.parseFloat(
                          APIUTILS.Financial(e.taxCost)
                        );
                        dto.totalCost += Number.parseFloat(
                          APIUTILS.Financial(e.totalCost)
                        );
                        grandTotal += Number.parseFloat(
                          APIUTILS.Financial(e.totalCost)
                        );
                      });
                      dto.basicCost = Number(APIUTILS.Financial(dto.basicCost));
                      dto.taxCost = Number(APIUTILS.Financial(dto.taxCost));
                      dto.totalCost = Number(APIUTILS.Financial(dto.totalCost));
                      if (!isNaN(e.target.value))
                        dto.loadingCharge = Number(e.target.value);

                      grandTotal += Number.parseFloat(
                        APIUTILS.Financial(dto.loadingCharge)
                      );
                      grandTotal += Number.parseFloat(
                        APIUTILS.Financial(dto.transportationCost)
                      );
                      setDTO({
                        ...dto,
                        grandTotal: Number.parseFloat(
                          APIUTILS.Financial(grandTotal)
                        ),
                      });
                    }}
                    disabled={id > 0 && !isEdit}
                    style={{ width: "100px" }}
                  ></input>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr
                style={{
                  backgroundColor: ANTI_FLASH_WHITE,
                  height: "40px",
                }}
              >
                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      flexGrow: 0,
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                    }}
                  >
                    {TOTAL}
                  </Typography>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      marginTop: 10,
                      flexGrow: 0,
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                      marginLeft: 5,
                    }}
                  >
                    ₹ {dto.basicCost}
                  </Typography>
                </td>
                <td></td>
                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      marginTop: 10,
                      flexGrow: 0,
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                      marginLeft: 5,
                    }}
                  >
                    ₹ {dto.taxCost}
                  </Typography>
                </td>
                {/* unitedAbove */}
                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      marginTop: 10,
                      flexGrow: 0,
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                      marginLeft: 5,
                    }}
                  >
                    ₹ {dto.totalCost}
                  </Typography>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr
                style={{
                  backgroundColor: ANTI_FLASH_WHITE,
                  height: "40px",
                }}
              >
                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      flexGrow: 0,
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                    }}
                  >
                    {GRAND_TOTAL}
                  </Typography>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      marginTop: 10,
                      flexGrow: 0,
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                      marginLeft: 5,
                    }}
                  >
                    ₹ {dto.grandTotal}
                  </Typography>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </TableBody>
          </table>
        </div>
      </div>
    </SideNav>
  );
}
