export const LOGIN = "Login";
export const REMEMBER_ME = "Remember me";
export const FORGOT_PASSWORD = "Forgot password?";
export const SIGN_IN = "Sign in";
export const LOGOUT = "Logout";
export const DASHBOARD = "Dashboard";
export const MASTER = "Master";
export const TAXES = "Taxes";
export const SUPPLIERS = "Suppliers";
export const UOM = "Uoms";
export const SITE = "Sites";
export const ITEM = "Items";
export const MATERIAL_MANAGEMENT = "Material Management";
export const LABOUR_MANAGEMENT = "Labour Management";
export const CUSTOMER_MANAGEMENT = "Customer Management";
export const USER_MANAGEMENT = "User Management";
export const REPORTS = "Reports";
export const DELETE = "Delete";
export const ADD = "Add";
export const ADD_ITEM = "Add Item";
export const SUPPLIER_CATEGORIES = "Supplier Categories";
export const ITEM_CATEGORIES = "Item Categories";
export const SITE_CATEGORIES = "Site Categories";
export const PURCHASE_PRICE_CONFIGURATION = "Purchase Price Configuration";
export const UPLOAD = "Upload";
export const DOWNLOAD = "Download";
export const CHANGE_STATUS = "Change Status";
export const GO = "Go";
export const SELECT_CSV_FILE_TO_UPLOAD = "Select a xlsx file to Upload";
export const OR_DRAG_AND_DROP_IT_HERE = "or drag and drop it here";
export const SALES_ORDERS = "Sales Orders";
export const SALES_ORDERS_DETAIL = "Sales Orders Detail";
export const INVOICE = "Invoice";
export const INVOICE_DETAIL = "Invoice Detail";
export const SAVE = "Save";
export const PRINT = "Print";
export const CANCEL = "Cancel";
export const PAYMENT_TERMS = "Payment Terms";
export const ON_SUPPLY = "On Supply";
export const ON_INSTALLATION = "On Installation";
export const ON_TESTING = "On Testing";
export const ON_COMMISSION_AND_HO = "On Commisioning & HO";
export const TOTAL = "Total";
export const TOTAL_SHOULD_BE_100 = "*Total should be 100%";
export const DOWNLOAD_TEMPLATE = "Download Template";
export const HOME = "Home";
export const INDENT = "Indent";
export const INDENT_DETAILS = "Indent Details";
export const GENERATE = "Generate";
export const PURCHASE_ORDER_INDENT = "Purchase Order";
export const PURCHASE_ORDER_INDENT_DETAIL = "Purchase Order Detail";
export const ITEM_CATEGORY = "Item Category";
export const ITEM_NAME = "Item Name";
export const ITEM_DESCRIPTION = "Item Description";
export const QTY = "Qty";
export const INDENT_QTY = "Indent Qty";
export const BALANCE_QTY = "Balance Qty";
export const RATE = "Rate";
export const PURCHASE_ORDER_UOM = "UOM";
export const BASIC_VALUE = "Basic Value";
export const TAX = "Tax";
export const TAX_VALUE = "Tax Value";
export const TOTAL_VALUE = "Total Value";
export const FREIGHT_CHARGE = "Freight Charge";
export const PURCHASE_ORDER_NO = "Purchase Order No : ";
export const SOURCE = "Source";
export const TARGET = "Target";
export const APPLY = "Apply";
export const GOODS_RECEIVED = "Goods Received";
export const CREDIT_NOTE = "Credit Note";
export const CREDIT_NOTE_DETAIL = "Credit Note Detail";
export const C_GRN_No = "C-GRN No : ";
export const STOCK = "Stock";
export const RETURN_QTY = "Return Qty";
export const BASIC_COST = "Basic Cost";
export const TAX_COST = "Tax Cost";
export const TOTAL_COST = "Total Cost";
export const GOODS_RECEIVED_DETAIL = "Goods Received Detail";
export const GRN_NO = "GRN No : ";
export const PURCHASE_QTY = "Purchase Qty";
export const STOCK_QTY = "Stock Qty";
export const SCHEDULED_QTY = "Scheduled Qty";
export const TRANSPORTATION_COST = "Transportation Cost";
export const LOADING_CHARGES = "Loading Charges";
export const MATERIAL_TRANSFER = "Material Transfer";
export const MATERIAL_TRANSFER_DETAIL = "Material Transfer Detail";
export const TRANSACTION_NO = "Transaction No : ";
export const MATERIAL_TRANSFER_UOM = "UOM";
export const TOTAL_STOCK = "Total Stock";
export const AVERAGE_RATE = "Average Rate";
export const SUB_CONTRACTOR_FOOD_ADVANCE = "Sub Contractor Food Advance";
export const SUB_CONTRACTOR_FOOD_ADVANCE_DETAIL =
  "Sub Contractor Food Advance Detail";
export const EXPORT = "Export";
export const EDIT = "Edit";
export const FOOD_ADVANCE = "Food Advance";
export const PAYABLE = "Payable";
export const SUB_CONTRACTOR_ADVANCE_REQUEST = "Sub Contractor Advance Request";
export const SUB_CONTRACTOR_ADVANCE_REQUEST_DETAIL =
  "Sub Contractor Advance Request Detail";
export const REFRESH = "Refresh";
export const AMOUNT_BILLED_TILL_DATE = "Amount Billed Till Date";
export const RETENTION_AMOUNT = "Retention Amount";
export const TESTING_AND_COMMISSION_BALANCE_AMOUNT =
  "Testing and Commission Balance Amount";
export const RETENTION_BALANCE_AMOUNT = "Retention Balance Amount";
export const AMOUNT_PAID_TO_DATE = "Amount Paid to Date";
export const WORK_ORDER = "Work Order";
export const WORK_ORDER_DETAIL = "Work Order Detail";
export const WORK_ORDER_NO = "Work Order No : ";
export const UNIT = "Unit";
export const NEW_VERSION = "New Version";
export const VERSION_NO = "Version No";
export const DATE = "Date";
export const OLD_VERSION = "Old Version";
export const DAILY_WORK_ENTRY = "Daily Work Entry";
export const DAILY_WORK_ENTRY_DETAILS = "Daily Work Entry Details";
export const REFERENCE_NO = "Reference No : ";
export const TYPE_OF_MAN_POWER = "Type of Manpower";
export const RATE_RS = "Rate(Rs)";
export const BUS_FARE = "Bus Fare";
export const FOOD_FARE = "Food Fare";
export const OT_RAISE = "OT Rate";
export const OT_HOURS = "OT Hours";
export const QUANTITY = "Quantity";
export const AMOUNT = "Amount";
export const DAILY_WORK_ENTRY_ITEM = "Item";
export const LABOURER_TYPE = "Labourer Type";
export const EMPLOYEE = "Employee";
export const EMPLOYEE_TYPE = "Employee Type";
export const MANAGER_ASSIGNMENT = "Manager Assignment";
export const SALES_ORDER_NO = "Sales Orders No";
export const SALES_ORDER_DATE = "Sales Orders Date";
export const Site_NAME = "Site Name";
export const INST_AMOUNT = "Inst Amount";
export const GRAND_TOTAL = "Grand Total";
export const STATUS = "Status";
export const CLEAR = "Clear All";
export const ITEM_CODE = "Item Code";
export const SUPPLY_RATE = "Supply Rate";
export const INST_RATE = "Inst Rate";
export const SUPPLY_AMOUNT = "Supply Amount";
export const TOTAL_AMOUNT = "Total Amount";
export const SUB_CONTRACTOR_BILLING = "Sub Contractor Billing";
export const SUB_CONTRACTOR_BILLING_DETAIL = "Sub Contractor Billing Detail";
export const CALCULATE = "Calculate";
export const LESS_RETENTION_AMOUNT_5 = "Less Retention Amount ";
export const LESS_TESTING_AND_COMMISION_AMOUNT_5 =
  "Less Testing & Commision Amount ";
export const TOTAL_BILL_AMOUNT = "Total Bill Amount";
export const RELEASE_LESS_RETENTION = "Release Less Retention";
export const RELEASE_TESTING_AND_COMMISION = "Release Testing & Commision";
export const TOTAL_PRESENT_BILL_AMOUNT = "Total Present Bill Amount";
export const PREVIOUS_BILL_AMOUNT_PAID = "Previous Bill Amount Paid";
export const LESS_AMOUNT_PAID_TILL_DATE = "Less Amount Paid Till Date";
export const LESS_ADVANCE_DEDUCTIBLE = "Less Advance Deductible";
export const TOTAL_AMOUNT_PAID = "Total Amount Paid";
export const GRAND_TOTAL_AMOUNT_ACTUAL_PAID =
  "Grand Total (Amount Actual Paid)";
export const RETENTION_AND_TESTING_AMOUNT_SUMMARY =
  "Retention & Testing Amount Summary";
export const TOTAL_DEDUCTED = "Total Deducted";
export const TOTAL_RELEASED = "Total Released";
export const BALANCED_AMOUNT = "Balance Amount";
export const RETENTION = "Retention";
export const TESTING_AND_COMMISION = "Testing & Commision";
export const PURCHASE_ORDER_REPORT = "Purchase Order Report";
export const GOODS_RECEIVED_REPORT = "Goods Received Report";
export const STOCK_STATEMENT_REPORT = "Stock Statement Report";
export const PURCHASE_RATIO_REPORT = "Purchase Ratio Report";
export const EXCAVATOR_SUMMARY_REPORT = "Excavator Summary Report";
export const SUB_CONTRACTOR_BILLING_REPORT = "Sub Contractor Billing Report";
export const SUB_CONTRACTOR_ADVANCE_REQUEST_SUMMARY_REPORT =
  "Sub Con Adv Req Summary";
export const SUB_CONTRACTOR_FOOD_ADVANCE_DETAIL_REPORT =
  "Sub Con Food Adn Detail";
export const AMOUNT_RECOVERED = "Amount Recovered";
export const BALANCE_AMOUNT = "Balance Amount";
export const OPENING_STOCK = "Opening Stock";
export const PURCHASE = "Purchase";
export const TRANSFER = "Transfer";
export const CONSUMPTION = "Consumption";
export const ADJUSTMENT = "Adjustment";
export const ITEM_RETURN = "Item Return";
export const CLOSING_STOCK = "Closing Stock";
export const TOTAL_LABOUR_BILLED_EXCAVATOR = "Total Labor Billed (Excavator)";
export const TOTAL_PURCHASE = "Total Purchase";
export const TOTAL_SUB_CONTRACTOR_BILLED = "Total Subcontractor Billed";
export const BILLED_TILL_DATE = "Billed Till Date";
export const BALANCE_BILLING = "Balance Billing";
export const SUB_CONTRACTOR_BILLING_SUMMARY_REPORT =
  "Sub Con Billing Sum Report";
export const SUB_CONTRACTOR_BILLED_QTY = "Sub Con Check List";
export const GRN_NOS = "GRN No's";
export const TOTAL_PO_QTY = "Total PO Qty";
export const TOTAL_PO_VALUE = "Total PO Value";
export const TOTAl_PURCHASE_QTY = "Total Purchase Qty";
export const TOTAL_PURCHASE_VALUE = "Total Purchase Value";
export const TOTAL_BALANCE_QTY = "Total Balance Qty";
export const TOTAL_BALANCE_VALUE = "Total Balance Value";
export const DESIGNATION = "Designation";
export const ATTENDANCE_REPORT = "Attendance Report";
export const BALANCE_BILLING_REPORT = "Balance Billing Report";
export const MATERIAL_MANAGEMENT_REPORT = "Material Mgmt Reports";
export const LABOUR_MANAGEMENT_REPORT = "Labour Mgmt Reports";
export const CUSTOMER_MANAGEMENT_REPORT = "Customer Mgmt Reports";
