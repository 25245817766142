import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import React, { useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  getItemCategories,
  getItemList,
  getItems,
  getSiteCategories,
  getSites,
  getSupplierCategories,
  getSuppliers,
  getUoms,
  globalFilter,
  itemCategoryCellRenderer,
  itemCellRenderer,
} from "../../../../../../component/cellRenderers/renderers";
import SideNav from "../../../../../../component/layouts/sideNav";
import {
  BASIC_COST,
  CANCEL,
  CLEAR,
  CREDIT_NOTE,
  CREDIT_NOTE_DETAIL,
  C_GRN_No,
  ITEM_CATEGORY,
  ITEM_NAME,
  MATERIAL_MANAGEMENT,
  RATE,
  RETURN_QTY,
  SAVE,
  STOCK,
  TAX,
  TAX_COST,
  TOTAL,
  TOTAL_COST,
  TRANSPORTATION_COST,
} from "../../../../../../resources/constant/strings/string";

import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  TableBody,
  TextField,
  Typography,
} from "@mui/material";
import apply_icon from "../../../../../../resources/assets/images/apply_icon.svg";
import minus_icon from "../../../../../../resources/assets/images/minus_icon.svg";
import plus_icon from "../../../../../../resources/assets/images/plus_icon.svg";
import {
  ANTI_FLASH_WHITE,
  CHARLESTON_GREEN,
  LIGHT_GREY_BACKGROUND,
  PRUSSIAN_BLUE,
  SPANISH_GREEN,
  WHITE,
} from "../../../../../../resources/constant/colors/theme";
import { PATH_CREDIT_NOTE } from "../../../../../../resources/constant/url/url";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { APIMATERIALMGMT } from "../../../../../../apihandler/materialmgmt";
import { APIUTILS } from "../../../../../../apihandler/utils";
import {
  setAlertConfirmDeletion,
  setAlertTitleText,
} from "../../../../../../component/modal/alertModal";
import Modal from "./modal";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  color: SPANISH_GREEN,
  transform: "translate(-50%, -50%)",
  zIndex: 2,
};
export default function CreditNoteDetail() {
  let { id } = useParams();
  const location = useLocation();
  const isEdit = location.state?.isEdit;
  console.log(id);
  const navigate = useNavigate();

  const addModel = id <= 0;
  const editModel = id > 0;

  const [disableFetch, setDisableFetch] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [formData, setFormData] = useState(null);

  const onApplyValue = (v) => {
    const newArr = [...rowItems];
    newArr[currentIndex].taxCost = Number.parseFloat(
      APIUTILS.Financial(Number(v.taxValue))
    );
    newArr[currentIndex].totalCost = Number.parseFloat(
      APIUTILS.Financial(Number(v.totalValue))
    );
    newArr[currentIndex].tax = v;
    let grandTotal = 0.0;
    dto.basicCost = 0.0;
    dto.taxCost = 0.0;
    dto.totalCost = 0.0;

    newArr.forEach((e) => {
      dto.basicCost += Number.parseFloat(APIUTILS.Financial(e.basicCost));
      dto.taxCost += Number.parseFloat(APIUTILS.Financial(e.taxCost));
      if (e.taxCost === 0) e.totalCost = e.basicCost;
      dto.totalCost += Number.parseFloat(APIUTILS.Financial(e.totalCost));
      grandTotal += Number.parseFloat(APIUTILS.Financial(e.totalCost));
    });

    grandTotal += Number.parseFloat(APIUTILS.Financial(dto.loadingCharge));
    grandTotal += Number.parseFloat(APIUTILS.Financial(dto.transportationCost));

    setDTO({
      ...dto,
      grandTotal: Number.parseFloat(APIUTILS.Financial(grandTotal)),
    });
    setRowItems(newArr);
  };

  const supplierCategories = getSupplierCategories().filter(
    (v) => v.name !== "Opening Stock Category"
  );
  const suppliers = getSuppliers().filter((v) => v.name !== "Opening Stock");
  const sites = getSites();

  const supplierCategoryProps = {
    options: supplierCategories,
    getOptionLabel: (option) => option?.name || "",
  };

  const supplierProps = {
    options: suppliers,
    getOptionLabel: (option) => option?.name || "",
  };

  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (
      value &&
      name !== "description" &&
      name !== "creditNoteNumber" &&
      name !== "billNo"
    ) {
      if (isNaN(value)) setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    } else {
      setDTO({ ...dto, [name]: value });
    }
  };

  const itemCategories = getItemCategories();

  const itemCategoryProps = {
    options: itemCategories,
    getOptionLabel: (option) => option?.name || "",
  };

  const itemList = getItemList();
  const uomList = getUoms();

  const items = getItems();
  const [grnOptions, setGrnOptions] = React.useState([]);

  const itemProps = {
    options: items,
    getOptionLabel: (option) => option?.name || "",
  };

  const uomProps = {
    options: uomList,
    getOptionLabel: (option) => option?.name || "",
  };

  const siteCategories = getSiteCategories();
  const siteCategoryProps = {
    options: siteCategories,
    getOptionLabel: (option) => option?.name || "",
  };

  const fetchGRN = (v) => {
    APIMATERIALMGMT.CrediteNoteGRN(v)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode === 200) {
          let grandTotal = 0.0;
          clearArray();
          const newArr = [...rowItems];
          console.log("Rows", rowItems.length);
          dto.basicCost = 0.0;
          dto.taxCost = 0.0;
          dto.totalCost = 0.0;
          dto.goodsReceivedId = v;
          resp.response
            .sort((a, b) => b.id - a.id)
            .forEach((e, i) => {
              e.rowId = i + 1;
              const it = items.filter((v) => v.value === e.itemId);
              if (it.length > 0) {
                e.itemCategoryId = it[0].dependentvalue;
              }
              const itemsInPO = itemList.filter((v) => v.id === e.itemId);
              if (itemsInPO.length > 0) {
                let uom = uomList.filter(
                  (v) => v.value === itemsInPO[0].purchaseUOMId
                );
                if (uom.length > 0) e.uomName = uom[0].name;
              }
              e.basicCost = 0.0;
              e.taxCost = 0.0;
              e.totalCost = 0.0;
              newArr.push(e);
            });
          dto.basicCost = Number(APIUTILS.Financial(dto.basicCost));
          dto.taxCost = Number(APIUTILS.Financial(dto.taxCost));
          dto.totalCost = Number(APIUTILS.Financial(dto.totalCost));
          grandTotal += APIUTILS.Financial(dto.transportationCost);
          grandTotal += APIUTILS.Financial(dto.loadingCharge);
          dto.purchaseOrderId = v;
          setDTO({
            ...dto,
            grandTotal: Number.parseFloat(APIUTILS.Financial(grandTotal)),
          });
          setRowItems((rowItems) => [].concat(newArr));
        } else {
          setAlertTitleText("Oops...", resp.message);
        }
      });
  };

  const [dto, setDTO] = useState({
    id: 0,
    creditNoteNumber: "",
    creditNoteGRNNumber: "",
    goodsReceivedId: 0,
    date: dayjs(),
    billDate: dayjs(),
    supplierId: 0,
    supplierCategoryId: 0,
    siteId: 0,
    taxAmount: 0,
    grandTotal: 0,
    billNo: "",
    status: "",
    modifiedBy: 0,
    purchaseOrderId: 0,
    description: "",
    transportationCost: 0.0,
    loadingCharge: 0.0,
    basicCost: 0.0,
    taxCost: 0.0,
    totalCost: 0.0,
  });
  const [rowItems, setRowItems] = useState([]);
  const [rowId, setRowId] = useState(2);
  function addObjectToArray() {
    const newArr = [...rowItems];
    newArr.push({
      id: newArr.length + 1,
      itemCategoryId: 0,
      itemId: 0,
      uomName: "",
      itemDescription: "",
      purchaseQty: 0.0,
      stockQty: 0,
      balanceQty: 0,
      scheduledQty: 0,
      isPOItem: false,
      rate: 0,
      discount: 0,
      basicCost: 0,
      taxCost: 0,
      tax: { taxIds: [] },
      totalCost: 0,
      status: "N",
    });
    setRowItems(newArr);
  }

  const removeObjectFromArray = (rowId) => {
    setRowItems(c => c.filter((x) => {
      return x.id !== rowId;
    }));
    setTimeout(()=>{

    let grandTotal = 0.0;
    dto.basicCost = 0.0;
    dto.taxCost = 0.0;
    dto.totalCost = 0.0;

    rowItems.forEach((e) => {
      dto.basicCost += Number.parseFloat(APIUTILS.Financial(e.basicCost));
      dto.taxCost += Number.parseFloat(APIUTILS.Financial(e.taxCost));
      if (e.taxCost === 0) e.totalCost = e.basicCost;
      dto.totalCost += Number.parseFloat(APIUTILS.Financial(e.totalCost));
      grandTotal += Number.parseFloat(APIUTILS.Financial(e.totalCost));
    });
    dto.basicCost = Number(APIUTILS.Financial(dto.basicCost));
    dto.taxCost = Number(APIUTILS.Financial(dto.taxCost));
    dto.totalCost = Number(APIUTILS.Financial(dto.totalCost));
    grandTotal += Number.parseFloat(APIUTILS.Financial(dto.transportationCost));
    setDTO({
      ...dto,
      grandTotal: Number.parseFloat(APIUTILS.Financial(grandTotal)),
    });
  },2000);
  }

  const clearArray = () => {
    setRowItems((x) => false);
    console.log("Rows count on clear", rowItems.length);
  };

  const onClear = () => {
    setAlertConfirmDeletion(
      "Confirmation needed",
      "Do you confirm on proceeding to clear?"
    ).then((consent) => {
      if (!consent.isConfirmed) return;
      window.location.reload();
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    dto.creditNoteDetails = rowItems.filter((m) => m.returnQty > 0);
    if (dto.creditNoteDetails.length <= 0) {
      setAlertTitleText("No items have been selected for return");
      return;
    }

    //api call to save goes here
    if (dto.id <= 0) {
      APIMATERIALMGMT.SaveCrediteNote(dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setAlertTitleText(
              "Success",
              "The record was inserted successfully"
            );
            navigate(PATH_CREDIT_NOTE + "/" + location.state.date);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    } else {
      APIMATERIALMGMT.UpdateGoodsReceived(id, dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setAlertTitleText("Success", "The record was updated successfully");
            navigate(PATH_CREDIT_NOTE + "/" + location.state.date);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
          }
        });
    }
  }

  const gridRef = useRef();
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [gridData, setGridData] = useState([]);
  const [supplierCategory, setSupplierCategory] = useState({});
  const [siteCategory, setSiteCategory] = useState({});
  const [supplier, setSupplier] = useState({});
  const [grn, setGRN] = useState({});
  const [site, setSite] = useState({});

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId(event.api.getSelectedRows()[0].id);
    else setSelectedRowId(0);
  };

  const columnDefs = [
    {
      flex: 1.5,
      headerName: "Item Category",
      field: "itemCategoryId",
      valueGetter: (params) => {
        return itemCategoryCellRenderer({ value: params.data.itemCategoryId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      flex: 1.5,
      headerName: "Item Name",
      field: "itemId",
      valueGetter: (params) => {
        return itemCellRenderer({ value: params.data.itemId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      flex: 1,
      wrapText: true,
      autoHeight: true,
      headerName: "Item Description",
      field: "description",
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Return Quantity",
      field: "returnQty",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      wraoText: true,
      autoHeight: true,
      headerName: "UOM",
      field: "uomName",
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Rate",
      field: "rate",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Basic Cost",
      field: "basicCost",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Tax Cost",
      field: "taxCost",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Total Cost",
      field: "totalCost",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      flex: 0.6,
      headerName: "Tax",
      field: "id",
      sortable: false,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            aria-label="edit"
            onClick={() => {
              setFormData(params.data);
              setOpenModal(true);
            }}
          >
            <VisibilityIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
          </IconButton>
        </div>
      ),
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };

  const onGridReady = (params) => {
    gridRef.current.api.applyTransaction({ add: [] });
    if (id > 0) {
      APIMATERIALMGMT.CreditNote(id)
        .then((resp) => resp.json())
        .then((resp) => {
          resp.response.creditNoteDetails =
            resp.response.creditNoteDetails.sort((a, b) => b.id - a.id);
          resp.response.creditNoteDetails.forEach((e, i) => {
            if (e.tax === null) {
              e.tax = { taxIds: [] };
            }
            const it = items.filter((v) => v.value === e.itemId);
            if (it.length > 0) {
              e.itemCategoryId = it[0].dependentvalue;
            }
            const itemsInIndent = itemList.filter((v) => v.id === e.itemId);
            if (itemsInIndent.length > 0) {
              let uom = uomList.filter(
                (v) => v.value === itemsInIndent[0].purchaseUOMId
              );
              if (uom.length > 0) e.uomName = uom[0].name;
            }
          });

          let basicTotal = 0.0;
          resp.response.creditNoteDetails.forEach((e) => {
            e.basicCost = Number(APIUTILS.Financial(e.basicCost));
            e.taxCost = Number(APIUTILS.Financial(e.taxCost));
            e.totalCost = Number(APIUTILS.Financial(e.totalCost));
            basicTotal += Number(e.totalCost);
          });

          const requiredSupplier = supplierProps.options.filter(
            (x) => x.value === resp.response.supplierId
          );
          setSupplier(
            requiredSupplier.length > 0
              ? requiredSupplier[0]
              : { value: 0, name: "" }
          );

          const requiredSupplierCategory = supplierCategoryProps.options.filter(
            (x) =>
              x.value ===
              (requiredSupplier.length > 0
                ? requiredSupplier[0].dependentvalue
                : 0)
          );
          setSupplierCategory(
            requiredSupplierCategory.length > 0
              ? requiredSupplierCategory[0]
              : { value: 0, name: "" }
          );

          const requiredSite = siteProps.options.filter(
            (x) => x.value === resp.response.siteId
          );
          setSite(
            requiredSite.length > 0 ? requiredSite[0] : { value: 0, name: "" }
          );

          const requiredSiteCategory = siteCategoryProps.options.filter(
            (x) =>
              x.value ===
              (requiredSite.length > 0 ? requiredSite[0].dependentvalue : 0)
          );
          setSiteCategory(
            requiredSiteCategory.length > 0
              ? requiredSiteCategory[0]
              : { value: 0, name: "" }
          );

          if (resp.response.goodsReceivedId > 0) {
            APIMATERIALMGMT.GoodsReceivedOptions({
              supplierId: resp.response.supplierId,
              siteId: resp.response.siteId,
            })
              .then((resp) => resp.json())
              .then((y) => {
                if (y.statusCode !== 200) return;
                setGrnOptions(y.response);
                const requiredPO = y.response.filter(
                  (x) => x.value === resp.response.goodsReceivedId
                );
                setGRN(
                  requiredPO.length > 0 ? requiredPO[0] : { value: 0, name: "" }
                );
              });
          }

          let totalCost = 0.0;
          resp.response.creditNoteDetails.forEach((e) => {
            totalCost += Number.parseFloat(APIUTILS.Financial(e.totalCost));
          });

          totalCost = Number(APIUTILS.Financial(totalCost));
          let grandTotal = totalCost;

          grandTotal = Number(APIUTILS.Financial(grandTotal));

          gridRef.current.api.applyTransaction({
            add: resp.response.creditNoteDetails,
          });

          setDTO({
            ...dto,
            id: resp.response.id,
            billNo: resp.response.billNo,
            creditNoteGRNNumber: resp.response.creditNoteGRNNumber,
            goodsReceivedId: resp.response.goodsReceivedId,
            creditNoteNumber: resp.response.creditNoteNumber,
            purchaseOrderId: resp.response.purchaseOrderId,
            date: resp.response.date
              ? resp.response.date.split("-").reverse().join("-")
              : dayjs("1901-01-01"),
            billDate: resp.response.billDate
              ? resp.response.billDate.split("-").reverse().join("-")
              : dayjs("1901-01-01"),
            supplierId: resp.response.supplierId,
            siteId: resp.response.siteId,
            taxAmount: resp.response.taxAmount,
            grandTotal: grandTotal,
            status: resp.response.status,
            description: resp.response.description,
            transportationCost: resp.response.transportationCost,
            loadingCharge: resp.response.loadingCharge,
            basicCost: APIUTILS.Financial(basicTotal),
            taxCost: resp.response.taxAmount,
            totalCost: totalCost,
          });
        });
    } else {
      gridRef.current.api.applyTransaction({ add: gridData });
    }
  };

  const [dataReady, setDataReady] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  return (
    <SideNav
      currentComponent={MATERIAL_MANAGEMENT}
      subComponent={CREDIT_NOTE}
      subComponentPath={PATH_CREDIT_NOTE}
      detailComponent={CREDIT_NOTE_DETAIL}
    >
      {openModal && (
        <Modal
          style={{ align: "center" }}
          setOpenModal={setOpenModal}
          onApply={onApplyValue}
          rowIndex={currentIndex}
          model={formData}
          formTitle={"Tax"}
        ></Modal>
      )}

      {/* below code is for grey background contents */}
      <div>
        <form>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            {(id <= 0 || isEdit) && (
              <Button
                style={{
                  backgroundColor: SPANISH_GREEN,
                  textTransform: "capitalize",
                  fontSize: 14,
                  color: WHITE,
                  minWidth: "180px",
                  minHeight: "45px",
                }}
                onClick={onFormSubmit}
              >
                {SAVE}
              </Button>
            )}

            {id <= 0 && (
              <Button
                variant="outlined"
                sx={{
                  borderColor: SPANISH_GREEN,
                }}
                style={{
                  backgroundColor: LIGHT_GREY_BACKGROUND,
                  textTransform: "capitalize",
                  fontSize: 14,
                  color: SPANISH_GREEN,
                  minWidth: "180px",
                  minHeight: "45px",
                  marginRight: 10,
                }}
                onClick={onClear}
              >
                {CLEAR}
              </Button>
            )}
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                //backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={() => {
                navigate(PATH_CREDIT_NOTE + "/" + location.state.date);
              }}
            >
              {CANCEL}
            </Button>
          </div>
          <div
            style={{
              backgroundColor: LIGHT_GREY_BACKGROUND,
              borderRadius: "5px",
              minWidth: "90%",
              minHeight: "50px",
              padding: 20,
              marginBottom: 10,
              borderStyle: "solid",
              border: "1x",
              borderColor: "#eaeaea",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography
                color={CHARLESTON_GREEN}
                style={{
                  fontWeight: 600,
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "left",
                }}
              >
                {C_GRN_No}
                {id > 0 ? ` ${dto.creditNoteGRNNumber}` : " Auto Generated"}
              </Typography>
              <Typography
                color={CHARLESTON_GREEN}
                style={{
                  fontWeight: 600,
                  fontFamily: "Inter",
                  fontSize: "16px",
                  //fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "left",
                }}
              ></Typography>
            </div>
            <table style={{ width: "100%" }}>
              <TableBody>
                <tr>
                  <td>
                    {id > 0 ? (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...supplierCategoryProps}
                        disabled={disableFetch}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="supplierCategoryId"
                        id="auto-complete-1"
                        value={supplierCategory}
                        isOptionEqualToValue={(o, v) => o.value === v.value}
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, supplierCategoryId: v?.value });
                            else
                              setDTO({
                                ...dto,
                                supplierCategoryId: Number(v?.value),
                              });
                          } else {
                            setDTO({ ...dto, supplierCategoryId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Supplier Category"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...supplierCategoryProps}
                        disabled={disableFetch}
                        id="auto-complete-2"
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="supplierCategoryId"
                        defaultValue={
                          supplierCategoryProps.options.find(
                            (v) => v.value === dto.supplierCategoryId
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, supplierCategoryId: v?.value });
                            else
                              setDTO({
                                ...dto,
                                supplierCategoryId: Number(v?.value),
                              });
                          } else {
                            setDTO({ ...dto, supplierCategoryId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Supplier Category"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                  <td>
                    {id > 0 ? (
                      <Autocomplete
                        {...supplierProps}
                        disabled={disableFetch}
                        id="auto-complete-3"
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="supplierId"
                        value={supplier}
                        isOptionEqualToValue={(o, v) => {
                          return o.value === v.value;
                        }}
                        filterOptions={(o, s) => {
                          let options = globalFilter(o, s);
                          let newOptions = [];
                          options.forEach((element) => {
                            if (
                              element.dependentvalue === dto.supplierCategoryId
                            )
                              newOptions.push(element);
                          });
                          return newOptions;
                        }}
                        onChange={(e, v) => {
                          if (disableFetch) {
                            setAlertTitleText("Oops..", "Cannot refetch GRN");
                            return;
                          }
                          if (v) {
                            if (v.dependentvalue !== dto.supplierCategoryId)
                              return;

                            if (isNaN(v?.value))
                              setDTO({ ...dto, supplierId: v?.value });
                            else
                              setDTO({ ...dto, supplierId: Number(v?.value) });
                          } else {
                            setDTO({ ...dto, supplierId: v?.value });
                          }

                          if (v?.value > 0) {
                            if (v?.value > 0 && dto.siteId > 0) {
                              APIMATERIALMGMT.GoodsReceivedOptions({
                                supplierId: v?.value,
                                siteId: dto.siteId,
                              })
                                .then((resp) => resp.json())
                                .then((resp) => {
                                  if (resp.statusCode !== 200) return;
                                  setGrnOptions(resp.response);
                                });
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Supplier Name"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        {...supplierProps}
                        disabled={disableFetch}
                        id="auto-complete-4"
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="supplierId"
                        defaultValue={
                          supplierProps.options.find(
                            (v) => v.value === dto.supplierId
                          ) || {}
                        }
                        filterOptions={(o, s) => {
                          let options = globalFilter(o, s);
                          let newOptions = [];
                          options.forEach((element) => {
                            if (
                              element.dependentvalue === dto.supplierCategoryId
                            )
                              newOptions.push(element);
                          });
                          return newOptions;
                        }}
                        onChange={(e, v) => {
                          if (v) {
                            if (v.dependentvalue !== dto.supplierCategoryId)
                              return;

                            if (isNaN(v?.value))
                              setDTO({ ...dto, supplierId: v?.value });
                            else
                              setDTO({ ...dto, supplierId: Number(v?.value) });
                          } else {
                            if (v?.value > 0) {
                              if (v?.value > 0 && dto.siteId > 0) {
                                APIMATERIALMGMT.GoodsReceivedOptions({
                                  supplierId: v?.value,
                                  siteId: dto.siteId,
                                })
                                  .then((resp) => resp.json())
                                  .then((resp) => {
                                    if (resp.statusCode !== 200) return;
                                    setGrnOptions(resp.response);
                                  });
                              }
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Supplier Name"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                  <td>
                    {id > 0 ? (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...siteCategoryProps}
                        disabled={disableFetch}
                        id="auto-complete-5"
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="siteCategoryId"
                        value={siteCategory}
                        isOptionEqualToValue={(o, v) => {
                          return o.value === v.value;
                        }}
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, siteCategoryId: v?.value });
                            else
                              setDTO({
                                ...dto,
                                siteCategoryId: Number(v?.value),
                              });
                          } else {
                            setDTO({ ...dto, siteCategoryId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Site Category"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...siteCategoryProps}
                        disabled={disableFetch}
                        id="auto-complete-6"
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="siteCategoryId"
                        defaultValue={
                          supplierProps.options.find(
                            (v) => v.value === dto.siteCategoryId
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, siteCategoryId: v?.value });
                            else
                              setDTO({
                                ...dto,
                                siteCategoryId: Number(v?.value),
                              });
                          } else {
                            setDTO({ ...dto, siteCategoryId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Site Category"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {id > 0 ? (
                      <Autocomplete
                        {...siteProps}
                        disabled={disableFetch}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        id="auto-complete-7"
                        name="siteId"
                        value={site}
                        isOptionEqualToValue={(o, v) => {
                          return o.value === v.value;
                        }}
                        filterOptions={(o, s) => {
                          let options = globalFilter(o, s);
                          let newOptions = [];
                          options.forEach((element) => {
                            if (element.dependentvalue === dto.siteCategoryId)
                              newOptions.push(element);
                          });
                          return newOptions;
                        }}
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, siteId: v?.value });
                            else setDTO({ ...dto, siteId: Number(v?.value) });
                          } else {
                            setDTO({ ...dto, siteId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Site"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        {...siteProps}
                        disabled={disableFetch}
                        id="auto-complete-8"
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="siteId"
                        filterOptions={(o, s) => {
                          let options = globalFilter(o, s);
                          let newOptions = [];
                          options.forEach((element) => {
                            if (element.dependentvalue === dto.siteCategoryId)
                              newOptions.push(element);
                          });
                          return newOptions;
                        }}
                        defaultValue={
                          siteProps.options.find(
                            (v) => v.value === dto.siteId
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (disableFetch) {
                            setAlertTitleText("Oops..", "Cannot refetch GRN");
                            return;
                          }

                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, siteId: v?.value });
                            else setDTO({ ...dto, siteId: Number(v?.value) });
                          } else {
                            setDTO({ ...dto, siteId: v?.value });
                          }

                          if (v?.value > 0) {
                            if (dto.supplierId > 0 && v?.value > 0) {
                              APIMATERIALMGMT.GoodsReceivedOptions({
                                supplierId: dto.supplierId,
                                siteId: v?.value,
                              })
                                .then((resp) => resp.json())
                                .then((resp) => {
                                  if (resp.statusCode !== 200) return;
                                  setGrnOptions(resp.response);
                                });
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Site"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                  <td>
                    {id > 0 ? (
                      <Autocomplete
                        filterOptions={globalFilter}
                        options={grnOptions}
                        getOptionLabel={(option) => option?.name || ""}
                        id="auto-complete-9"
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        disabled={disableFetch}
                        value={grn}
                        isOptionEqualToValue={(o, v) => {
                          return o.value === v.value;
                        }}
                        defaultValue={
                          grnOptions.find(
                            (v) => v.value === dto.goodsReceivedId
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (v?.value) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, goodsReceivedId: v?.value });
                            else
                              setDTO({
                                ...dto,
                                goodsReceivedId: Number(v?.value),
                              });
                            dto.creditNoteGRNNumber = v?.name;
                            fetchGRN(v?.value);
                          } else {
                            setDTO({ ...dto, goodsReceivedId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="GRN No"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        filterOptions={globalFilter}
                        options={grnOptions}
                        getOptionLabel={(option) => option?.name || ""}
                        id="auto-complete-10"
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        disabled={disableFetch}
                        defaultValue={
                          grnOptions.find(
                            (v) => v.value === dto.goodsReceivedId
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (v?.value) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, goodsReceivedId: v?.value });
                            else
                              setDTO({
                                ...dto,
                                goodsReceivedId: Number(v?.value),
                              });
                            dto.creditNoteGRNNumber = v?.name;
                            fetchGRN(v?.value);
                          } else {
                            setDTO({ ...dto, goodsReceivedId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="GRN No"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        disabled={id > 0}
                        label="Credit Note Date "
                        inputFormat="DD-MM-YYYY"
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dto.date}
                        maxDate={dayjs()}
                        onChange={(newValue) => {
                          setDTO({ ...dto, date: newValue });
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextField
                      inputProps={{
                        maxlength: 16,
                        style: { fontSize: 14 },
                      }}
                      variant="standard"
                      disabled={id > 0}
                      label="Bill No"
                      required
                      style={{
                        minWidth: "200px",
                        minHeight: "48.5px",
                        width: "200px",
                      }}
                      name="billNo"
                      value={dto.billNo}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        style: { color: "grey", fontSize: 14 },
                      }}
                      InputProps={{ style: { fontSize: 14 } }}
                    ></TextField>
                  </td>
                  <td>
                    <TextField
                      inputProps={{
                        maxlength: 16,
                        style: { fontSize: 14 },
                      }}
                      variant="standard"
                      disabled={id > 0}
                      label="Credit Note No"
                      style={{
                        minWidth: "200px",
                        minHeight: "48.5px",
                        width: "200px",
                      }}
                      name="creditNoteNumber"
                      value={dto.creditNoteNumber}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        style: { color: "grey", fontSize: 14 },
                      }}
                      InputProps={{ style: { fontSize: 14 } }}
                    ></TextField>
                  </td>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Bill Date "
                        disabled={id > 0}
                        inputFormat="DD-MM-YYYY"
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        name="billDate"
                        value={dto.billDate}
                        maxDate={dayjs()}
                        onChange={(newValue) => {
                          setDTO({ ...dto, billDate: newValue });
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextField
                      variant="standard"
                      label="Grand Total"
                      disabled={true}
                      style={{
                        minWidth: "200px",
                        minHeight: "48.5px",
                        width: "200px",
                      }}
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setDTO({ ...dto, [name]: value });
                      }}
                      onWheel={(event) => event.target.blur()}
                      name="grandTotal"
                      value={dto.grandTotal}
                      onBlur={handleInputChange}
                      InputLabelProps={{
                        style: { color: "grey", fontSize: 14 },
                      }}
                      InputProps={{ style: { fontSize: 14 } }}
                    ></TextField>
                  </td>
                </tr>
              </TableBody>
            </table>
          </div>
        </form>
      </div>
      {/* below code is for purchase order dynamic row  addition deletion*/}
      {id > 0 ? (
        <div className="App">
          <div className="ag-theme-alpine" style={{ height: "570px" }}>
            <AgGridReact
              copyGroupHeadersToClipboard={true}
              enableRangeSelection={true}
              columnDefs={columnDefs}
              ref={gridRef}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              paginationAutoPageSize={true}
              pagination={true}
              rowSelection="multiple"
              getRowId={(params) => params.data.id}
              onSelectionChanged={onRowSelectionChanged}
            ></AgGridReact>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: WHITE,
            borderRadius: "5px",
            minWidth: "90%",
            minHeight: "50px",
            marginBottom: 10,
            border: "0.1px solid #eaeaea",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            overflowX: "auto",
          }}
        >
          <div
            style={{
              width: "1220px",
            }}
          >
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
              }}
            >
              <TableBody>
                <tr
                  style={{
                    backgroundColor: LIGHT_GREY_BACKGROUND,
                    borderBottom: "0.001pt solid #f0eff4",
                    borderTop: "0.001pt solid #f0eff4",
                  }}
                >
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {ITEM_CATEGORY}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {ITEM_NAME}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {STOCK}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {RETURN_QTY}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {RATE}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {BASIC_COST}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {TAX}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {TAX_COST}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {TOTAL_COST}
                    </Typography>
                  </th>

                  <th></th>
                  <td></td>
                </tr>
                <tr>
                  {rowItems?.length <= 0 && (
                    <IconButton
                      style={{ marginTop: 10 }}
                      id={`button-plus-default`}
                      aria-label="plus"
                      onClick={() => {
                        addObjectToArray();
                      }}
                    >
                      <img src={plus_icon} alt="" />
                    </IconButton>
                  )}
                </tr>
                {rowItems?.map((item, index) => {
                  return (
                    <tr key={item.rowId}
                      style={{
                        borderBottom: "0.001pt solid #f0eff4",
                      }}
                    >
                      <td>
                        <Autocomplete
                          filterOptions={globalFilter}
                          id={`autocomplete-1-${index}`}
                          {...itemCategoryProps}
                          variant="standard"
                          style={{
                            minWidth: "160px",
                            minHeight: "38.5px",
                            width: "160px",
                            height: "38.5px",
                          }}
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          disabled={id > 0}
                          defaultValue={
                            itemCategoryProps.options.find(
                              (v) => v.value === rowItems[index].itemCategoryId
                            ) || {}
                          }
                          onChange={(e, v) => {
                            if (dto.supplierId <= 0) {
                              setAlertTitleText(
                                "Warning",
                                "Please Select Supplier..."
                              );
                              return;
                            }

                            let newArr = [...rowItems];
                            newArr[index].itemCategoryId = Number(v?.value);
                            setRowItems(newArr);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Item Category"
                              variant="standard"
                              style={{
                                minWidth: "190px",
                                minHeight: "38.5px",
                                width: "190px",
                                height: "38.5px",
                                marginBottom: 5,
                                marginLeft: 5,
                              }}
                              {...params}
                              InputLabelProps={{
                                style: {
                                  color: "grey",
                                  fontSize: 12,
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 12 },
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <Autocomplete
                          id={`autocomplete-2-${index}`}
                          {...itemProps}
                          autoComplete
                          autoHighlight
                          autoSelect
                          variant="standard"
                          disabled={id > 0}
                          style={{
                            minWidth: "160px",
                            minHeight: "38.5px",
                            width: "160px",
                            height: "38.5px",
                          }}
                          defaultValue={
                            itemProps.options.find(
                              (v) => v?.value === rowItems[index].itemId
                            ) || {}
                          }
                          filterOptions={(o, s) => {
                            let options = globalFilter(o, s);
                            let newOptions = [];
                            options.forEach((element) => {
                              if (
                                element.dependentvalue ===
                                rowItems[index].itemCategoryId
                              )
                                newOptions.push(element);
                            });
                            return newOptions;
                          }}
                          onChange={(e, v) => {
                            let newArr = [...rowItems];
                            newArr[index].itemId = Number(v?.value);
                            const filteredItems = itemList.filter(
                              (x) => x.id === newArr[index].itemId
                            );
                            if (filteredItems.length > 0) {
                              uomList.forEach((element) => {
                                if (
                                  element.value ===
                                  filteredItems[0].purchaseUOMId
                                )
                                  newArr[index].uomName = element.name;
                              });
                            }
                            setRowItems(newArr);
                          }}
                          name="itemId"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Item Name"
                              variant="standard"
                              style={{
                                minWidth: "190px",
                                minHeight: "38.5px",
                                width: "190px",
                                height: "38.5px",
                              }}
                              {...params}
                              InputLabelProps={{
                                style: {
                                  color: "grey",
                                  fontSize: 12,
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 12 },
                              }}
                            />
                          )}
                        ></Autocomplete>
                      </td>
                      <td>
                        <TextField
                          id={`textfield-3-${index}`}
                          variant="standard"
                          label="Stock"
                          disabled={true}
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "90px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "90px",
                            fontSize: 10,
                            marginBottom: 5,
                            marginLeft: 5,
                          }}
                          name="stockQty"
                          value={item.stockQty}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 12 },
                          }}
                          InputProps={{
                            style: { fontSize: 12 },
                          }}
                        ></TextField>
                      </td>
                      <td>
                        <TextField
                          variant="standard"
                          label="Return Qty"
                          disabled={id > 0}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = e.target.value;
                            setRowItems(newArr);
                          }}
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "90px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "90px",
                            fontSize: 10,
                          }}
                          name="returnQty"
                          value={rowItems[index].returnQty}
                          onBlur={(e) => {
                            if (e.target.value > rowItems[index].stockQty) {
                              setAlertTitleText(
                                "Warning",
                                "Return qty cannot be more than stock qty"
                              );
                            } else {
                              let newArr = [...rowItems];
                              newArr[index][e.target.name] = Number(
                                e.target.value
                              );
                              newArr[index].basicCost = Number(
                                APIUTILS.Financial(
                                  Number(e.target.value) * newArr[index].rate
                                )
                              );

                              if (newArr[index].basicCost === 0)
                                newArr[index].taxCost = 0;

                              if (
                                newArr[index].tax !== null &&
                                newArr[index].tax.taxIds.length > 0 &&
                                newArr[index].basicCost !== 0
                              ) {
                                APIUTILS.CalculateTax({
                                  taxIds: newArr[index].tax.taxIds,
                                  basicAmount: newArr[index].basicCost,
                                })
                                  .then((resp) => resp.json())
                                  .then((resp) => {
                                    if (resp.statusCode !== 200) {
                                      setAlertTitleText(
                                        "Oops...",
                                        "Cannot calculate tax"
                                      );
                                      return;
                                    }
                                    newArr[index].tax = resp.response;
                                    newArr[index].taxCost = Number.parseFloat(
                                      APIUTILS.Financial(
                                        Number(resp.response.taxValue)
                                      )
                                    );
                                    newArr[index].totalCost = Number.parseFloat(
                                      APIUTILS.Financial(
                                        Number(resp.response.totalValue)
                                      )
                                    );
                                    setRowItems(newArr);
                                    let grandTotal = 0.0;
                                    dto.basicCost = 0.0;
                                    dto.taxCost = 0.0;
                                    dto.totalCost = 0.0;

                                    newArr.forEach((e) => {
                                      dto.basicCost += Number.parseFloat(
                                        APIUTILS.Financial(e.basicCost)
                                      );
                                      dto.taxCost += Number.parseFloat(
                                        APIUTILS.Financial(e.taxCost)
                                      );
                                      dto.totalCost += Number.parseFloat(
                                        APIUTILS.Financial(e.totalCost)
                                      );
                                      grandTotal += Number.parseFloat(
                                        APIUTILS.Financial(e.totalCost)
                                      );
                                    });
                                    dto.basicCost = Number(
                                      APIUTILS.Financial(dto.basicCost)
                                    );
                                    dto.taxCost = Number(
                                      APIUTILS.Financial(dto.taxCost)
                                    );
                                    dto.totalCost = Number(
                                      APIUTILS.Financial(dto.totalCost)
                                    );
                                    grandTotal += Number.parseFloat(
                                      APIUTILS.Financial(dto.loadingCharge)
                                    );
                                    grandTotal += Number.parseFloat(
                                      APIUTILS.Financial(dto.transportationCost)
                                    );
                                    setDTO({
                                      ...dto,
                                      grandTotal: Number.parseFloat(
                                        APIUTILS.Financial(grandTotal)
                                      ),
                                    });
                                  });
                              } else {
                                setRowItems(newArr);
                                let grandTotal = 0.0;
                                dto.basicCost = 0.0;
                                dto.taxCost = 0.0;
                                dto.totalCost = 0.0;

                                newArr.forEach((e) => {
                                  dto.basicCost += Number.parseFloat(
                                    APIUTILS.Financial(e.basicCost)
                                  );
                                  dto.taxCost += Number.parseFloat(
                                    APIUTILS.Financial(e.taxCost)
                                  );
                                  dto.totalCost += Number.parseFloat(
                                    APIUTILS.Financial(e.totalCost)
                                  );
                                  grandTotal += Number.parseFloat(
                                    APIUTILS.Financial(e.totalCost)
                                  );
                                });
                                dto.basicCost = Number(
                                  APIUTILS.Financial(dto.basicCost)
                                );
                                dto.taxCost = Number(
                                  APIUTILS.Financial(dto.taxCost)
                                );
                                dto.totalCost = Number(
                                  APIUTILS.Financial(dto.totalCost)
                                );
                                grandTotal += Number.parseFloat(
                                  APIUTILS.Financial(dto.loadingCharge)
                                );
                                grandTotal += Number.parseFloat(
                                  APIUTILS.Financial(dto.transportationCost)
                                );
                                setDTO({
                                  ...dto,
                                  grandTotal: Number.parseFloat(
                                    APIUTILS.Financial(grandTotal)
                                  ),
                                });
                              }
                            }
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 12 },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {rowItems[index].uomName}
                              </InputAdornment>
                            ),
                            style: { fontSize: 12 },
                          }}
                        ></TextField>
                      </td>
                      <td>
                        <TextField
                          id={`textfield-5-${index}`}
                          variant="standard"
                          label="Rate"
                          disabled={true}
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "90px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "90px",
                            fontSize: 10,
                            marginBottom: 5,
                            marginLeft: 5,
                          }}
                          name="rate"
                          value={rowItems[index].rate}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 12 },
                          }}
                          InputProps={{
                            style: { fontSize: 12 },
                          }}
                        ></TextField>
                      </td>
                      <td>
                        <TextField
                          id={`textfield-6-${index}`}
                          variant="standard"
                          label="Basic Cost"
                          disabled={true}
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "90px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "90px",
                            fontSize: 10,
                            marginBottom: 5,
                            marginLeft: 5,
                          }}
                          name="basicCost"
                          value={rowItems[index].basicCost}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 12 },
                          }}
                          InputProps={{
                            style: { fontSize: 12 },
                          }}
                        ></TextField>
                      </td>
                      <td>
                        <IconButton
                          disabled={id > 0}
                          style={{
                            marginTop: 10,

                            marginRight: 10,
                          }}
                          id={`button-plus-${index}`}
                          aria-label="plus"
                          onClick={() => {
                            setCurrentIndex(index);
                            setFormData(rowItems[index]);
                            setOpenModal(true);
                          }}
                        >
                          <img src={apply_icon} alt="" />
                        </IconButton>
                      </td>
                      <td>
                        <TextField
                          id={`textfield-7-${index}`}
                          variant="standard"
                          disabled={true}
                          label="Tax Cost"
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "90px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "90px",
                            fontSize: 10,
                            marginBottom: 5,
                            marginLeft: 5,
                          }}
                          name="taxCost"
                          value={rowItems[index].taxCost}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 12 },
                          }}
                          InputProps={{
                            style: { fontSize: 12 },
                          }}
                        ></TextField>
                      </td>
                      <td>
                        <TextField
                          id={`textfield-8-${index}`}
                          variant="standard"
                          label="Total Cost"
                          disabled={true}
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "90px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "90px",
                            fontSize: 10,
                            marginBottom: 5,
                            marginLeft: 5,
                          }}
                          name="totalCost"
                          value={rowItems[index].totalCost}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 12 },
                          }}
                          InputProps={{
                            style: { fontSize: 12 },
                          }}
                        ></TextField>
                      </td>

                      <td>
                        {id <= 0 && index >= 0 && (
                          <IconButton
                            style={{ marginTop: 10 }}
                            aria-label="minus"
                            onClick={() => {
                              removeObjectFromArray(item.rowId);
                            }}
                          >
                            <img src={minus_icon} alt="" />
                          </IconButton>
                        )}
                      </td>
                      <td>
                        {id <= 0 && (
                          <IconButton
                            style={{ marginTop: 10 }}
                            id={`button-plus-${index}`}
                            aria-label="plus"
                            onClick={() => {
                              addObjectToArray();
                            }}
                          >
                            <img src={plus_icon} alt="" />
                          </IconButton>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </TableBody>
            </table>
          </div>
        </div>
      )}
      <div
        style={{
          backgroundColor: WHITE,
          borderRadius: "5px",
          minWidth: "90%",
          minHeight: "50px",
          marginBottom: 10,
          border: "0.1px solid #eaeaea",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflowX: "auto",
        }}
      >
        <div
          style={{
            width: "1220px",
          }}
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <TableBody>
              <th align="left">
                <Typography
                  color={PRUSSIAN_BLUE}
                  style={{
                    flexGrow: 0,
                    fontFamily: "Inter",
                    fontSize: 14,
                    fontstretch: "normal",
                    fontStyle: "normal",
                    userSelect: "none",
                    lineHeight: 1.32,
                    letterSpacing: "normal",
                    marginTop: 20,
                    marginBottom: 10,
                    marginLeft: 5,
                    fontWeight: 600,
                  }}
                ></Typography>
              </th>
              <th align="left">
                <Typography
                  color={PRUSSIAN_BLUE}
                  style={{
                    flexGrow: 0,
                    fontFamily: "Inter",
                    fontSize: 14,
                    fontstretch: "normal",
                    fontStyle: "normal",
                    userSelect: "none",
                    lineHeight: 1.32,
                    letterSpacing: "normal",
                    marginTop: 20,
                    marginBottom: 10,
                    marginLeft: 5,
                    fontWeight: 600,
                  }}
                ></Typography>
              </th>
              <th align="left">
                <Typography
                  color={PRUSSIAN_BLUE}
                  style={{
                    flexGrow: 0,
                    fontFamily: "Inter",
                    fontSize: 14,
                    fontstretch: "normal",
                    fontStyle: "normal",
                    userSelect: "none",
                    lineHeight: 1.32,
                    letterSpacing: "normal",
                    marginTop: 20,
                    marginBottom: 10,
                    marginLeft: 5,
                    fontWeight: 600,
                  }}
                ></Typography>
              </th>
              <th align="left">
                <Typography
                  color={PRUSSIAN_BLUE}
                  style={{
                    flexGrow: 0,
                    fontFamily: "Inter",
                    fontSize: 14,
                    fontstretch: "normal",
                    fontStyle: "normal",
                    userSelect: "none",
                    lineHeight: 1.32,
                    letterSpacing: "normal",
                    marginTop: 20,
                    marginBottom: 10,
                    marginLeft: 5,
                    fontWeight: 600,
                  }}
                ></Typography>
              </th>
              <th align="left">
                <Typography
                  color={PRUSSIAN_BLUE}
                  style={{
                    flexGrow: 0,
                    fontFamily: "Inter",
                    fontSize: 14,
                    fontstretch: "normal",
                    fontStyle: "normal",
                    userSelect: "none",
                    lineHeight: 1.32,
                    letterSpacing: "normal",
                    marginTop: 20,
                    marginBottom: 10,
                    marginLeft: 5,
                    fontWeight: 600,
                  }}
                ></Typography>
              </th>
              <th align="left">
                <Typography
                  color={PRUSSIAN_BLUE}
                  style={{
                    flexGrow: 0,
                    fontFamily: "Inter",
                    fontSize: 14,
                    fontstretch: "normal",
                    fontStyle: "normal",
                    userSelect: "none",
                    lineHeight: 1.32,
                    letterSpacing: "normal",
                    marginTop: 20,
                    marginBottom: 10,
                    marginLeft: 5,
                    fontWeight: 600,
                  }}
                ></Typography>
              </th>
              <th align="left">
                <Typography
                  color={PRUSSIAN_BLUE}
                  style={{
                    flexGrow: 0,
                    fontFamily: "Inter",
                    fontSize: 14,
                    fontstretch: "normal",
                    fontStyle: "normal",
                    userSelect: "none",
                    lineHeight: 1.32,
                    letterSpacing: "normal",
                    marginTop: 20,
                    marginBottom: 10,
                    marginLeft: 5,
                    fontWeight: 600,
                  }}
                ></Typography>
              </th>
              <th align="left">
                <Typography
                  color={PRUSSIAN_BLUE}
                  style={{
                    flexGrow: 0,
                    fontFamily: "Inter",
                    fontSize: 14,
                    fontstretch: "normal",
                    fontStyle: "normal",
                    userSelect: "none",
                    lineHeight: 1.32,
                    letterSpacing: "normal",
                    marginTop: 20,
                    marginBottom: 10,
                    marginLeft: 5,
                    fontWeight: 600,
                  }}
                ></Typography>
              </th>
              <th align="left">
                <Typography
                  color={PRUSSIAN_BLUE}
                  style={{
                    flexGrow: 0,
                    fontFamily: "Inter",
                    fontSize: 14,
                    fontstretch: "normal",
                    fontStyle: "normal",
                    userSelect: "none",
                    lineHeight: 1.32,
                    letterSpacing: "normal",
                    marginTop: 20,
                    marginBottom: 10,
                    marginLeft: 5,
                    fontWeight: 600,
                  }}
                ></Typography>
              </th>
              <tr style={{ height: "30px" }}></tr>
              <tr>
                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      flexGrow: 0,
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                    }}
                  >
                    {TRANSPORTATION_COST}
                  </Typography>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <input
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setDTO({ ...dto, [name]: value });
                    }}
                    onWheel={(event) => event.target.blur()}
                    name="transportationCost"
                    value={dto.transportationCost}
                    onBlur={(e) => {
                      let grandTotal = 0.0;
                      dto.basicCost = 0.0;
                      dto.taxCost = 0.0;
                      dto.totalCost = 0.0;

                      rowItems.forEach((e) => {
                        dto.basicCost += Number.parseFloat(
                          APIUTILS.Financial(e.basicCost)
                        );
                        dto.taxCost += Number.parseFloat(
                          APIUTILS.Financial(e.taxCost)
                        );
                        dto.totalCost += Number.parseFloat(
                          APIUTILS.Financial(e.totalCost)
                        );
                        grandTotal += Number.parseFloat(
                          APIUTILS.Financial(e.totalCost)
                        );
                      });
                      dto.basicCost = Number(APIUTILS.Financial(dto.basicCost));
                      dto.taxCost = Number(APIUTILS.Financial(dto.taxCost));
                      dto.totalCost = Number(APIUTILS.Financial(dto.totalCost));
                      if (!isNaN(e.target.value))
                        dto.transportationCost = Number(e.target.value);

                      grandTotal += Number.parseFloat(
                        APIUTILS.Financial(dto.loadingCharge)
                      );
                      grandTotal += Number.parseFloat(
                        APIUTILS.Financial(dto.transportationCost)
                      );
                      setDTO({
                        ...dto,
                        grandTotal: Number.parseFloat(
                          APIUTILS.Financial(grandTotal)
                        ),
                      });
                    }}
                    disabled={id > 0 && !isEdit}
                    style={{ width: "100px" }}
                  ></input>
                </td>
              </tr>
              <tr
                style={{
                  backgroundColor: ANTI_FLASH_WHITE,
                  height: "40px",
                }}
              >
                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      flexGrow: 0,
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                    }}
                  >
                    {TOTAL}
                  </Typography>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      marginTop: 10,
                      flexGrow: 0,
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                      //marginRight: 10,
                      marginLeft: 5,
                    }}
                  >
                    ₹ {dto.basicCost}
                  </Typography>
                </td>
                <td></td>

                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      marginTop: 10,
                      flexGrow: 0,
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                      marginLeft: 5,
                    }}
                  >
                    ₹ {dto.taxCost}
                  </Typography>
                </td>
                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      marginTop: 10,
                      flexGrow: 0,
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                      marginLeft: 5,
                    }}
                  >
                    ₹ {dto.totalCost}
                  </Typography>
                </td>
              </tr>
            </TableBody>
          </table>
        </div>
      </div>
    </SideNav>
  );
}
