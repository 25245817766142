import { Button, Divider, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import avatar_icon from "../../../src/resources/assets/images/avatar.jpg";
import burger_icon from "../../../src/resources/assets/images/burger_icon.svg";
import customer_active_icon from "../../../src/resources/assets/images/customer_active_icon.svg";
import customer_icon from "../../../src/resources/assets/images/customer_icon.svg";
import dashboard_active_icon from "../../../src/resources/assets/images/dashboard_active_icon.svg";
import dashboard_icon from "../../../src/resources/assets/images/dashboard_icon.svg";
import iconDown from "../../../src/resources/assets/images/iconDown.svg";
import iconUp from "../../../src/resources/assets/images/iconUp.svg";
import labour_active_icon from "../../../src/resources/assets/images/labour_active_icon.svg";
import labour_icon from "../../../src/resources/assets/images/labour_icon.svg";
import logout_icon from "../../../src/resources/assets/images/logout_icon.svg";
import master_active_icon from "../../../src/resources/assets/images/master_active_icon.svg";
import master_icon from "../../../src/resources/assets/images/master_icon.svg";
import material_active_icon from "../../../src/resources/assets/images/material_active_icon.svg";
import material_icon from "../../../src/resources/assets/images/material_icon.svg";
import reports_active_icon from "../../../src/resources/assets/images/reports_active_icon.svg";
import report_icon from "../../../src/resources/assets/images/reports_icon.svg";
import user_active_icon from "../../../src/resources/assets/images/user_active_icon.svg";
import user_icon from "../../../src/resources/assets/images/user_icon.svg";
import logo from "../../resources/assets/images/newDashboardLogo.svg";
import {
  CADET_GREY,
  CHARLESTON_GREEN,
  PRUSSIAN_BLUE,
  SPANISH_GRAY,
  SPANISH_GREEN,
  TRANSPARENT_WHITE_FOR_SUB_DIV,
  WHITE,
} from "../../resources/constant/colors/theme";
import {
  PATH_ADD_EMPLOYEE,
  PATH_ATTENDANCE_REPORT,
  PATH_BALANCE_BILLING_REPORT,
  PATH_CREDIT_NOTE,
  PATH_DAILY_WORK_ENTRY,
  PATH_DASHBOARD,
  PATH_DESIGNATION,
  PATH_EMPLOYEE_TYPE,
  PATH_EXCAVATOR_SUMMARY_REPORT,
  PATH_GOODS_RECEIVED,
  PATH_GOODS_RECEIVED_REPORT,
  PATH_INDENT,
  PATH_INVOICE,
  PATH_ITEM,
  PATH_ITEM_CATEGORIES,
  PATH_LABOURER_TYPE,
  PATH_LOGIN,
  PATH_MANAGER_ASSIGNMENT,
  PATH_MATERIAL_TRANSFER,
  PATH_PURCHASE_ORDER,
  PATH_PURCHASE_ORDER_REPORT,
  PATH_PURCHASE_PRICE_CONFIGURATION,
  PATH_PURCHASE_RATIO_REPORT,
  PATH_SALES_ORDERS,
  PATH_SITE,
  PATH_SITE_CATEGORIES,
  PATH_STOCK_STATEMENT_REPORT,
  PATH_SUB_CONTRACTOR_ADVANCE_REQUEST,
  PATH_SUB_CONTRACTOR_ADVANCE_REQUEST_SUMMARY_REPORT,
  PATH_SUB_CONTRACTOR_BILLED_QTY,
  PATH_SUB_CONTRACTOR_BILLING,
  PATH_SUB_CONTRACTOR_BILLING_REPORT,
  PATH_SUB_CONTRACTOR_BILLING_SUMMARY_REPORT,
  PATH_SUB_CONTRACTOR_FOOD_ADVANCE,
  PATH_SUB_CONTRACTOR_FOOD_ADVANCE_DETAIL_REPORT,
  PATH_SUPPLIER,
  PATH_SUPPLIER_CATEGORIES,
  PATH_TAX,
  PATH_UOM,
  PATH_USER_MANAGEMENT,
  PATH_WORK_ORDER,
} from "../../resources/constant/url/url";

import { APILOGIN } from "../../apihandler/login";
import { useAuth } from "../../hooks/useAuth";
import {
  ATTENDANCE_REPORT,
  BALANCE_BILLING_REPORT,
  CREDIT_NOTE,
  CUSTOMER_MANAGEMENT,
  CUSTOMER_MANAGEMENT_REPORT,
  DAILY_WORK_ENTRY,
  DASHBOARD,
  DESIGNATION,
  EMPLOYEE,
  EMPLOYEE_TYPE,
  EXCAVATOR_SUMMARY_REPORT,
  GOODS_RECEIVED,
  GOODS_RECEIVED_REPORT,
  HOME,
  INDENT,
  INVOICE,
  ITEM,
  ITEM_CATEGORIES,
  LABOURER_TYPE,
  LABOUR_MANAGEMENT,
  LABOUR_MANAGEMENT_REPORT,
  LOGOUT,
  MANAGER_ASSIGNMENT,
  MASTER,
  MATERIAL_MANAGEMENT,
  MATERIAL_MANAGEMENT_REPORT,
  MATERIAL_TRANSFER,
  PURCHASE_ORDER_INDENT,
  PURCHASE_ORDER_REPORT,
  PURCHASE_PRICE_CONFIGURATION,
  PURCHASE_RATIO_REPORT,
  SALES_ORDERS,
  SITE,
  SITE_CATEGORIES,
  STOCK_STATEMENT_REPORT,
  SUB_CONTRACTOR_ADVANCE_REQUEST,
  SUB_CONTRACTOR_ADVANCE_REQUEST_SUMMARY_REPORT,
  SUB_CONTRACTOR_BILLED_QTY,
  SUB_CONTRACTOR_BILLING,
  SUB_CONTRACTOR_BILLING_REPORT,
  SUB_CONTRACTOR_BILLING_SUMMARY_REPORT,
  SUB_CONTRACTOR_FOOD_ADVANCE,
  SUB_CONTRACTOR_FOOD_ADVANCE_DETAIL_REPORT,
  SUPPLIERS,
  SUPPLIER_CATEGORIES,
  TAXES,
  UOM,
  USER_MANAGEMENT,
  WORK_ORDER,
} from "../../resources/constant/strings/string";
//import { minWidth } from "@mui/system";
import bread_crum_icon from "../../resources/assets/images/bread_crum_icon.svg";
const useStyles = makeStyles(() => ({
  main: {
    display: "flex",
    flexdirection: "column",
    position: "relative",
    //overflow: "hidden",
  },
}));
export default function SideNav({
  children,
  currentComponent,
  subComponent,
  subComponentPath,
  detailComponent,
  sideBarOpenStatus = true,
}) {
  const [serverVersion, setServerVersion] = useState("v1.0.0.7-16072023");
  //let tempComponent = "";
  const [localCurrentComponent, setlocalCurrentComponent] =
    useState(currentComponent);
  const { authenticated, logout } = useAuth();
  const [sidebarOpen, setSideBarOpen] = useState(sideBarOpenStatus);
  const [activeMenu, setActiveMenu] = useState(true);

  const handleViewSidebar = () => {
    setSideBarOpen(!sidebarOpen);
  };

  const logOff = () => {
    console.log("Logging off");
    logout()
      .then((x) => {
        navigate(PATH_LOGIN);
        console.log("Logged Off");
      })
      .catch((e) => {
        console.log("Failed to Log Off");
      });
  };

  const navigate = useNavigate();
  const classes = useStyles();
  const sideBarItems = [
    {
      name: DASHBOARD,
      icon: dashboard_icon,
      path: PATH_DASHBOARD,
      activeIcon: dashboard_active_icon,
    },
    {
      name: MASTER,
      icon: master_icon,
      activeIcon: master_active_icon,

      subMenu: [
        {
          parentComponent: MASTER,
          name: UOM,
          path: PATH_UOM,
        },
        {
          parentComponent: MASTER,
          name: ITEM_CATEGORIES,
          path: PATH_ITEM_CATEGORIES,
        },
        {
          parentComponent: MASTER,
          name: ITEM,
          path: PATH_ITEM,
        },
        {
          parentComponent: MASTER,
          name: SUPPLIER_CATEGORIES,
          path: PATH_SUPPLIER_CATEGORIES,
        },
        {
          parentComponent: MASTER,
          name: SUPPLIERS,
          path: PATH_SUPPLIER,
        },
        {
          parentComponent: MASTER,
          name: SITE_CATEGORIES,
          path: PATH_SITE_CATEGORIES,
        },
        {
          parentComponent: MASTER,
          name: SITE,
          path: PATH_SITE,
        },
        {
          parentComponent: MASTER,
          name: TAXES,
          path: PATH_TAX,
        },
        {
          parentComponent: MASTER,
          name: LABOURER_TYPE,
          path: PATH_LABOURER_TYPE,
        },
        {
          parentComponent: LABOUR_MANAGEMENT,
          name: MANAGER_ASSIGNMENT,
          path: PATH_MANAGER_ASSIGNMENT,
        },
        {
          parentComponent: MASTER,
          name: EMPLOYEE_TYPE,
          path: PATH_EMPLOYEE_TYPE,
        },
        {
          parentComponent: MASTER,
          name: DESIGNATION,
          path: PATH_DESIGNATION,
        },
        {
          parentComponent: MASTER,
          name: EMPLOYEE,
          path: PATH_ADD_EMPLOYEE,
        },
      ],
    },
    {
      name: MATERIAL_MANAGEMENT,
      icon: material_icon,
      activeIcon: material_active_icon,
      subMenu: [
        {
          parentComponent: MATERIAL_MANAGEMENT,
          name: PURCHASE_PRICE_CONFIGURATION,
          path: PATH_PURCHASE_PRICE_CONFIGURATION,
        },
        {
          parentComponent: MATERIAL_MANAGEMENT,
          name: INDENT,
          path: PATH_INDENT,
        },
        {
          parentComponent: MATERIAL_MANAGEMENT,
          name: PURCHASE_ORDER_INDENT,
          path: PATH_PURCHASE_ORDER,
        },

        {
          parentComponent: MATERIAL_MANAGEMENT,
          name: GOODS_RECEIVED,
          path: PATH_GOODS_RECEIVED,
        },

        {
          parentComponent: MATERIAL_MANAGEMENT,
          name: CREDIT_NOTE,
          path: PATH_CREDIT_NOTE,
        },
        {
          parentComponent: MATERIAL_MANAGEMENT,
          name: MATERIAL_TRANSFER,
          path: PATH_MATERIAL_TRANSFER,
        },
      ],
    },
    {
      name: CUSTOMER_MANAGEMENT,
      icon: customer_icon,
      activeIcon: customer_active_icon,
      subMenu: [
        {
          parentComponent: CUSTOMER_MANAGEMENT,
          name: SALES_ORDERS,
          path: PATH_SALES_ORDERS,
        },
        {
          parentComponent: CUSTOMER_MANAGEMENT,
          name: INVOICE,
          path: PATH_INVOICE,
        },
      ],
    },
    {
      name: LABOUR_MANAGEMENT,
      icon: labour_icon,
      activeIcon: labour_active_icon,
      subMenu: [
        {
          parentComponent: LABOUR_MANAGEMENT,
          name: WORK_ORDER,
          path: PATH_WORK_ORDER,
        },
        {
          parentComponent: LABOUR_MANAGEMENT,
          name: DAILY_WORK_ENTRY,
          path: PATH_DAILY_WORK_ENTRY,
        },
        {
          parentComponent: LABOUR_MANAGEMENT,
          name: SUB_CONTRACTOR_FOOD_ADVANCE,
          path: PATH_SUB_CONTRACTOR_FOOD_ADVANCE,
        },
        {
          parentComponent: LABOUR_MANAGEMENT,
          name: SUB_CONTRACTOR_ADVANCE_REQUEST,
          path: PATH_SUB_CONTRACTOR_ADVANCE_REQUEST,
        },
        {
          parentComponent: LABOUR_MANAGEMENT,
          name: SUB_CONTRACTOR_BILLING,
          path: PATH_SUB_CONTRACTOR_BILLING,
        },
      ],
    },
    {
      name: USER_MANAGEMENT,
      icon: user_icon,
      path: PATH_USER_MANAGEMENT,
      activeIcon: user_active_icon,
    },

    {
      name: MATERIAL_MANAGEMENT_REPORT,
      icon: report_icon,
      activeIcon: reports_active_icon,
      subMenu: [
        {
          parentComponent: MATERIAL_MANAGEMENT_REPORT,
          name: GOODS_RECEIVED_REPORT,
          path: PATH_GOODS_RECEIVED_REPORT,
        },
        // {
        //   parentComponent: MATERIAL_MANAGEMENT_REPORT,
        //   name: PURCHASE_RATIO_REPORT,
        //   path: PATH_PURCHASE_RATIO_REPORT,
        // },
        {
          parentComponent: MATERIAL_MANAGEMENT_REPORT,
          name: STOCK_STATEMENT_REPORT,
          path: PATH_STOCK_STATEMENT_REPORT,
        },
        {
          parentComponent: MATERIAL_MANAGEMENT_REPORT,
          name: PURCHASE_ORDER_REPORT,
          path: PATH_PURCHASE_ORDER_REPORT,
        },
      ],
    },
    {
      name: CUSTOMER_MANAGEMENT_REPORT,
      icon: report_icon,
      activeIcon: reports_active_icon,
      subMenu: [
        {
          parentComponent: CUSTOMER_MANAGEMENT_REPORT,
          name: BALANCE_BILLING_REPORT,
          path: PATH_BALANCE_BILLING_REPORT,
        },
        {
          parentComponent: CUSTOMER_MANAGEMENT_REPORT,
          name: SUB_CONTRACTOR_BILLED_QTY,
          path: PATH_SUB_CONTRACTOR_BILLED_QTY,
        },
      ],
    },
    {
      name: LABOUR_MANAGEMENT_REPORT,
      icon: report_icon,
      activeIcon: reports_active_icon,
      subMenu: [
        // {
        //   parentComponent: LABOUR_MANAGEMENT_REPORT,
        //   name: SUB_CONTRACTOR_FOOD_ADVANCE_DETAIL_REPORT,
        //   path: PATH_SUB_CONTRACTOR_FOOD_ADVANCE_DETAIL_REPORT,
        // },
        {
          parentComponent: LABOUR_MANAGEMENT_REPORT,
          name: ATTENDANCE_REPORT,
          path: PATH_ATTENDANCE_REPORT,
        },
        {
          parentComponent: LABOUR_MANAGEMENT_REPORT,
          name: EXCAVATOR_SUMMARY_REPORT,
          path: PATH_EXCAVATOR_SUMMARY_REPORT,
        },
        {
          parentComponent: LABOUR_MANAGEMENT_REPORT,
          name: SUB_CONTRACTOR_ADVANCE_REQUEST_SUMMARY_REPORT,
          path: PATH_SUB_CONTRACTOR_ADVANCE_REQUEST_SUMMARY_REPORT,
        },
        {
          parentComponent: LABOUR_MANAGEMENT_REPORT,
          name: SUB_CONTRACTOR_BILLING_REPORT,
          path: PATH_SUB_CONTRACTOR_BILLING_REPORT,
        },
        {
          parentComponent: LABOUR_MANAGEMENT_REPORT,
          name: SUB_CONTRACTOR_BILLING_SUMMARY_REPORT,
          path: PATH_SUB_CONTRACTOR_BILLING_SUMMARY_REPORT,
        },
      ],
    },
  ];

  return (
    // This is main div
    <div className={classes.main}>
      {/* this is div for side nav container */}

      <div
        id="div-0"
        style={{
          display: "flex",
          //position: "",
          backgroundColor: PRUSSIAN_BLUE,
          flexDirection: "column",
          //minWidth: "20%",
          minHeight: "100vh",
          //left: "-20vw",
          minWidth: "18%",
          alignItems: "center",
          position: sidebarOpen ? "relative" : "absolute",
          left: sidebarOpen ? "" : "-30%",
        }}
        //className={sidebarOpen ? classes.sidebarOpen : classes.sidebarOpen}
      >
        {/* this is div for image */}
        <div
          id="div-1"
          style={{
            display: "flex",
          }}
        >
          <img
            width="170px"
            height="50px"
            src={logo}
            alt=""
            style={{
              paddingTop: 15,
            }}
          />
        </div>
        {/* image div closed */}

        {/* this is div conatiner for each nav item */}
        <div style={{ marginLeft: "10px", marginTop: 10, paddingRight: 3 }}>
          {sideBarItems?.map((item) => {
            return (
              <div
                id={`div-1-${item?.name}`}
                style={{
                  cursor: "pointer",
                  borderRadius: 5,
                  marginLeft: "0px",
                  //backgroundColor: "red",
                  minWidth: "200px",
                  minHeight: "40px",
                  //mardginLeft: "7px",
                  //display: "flex",
                }}
              >
                <div
                  id={`div-2-${item?.name}`}
                  style={{
                    display: "flex",
                    position: "relative",
                    padding: 3,
                    borderRadius: 5,
                    // backgroundColor: "red",
                    // backgroundColor: item.subMenu
                    //   ? ""
                    //   : localCurrentComponent === item?.name && activeMenu
                    //   ? TRANSPARENT_WHITE_FOR_NAVIGATION_DIV
                    //   : PRUSSIAN_BLUE,
                  }}
                  onClick={() => {
                    item.subMenu
                      ? setlocalCurrentComponent(
                          item.subMenu[0].parentComponent
                        )
                      : setlocalCurrentComponent(item.name);
                    if (activeMenu) {
                      setActiveMenu(!activeMenu);
                    } else {
                      setActiveMenu(!activeMenu);
                      console.log(item?.name, "-----", item?.path);
                      if (!item.subMenu && item.path) {
                        navigate(item.path);
                      }
                    }
                  }}
                >
                  <img
                    src={
                      localCurrentComponent === item?.name && activeMenu
                        ? item.activeIcon
                        : item.icon
                    }
                    alt=""
                    style={{
                      marginRight: 10,
                      userSelect: "none",
                    }}
                  />
                  <Typography
                    color={
                      localCurrentComponent === item?.name && activeMenu
                        ? WHITE
                        : CADET_GREY
                    }
                    fontWeight={
                      localCurrentComponent === item?.name ? "500" : "100"
                    }
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontstretch: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      fontStyle: "normal",
                      userSelect: "none",
                      position: "relative",
                      marginTop: 2,
                    }}
                  >
                    {item?.name}
                  </Typography>

                  {item.subMenu ? (
                    <img
                      src={
                        localCurrentComponent === item?.name && activeMenu
                          ? iconUp
                          : iconDown
                      }
                      alt=""
                      style={{
                        position: "absolute",
                        right: 0,
                        marginTop: 10,
                        userSelect: "none",
                      }}
                    />
                  ) : (
                    <div />
                  )}
                </div>
                {localCurrentComponent === item.name && activeMenu ? (
                  <div
                    id={`div-3-${item?.name}`}
                    style={{
                      marginLeft: "-50%",
                      marginRight: "-50%",
                      //left: 0,
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: TRANSPARENT_WHITE_FOR_SUB_DIV,
                      //alignItems: "center",
                    }}
                  >
                    {item.subMenu?.map((item1) => {
                      return (
                        <div
                          id={`div-4-${item?.name}`}
                          style={{
                            display: "flex",
                            padding: 4,
                            marginLeft: "32%",
                          }}
                        >
                          <Typography
                            color={
                              subComponent === item1?.name ? WHITE : CADET_GREY
                            }
                            fontWeight={
                              subComponent === item1?.name ? "400" : "100"
                            }
                            style={{
                              fontFamily: "sans-serif",
                              fontSize: 11,
                              fontstretch: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "left",
                              fontStyle: "normal",
                              userSelect: "none",
                            }}
                            onClick={() => {
                              navigate?.(item1?.path);
                            }}
                          >
                            {item1?.name}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            );
          })}
        </div>
        {/* nav item div container closed */}

        {/* logout */}
        <Button
          fullWidth
          style={{
            position: "fixed",
            bottom: 20,
            maxWidth: 205,
            width: "80%",
            minHeight: 40,
            backgroundColor: SPANISH_GREEN,
            justifyContent: "flex-start",
            textTransform: "capitalize",
            fontFamily: "sans-serif",
            fontSize: 16,
            marginRight: 30,
            marginLeft: 30,
          }}
          variant="contained"
          startIcon={<img src={logout_icon} alt="" />}
          onClick={() => {
            logOff();
          }}
        >
          {LOGOUT}
        </Button>
        <Typography
          color={WHITE}
          fontWeight={"400"}
          style={{
            marginTop: 5,
            position: "fixed",
            bottom: 0,

            fontSize: 14,
            fontstretch: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            fontStyle: "normal",
            userSelect: "none",
          }}
        >
          {serverVersion}
        </Typography>
        {/* logout button closed */}
      </div>

      {/* side nav div closed */}
      <div
        style={{
          flexdirection: "columm",
          width: sidebarOpen ? "82%" : "100%",
          left: 0,
        }}
      >
        <div
          style={{
            height: "10vh",
            minWidth: "100%",
            display: "flex",
            flexdirection: "column",
            backgroundColor: WHITE,
          }}
        >
          <IconButton
            style={{ margin: 5, cursor: "pointer" }}
            onClick={() => {
              handleViewSidebar();
            }}
            onMouseOver={() => {
              handleViewSidebar();
            }}
          >
            <img src={burger_icon} alt="" />
          </IconButton>
        </div>
        <div
          style={{
            display: "flex",
            position: "absolute",
            right: "0",
            top: "0",
          }}
        >
          <img
            src={avatar_icon}
            alt=""
            width="36px"
            height="36px"
            style={{
              marginTop: 15,
              borderRadius: "50%",
              marginRight: 5,
              border: `2px solid ${SPANISH_GREEN}`,
            }}
          />
          <Typography
            style={{
              marginTop: 25,
              marginRight: 10,
              //
              flexGrow: 0,
              fontFamily: "sans-serif",
              fontSize: "15px",
              fontWeight: 600,
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: 1.32,
              letterSpacing: "normal",
            }}
          >
            Welcome, {APILOGIN.GetUsername()}
          </Typography>
        </div>
        <Divider />
        {/* below code is for bread crumbs */}
        <div
          style={{
            display: "flex",
            flexdirection: "column",
            padding: 5,
            marginLeft: 10,
            marginTop: 10,
          }}
        >
          <Typography
            color={subComponent ? SPANISH_GRAY : CHARLESTON_GREEN}
            fontWeight={subComponent ? "normal" : 600}
            style={{
              display: "flex",
              fontFamily: "sans-serif",
              fontSize: "13px",
              fontstretch: "normal",
              fontStyle: "normal",
              letterSpacing: "normal",
              textAlign: "left",
              cursor: "pointer",
              userSelect: "none",
              width: "auto",
            }}
            onClick={() => {
              navigate(PATH_DASHBOARD);
            }}
          >
            {HOME}
          </Typography>
          <img
            src={bread_crum_icon}
            alt=""
            style={{ marginLeft: 10, marginRight: 10 }}
          />
          {subComponent !== "" ? (
            <div style={{ display: "flex", flexdirection: "column" }}>
              <Typography
                color={detailComponent ? SPANISH_GRAY : CHARLESTON_GREEN}
                style={{
                  display: "flex",
                  fontFamily: "sans-serif",
                  fontSize: "13px",
                  fontstretch: "normal",
                  fontStyle: "normal",
                  letterSpacing: "normal",
                  textAlign: "left",
                  cursor: "pointer",
                  userSelect: "none",
                  width: "auto",
                }}
                fontWeight={detailComponent ? "normal" : 600}
                onClick={() => {
                  navigate(subComponentPath);
                }}
              >
                {subComponent}
              </Typography>
              {detailComponent !== "" ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <img
                    src={bread_crum_icon}
                    alt=""
                    style={{ marginLeft: 10, marginRight: 10 }}
                  />
                  <Typography
                    fontWeight={600}
                    style={{
                      display: "flex",
                      fontFamily: "sans-serif",
                      fontSize: "13px",
                      fontstretch: "normal",
                      fontStyle: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      cursor: "pointer",
                      userSelect: "none",
                      width: "auto",
                    }}
                  >
                    {detailComponent}
                  </Typography>
                </div>
              ) : (
                <div />
              )}
            </div>
          ) : (
            <div />
          )}
        </div>
        {/* bread crumbs code ends  */}
        <div
          style={{
            backgroundColor: "",
            margin: 10,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          {children}
        </div>
      </div>
    </div>
    // main div closed
  );
}
