import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  TableBody,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React, { useEffect, useRef, useState } from "react";
import SideNav from "../../../../../component/layouts/sideNav";
import {
  CANCEL,
  EXPORT,
  GENERATE,
  INDENT,
  INDENT_DETAILS,
  MATERIAL_MANAGEMENT,
  SAVE,
} from "../../../../../resources/constant/strings/string";

import {
  getItemCategories,
  getItemList,
  getItems,
  getProjectManagers,
  getSites,
  getUoms,
  globalFilter,
  itemCategoryCellRenderer,
  itemCellRenderer,
} from "../../../../../component/cellRenderers/renderers";
import {
  BLACK,
  LIGHT_GREY_BACKGROUND,
  PRUSSIAN_BLUE,
  SPANISH_GREEN,
  WHITE,
} from "../../../../../resources/constant/colors/theme";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { APILOGIN } from "../../../../../apihandler/login";
import {
  setAlertConfirmDeletion,
  setAlertRadioConfirmation,
  setAlertTitleText,
  setAlertTitleTextWithoutTimer,
} from "../../../../../component/modal/alertModal";
import plus_icon from "../../../../../resources/assets/images/plus_icon.svg";
import {
  PATH_INDENT,
  PATH_LOGIN,
} from "../../../../../resources/constant/url/url";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import { APIMATERIALMGMT } from "../../../../../apihandler/materialmgmt";
import minus_icon from "../../../../../resources/assets/images/minus_icon.svg";
import EditModal from "./editModal";

export default function IndentDetails() {
  let { id } = useParams();
  const [lastRowId, setLastRowId] = useState(0);

  const onFormSubmit = (e) => {
    console.log("Calling on form submit");
    e.preventDefault();
    validation();
    //
  };

  const location = useLocation();
  const isEdit = location.state?.isEdit;
  function validation() {
    dataFilledVariable ? functionForSave() : functionForGenerate();
  }

  const changeDateFormat = (datePassed) => {
    var date = datePassed;
    return date.split("-").reverse().join("-");
  };

  const functionForSave = () => {
    if (rowItems.length <= 0) {
      setAlertTitleText("No items have been selected for indent");
      return;
    }

    dto.itemDetails = rowItems;

    if (id <= 0) {
      APIMATERIALMGMT.AddIndent(dto)
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.statusCode === 200) {
            if (resp.message !== "OK") {
              setAlertTitleText(
                "Success",
                `Record saved successfully... Warning ${resp.message}`
              );
            } else {
              setAlertTitleText("Success", `Record saved successfully`);
            }

            navigate(
              PATH_INDENT + "/" + dayjs().startOf("month").format("YYYY-MM-DD")
            );
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${resp.message}`
            );
          }
        });
    } else {
      dto.id = Number(id);
      APIMATERIALMGMT.UpdateIndent(id, dto)
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.statusCode === 200) {
            if (resp.message !== "OK") {
              setAlertTitleText(
                "Success",
                `Record saved successfully... Warning ${resp.message}`
              );
            } else {
              setAlertTitleText("Success", `Record saved successfully`);
            }

            navigate(
              PATH_INDENT + "/" + dayjs().startOf("month").format("YYYY-MM-DD")
            );
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${resp.message}`
            );
          }
        });
    }
  };

  const exportData = () => {
    setAlertRadioConfirmation(
      { excel: "Excel", pdf: "PDF" },
      "Please select an option..."
    ).then((result) => {
      if (!result.isConfirmed) return;

      console.log(result);
      dto.projectManagerName = projectManagerValue.name;
      dto.siteName = projectName.name;
      let filename = "";
      dto.exportExcel = result.value === "excel";
      APIMATERIALMGMT.ExportIndent(dto)
        .then((res) => {
          const contentType = res.headers.get("content-type");
          if (contentType.startsWith("application/json")) {
            return Promise.reject(res.json());
          } else {
            const header = res.headers.get("Content-Disposition");
            if (header !== null) {
              const parts = header.split(";");
              filename = parts[1].split("=")[1].replaceAll('"', "");
            } else {
              filename = `indent_${id}.html`;
            }

            return res.blob();
          }
        })
        .then((blob) => {
          if (blob !== null) {
            if (dto.exportExcel) {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement("a");
              a.href = url;
              a.download = filename.replace(".html", ".xlsx");
              document.body.appendChild(a);
              a.click();
              a.remove();
            } else {
              blob.text().then((x) => {
                let html = x;
                html = html.replace(
                  "<script>",
                  '<script type="text/javascript">'
                );
                let blob = new Blob([html], { type: "text/html" });
                let url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
              
                let win = window.open(url, '_blank', '');
                setTimeout(()=>{
                    win.print();
                }, 2000);
              });
            }
          }
        })
        .catch((m) => {
          setAlertTitleText("Oops...", `Something went wrong...${m.message}`);
        });
    });
  };

  const functionForGenerate = () => {
    if (dto.projectManager <= 0 || dto.siteId <= 0 || rowItems.length <= 0) {
      setAlertTitleText("Oops..", "Please select mandatory values");
      return;
    }

    dto.itemDetails = rowItems.filter((x) => x.quantity > 0);
    if (dto.itemDetails.length <= 0) {
      setAlertTitleText("No items selected");
      return;
    }
    APIMATERIALMGMT.GenerateIndent(dto)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode === 200) {
          setDataFilledVariable(true);
          resp.response.itemDetails.forEach((x, i) => {
            if (x.id === 0) {
              x.id = Number((Math.random() * 10000000).toFixed(0));
            }
            x.rowId = i;
          });
          setRowItems((_) => [].concat(resp.response.itemDetails));
          setGridData(resp.response.itemDetails);
          if (resp.message !== "OK") {
            setAlertTitleTextWithoutTimer("Warning", `${resp.message}`);
          }
        } else {
          setAlertTitleText(
            "Oops...",
            `Something went wrong... ${resp.message}`
          );
        }
      });
  };

  const [rowItems, setRowItems] = useState([
    {
      id: 0,
      rowId: 1 * Number((Math.random() * 10000000).toFixed(0)),
      categoryId: 0,
      itemId: 0,
      uomName: "",
      quantity: 0.0,
      boqQty: 0.0,
      stock: 0.0,
      previousPOQty: 0.0,
      previousPONumber: "",
      purchaseRatio: "NA",
      previousPODate: "",
      poQty: 0.0,
      description: "",
    },
  ]);
  const [rowId, setRowId] = useState(2);
  const [gridData, setGridData] = useState([]);
  const [dto, setDTO] = useState({
    date: dayjs(),
    projectManager: 0,
    siteId: 0,
    indentNo: "Auto Generated",
    id: 1 * Number((Math.random() * 10000000).toFixed(0)),
    itemDetails: [],
    status: "N",
  });

  const removeObjectFromArray = (rowId) => {
    setRowItems(c =>
      c.filter((x) => {
        return x.rowId !== rowId;
      })
    );
  };

  const addObjectToArray = () => {
    let newArr = [
      ...rowItems,
      {
        id: 0,
        rowId: 1 * Number((Math.random() * 10000000).toFixed(0)),
        categoryId: 0,
        itemId: 0,
        uomName: "",
        quantity: 0.0,
        boqQty: 0.0,
        stock: 0.0,
        previousPOQty: 0.0,
        purchaseRatio: "NA",
        previousPODate: "",
        poQty: 0.0,
        description: "",
      },
    ];
    setRowItems(newArr);
  };

  const navigate = useNavigate();

  const gridRef = useRef();

  const [dataFilledVariable, setDataFilledVariable] = useState(false);

  useEffect(() => {
    if (!APILOGIN.GetIsLoggedIn()) navigate(PATH_LOGIN);
  }, []);

  const [selectedRowId, setSelectedRowId] = useState(0);

  const itemCategories = getItemCategories();

  const itemCategoryProps = {
    options: itemCategories,
    getOptionLabel: (option) => option?.name || "",
  };

  const itemList = getItemList();
  const uomList = getUoms();

  const items = getItems();

  const itemProps = {
    options: items,
    getOptionLabel: (option) => option?.name || "",
  };

  const sites = getSites();

  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };

  const pms = getProjectManagers();

  const projectManagerProps = {
    options: pms,
    getOptionLabel: (option) => option?.name || "",
  };

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId(event.api.getSelectedRows()[0].id);
    else setSelectedRowId(0);
  };

  const onAdd = (data) => {
    const items = rowItems;
    items.push(data);
    const tempDto = dto;
    tempDto.itemDetails = items;

    APIMATERIALMGMT.GenerateIndent(tempDto)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode === 200) {
          resp.response.itemDetails.forEach((x, i) => {
            if (x.id === 0) {
              x.id = Number((Math.random() * 10000000).toFixed(0));
            }
            x.rowId = i;
          });
          setDTO({
            ...dto,
            itemDetails: resp.response.itemDetails,
          });
          setRowItems((rowItems) => [].concat(resp.response.itemDetails));
          try {
            const rowData = [];
            gridRef.current.api.forEachNode(function (node) {
              rowData.push(node.data);
            });
            gridRef.current.api.applyTransaction({
              remove: rowData,
            });
          } catch (e) {}
          try {
            gridRef.current.api.applyTransaction({
              add: resp.response.itemDetails,
            });
          } catch (e) {}
          setLastRowId(
            gridRef.current.api.getDisplayedRowAtIndex(
              gridRef.current.api.getLastDisplayedRow()
            ).data.rowId
          );
        } else {
          setAlertTitleText(
            "Oops...",
            `Something went wrong... ${resp.message}`
          );
        }
      });
  };

  const onUpdate = (data) => {
    const itemDetails = dto.itemDetails.map((obj) => {
      if (obj.id === data.id) {
        return { ...obj, ...data };
      }
      return obj;
    });

    const tempDto = dto;
    tempDto.itemDetails = itemDetails;
    APIMATERIALMGMT.GenerateIndent(dto)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode === 200) {
          resp.response.itemDetails.forEach((x, i) => {
            if (x.id === 0) {
              x.id = Number((Math.random() * 10000000).toFixed(0));
            }
            x.rowId = i;
          });

          setDTO({
            ...dto,
            itemDetails: resp.response.itemDetails,
          });
          setRowItems((rowItems) => [].concat(resp.response.itemDetails));
          try {
            const rowData = [];
            gridRef.current.api.forEachNode(function (node) {
              rowData.push(node.data);
            });
            gridRef.current.api.applyTransaction({
              remove: rowData,
            });
          } catch (e) {}
          try {
            gridRef.current.api.applyTransaction({
              add: resp.response.itemDetails,
            });
          } catch (e) {}
        } else {
          setAlertTitleText(
            "Oops...",
            `Something went wrong... ${resp.message}`
          );
        }
      });
  };

  const [projectManagerValue, setProjectManagerValue] = useState({});
  const [projectName, setProjectName] = useState({});

  const [openEditModal, setEditOpenModal] = useState(false);
  const [editFormTitle, setEditFormTitle] = useState("Add");
  const [editFormData, setEditFormData] = useState(null);
  const columnDefs = [
    {
      flex: 1.8,
      autoHeight: true,
      wrapText: true,
      headerName: "Item Category",
      field: "categoryId",
      valueGetter: (params) => {
        return itemCategoryCellRenderer({ value: params.data.categoryId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 1.8,
      autoHeight: true,
      wrapText: true,
      headerName: "Item Name",
      field: "itemId",
      valueGetter: (params) => {
        return itemCellRenderer({ value: params.data.itemId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Description",
      field: "description",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Quantity",
      field: "quantity",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
  ];

  const columnDefs2 = [
    {
      flex: 1.4,
      headerName: "Actions",
      field: "id",
      sortable: false,
      filter: false,
      hide: !isEdit,
      floatingFilter: false,
      cellRenderer: (params) =>
        lastRowId === params.data?.rowId ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              aria-label="Add"
              onClick={() => {
                setEditFormTitle("Add");
                setEditFormData({
                  id: 0,
                  rowId: 1 * Number((Math.random() * 10000000).toFixed(0)),
                  categoryId: 0,
                  itemId: 0,
                  uomName: "",
                  quantity: 0.0,
                  description: "",
                });
                setEditOpenModal(true);
              }}
            >
              <AddCircleOutlineIcon
                style={{ color: PRUSSIAN_BLUE, width: "20px" }}
              />
            </IconButton>
            {params.data?.poQty <= 0 && (
              <IconButton
                aria-label="edit"
                onClick={() => {
                  setEditFormTitle("Edit");
                  setEditFormData(params.data);
                  setEditOpenModal(true);
                }}
              >
                <EditIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
              </IconButton>
            )}
            {params.data?.poQty <= 0 && (
              <IconButton
                aria-label="edit"
                onClick={() => {
                  setAlertConfirmDeletion(
                    "Confirmation needed",
                    "Do you want to delete? Please confirm"
                  ).then((consent) => {
                    if (!consent.isConfirmed) return;

                    const selectedData = gridRef.current.api.getSelectedRows();
                    let m = rowItems.filter((x) => x.id !== params.data?.id);
                    gridRef.current.api.applyTransaction({
                      remove: selectedData,
                    });
                    setRowItems((r) => [].concat(m));
                    setLastRowId(
                      gridRef.current.api.getDisplayedRowAtIndex(
                        gridRef.current.api.getLastDisplayedRow()
                      ).data.rowId
                    );
                  });
                }}
              >
                <DeleteForeverIcon
                  style={{ color: PRUSSIAN_BLUE, width: "20px" }}
                />
              </IconButton>
            )}
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {params.data?.poQty <= 0 && (
              <IconButton
                aria-label="edit"
                onClick={() => {
                  setEditFormTitle("Edit");
                  setEditFormData(params.data);
                  setEditOpenModal(true);
                }}
              >
                <EditIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
              </IconButton>
            )}
            {params.data?.poQty <= 0 && (
              <IconButton
                aria-label="edit"
                onClick={() => {
                  setAlertConfirmDeletion(
                    "Confirmation needed",
                    "Do you want to delete? Please confirm"
                  ).then((consent) => {
                    if (!consent.isConfirmed) return;

                    const selectedData = gridRef.current.api.getSelectedRows();
                    let m = rowItems.filter((x) => x.id !== params.data?.id);

                    gridRef.current.api.applyTransaction({
                      remove: selectedData,
                    });
                    setRowItems((r) => [].concat(m));
                    setLastRowId(
                      gridRef.current.api.getDisplayedRowAtIndex(
                        gridRef.current.api.getLastDisplayedRow()
                      ).data.rowId
                    );
                  });
                }}
              >
                <DeleteForeverIcon
                  style={{ color: PRUSSIAN_BLUE, width: "20px" }}
                />
              </IconButton>
            )}
          </div>
        ),
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 1.5,
      autoHeight: true,
      wrapText: true,
      headerName: "Item Category",
      field: "categoryId",
      valueGetter: (params) => {
        return itemCategoryCellRenderer({ value: params.data.categoryId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 1.5,
      autoHeight: true,
      wrapText: true,
      headerName: "Item Name",
      field: "itemId",
      valueGetter: (params) => {
        return itemCellRenderer({ value: params.data.itemId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Description",
      field: "description",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Quantity",
      field: "quantity",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Stock",
      field: "stock",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Previous PO Qty",
      field: "previousPOQty",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Previous PO Number",
      field: "previousPONumber",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Purchase Ratio",
      field: "purchaseRatio",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
  ];

  const columnDefs1 = [
    {
      flex: 1.5,
      autoHeight: true,
      wrapText: true,
      headerName: "Item Category",
      field: "categoryId",
      valueGetter: (params) => {
        return itemCategoryCellRenderer({ value: params.data.categoryId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 1.5,
      autoHeight: true,
      wrapText: true,
      headerName: "Item Name",
      field: "itemId",
      valueGetter: (params) => {
        return itemCellRenderer({ value: params.data.itemId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Description",
      field: "description",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Quantity",
      field: "quantity",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Stock",
      field: "stock",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Previous PO Qty",
      field: "previousPOQty",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Previous PO Number",
      field: "previousPONumber",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Purchase Ratio",
      field: "purchaseRatio",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };

  const onGridReady = (params) => {
    if (id > 0) {
      APIMATERIALMGMT.Indent(id)
        .then((resp) => resp.json())
        .then((resp) => {
          let itemDetails = resp.response.itemDetails;
          itemDetails = itemDetails.sort((a, b) => a.id - b.id);
          itemDetails.forEach((v, i) => {
            const it = items.filter((y) => y.value === v.itemId);
            if (it.length > 0) {
              v.categoryId = it[0].dependentvalue;
            }
            v.rowId = i;
            const itemsIn = itemList.filter((x) => x.id === v.itemId);
            if (itemsIn.length > 0) {
              v.quantityUom = `${v.quantity} ${
                uomList.filter((x) => x.value === itemsIn[0].purchaseUOMId)[0]
                  .name
              }`;
            }
          });

          const requiredProjectManager = projectManagerProps.options.filter(
            (x) => x.value === resp.response.projectManager
          );
          setProjectManagerValue(
            requiredProjectManager.length > 0
              ? requiredProjectManager[0]
              : { value: 0, name: "" }
          );

          const requiredProjectName = siteProps.options.filter(
            (x) => x.value === resp.response.siteId
          );
          setProjectName(
            requiredProjectName.length > 0
              ? requiredProjectName[0]
              : { value: 0, name: "" }
          );

          gridRef.current.api.applyTransaction({
            add: itemDetails,
          });
          setDTO({
            ...dto,
            id: resp.response.id,
            indentNo: resp.response.indentNo,
            projectManager: resp.response.projectManager,
            siteId: resp.response.siteId,
            status: resp.response.status,
            date: resp.response.date.split("-").reverse().join("-"),
            itemDetails: itemDetails,
          });

          setRowItems((rowItems) => [].concat(itemDetails));

          setLastRowId(
            gridRef.current.api.getDisplayedRowAtIndex(
              gridRef.current.api.getLastDisplayedRow()
            ).data.rowId
          );

          setDataFilledVariable(true);
        });
    } else {
      gridRef.current.api.applyTransaction({ add: gridData });
    }
  };

  return (
    <SideNav
      currentComponent={MATERIAL_MANAGEMENT}
      subComponent={INDENT}
      subComponentPath={PATH_INDENT}
      detailComponent={INDENT_DETAILS}
    >
      {openEditModal && (
        <EditModal
          formTitle={editFormTitle}
          setOpenModal={setEditOpenModal}
          model={editFormData}
          onAdd={onAdd}
          onUpdate={onUpdate}
        ></EditModal>
      )}
      <form>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginBottom: 10,
            marginRight: 10,
          }}
        >
          {(id <= 0 || isEdit) && (
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
              }}
              onClick={onFormSubmit}
            >
              {dataFilledVariable ? SAVE : GENERATE}
            </Button>
          )}
          {dto.id > 0 && (
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={exportData}
            >
              {EXPORT}
            </Button>
          )}
          <Button
            onClick={() => {
              navigate(PATH_INDENT + "/" + location.state.date);
            }}
            variant="outlined"
            sx={{
              borderColor: SPANISH_GREEN,
            }}
            style={{
              //backgroundColor: SPANISH_GREEN,
              textTransform: "capitalize",
              fontSize: 14,
              color: SPANISH_GREEN,
              minWidth: "180px",
              minHeight: "45px",
              marginRight: 10,
            }}
          >
            {CANCEL}
          </Button>
        </div>
        {/* delete , add button div ends */}

        <div
          style={{
            backgroundColor: LIGHT_GREY_BACKGROUND,
            borderRadius: "5px",
            minWidth: "90%",
            minHeight: "50px",
            padding: 20,
            marginBottom: 10,
            borderStyle: "solid",
            border: "1x",
            borderColor: "#eaeaea",
            display: "flex",
          }}
        >
          <table style={{ width: "100%" }}>
            <TableBody>
              <tr>
                <td>
                  <TextField
                    variant="standard"
                    label="Indent No"
                    disabled={true}
                    style={{
                      minWidth: "200px",
                      minHeight: "48.5px",
                      width: "200px",
                    }}
                    name="indentNo"
                    value={id > 0 ? dto.indentNo : "Auto Generated"}
                    InputLabelProps={{
                      style: { color: "grey", fontSize: 14 },
                    }}
                    InputProps={{ style: { fontSize: 14 } }}
                  ></TextField>
                </td>
                <td>
                  {id > 0 ? (
                    <Autocomplete
                      filterOptions={globalFilter}
                      {...siteProps}
                      id="auto-complete-1"
                      name="siteId"
                      variant="standard"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      disabled={
                        dataFilledVariable ? true : id > 0
                      }
                      value={projectName}
                      isOptionEqualToValue={(o, v) => o.value === v.value}
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, siteId: v?.value });
                          else
                            setDTO({
                              ...dto,
                              siteId: Number(v?.value),
                            });
                        } else {
                          setDTO({ ...dto, siteId: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Project Name"
                          required
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      filterOptions={globalFilter}
                      {...siteProps}
                      id="auto-complete-1"
                      name="siteId"
                      variant="standard"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      disabled={
                        dataFilledVariable ? true : id > 0
                      }
                      defaultValue={
                        siteProps.options.find((v) => v.value === dto.siteId) ||
                        {}
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, siteId: v?.value });
                          else
                            setDTO({
                              ...dto,
                              siteId: Number(v?.value),
                            });
                        } else {
                          setDTO({ ...dto, siteId: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Project Name"
                          required
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  )}
                </td>
                <td>
                  {id > 0 ? (
                    <Autocomplete
                      variant="standard"
                      {...projectManagerProps}
                      id="auto-complete-2"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      disabled={
                        dataFilledVariable ? true : id > 0
                      }
                      style={{
                        minWidth: "200px",
                        minHeight: "48.5px",
                        width: "200px",
                      }}
                      filterOptions={(o, s) => {
                        let options = globalFilter(o, s);
                        let newOptions = [];
                        options.forEach((element) => {
                          if (element.dependentvalue === dto.siteId)
                            newOptions.push(element);
                        });
                        return newOptions;
                      }}
                      name="projectManager"
                      required
                      isOptionEqualToValue={(o, v) => o.value === v.value}
                      value={projectManagerValue}
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, projectManager: v?.value });
                          else
                            setDTO({
                              ...dto,
                              projectManager: Number(v?.value),
                            });
                        } else {
                          setDTO({ ...dto, projectManager: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Project Manager"
                          required
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    ></Autocomplete>
                  ) : (
                    <Autocomplete
                      filterOptions={(o, s) => {
                        let options = globalFilter(o, s);
                        let newOptions = [];
                        options.forEach((element) => {
                          if (element.dependentvalue === dto.siteId)
                            newOptions.push(element);
                        });
                        return newOptions;
                      }}
                      variant="standard"
                      {...projectManagerProps}
                      id="auto-complete-2"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      disabled={
                        dataFilledVariable ? true : id > 0
                      }
                      style={{
                        minWidth: "200px",
                        minHeight: "48.5px",
                        width: "200px",
                      }}
                      name="projectManager"
                      required
                      defaultValue={
                        projectManagerProps.options.find(
                          (v) => v.value === dto.projectManager
                        ) || {}
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, projectManager: v?.value });
                          else
                            setDTO({
                              ...dto,
                              projectManager: Number(v?.value),
                            });
                        } else {
                          setDTO({ ...dto, projectManager: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Project Manager"
                          required
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    ></Autocomplete>
                  )}
                </td>
                <td>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="From Date "
                      inputFormat="DD-MM-YYYY"
                      style={{ minWidth: "272px", minHeight: "48.5px" }}
                      value={dto.date}
                      maxDate={dayjs()}
                      disabled={
                        dataFilledVariable ? true : id > 0
                      }
                      //minDate={dayjs("2017-01-01")}
                      onChange={(newValue) => {
                        setDTO({ ...dto, date: newValue });
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </td>
              </tr>
            </TableBody>
          </table>
        </div>
      </form>
      {/* below code is for idents... */}
      {id > 0 ? (
        <div className="App">
          <div className="ag-theme-alpine" style={{ height: "570px" }}>
            {isEdit ? (
              <AgGridReact
                copyGroupHeadersToClipboard={true}
                enableRangeSelection={true}
                columnDefs={columnDefs2}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                paginationAutoPageSize={true}
                pagination={true}
                rowSelection="multiple"
                getRowId={(params) => params.data.id}
                onSelectionChanged={onRowSelectionChanged}
              ></AgGridReact>
            ) : (
              <AgGridReact
                copyGroupHeadersToClipboard={true}
                enableRangeSelection={true}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                paginationAutoPageSize={true}
                pagination={true}
                rowSelection="multiple"
                getRowId={(params) => params.data.id}
                onSelectionChanged={onRowSelectionChanged}
              ></AgGridReact>
            )}
          </div>
        </div>
      ) : (
        <div>
          {!dataFilledVariable ? (
            <div
              style={{
                backgroundColor: WHITE,
                borderRadius: "5px",
                minWidth: "90%",
                minHeight: "50px",
                marginBottom: 10,
                borderStyle: "solid",
                border: "1x",
                borderColor: PRUSSIAN_BLUE,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ padding: 10 }}>
                <table style={{ width: "100%" }}>
                  <TableBody>
                    {rowItems?.map((item, index) => {
                      return (
                        <tr key={item.rowId}>
                          <td>
                            <Autocomplete
                              filterOptions={globalFilter}
                              id={`auto-complete-1-${index}`}
                              {...itemCategoryProps}
                              autoComplete
                              autoHighlight
                              autoSelect
                              includeInputInList
                              name="categoryId"
                              disabled={id > 0}
                              defaultValue={
                                itemCategoryProps.options.find(
                                  (v) => v.value === rowItems[index].categoryId
                                ) || {}
                              }
                              onChange={(e, v) => {
                                let newArr = [...rowItems];
                                newArr[index].categoryId = Number(v?.value);
                                setRowItems(newArr);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Item category"
                                  variant="standard"
                                  style={{
                                    minWidth: "200px",
                                    minHeight: "38.5px",
                                    width: "200px",
                                    height: "38.5px",
                                  }}
                                  InputLabelProps={{
                                    style: { color: "grey", fontSize: 14 },
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    style: { fontSize: 14 },
                                  }}
                                />
                              )}
                            />
                          </td>
                          <td>
                            <Autocomplete
                              id={`auto-complete-2-${index}`}
                              {...itemProps}
                              variant="standard"
                              autoComplete
                              autoHighlight
                              autoSelect
                              disabled={id > 0}
                              style={{
                                minWidth: "200px",
                                minHeight: "38.5px",
                                width: "200px",
                                height: "38.5px",
                              }}
                              defaultValue={
                                itemProps.options.find(
                                  (v) => v?.value === rowItems[index].itemId
                                ) || {}
                              }
                              filterOptions={(o, s) => {
                                let options = globalFilter(o, s);
                                let newOptions = [];
                                options.forEach((element) => {
                                  if (
                                    element.dependentvalue ==
                                    rowItems[index].categoryId
                                  )
                                    newOptions.push(element);
                                });
                                return newOptions;
                              }}
                              onChange={(e, v) => {
                                let newArr = [...rowItems];
                                newArr[index].itemId = Number(v?.value);
                                const filteredItems = itemList.filter(
                                  (x) => x.id === newArr[index].itemId
                                );
                                if (filteredItems.length > 0) {
                                  newArr[index].description =
                                    filteredItems[0].description;
                                  uomList.forEach((element) => {
                                    if (
                                      element.value ===
                                      filteredItems[0].purchaseUOMId
                                    )
                                      newArr[index].uomName = element.name;
                                  });
                                }

                                setRowItems(newArr);
                              }}
                              name="itemId"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Item Name"
                                  id={`textfield-1-${index}`}
                                  variant="standard"
                                  style={{
                                    minWidth: "200px",
                                    minHeight: "38.5px",
                                    width: "200px",
                                    height: "38.5px",
                                  }}
                                  InputLabelProps={{
                                    style: { color: "grey", fontSize: 14 },
                                  }}
                                  InputProps={{
                                    ...params.InputProps,
                                    style: { fontSize: 14 },
                                  }}
                                />
                              )}
                            ></Autocomplete>
                          </td>
                          <td>
                            <TextField
                              variant="standard"
                              label="Quantity"
                              disabled={id > 0}
                              onChange={(e) => {
                                let newArr = [...rowItems];
                                newArr[index][e.target.name] = e.target.value;
                                setRowItems(newArr);
                              }}
                              onWheel={(event) => event.target.blur()}
                              style={{
                                minWidth: "200px",
                                minHeight: "38.5px",
                                height: "38.5px",
                                width: "200px",
                                fontSize: 10,
                              }}
                              name="quantity"
                              onBlur={(e) => {
                                let newArr = [...rowItems];
                                newArr[index][e.target.name] = Number(
                                  e.target.value
                                );
                                setRowItems(newArr);
                              }}
                              value={rowItems[index].quantity}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {rowItems[index].uomName}
                                  </InputAdornment>
                                ),
                                style: { fontSize: 14 },
                              }}
                            ></TextField>
                          </td>
                          <td>
                            <TextareaAutosize
                              variant="standard"
                              label="Description"
                              maxLength={128}
                              disabled={id > 0}
                              style={{
                                outlineColor: BLACK,
                                borderTop: "10px",
                                borderRight: "10px",
                                borderLeft: "10px",
                                marginTop: 15,
                                fontSize: 12,
                                fontFamily: "sans-serif",
                              }}
                              multiline="true"
                              maxRows={4}
                              minRows={2}
                              value={rowItems[index].description}
                              name="description"
                              onChange={(e) => {
                                let newArr = [...rowItems];
                                newArr[index][e.target.name] = e.target.value;
                                setRowItems(newArr);
                              }}
                              placeholder="Description"
                            ></TextareaAutosize>
                          </td>
                          <td>
                            {id <= 0 && index > 0 && (
                              <IconButton
                                style={{ marginTop: 10 }}
                                aria-label="minus"
                                onClick={() => {
                                  removeObjectFromArray(item.rowId);
                                }}
                              >
                                <img src={minus_icon} alt="" />
                              </IconButton>
                            )}
                          </td>
                          <td>
                            <IconButton
                              style={{ marginTop: 10 }}
                              id={`button-plus-${index}`}
                              aria-label="plus"
                              onClick={() => {
                                addObjectToArray();
                              }}
                            >
                              <img src={plus_icon} alt="" />
                            </IconButton>
                          </td>
                        </tr>
                      );
                    })}
                  </TableBody>
                </table>
              </div>
            </div>
          ) : (
            <div className="App">
              <div className="ag-theme-alpine" style={{ height: "570px" }}>
                <AgGridReact
                  copyGroupHeadersToClipboard={true}
                  enableRangeSelection={true}
                  columnDefs={columnDefs1}
                  ref={gridRef}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  paginationAutoPageSize={true}
                  pagination={true}
                  rowSelection="multiple"
                  getRowId={(params) => params.data.id}
                  onSelectionChanged={onRowSelectionChanged}
                ></AgGridReact>
              </div>
            </div>
          )}
        </div>
      )}
    </SideNav>
  );
}
