import Swal from "sweetalert2";
import logo from "../../resources/assets/images/logo.svg";
const setAlertTitleText = (alertTitle, alertText) => {
  Swal.fire({
    title: alertTitle,
    text: alertText,
    imageUrl: logo,
    imageWidth: 150,
    imageHeight: 75,
    imageAlt: "Custom image",
    showCancelButton: false,
    timer: 5000,
  });
};
const setAlertTitleTextWithoutTimer = (alertTitle, alertText) => {
  Swal.fire({
    title: alertTitle,
    text: alertText,
    imageUrl: logo,
    imageWidth: 150,
    imageHeight: 75,
    imageAlt: "Custom image",
    showCancelButton: false,
  });
};
const setAlertConfirmDeletion = (alertTitle, alertText) => {
  return Swal.fire({
    title: alertTitle,
    text: alertText,
    imageUrl: logo,
    imageWidth: 150,
    imageHeight: 75,
    imageAlt: "Custom image",
    showCancelButton: true,
    icon: "warning",
    dangerMode: true,
  });
};

const setAlertRadioConfirmation = (inputOptions, alertTitle) => {
  return Swal.fire({
    title: alertTitle,
    imageUrl: logo,
    imageWidth: 150,
    imageHeight: 75,
    imageAlt: "Custom image",
    showCancelButton: false,
    input: "radio",
    inputOptions: inputOptions,
    inputValidator: (value) => {
      if (!value) {
        return "You need to choose one option";
      }
    },
    icon: "warning",
    dangerMode: true,
  });
};

export {
  setAlertTitleText,
  setAlertConfirmDeletion,
  setAlertRadioConfirmation,
  setAlertTitleTextWithoutTimer,
};
