import * as React from "react";
import { APILOGIN } from "../apihandler/login";

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [role, setRole] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    sessionStatus()
      .then((x) => {
        setAuthenticated(true);
        setLoading(false);
      })
      .catch((e) => {
        setAuthenticated(false);
        setLoading(false);
      });
  }, []);

  const login = (username, password) => {
    return new Promise((res, rej) => {
      APILOGIN.Login(username, password)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200 && data.status) {
            //api call
            setAuthenticated(true);
            APILOGIN.SetIsLoggedIn(true);
            APILOGIN.SetUsername(data.response.UserName);
            setUsername(data.response.UserName);
            setRole(data.response.RoleID);
            res();
          } else {
            rej();
          }
        })
        .catch((e) => {
          rej();
        });
    });
  };

  const logout = () => {
    return new Promise((res, rej) => {
      APILOGIN.Logoff()
        .then((resp) => resp.json())
        .then((resp) => {
          setAuthenticated(false);
          setUsername("");
          setRole("");
          res();
        })
        .catch((e) => {
          setAuthenticated(false);
          rej();
        });
    });
  };

  const sessionStatus = () => {
    return new Promise((res, rej) => {
      APILOGIN.SessionStatus()
        .then((resp) => resp.json())
        .then((resp) => {
          APILOGIN.SetIsLoggedIn(true);
          APILOGIN.SetUsername(resp.response.username);
          setUsername(resp.response.username);
          setRole(resp.response.roleId);
          setAuthenticated(true);
          res();
        })
        .catch((e) => {
          rej();
        });
    });
  };
  return (
    <AuthContext.Provider
      value={{ authenticated, username, role, loading, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};
