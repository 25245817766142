import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import {
  PATH_ADD_EMPLOYEE,
  PATH_ATTENDANCE_REPORT,
  PATH_BALANCE_BILLING_REPORT,
  PATH_CREDIT_NOTE,
  PATH_CREDIT_NOTE_DETAIL,
  PATH_CREDIT_NOTE_DETAIL_WITH_PARAM,
  PATH_CREDIT_NOTE_WITH_DATE,
  PATH_DAILY_WORK_ENTRY,
  PATH_DAILY_WORK_ENTRY_DETAILS,
  PATH_DAILY_WORK_ENTRY_DETAILS_WITH_PARAM,
  PATH_DAILY_WORK_ENTRY_WITH_DATE,
  PATH_DASHBOARD,
  PATH_DESIGNATION,
  PATH_EMPLOYEE_TYPE,
  PATH_EXCAVATOR_SUMMARY_REPORT,
  PATH_GOODS_RECEIVED,
  PATH_GOODS_RECEIVED_DETAIL,
  PATH_GOODS_RECEIVED_DETAIL_WITH_PARAM,
  PATH_GOODS_RECEIVED_REPORT,
  PATH_GOODS_RECEIVED_WITH_DATE,
  PATH_INDENT,
  PATH_INDENT_DETAILS,
  PATH_INDENT_DETAILS_WITH_PARAM,
  PATH_INDENT_WITH_DATE,
  PATH_INVOICE,
  PATH_INVOICE_DETAIL,
  PATH_INVOICE_WITH_DATE,
  PATH_ITEM,
  PATH_ITEM_CATEGORIES,
  PATH_LABOURER_TYPE,
  PATH_LOGIN,
  PATH_MANAGER_ASSIGNMENT,
  PATH_MASTER,
  PATH_MATERIAL_TRANSFER,
  PATH_MATERIAL_TRANSFER_DETAIL,
  PATH_MATERIAL_TRANSFER_DETAIL_WITH_PARAM,
  PATH_MATERIAL_TRANSFER_WITH_DATE,
  PATH_PURCHASE_ORDER,
  PATH_PURCHASE_ORDER_DETAIL,
  PATH_PURCHASE_ORDER_DETAIL_WITHOUT_PARAM,
  PATH_PURCHASE_ORDER_REPORT,
  PATH_PURCHASE_ORDER_WITH_DATE,
  PATH_PURCHASE_PRICE_CONFIGURATION,
  PATH_PURCHASE_RATIO_REPORT,
  PATH_SALES_ORDERS,
  PATH_SALES_ORDERS_DETAIL,
  PATH_SALES_ORDERS_WITH_DATE,
  PATH_SITE,
  PATH_SITE_CATEGORIES,
  PATH_STOCK_STATEMENT_REPORT,
  PATH_SUB_CONTRACTOR_ADVANCE_REQUEST,
  PATH_SUB_CONTRACTOR_ADVANCE_REQUEST_DETAIL,
  PATH_SUB_CONTRACTOR_ADVANCE_REQUEST_DETAIL_WITH_PARAM,
  PATH_SUB_CONTRACTOR_ADVANCE_REQUEST_SUMMARY_REPORT,
  PATH_SUB_CONTRACTOR_ADVANCE_REQUEST_WITH_DATE,
  PATH_SUB_CONTRACTOR_BILLED_QTY,
  PATH_SUB_CONTRACTOR_BILLING,
  PATH_SUB_CONTRACTOR_BILLING_DETAIL,
  PATH_SUB_CONTRACTOR_BILLING_DETAIL_WITH_PARAM,
  PATH_SUB_CONTRACTOR_BILLING_REPORT,
  PATH_SUB_CONTRACTOR_BILLING_SUMMARY_REPORT,
  PATH_SUB_CONTRACTOR_BILLING_WITH_DATE,
  PATH_SUB_CONTRACTOR_FOOD_ADVANCE,
  PATH_SUB_CONTRACTOR_FOOD_ADVANCE_DETAIL,
  PATH_SUB_CONTRACTOR_FOOD_ADVANCE_DETAIL_REPORT,
  PATH_SUB_CONTRACTOR_FOOD_ADVANCE_DETAIL_WITH_PARAM,
  PATH_SUB_CONTRACTOR_FOOD_ADVANCE_WITH_DATE,
  PATH_SUPPLIER,
  PATH_SUPPLIER_CATEGORIES,
  PATH_TAX,
  PATH_TEMPLATE,
  PATH_UOM,
  PATH_USER_MANAGEMENT,
  PATH_WORK_ORDER,
  PATH_WORK_ORDER_DETAIL,
  PATH_WORK_ORDER_DETAIL_WITH_PARAM,
  PATH_WORK_ORDER_WITH_DATE,
} from "../../resources/constant/url/url";
import Temp from "../../template/temp";
import Invoice from "../../views/customerManagement/invoice/components/invoice";
import InvoiceDetail from "../../views/customerManagement/invoice/invoiceDetail/components/invoiceDetail";
import SalesOrder from "../../views/customerManagement/salesOrder/components/salesOrder";
import SalesOrdersDetail from "../../views/customerManagement/salesOrder/salesOrdersDetail/components/salesOrdersDetail";
import Home from "../../views/home/home";
import DailyWorkEntry from "../../views/labourManagement/dailyWorkEntry/components/dailyWorkEntry";
import DailyWorkEntryDetails from "../../views/labourManagement/dailyWorkEntry/dailyWorkEntryDetails/components/dailyWorkEntryDetails";
import ManagerAssignment from "../../views/labourManagement/managerAssignment/components/managerAssignment";
import SubContractorAdvanceRequest from "../../views/labourManagement/subContractorAdvanceRequest/components/subContractorAdvanceRequest";
import SubContractorAdvanceRequestDetail from "../../views/labourManagement/subContractorAdvanceRequest/subcontractorAdvanceRequestDetail/components/subContractorAdvanceRequestDetail";
import SubContractorBilling from "../../views/labourManagement/subContractorBilling/components/subContractorBilling";
import SubContractorBillingDetail from "../../views/labourManagement/subContractorBilling/components/subContractorBillingDetail/components/subContractorBillingDetail";
import SubContractorFoodAdvance from "../../views/labourManagement/subContractorFoodAdvance/components/subContractorFoodAdvance";
import SubContractorFoodAdvanceDetail from "../../views/labourManagement/subContractorFoodAdvance/subContractorFoodAdvanceDetail/components/subContractorFoodAdvanceDetail";
import WorkOrder from "../../views/labourManagement/workOrder/components/workOrder";
import WorkOrderDetail from "../../views/labourManagement/workOrder/workOrderDetail/components/workOrderDetail";
import Login from "../../views/login/login";
import Designation from "../../views/master/designation/components/designation";
import Employee from "../../views/master/employee/components/employee";
import EmployeeType from "../../views/master/employeeType/components/employeeType";
import ItemCategories from "../../views/master/itemCategories/components/itemCategories";
import Item from "../../views/master/items/components/item";
import LabourerType from "../../views/master/labourerType/components/labourerType";
import Master from "../../views/master/master";
import SiteCategories from "../../views/master/siteCategories/components/siteCategories";
import Site from "../../views/master/sites/components/site";
import SupplierCategories from "../../views/master/supplierCategories/components/supplierCategories";
import Supplier from "../../views/master/suppliers/components/supplier";
import Tax from "../../views/master/taxes/components/tax";
import Uom from "../../views/master/uoms/components/uom";
import CreditNote from "../../views/materialManagement/creditNote/components/creditNote";
import CreditNoteDetail from "../../views/materialManagement/creditNote/components/creditNoteDetail/components/creditNoteDetail";
import GoodsReceived from "../../views/materialManagement/goodsReceived/components/goodsReceived";
import GoodsReceivedDetail from "../../views/materialManagement/goodsReceived/components/goodsReceivedDetail/components/goodsReceivedDetail";
import Indent from "../../views/materialManagement/indent/components/indent";
import IndentDetails from "../../views/materialManagement/indent/indentDetails/components/indentDetails";
import MaterialTransfer from "../../views/materialManagement/materialTransfer/components/materialTransfer";
import MaterialTransferDetail from "../../views/materialManagement/materialTransfer/materialTransferDetail/components/materialTransferDetail";
import PurchaseOrder from "../../views/materialManagement/purchaseOrderIndent/components/purchaseOrder";
import PurchaseOrderDetail from "../../views/materialManagement/purchaseOrderIndent/purchaseOrderIndentDetail/components/purchaseOrderDetail";
import PurchasePriceConfiguration from "../../views/materialManagement/purchasePriceConfiguration/components/purchasePriceConfiguration";
import AttendanceReport from "../../views/reports/attendanceReport/components/attendanceReport";
import BalanceBillingReport from "../../views/reports/balanceBillingReport/components/balanceBillingReport";
import ExcavatorSummaryReport from "../../views/reports/excavatorSummaryReport/components/excavatorSummaryReport";
import GoodsReceivedReport from "../../views/reports/goodsReceivedReport/components/goodsReceivedReport";
import PurchaseOrderReport from "../../views/reports/purchaseOrderReport/components/purchaseOrderReport";
import PurchaseRatioReport from "../../views/reports/purchaseRatioReport/components/purchaseRatioReport";
import StockStatementReport from "../../views/reports/stockStatementReport/components/stockStatementReport";
import SubContractorAdvanceRequestSummaryReport from "../../views/reports/subContractorAdvanceRequestSummaryReport/components/subContractorAdvanceRequestSummaryReport";
import SubContractorBilledQty from "../../views/reports/subContractorBilledQty/components/subContractorBilledQty";
import SubContractorBillingReport from "../../views/reports/subContractorBillingReport/components/subContractorBillingReport";
import SubContractorBillingSummaryReport from "../../views/reports/subContractorBillingSummaryReport/components/subContractorBillingSummaryReport";
import SubContractorFoodAdvanceDetailReport from "../../views/reports/subContractorFoodAdvanceDetailReport/components/subContractorFoodAdvanceDetailReport";
import UserManagement from "../../views/userManagement/components/userManagement";
import RequireAuth from "../auth/requireAuth";

export default function RoutesComponent() {
  const { authenticated } = useAuth();

  return (
    <div>
      {authenticated ? (
        <Routes>
          <Route exact path={PATH_LOGIN} element={<Login />} />

          <Route path="/" element={<RequireAuth />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path={PATH_MASTER} element={<Master />} />
            <Route exact path={PATH_DASHBOARD} element={<Home />} />
            <Route
              exact
              path={PATH_USER_MANAGEMENT}
              element={<UserManagement />}
            />

            <Route
              exact
              path={PATH_SITE_CATEGORIES}
              element={<SiteCategories />}
            />
            <Route exact path={PATH_SITE} element={<Site />} />
            <Route exact path={PATH_UOM} element={<Uom />} />
            <Route exact path={PATH_TAX} element={<Tax />} />

            <Route
              exact
              path={PATH_SUPPLIER_CATEGORIES}
              element={<SupplierCategories />}
            />
            <Route exact path={PATH_SUPPLIER} element={<Supplier />} />

            <Route
              exact
              path={PATH_ITEM_CATEGORIES}
              element={<ItemCategories />}
            />
            <Route exact path={PATH_ITEM} element={<Item />} />
            <Route
              exact
              path={PATH_PURCHASE_PRICE_CONFIGURATION}
              element={<PurchasePriceConfiguration />}
            />
            <Route exact path={PATH_SALES_ORDERS} element={<SalesOrder />} />
            <Route
              exact
              path={PATH_SALES_ORDERS_WITH_DATE}
              element={<SalesOrder />}
            />
            <Route
              path={PATH_SALES_ORDERS_DETAIL}
              element={<SalesOrdersDetail />}
            />
            <Route exact path={PATH_INVOICE_WITH_DATE} element={<Invoice />} />
            <Route path={PATH_INVOICE} element={<Invoice />} />

            <Route path={PATH_INVOICE_DETAIL} element={<InvoiceDetail />} />

            <Route exact path={PATH_INDENT} element={<Indent />} />
            <Route exact path={PATH_INDENT_WITH_DATE} element={<Indent />} />
            <Route
              exact
              path={PATH_INDENT_DETAILS}
              element={<IndentDetails />}
            />
            <Route
              exact
              path={PATH_INDENT_DETAILS_WITH_PARAM}
              element={<IndentDetails />}
            />
            <Route path={PATH_PURCHASE_ORDER} element={<PurchaseOrder />} />
            <Route
              path={PATH_PURCHASE_ORDER_WITH_DATE}
              element={<PurchaseOrder />}
            />

            <Route
              path={PATH_PURCHASE_ORDER_DETAIL}
              element={<PurchaseOrderDetail />}
            />
            <Route
              path={PATH_PURCHASE_ORDER_DETAIL_WITHOUT_PARAM}
              element={<PurchaseOrderDetail />}
            />

            <Route path={PATH_GOODS_RECEIVED} element={<GoodsReceived />} />
            <Route
              path={PATH_GOODS_RECEIVED_WITH_DATE}
              element={<GoodsReceived />}
            />

            <Route path={PATH_CREDIT_NOTE} element={<CreditNote />} />
            <Route path={PATH_CREDIT_NOTE_WITH_DATE} element={<CreditNote />} />
            <Route
              path={PATH_CREDIT_NOTE_DETAIL}
              element={<CreditNoteDetail />}
            />
            <Route
              path={PATH_CREDIT_NOTE_DETAIL_WITH_PARAM}
              element={<CreditNoteDetail />}
            />

            <Route
              path={PATH_GOODS_RECEIVED_DETAIL}
              element={<GoodsReceivedDetail />}
            />

            <Route
              path={PATH_GOODS_RECEIVED_DETAIL_WITH_PARAM}
              element={<GoodsReceivedDetail />}
            />

            <Route
              path={PATH_MATERIAL_TRANSFER}
              element={<MaterialTransfer />}
            />
            <Route
              path={PATH_MATERIAL_TRANSFER_WITH_DATE}
              element={<MaterialTransfer />}
            />
            <Route
              path={PATH_MATERIAL_TRANSFER_DETAIL}
              element={<MaterialTransferDetail />}
            />
            <Route
              path={PATH_MATERIAL_TRANSFER_DETAIL_WITH_PARAM}
              element={<MaterialTransferDetail />}
            />

            <Route
              path={PATH_SUB_CONTRACTOR_FOOD_ADVANCE}
              element={<SubContractorFoodAdvance />}
            />

            <Route
              path={PATH_SUB_CONTRACTOR_FOOD_ADVANCE_WITH_DATE}
              element={<SubContractorFoodAdvance />}
            />

            <Route
              path={PATH_SUB_CONTRACTOR_FOOD_ADVANCE_DETAIL}
              element={<SubContractorFoodAdvanceDetail />}
            />
            <Route
              path={PATH_SUB_CONTRACTOR_FOOD_ADVANCE_DETAIL_WITH_PARAM}
              element={<SubContractorFoodAdvanceDetail />}
            />
            <Route
              path={PATH_SUB_CONTRACTOR_ADVANCE_REQUEST}
              element={<SubContractorAdvanceRequest />}
            />

            <Route
              path={PATH_SUB_CONTRACTOR_ADVANCE_REQUEST_WITH_DATE}
              element={<SubContractorAdvanceRequest />}
            />

            <Route
              path={PATH_SUB_CONTRACTOR_ADVANCE_REQUEST_DETAIL_WITH_PARAM}
              element={<SubContractorAdvanceRequestDetail />}
            />
            <Route
              path={PATH_SUB_CONTRACTOR_ADVANCE_REQUEST_DETAIL}
              element={<SubContractorAdvanceRequestDetail />}
            />

            <Route path={PATH_WORK_ORDER} element={<WorkOrder />} />
            <Route path={PATH_WORK_ORDER_WITH_DATE} element={<WorkOrder />} />
            <Route
              path={PATH_WORK_ORDER_DETAIL}
              element={<WorkOrderDetail />}
            />
            <Route
              path={PATH_WORK_ORDER_DETAIL_WITH_PARAM}
              element={<WorkOrderDetail />}
            />
            <Route path={PATH_DAILY_WORK_ENTRY} element={<DailyWorkEntry />} />
            <Route
              path={PATH_DAILY_WORK_ENTRY_WITH_DATE}
              element={<DailyWorkEntry />}
            />
            <Route
              path={PATH_DAILY_WORK_ENTRY_DETAILS}
              element={<DailyWorkEntryDetails />}
            />
            <Route
              path={PATH_DAILY_WORK_ENTRY_DETAILS_WITH_PARAM}
              element={<DailyWorkEntryDetails />}
            />

            <Route path={PATH_LABOURER_TYPE} element={<LabourerType />} />
            <Route path={PATH_ADD_EMPLOYEE} element={<Employee />} />
            <Route path={PATH_EMPLOYEE_TYPE} element={<EmployeeType />} />
            <Route
              path={PATH_MANAGER_ASSIGNMENT}
              element={<ManagerAssignment />}
            />
            <Route path={PATH_DESIGNATION} element={<Designation />} />
            <Route
              path={PATH_SUB_CONTRACTOR_BILLING}
              element={<SubContractorBilling />}
            />
            <Route
              path={PATH_SUB_CONTRACTOR_BILLING_WITH_DATE}
              element={<SubContractorBilling />}
            />

            <Route
              path={PATH_SUB_CONTRACTOR_BILLING_DETAIL}
              element={<SubContractorBillingDetail />}
            />
            <Route
              path={PATH_SUB_CONTRACTOR_BILLING_DETAIL_WITH_PARAM}
              element={<SubContractorBillingDetail />}
            />
            <Route
              path={PATH_PURCHASE_ORDER_REPORT}
              element={<PurchaseOrderReport />}
            />
            <Route
              path={PATH_GOODS_RECEIVED_REPORT}
              element={<GoodsReceivedReport />}
            />
            <Route
              path={PATH_STOCK_STATEMENT_REPORT}
              element={<StockStatementReport />}
            />

            {/* <Route
              path={PATH_PURCHASE_RATIO_REPORT}
              element={<PurchaseRatioReport />}
            /> */}
            <Route
              path={PATH_EXCAVATOR_SUMMARY_REPORT}
              element={<ExcavatorSummaryReport />}
            />

            <Route
              path={PATH_SUB_CONTRACTOR_BILLING_REPORT}
              element={<SubContractorBillingReport />}
            />
            <Route
              path={PATH_SUB_CONTRACTOR_ADVANCE_REQUEST_SUMMARY_REPORT}
              element={<SubContractorAdvanceRequestSummaryReport />}
            />
            {/* <Route
              path={PATH_SUB_CONTRACTOR_FOOD_ADVANCE_DETAIL_REPORT}
              element={<SubContractorFoodAdvanceDetailReport />}
            /> */}
            <Route
              path={PATH_SUB_CONTRACTOR_BILLING_SUMMARY_REPORT}
              element={<SubContractorBillingSummaryReport />}
            />
            <Route
              path={PATH_SUB_CONTRACTOR_BILLED_QTY}
              element={<SubContractorBilledQty />}
            />
            <Route
              path={PATH_ATTENDANCE_REPORT}
              element={<AttendanceReport />}
            />
            <Route
              path={PATH_BALANCE_BILLING_REPORT}
              element={<BalanceBillingReport />}
            />
            <Route path={PATH_TEMPLATE} element={<Temp />} />
          </Route>
        </Routes>
      ) : (
        <Login />
      )}
    </div>
  );
}
