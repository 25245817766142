import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, IconButton, TextField } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import React, { useEffect, useRef, useState } from "react";
import SideNav from "../../../../component/layouts/sideNav";
import {
  ADD,
  DELETE,
  GO,
  LABOUR_MANAGEMENT,
  SUB_CONTRACTOR_ADVANCE_REQUEST,
} from "../../../../resources/constant/strings/string";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  closureStatusRenderer,
  getProjectManagers,
  getSites,
  getSupplierCategories,
  getSuppliers,
  projectManagerCellRenderer,
  setProjectManagers,
  setSites,
  setSupplierCategories,
  setSuppliers,
  siteCellRenderer,
  supplierCellRenderer,
} from "../../../../component/cellRenderers/renderers";
import {
  LIGHT_GREY_BACKGROUND,
  PRUSSIAN_BLUE,
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { APILABOURMGMT } from "../../../../apihandler/labourmgmt";
import { APILOGIN } from "../../../../apihandler/login";
import { APIMASTER } from "../../../../apihandler/master";
import {
  setAlertConfirmDeletion,
  setAlertTitleText,
} from "../../../../component/modal/alertModal";
import {
  PATH_LOGIN,
  PATH_SUB_CONTRACTOR_ADVANCE_REQUEST,
  PATH_SUB_CONTRACTOR_ADVANCE_REQUEST_DETAIL,
} from "../../../../resources/constant/url/url";

export default function SubContractorAdvanceRequest() {
  let { date } = useParams();

  const [dateValue, setDateValue] = React.useState(null);
  const [toDateValue, setToDateValue] = React.useState(dayjs().endOf("month"));

  const navigate = useNavigate();

  const gridRef = useRef();

  useEffect(() => {
    if (!APILOGIN.GetIsLoggedIn()) navigate(PATH_LOGIN);
  }, []);

  const [selectedRowId, setSelectedRowId] = useState(0);

  const deleteModel = () => {
    if (selectedRowId <= 0) {
      return;
    }
    setAlertConfirmDeletion(
      "Confirmation needed",
      "Do you confirm on proceeding to delete this record?"
    ).then((consent) => {
      if (!consent.isConfirmed) return;

      console.log("delete the selected id: ", selectedRowId);
      APILABOURMGMT.DeleteSubContractorAdvance(selectedRowId)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.statusCode === 204) {
            const selectedData = gridRef.current.api.getSelectedRows();
            gridRef.current.api.applyTransaction({ remove: selectedData });
            setAlertTitleText("Success", "The record was deleted successfully");
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
          }
        });
    });
  };

  const updateModel = (model) => {
    gridRef.current.api.applyTransaction({ update: [model] });
  };

  const addModel = (model) => {
    gridRef.current.api.applyTransaction({ add: [model], addIndex: 0 });
  };

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows()[0].status === "P") {
      if (event.api.getSelectedRows().length > 0)
        setSelectedRowId(event.api.getSelectedRows()[0].id);
      else setSelectedRowId(0);
    }
  };

  const handleEditData = (oldData) => {
    console.log(oldData);
  };

  const projectManagers = getProjectManagers();

  const projectManagerProps = {
    options: projectManagers,
    getOptionLabel: (option) => option?.name || "",
  };
  const supplierCategories = getSupplierCategories().filter((x) => {
    return x.dependentvalue === "L";
  });
  const suppliers = getSuppliers().filter((x) => {
    return (
      supplierCategories.filter((y) => y.value === x.dependentvalue).length > 0
    );
  });

  const supplierProps = {
    options: suppliers,
    getOptionLabel: (option) => option?.name || "",
  };
  const sites = getSites();

  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };

  const columnDefs = [
    {
      headerName: "Entry Date",
      field: "date",
      checkboxSelection: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 1.7,
      headerName: "Contractor Name",
      field: "contractorId",
      autoHeight: true,
      wrapText: true,
      valueGetter: (params) => {
        return supplierCellRenderer({
          value: params.data.contractorId,
        });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 1.7,
      headerName: "Site",
      field: "siteId",
      autoHeight: true,
      wrapText: true,
      valueGetter: (params) => {
        return siteCellRenderer({
          value: params.data.siteId,
        });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 1.5,
      headerName: "Project Manager",
      field: "projectManager",
      autoHeight: true,
      wrapText: true,
      valueGetter: (params) => {
        return projectManagerCellRenderer({
          value: params.data.projectManager,
        });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 0.8,
      headerName: "Pending Amount",
      field: "pendingAmount",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Status",
      field: "status",
      valueGetter: (params) => {
        return closureStatusRenderer({
          value: params.data.status,
        });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 1,
      headerName: "Details",
      field: "id",
      sortable: false,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => (
        <div style={{ display: "flex" }}>
          {params.data?.status === "P" && (
            <IconButton
              aria-label="edit"
              onClick={() => {
                navigate(
                  PATH_SUB_CONTRACTOR_ADVANCE_REQUEST_DETAIL + params.data.id,
                  {
                    state: {
                      date: dateValue.format("YYYY-MM-DD"),
                      isEdit: true,
                    },
                  }
                );
              }}
            >
              <EditIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
            </IconButton>
          )}
          <IconButton
            aria-label="edit"
            onClick={() => {
              navigate(
                PATH_SUB_CONTRACTOR_ADVANCE_REQUEST_DETAIL + params.data.id,
                {
                  state: {
                    date: dateValue.format("YYYY-MM-DD"),
                    isEdit: false,
                  },
                }
              );
            }}
          >
            <VisibilityIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
          </IconButton>
          <IconButton
            aria-label="edit"
            onClick={() => {
              let filename = "";
              let localDto = {};
              const pm = projectManagerProps.options.filter(
                (x) => x.value === params.data.projectManager
              );
              localDto.projectManagerName = pm.length > 0 ? pm[0].name : "";
              const site = siteProps.options.filter(
                (x) => x.value === params.data.siteId
              );
              localDto.siteName = site.length > 0 ? site[0].name : "";
              const supplier = supplierProps.options.filter(
                (x) => x.value === params.data.contractorId
              );
              localDto.contractorName =
                supplier.length > 0 ? supplier[0].name : "";
              localDto.id = params.data.id ? params.data.id : "";
              localDto.siteId = params.data.siteId ? params.data.siteId : "";
              localDto.contractorId = params.data.contractorId
                ? params.data.contractorId
                : "";
              localDto.projectManager = params.data.projectManager
                ? params.data.projectManager
                : "";
              localDto.reasonForAdvance = params.data.reasonForAdvance
                ? params.data.reasonForAdvance
                : "";
              localDto.amountBilledTillDate = params.data.amountBilledTillDate
                ? params.data.amountBilledTillDate
                : 0;
              localDto.retentionAmount = params.data.retentionAmount
                ? params.data.retentionAmount
                : 0;
              localDto.amountRecommended = params.data.amountRecommended
                ? params.data.amountRecommended
                : 0;

              localDto.amountRequested = params.data.amountRequested
                ? params.data.amountRequested
                : 0;
              localDto.numberOfInstallments = params.data.numberOfInstallments
                ? params.data.numberOfInstallments
                : 0;

              localDto.paidAmount = params.data.paidAmount
                ? params.data.paidAmount
                : 0;
              localDto.remark = params.data.remark ? params.data.remark : "";
              localDto.advanceAmountPaid = params.data.advanceAmountPaid
                ? params.data.advanceAmountPaid
                : 0;

              localDto.pendingAmount = params.data.pendingAmount
                ? params.data.pendingAmount
                : 0;
              localDto.status = params.data.status ? params.data.status : "";
              localDto.billListView = params.data.billListView
                ? params.data.billListView
                : [];

              localDto.date = params.data.date
                ? dayjs(params.data.date, "DD-MM-YYYY").format("YYYY-MM-DD")
                : [];

              localDto.retentionBalanceAmount = params.data
                .retentionBalanceAmount
                ? params.data.retentionBalanceAmount
                : 0;

              localDto.testingBalanceAmount = params.data.testingBalanceAmount
                ? params.data.testingBalanceAmount
                : 0;

              APILABOURMGMT.SubContractorAdvanceExport(localDto)
                .then((res) => {
                  const contentType = res.headers.get("content-type");
                  if (contentType.startsWith("application/json")) {
                    return Promise.reject(res.json());
                  } else {
                    const header = res.headers.get("Content-Disposition");
                    if (header !== null) {
                      const parts = header.split(";");
                      filename = parts[1].split("=")[1].replaceAll('"', "");
                    } else {
                      filename = `subcontractorbilling_${params.data.id}.html`;
                    }

                    return res.blob();
                  }
                })
                .then((blob) => {
                  if (blob !== null) {
                    if (localDto.exportExcel) {
                      let url = window.URL.createObjectURL(blob);
                      let a = document.createElement("a");
                      a.href = url;
                      a.download = filename.replace(".html", ".xlsx");
                      document.body.appendChild(a);
                      a.click();
                      a.remove();
                    } else {
                      blob.text().then((x) => {
                        let html = x;
                        html = html.replace(
                          "<script>",
                          '<script type="text/javascript">'
                        );
                        let blob = new Blob([html], { type: "text/html" });
                        let url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
                      
                        let win = window.open(url, '_blank', '');
                        setTimeout(()=>{
                            win.print();
                        }, 2000);
                      });
                    }
                  }
                })
                .catch((m) => {
                  setAlertTitleText(
                    "Oops...",
                    `Something went wrong...${m.message}`
                  );
                });
            }}
          >
            <PrintIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
          </IconButton>
        </div>
      ),
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };

  const fetchSubContractorAdvance = (d1, d2) => {
    return APILABOURMGMT.SubContractorAdvances(
      d1.format("YYYY-MM-DD"),
      d2.format("YYYY-MM-DD")
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) {
          setAlertTitleText("Oops..", `An error occurred ${resp.message}`);
          return;
        }
        const data = resp.response.sort((a, b) => b.id - a.id);
        return Promise.resolve(data);
      });
  };

  const onGridReady = (params) => {
    if (date) {
      setDateValue(dayjs(date));
    } else {
      setDateValue(dayjs().startOf("month"));
    }

    APIMASTER.ProjectManagerOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setProjectManagers(resp.response);
      });

    APIMASTER.SiteOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSites(resp.response);
      });
    APIMASTER.SupplierCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSupplierCategories(resp.response);
      });

    APIMASTER.SupplierOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSuppliers(resp.response);
      });

    if (date) {
      fetchSubContractorAdvance(dayjs(date), dayjs(toDateValue)).then(
        (data) => {
          params.api.applyTransaction({ add: data });
        }
      );
    } else {
      fetchSubContractorAdvance(
        dayjs().startOf("month"),
        dayjs().endOf("month")
      ).then((data) => {
        params.api.applyTransaction({ add: data });
      });
    }
  };

  return (
    <SideNav
      currentComponent={LABOUR_MANAGEMENT}
      subComponent={SUB_CONTRACTOR_ADVANCE_REQUEST}
      subComponentPath={PATH_SUB_CONTRACTOR_ADVANCE_REQUEST}
      detailComponent=""
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginBottom: 10,
          marginRight: 10,
        }}
      >
        <Button
          style={{
            backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: WHITE,
            minWidth: "180px",
            minHeight: "45px",
          }}
          onClick={() => {
            navigate(PATH_SUB_CONTRACTOR_ADVANCE_REQUEST_DETAIL + 0, {
              state: {
                date: dateValue.format("YYYY-MM-DD"),
                isEdit: false,
              },
            });
          }}
        >
          {ADD}
        </Button>

        <Button
          onClick={() => {
            deleteModel();
          }}
          variant="outlined"
          sx={{
            borderColor: SPANISH_GREEN,
          }}
          style={{
            //backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: SPANISH_GREEN,
            minWidth: "180px",
            minHeight: "45px",
            marginRight: 10,
          }}
        >
          {DELETE}
        </Button>
      </div>
      {/* delete , add button div ends */}
      <div
        style={{
          backgroundColor: LIGHT_GREY_BACKGROUND,
          borderRadius: "5px",
          minWidth: "90%",
          minHeight: "50px",
          padding: 20,
          marginBottom: 10,
          borderStyle: "solid",
          border: "1x",
          borderColor: "#eaeaea",
          display: "flex",
        }}
      >
        <div style={{ display: "flex" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              id="dateId"
              label="From Date"
              value={dateValue ? dateValue : dateValue}
              //minDate={dayjs("2017-01-01")}
              onChange={(newValue) => {
                setDateValue(newValue);
                //setToDateValue(dayjs(newValue).endOf("month"));
              }}
              inputFormat="DD-MM-YYYY"
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              id="toDateId"
              label="To Date"
              value={toDateValue}
              //minDate={dayjs("2017-01-01")}
              onChange={(newValue) => {
                setToDateValue(newValue);
              }}
              inputFormat="DD-MM-YYYY"
              renderInput={(params) => (
                <TextField {...params} style={{ marginLeft: 10 }} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div
          align="right"
          style={{
            width: "100%",
            right: 0,
          }}
        >
          <Button
            style={{
              backgroundColor: SPANISH_GREEN,
              textTransform: "capitalize",
              fontSize: 14,
              color: WHITE,
              minWidth: "129px",
              minHeight: "45px",
              marginTop: 5,
            }}
            onClick={() => {
              fetchSubContractorAdvance(dateValue, toDateValue).then((data) => {
                try {
                  const rowData = [];
                  gridRef.current.api.forEachNode(function (node) {
                    rowData.push(node.data);
                  });
                  gridRef.current.api.applyTransaction({
                    remove: rowData,
                  });
                } catch (e) {}
                try {
                  gridRef.current.api.applyTransaction({ add: data });
                } catch (e) {}
              });
            }}
          >
            {GO}
          </Button>
        </div>
      </div>
      {/* aggrid div start */}
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "570px" }}>
          <AgGridReact
            copyGroupHeadersToClipboard={true}
            enableRangeSelection={true}
            columnDefs={columnDefs}
            ref={gridRef}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            paginationAutoPageSize={true}
            pagination={true}
            rowSelection="multiple"
            getRowId={(params) => params.data.id}
            onSelectionChanged={onRowSelectionChanged}
          ></AgGridReact>
        </div>
      </div>
    </SideNav>
  );
}
