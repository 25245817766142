import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { PATH_LOGIN } from "../../resources/constant/url/url";

export default function RequireAuth(props) {
  const { authenticated } = useAuth();
  const location = useLocation();

  return authenticated === true ? (
    <Outlet />
  ) : (
    <Navigate to={PATH_LOGIN} replace state={{ path: location.pathname }} />
  );
}
