import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import React, { useEffect, useRef, useState } from "react";
import SideNav from "../../../../component/layouts/sideNav";
import {
  ADD,
  DELETE,
  DOWNLOAD,
  GO,
  MATERIAL_MANAGEMENT,
  PURCHASE_PRICE_CONFIGURATION,
  UPLOAD,
} from "../../../../resources/constant/strings/string";

import {
  itemCellRenderer,
  setItemList,
  setItems,
  setSupplierCategories,
  setSuppliers,
  setUoms,
  supplierCellRenderer,
} from "../../../../component/cellRenderers/renderers";
import {
  LIGHT_GREY_BACKGROUND,
  PRUSSIAN_BLUE,
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { APILOGIN } from "../../../../apihandler/login";
import { APIMASTER } from "../../../../apihandler/master";
import { APIMATERIALMGMT } from "../../../../apihandler/materialmgmt";
import {
  setAlertConfirmDeletion,
  setAlertTitleText,
} from "../../../../component/modal/alertModal";
import {
  PATH_LOGIN,
  PATH_PURCHASE_PRICE_CONFIGURATION,
} from "../../../../resources/constant/url/url";
import Modal from "./modal";
import UploadModal from "./uploadModal";

export default function PurchasePriceConfiguration() {
  let { date } = useParams();
  const [dateValue, setDateValue] = React.useState(null);
  const [toDateValue, setToDateValue] = React.useState(dayjs().endOf("month"));

  const navigate = useNavigate();

  const gridRef = useRef();

  useEffect(() => {
    if (!APILOGIN.GetIsLoggedIn()) navigate(PATH_LOGIN);
  }, []);

  const [selectedRowId, setSelectedRowId] = useState(0);

  let initialValue = {};

  const setInitialValueForAdd = () => {
    initialValue = {
      id: 0,
      supplierId: 0,
      itemId: 0,
      description: "",
      effectiveDate: dayjs(),
      rate: 0,
      equipmentCapacity: 0,
    };
  };

  let uploadInitialValue = {};

  const setUploadInitialValue = () => {
    uploadInitialValue = {
      effectiveDate: "07/02/2022",
      supplier: "",
      dataFirstRow: 2,
    };
  };

  const deleteModel = () => {
    if (selectedRowId <= 0) {
      return;
    }
    setAlertConfirmDeletion(
      "Confirmation needed",
      "Do you confirm on proceeding to delete this record?"
    ).then((consent) => {
      if (!consent.isConfirmed) return;

      console.log("delete the selected id: ", selectedRowId);
      APIMATERIALMGMT.DeletePurchasePriceConfig(selectedRowId)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.statusCode === 204) {
            const selectedData = gridRef.current.api.getSelectedRows();
            gridRef.current.api.applyTransaction({ remove: selectedData });
            setAlertTitleText("Success", "The record was deleted successfully");
            setOpenModal(false);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
            setOpenModal(false);
          }
        });
    });
  };

  const updateModel = (model) => {
    fetchPurchasePriceConfigs(dateValue, toDateValue).then((data) => {
      try {
        const rowData = [];
        gridRef.current.api.forEachNode(function (node) {
          rowData.push(node.data);
        });
        gridRef.current.api.applyTransaction({
          remove: rowData,
        });
      } catch (e) {}
      try {
        gridRef.current.api.applyTransaction({ add: data });
      } catch (e) {}
    });
  };

  const addModel = (model) => {
    try {
      gridRef.current.api.applyTransaction({ add: [...model], addIndex: 0 });
    } catch {
      gridRef.current.api.applyTransaction({ add: [model], addIndex: 0 });
    }
  };

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId(event.api.getSelectedRows()[0].id);
    else setSelectedRowId(0);
  };

  const [formTitle, setFormTitle] = useState("Add supplier");
  const [uploadFormTitle, setUploadFormTitle] = useState("Upload");
  const handleEditData = (oldData) => {
    console.log(oldData);
    initialValue = {
      id: oldData.id,
      supplierId: oldData.supplierId,
      itemId: oldData.itemId,
      description: oldData.description,
      effectiveDate: oldData.effectiveDate.split("-").reverse().join("-"),
      rate: oldData.rate,
      equipmentCapacity: oldData.equipmentCapacity,
    };
    setFormData(initialValue);
    setFormTitle("Edit Purchase Price Configuration");
    setOpenModal(true);
  };

  const columnDefs = [
    {
      flex: 0.8,
      headerName: "Effective Date",
      field: "effectiveDate",
      checkboxSelection: false,
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 1.3,
      headerName: "Supplier Name",
      field: "supplierId",
      autoHeight: true,
      valueGetter: (params) => {
        return supplierCellRenderer({ value: params.data.supplierId });
      },
      wrapText: true,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 1.5,
      headerName: "Item",
      field: "prefix",
      valueGetter: (params) => {
        return itemCellRenderer({ value: params.data.itemId });
      },
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 0.6,
      headerName: "Rate",
      field: "rate",

      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Description",
      field: "description",
      filter: "agNumberColumnFilter",
      floatingFilter: true,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      flex: 0.5,
      headerName: "Edit",
      field: "id",
      sortable: false,
      filter: false,
      floatingFilter: false,

      cellRenderer: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <IconButton
            aria-label="edit"
            onClick={() => {
              handleEditData(params.data);
            }}
          >
            <EditIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
          </IconButton>
        </div>
      ),
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
  };

  const onGridReady = (params) => {
    APIMASTER.UomOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setUoms(resp.response);
      });
    APIMASTER.SupplierCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSupplierCategories(resp.response);
      });
    APIMASTER.SupplierOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSuppliers(resp.response);
      });
    APIMASTER.Items()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        let itemOptions = [];
        resp.response.forEach((element) => {
          itemOptions.push({
            name: element.itemName,
            value: element.id,
            dependentvalue: element.categoryId,
          });
        });
        setItems(itemOptions);
        setItemList(resp.response);
      })
      .then((x) => {
        if (date) {
          setDateValue(dayjs(date));
          setToDateValue(dayjs(date).endOf("month"));
        } else {
          setDateValue(dayjs().startOf("month"));
          setToDateValue(dayjs().endOf("month"));
        }

        if (date) {
          fetchPurchasePriceConfigs(dayjs(date), dayjs(toDateValue)).then(
            (data) => {
              params.api.applyTransaction({ add: data });
            }
          );
        } else {
          fetchPurchasePriceConfigs(
            dayjs().startOf("month"),
            dayjs().endOf("month")
          ).then((data) => {
            params.api.applyTransaction({ add: data });
          });
        }
      });
  };

  const fetchPurchasePriceConfigs = (d1, d2) => {
    return APIMATERIALMGMT.PurchasePriceConfigs(
      d1.format("YYYY-MM-DD"),
      d2.format("YYYY-MM-DD")
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) {
          setAlertTitleText("Oops..", `An error occurred ${resp.message}`);
          return;
        }
        const data = resp.response.sort((a, b) => b.id - a.id);
        return Promise.resolve(data);
      });
  };

  const [openModal, setOpenModal] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [formData, setFormData] = useState(initialValue);
  const [uploadFormData, setUploadFormData] = useState(uploadInitialValue);

  return (
    <SideNav
      currentComponent={MATERIAL_MANAGEMENT}
      subComponent={PURCHASE_PRICE_CONFIGURATION}
      subComponentPath={PATH_PURCHASE_PRICE_CONFIGURATION}
      detailComponent=""
    >
      {openModal && (
        <Modal
          style={{ align: "center" }}
          setOpenModal={setOpenModal}
          updateModel={updateModel}
          addModel={addModel}
          model={formData}
          formTitle={formTitle}
        ></Modal>
      )}
      {openUploadModal && (
        <UploadModal
          style={{ align: "center" }}
          setOpenUploadModal={setOpenUploadModal}
          updateModel={updateModel}
          addModel={addModel}
          model={uploadFormData}
          uploadFormTitle={uploadFormTitle}
        ></UploadModal>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginBottom: 10,
          marginRight: 10,
        }}
      >
        <Button
          style={{
            backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: WHITE,
            minWidth: "180px",
            minHeight: "45px",
          }}
          onClick={() => {
            setInitialValueForAdd();
            setFormData(initialValue);
            setFormTitle("Add Purchase Price Configuration");
            setOpenModal(true);
          }}
        >
          {ADD}
        </Button>

        <Button
          onClick={() => {
            deleteModel();
          }}
          variant="outlined"
          sx={{
            borderColor: SPANISH_GREEN,
          }}
          style={{
            //backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: SPANISH_GREEN,
            minWidth: "180px",
            minHeight: "45px",
            marginRight: 10,
          }}
        >
          {DELETE}
        </Button>
        <Button
          style={{
            backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: WHITE,
            minWidth: "180px",
            minHeight: "45px",
            marginRight: 10,
          }}
          onClick={() => {
            let filename = "";
            APIMATERIALMGMT.PurchasePriceConfigDownloadTemplate()
              .then((res) => {
                const contentType = res.headers.get("content-type");
                if (contentType.startsWith("application/json")) {
                  return Promise.reject(res.json());
                } else {
                  const header = res.headers.get("Content-Disposition");
                  if (header !== null) {
                    const parts = header.split(";");
                    filename = parts[1].split("=")[1].replaceAll('"', "");
                  } else {
                    filename = "Purchase Price Upload Template.xlsx";
                  }

                  return res.blob();
                }
              })
              .then((blob) => {
                if (blob !== null) {
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement("a");
                  a.href = url;
                  a.download = filename;
                  document.body.appendChild(a);
                  a.click();
                  a.remove();
                }
              })
              .catch((m) => {
                setAlertTitleText(
                  "Oops...",
                  `Something went wrong...${m.message}`
                );
              });
          }}
        >
          {DOWNLOAD}
        </Button>
        <Button
          onClick={() => {
            setUploadInitialValue();
            setUploadFormData(uploadInitialValue);
            setUploadFormTitle("Upload");
            setOpenUploadModal(true);
          }}
          variant="outlined"
          sx={{
            borderColor: SPANISH_GREEN,
          }}
          style={{
            //backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: SPANISH_GREEN,
            minWidth: "180px",
            minHeight: "45px",
            marginRight: 10,
          }}
        >
          {UPLOAD}
        </Button>
      </div>
      {/* delete , add button div ends */}
      <div
        style={{
          backgroundColor: LIGHT_GREY_BACKGROUND,
          borderRadius: "5px",
          minWidth: "90%",
          minHeight: "50px",
          padding: 20,
          marginBottom: 10,
          borderStyle: "solid",
          border: "1x",
          borderColor: "#eaeaea",
          display: "flex",
        }}
      >
        <div style={{ display: "flex" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              id="dateId"
              label="From Date"
              value={dateValue ? dateValue : dateValue}
              //minDate={dayjs("2017-01-01")}
              onChange={(newValue) => {
                setDateValue(newValue);
                //setToDateValue(dayjs(newValue).endOf("month"));
              }}
              inputFormat="DD-MM-YYYY"
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              id="toDateId"
              label="To Date"
              value={toDateValue}
              //minDate={dayjs("2017-01-01")}
              onChange={(newValue) => {
                setToDateValue(newValue);
              }}
              inputFormat="DD-MM-YYYY"
              renderInput={(params) => (
                <TextField {...params} style={{ marginLeft: 10 }} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div
          align="right"
          style={{
            width: "100%",
            right: 0,
          }}
        >
          <Button
            style={{
              backgroundColor: SPANISH_GREEN,
              textTransform: "capitalize",
              fontSize: 14,
              color: WHITE,
              minWidth: "129px",
              minHeight: "45px",
              marginTop: 5,
            }}
            onClick={() => {
              fetchPurchasePriceConfigs(dateValue, toDateValue).then((data) => {
                try {
                  const rowData = [];
                  gridRef.current.api.forEachNode(function (node) {
                    rowData.push(node.data);
                  });
                  gridRef.current.api.applyTransaction({
                    remove: rowData,
                  });
                } catch (e) {}
                try {
                  gridRef.current.api.applyTransaction({ add: data });
                } catch (e) {}
              });
            }}
          >
            {GO}
          </Button>
        </div>
      </div>
      {/* aggrid div start */}
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "570px" }}>
          <AgGridReact
            copyGroupHeadersToClipboard={true}
            enableRangeSelection={true}
            columnDefs={columnDefs}
            ref={gridRef}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            paginationAutoPageSize={true} pagination={true}
            //rowHeight={80}
            rowSelection="multiple"
            getRowId={(params) => params.data.id}
            onSelectionChanged={onRowSelectionChanged}
          ></AgGridReact>
        </div>
      </div>
    </SideNav>
  );
}
