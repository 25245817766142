import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, IconButton, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import React, { useEffect, useRef, useState } from "react";
import SideNav from "../../../../component/layouts/sideNav";
import {
  ADD,
  DELETE,
  GO,
  MATERIAL_MANAGEMENT,
  MATERIAL_TRANSFER,
} from "../../../../resources/constant/strings/string";

import {
  employeeCellRenderer,
  setEmployees,
  setItemCategories,
  setItemList,
  setItems,
  setSiteCategories,
  setSites,
  setSuppliers,
  setUoms,
  setUsers,
  siteCellRenderer,
  userCellRenderer,
} from "../../../../component/cellRenderers/renderers";
import {
  LIGHT_GREY_BACKGROUND,
  PRUSSIAN_BLUE,
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { APILOGIN } from "../../../../apihandler/login";
import { APIMASTER } from "../../../../apihandler/master";
import { APIMATERIALMGMT } from "../../../../apihandler/materialmgmt";
import {
  setAlertConfirmDeletion,
  setAlertTitleText,
} from "../../../../component/modal/alertModal";
import {
  PATH_LOGIN,
  PATH_MATERIAL_TRANSFER,
  PATH_MATERIAL_TRANSFER_DETAIL,
} from "../../../../resources/constant/url/url";

export default function MaterialTransfer() {
  let { date } = useParams();

  const [dateValue, setDateValue] = React.useState(null);
  const [toDateValue, setToDateValue] = React.useState(dayjs().endOf("month"));

  const navigate = useNavigate();

  const gridRef = useRef();

  useEffect(() => {
    if (!APILOGIN.GetIsLoggedIn()) navigate(PATH_LOGIN);
  }, []);

  const [selectedRowId, setSelectedRowId] = useState(0);

  const deleteModel = () => {
    if (selectedRowId <= 0) {
      return;
    }
    setAlertConfirmDeletion(
      "Confirmation needed",
      "Do you confirm on proceeding to delete this record?"
    ).then((consent) => {
      if (!consent.isConfirmed) return;

      console.log("delete the selected id: ", selectedRowId);
      APIMATERIALMGMT.DeleteMaterialTransfer(selectedRowId)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.statusCode === 204) {
            const selectedData = gridRef.current.api.getSelectedRows();
            gridRef.current.api.applyTransaction({ remove: selectedData });
            setAlertTitleText("Success", "The record was deleted successfully");
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
          }
        });
    });
  };

  const updateModel = (model) => {
    gridRef.current.api.applyTransaction({ update: [model] });
  };

  const addModel = (model) => {
    gridRef.current.api.applyTransaction({ add: [model], addIndex: 0 });
  };

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId(event.api.getSelectedRows()[0].id);
    else setSelectedRowId(0);
  };

  const handleEditData = (oldData) => {
    console.log(oldData);
  };

  const columnDefs = [
    {
      flex: 1.5,
      headerName: "Transaction No",
      field: "transactionNo",
      checkboxSelection: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Transaction Date",
      field: "transactionDate",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      flex: 1.5,
      headerName: "From Site",
      field: "fromSite",
      autoHeight: true,
      wrapText: true,
      valueGetter: (params) => {
        return siteCellRenderer({ value: params.data.fromSite });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 1.5,
      headerName: "To Site",
      field: "toSite",
      autoHeight: true,
      wrapText: true,
      valueGetter: (params) => {
        return siteCellRenderer({ value: params.data.toSite });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Reported By",
      field: "reportedBy",
      filter: false,
      floatingFilter: false,
      valueGetter: (params) => {
        return employeeCellRenderer({ value: params.data.reportedBy });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Modified By",
      field: "modifiedBy",
      filter: false,
      floatingFilter: false,
      valueGetter: (params) => {
        return userCellRenderer({ value: params.data.modifiedBy });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Modified Date",
      field: "modifiedDate",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      flex: 0.7,
      headerName: "Action",
      field: "id",
      sortable: false,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            aria-label="edit"
            onClick={() => {
              navigate(PATH_MATERIAL_TRANSFER_DETAIL + params.data.id, {
                state: {
                  date: dateValue.format("YYYY-MM-DD"),
                  isEdit: false,
                },
              });
            }}
          >
            <VisibilityIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
          </IconButton>
        </div>
      ),
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };

  const fetchMaterialTransfers = (d1, d2) => {
    return APIMATERIALMGMT.MaterialTransfers(
      d1.format("YYYY-MM-DD"),
      d2.format("YYYY-MM-DD")
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) {
          setAlertTitleText("Oops..", `An error occurred ${resp.message}`);
          return;
        }
        const data = resp.response.sort((a, b) => b.id - a.id);
        return Promise.resolve(data);
      });
  };

  const onGridReady = (params) => {
    if (date) {
      setDateValue(dayjs(date));
    } else {
      setDateValue(dayjs().startOf("month"));
    }

    APIMASTER.SupplierOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSuppliers(resp.response);
      });
    APIMASTER.UomOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setUoms(resp.response);
      });
    APIMASTER.UserOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setUsers(resp.response);
      });

    APIMASTER.EmployeeOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setEmployees(resp.response);
      });

    APIMASTER.ItemCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setItemCategories(resp.response);
      });

    APIMASTER.SiteCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSiteCategories(resp.response);
      });

    APIMASTER.SiteOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSites(resp.response);
      });

    APIMASTER.Items()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        let itemOptions = [];
        resp.response.forEach((element) => {
          itemOptions.push({
            name: element.itemName,
            value: element.id,
            dependentvalue: element.categoryId,
          });
        });
        setItems(itemOptions);
        setItemList(resp.response);
      });

    if (date) {
      fetchMaterialTransfers(dayjs(date), dayjs(toDateValue)).then((data) => {
        params.api.applyTransaction({ add: data });
      });
    } else {
      fetchMaterialTransfers(
        dayjs().startOf("month"),
        dayjs().endOf("month")
      ).then((data) => {
        params.api.applyTransaction({ add: data });
      });
    }
  };

  return (
    <SideNav
      currentComponent={MATERIAL_MANAGEMENT}
      subComponent={MATERIAL_TRANSFER}
      subComponentPath={PATH_MATERIAL_TRANSFER}
      detailComponent=""
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginBottom: 10,
          marginRight: 10,
        }}
      >
        <Button
          style={{
            backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: WHITE,
            minWidth: "180px",
            minHeight: "45px",
          }}
          onClick={() => {
            navigate(PATH_MATERIAL_TRANSFER_DETAIL + 0, {
              state: {
                date: dateValue.format("YYYY-MM-DD"),
                isEdit: false,
              },
            });
          }}
        >
          {ADD}
        </Button>

        <Button
          onClick={() => {
            deleteModel();
          }}
          variant="outlined"
          sx={{
            borderColor: SPANISH_GREEN,
          }}
          style={{
            //backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: SPANISH_GREEN,
            minWidth: "180px",
            minHeight: "45px",
            marginRight: 10,
          }}
        >
          {DELETE}
        </Button>
      </div>
      {/* delete , add button div ends */}
      <div
        style={{
          backgroundColor: LIGHT_GREY_BACKGROUND,
          borderRadius: "5px",
          minWidth: "90%",
          minHeight: "50px",
          padding: 20,
          marginBottom: 10,
          borderStyle: "solid",
          border: "1x",
          borderColor: "#eaeaea",
          display: "flex",
        }}
      >
        <div style={{ display: "flex" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              id="dateId"
              label="From Date"
              value={dateValue ? dateValue : dateValue}
              //minDate={dayjs("2017-01-01")}
              onChange={(newValue) => {
                setDateValue(newValue);
                //setToDateValue(dayjs(newValue).endOf("month"));
              }}
              inputFormat="DD-MM-YYYY"
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              id="toDateId"
              label="To Date"
              value={toDateValue}
              //minDate={dayjs("2017-01-01")}
              onChange={(newValue) => {
                setToDateValue(newValue);
              }}
              inputFormat="DD-MM-YYYY"
              renderInput={(params) => (
                <TextField {...params} style={{ marginLeft: 10 }} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div
          align="right"
          style={{
            width: "100%",
            right: 0,
          }}
        >
          <Button
            style={{
              backgroundColor: SPANISH_GREEN,
              textTransform: "capitalize",
              fontSize: 14,
              color: WHITE,
              minWidth: "129px",
              minHeight: "45px",
              marginTop: 5,
            }}
            onClick={() => {
              fetchMaterialTransfers(dateValue, toDateValue).then((data) => {
                try {
                  const rowData = [];
                  gridRef.current.api.forEachNode(function (node) {
                    rowData.push(node.data);
                  });
                  gridRef.current.api.applyTransaction({
                    remove: rowData,
                  });
                } catch (e) {}
                try {
                  gridRef.current.api.applyTransaction({ add: data });
                } catch (e) {}
              });
            }}
          >
            {GO}
          </Button>
        </div>
      </div>
      {/* aggrid div start */}
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "570px" }}>
          <AgGridReact
            copyGroupHeadersToClipboard={true}
            enableRangeSelection={true}
            columnDefs={columnDefs}
            ref={gridRef}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            paginationAutoPageSize={true} pagination={true}
            rowSelection="multiple"
            getRowId={(params) => params.data.id}
            onSelectionChanged={onRowSelectionChanged}
          ></AgGridReact>
        </div>
      </div>
    </SideNav>
  );
}
