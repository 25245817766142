import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TableBody,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  BLACK,
  SPANISH_GREEN,
  WHITE,
} from "../../../../../resources/constant/colors/theme";

import {
  getItemCategories,
  getItemList,
  getItems,
  getUoms,
  globalFilter,
} from "../../../../../component/cellRenderers/renderers";
import "./editModal.css";
import {
  setAlertConfirmDeletion,
  setAlertTitleText,
} from "../../../../../component/modal/alertModal";

function EditModal({
  setOpenModal,
  model,
  formTitle,
  updateModel,
  addModel,
  viewModel,
  onAdd,
  onUpdate,
}) {
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    //only required field validation so direct api call
    if (dto.id <= 0) onAdd(dto);
    else onUpdate(dto);

    setOpenModal(false);
  }

  const [dto, setDTO] = useState(model);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (isNaN(value) || name === "description")
        setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    } else {
      setDTO({ ...dto, [name]: value });
    }
  };

  const itemCategories = getItemCategories();

  const itemCategoryProps = {
    options: itemCategories,
    getOptionLabel: (option) => option?.name || "",
  };
  const items = getItems();
  const itemList = getItemList();
  const uomList = getUoms();
  const uomProps = {
    options: uomList,
    getOptionLabel: (option) => option?.name || "",
  };

  const itemProps = {
    options: items,
    getOptionLabel: (option) => option?.name || "",
  };

  return (
    <div className="modalBackgroundForIndentEdit">
      {/* div for top right close button */}
      <form>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton
            style={{ width: "auto", align: "center", marginRight: 5 }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* top right close button div closed */}

        <div
          style={{
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <table style={{ width: "100%", margin: 5 }}>
            <TableBody>
              <tr>
                <td>
                  <Typography
                    fontSize={18}
                    fontWeight={600}
                    fontspacing={"normal"}
                    textTransform="capitalize"
                    align="left"
                  >
                    {formTitle}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    id={`autocomplete-1`}
                    {...itemCategoryProps}
                    variant="standard"
                    disabled={dto.id > 0}
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    defaultValue={
                      itemCategoryProps.options.find(
                        (v) => v?.value === dto.categoryId
                      ) || {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, categoryId: v?.value });
                        else setDTO({ ...dto, categoryId: Number(v?.value) });
                      } else {
                        setDTO({ ...dto, categoryId: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Item Category"
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          minHeight: "48.5px",
                          width: "200px",
                          height: "48.5px",
                          marginBottom: 5,
                          marginLeft: 5,
                        }}
                        InputLabelProps={{
                          style: { color: "grey" },
                        }}
                      />
                    )}
                  />
                </td>
                <td>
                  <Autocomplete
                    id={`autocomplete-2`}
                    {...itemProps}
                    disabled={dto.id > 0}
                    autoComplete
                    autoHighlight
                    autoSelect
                    variant="standard"
                    filterOptions={(o, s) => {
                      let options = globalFilter(o, s);
                      let newOptions = [];
                      options.forEach((element) => {
                        if (element.dependentvalue === dto.categoryId)
                          newOptions.push(element);
                      });
                      return newOptions;
                    }}
                    defaultValue={
                      itemProps.options.find((v) => v?.value === dto.itemId) ||
                      {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        let uomName = "";
                        let description = "";
                        const items = itemList.filter((a, i) => {
                          return a.id === v?.value;
                        });

                        if (items.length > 0) {
                          description = items[0].description;
                          uomList.forEach((element) => {
                            if (element.value === items[0].purchaseUOMId)
                              uomName = element.name;
                          });
                        }

                        if (isNaN(v?.value))
                          setDTO({
                            ...dto,
                            itemId: v?.value,
                            uomName: uomName,
                            description: description,
                          });
                        else
                          setDTO({
                            ...dto,
                            itemId: Number(v?.value),
                            uomName: uomName,
                            description: description,
                          });
                      } else {
                        let uomName = "";
                        let description = "";
                        const items = itemList.filter((a, i) => {
                          return a.id === v?.value;
                        });
                        if (items.length > 0) {
                          description = items[0].description;
                          uomList.forEach((element) => {
                            if (element.value === items[0].purchaseUOMId)
                              uomName = element.name;
                          });
                        }
                        setDTO({
                          ...dto,
                          itemId: v?.value,
                          uomName: uomName,
                          description: description,
                        });
                      }
                    }}
                    name="itemId"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Item Name"
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          minHeight: "48.5px",
                          width: "200px",
                          height: "48.5px",
                        }}
                        {...params}
                        InputLabelProps={{
                          style: {
                            color: "grey",
                          },
                        }}
                      />
                    )}
                  ></Autocomplete>
                </td>

                <td>
                  <TextField
                    variant="standard"
                    label="Quantity"
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setDTO({...dto, [name]: value});
                    }}
                    onWheel={(event) => event.target.blur()}
                    style={{
                      minWidth: "200px",
                      minHeight: "48.5px",
                      height: "48.5px",
                      width: "200px",
                      fontSize: 10,
                    }}
                    onBlur={handleInputChange}
                    name="quantity"
                    value={dto.quantity}
                    InputLabelProps={{
                      style: { color: "grey", fontSize: 14 },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {dto.uomName}
                        </InputAdornment>
                      ),
                      style: { fontSize: 14 },
                    }}
                  ></TextField>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                  >
                    <TextareaAutosize
                      variant="standard"
                      label="Description"
                      maxLength={128}
                      onChange={handleInputChange}
                      style={{
                        outlineColor: BLACK,
                        borderTop: "10px",
                        borderRight: "10px",
                        borderLeft: "10px",
                        marginTop: 15,
                        fontSize: 12,
                        fontFamily: "sans-serif",
                        maxWidth: "848px",
                      }}
                      multiline="true"
                      value={dto.description}
                      maxRows={4}
                      minRows={2}
                      name="description"
                      placeholder="Description"
                    ></TextareaAutosize>
                  </Stack>
                </td>
              </tr>
            </TableBody>
          </table>
        </div>

        {/* div for buttons */}
        {!viewModel ? (
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              marginTop: "20px",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={onFormSubmit}
            >
              Save
            </Button>
          </div>
        ) : (
          <div />
        )}
        {/* div for buttons closed */}
      </form>
      {/* pop up div closed  */}
    </div>
  );
}

export default EditModal;
