import { Constants } from "./constants";

let isLoggedIn = false;
let username = "";

export const APILOGIN = {
  SetUsername: (v) => {
    username = v;
  },
  GetUsername: () => {
    return username;
  },
  SetIsLoggedIn: (v) => {
    isLoggedIn = v;
  },
  GetIsLoggedIn: () => {
    return isLoggedIn;
  },
  Login: (username, password) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/login`, {
      body: JSON.stringify({
        username: username,
        password: password,
      }),
      method: "POST",
      credentials: "include",
    });
  },
  SessionStatus: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/sessionstatus`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      credentials: "include",
    });
  },
  Logoff: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/logout`, {
      method: "POST",
      credentials: "include",
    });
  },
};
