import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getItemCategories,
  getItemList,
  getItems,
  getLabourerTypes,
  getSites,
  getSupplierCategories,
  getSuppliers,
  getUoms,
  globalFilter,
} from "../../../../../component/cellRenderers/renderers";
import SideNav from "../../../../../component/layouts/sideNav";
import {
  BUS_FARE,
  CANCEL,
  FOOD_FARE,
  ITEM_CATEGORY,
  ITEM_NAME,
  LABOUR_MANAGEMENT,
  NEW_VERSION,
  OLD_VERSION,
  OT_RAISE,
  RATE,
  RATE_RS,
  SAVE,
  TYPE_OF_MAN_POWER,
  WORK_ORDER,
  WORK_ORDER_DETAIL,
  WORK_ORDER_NO,
} from "../../../../../resources/constant/strings/string";

import { PATH_WORK_ORDER } from "../../../../../resources/constant/url/url";

import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  TableBody,
  TextField,
  Typography,
} from "@mui/material";
import minus_icon from "../../../../../resources/assets/images/minus_icon.svg";
import plus_icon from "../../../../../resources/assets/images/plus_icon.svg";
import {
  CHARLESTON_GREEN,
  LIGHT_GREY_BACKGROUND,
  PRUSSIAN_BLUE,
  SPANISH_GREEN,
  WHITE,
} from "../../../../../resources/constant/colors/theme";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { APILABOURMGMT } from "../../../../../apihandler/labourmgmt";
import { setAlertTitleText } from "../../../../../component/modal/alertModal";
import Modal from "./modal";
const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  color: SPANISH_GREEN,
  transform: "translate(-50%, -50%)",
  zIndex: 2,
};
export default function WorkOrderDetail() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [dataReady, setDataReady] = useState(false);
  const [dateValue, setDateValue] = React.useState(null);
  const [toDateValue, setToDateValue] = React.useState(dayjs().endOf("month"));
  const [otFromServer, setOtFromServer] = useState(0.0);

  const location = useLocation();
  const isEdit = location.state?.isEdit;

  const initialValue = {
    id: 0,
    workOrderNumber: "Auto Generated",
    version: 1,
    contractorId: 0,
    retention: 0,
    commission: 0,
    locationId: 0,
    effectiveDate: dayjs(),
    workOrderDetailItem: [],
  };

  const [dto, setDTO] = useState(initialValue);

  const [workOrderDetailItems, setWorkOrderDetailItems] = useState([
    {
      id: -1*Number((Math.random() * 10000000).toFixed(0)),
      rowId: 0,
      itemCategoryId: 0,
      itemId: 0,
      labourerTypeId: 0,
      rate: 0,
      busFare: 0,
      foodFare: 0,
      otRate: 0,
      total: 0,
    },
  ]);
  const [workOrderDetailLabourItems, setWorkOrderDetailLabourItems] = useState([
    {
      id: -1*Number((Math.random() * 10000000).toFixed(0)),
      rowId: 0,
      itemCategoryId: 0,
      itemId: 0,
      labourerTypeId: 0,
      rate: 0,
      busFare: 0,
      foodFare: 0,
      otRate: otFromServer,
      total: 0,
    },
  ]);

  const [rowId, setRowId] = useState(2);

  const addObjectToArrayItems = () => {
    setWorkOrderDetailItems([
      ...workOrderDetailItems,
      {
        id: 0,
        rowId: -1*Number((Math.random() * 10000000).toFixed(0)),
        itemCategoryId: 0,
        itemId: 0,
        labourerTypeId: 0,
        rate: 0,
        busFare: 0,
        foodFare: 0,
        otRate: 0,
        total: 0,
        type: "I",
      },
    ]);
  };
  const addObjectToArrayLabourItems = () => {
    setWorkOrderDetailLabourItems([
      ...workOrderDetailLabourItems,
      {
        id: 0,
        rowId: -1*Number((Math.random() * 10000000).toFixed(0)),
        itemCategoryId: 0,
        itemId: 0,
        labourerTypeId: 0,
        rate: 0,
        busFare: 0,
        foodFare: 0,
        otRate: otFromServer,
        total: 0,
        type: "L",
      },
    ]);
  };

  const removeObjectFromArrayItems = (rowId) => {
    setWorkOrderDetailItems(c =>
      c.filter((x) => {
        return x.rowId !== rowId;
      })
    );
    workOrderDetailItems.forEach((e, i) => {
      e.rowId = i;
    });
  };
  const removeObjectFromArrayLabourItems = (rowId) => {
    setWorkOrderDetailLabourItems(c =>
      c.filter((x) => {
        return x.rowId !== rowId;
      })
    );
    workOrderDetailLabourItems.forEach((e, i) => {
      e.rowId = i;
    });
  };
  const itemCategory = getItemCategories();

  const itemCategoryProps = {
    options: itemCategory,
    getOptionLabel: (option) => option?.name || "",
  };

  const itemList = getItemList();
  const uomList = getUoms();

  const items = getItems();
  const itemProps = {
    options: items,
    getOptionLabel: (option) => option?.name || "",
  };

  const supplierCategories = getSupplierCategories().filter((x) => {
    return x.dependentvalue === "L";
  });
  const suppliers = getSuppliers().filter((x) => {
    return (
      supplierCategories.filter((y) => y.value === x.dependentvalue).length > 0
    );
  });
  const supplierProps = {
    options: suppliers,
    getOptionLabel: (option) => option?.name || "",
  };

  const sites = getSites();
  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };

  const labourerTypes = getLabourerTypes();
  const labourerTypeProps = {
    options: labourerTypes,
    getOptionLabel: (option) => option?.name || "",
  };

  const uoms = getUoms();

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (isNaN(value)) setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    }
  };

  // below code is of detail component
  const [openModal, setOpenModal] = useState(false);

  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    //api call to save goes here
    workOrderDetailItems.forEach((e) => (e.type = "I"));
    workOrderDetailLabourItems.forEach((e) => (e.type = "L"));
    const rowItems = workOrderDetailItems.concat(workOrderDetailLabourItems);
    dto.workOrderDetailItem = rowItems.filter(
      (x) => x.itemCategoryId > 0 || x.labourerTypeId > 0
    );

    rowItems.forEach(v => {
      if(v.id <= 0)
        v.id=0;
    })

    if (id <= 0) {
      APILABOURMGMT.AddWorkOrder(dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setAlertTitleText(
              "Success",
              "The record was inserted successfully"
            );
            navigate(PATH_WORK_ORDER + "/" + location.state.date);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    } else {
      APILABOURMGMT.UpdateWorkOrder(id, dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setAlertTitleText("Success", "The record was updated successfully");
            navigate(PATH_WORK_ORDER + "/" + location.state.date);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    }
  }

  useEffect(() => {
    APILABOURMGMT.WorkOrderDetailOt()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode === 200) {
          setOtFromServer(resp.response.otRate);
          if (id <= 0) {
            const m = {
              id: 0,
              itemCategoryId: 0,
              itemId: 0,
              labourerTypeId: 0,
              rate: 0,
              busFare: 0,
              foodFare: 0,
              otRate: otFromServer,
              total: 0,
            };
            setWorkOrderDetailLabourItems((w) =>
              w.map((o) => {
                return { ...o, ...m };
              })
            );
          }
        }
      });

    if (isEdit || id > 0) {
      APILABOURMGMT.WorkOrder(id)
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.statusCode === 200) {
            setDTO({
              ...dto,
              workOrderNumber: resp.response.workOrderNumber,
              commission: resp.response.commission,
              contractorId: resp.response.contractorId,
              effectiveDate: resp.response.effectiveDate
                .split("-")
                .reverse()
                .join("-"),
              id: resp.response.id,
              locationId: resp.response.locationId,
              retention: resp.response.retention,
              version: resp.response.version,
            });

            const itemWorkItems = resp.response.workOrderDetailItem
              .sort((a, b) => a.id - b.id)
              .filter((x) => x.type === "I");
            const labourWorkItems = resp.response.workOrderDetailItem
              .sort((a, b) => a.id - b.id)
              .filter((x) => x.type === "L");

            itemWorkItems.forEach((x,i)=>x.rowId=i);
            labourWorkItems.forEach((x,i)=>x.rowId=i);

            itemWorkItems.forEach((x) => {
              const item = items.filter((m) => m.value === x.itemId);
              x.itemCategoryId = item.length > 0 ? item[0].dependentvalue : 0;
              const filteredItems = itemList.filter((x) => x.id === x.itemId);
              if (filteredItems.length > 0) {
                uomList.forEach((element) => {
                  if (element.value === filteredItems[0].stockUOMId)
                    x.uomName = element.name;
                });
              }
            });

            setWorkOrderDetailItems((w) => w.filter((m) => m?.id === -1));
            setWorkOrderDetailLabourItems((w) => w.filter((m) => m?.id === -1));

            setWorkOrderDetailItems((r) => [].concat(itemWorkItems));
            setWorkOrderDetailLabourItems((r) => [].concat(labourWorkItems));

            setDataReady(true);
          }
        });
    } else {
      setDataReady(true);
    }
  }, [isEdit, id]);

  return (
    <SideNav
      currentComponent={LABOUR_MANAGEMENT}
      subComponent={WORK_ORDER}
      subComponentPath={PATH_WORK_ORDER}
      detailComponent={WORK_ORDER_DETAIL}
    >
      {/* below code is of work Order detail component */}
      {dataReady ? (
        <div>
          <div>
            {/* {openModal && (
            <Modal
              style={{ align: "center" }}
              setOpenModal={setOpenModal}
              // updateModel={updateModel}
              // addModel={addModel}
              // viewModel={viewOnlyModel}
              // model={formData}
              formTitle={"Version"}
            ></Modal>
          )} */}
            <form>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  marginBottom: 10,
                  marginRight: 10,
                }}
              >
                {(id <= 0 || isEdit) && (
                  <Button
                    style={{
                      backgroundColor: SPANISH_GREEN,
                      textTransform: "capitalize",
                      fontSize: 14,
                      color: WHITE,
                      minWidth: "180px",
                      minHeight: "45px",
                    }}
                    onClick={onFormSubmit}
                  >
                    {SAVE}
                  </Button>
                )}
                {/* {(id <= 0 || isEdit) && (            
              <Button
                onClick={() => {}}
                variant="outlined"
                sx={{
                  borderColor: SPANISH_GREEN,
                }}
                style={{
                  //backgroundColor: SPANISH_GREEN,
                  textTransform: "capitalize",
                  fontSize: 14,
                  color: SPANISH_GREEN,
                  minWidth: "180px",
                  minHeight: "45px",
                  marginRight: 10,
                }}
              >
                {NEW_VERSION}
              </Button>
              )} */}
                {/* <Button
                onClick={() => {
                  setOpenModal(true);
                }}
                style={{
                  backgroundColor: SPANISH_GREEN,
                  textTransform: "capitalize",
                  fontSize: 14,
                  color: WHITE,
                  minWidth: "180px",
                  minHeight: "45px",
                  marginRight: 10,
                }}
              >
                {OLD_VERSION}
              </Button> */}
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: SPANISH_GREEN,
                  }}
                  style={{
                    //backgroundColor: SPANISH_GREEN,
                    textTransform: "capitalize",
                    fontSize: 14,
                    color: SPANISH_GREEN,
                    minWidth: "180px",
                    minHeight: "45px",
                    marginRight: 10,
                  }}
                  onClick={() => {
                    navigate(PATH_WORK_ORDER + "/" + location.state.date);
                  }}
                >
                  {CANCEL}
                </Button>
              </div>
              <div
                style={{
                  backgroundColor: LIGHT_GREY_BACKGROUND,
                  borderRadius: "5px",
                  minWidth: "90%",
                  minHeight: "50px",
                  padding: 20,
                  marginBottom: 10,
                  borderStyle: "solid",
                  border: "1x",
                  borderColor: "#eaeaea",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      fontWeight: 600,
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                    }}
                  >
                    {WORK_ORDER_NO}
                  </Typography>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      fontWeight: 600,
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      //fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                    }}
                  >
                    {dto.workOrderNumber}
                  </Typography>
                </div>
                <table style={{ width: "100%" }}>
                  <TableBody>
                    <tr>
                      <td>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label="Effective Date "
                            value={dayjs(dto.effectiveDate)}
                            inputFormat="DD-MM-YYYY"
                            maxDate={dayjs()}
                            disabled={id > 0}
                            onChange={(newValue) => {
                              setDateValue(newValue);
                              setDTO({
                                ...dto,
                                effectiveDate: newValue.format("YYYY-MM-DD"),
                              });
                            }}
                            style={{ minWidth: "272px", minHeight: "48.5px" }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                style={{
                                  minWidth: "200px",
                                  minHeight: "48.5px",
                                  width: "200px",
                                }}
                                InputLabelProps={{
                                  style: { color: "grey", fontSize: 14 },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { fontSize: 14 },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </td>
                      <td>
                        <Autocomplete
                          filterOptions={globalFilter}
                          {...supplierProps}
                          id="auto-complete-1"
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          disabled={id > 0}
                          name="contractorId"
                          defaultValue={
                            supplierProps.options.find(
                              (v) => v.value === dto.contractorId
                            ) || {}
                          }
                          onChange={(e, v) => {
                            if (v) {
                              if (isNaN(v?.value))
                                setDTO({ ...dto, contractorId: v?.value });
                              else
                                setDTO({
                                  ...dto,
                                  contractorId: Number(v?.value),
                                });
                            } else {
                              setDTO({ ...dto, contractorId: v?.value });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Contractor Name"
                              required
                              variant="standard"
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 14 },
                              }}
                            />
                          )}
                        />
                      </td>

                      <td>
                        <Autocomplete
                          filterOptions={globalFilter}
                          {...siteProps}
                          id="auto-complete-2"
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          disabled={id > 0}
                          name="locationId"
                          defaultValue={
                            siteProps.options.find(
                              (v) => v.value === dto.locationId
                            ) || {}
                          }
                          onChange={(e, v) => {
                            if (v) {
                              if (isNaN(v?.value))
                                setDTO({ ...dto, locationId: v?.value });
                              else
                                setDTO({
                                  ...dto,
                                  locationId: Number(v?.value),
                                });
                            } else {
                              setDTO({ ...dto, locationId: v?.value });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Location"
                              required
                              variant="standard"
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 14 },
                              }}
                            />
                          )}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <TextField
                          variant="standard"
                          label="Retention"
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setDTO({...dto, [name]: value});
                          }}
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "200px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "200px",
                            fontSize: 10,
                          }}
                          name="retention"
                          value={dto.retention}
                          disabled={id > 0 && !isEdit}
                          onBlur={handleInputChange}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                            style: { fontSize: 14 },
                          }}
                        ></TextField>
                      </td>
                      <td>
                        <TextField
                          variant="standard"
                          label="Testing & Commision"
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setDTO({...dto, [name]: value});
                          }}
                          onWheel={(event) => event.target.blur()}
                          onBlur={handleInputChange}
                          style={{
                            minWidth: "200px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "200px",
                            fontSize: 10,
                          }}
                          disabled={id > 0 && !isEdit}
                          name="commission"
                          value={dto.commission}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                            style: { fontSize: 14 },
                          }}
                        ></TextField>
                      </td>
                    </tr>
                  </TableBody>
                </table>
              </div>
            </form>
          </div>
          <div
            style={{
              backgroundColor: WHITE,
              borderRadius: "5px",
              minWidth: "90%",
              minHeight: "50px",
              marginBottom: 10,
              border: "0.1px solid #eaeaea",
              display: "flex",
              flexDirection: "column",
              // width: "100%",
              overflowX: "auto",
            }}
          >
            <div
            // style={{
            //   width: "1000px",
            // }}
            >
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                }}
              >
                <TableBody>
                  <tr
                    style={{
                      backgroundColor: LIGHT_GREY_BACKGROUND,
                      borderBottom: "0.001pt solid #f0eff4",
                      borderTop: "0.001pt solid #f0eff4",
                    }}
                  >
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {TYPE_OF_MAN_POWER}
                      </Typography>
                    </th>
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {RATE_RS}
                      </Typography>
                    </th>
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {BUS_FARE}
                      </Typography>
                    </th>
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {FOOD_FARE}
                      </Typography>
                    </th>
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {OT_RAISE}
                      </Typography>
                    </th>

                    <th></th>
                    <td></td>
                  </tr>
                  <tr>
                  {workOrderDetailLabourItems?.length <= 0 && (
                      <IconButton
                        style={{ marginTop: 10 }}
                        id={`button-plus-default`}
                        aria-label="plus"
                        onClick={() => {
                          addObjectToArrayLabourItems();
                        }}
                      >
                        <img src={plus_icon} alt="" />
                      </IconButton>
                    )}
                  </tr>
                  {workOrderDetailLabourItems?.map((item, index) => {
                    return (
                      <tr key={item.rowId}
                        style={{
                          borderBottom: "0.001pt solid #f0eff4",
                        }}
                      >
                        <td>
                          <Autocomplete
                            filterOptions={globalFilter}
                            {...labourerTypeProps}
                            id={`autocomplete-l-1-${index}`}
                            autoComplete
                            autoHighlight
                            autoSelect
                            includeInputInList
                            name="typeOfManPower"
                            disabled={id > 0 && !isEdit}
                            defaultValue={
                              labourerTypeProps.options.find(
                                (v) =>
                                  v.value ===
                                  workOrderDetailLabourItems[index]
                                    .labourerTypeId
                              ) || {}
                            }
                            onChange={(e, v) => {
                              let newArr = [...workOrderDetailLabourItems];
                              newArr[index].labourerTypeId = Number(v?.value);

                              if (newArr[index].otRate <= 0)
                                newArr[index].otRate = otFromServer;

                              setWorkOrderDetailLabourItems(newArr);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Type of Man Power"
                                id={`textfield-l-1-${index}`}
                                variant="standard"
                                style={{
                                  minWidth: "150px",
                                  minHeight: "38.5px",
                                  width: "150px",
                                  height: "38.5px",
                                  marginBottom: 5,
                                  marginLeft: 5,
                                }}
                                InputLabelProps={{
                                  style: { color: "grey", fontSize: 14 },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { fontSize: 14 },
                                }}
                              />
                            )}
                          />
                        </td>
                        <td>
                          <input
                            onChange={(e) => {
                              const { name, value } = e.target;
                              let newArr = [...workOrderDetailLabourItems];
                              newArr[index][e.target.name] = (
                                e.target.value
                              );
                              setWorkOrderDetailLabourItems(newArr);
                            }}
                            onWheel={(event) => event.target.blur()}
                            id={`textfield-l-2-${index}`}
                            name="rate"
                            value={workOrderDetailLabourItems[index].rate}
                            disabled={id > 0 && !isEdit}
                            onBlur={(e) => {
                              let newArr = [...workOrderDetailLabourItems];
                              newArr[index][e.target.name] = Number(
                                e.target.value
                              );
                              newArr[index].otRate =
                                otFromServer * newArr[index].rate;
                              setWorkOrderDetailLabourItems(newArr);
                            }}
                            style={{
                              width: "100px",
                              border: "solid 1px #eaeaea",
                              borderRadius: "4px",
                              height: "20px",
                            }}
                          ></input>
                        </td>

                        <td>
                          <input
                            onChange={(e) => {
                              const { name, value } = e.target;
                              let newArr = [...workOrderDetailLabourItems];
                              newArr[index][e.target.name] = (
                                e.target.value
                              );
                              setWorkOrderDetailLabourItems(newArr);
                            }}
                            onWheel={(event) => event.target.blur()}
                            id={`textfield-l-3-${index}`}
                            name="busFare"
                            disabled={id > 0 && !isEdit}
                            value={workOrderDetailLabourItems[index].busFare}
                            onBlur={(e) => {
                              let newArr = [...workOrderDetailLabourItems];
                              newArr[index][e.target.name] = Number(
                                e.target.value
                              );
                              setWorkOrderDetailLabourItems(newArr);
                            }}
                            style={{
                              width: "100px",
                              border: "solid 1px #eaeaea",
                              borderRadius: "4px",
                              height: "20px",
                            }}
                          ></input>
                        </td>
                        <td>
                          <input
                            onChange={(e) => {
                              const { name, value } = e.target;
                              let newArr = [...workOrderDetailLabourItems];
                              newArr[index][e.target.name] = (
                                e.target.value
                              );
                              setWorkOrderDetailLabourItems(newArr);
                            }}
                            onWheel={(event) => event.target.blur()}
                            id={`textfield-l-4-${index}`}
                            name="foodFare"
                            disabled={id > 0 && !isEdit}
                            value={workOrderDetailLabourItems[index].foodFare}
                            onBlur={(e) => {
                              let newArr = [...workOrderDetailLabourItems];
                              newArr[index][e.target.name] = Number(
                                e.target.value
                              );
                              setWorkOrderDetailLabourItems(newArr);
                            }}
                            style={{
                              width: "100px",
                              border: "solid 1px #eaeaea",
                              borderRadius: "4px",
                              height: "20px",
                            }}
                          ></input>
                        </td>
                        <td>
                          <input
                            
                            onWheel={(event) => event.target.blur()}
                            id={`textfield-l-5-${index}`}
                            name="otRate"
                            disabled
                            value={workOrderDetailLabourItems[index].otRate}
                            style={{
                              width: "100px",
                              border: "solid 1px #eaeaea",
                              borderRadius: "4px",
                              height: "20px",
                            }}
                          ></input>
                        </td>
                        <td>
                          {(isEdit || id <= 0) && index > 0 && (
                            <IconButton
                              style={{ marginTop: 10 }}
                              id={`button-minus-labourer-${index}`}
                              aria-label="minus"
                              onClick={() => {
                                if (workOrderDetailLabourItems.length > 1) {
                                  removeObjectFromArrayLabourItems(item.rowId);
                                }
                              }}
                            >
                              <img src={minus_icon} alt="" />
                            </IconButton>
                          )}
                        </td>
                        <td>
                          {(isEdit || id <= 0) && (
                            <IconButton
                              style={{ marginTop: 10 }}
                              id={`button-plus-labourer-${index}`}
                              aria-label="plus"
                              onClick={() => {
                                addObjectToArrayLabourItems();
                              }}
                            >
                              <img src={plus_icon} alt="" />
                            </IconButton>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </TableBody>
              </table>
            </div>
          </div>
          <div
            style={{
              backgroundColor: WHITE,
              borderRadius: "5px",
              minWidth: "90%",
              minHeight: "50px",
              marginBottom: 10,
              border: "0.1px solid #eaeaea",
              display: "flex",
              flexDirection: "column",
              // width: "100%",
              overflowX: "auto",
            }}
          >
            <div
            // style={{
            //   width: "1000px",
            // }}
            >
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                }}
              >
                <TableBody>
                  <tr
                    style={{
                      backgroundColor: LIGHT_GREY_BACKGROUND,
                      borderBottom: "0.001pt solid #f0eff4",
                      borderTop: "0.001pt solid #f0eff4",
                    }}
                  >
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {ITEM_CATEGORY}
                      </Typography>
                    </th>
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {ITEM_NAME}
                      </Typography>
                    </th>
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {RATE}
                      </Typography>
                    </th>

                    <th></th>
                    <td></td>
                  </tr>
                  <tr>
                  {workOrderDetailItems?.length <= 0 && (
                      <IconButton
                        style={{ marginTop: 10 }}
                        id={`button-plus-default`}
                        aria-label="plus"
                        onClick={() => {
                          addObjectToArrayItems();
                        }}
                      >
                        <img src={plus_icon} alt="" />
                      </IconButton>
                    )}
                  </tr>
                  {workOrderDetailItems?.map((item, index) => {
                    return (
                      <tr key={item.rowId}
                        style={{
                          borderBottom: "0.001pt solid #f0eff4",
                        }}
                      >
                        <td>
                          <Autocomplete
                            filterOptions={globalFilter}
                            {...itemCategoryProps}
                            id={`a-autocomplete-1-${index}`}
                            disabled={id > 0 && !isEdit}
                            autoComplete
                            autoHighlight
                            autoSelect
                            includeInputInList
                            name="itemCategory"
                            defaultValue={
                              itemCategoryProps.options.find(
                                (v) =>
                                  v.value ===
                                  workOrderDetailItems[index].itemCategoryId
                              ) || {}
                            }
                            onChange={(e, v) => {
                              let newArr = [...workOrderDetailItems];
                              newArr[index].itemCategoryId = Number(v?.value);
                              setWorkOrderDetailItems(newArr);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Item Category"
                                id={`a-textfield-1-${index}`}
                                variant="standard"
                                style={{
                                  minWidth: "150px",
                                  minHeight: "38.5px",
                                  width: "350px",
                                  height: "38.5px",
                                  marginBottom: 5,
                                  marginLeft: 5,
                                }}
                                InputLabelProps={{
                                  style: { color: "grey", fontSize: 14 },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { fontSize: 14 },
                                }}
                              />
                            )}
                          />
                        </td>
                        <td>
                          <Autocomplete
                            {...itemProps}
                            id={`a-autocomplete-2-${index}`}
                            autoComplete
                            autoHighlight
                            autoSelect
                            includeInputInList
                            disabled={id > 0 && !isEdit}
                            name="itemName"
                            defaultValue={
                              itemProps.options.find(
                                (v) =>
                                  v.value === workOrderDetailItems[index].itemId
                              ) || {}
                            }
                            onChange={(e, v) => {
                              let newArr = [...workOrderDetailItems];
                              newArr[index].itemId = Number(v?.value);
                              const filteredItems = itemList.filter(
                                (x) => x.id === newArr[index].itemId
                              );
                              if (filteredItems.length > 0) {
                                uomList.forEach((element) => {
                                  if (
                                    element.value ===
                                    filteredItems[0].stockUOMId
                                  )
                                    newArr[index].uomName = element.name;
                                });
                              }
                              setWorkOrderDetailItems(newArr);
                            }}
                            filterOptions={(o, s) => {
                              let options = globalFilter(o, s);
                              let newOptions = [];
                              options.forEach((element) => {
                                if (
                                  element.dependentvalue ===
                                  workOrderDetailItems[index].itemCategoryId
                                )
                                  newOptions.push(element);
                              });
                              return newOptions;
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Item Name"
                                id={`a-textfield-2-${index}`}
                                variant="standard"
                                style={{
                                  minWidth: "150px",
                                  minHeight: "38.5px",
                                  width: "350px",
                                  height: "38.5px",
                                  marginBottom: 5,
                                  marginLeft: 5,
                                }}
                                InputLabelProps={{
                                  style: { color: "grey", fontSize: 14 },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { fontSize: 14 },
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {workOrderDetailItems[index].uomName}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </td>
                        <td>
                          <input
                            onChange={(e) => {
                              const { name, value } = e.target;
                              let newArr = [...workOrderDetailItems];
                              newArr[index][e.target.name] = (
                                e.target.value
                              );
                              setWorkOrderDetailItems(newArr);
                            }}
                            onWheel={(event) => event.target.blur()}
                            id={`a-textfield-4-${index}`}
                            name="rate"
                            value={item.rate}
                            disabled={id > 0 && !isEdit}
                            onBlur={(e) => {
                              let newArr = [...workOrderDetailItems];
                              newArr[index].rate = Number(e.target.value);
                              setWorkOrderDetailItems(newArr);
                            }}
                            style={{
                              width: "100px",
                              border: "solid 1px #eaeaea",
                              borderRadius: "4px",
                              height: "20px",
                            }}
                          ></input>
                        </td>

                        <td>
                          {(isEdit || id <= 0) && index > 0 && (
                            <IconButton
                              style={{ marginTop: 10 }}
                              aria-label="minus"
                              onClick={() => {
                                if (workOrderDetailItems.length > 1) {
                                  removeObjectFromArrayItems(item.rowId);
                                }
                              }}
                            >
                              <img src={minus_icon} alt="" />
                            </IconButton>
                          )}
                        </td>
                        <td>
                          {(isEdit || id <= 0) && (
                            <IconButton
                              style={{ marginTop: 10 }}
                              id={`a-button-plus-${index}`}
                              aria-label="plus"
                              onClick={() => {
                                addObjectToArrayItems();
                              }}
                            >
                              <img src={plus_icon} alt="" />
                            </IconButton>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </TableBody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </SideNav>
  );
}
