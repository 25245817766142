import {
  Autocomplete,
  Button,
  IconButton,
  TableBody,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { APILABOURMGMT } from "../../../../apihandler/labourmgmt";
import {
  getEmployees,
  getItemTypes,
  getSiteCategories,
  getSites,
  globalFilter,
} from "../../../../component/cellRenderers/renderers";
import { setAlertTitleText } from "../../../../component/modal/alertModal";
import "./modal.css";

function Modal({
  setOpenModal,
  model,
  formTitle,
  updateModel,
  addModel,
  viewModel,
}) {
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };

  useEffect(() => {
    console.log("hello", dto);
  }, []);
  function validation() {
    //only required so direct api call
    if (dto.id <= 0)
      APILABOURMGMT.AddManagerAssignment(dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            const reqdCategories = getSiteCategories();

            const reqdSites = getSites();

            reqdSites.forEach((element) => {
              const reqdSite = reqdSites.filter(
                (x) => x.value === data.response.locationId
              );
              if (reqdSite.length > 0) {
                const reqdCategory = reqdCategories.filter(
                  (x) => x.value === reqdSite[0].dependentvalue
                );
                if (reqdCategory.length > 0) {
                  data.response.categoryId = reqdCategory[0].value;
                }
              }
            });
            addModel(data.response);
            setAlertTitleText(
              "Success",
              "The record was inserted successfully"
            );
            setOpenModal(false);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    else console.log("hola1", dto);
    APILABOURMGMT.UpdateManagerAssignment(dto.id, dto)
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200) {
          const reqdCategories = getSiteCategories();

          const reqdSites = getSites();

          reqdSites.forEach((element) => {
            const reqdSite = reqdSites.filter(
              (x) => x.value === data.response.locationId
            );
            if (reqdSite.length > 0) {
              const reqdCategory = reqdCategories.filter(
                (x) => x.value === reqdSite[0].dependentvalue
              );
              if (reqdCategory.length > 0) {
                data.response.categoryId = reqdCategory[0].value;
              }
            }
          });

          updateModel(data.response);
          setAlertTitleText("Success", "The record was updated successfully");
          setOpenModal(false);
        } else {
          setAlertTitleText(
            "Oops...",
            `Something went wrong...${data.message}`
          );
        }
      });
  }

  const [dto, setDTO] = useState(model);

  const siteCategories = getSiteCategories();
  const sites = getSites();
  const employees = getEmployees();

  const siteCategoryProps = {
    options: siteCategories,
    getOptionLabel: (option) => option?.name || "",
  };

  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };

  const employeeProps = {
    options: employees,
    getOptionLabel: (option) => option?.name || "",
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (isNaN(value) || name === "description")
        setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    } else {
      setDTO({ ...dto, [name]: value });
    }
  };

  const itemTypes = getItemTypes();

  const itemTypeProps = {
    options: itemTypes,
    getOptionLabel: (option) => option?.name || "",
  };
  return (
    <div className="modalBackgroundForManagerAssignment">
      {/* div for top right close button */}
      <form>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton
            style={{ width: "auto", align: "center", marginRight: 5 }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* top right close button div closed */}

        <div
          style={{
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <table style={{ width: "100%", margin: 5 }}>
            <TableBody>
              <tr>
                <td>
                  <Typography
                    fontSize={18}
                    fontWeight={600}
                    fontspacing={"normal"}
                    textTransform="capitalize"
                    align="left"
                  >
                    {formTitle}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    {...siteCategoryProps}
                    id="auto-complete-1"
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    name="siteCategory"
                    defaultValue={
                      siteCategoryProps.options.find(
                        (v) => v.value === dto.categoryId
                      ) || {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, categoryId: v?.value });
                        else setDTO({ ...dto, categoryId: Number(v?.value) });
                      } else {
                        setDTO({ ...dto, categoryId: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Site Category"
                        required
                        variant="standard"
                        style={{
                          minWidth: "272px",
                          minHeight: "48.5px",
                          width: "272px",
                        }}
                      />
                    )}
                  />
                </td>
                <td>
                  <Autocomplete
                    {...siteProps}
                    id="auto-complete-2"
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    name="location"
                    defaultValue={
                      siteProps.options.find(
                        (v) => v.value === dto.locationId
                      ) || {}
                    }
                    filterOptions={(o, s) => {
                      let options = globalFilter(o, s);
                      let newOptions = [];
                      options.forEach((element) => {
                        if (element.dependentvalue === dto.categoryId)
                          newOptions.push(element);
                      });
                      return newOptions;
                    }}
                    onChange={(e, v) => {
                      if (v) {
                        if (v.dependentvalue !== dto.categoryId) return;

                        if (isNaN(v?.value))
                          setDTO({ ...dto, locationId: v?.value });
                        else setDTO({ ...dto, locationId: Number(v?.value) });
                      } else {
                        setDTO({ ...dto, locationId: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Location"
                        required
                        variant="standard"
                        style={{
                          minWidth: "272px",
                          minHeight: "48.5px",
                          width: "272px",
                        }}
                      />
                    )}
                  />
                </td>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    {...employeeProps}
                    id="auto-complete-3"
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    name="projectManager"
                    defaultValue={
                      employeeProps.options.find(
                        (v) => v.value === dto.employeeId
                      ) || {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, employeeId: v?.value });
                        else setDTO({ ...dto, employeeId: Number(v?.value) });
                      } else {
                        setDTO({ ...dto, employeeId: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Project Manager"
                        required
                        variant="standard"
                        style={{
                          minWidth: "272px",
                          minHeight: "48.5px",
                          width: "272px",
                        }}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="From Date"
                      style={{ minWidth: "272px", minHeight: "48.5px" }}
                      value={dayjs(dto.effectiveDate)}
                      inputFormat="DD-MM-YYYY"
                      onChange={(newValue) => {
                        setDTO({
                          ...dto,
                          effectiveDate: newValue.format("YYYY-MM-DD"),
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          style={{ minWidth: "272px", minHeight: "48.5px" }}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </td>
              </tr>
            </TableBody>
          </table>
        </div>

        {/* div for buttons */}
        {!viewModel ? (
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={onFormSubmit}
            >
              Save
            </Button>
          </div>
        ) : (
          <div />
        )}
        {/* div for buttons closed */}
      </form>
      {/* pop up div closed  */}
    </div>
  );
}

export default Modal;
