import { Autocomplete, Button, TableBody, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { APIMASTER } from "../../../../apihandler/master";
import { APIREPORTS } from "../../../../apihandler/reports";
import {
  getItemList,
  getItems,
  getSiteCategories,
  getSites,
  getSupplierCategories,
  getSuppliers,
  getUoms,
  globalFilter,
  setItemList,
  setItems,
  setSiteCategories,
  setSites,
  setSupplierCategories,
  setUoms,
} from "../../../../component/cellRenderers/renderers";
import SideNav from "../../../../component/layouts/sideNav";
import { setAlertTitleText } from "../../../../component/modal/alertModal";
import {
  LIGHT_GREY_BACKGROUND,
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";
import {
  EXPORT,
  GENERATE,
  LABOUR_MANAGEMENT_REPORT,
  SUB_CONTRACTOR_BILLING_SUMMARY_REPORT,
} from "../../../../resources/constant/strings/string";
import { PATH_SUB_CONTRACTOR_BILLING_SUMMARY_REPORT } from "../../../../resources/constant/url/url";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  color: SPANISH_GREEN,
  transform: "translate(-50%, -50%)",
  zIndex: 2,
};

export default function SubContractorBillingSummaryReport() {
  const [itemDetails, setItemDetails] = useState([]);

  const [dataReady, setDataReady] = useState(true);

  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    setDataReady(false);
    APIREPORTS.SubContractorBillingSummaryReport(dto)
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode !== 200) {
          setAlertTitleText("Oops", res.message);
          setDataReady(true);
          return;
        }
        try {
          setDataReady(true);
          gridRef1.current.api.setRowData([]);
        } catch (e) {}
        const headers = [];
        const allItems = [];
        res.response.itemDetailsItem?.forEach((x) => {
          if (
            headers.filter((v) => v.headerName === x.subcontractorName)
              .length <= 0
          ) {
            headers.push({
              headerName: x.subcontractorName,
              field: `v_${x.contractorId}`,
            });
          }

          if (dto.groupBy === "I") {
            const reqdItem = items.filter((v) => v.id === x.itemId);
            if (reqdItem.length > 0) {
              x.item = reqdItem[0].itemName;
              const reqdUom = uoms.filter(
                (x) => x.value === reqdItem[0].stockUOMId
              );
              if (reqdUom.length > 0) x.unit = reqdUom[0].name;
            }
          }
          if (allItems.indexOf(x.item) < 0) {
            allItems.push(x.item);
          }
        });

        if (dto.groupBy === "I") {
          dto.itemDetailsItem = res.response.itemDetailsItem;
          dto.numberOfSubContractor = headers.length;
          const data = [];
          allItems?.forEach((x, i) => {
            const reqdRow = res.response.itemDetailsItem?.filter(
              (m) => m.item === x
            );
            if (reqdRow.length > 0) {
              let obj = {};
              let total = 0;
              reqdRow.forEach((m) => {
                obj[`v_${m.contractorId}`] = m.quantity;
                total += m.quantity;
              });
              obj.itemName = x;
              obj.slNo = i + 1;
              obj.totalQty = total;
              obj.unit = reqdRow[0].unit;
              data.push(obj);
            }
          });

          setColumnDefs([
            ...columnDefs
              .filter((x) => false)
              .concat(columnDefs1)
              .concat(headers)
              .concat(columnDefs2),
          ]);
          gridRef1.current.api.applyTransaction({
            add: data,
          });

          setItemDetails((r) => [].concat(data));
        }
        if (dto.groupBy === "A") {
          setColumnDefs([
            ...columnDefs.filter((x) => false).concat(columnDefs3),
          ]);
          dto.itemDetailsAmount = res.response.itemDetailsAmount;
          res.response.itemDetailsAmount.forEach((x, i) => {
            x.slNo = i + 1;
          });
          gridRef1.current.api.applyTransaction({
            add: res.response.itemDetailsAmount,
          });

          setItemDetails((r) => [].concat(res.response.itemDetailsAmount));
        }
        setDataReady(true);
      })
      .catch((e) => {
        setAlertTitleText("Oops", "An error occurred");
        setDataReady(true);
        return;
      });
  }
  const [dto, setDTO] = useState({
    id: 0,
    siteCategoryId: 0,
    siteCategory: "",
    siteId: 0,
    site: "",
    supplierCategoryId: 0,
    supplierCategory: "",
    fromDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    toDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  const siteCategoryItems = getSiteCategories();
  const sites = getSites();
  const items = getItemList();
  const uoms = getUoms();
  const [isGridReady, setIsGridReady] = useState(false);

  const siteCategoryProps = {
    options: siteCategoryItems,
    getOptionLabel: (option) => option?.name || "",
  };

  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };

  const supplierCategories = getSupplierCategories().filter((x) => {
    return x.dependentvalue === "L";
  });

  const supplierCategoryProps = {
    options: supplierCategories,
    getOptionLabel: (option) => option?.name || "",
  };

  const suppliers = getSuppliers().filter((x) => {
    return (
      supplierCategories.filter((y) => y.value === x.dependentvalue).length > 0
    );
  });

  const supplierProps = {
    options: suppliers,
    getOptionLabel: (option) => option?.name || "",
  };

  const groupByProps = {
    options: [
      { name: "Item", value: "I" },
      { name: "Amount", value: "A" },
    ],
    getOptionLabel: (option) => option?.name || "",
  };

  useEffect(() => {
    if (isGridReady) {
      supplierCategories
        .filter((x) => false)
        .concat(
          getSupplierCategories().filter((x) => {
            return x.dependentvalue === "L";
          })
        );
      siteCategoryItems.filter((x) => false).concat(getSiteCategories());
      suppliers
        .filter((x) => false)
        .concat(
          getSuppliers().filter((x) => {
            return (
              supplierCategories.filter((y) => y.value === x.dependentvalue)
                .length > 0
            );
          })
        );
      sites.filter((x) => false).concat(getSites());
    }
  }, [isGridReady]);

  // below code is for grid - 1
  const [columnDefs, setColumnDefs] = useState([]);
  const columnDefs1 = [
    {
      headerName: "SL No",
      field: "slNo",
    },
    {
      headerName: "Item Name",
      field: "itemName",
      autoHeight: true,
      wrapText: true,
    },
  ];

  const columnDefs2 = [
    {
      headerName: "Total Qty",
      field: "totalQty",
    },
    {
      headerName: "Unit",
      field: "unit",
    },
  ];

  const columnDefs3 = [
    {
      headerName: "SL No",
      field: "slNo",
    },
    {
      headerName: "Sub Contractor Name",
      field: "subcontractorName",
    },
    {
      headerName: "Billed Amount",
      field: "billedAmount",
    },
    {
      headerName: "Retention Held",
      field: "retentionHeld",
    },
    {
      headerName: "Testing & Commission Held",
      field: "testingAndCommissionHeld",
    },
    {
      headerName: "Advance Balance",
      field: "advanceBalance",
    },
    {
      headerName: "Food Advance",
      field: "foodAdvance",
    },
    {
      headerName: "Total Paid",
      field: "totalPaidAmount",
    },
    {
      headerName: "Total Balance",
      field: "totalBalanceAmount",
    },
    {
      headerName: "Work Order No",
      field: "workOrderNo",
    },
  ];
  const gridRef1 = useRef();
  const defaultColDef1 = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: false,
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: false,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };

  const onGridReady1 = (params) => {
    gridRef1.current.api.setRowData([]);

    const p1 = APIMASTER.SupplierCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        console.log("Set supplier category props");
        setSupplierCategories(resp.response);
      });
    const p2 = APIMASTER.SiteCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSiteCategories(resp.response);
      });

    const p4 = APIMASTER.SiteOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSites(resp.response);
      });

    const p5 = APIMASTER.UomOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setUoms(resp.response);
      });

    const p6 = APIMASTER.Items()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        let itemOptions = [];
        resp.response.forEach((element) => {
          itemOptions.push({
            name: element.itemName,
            value: element.id,
            dependentvalue: element.categoryId,
          });
        });
        setItems(itemOptions);
        setItemList(resp.response);
      });

    Promise.all([p1, p2, p4, p5, p6]).then(() => {
      try {
        setDataReady(true);
        setIsGridReady(true);
        gridRef1.current.api.setRowData([]);
      } catch (e) {}
    });
  };
  const [selectedRowId1, setSelectedRowId1] = useState(0);

  const onRowSelectionChanged1 = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId1(event.api.getSelectedRows()[0].id);
    else setSelectedRowId1(0);
  };
  // grid 1 code ends

  return (
    <SideNav
      currentComponent={LABOUR_MANAGEMENT_REPORT}
      subComponent={SUB_CONTRACTOR_BILLING_SUMMARY_REPORT}
      subComponentPath={PATH_SUB_CONTRACTOR_BILLING_SUMMARY_REPORT}
      detailComponent=""
    >
      <div>
        <div style={style}>
          <ThreeCircles
            visible={!dataReady}
            height="256"
            width="256"
            outerCircleColor={SPANISH_GREEN}
            innerCircleColor="#eeeeee"
            middleCircleColor={SPANISH_GREEN}
            ariaLabel="eNirmaan-loading"
            color={SPANISH_GREEN}
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
        <form>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={onFormSubmit}
            >
              {GENERATE}
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                //backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={() => {
                let filename = "";
                dto.itemDetails = itemDetails;
                setDataReady(false);
                APIREPORTS.SubContractorBillingSummaryReportExport(dto)
                  .then((res) => {
                    setDataReady(true);

                    const contentType = res.headers.get("content-type");
                    if (contentType.startsWith("application/json")) {
                      return Promise.reject(res.json());
                    } else {
                      const header = res.headers.get("Content-Disposition");
                      if (header !== null) {
                        const parts = header.split(";");
                        filename = parts[1].split("=")[1].replaceAll('"', "");
                      } else {
                        filename =
                          "Sub Contractor Billing Summary Export Report.xlsx";
                      }

                      return res.blob();
                    }
                  })
                  .then((blob) => {
                    if (blob !== null) {
                      let url = window.URL.createObjectURL(blob);
                      let a = document.createElement("a");
                      a.href = url;
                      a.download = filename;
                      document.body.appendChild(a);
                      a.click();
                      a.remove();
                    }
                  })
                  .catch((m) => {
                    setAlertTitleText(
                      "Oops...",
                      `Something went wrong...${m.message}`
                    );
                  });
              }}
            >
              {EXPORT}
            </Button>
          </div>
          {/* button div ends */}
          {/* below code is for above container */}
          <div
            style={{
              backgroundColor: LIGHT_GREY_BACKGROUND,
              borderRadius: "5px",
              minWidth: "90%",
              minHeight: "50px",
              padding: 20,
              marginBottom: 10,
              borderStyle: "solid",
              border: "1x",
              borderColor: "#eaeaea",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <table style={{ width: "100%" }}>
              <TableBody>
                <tr>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="From Date"
                        inputFormat="DD-MM-YYYY"
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dto.fromDate}
                        onChange={(newValue) => {
                          setDTO({
                            ...dto,
                            fromDate: newValue.format("YYYY-MM-DD"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="To Date"
                        inputFormat="DD-MM-YYYY"
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dto.toDate}
                        onChange={(newValue) => {
                          setDTO({
                            ...dto,
                            toDate: newValue.format("YYYY-MM-DD"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                  <td>
                    <Autocomplete
                      filterOptions={globalFilter}
                      {...siteCategoryProps}
                      options={siteCategoryItems}
                      id="auto-complete-1"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="siteCategoryId"
                      defaultValue={
                        siteCategoryProps.options.find(
                          (v) => v.value === dto.siteCategoryId
                        ) || {}
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({
                              ...dto,
                              siteCategoryId: v?.value,
                              siteCategory: v?.name,
                            });
                          else
                            setDTO({
                              ...dto,
                              siteCategoryId: Number(v?.value),
                              siteCategory: v?.name,
                            });
                        } else {
                          setDTO({
                            ...dto,
                            siteCategoryId: v?.value,
                            siteCategory: v?.name,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Site Category"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            width: "200px",
                            minHeight: "48.5px",
                            height: "48.5px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Autocomplete
                      {...siteProps}
                      id="auto-complete-2"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="siteId"
                      defaultValue={
                        siteProps.options.find((v) => v.value === dto.siteId) ||
                        {}
                      }
                      filterOptions={(o, s) => {
                        let options = globalFilter(o, s);
                        let newOptions = [];
                        options.forEach((element) => {
                          if (element.dependentvalue === dto.siteCategoryId)
                            newOptions.push(element);
                        });
                        return newOptions;
                      }}
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, siteId: v?.value, site: v?.name });
                          else
                            setDTO({
                              ...dto,
                              siteId: Number(v?.value),
                              site: v?.name,
                            });
                        } else {
                          setDTO({ ...dto, siteId: v?.value, site: v?.name });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Site"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Autocomplete
                      filterOptions={globalFilter}
                      {...supplierCategoryProps}
                      id="auto-complete-1"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="supplierCategoryId"
                      defaultValue={
                        supplierCategoryProps.options.find(
                          (v) => v.value === dto.supplierCategoryId
                        ) || {}
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({
                              ...dto,
                              supplierCategoryId: v?.value,
                              supplierCategory: v?.name,
                            });
                          else
                            setDTO({
                              ...dto,
                              supplierCategoryId: Number(v?.value),
                              supplierCategory: v?.name,
                            });
                        } else {
                          setDTO({
                            ...dto,
                            supplierCategoryId: v?.value,
                            supplierCategory: v?.name,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Supplier Category"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                            height: "48.5px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Autocomplete
                      {...groupByProps}
                      id="auto-complete-4"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="groupBy"
                      isOptionEqualToValue={(o, v) => {
                        return o.value === v.value;
                      }}
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, groupBy: v?.value });
                          else
                            setDTO({
                              ...dto,
                              groupBy: Number(v?.value),
                            });
                        } else {
                          setDTO({ ...dto, groupBy: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Group By"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                </tr>
              </TableBody>
            </table>
          </div>
        </form>
      </div>

      {/* below code is for ag-grid -1 */}
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "570px" }}>
          <AgGridReact
            copyGroupHeadersToClipboard={true}
            enableRangeSelection={true}
            columnDefs={columnDefs}
            ref={gridRef1}
            defaultColDef={defaultColDef1}
            onGridReady={onGridReady1}
            paginationAutoPageSize={true} pagination={true}
            onSelectionChanged={onRowSelectionChanged1}
          ></AgGridReact>
        </div>
      </div>
    </SideNav>
  );
}
