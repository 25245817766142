import { Button, IconButton, TableBody, Typography } from "@mui/material";
import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  LIGHT_GREY_BACKGROUND,
  METALIC_RED,
  PRUSSIAN_BLUE,
  SPANISH_GREEN,
  WARM_GRAY,
  WHITE,
} from "../../../../../resources/constant/colors/theme";

import "./modal.css";

import {
  ON_COMMISSION_AND_HO,
  ON_INSTALLATION,
  ON_SUPPLY,
  ON_TESTING,
  TOTAL,
  TOTAL_SHOULD_BE_100,
} from "../../../../../resources/constant/strings/string";

function Modal({ setOpenModal, model, formTitle, onAdd, viewMode }) {
  const [dto, setDTO] = useState(model);
  const [total1, setTotal1] = useState(
    dto?.supplyTerms1 +
      dto?.supplyTerms2 +
      dto?.supplyTerms3 +
      dto?.supplyTerms4
  );
  const [total2, setTotal2] = useState(
    dto?.installationTerms1 +
      dto?.installationTerms2 +
      dto?.installationTerms3 +
      dto?.installationTerms4
  );

  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    setTotal1(
      Number(dto?.supplyTerms1) +
        Number(dto?.supplyTerms2) +
        Number(dto?.supplyTerms3) +
        Number(dto?.supplyTerms4)
    );
    setTotal2(
      Number(dto?.installationTerms1) +
        Number(dto?.installationTerms2) +
        Number(dto?.installationTerms3) +
        Number(dto?.installationTerms4)
    );

    if (total1 < 100 || total2 < 100) return;

    console.log(dto);
    onAdd(dto);
    setOpenModal(false);
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (isNaN(value)) setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    }
  };

  return (
    <div className="modalBackgroundForSalesOrderPaymentTerms">
      {/* div for top right close button */}
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <IconButton
          style={{ width: "auto", align: "center", marginRight: 5 }}
          onClick={() => setOpenModal(false)}
        >
          <CloseIcon />
        </IconButton>
      </div>
      {/* top right close button div closed */}

      <div
        style={{
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          width: "100%",
        }}
      >
        <table style={{ width: "100%", margin: 5 }}>
          <TableBody>
            <tr>
              <td>
                <Typography
                  fontSize={18}
                  fontWeight={600}
                  fontspacing={"normal"}
                  textTransform="capitalize"
                  align="left"
                >
                  {formTitle}
                </Typography>
              </td>
            </tr>
          </TableBody>
        </table>
      </div>

      <div
        style={{
          backgroundColor: LIGHT_GREY_BACKGROUND,
          borderRadius: "5px",
          minWidth: "90%",
          minHeight: "50px",
          padding: 20,
          marginBottom: 10,
          borderStyle: "solid",
          border: "1x",
          borderColor: "#eaeaea",
          display: "flex",
          marginLeft: 20,
          marginRight: 20,

          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <table style={{ borderSpacing: 30 }}>
          <TableBody>
            <tr>
              <td></td>
              <td>
                <Typography
                  color={WARM_GRAY}
                  style={{
                    fontFamily: "Inter",
                    fontSize: 14,
                    fontstretch: "normal",
                    fontWeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    fontStyle: "normal",
                    userSelect: "none",
                    lineHeight: 2,
                  }}
                >
                  {ON_SUPPLY}
                </Typography>
              </td>
              <td>
                <Typography
                  color={WARM_GRAY}
                  style={{
                    fontFamily: "Inter",
                    fontSize: 14,
                    fontstretch: "normal",
                    fontWeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    fontStyle: "normal",
                    userSelect: "none",
                    lineHeight: 2,
                  }}
                >
                  {ON_INSTALLATION}
                </Typography>
              </td>
              <td>
                <Typography
                  color={WARM_GRAY}
                  style={{
                    fontFamily: "Inter",
                    fontSize: 14,
                    fontstretch: "normal",
                    fontWeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    fontStyle: "normal",
                    userSelect: "none",
                    lineHeight: 2,
                  }}
                >
                  {ON_TESTING}
                </Typography>
              </td>
              <td>
                <Typography
                  color={WARM_GRAY}
                  style={{
                    fontFamily: "Inter",
                    fontSize: 14,
                    fontstretch: "normal",
                    fontWeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    fontStyle: "normal",
                    userSelect: "none",
                    lineHeight: 2,
                  }}
                >
                  {ON_COMMISSION_AND_HO}
                </Typography>
              </td>
              <td>
                <Typography
                  color={WARM_GRAY}
                  style={{
                    fontFamily: "Inter",
                    fontSize: 14,
                    fontstretch: "normal",
                    fontWeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    fontStyle: "normal",
                    userSelect: "none",
                    lineHeight: 2,
                  }}
                >
                  {TOTAL}
                </Typography>
              </td>
            </tr>
            <tr style={{ marginBottom: 20 }}>
              <td>
                <Typography
                  color={PRUSSIAN_BLUE}
                  style={{
                    fontFamily: "Inter",
                    fontSize: 16,
                    fontstretch: "normal",
                    fontWeight: "600",
                    letterSpacing: "normal",
                    fontStyle: "normal",
                    userSelect: "none",
                    lineHeight: 2,
                  }}
                >
                  Supply
                </Typography>
              </td>
              <td>
                <input
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setDTO({ ...dto, [name]: value });
                  }}
                  onWheel={(event) => event.target.blur()}
                  onBlur={(event) => {
                    handleInputChange(event);
                    setTotal1(
                      Number(event.target.value) +
                        dto.supplyTerms2 +
                        dto.supplyTerms3 +
                        dto.supplyTerms4
                    );
                  }}
                  name="supplyTerms1"
                  value={dto.supplyTerms1}
                  style={{
                    width: "50px",
                    height: "20px",
                    textAlign: "center",
                  }}
                ></input>
              </td>
              <td>
                <input
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setDTO({ ...dto, [name]: value });
                  }}
                  onWheel={(event) => event.target.blur()}
                  onBlur={(event) => {
                    handleInputChange(event);
                    setTotal1(
                      dto.supplyTerms1 +
                        Number(event.target.value) +
                        dto.supplyTerms3 +
                        dto.supplyTerms4
                    );
                  }}
                  name="supplyTerms2"
                  value={dto.supplyTerms2}
                  style={{
                    width: "50px",
                    height: "20px",
                    textAlign: "center",
                  }}
                ></input>
              </td>
              <td>
                <input
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setDTO({ ...dto, [name]: value });
                  }}
                  onWheel={(event) => event.target.blur()}
                  onBlur={(event) => {
                    handleInputChange(event);
                    setTotal1(
                      dto.supplyTerms1 +
                        dto.supplyTerms2 +
                        Number(event.target.value) +
                        dto.supplyTerms4
                    );
                  }}
                  name="supplyTerms3"
                  value={dto.supplyTerms3}
                  style={{
                    width: "50px",
                    height: "20px",
                    textAlign: "center",
                  }}
                ></input>
              </td>
              <td>
                <input
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setDTO({ ...dto, [name]: value });
                  }}
                  onWheel={(event) => event.target.blur()}
                  onBlur={(event) => {
                    handleInputChange(event);
                    setTotal1(
                      dto.supplyTerms1 +
                        dto.supplyTerms2 +
                        dto.supplyTerms3 +
                        Number(event.target.value)
                    );
                  }}
                  name="supplyTerms4"
                  value={dto.supplyTerms4}
                  style={{
                    width: "50px",
                    height: "20px",
                    textAlign: "center",
                  }}
                ></input>
              </td>
              <td>
                <input
                  
                  onWheel={(event) => event.target.blur()}
                  disabled
                  value={total1}
                  style={{
                    width: "50px",
                    height: "20px",
                    textAlign: "center",
                  }}
                ></input>
              </td>
            </tr>
            <tr style={{ marginBottom: 20 }}>
              <td>
                <Typography
                  color={PRUSSIAN_BLUE}
                  style={{
                    fontFamily: "Inter",
                    fontSize: 16,
                    fontstretch: "normal",
                    fontWeight: "600",
                    letterSpacing: "normal",
                    fontStyle: "normal",
                    userSelect: "none",
                    lineHeight: 2,
                  }}
                >
                  Installation
                </Typography>
              </td>
              <td>
                <input
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setDTO({ ...dto, [name]: value });
                  }}
                  onWheel={(event) => event.target.blur()}
                  onBlur={(event) => {
                    handleInputChange(event);
                    setTotal2(
                      Number(event.target.value) +
                        dto.installationTerms2 +
                        dto.installationTerms3 +
                        dto.installationTerms4
                    );
                  }}
                  name="installationTerms1"
                  value={dto.installationTerms1}
                  style={{
                    width: "50px",
                    height: "20px",
                    textAlign: "center",
                  }}
                ></input>
              </td>
              <td>
                <input
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setDTO({ ...dto, [name]: value });
                  }}
                  onWheel={(event) => event.target.blur()}
                  onBlur={(event) => {
                    handleInputChange(event);
                    setTotal2(
                      dto.installationTerms1 +
                        Number(event.target.value) +
                        dto.installationTerms3 +
                        dto.installationTerms4
                    );
                  }}
                  name="installationTerms2"
                  value={dto.installationTerms2}
                  style={{
                    width: "50px",
                    height: "20px",
                    textAlign: "center",
                  }}
                ></input>
              </td>
              <td>
                <input
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setDTO({ ...dto, [name]: value });
                  }}
                  onWheel={(event) => event.target.blur()}
                  onBlur={(event) => {
                    handleInputChange(event);
                    setTotal2(
                      dto.installationTerms1 +
                        dto.installationTerms2 +
                        Number(event.target.value) +
                        dto.installationTerms4
                    );
                  }}
                  name="installationTerms3"
                  value={dto.installationTerms3}
                  style={{
                    width: "50px",
                    height: "20px",
                    textAlign: "center",
                  }}
                ></input>
              </td>
              <td>
                <input
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setDTO({ ...dto, [name]: value });
                  }}
                  onWheel={(event) => event.target.blur()}
                  name="installationTerms4"
                  onBlur={(event) => {
                    handleInputChange(event);
                    setTotal2(
                      dto.installationTerms1 +
                        dto.installationTerms2 +
                        dto.installationTerms3 +
                        Number(event.target.value)
                    );
                  }}
                  value={dto.installationTerms4}
                  style={{
                    width: "50px",
                    height: "20px",
                    textAlign: "center",
                  }}
                ></input>
              </td>
              <td>
                <input
                  
                  onWheel={(event) => event.target.blur()}
                  disabled
                  value={total2}
                  style={{
                    width: "50px",
                    height: "20px",
                    textAlign: "center",
                  }}
                ></input>
              </td>
            </tr>
          </TableBody>
        </table>
      </div>
      <Typography
        color={METALIC_RED}
        style={{
          fontFamily: "Inter",
          fontSize: 12,
          fontstretch: "normal",
          fontWeight: "normal",
          letterSpacing: "normal",
          textAlign: "left",
          fontStyle: "normal",
          userSelect: "none",
          lineHeight: 1.52,
          marginLeft: 20,
        }}
      >
        {TOTAL_SHOULD_BE_100}
      </Typography>
      {!viewMode ? (
        <div
          style={{
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              borderColor: SPANISH_GREEN,
            }}
            style={{
              textTransform: "capitalize",
              fontSize: 14,
              color: SPANISH_GREEN,
              minWidth: "180px",
              minHeight: "45px",
              margin: 10,
            }}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: SPANISH_GREEN,
              textTransform: "capitalize",
              fontSize: 14,
              color: WHITE,
              minWidth: "180px",
              minHeight: "45px",
              margin: 10,
            }}
            onClick={onFormSubmit}
          >
            Save
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Modal;
