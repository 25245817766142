import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import React from "react";
import ReactDOMClient from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { AuthProvider } from "./hooks/useAuth";

if (
  process.env.REACT_APP_ENV === "staging" ||
  process.env.REACT_APP_ENV === "production"
) {
  Sentry.init({
    dsn: "https://1c50075c9c754efbabb470aa03109156@o4503905675247616.ingest.sentry.io/4503905677934593",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

if (
  process.env.REACT_APP_ENV === "staging" ||
  process.env.REACT_APP_ENV === "production"
) {
  root.render(
    <React.StrictMode>
      <AuthProvider>
        <App />
      </AuthProvider>
    </React.StrictMode>
  );
} else {
  root.render(
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
