import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, IconButton } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import React, { useRef, useState } from "react";
import { APIMASTER } from "../../../../apihandler/master";
import { paymentTypesRenderer } from "../../../../component/cellRenderers/renderers";
import SideNav from "../../../../component/layouts/sideNav";
import {
  setAlertConfirmDeletion,
  setAlertTitleText,
} from "../../../../component/modal/alertModal";
import {
  PRUSSIAN_BLUE,
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";
import {
  ADD,
  DELETE,
  EMPLOYEE_TYPE,
  MASTER,
} from "../../../../resources/constant/strings/string";
import { PATH_EMPLOYEE_TYPE } from "../../../../resources/constant/url/url";
import Modal from "./modal";

export default function EmployeeType() {
  const gridRef = useRef();

  const [selectedRowId, setSelectedRowId] = useState(0);

  let initialValue = {};

  const setInitialValueForAdd = () => {
    initialValue = {
      id: 0,
      name: "",
      prefix: "",
      paymentType: "",
    };
  };

  const deleteModel = () => {
    if (selectedRowId <= 0) {
      setAlertTitleText("Oops...", `Select a row to delete`);
      return;
    }
    setAlertConfirmDeletion(
      "Confirmation needed",
      "Do you confirm on proceeding to delete this record?"
    ).then((consent) => {
      if (!consent.isConfirmed) return;

      console.log("delete the selected id: ", selectedRowId);
      APIMASTER.DeleteEmployeeType(selectedRowId)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.statusCode === 204) {
            const selectedData = gridRef.current.api.getSelectedRows();
            gridRef.current.api.applyTransaction({ remove: selectedData });
            setAlertTitleText("Success", "The record was deleted successfully");
            setOpenModal(false);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
            setOpenModal(false);
          }
        });
    });
  };

  const updateModel = (model) => {
    gridRef.current.api.applyTransaction({ update: [model] });
  };

  const addModel = (model) => {
    gridRef.current.api.applyTransaction({ add: [model], addIndex: 0 });
  };

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId(event.api.getSelectedRows()[0].id);
    else setSelectedRowId(0);
  };

  const [formTitle, setFormTitle] = useState("Add Employee Type");
  const handleEditData = (oldData) => {
    console.log(oldData);
    initialValue = {
      id: oldData.id,
      name: oldData.name,
      prefix: oldData.prefix,
      paymentType: oldData.paymentType,
    };
    setFormData(initialValue);
    setViewOnlyModel(false);
    setFormTitle("Edit Employee Type");
    setOpenModal(true);
  };

  const handleViewData = (oldData) => {
    console.log(oldData);
    initialValue = {
      id: oldData.id,
      name: oldData.name,
      prefix: oldData.prefix,
      paymentType: oldData.paymentType,
    };
    setFormData(initialValue);
    setViewOnlyModel(true);
    setFormTitle("View Employee Type");
    setOpenModal(true);
  };

  const columnDefs = [
    {
      headerName: "Employee Type",
      field: "name",
      checkboxSelection: false,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Prefix",
      field: "prefix",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },

    {
      flex: 0.6,
      headerName: "Payment Type",
      field: "paymentType",
      valueGetter: (params) => {
        return paymentTypesRenderer({ value: params.data.paymentType });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },

    {
      flex: 0.8,
      headerName: "Actions",
      field: "id",
      sortable: false,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            aria-label="edit"
            onClick={() => {
              handleEditData(params.data);
            }}
          >
            <EditIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
          </IconButton>
          <IconButton
            aria-label="visibility"
            onClick={() => {
              handleViewData(params.data);
            }}
          >
            <VisibilityIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
          </IconButton>
        </div>
      ),
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
  };

  const onGridReady = (params) => {
    APIMASTER.EmployeeTypes()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) {
          setAlertTitleText("Oops..", `An error occurred ${resp.message}`);
          return;
        }
        const data = resp.response.sort((a, b) => b.id - a.id);
        params.api.applyTransaction({ add: data });
      });
  };

  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState(initialValue);
  const [viewOnlyModel, setViewOnlyModel] = useState(false);

  return (
    <SideNav
      currentComponent={MASTER}
      subComponent={EMPLOYEE_TYPE}
      subComponentPath={PATH_EMPLOYEE_TYPE}
      detailComponent=""
    >
      {openModal && (
        <Modal
          style={{ align: "center" }}
          setOpenModal={setOpenModal}
          updateModel={updateModel}
          viewModel={viewOnlyModel}
          addModel={addModel}
          model={formData}
          formTitle={formTitle}
        ></Modal>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginBottom: 10,
          marginRight: 10,
        }}
      >
        <Button
          style={{
            backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: WHITE,
            minWidth: "180px",
            minHeight: "45px",
          }}
          onClick={() => {
            setInitialValueForAdd();
            setFormData(initialValue);
            setViewOnlyModel(false);
            setFormTitle("Add Employee Type");
            setOpenModal(true);
          }}
        >
          {ADD}
        </Button>

        <Button
          onClick={() => {
            deleteModel();
          }}
          variant="outlined"
          sx={{
            borderColor: SPANISH_GREEN,
          }}
          style={{
            //backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: SPANISH_GREEN,
            minWidth: "180px",
            minHeight: "45px",
            marginRight: 10,
          }}
        >
          {DELETE}
        </Button>
      </div>
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "570px" }}>
          <AgGridReact
            copyGroupHeadersToClipboard={true}
            enableRangeSelection={true}
            columnDefs={columnDefs}
            ref={gridRef}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            paginationAutoPageSize={true} pagination={true}
            rowSelection="multiple"
            getRowId={(params) => params.data.id}
            onSelectionChanged={onRowSelectionChanged}
          ></AgGridReact>
        </div>
      </div>
    </SideNav>
  );
}
