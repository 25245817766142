import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, IconButton } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIMASTER } from "../../../../apihandler/master";
import {
  itemCategoryCellRenderer,
  setItemCategories,
  setItems,
  setUoms,
} from "../../../../component/cellRenderers/renderers";
import SideNav from "../../../../component/layouts/sideNav";
import {
  setAlertConfirmDeletion,
  setAlertTitleText,
} from "../../../../component/modal/alertModal";
import {
  PRUSSIAN_BLUE,
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";
import {
  ADD,
  DELETE,
  EXPORT,
  ITEM,
  MASTER,
} from "../../../../resources/constant/strings/string";
import { PATH_ITEM } from "../../../../resources/constant/url/url";
import Modal from "./modal";

export default function Item() {
  const navigate = useNavigate();

  const gridRef = useRef();

  let initialValue = {};

  const setInitialValueForAdd = () => {
    initialValue = {
      id: 0,
      itemName: "",
      itemCode: "Auto Generated",
      categoryId: 0,
      parentId: null,
      purchaseUOMId: null,
      stockUOMId: null,
      description: "",
    };
  };

  const [selectedRowId, setSelectedRowId] = useState(0);

  const deleteModel = () => {
    if (selectedRowId <= 0) {
      setAlertTitleText("Oops...", `Select a row to delete`);
      return;
    }
    setAlertConfirmDeletion(
      "Confirmation needed",
      "Do you confirm on proceeding to delete this record?"
    ).then((consent) => {
      if (!consent.isConfirmed) return;

      console.log("delete the selected id: ", selectedRowId);
      APIMASTER.DeleteItem(selectedRowId)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.statusCode === 204) {
            const selectedData = gridRef.current.api.getSelectedRows();
            gridRef.current.api.applyTransaction({ remove: selectedData });
            setAlertTitleText("Success", "The record was deleted successfully");
            setOpenModal(false);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
            setOpenModal(false);
          }
        });
    });
  };

  const updateModel = (model) => {
    gridRef.current.api.applyTransaction({ update: [model] });
  };

  const addModel = (model) => {
    gridRef.current.api.applyTransaction({ add: [model], addIndex: 0 });
  };

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId(event.api.getSelectedRows()[0].id);
    else setSelectedRowId(0);
  };
  const [formTitle, setFormTitle] = useState("Add Item");
  const handleEditData = (oldData) => {
    console.log(oldData);
    initialValue = {
      id: oldData.id,
      itemName: oldData.itemName,
      itemCode: oldData.itemCode,
      categoryId: oldData.categoryId,
      description: oldData.description,
      parentId: oldData.parentId,
      purchaseUOMId: oldData.purchaseUOMId,
      stockUOMId: oldData.stockUOMId,
    };
    setFormData(initialValue);
    setViewOnlyModel(false);
    setFormTitle("Edit Item");
    setOpenModal(true);
  };

  const handleViewData = (oldData) => {
    console.log(oldData);
    initialValue = {
      id: oldData.id,
      itemName: oldData.itemName,
      itemCode: oldData.itemCode,
      categoryId: oldData.categoryId,
      description: oldData.description,
      parentId: oldData.parentId,
      purchaseUOMId: oldData.purchaseUOMId,
      stockUOMId: oldData.stockUOMId,
    };
    setFormData(initialValue);
    setViewOnlyModel(true);
    setFormTitle("View Item");
    setOpenModal(true);
  };

  const columnDefs = [
    {
      headerName: "Item Code",
      field: "itemCode",
      checkboxSelection: false,
      cellStyle: { "border-right-color": "#e2e2e2" },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Item Name",
      field: "itemName",
      cellStyle: { "border-right-color": "#e2e2e2" },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Description",
      field: "description",
      filterParams: {
        caseSensitive: false,
        defaultOption: "contains",
      },
      cellStyle: { "border-right-color": "#e2e2e2" },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Category",
      field: "categoryId",
      valueGetter: (params) => {
        return itemCategoryCellRenderer({ value: params.data.categoryId });
      },
      cellStyle: { "border-right-color": "#e2e2e2" },
      wrapText: true,
      autoHeight: true,
    },
    {
      // this is for action, edit and view...
      headerName: "Actions",
      field: "id",
      sortable: false,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            aria-label="edit"
            onClick={() => {
              console.log(params.data);
              handleEditData(params.data);
            }}
          >
            <EditIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
          </IconButton>
          <IconButton
            aria-label="visibility"
            onClick={() => {
              handleViewData(params.data);
            }}
          >
            <VisibilityIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
          </IconButton>
        </div>
      ),
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
  };

  const onGridReady = (params) => {
    APIMASTER.ItemCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        setItemCategories(resp.response);
      })
      .then((resp) => {
        APIMASTER.Items()
          .then((resp) => resp.json())
          .then((resp) => {
            if (resp.statusCode !== 200) {
              setAlertTitleText("Oops..", `An error occurred ${resp.message}`);
              return;
            }
            let itemOptions = [];
            resp.response.forEach((element) => {
              itemOptions.push({
                name: element.itemName,
                value: element.id,
                dependentvalue: element.categoryId,
              });
            });
            setItems(itemOptions);
            const data = resp.response.sort((a, b) => b.id - a.id);
            params.api.applyTransaction({ add: data });
          });
      });

    APIMASTER.UomOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        setUoms(resp.response);
      });
  };

  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState(initialValue);
  const [viewOnlyModel, setViewOnlyModel] = useState(false);

  return (
    <SideNav
      currentComponent={MASTER}
      subComponent={ITEM}
      subComponentPath={PATH_ITEM}
      detailComponent=""
    >
      {openModal && (
        <Modal
          style={{ align: "center" }}
          updateModel={updateModel}
          addModel={addModel}
          viewModel={viewOnlyModel}
          setOpenModal={setOpenModal}
          model={formData}
          formTitle={formTitle}
        ></Modal>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginBottom: 10,
          marginRight: 10,
        }}
      >
        <Button
          style={{
            backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: WHITE,
            minWidth: "180px",
            minHeight: "45px",
          }}
          onClick={() => {
            setInitialValueForAdd();
            setViewOnlyModel(false);
            setFormData(initialValue);
            setFormTitle("Add Item");
            setOpenModal(true);
          }}
        >
          {ADD}
        </Button>

        <Button
          onClick={() => {
            deleteModel();
          }}
          variant="outlined"
          sx={{
            borderColor: SPANISH_GREEN,
          }}
          style={{
            //backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: SPANISH_GREEN,
            minWidth: "180px",
            minHeight: "45px",
            marginRight: 10,
          }}
        >
          {DELETE}
        </Button>
        <Button
          style={{
            backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: WHITE,
            minWidth: "180px",
            minHeight: "45px",
            marginRight: "10px",
          }}
          onClick={() => {
            let filename = "";
            APIMASTER.ItemExport()
              .then((res) => {
                const contentType = res.headers.get("content-type");
                if (contentType.startsWith("application/json")) {
                  return Promise.reject(res.json());
                } else {
                  const header = res.headers.get("Content-Disposition");
                  if (header !== null) {
                    const parts = header.split(";");
                    filename = parts[1].split("=")[1].replaceAll('"', "");
                  } else {
                    filename = "items.xlsx";
                  }

                  return res.blob();
                }
              })
              .then((blob) => {
                if (blob !== null) {
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement("a");
                  a.href = url;
                  a.download = filename;
                  document.body.appendChild(a);
                  a.click();
                  a.remove();
                }
              })
              .catch((m) => {
                setAlertTitleText(
                  "Oops...",
                  `Something went wrong...${m.message}`
                );
              });
          }}
        >
          {EXPORT}
        </Button>
      </div>
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "570px" }}>
          <AgGridReact
            copyGroupHeadersToClipboard={true}
            enableRangeSelection={true}
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            paginationAutoPageSize={true} pagination={true}
            rowSelection="multiple"
            getRowId={(params) => params.data.id}
            onSelectionChanged={onRowSelectionChanged}
          ></AgGridReact>
        </div>
      </div>
    </SideNav>
  );
}
