export const PATH_LOGIN = "/";
export const PATH_DASHBOARD = "/dashboard";
export const PATH_MASTER = "/master";
export const PATH_SITE = "/master/site";
export const PATH_UOM = "/master/uom";
export const PATH_TAX = "/master/tax";
export const PATH_SUPPLIER = "/master/supplier";
export const PATH_ITEM = "/master/item";
export const PATH_SUPPLIER_CATEGORIES = "/master/supplier_categories";
export const PATH_ITEM_CATEGORIES = "/master/item_categories";
export const PATH_SITE_CATEGORIES = "/master/site_categories";
export const PATH_USER_MANAGEMENT = "/user_management";
export const PATH_PURCHASE_PRICE_CONFIGURATION =
  "/material_management/purchase_price_configuration";
export const PATH_INDENT = "/material_management/indent";
export const PATH_INDENT_WITH_DATE = "/material_management/indent/:date";
export const PATH_INDENT_DETAILS =
  "/material_management/indent/indent_details/";
export const PATH_INDENT_DETAILS_WITH_PARAM =
  "/material_management/indent/indent_details/:id";

export const PATH_SALES_ORDERS = "/customer_management/sales_orders";
export const PATH_SALES_ORDERS_WITH_DATE =
  "/customer_management/sales_orders/:date";
export const PATH_SALES_ORDERS_DETAIL =
  "/customer_management/sales_orders/sales_orders_detail/:id";
export const PATH_SALES_ORDERS_DETAIL_WITHOUT_PARAM =
  "/customer_management/sales_orders/sales_orders_detail/";

export const PATH_PURCHASE_ORDER = "/material_management/purchase_order";
export const PATH_PURCHASE_ORDER_WITH_DATE =
  "/material_management/purchase_order/:date";

export const PATH_INVOICE_WITH_DATE = "/customer_management/invoice/:date";
export const PATH_INVOICE = "/customer_management/invoice";
export const PATH_INVOICE_DETAIL =
  "/customer_management/invoice/invoice_detail/:id";
export const PATH_INVOICE_DETAIL_WITHOUT_PARAM =
  "/customer_management/invoice/invoice_detail/";

export const PATH_PURCHASE_ORDER_DETAIL =
  "/material_management/purchase_order/purchase_order_detail/:id";
export const PATH_PURCHASE_ORDER_DETAIL_WITHOUT_PARAM =
  "/material_management/purchase_order/purchase_order_detail/";

export const PATH_GOODS_RECEIVED = "/material_management/goods_received";
export const PATH_GOODS_RECEIVED_DETAIL =
  "/material_management/goods_received/goods_received_detail/";
export const PATH_GOODS_RECEIVED_WITH_DATE =
  "/material_management/goods_received/:date";
export const PATH_GOODS_RECEIVED_DETAIL_WITH_PARAM =
  "/material_management/goods_received/goods_received_detail/:id";

export const PATH_CREDIT_NOTE = "/material_management/credit_note";
export const PATH_CREDIT_NOTE_DETAIL =
  "/material_management/credit_note/credit_note_detail/";
export const PATH_CREDIT_NOTE_WITH_DATE =
  "/material_management/credit_note/:date";
export const PATH_CREDIT_NOTE_DETAIL_WITH_PARAM =
  "/material_management/credit_note/credit_note_detail/:id";

export const PATH_MATERIAL_TRANSFER = "/material_management/material_transfer";
export const PATH_MATERIAL_TRANSFER_WITH_DATE =
  "/material_management/material_transfer/:date";
export const PATH_MATERIAL_TRANSFER_DETAIL =
  "/material_management/material_transfer/material_transfer_detail/";
export const PATH_MATERIAL_TRANSFER_DETAIL_WITH_PARAM =
  "/material_management/material_transfer/material_transfer_detail/:id";

export const PATH_SUB_CONTRACTOR_FOOD_ADVANCE =
  "/labour_management/sub_contractor_food_advance";
export const PATH_SUB_CONTRACTOR_FOOD_ADVANCE_WITH_DATE =
  "/labour_management/sub_contractor_food_advance/:date";
export const PATH_SUB_CONTRACTOR_FOOD_ADVANCE_DETAIL =
  "/labour_management/sub_contractor_food_advance/sub_contractor_food_advance_detail/";
export const PATH_SUB_CONTRACTOR_FOOD_ADVANCE_DETAIL_WITH_PARAM =
  "/labour_management/sub_contractor_food_advance/sub_contractor_food_advance_detail/:id";
export const PATH_SUB_CONTRACTOR_ADVANCE_REQUEST =
  "/labor_management/sub_contractor_advance_request";
export const PATH_SUB_CONTRACTOR_ADVANCE_REQUEST_WITH_DATE =
  "/labor_management/sub_contractor_advance_request/:date";
export const PATH_SUB_CONTRACTOR_ADVANCE_REQUEST_DETAIL =
  "/labour_management/sub_contractor_advance_request/sub_contractor_advance_request_detail/";

export const PATH_SUB_CONTRACTOR_ADVANCE_REQUEST_DETAIL_WITH_PARAM =
  "/labour_management/sub_contractor_advance_request/sub_contractor_advance_request_detail/:id";

export const PATH_WORK_ORDER = "/labour_management/work_order";
export const PATH_WORK_ORDER_WITH_DATE = "/labour_management/work_order/:date";
export const PATH_WORK_ORDER_DETAIL =
  "/labour_management/work_order/work_order_detail/";
export const PATH_WORK_ORDER_DETAIL_WITH_PARAM =
  "/labour_management/work_order/work_order_detail/:id";

export const PATH_DAILY_WORK_ENTRY = "/labour_management/daily_work_entry";
export const PATH_DAILY_WORK_ENTRY_WITH_DATE =
  "/labour_management/daily_work_entry/:date";
export const PATH_DAILY_WORK_ENTRY_DETAILS =
  "/labour_management/daily_work_entry/daily_work_entry_details/";
export const PATH_DAILY_WORK_ENTRY_DETAILS_WITH_PARAM =
  "/labour_management/daily_work_entry/daily_work_entry_details/:id";
export const PATH_LABOURER_TYPE = "/master/labourer_type";
export const PATH_ADD_EMPLOYEE = "/master/add_employee";
export const PATH_EMPLOYEE_TYPE = "/master/employee_type";
export const PATH_MANAGER_ASSIGNMENT = "/labour_management/manager_assignment";
export const PATH_SUB_CONTRACTOR_BILLING =
  "/labour_management/sub_contractor_billing";
export const PATH_SUB_CONTRACTOR_BILLING_WITH_DATE =
  "/labour_management/sub_contractor_billing/:date";

export const PATH_SUB_CONTRACTOR_BILLING_DETAIL =
  "/labour_management/sub_contractor_billing/sub_contractor_billing_detail/";
export const PATH_SUB_CONTRACTOR_BILLING_DETAIL_WITH_PARAM =
  "/labour_management/sub_contractor_billing/sub_contractor_billing_detail/:id";

export const PATH_PURCHASE_ORDER_REPORT = "/reports/purchase_order_report";
export const PATH_GOODS_RECEIVED_REPORT = "/reports/goods_received_report";
export const PATH_STOCK_STATEMENT_REPORT = "/reports/stock_statement_report";
export const PATH_PURCHASE_RATIO_REPORT = "/reports/purchase_ratio_report";
export const PATH_EXCAVATOR_SUMMARY_REPORT =
  "/reports/excavator_summary_report";
export const PATH_SUB_CONTRACTOR_BILLING_REPORT =
  "/reports/sub_contractor_billing_report";
export const PATH_SUB_CONTRACTOR_ADVANCE_REQUEST_SUMMARY_REPORT =
  "/reports/sub_contractor_advance_request_summary_report";
export const PATH_SUB_CONTRACTOR_FOOD_ADVANCE_DETAIL_REPORT =
  "/reports/sub_contractor_food_advance_detail_report";
export const PATH_TEMPLATE = "/template";
export const PATH_SUB_CONTRACTOR_BILLING_SUMMARY_REPORT =
  "/reports/sub_contractor_billing_summary_report";
export const PATH_SUB_CONTRACTOR_BILLED_QTY =
  "/reports/sub_contractor_billed_qty";
export const PATH_DESIGNATION = "/master/designation";
export const PATH_ATTENDANCE_REPORT = "/reports/attendance_report";
export const PATH_BALANCE_BILLING_REPORT = "/reports/balance_billing_report";
