import {
  Autocomplete,
  Button,
  createFilterOptions,
  IconButton,
  TableBody,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  BLACK,
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";

import { Stack } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { APIMATERIALMGMT } from "../../../../apihandler/materialmgmt";
import {
  getItemList,
  getItems,
  getSuppliers,
  globalFilter,
} from "../../../../component/cellRenderers/renderers";
import { setAlertTitleText } from "../../../../component/modal/alertModal";
import "./modal.css";

function Modal({
  setOpenModal,
  model,
  formTitle,
  updateModel,
  addModel,
  viewModel,
}) {
  const [dateValue, setDateValue] = React.useState(dayjs());

  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    //only required so direct api call
    if (dto.id <= 0)
      APIMATERIALMGMT.AddPurchasePriceConfig(dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            addModel(data.response);
            setAlertTitleText(
              "Success",
              "The record was inserted successfully"
            );
            setOpenModal(false);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    else
      APIMATERIALMGMT.UpdatePurchasePriceConfig(dto.id, dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            updateModel(data.response);
            setAlertTitleText("Success", "The record was updated successfully");
            setOpenModal(false);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
          }
        });
  }

  const [dto, setDTO] = useState(model);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (isNaN(value) || name === "description")
        setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    }
  };

  const suppliers = getSuppliers().filter((v) => v.name !== "Opening Stock");
  const supplierProps = {
    options: suppliers,
    getOptionLabel: (option) => option?.name || "",
  };

  const items = getItems();
  const itemProps = {
    options: items,
    getOptionLabel: (option) => option?.name || "",
  };

  const itemList = getItemList();

  return (
    <div className="modalBackgroundForPurchasePriceConfiguration">
      {/* div for top right close button */}
      <form>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton
            style={{ width: "auto", align: "center", marginRight: 5 }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* top right close button div closed */}

        <div
          style={{
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <table style={{ width: "100%", margin: 5 }}>
            <TableBody>
              <tr>
                <td>
                  <Typography
                    fontSize={18}
                    fontWeight={600}
                    fontspacing={"normal"}
                    textTransform="capitalize"
                    align="left"
                  >
                    {formTitle}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Effective Date "
                      style={{
                        minWidth: "200px",
                        width: "200px",
                        minHeight: "48.5px",
                      }}
                      maxDate={dayjs()}
                      value={dayjs(dto.effectiveDate)}
                      inputFormat="DD-MM-YYYY"
                      onChange={(newValue) => {
                        setDateValue(newValue);
                        setDTO({
                          ...dto,
                          effectiveDate: newValue.format("YYYY-MM-DD"),
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          required
                          style={{
                            minWidth: "200px",
                            width: "200px",
                            minHeight: "48.5px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </td>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    {...supplierProps}
                    id="auto-complete-1"
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    name="supplierId"
                    defaultValue={
                      supplierProps.options.find(
                        (v) => v.value === dto.supplierId
                      ) || {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, supplierId: v?.value });
                        else setDTO({ ...dto, supplierId: Number(v?.value) });
                      } else {
                        setDTO({ ...dto, supplierId: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Supplier"
                        required
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          width: "200px",
                          minHeight: "48.5px",
                        }}
                        InputLabelProps={{
                          style: { color: "grey" },
                        }}
                      />
                    )}
                  />
                </td>
                <td>
                  <Autocomplete
                    filterOptions={createFilterOptions({
                      ignoreCase: true,
                      matchFrom: "start",
                    })}
                    {...itemProps}
                    id="auto-complete-3"
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    name="itemId"
                    defaultValue={
                      itemProps.options.find((v) => v.value === dto.itemId) ||
                      {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        let description = "";
                        const items = itemList.filter((a, i) => {
                          return a.id === v?.value;
                        });
                        if (items.length > 0) {
                          description = items[0].description;
                        }

                        if (isNaN(v?.value))
                          setDTO({
                            ...dto,
                            itemId: v?.value,
                            description: description,
                          });
                        else
                          setDTO({
                            ...dto,
                            itemId: Number(v?.value),
                            description: description,
                          });
                      } else {
                        setDTO({ ...dto, itemId: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Item"
                        required
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          minHeight: "48.5px",
                          width: "200px",
                          height: "48.5px",
                        }}
                        InputLabelProps={{
                          style: { color: "grey" },
                        }}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setDTO({...dto, [name]: value});
                    }}
                    onWheel={(event) => event.target.blur()}
                    variant="standard"
                    label="Rate"
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    name="rate"
                    value={dto.rate}
                    onBlur={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                    inputProps={{ style: { textAlign: "center" } }}
                  ></TextField>
                </td>
                <td colSpan={2}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                  >
                    <TextareaAutosize
                      variant="standard"
                      maxLength={128}
                      label="Description"
                      style={{
                        outlineColor: BLACK,
                        borderTop: "10px",
                        borderRight: "10px",
                        borderLeft: "10px",
                        marginTop: 15,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        maxWidth: "460px",
                      }}
                      multiline="true"
                      maxRows={4}
                      minRows={2}
                      //maxRows={2}
                      value={dto.description}
                      name="description"
                      onChange={handleInputChange}
                      placeholder="Description"
                    ></TextareaAutosize>
                  </Stack>
                </td>
              </tr>
            </TableBody>
          </table>
        </div>

        {/* div for buttons */}
        <div
          style={{
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
            marginTop: 10,
          }}
        >
          <Button
            variant="outlined"
            sx={{
              borderColor: SPANISH_GREEN,
            }}
            style={{
              textTransform: "capitalize",
              fontSize: 14,
              color: SPANISH_GREEN,
              minWidth: "180px",
              minHeight: "45px",
              margin: 10,
            }}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: SPANISH_GREEN,
              textTransform: "capitalize",
              fontSize: 14,
              color: WHITE,
              minWidth: "180px",
              minHeight: "45px",
              margin: 10,
            }}
            onClick={onFormSubmit}
          >
            Save
          </Button>
        </div>
        {/* div for buttons closed */}
      </form>
      {/* pop up div closed  */}
    </div>
  );
}

export default Modal;
