import { Button, TableBody, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { APIREPORTS } from "../../../../apihandler/reports";
import {
  getSites,
  getSupplierCategories,
  getSuppliers,
} from "../../../../component/cellRenderers/renderers";
import SideNav from "../../../../component/layouts/sideNav";
import { setAlertTitleText } from "../../../../component/modal/alertModal";
import {
  LIGHT_GREY_BACKGROUND,
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";
import {
  ATTENDANCE_REPORT,
  CUSTOMER_MANAGEMENT_REPORT,
  EXPORT,
  GENERATE,
  LABOUR_MANAGEMENT_REPORT,
} from "../../../../resources/constant/strings/string";
import { PATH_ATTENDANCE_REPORT } from "../../../../resources/constant/url/url";
import { APIUTILS } from "../../../../apihandler/utils";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  color: SPANISH_GREEN,
  transform: "translate(-50%, -50%)",
  zIndex: 2,
};

export default function AttendanceReport() {
  const [itemDetails, setItemDetails] = useState([]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    console.log("dto", dto);
    setDataReady(false);
    APIREPORTS.AttendanceReport(dto)
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode !== 200) {
          setDataReady(true);
          setAlertTitleText("Oops..", `An error occurred ${data.message}`);
          return;
        }
        try {
          setDataReady(true);
          gridRef1.current.api.setRowData([]);
        } catch (e) {}
        setItemDetails((r) => [].concat(data.response.itemDetails));
        gridRef1.current.api.applyTransaction({
          add: data.response.itemDetails,
        });
        setTimeout(() => {
          let pinnedBottomData = generatePinnedBottomData();
          gridRef1.current.api.setPinnedBottomRowData([pinnedBottomData]);
        }, 2000);
      })
      .catch((e) => {
        setAlertTitleText("Oops", "An error occurred");
        setDataReady(true);
        return;
      });
  }

  const generatePinnedBottomData = () => {
    // generate a row-data with null values
    let result = {};

    gridRef1.current.columnApi.getAllGridColumns().forEach((item) => {
      result[item.colId] = null;
    });
    return calculatePinnedBottomData(result);
  };

  const calculatePinnedBottomData = (target) => {
    //console.log(target);
    //**list of columns fo aggregation**
    let columnsWithAggregation = [
      "supplierName",
      "fitter",
      "fitterOT",
      "semiFitter",
      "semiFitterOT",
      "helper",
      "helperOT",
      "mason",
      "masonOT",
      "totalLabour",
      "totalOT",
    ];
    columnsWithAggregation.forEach((element) => {
      gridRef1.current.api.forEachNodeAfterFilter((rowNode) => {
        if (element === "supplierName") {
          target[element] = "Grand Total";
          return;
        }
        if (rowNode.data[element] || rowNode.data[element] === 0)
          target[element] += Number.parseFloat(
            APIUTILS.Financial(rowNode.data[element])
          );
      });
      if (target[element] && element !== "supplierName") {
        target[element] = `${APIUTILS.Financial(target[element])}`;
      }
    });
    return target;
  };

  const [dto, setDTO] = useState({
    date: dayjs().format("YYYY-MM-DD"),
  });
  const [dataReady, setDataReady] = useState(true);
  const sites = getSites();

  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };
  // below code is for grid - 1
  const columnDefs1 = [
    {
      flex: 2,
      headerName: "Contractor Name",
      field: "supplierName",
      autoHeight: true,
      wrapText: true,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 2,
      headerName: "Work Order No",
      field: "workOrderNo",
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Fitter",
      field: "fitter",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "OT Hours",
      field: "fitterOT",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Semi Fitter",
      field: "semiFitter",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Semi Fitter OT",
      field: "semiFitterOT",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Helper",
      field: "helper",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "OT Hours",
      field: "helperOT",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Mason",
      field: "mason",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "OT Hours",
      field: "masonOT",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Total Labour",
      field: "totalLabour",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Total OT Hours",
      field: "totalOT",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
  ];
  const gridRef1 = useRef();
  const defaultColDef1 = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };
  const onGridReady1 = (params) => {
    gridRef1.current.api.setRowData([]);
  };
  const [selectedRowId1, setSelectedRowId1] = useState(0);

  const onRowSelectionChanged1 = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId1(event.api.getSelectedRows()[0].id);
    else setSelectedRowId1(0);
  };
  // grid 1 code ends

  const supplierCategories = getSupplierCategories().filter((x) => {
    return x.dependentvalue === "L";
  });
  const suppliers = getSuppliers().filter((x) => {
    return (
      supplierCategories.filter((y) => y.value === x.dependentvalue).length > 0
    );
  });

  const supplierProps = {
    options: suppliers,
    getOptionLabel: (option) => option?.name || "",
  };
  return (
    <SideNav
      currentComponent={LABOUR_MANAGEMENT_REPORT}
      subComponent={ATTENDANCE_REPORT}
      subComponentPath={PATH_ATTENDANCE_REPORT}
      detailComponent=""
    >
      <div>
        <div style={style}>
          <ThreeCircles
            visible={!dataReady}
            height="256"
            width="256"
            outerCircleColor={SPANISH_GREEN}
            innerCircleColor="#eeeeee"
            middleCircleColor={SPANISH_GREEN}
            ariaLabel="eNirmaan-loading"
            color={SPANISH_GREEN}
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
        <form>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={onFormSubmit}
            >
              {GENERATE}
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                //backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={() => {
                let filename = "";
                dto.itemDetails = itemDetails;
                setDataReady(false);
                APIREPORTS.AttendanceReportExport(dto)
                  .then((res) => {
                    setDataReady(true);

                    const contentType = res.headers.get("content-type");
                    if (contentType.startsWith("application/json")) {
                      return Promise.reject(res.json());
                    } else {
                      const header = res.headers.get("Content-Disposition");
                      if (header !== null) {
                        const parts = header.split(";");
                        filename = parts[1].split("=")[1].replaceAll('"', "");
                      } else {
                        filename = "Attendance Export Report.xlsx";
                      }

                      return res.blob();
                    }
                  })
                  .then((blob) => {
                    if (blob !== null) {
                      let url = window.URL.createObjectURL(blob);
                      let a = document.createElement("a");
                      a.href = url;
                      a.download = filename;
                      document.body.appendChild(a);
                      a.click();
                      a.remove();
                    }
                  })
                  .catch((m) => {
                    setAlertTitleText(
                      "Oops...",
                      `Something went wrong...${m.message}`
                    );
                  });
              }}
            >
              {EXPORT}
            </Button>
          </div>
          {/* button div ends */}
          {/* below code is for above container */}
          <div
            style={{
              backgroundColor: LIGHT_GREY_BACKGROUND,
              borderRadius: "5px",
              minWidth: "90%",
              minHeight: "50px",
              padding: 20,
              marginBottom: 10,
              borderStyle: "solid",
              border: "1x",
              borderColor: "#eaeaea",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <table style={{ width: "100%" }}>
              <TableBody>
                <tr>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Date"
                        inputFormat="DD-MM-YYYY"
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dto.date}
                        onChange={(newValue) => {
                          setDTO({
                            ...dto,
                            date: newValue.format("YYYY-MM-DD"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                </tr>
              </TableBody>
            </table>
          </div>
        </form>
      </div>

      {/* below code is for ag-grid -1 */}
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "570px" }}>
          <AgGridReact
            copyGroupHeadersToClipboard={true}
            enableRangeSelection={true}
            columnDefs={columnDefs1}
            ref={gridRef1}
            defaultColDef={defaultColDef1}
            onGridReady={onGridReady1}
            paginationAutoPageSize={true} pagination={true}
            onSelectionChanged={onRowSelectionChanged1}
          ></AgGridReact>
        </div>
      </div>
    </SideNav>
  );
}
