import {
  Autocomplete,
  Button,
  InputAdornment,
  Table,
  TableBody,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { APISALES } from "../../../../../apihandler/sales";
import { APIUTILS } from "../../../../../apihandler/utils";
import {
  getClosureStatuses,
  getOrders,
  getSiteCategories,
  getSites,
  globalFilter,
} from "../../../../../component/cellRenderers/renderers";
import { setAlertTitleText } from "../../../../../component/modal/alertModal";
import {
  LIGHT_GREY_BACKGROUND,
  SPANISH_GREEN,
  WHITE,
} from "../../../../../resources/constant/colors/theme";
import {
  CANCEL,
  DOWNLOAD_TEMPLATE,
  SAVE,
  UPLOAD,
} from "../../../../../resources/constant/strings/string";
import { PATH_INVOICE } from "../../../../../resources/constant/url/url";
import UploadModal from "./uploadModal";

export default function InvoiceDetailComponent({
  onAdd,
  onOrderChanged,
  model,
}) {
  let { id } = useParams();
  const [addModel, setAddModel] = useState(id == 0);
  const [editModel, setEditModel] = useState(id > 0);

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [formTitle, setFormTitle] = useState("Payment Terms ( In Percentage )");
  const location = useLocation();

  const navigate = useNavigate();
  const onFormSubmit = (e) => {
    e.preventDefault();

    validation();
    //
  };
  function validation() {
    dto.invoiceDetail = dto.invoiceDetail.filter(
      (x) =>
        x.supplyAmt1 > 0 ||
        x.supplyAmt2 > 0 ||
        x.supplyAmt3 > 0 ||
        x.supplyAmt4 > 0 ||
        x.installationAmt1 > 0 ||
        x.installationAmt2 > 0 ||
        x.installationAmt3 > 0 ||
        x.installationAmt4 > 0
    );

    //validation and other api calls to be made here
    if (dto.id <= 0)
      APISALES.AddInvoice(dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setAlertTitleText(
              "Success",
              "The record was inserted successfully"
            );
            navigate(PATH_INVOICE + "/" + location.state.date);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    else
      APISALES.UpdateInvoice(dto.id, dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setAlertTitleText("Success", "The record was updated successfully");
            navigate(PATH_INVOICE + "/" + location.state.date);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
          }
        });
  }

  const [dateValue, setDateValue] = useState(dayjs());
  const [dto, setDTO] = useState(model);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (isNaN(value)) setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    }
  };
  const siteCategories = getSiteCategories();
  const sites = getSites();
  const closureStatuses = getClosureStatuses();
  const salesOrders = getOrders();

  const salesOrderProps = {
    options: salesOrders,
    getOptionLabel: (option) => option?.name || "",
  };

  const siteCategoryProps = {
    options: siteCategories,
    getOptionLabel: (option) => option?.name || "",
  };

  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };

  let previousOrderId = id;

  const onAddDetail = (data) => {
    if (previousOrderId !== dto.salesOrderId) {
      APISALES.SalesOrderDetail(dto.salesOrderId)
        .then((resp) => resp.json())
        .then((json) => {
          onOrderChanged(json.response.paymentTerms);
          previousOrderId = dto.salesOrderId;
        });
    }
    let detailData = onAdd(data);
    let totalSupplyAmount = 0.0;
    let totalInstallationAmount = 0.0;
    let totalAmount = 0.0;

    detailData.forEach((e) => {
      totalSupplyAmount += Number.parseFloat(
        APIUTILS.Financial(
          e.supplyAmt1 + e.supplyAmt2 + e.supplyAmt3 + e.supplyAmt4
        )
      );
      totalInstallationAmount += Number.parseFloat(
        APIUTILS.Financial(
          e.installationAmt1 +
            e.installationAmt2 +
            e.installationAmt3 +
            e.installationAmt4
        )
      );
    });

    let paymentTermsQty = [];
    detailData.forEach((e) => {
      paymentTermsQty.push({
        itemId: e.itemId,
        invoiceId: 0,
        supplyQty1: e.supplyQty1,
        supplyQty2: e.supplyQty2,
        supplyQty3: e.supplyQty3,
        supplyQty4: e.supplyQty4,
        installationQty1: e.installationQty1,
        installationQty2: e.installationQty2,
        installationQty3: e.installationQty3,
        installationQty4: e.installationQty4,
      });
    });

    totalAmount = APIUTILS.Financial(
      totalSupplyAmount + totalInstallationAmount
    );
    setDTO({
      ...dto,
      totalAmount: Number(APIUTILS.Financial(totalAmount)),
      totalInstallationAmount: Number(
        APIUTILS.Financial(totalInstallationAmount)
      ),
      totalSupplyAmount: Number(APIUTILS.Financial(totalSupplyAmount)),
      grandTotal: Number(APIUTILS.Financial(totalAmount)),
      invoiceDetail: detailData,
      paymentTermsQty: paymentTermsQty,
    });
  };

  return (
    <div>
      <form>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginBottom: 10,
            marginRight: 10,
          }}
        >
          <Button
            style={{
              backgroundColor: SPANISH_GREEN,
              textTransform: "capitalize",
              fontSize: 14,
              color: WHITE,
              minWidth: "180px",
              minHeight: "45px",
            }}
            onClick={() => {
              navigate(PATH_INVOICE + "/" + location.state.date);
            }}
          >
            {CANCEL}
          </Button>

          <Button
            variant="outlined"
            sx={{
              borderColor: SPANISH_GREEN,
            }}
            style={{
              //backgroundColor: SPANISH_GREEN,
              textTransform: "capitalize",
              fontSize: 14,
              color: SPANISH_GREEN,
              minWidth: "180px",
              minHeight: "45px",
              marginRight: 10,
            }}
            onClick={onFormSubmit}
          >
            {SAVE}
          </Button>
        </div>
        <div
          style={{
            backgroundColor: LIGHT_GREY_BACKGROUND,
            borderRadius: "5px",
            minWidth: "90%",
            minHeight: "50px",
            padding: 20,
            marginBottom: 10,
            borderStyle: "solid",
            border: "1x",
            borderColor: "#eaeaea",
            display: "flex",

            //alignItems: "center",
            //alignContent: "center",
            //justifyContent: "center",
          }}
        >
          {/* inside grey background */}
          <div
            style={{
              display: "flex",
              minWidth: "100%",
              flexDirection: "column",
            }}
          >
            <Table style={{ margin: 10 }}>
              <TableBody>
                <tr>
                  <td>
                    <TextField
                      variant="standard"
                      label="RA Bill No"
                      style={{
                        minWidth: "200px",
                        width: "200px",
                        minHeight: "48.5px",
                      }}
                      name="invoiceNo"
                      disabled={addModel || editModel}
                      value={dto.invoiceNo}
                      InputLabelProps={{
                        style: { color: "grey" },
                      }}
                    ></TextField>
                  </td>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="RA Bill Orders Date"
                        inputFormat="DD-MM-YYYY"
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dayjs(dto.invoiceDate)}
                        maxDate={dayjs()}
                        disabled={editModel}
                        onChange={(newValue) => {
                          setDateValue(newValue);
                          setDTO({
                            ...dto,
                            invoiceDate: newValue.format("YYYY-MM-DD"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            style={{
                              minWidth: "200px",
                              width: "200px",
                              minHeight: "48.5px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey" },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                  <td>
                    <Autocomplete
                      filterOptions={globalFilter}
                      {...siteCategoryProps}
                      id="auto-complete-1"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="categoryId"
                      disabled={editModel}
                      defaultValue={
                        siteCategoryProps.options.find(
                          (v) => v.value === dto.categoryId
                        ) || {}
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, categoryId: v?.value });
                          else setDTO({ ...dto, categoryId: Number(v?.value) });
                        } else {
                          setDTO({ ...dto, categoryId: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Site Category"
                          required
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            width: "200px",
                            minHeight: "48.5px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                        />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Autocomplete
                      filterOptions={(o, s) => {
                        let options = globalFilter(o, s);
                        let newOptions = [];
                        options.forEach((element) => {
                          if (element.dependentvalue === dto.categoryId)
                            newOptions.push(element);
                        });
                        return newOptions;
                      }}
                      {...siteProps}
                      id="auto-complete-2"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="customerId"
                      disabled={editModel}
                      defaultValue={
                        siteProps.options.find(
                          (v) => v.value === dto.customerId
                        ) || {}
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (v.dependentvalue !== dto.categoryId) return;

                          if (isNaN(v?.value))
                            setDTO({ ...dto, customerId: v?.value });
                          else setDTO({ ...dto, customerId: Number(v?.value) });
                        } else {
                          setDTO({ ...dto, customerId: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Site Name/Project Name"
                          required
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            width: "200px",
                            minHeight: "48.5px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Autocomplete
                      filterOptions={(o, s) => {
                        let options = globalFilter(o, s);
                        let newOptions = [];
                        options.forEach((element) => {
                          if (
                            element.dependentvalue === dto.customerId &&
                            (element.otherdependentvalue === "P" ||
                              element.otherdependentvalue === "N") &&
                            (dayjs(dto.invoiceDate).isSame(element.date) ||
                              dayjs(dto.invoiceDate).isAfter(element.date))
                          )
                            newOptions.push(element);
                        });
                        return newOptions;
                      }}
                      {...salesOrderProps}
                      id="auto-complete-3"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="salesOrderId"
                      disabled={editModel}
                      defaultValue={
                        salesOrderProps.options.find(
                          (v) => v.value === dto.salesOrderId
                        ) || {}
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, salesOrderId: v?.value });
                          else
                            setDTO({ ...dto, salesOrderId: Number(v?.value) });
                        } else {
                          setDTO({ ...dto, salesOrderId: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Work order Number"
                          required
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            width: "200px",
                            minHeight: "48.5px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <TextField
                      variant="standard"
                      label="Total Supply Amount "
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setDTO({ ...dto, [name]: value });
                      }}
                      onWheel={(event) => event.target.blur()}
                      style={{
                        minWidth: "200px",
                        width: "200px",
                        minHeight: "48.5px",
                      }}
                      name="totalSupplyAmount"
                      value={dto.totalSupplyAmount}
                      onBlur={handleInputChange}
                      disabled={addModel || editModel}
                      InputLabelProps={{
                        style: { color: "grey" },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                    ></TextField>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextField
                      variant="standard"
                      label="Total Installation Amount "
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setDTO({ ...dto, [name]: value });
                      }}
                      onWheel={(event) => event.target.blur()}
                      style={{
                        minWidth: "200px",
                        width: "200px",
                        minHeight: "48.5px",
                      }}
                      name="totalInstallationAmount"
                      value={dto.totalInstallationAmount}
                      onBlur={handleInputChange}
                      disabled={addModel || editModel}
                      InputLabelProps={{
                        style: { color: "grey" },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                    ></TextField>
                  </td>
                  <td>
                    <TextField
                      variant="standard"
                      label="Total Amount "
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setDTO({ ...dto, [name]: value });
                      }}
                      onWheel={(event) => event.target.blur()}
                      style={{
                        minWidth: "200px",
                        width: "200px",
                        minHeight: "48.5px",
                      }}
                      name="totalAmount"
                      value={dto.totalAmount}
                      onBlur={handleInputChange}
                      disabled={addModel || editModel}
                      InputLabelProps={{
                        style: { color: "grey" },
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                    ></TextField>
                  </td>
                </tr>
              </TableBody>
            </Table>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 10,
                marginBottom: 10,
                marginRight: 10,
              }}
            >
              <Button
                onClick={() => {
                  if (
                    dto.salesOrderId <= 0 ||
                    dto.categoryId <= 0 ||
                    dto.customerId <= 0
                  ) {
                    setAlertTitleText(
                      "Oops...",
                      `Select site, customer and order to download`
                    );
                    return;
                  }

                  let siteNames = sites.filter(
                    (v) => v.value === dto.customerId
                  );

                  let categoryNames = siteCategories.filter(
                    (v) => v.value === dto.categoryId
                  );

                  let filename = "";
                  APISALES.SalesInvoiceDownloadTemplate({
                    salesOrderId: dto.salesOrderId,
                    categoryId: dto.categoryId,
                    customerId: dto.customerId,
                    customerName: siteNames.length > 0 ? siteNames[0].name : "",
                    categoryName:
                      categoryNames.length > 0 ? categoryNames[0].name : "",
                  })
                    .then((res) => {
                      const contentType = res.headers.get("content-type");
                      if (contentType.startsWith("application/json")) {
                        return Promise.reject(res.json());
                      } else {
                        const header = res.headers.get("Content-Disposition");
                        if (header !== null) {
                          const parts = header.split(";");
                          filename = parts[1].split("=")[1].replaceAll('"', "");
                        } else {
                          filename = "RA Bill Invoice.xlsx";
                        }

                        return res.blob();
                      }
                    })
                    .then((blob) => {
                      if (blob !== null) {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        a.href = url;
                        a.download = filename;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                      }
                    })
                    .catch((m) => {
                      setAlertTitleText(
                        "Oops...",
                        `Something went wrong...${m.message}`
                      );
                    });
                }}
                variant="outlined"
                sx={{
                  borderColor: SPANISH_GREEN,
                }}
                style={{
                  //backgroundColor: SPANISH_GREEN,
                  textTransform: "capitalize",
                  fontSize: 14,
                  color: SPANISH_GREEN,
                  minWidth: "180px",
                  minHeight: "45px",
                  marginRight: 10,
                }}
              >
                {DOWNLOAD_TEMPLATE}
              </Button>
              <Button
                style={{
                  backgroundColor: SPANISH_GREEN,
                  textTransform: "capitalize",
                  fontSize: 14,
                  color: WHITE,
                  minWidth: "180px",
                  minHeight: "45px",
                  marginRight: 10,
                }}
                onClick={() => {
                  setOpenUploadModal(true);
                }}
              >
                {UPLOAD}
              </Button>
            </div>
          </div>

          {/* inside grey background */}
        </div>
        {openUploadModal && (
          <UploadModal
            style={{ align: "center" }}
            addModel={onAddDetail}
            setOpenUploadModal={setOpenUploadModal}
          ></UploadModal>
        )}
      </form>
    </div>
  );
}
