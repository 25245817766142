import {
  Autocomplete,
  createFilterOptions,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TableBody,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import {
  BLACK,
  SPANISH_GREEN,
  WHITE,
} from "../../../resources/constant/colors/theme";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { APIMASTER } from "../../../apihandler/master";
import "./modal.css";
import { setAlertTitleText } from "../../../component/modal/alertModal";
import {
  getRoles,
  globalFilter,
} from "../../../component/cellRenderers/renderers";
import {
  isValidEmail,
  isValidPassword,
  isValidPhone,
} from "../../../resources/constant/utilities/util";
import { Password } from "@mui/icons-material";

function Modal({
  setOpenModal,
  model,
  formTitle,
  updateModel,
  addModel,
  viewModel,
}) {
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    if (!isValidEmail(dto.email)) {
      setErrorEmail("Enter a valid Email");
      return;
    } else if (dto.phone?.length < 10) {
      setErrorPhone("Enter a valid Number");
      return;
    } else if (!isValidPassword(dto.password)) {
      if (dto.id <= 0 || dto.password?.length > 0) {
        setAlertTitleText(
          "Weak Password",
          "Password should contain at least 8 characters, one upper case, one lower case, one number, and one special char [Allowed @$!%*?&]"
        );
        return;
      }
    } else if (passwordConfirm !== dto.password) {
      if (dto.id <= 0 || dto.password?.length > 0) {
        setErrorConfirmPassword("Password does not match");
        return;
      }
    }
    //proceed to go further...
    if (dto.id <= 0)
      APIMASTER.AddUser(dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            if (data.reason !== "NotOK") {
              addModel(data.response);
              setAlertTitleText(
                "Success",
                "The record was inserted successfully"
              );
              setOpenModal(false);
            } else {
              setAlertTitleText("Oops...", "Password did not pass validation");
              setOpenModal(false);
            }
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    else
      APIMASTER.UpdateUser(dto.id, dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            if (data.reason !== "NotOK") {
              updateModel(data.response);
              setAlertTitleText(
                "Success",
                "The record was updated successfully"
              );
              setOpenModal(false);
            } else {
              setAlertTitleText("Oops...", "Password did not pass validation");
              setOpenModal(false);
            }
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
          }
        });
  }
  const [visible, setVisible] = useState(false);

  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  const [errorEmail, setErrorEmail] = useState("");
  const [errorPhone, setErrorPhone] = useState("");

  const roles = getRoles();

  const [dto, setDTO] = useState(model);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (isNaN(value) || name === "phone") setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    } else {
      setDTO({ ...dto, [name]: value });
    }
  };

  const roleProps = {
    options: roles,
    getOptionLabel: (option) => option.name,
  };

  return (
    <div className="modalBackgroundForUserManagement">
      {/* div for top right close button */}
      <form>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton
            style={{ width: "auto", align: "center", marginRight: 5 }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* top right close button div closed */}

        <div
          style={{
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <table style={{ width: "100%", margin: 10 }}>
            <TableBody>
              <tr>
                <td>
                  <Typography
                    fontSize={20}
                    fontWeight={600}
                    fontspacing={"normal"}
                    textTransform="capitalize"
                    align="left"
                  >
                    {formTitle}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    variant="standard"
                    label="Name"
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    name="name"
                    disabled={viewModel}
                    value={dto.name}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    {...roleProps}
                    id="auto-complete-1"
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    disabled={viewModel}
                    name="roleId"
                    defaultValue={
                      roleProps.options.find((v) => v.value === dto.roleId) ||
                      {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, roleId: v?.value });
                        else setDTO({ ...dto, roleId: Number(v?.value) });
                      } else {
                        setDTO({ ...dto, roleId: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Role"
                        disabled={viewModel}
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          width: "200px",
                          minHeight: "48.5px",
                        }}
                        InputLabelProps={{
                          style: { color: "grey" },
                        }}
                      />
                    )}
                  />
                </td>
                <td>
                  <TextField
                    variant="standard"
                    label="User Name"
                    disabled={viewModel}
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    name="userName"
                    value={dto.userName}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    variant="standard"
                    label="Email ID"
                    disabled={viewModel}
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    name="email"
                    required
                    value={dto.email}
                    helperText={errorEmail}
                    onChange={(e, v) => {
                      if (!isValidEmail(e.target.value)) {
                        setErrorEmail("Enter valid email");
                      } else {
                        setErrorEmail("");
                      }
                      handleInputChange(e, v);
                    }}
                    error={!!errorEmail}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
                <td>
                  <TextField
                    variant="standard"
                    label="Mobile No"
                    type="number"
                    disabled={viewModel}
                    required
                    onWheel={(event) => event.target.blur()}
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    name="phone"
                    helperText={errorPhone}
                    error={!!errorPhone}
                    value={dto.phone}
                    onChange={(e) => {
                      if (e.target.value.length !== 10) {
                        setErrorPhone("Enter a valid mobile number");
                      } else {
                        setErrorPhone("");
                      }
                      handleInputChange(e);
                    }}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
                <td>
                  {viewModel ? (
                    <div />
                  ) : (
                    <TextField
                      style={{
                        minWidth: "200px",
                        width: "200px",
                        minHeight: "48.5px",
                      }}
                      variant="standard"
                      label="Password"
                      name="password"
                      value={dto.password}
                      onChange={handleInputChange}
                      type={visible ? "text" : "password"}
                      InputLabelProps={{
                        style: { color: "grey" },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                setVisible?.(!visible);
                              }}
                              size="small"
                              aria-label="toggle password"
                              edge="end"
                              style={{ paddingRight: 15 }}
                            >
                              {visible === true ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  {viewModel ? (
                    <div />
                  ) : (
                    <TextField
                      style={{
                        minWidth: "200px",
                        width: "200px",
                        minHeight: "48.5px",
                      }}
                      variant="standard"
                      label="Confirm Password"
                      value={passwordConfirm}
                      helperText={errorConfirmPassword}
                      error={!!errorConfirmPassword}
                      onChange={(e) => {
                        setPasswordConfirm?.(e.target.value);
                        setErrorConfirmPassword?.("");
                      }}
                      InputLabelProps={{
                        style: { color: "grey" },
                      }}
                      type={visibleConfirm ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                setVisibleConfirm?.(!visibleConfirm);
                              }}
                              size="small"
                              aria-label="toggle password"
                              edge="end"
                              style={{ paddingRight: 15 }}
                            >
                              {visibleConfirm === true ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </td>
              </tr>
            </TableBody>
          </table>
        </div>

        {/* div for buttons */}
        {!viewModel ? (
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              marginTop: 10,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={onFormSubmit}
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
            >
              Save
            </Button>
          </div>
        ) : (
          <div />
        )}
      </form>
      {/* div for buttons closed */}

      {/* pop up div closed  */}
    </div>
  );
}

export default Modal;
