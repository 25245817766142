import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SideNav from "../../../../../component/layouts/sideNav";
import {
  AMOUNT,
  BUS_FARE,
  CANCEL,
  DAILY_WORK_ENTRY,
  DAILY_WORK_ENTRY_DETAILS,
  DAILY_WORK_ENTRY_ITEM,
  FOOD_FARE,
  GO,
  LABOUR_MANAGEMENT,
  OT_HOURS,
  OT_RAISE,
  QUANTITY,
  RATE_RS,
  REFERENCE_NO,
  SAVE,
  TOTAL,
  TYPE_OF_MAN_POWER,
  UNIT,
} from "../../../../../resources/constant/strings/string";

import { PATH_DAILY_WORK_ENTRY } from "../../../../../resources/constant/url/url";

import {
  Autocomplete,
  Button,
  TableBody,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import {
  BLACK,
  CHARLESTON_GREEN,
  LIGHT_GREY_BACKGROUND,
  PRUSSIAN_BLUE,
  SPANISH_GREEN,
  WHITE,
} from "../../../../../resources/constant/colors/theme";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { APILABOURMGMT } from "../../../../../apihandler/labourmgmt";
import { APIUTILS } from "../../../../../apihandler/utils";
import {
  getItemList,
  getLabourerTypes,
  getSites,
  getSupplierCategories,
  getSuppliers,
  getUoms,
  getWorkOrders,
  globalFilter,
} from "../../../../../component/cellRenderers/renderers";
import { setAlertTitleText } from "../../../../../component/modal/alertModal";
const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  color: SPANISH_GREEN,
  transform: "translate(-50%, -50%)",
  zIndex: 2,
};
export default function DailyWorkEntryDetails() {
  let { id } = useParams();
  const [dailyWorkEntryItems, setDailyWorkEntryItems] = useState([]);
  const [dailyWorkEntryLabour, setDailyWorkEntryLabour] = useState([]);
  const navigate = useNavigate();
  const [isFetched, setIsFetched] = useState(false);

  const location = useLocation();
  const isEdit = location.state?.isEdit;

  const fetchWorkOrder = () => {
    APILABOURMGMT.WorkOrder(dto.contractId)
      .then((resp) => resp.json())
      .then((x) => {
        if (x.statusCode !== 200) {
          setAlertTitleText("Oops...", x.message);
          return;
        }

        setDailyWorkEntryLabour((dailyWorkEntryLabour) =>
          dailyWorkEntryLabour.filter((m) => m?.id === -1)
        );
        setDailyWorkEntryItems((dailyWorkEntryItems) =>
          dailyWorkEntryItems.filter((m) => m?.id === -1)
        );

        const dataLabour = [];
        const dataItem = [];

        x.response.workOrderDetailItem.forEach((e, i) => {
          if (e.type === "L") {
            const reqdItem = labourerTypes.filter(
              (x) => x.value === e.labourerTypeId
            );

            dataLabour.push({
              id: i,
              contractDetailId: e.id,
              total: e.busFare + e.foodFare + e.rate,
              quantity: 0,
              busFare: e.busFare,
              foodFare: e.foodFare,
              itemCategoryId: 0,
              itemId: 0,
              labourerTypeId: e.labourerTypeId,
              typeOfManPower: reqdItem.length > 0 ? reqdItem[0].name : "",
              otHours: 0,
              otRate: e.otRate,
              rate: e.rate,
              type: "L",
            });
          } else {
            const reqdItem = items.filter((x) => x.id === e.itemId);
            let uomName = "";
            if (reqdItem.length > 0) {
              const reqdUom = uomList.filter(
                (x) => x.value === reqdItem[0].stockUOMId
              );
              if (reqdUom.length > 0) uomName = reqdUom[0].name;
            }
            dataItem.push({
              id: i,
              contractDetailId: e.id,
              total: 0,
              quantity: 0,
              busFare: 0,
              foodFare: 0,
              itemCategoryId: 0,
              itemId: e.itemId,
              itemName: reqdItem.length > 0 ? reqdItem[0].itemName : "",
              uomName: uomName,
              labourerTypeId: 0,
              otHours: 0,
              otRate: e.otRate,
              rate: e.rate,
              type: "I",
            });
          }
        });

        setDailyWorkEntryItems([...dailyWorkEntryItems.sort((a, b) => a.contractDetailId - b.contractDetailId).concat(dataItem)]);
        setDailyWorkEntryLabour([...dailyWorkEntryLabour.sort((a, b) => a.contractDetailId - b.contractDetailId).concat(dataLabour)]);

        setIsFetched(true);
      });
    console.log(
      "checkingIn",
      dailyWorkEntryLabour,
      "check",
      dailyWorkEntryItems
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (isNaN(value) || name === "workDone" || name === "nextDayWork")
        setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    }
  };

  const [dataReady, setDataReady] = useState(false);
  const initialValue = {
    id: 0,
    entryDate: dayjs().add(-1, "day"),
    billId: 0,
    referenceNo: "",
    siteId: 0,
    supplierId: 0,
    contractId: 0,
    workDone: "",
    nextDayWork: "",
  };
  const [dto, setDTO] = useState(initialValue);

  // below code is of daily work entry detail component
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    dto.dailyWorkEntryLabour = dailyWorkEntryLabour;
    dto.dailyWorkEntryItem = dailyWorkEntryItems;

    if (
      workOrderProps.options.filter(
        (x) =>
          x.otherdependentvalue === dto.siteId && x.value === dto.contractId
      ).length <= 0
    ) {
      setAlertTitleText("Oops...", `Work order is not valid for this site`);
      return;
    }

    //api call to save goes here
    if (id <= 0) {
      APILABOURMGMT.AddDailyWorkEntry(dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setAlertTitleText(
              "Success",
              "The record was inserted successfully"
            );
            navigate(PATH_DAILY_WORK_ENTRY + "/" + location.state.date);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    } else {
      APILABOURMGMT.UpdateDailyWorkEntry(id, dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setAlertTitleText("Success", "The record was updated successfully");
            navigate(PATH_DAILY_WORK_ENTRY + "/" + location.state.date);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    }
  }
  const supplierCategories = getSupplierCategories().filter((x) => {
    return x.dependentvalue === "L";
  });
  const suppliers = getSuppliers().filter((x) => {
    return (
      supplierCategories.filter((y) => y.value === x.dependentvalue).length > 0
    );
  });
  const sites = getSites();
  const workOrders = getWorkOrders();
  const uomList = getUoms();
  const items = getItemList();
  const labourerTypes = getLabourerTypes();

  const supplierProps = {
    options: suppliers,
    getOptionLabel: (option) => option?.name || "",
  };
  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };
  const workOrderProps = {
    options: workOrders,
    getOptionLabel: (option) => option?.name || "",
  };

  useEffect(() => {
    if (isEdit || id > 0) {
      APILABOURMGMT.DailyWorkEntry(id)
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.statusCode === 200) {
            APILABOURMGMT.WorkOrder(resp.response.contractId)
              .then((x) => x.json())
              .then((x) => {
                const wo = x.response;

                setDTO({
                  ...dto,
                  id: resp.response.id,
                  billId: resp.response.billId,
                  supplierId: wo.contractorId,
                  siteId: wo.locationId,
                  entryDate: resp.response.entryDate
                    .split("-")
                    .reverse()
                    .join("-"),
                  referenceNo: resp.response.referenceNo,
                  contractId: resp.response.contractId,
                  workDone: resp.response.workDone,
                  nextDayWork: resp.response.nextDayWork,
                });

                const itemWorkItems = resp.response.dailyWorkEntryItem;
                const labourWorkItems = resp.response.dailyWorkEntryLabour;

                itemWorkItems.forEach((y) => {
                  const item = items.filter((m) => m.id === y.itemId);
                  y.itemCategoryId = item.length > 0 ? item[0].categoryId : 0;
                  y.itemName = item.length > 0 ? item[0].itemName : 0;
                  y.rate = y.itemRate;
                  y.amount = Number(APIUTILS.Financial(y.rate * y.quantity));

                  if (item.length > 0) {
                    const reqdUom = uomList.filter(
                      (z) => z.value === item[0].stockUOMId
                    );
                    if (reqdUom.length > 0) y.uomName = reqdUom[0].name;
                  }
                });

                labourWorkItems.forEach((y, i) => {
                  const item = labourerTypes.filter(
                    (m) => m.value === y.labourId
                  );
                  y.typeOfManPower = item.length > 0 ? item[0].name : 0;
                  y.rate = y.ratePerDay;
                  y.otRate = 0;

                  wo.workOrderDetailItem.forEach((m) => {
                    if (m.type === "L") {
                      const reqdItem = labourerTypes.filter(
                        (x) =>
                          x.value === m.labourerTypeId && x.value === y.labourId
                      );
                      if (reqdItem.length > 0) {
                        y.otRate = m.otRate;
                      }
                    }
                  });

                  y.amount =
                    Number(APIUTILS.Financial(y.total * y.quantity)) +
                    Number(APIUTILS.Financial(y.otRate * y.otHours));
                });

                setDailyWorkEntryItems((r) => [].concat(itemWorkItems));
                setDailyWorkEntryLabour((r) => [].concat(labourWorkItems));

                setDataReady(true);
              });
          }
        });
    } else {
      setDataReady(true);
    }
  }, [isEdit, id]);

  return (
    <SideNav
      currentComponent={LABOUR_MANAGEMENT}
      subComponent={DAILY_WORK_ENTRY}
      subComponentPath={PATH_DAILY_WORK_ENTRY}
      detailComponent={DAILY_WORK_ENTRY_DETAILS}
    >
      {/* below code is for daily work entry detail component */}
      {dataReady ? (
        <div>
          <div>
            <form>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  marginBottom: 10,
                  marginRight: 10,
                }}
              >
                {(id <= 0 || isEdit) && (
                  <Button
                    style={{
                      backgroundColor: SPANISH_GREEN,
                      textTransform: "capitalize",
                      fontSize: 14,
                      color: WHITE,
                      minWidth: "180px",
                      minHeight: "45px",
                    }}
                    onClick={onFormSubmit}
                  >
                    {SAVE}
                  </Button>
                )}
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: SPANISH_GREEN,
                  }}
                  style={{
                    //backgroundColor: SPANISH_GREEN,
                    textTransform: "capitalize",
                    fontSize: 14,
                    color: SPANISH_GREEN,
                    minWidth: "180px",
                    minHeight: "45px",
                    marginRight: 10,
                  }}
                  onClick={() => {
                    navigate(PATH_DAILY_WORK_ENTRY + "/" + location.state.date);
                  }}
                >
                  {CANCEL}
                </Button>
              </div>
              <div
                style={{
                  backgroundColor: LIGHT_GREY_BACKGROUND,
                  borderRadius: "5px",
                  minWidth: "90%",
                  minHeight: "50px",
                  padding: 20,
                  marginBottom: 10,
                  borderStyle: "solid",
                  border: "1x",
                  borderColor: "#eaeaea",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      fontWeight: 600,
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                    }}
                  >
                    {REFERENCE_NO}
                  </Typography>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      fontWeight: 600,
                      fontFamily: "sans-serif",
                      fontSize: "16px",
                      //fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                    }}
                  >
                    {dto.referenceNo}
                  </Typography>
                </div>
                <table style={{ width: "100%" }}>
                  <TableBody>
                    <tr>
                      <td>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label="Date"
                            maxDate={dayjs()}
                            disabled={(id > 0 || isFetched) && dto.billId > 0}
                            style={{ minWidth: "272px", minHeight: "48.5px" }}
                            onChange={(newValue) => {
                              setDTO({
                                ...dto,
                                entryDate: newValue.format("YYYY-MM-DD"),
                              });
                            }}
                            inputFormat="DD-MM-YYYY"
                            value={dayjs(dto.entryDate)}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                style={{
                                  minWidth: "200px",
                                  minHeight: "48.5px",
                                  width: "200px",
                                }}
                                InputLabelProps={{
                                  style: { color: "grey", fontSize: 14 },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { fontSize: 14 },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </td>
                      <td>
                        <Autocomplete
                          filterOptions={globalFilter}
                          {...supplierProps}
                          disabled={id > 0}
                          id="auto-complete-1"
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          name="contractorName"
                          defaultValue={
                            supplierProps.options.find(
                              (v) => v.value === dto.supplierId
                            ) || {}
                          }
                          onChange={(e, v) => {
                            if (v) {
                              if (isNaN(v?.value))
                                setDTO({ ...dto, supplierId: v?.value });
                              else
                                setDTO({
                                  ...dto,
                                  supplierId: Number(v?.value),
                                });
                            } else {
                              setDTO({ ...dto, supplierId: v?.value });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Contractor Name"
                              required
                              variant="standard"
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 14 },
                              }}
                            />
                          )}
                        />
                      </td>

                      <td>
                        <Autocomplete
                          filterOptions={globalFilter}
                          {...siteProps}
                          disabled={id > 0}
                          id="auto-complete-2"
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          name="site"
                          defaultValue={
                            siteProps.options.find(
                              (v) => v.value === dto.siteId
                            ) || {}
                          }
                          onChange={(e, v) => {
                            if (v) {
                              if (isNaN(v?.value))
                                setDTO({ ...dto, siteId: v?.value });
                              else setDTO({ ...dto, siteId: Number(v?.value) });
                            } else {
                              setDTO({ ...dto, siteId: v?.value });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Site (To)"
                              required
                              variant="standard"
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 14 },
                              }}
                            />
                          )}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Autocomplete
                          {...workOrderProps}
                          id="auto-complete-4"
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          name="workOrderNo"
                          disabled={id > 0}
                          defaultValue={
                            workOrderProps.options.find(
                              (v) => v.value === dto.contractId
                            ) || {}
                          }
                          onChange={(e, v) => {
                            if (v) {
                              if (isNaN(v?.value))
                                setDTO({ ...dto, contractId: v?.value });
                              else
                                setDTO({
                                  ...dto,
                                  contractId: Number(v?.value),
                                });
                            } else {
                              setDTO({ ...dto, contractId: v?.value });
                            }
                          }}
                          filterOptions={(o, s) => {
                            let options = globalFilter(o, s);
                            let newOptions = [];
                            options.forEach((element) => {
                              if (
                                element.dependentvalue === dto.supplierId &&
                                element.otherdependentvalue === dto.siteId
                              ) {
                                if (
                                  element.date &&
                                  (dayjs(dto.entryDate).isAfter(element.date) ||
                                    dayjs(dto.entryDate).isSame(element.date))
                                )
                                  newOptions.push(element);
                              }
                            });
                            return newOptions;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Work Order No"
                              variant="standard"
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 14 },
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>
                        {!isFetched && id <= 0 ? (
                          <Button
                            variant="outlined"
                            style={{
                              backgroundColor: SPANISH_GREEN,
                              textTransform: "capitalize",
                              fontSize: 14,
                              color: WHITE,
                              minWidth: "180px",
                              minHeight: "45px",
                            }}
                            onClick={() => {
                              fetchWorkOrder();
                            }}
                          >
                            {GO}
                          </Button>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  </TableBody>
                </table>
              </div>
              {/* below div is for nature of work and todays work */}
              <div style={{ marginLeft: 10, marginBottom: 15 }}>
                <TextareaAutosize
                  variant="standard"
                  value={dto.nextDayWork}
                  onChange={handleInputChange}
                  style={{
                    outlineColor: BLACK,
                    borderTop: "10px",
                    borderRight: "10px",
                    borderLeft: "10px",
                    marginTop: 15,
                    fontSize: 12,
                    fontFamily: "sans-serif",
                    marginRight: 20,
                    minWidth: "260px",
                  }}
                  multiline="true"
                  name="nextDayWork"
                  maxRows={4}
                  minRows={2}
                  placeholder="Nature Of work"
                ></TextareaAutosize>
                <TextareaAutosize
                  variant="standard"
                  value={dto.workDone}
                  onChange={handleInputChange}
                  style={{
                    outlineColor: BLACK,
                    borderTop: "10px",
                    borderRight: "10px",
                    borderLeft: "10px",
                    marginTop: 15,
                    fontSize: 12,
                    fontFamily: "sans-serif",
                    minWidth: "260px",
                  }}
                  multiline="true"
                  name="workDone"
                  maxRows={4}
                  minRows={2}
                  placeholder="Todays Work"
                ></TextareaAutosize>
              </div>
              {/* Nature of work and todays work complete */}
            </form>
          </div>

          <div
            style={{
              backgroundColor: WHITE,
              borderRadius: "5px",
              minWidth: "90%",
              minHeight: "50px",
              marginBottom: 10,
              border: "0.1px solid #eaeaea",
              display: "flex",
              flexDirection: "column",
              // width: "100%",
              overflowX: "auto",
            }}
          >
            <div
            // style={{
            //   width: "1000px",
            // }}
            >
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <TableBody>
                  <tr
                    style={{
                      backgroundColor: LIGHT_GREY_BACKGROUND,
                      borderBottom: "0.001pt solid #f0eff4",
                      borderTop: "0.001pt solid #f0eff4",
                    }}
                  >
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {TYPE_OF_MAN_POWER}
                      </Typography>
                    </th>
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {RATE_RS}
                      </Typography>
                    </th>
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {BUS_FARE}
                      </Typography>
                    </th>
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {FOOD_FARE}
                      </Typography>
                    </th>
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {TOTAL}
                      </Typography>
                    </th>
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {QUANTITY}
                      </Typography>
                    </th>
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {OT_RAISE}
                      </Typography>
                    </th>
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {OT_HOURS}
                      </Typography>
                    </th>
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {AMOUNT}
                      </Typography>
                    </th>
                  </tr>
                  {dailyWorkEntryLabour?.map((item, index) => {
                    return (
                      <tr key={item.id}
                        style={{
                          borderBottom: "0.001pt solid #f0eff4",
                        }}
                      >
                        <td>
                          <Typography
                            color={CHARLESTON_GREEN}
                            style={{
                              flexGrow: 0,
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: 1.32,
                              letterSpacing: "normal",
                              marginLeft: 8,
                            }}
                          >
                            {dailyWorkEntryLabour[index].typeOfManPower}
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            color={CHARLESTON_GREEN}
                            style={{
                              flexGrow: 0,
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: 1.32,
                              letterSpacing: "normal",
                              marginLeft: 8,
                            }}
                          >
                            {dailyWorkEntryLabour[index].rate}
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            color={CHARLESTON_GREEN}
                            style={{
                              flexGrow: 0,
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: 1.32,
                              letterSpacing: "normal",
                              marginLeft: 8,
                            }}
                          >
                            {dailyWorkEntryLabour[index].busFare}
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            color={CHARLESTON_GREEN}
                            style={{
                              flexGrow: 0,
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: 1.32,
                              letterSpacing: "normal",
                              marginLeft: 8,
                            }}
                          >
                            {dailyWorkEntryLabour[index].foodFare}
                          </Typography>
                        </td>{" "}
                        <td>
                          <Typography
                            color={CHARLESTON_GREEN}
                            style={{
                              flexGrow: 0,
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: 1.32,
                              letterSpacing: "normal",
                              marginLeft: 8,
                            }}
                          >
                            {dailyWorkEntryLabour[index].total}
                          </Typography>
                        </td>
                        <td>
                          <input
                            onChange={(e) => {
                              const { name, value } = e.target;
                              let newArr = [...dailyWorkEntryLabour];
                              newArr[index][e.target.name] = (
                                e.target.value
                              );
                              setDailyWorkEntryLabour(newArr);
                            }}
                            onWheel={(event) => event.target.blur()}
                            name="quantity"
                            disabled={id > 0 && !isEdit}
                            id={`textfield-1-${item?.id}`}
                            value={dailyWorkEntryLabour[index].quantity}
                            onBlur={(e) => {
                              let newArr = [...dailyWorkEntryLabour];
                              newArr[index][e.target.name] = Number(
                                e.target.value
                              );
                              newArr[index].amount =
                                Number(
                                  APIUTILS.Financial(
                                    newArr[index].total * newArr[index].quantity
                                  )
                                ) +
                                Number(
                                  APIUTILS.Financial(
                                    newArr[index].otRate * newArr[index].otHours
                                  )
                                );
                              setDailyWorkEntryLabour(newArr);
                            }}
                            style={{
                              width: "100px",
                              border: "solid 1px #eaeaea",
                              borderRadius: "4px",
                              height: "20px",
                            }}
                          ></input>
                        </td>
                        <td>
                          <Typography
                            color={CHARLESTON_GREEN}
                            style={{
                              flexGrow: 0,
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: 1.32,
                              letterSpacing: "normal",
                              marginLeft: 8,
                            }}
                          >
                            {dailyWorkEntryLabour[index].otRate}
                          </Typography>
                        </td>
                        <td>
                          <input
                            onChange={(e) => {
                              const { name, value } = e.target;
                              let newArr = [...dailyWorkEntryLabour];
                              newArr[index][e.target.name] = (
                                e.target.value
                              );
                              setDailyWorkEntryLabour(newArr);
                            }}
                            onWheel={(event) => event.target.blur()}
                            id={`textfield-2-${item?.id}`}
                            name="otHours"
                            disabled={id > 0 && !isEdit}
                            value={dailyWorkEntryLabour[index].otHours}
                            onBlur={(e) => {
                              let newArr = [...dailyWorkEntryLabour];
                              newArr[index][e.target.name] = Number(
                                e.target.value
                              );
                              newArr[index].amount =
                                Number(
                                  APIUTILS.Financial(
                                    newArr[index].total * newArr[index].quantity
                                  )
                                ) +
                                Number(
                                  APIUTILS.Financial(
                                    newArr[index].otRate * e.target.value
                                  )
                                );
                              setDailyWorkEntryLabour(newArr);
                            }}
                            style={{
                              width: "100px",
                              border: "solid 1px #eaeaea",
                              borderRadius: "4px",
                              height: "20px",
                            }}
                          ></input>
                        </td>
                        <td>
                          <Typography
                            color={CHARLESTON_GREEN}
                            style={{
                              flexGrow: 0,
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: 1.32,
                              letterSpacing: "normal",
                              marginLeft: 8,
                            }}
                          >
                            {dailyWorkEntryLabour[index].amount}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
                </TableBody>
              </table>
            </div>
          </div>
          <div
            style={{
              backgroundColor: WHITE,
              borderRadius: "5px",
              minWidth: "90%",
              minHeight: "50px",
              marginBottom: 10,
              border: "0.1px solid #eaeaea",
              display: "flex",
              flexDirection: "column",
              // width: "100%",
              overflowX: "auto",
            }}
          >
            <div
            // style={{
            //   width: "1000px",
            // }}
            >
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                }}
              >
                <TableBody>
                  <tr
                    style={{
                      backgroundColor: LIGHT_GREY_BACKGROUND,
                      borderBottom: "0.001pt solid #f0eff4",
                      borderTop: "0.001pt solid #f0eff4",
                    }}
                  >
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {DAILY_WORK_ENTRY_ITEM}
                      </Typography>
                    </th>
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {UNIT}
                      </Typography>
                    </th>
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {RATE_RS}
                      </Typography>
                    </th>
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {QUANTITY}
                      </Typography>
                    </th>

                    <th></th>
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {AMOUNT}
                      </Typography>
                    </th>
                  </tr>
                  {dailyWorkEntryItems?.map((item, index) => {
                    return (
                      <tr key={item.id}
                        style={{
                          borderBottom: "0.001pt solid #f0eff4",
                        }}
                      >
                        <td>
                          <Typography
                            color={CHARLESTON_GREEN}
                            style={{
                              flexGrow: 0,
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: 1.32,
                              letterSpacing: "normal",
                              marginLeft: 8,
                            }}
                          >
                            {dailyWorkEntryItems[index].itemName}
                          </Typography>
                        </td>
                        <td>
                          <Typography
                            color={CHARLESTON_GREEN}
                            style={{
                              flexGrow: 0,
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: 1.32,
                              letterSpacing: "normal",
                              marginLeft: 8,
                            }}
                          >
                            {dailyWorkEntryItems[index].uomName}
                          </Typography>
                        </td>

                        <td>
                          <Typography
                            color={CHARLESTON_GREEN}
                            style={{
                              flexGrow: 0,
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: 1.32,
                              letterSpacing: "normal",
                              marginLeft: 8,
                            }}
                          >
                            {dailyWorkEntryItems[index].rate}
                          </Typography>
                        </td>
                        <td>
                          <input
                            onChange={(e) => {
                              const { name, value } = e.target;
                              let newArr = [...dailyWorkEntryItems];
                              newArr[index][e.target.name] = (
                                e.target.value
                              );
                              setDailyWorkEntryItems(newArr);
                            }}
                            onWheel={(event) => event.target.blur()}
                            id={`textfield-3-${index}`}
                            name="quantity"
                            disabled={id > 0 && !isEdit}
                            value={dailyWorkEntryItems[index].quantity}
                            onBlur={(e) => {
                              let newArr = [...dailyWorkEntryItems];
                              newArr[index][e.target.name] = Number(
                                e.target.value
                              );
                              newArr[index].amount = Number(
                                APIUTILS.Financial(
                                  newArr[index].rate * Number(e.target.value)
                                )
                              );
                              setDailyWorkEntryItems(newArr);
                            }}
                            style={{
                              width: "100px",
                              border: "solid 1px #eaeaea",
                              borderRadius: "4px",
                              height: "20px",
                            }}
                          ></input>
                        </td>
                        <td></td>
                        <td>
                          <Typography
                            color={CHARLESTON_GREEN}
                            style={{
                              flexGrow: 0,
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: 1.32,
                              letterSpacing: "normal",
                              marginLeft: 8,
                            }}
                          >
                            {item.amount}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
                </TableBody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </SideNav>
  );
}
