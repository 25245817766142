import { decode, encode } from "@msgpack/msgpack";
import { createFilterOptions } from "@mui/material";
import { Buffer } from "buffer";

const createKey = (id) => {
  return `urn:niveus:enirmaan:${id}`;
};

const getItemCached = (key) => {
  const data =
    localStorage.getItem(key) ||
    Buffer.from(encode([{ name: "", value: 0 }])).toString("base64");
  return decode(Buffer.from(data, "base64"));
};

const setItemCache = (key, value) => {
  try {
    if (value !== null)
      localStorage.setItem(
        key,
        Buffer.from(encode(value || [{ name: "", value: 0 }])).toString(
          "base64"
        )
      );
  } catch (e) {}
};

const globalFilter = createFilterOptions({
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  //matchFrom: 'start'
});

let closureStatuses = [
  { value: "P", name: "Pending" },
  { value: "SC", name: "Short Closed" },
  { value: "MC", name: "Manually Closed" },
  { value: "C", name: "Closed" },
  { value: "N", name: "New" },
];
const getClosureStatuses = () => {
  return closureStatuses;
};
const closureStatusRenderer = (params) => {
  let filteredValues = closureStatuses.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let supplierForTypes = [
  { value: "R", name: "Raw Material" },
  { value: "F", name: "Fuel" },
  { value: "L", name: "Labour" },
  { value: "I", name: "Insurance" },
  { value: "SC", name: "Service Center" },
  { value: "O", name: "Others" },
];

const getSupplierForTypes = () => {
  return supplierForTypes;
};
const supplierForTypesRenderer = (params) => {
  let filteredValues = supplierForTypes.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let bloodGroups = [
  { value: "A+", name: "A+" },
  { value: "A-", name: "A-" },
  { value: "B+", name: "B+" },
  { value: "B-", name: "B-" },
  { value: "O+", name: "O+" },
  { value: "O-", name: "O-" },
  { value: "AB+", name: "AB+" },
  { value: "AB-", name: "AB-" },
];

const getBloodGroups = () => {
  return bloodGroups;
};
const bloodGroupsRenderer = (params) => {
  let filteredValues = bloodGroups.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let genders = [
  { value: "M", name: "Male" },
  { value: "F", name: "Female" },
];
const getGenders = () => {
  return genders;
};
const genderRenderer = (params) => {
  let filteredValues = genders.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let employmentStatus = [
  { value: "C", name: "Current" },
  { value: "F", name: "Former" },
];
const getEmploymentStatuses = () => {
  return employmentStatus;
};
const employmentStatusRenderer = (params) => {
  let filteredValues = employmentStatus.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let paymentTypes = [
  { value: "M", name: "Monthly" },
  { value: "D", name: "Daily" },
  { value: "W", name: "Weekly" },
  { value: "P", name: "Production Basis" },
];
const getPaymentTypes = () => {
  return paymentTypes;
};
const paymentTypesRenderer = (params) => {
  let filteredValues = paymentTypes.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let labourerTypes = [];
const setLabourerTypes = (data) => {
  labourerTypes = data.sort((a, b) => a.value - b.value);
  setItemCache(createKey("labourerTypes"), companies);
};
const getLabourerTypes = () => {
  if (labourerTypes.length <= 0) {
    const data = getItemCached(createKey("labourerTypes"));
    labourerTypes = data;
  }
  return labourerTypes;
};

const labourerTypesCellRenderer = (params) => {
  if (labourerTypes.length <= 0) {
    labourerTypes = getItemCached(createKey("labourerTypes"));
  }
  let filteredValues = labourerTypes.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let companies = [];
const setCompanies = (data) => {
  companies = data.sort((a, b) => a.value - b.value);
  setItemCache(createKey("companies"), companies);
};
const getCompanies = () => {
  if (companies.length <= 0) {
    const data = getItemCached(createKey("companies"));
    companies = data;
  }
  return companies;
};

const companiesCellRenderer = (params) => {
  if (companies.length <= 0) {
    companies = getItemCached(createKey("companies"));
  }
  let filteredValues = companies.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let invoices = [];
const setInvoices = (data) => {
  invoices = data.sort((a, b) => a.value - b.value);
  setItemCache(createKey("invoices"), invoices);
};

const getInvoices = () => {
  if (invoices.length <= 0) {
    invoices = getItemCached(createKey("invoices"));
  }
  return invoices;
};
const invoicesCellRenderer = (params) => {
  if (invoices.length <= 0) {
    invoices = getItemCached(createKey("invoices"));
  }
  let filteredValues = invoices.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let subcontractorBilling = [];
const setSubContractorBilling = (data) => {
  subcontractorBilling = data.sort((a, b) => a.value - b.value);
  setItemCache(createKey("subcontractorBilling"), subcontractorBilling);
};

const getSubContractorBilling = () => {
  if (subcontractorBilling.length <= 0) {
    subcontractorBilling = getItemCached(createKey("subcontractorBilling"));
  }
  return subcontractorBilling;
};
const subContractorCellRenderer = (params) => {
  if (subcontractorBilling.length <= 0) {
    subcontractorBilling = getItemCached(createKey("subcontractorBilling"));
  }
  let filteredValues = subcontractorBilling.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let workOrders = [];
const setWorkOrders = (data) => {
  workOrders = data.sort((a, b) => a.value - b.value);
  setItemCache(createKey("workOrders"), workOrders);
};

const getWorkOrders = () => {
  if (workOrders.length <= 0) {
    workOrders = getItemCached(createKey("workOrders"));
  }
  return workOrders;
};
const workOrdersCellRenderer = (params) => {
  if (workOrders.length <= 0) {
    workOrders = getItemCached(createKey("workOrders"));
  }
  let filteredValues = workOrders.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let projectPhases = [];
const setProjectPhases = (data) => {
  projectPhases = data.sort((a, b) => a.value - b.value);
  setItemCache(createKey("indents"), indents);
};

const getProjectPhases = () => {
  if (projectPhases.length <= 0) {
    projectPhases = getItemCached(createKey("projectPhases"));
  }
  return projectPhases;
};
const phasesCellRenderer = (params) => {
  if (projectPhases.length <= 0) {
    projectPhases = getItemCached(createKey("projectPhases"));
  }
  let filteredValues = projectPhases.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let indents = [];
const setIndents = (data) => {
  indents = data.sort((a, b) => a.value - b.value);
  setItemCache(createKey("indents"), indents);
};

const getIndents = () => {
  if (indents.length <= 0) {
    indents = getItemCached(createKey("indents"));
  }
  return indents;
};
const indentsCellRenderer = (params) => {
  if (indents.length <= 0) {
    indents = getItemCached(createKey("indents"));
  }
  let filteredValues = indents.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let goodsReceiveds = [];
const setGoodsReceived = (data) => {
  goodsReceiveds = data.sort((a, b) => a.value - b.value);
  setItemCache(createKey("goodsReceiveds"), goodsReceiveds);
};
const getGoodsReceived = () => {
  if (goodsReceiveds.length <= 0) {
    goodsReceiveds = getItemCached(createKey("goodsReceiveds"));
  }
  return goodsReceiveds;
};
const goodsReceivedCellRenderer = (params) => {
  if (goodsReceiveds.length <= 0) {
    goodsReceiveds = getItemCached(createKey("goodsReceiveds"));
  }
  let filteredValues = goodsReceiveds.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let purchaseOrders = [];
const setPurchaseOrders = (data) => {
  purchaseOrders = data.sort((a, b) => a.value - b.value);
  setItemCache(createKey("purchaseOrders"), purchaseOrders);
};

const getPurchaseOrders = () => {
  if (purchaseOrders.length <= 0) {
    purchaseOrders = getItemCached(createKey("purchaseOrders"));
  }
  return purchaseOrders;
};

const purchaseOrdersCellRenderer = (params) => {
  if (purchaseOrders.length <= 0) {
    purchaseOrders = getItemCached(createKey("purchaseOrders"));
  }
  let filteredValues = purchaseOrders.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let orders = [];
const setOrders = (data) => {
  orders = data.sort((a, b) => a.value - b.value);
  setItemCache(createKey("orders"), orders);
};

const getOrders = () => {
  if (orders.length <= 0) {
    const data = getItemCached(createKey("orders")) || [];
    orders = data;
  }
  return orders;
};

const ordersCellRenderer = (params) => {
  if (orders.length <= 0) {
    orders = getItemCached(createKey("orders"));
  }
  let filteredValues = orders.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let itemList = [];
const setItemList = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  itemList = data;
  try {
    setItemCache(createKey("itemList"), data);
  } catch (e) {}
};
const getItemList = () => {
  if (itemList.length <= 0) {
    itemList = getItemCached(createKey("itemList"));
  }

  return [
    ...new Map(itemList.map((item) => [item["itemName"], item])).values(),
  ];
};

let items = [];
const setItems = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  items = data;
  setItemCache(createKey("items"), data);
};

const getItems = () => {
  if (items.length <= 0) {
    items = getItemCached(createKey("items"));
  }
  return [...new Map(items.map((item) => [item["name"], item])).values()];
};

const itemCellRenderer = (params) => {
  if (items.length <= 0) {
    items = getItemCached(createKey("items"));
  }
  let filteredValues = items.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let itemCategories = [];
const setItemCategories = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  itemCategories = data;
  setItemCache(createKey("itemCategories"), data);
};

const getItemCategories = () => {
  if (itemCategories.length <= 0) {
    itemCategories = getItemCached(createKey("itemCategories"));
  }
  return itemCategories;
};
const itemCategoryCellRenderer = (params) => {
  if (itemCategories.length <= 0) {
    itemCategories = getItemCached(createKey("itemCategories"));
  }

  let filteredValues = itemCategories.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let taxes = [];
const taxCellRenderer = (params) => {
  if (taxes.length <= 0) {
    taxes = getItemCached(createKey("taxes"));
  }
  let filteredValues = taxes.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

const setTaxes = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  taxes = data;
  setItemCache(createKey("taxes"), data);
};
const getTaxes = () => {
  if (taxes.length <= 0) {
    taxes = getItemCached(createKey("taxes"));
  }
  return taxes;
};

let states = [];
const stateCellRenderer = (params) => {
  if (states.length <= 0) {
    states = getItemCached(createKey("states"));
  }
  let filteredValues = states.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

const setStates = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  states = data;
  setItemCache(createKey("states"), data);
};
const getStates = () => {
  if (states.length <= 0) {
    states = getItemCached(createKey("states"));
  }
  return states;
};

let countries = [];
const countryCellRenderer = (params) => {
  if (countries.length <= 0) {
    countries = getItemCached(createKey("countries"));
  }
  let filteredValues = countries.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

const setCountries = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  countries = data;
  setItemCache(createKey("countries"), data);
};
const getCountries = () => {
  if (countries.length <= 0) {
    countries = getItemCached(createKey("countries"));
  }
  return countries;
};

let taxCategory = [];
const taxCategoryCellRenderer = (params) => {
  if (taxCategory.length <= 0) {
    taxCategory = getItemCached(createKey("taxCategory"));
  }
  let filteredValues = taxCategory.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

const setTaxCategories = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  taxCategory = data;
  setItemCache(createKey("taxCategory"), data);
};
const getTaxCategories = (data) => {
  if (taxCategory.length <= 0) {
    taxCategory = getItemCached(createKey("taxCategory"));
  }
  return taxCategory;
};

let supplierCategory = [];
const supplierCategoryCellRenderer = (params) => {
  if (supplierCategory.length <= 0) {
    supplierCategory = getItemCached(createKey("supplierCategory"));
  }
  let filteredValues = supplierCategory.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};
const setSupplierCategories = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  supplierCategory = data;
  setItemCache(createKey("supplierCategory"), data);
};
const getSupplierCategories = () => {
  if (supplierCategory.length <= 0) {
    supplierCategory = getItemCached(createKey("supplierCategory"));
  }
  return supplierCategory;
};

let customerCategory = [];
const customerCategoryCellRenderer = (params) => {
  if (customerCategory.length <= 0) {
    customerCategory = getItemCached(createKey("customerCategory"));
  }
  let filteredValues = customerCategory.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

const setSiteCategories = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  customerCategory = data;
  setItemCache(createKey("customerCategory"), data);
};

const getSiteCategories = () => {
  if (customerCategory.length <= 0) {
    customerCategory = getItemCached(createKey("customerCategory"));
  }
  return customerCategory;
};

let accountCategory = [];
const accountCategoryCellRenderer = (params) => {
  let filteredValues = accountCategory.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

const setAccountCategories = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  accountCategory = data;
};

const getAccountCategories = () => {
  return accountCategory;
};

let uoms = [];
const uomCellRenderer = (params) => {
  if (uoms.length <= 0) {
    uoms = getItemCached(createKey("uoms"));
  }
  let filteredValues = uoms.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

const setUoms = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  uoms = data;
  setItemCache(createKey("uoms"), data);
};

const getUoms = () => {
  if (uoms.length <= 0) {
    uoms = getItemCached(createKey("uoms"));
  }
  return uoms;
};

let uomTypes = [];

const uomTypesCellRenderer = (params) => {
  if (uomTypes.length <= 0) {
    uomTypes = getItemCached(createKey("uomTypes"));
  }
  let filteredValues = uomTypes.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

const setUomTypes = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  uomTypes = data;
  setItemCache(createKey("uomTypes"), data);
};

const getUomTypes = () => {
  if (uomTypes.length <= 0) {
    uomTypes = getItemCached(createKey("uomTypes"));
  }
  return uomTypes;
};

let itemTypes = [];
const setItemTypes = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  itemTypes = data;
  setItemCache(createKey("itemTypes"), data);
};
const getItemTypes = () => {
  if (itemTypes.length <= 0) {
    itemTypes = getItemCached(createKey("itemTypes"));
  }
  return itemTypes;
};
const itemTypesCellRenderer = (params) => {
  if (itemTypes.length <= 0) {
    itemTypes = getItemCached(createKey("itemTypes"));
  }
  let filteredValues = itemTypes.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};
let roles = [];
const setRoles = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  roles = data;
  setItemCache(createKey("roles"), data);
};
const getRoles = () => {
  if (roles.length <= 0) {
    roles = getItemCached(createKey("roles"));
  }
  return roles;
};
const rolesCellRenderer = (params) => {
  if (roles.length <= 0) {
    roles = getItemCached(createKey("roles"));
  }
  let filteredValues = roles.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};
let sites = [];
const setSites = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  sites = data;
  setItemCache(createKey("sites"), data);
};
const getSites = () => {
  if (sites.length <= 0) {
    sites = getItemCached(createKey("sites"));
  }
  return sites;
};
const siteCellRenderer = (params) => {
  if (sites.length <= 0) {
    sites = getItemCached(createKey("sites"));
  }
  let filteredValues = sites.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let suppliers = [];
const setSuppliers = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  suppliers = data;
  setItemCache(createKey("suppliers"), data);
};
const getSuppliers = () => {
  if (suppliers.length <= 0) {
    suppliers = getItemCached(createKey("suppliers"));
  }
  return suppliers;
};
const supplierCellRenderer = (params) => {
  if (suppliers.length <= 0) {
    suppliers = getItemCached(createKey("suppliers"));
  }
  let filteredValues = suppliers.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let users = [];
const setUsers = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  users = data;
  setItemCache(createKey("users"), data);
};
const getUsers = () => {
  if (users.length <= 0) {
    users = getItemCached(createKey("users"));
  }
  return users;
};
const userCellRenderer = (params) => {
  if (users.length <= 0) {
    users = getItemCached(createKey("users"));
  }
  let filteredValues = users.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let employees = [];
const setEmployees = (data) => {
  const unique = [
    ...new Map(data.map((item) => [item["name"], item])).values(),
  ];
  const dataUnique = unique.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  employees = dataUnique;
  setItemCache(createKey("employees"), employees);
};
const getEmployees = () => {
  if (employees.length <= 0) {
    employees = getItemCached(createKey("employees"));
  }
  return employees;
};
const employeeCellRenderer = (params) => {
  if (employees.length <= 0) {
    employees = getItemCached(createKey("employees"));
  }
  let filteredValues = employees.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let projectManagers = [];
const setProjectManagers = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  projectManagers = data;
  setItemCache(createKey("projectManagers"), data);
};
const getProjectManagers = () => {
  if (projectManagers.length <= 0) {
    projectManagers = getItemCached(createKey("projectManagers"));
  }
  return projectManagers;
};
const projectManagerCellRenderer = (params) => {
  if (projectManagers.length <= 0) {
    projectManagers = getItemCached(createKey("projectManagers"));
  }
  let filteredValues = projectManagers.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let designations = [];
const setDesignations = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  designations = data;
  setItemCache(createKey("designations"), data);
};
const getDesignations = () => {
  if (designations.length <= 0) {
    designations = getItemCached(createKey("designations"));
  }
  return designations;
};
const designationsCellRenderer = (params) => {
  if (designations.length <= 0) {
    designations = getItemCached(createKey("designations"));
  }
  let filteredValues = designations.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let employeeTypes = [];
const setEmployeeTypes = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  employeeTypes = data;
  setItemCache(createKey("employeeTypes"), data);
};
const getEmployeeTypes = () => {
  if (employeeTypes.length <= 0) {
    employeeTypes = getItemCached(createKey("employeeTypes"));
  }
  return employeeTypes;
};
const employeeTypesCellRenderer = (params) => {
  if (employeeTypes.length <= 0) {
    employeeTypes = getItemCached(createKey("employeeTypes"));
  }
  let filteredValues = employeeTypes.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

let maritalStatuses = [];
const setMaritalStatuses = (data) => {
  data = data.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  maritalStatuses = data;
  setItemCache(createKey("maritalStatuses"), data);
};
const getMaritalStatuses = () => {
  if (maritalStatuses.length <= 0) {
    maritalStatuses = getItemCached(createKey("maritalStatuses"));
  }
  return maritalStatuses;
};
const maritalStatusCellRenderer = (params) => {
  if (maritalStatuses.length <= 0) {
    maritalStatuses = getItemCached(createKey("maritalStatuses"));
  }
  let filteredValues = maritalStatuses.filter((v, i) => {
    return v.value === params.value;
  });
  return filteredValues.length > 0 ? filteredValues[0].name : "";
};

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export {
  itemCategoryCellRenderer,
  itemTypesCellRenderer,
  uomTypesCellRenderer,
  uomCellRenderer,
  stateCellRenderer,
  accountCategoryCellRenderer,
  taxCategoryCellRenderer,
  supplierCategoryCellRenderer,
  customerCategoryCellRenderer,
  rolesCellRenderer,
  countryCellRenderer,
  siteCellRenderer,
  supplierCellRenderer,
  itemCellRenderer,
  closureStatusRenderer,
  ordersCellRenderer,
  projectManagerCellRenderer,
  userCellRenderer,
  employeeCellRenderer,
  employeeTypesCellRenderer,
  designationsCellRenderer,
  maritalStatusCellRenderer,
  employmentStatusRenderer,
  genderRenderer,
  bloodGroupsRenderer,
  paymentTypesRenderer,
  companiesCellRenderer,
  indentsCellRenderer,
  taxCellRenderer,
  purchaseOrdersCellRenderer,
  goodsReceivedCellRenderer,
  workOrdersCellRenderer,
  subContractorCellRenderer,
  labourerTypesCellRenderer,
  phasesCellRenderer,
  supplierForTypesRenderer,
  invoicesCellRenderer,
  setInvoices,
  setSubContractorBilling,
  setWorkOrders,
  setTaxes,
  setIndents,
  setDesignations,
  setMaritalStatuses,
  setEmployeeTypes,
  setEmployees,
  setUsers,
  setOrders,
  setItemTypes,
  setItemCategories,
  setSiteCategories,
  setStates,
  setTaxCategories,
  setSupplierCategories,
  setUomTypes,
  setUoms,
  setCountries,
  setRoles,
  setItems,
  setSites,
  setSuppliers,
  setAccountCategories,
  setItemList,
  setProjectManagers,
  setCompanies,
  setPurchaseOrders,
  setGoodsReceived,
  setLabourerTypes,
  setProjectPhases,
  getEmployeeTypes,
  getDesignations,
  getMaritalStatuses,
  getEmployees,
  getUsers,
  getOrders,
  getClosureStatuses,
  getItemList,
  getSites,
  getSuppliers,
  getItemCategories,
  getItems,
  getItemTypes,
  getUomTypes,
  getUoms,
  getCountries,
  getStates,
  getSiteCategories,
  getAccountCategories,
  getSupplierCategories,
  getTaxCategories,
  getRoles,
  getProjectManagers,
  getGenders,
  getBloodGroups,
  getEmploymentStatuses,
  getPaymentTypes,
  getCompanies,
  getIndents,
  getTaxes,
  getPurchaseOrders,
  getGoodsReceived,
  getWorkOrders,
  getSubContractorBilling,
  getLabourerTypes,
  getProjectPhases,
  getSupplierForTypes,
  getInvoices,
  globalFilter,
};
