import { Autocomplete, Button, TableBody, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { APIMASTER } from "../../../../apihandler/master";
import { APIREPORTS } from "../../../../apihandler/reports";
import { APISALES } from "../../../../apihandler/sales";
import {
  getItemCategories,
  getItems,
  getOrders,
  getSites,
  getSupplierCategories,
  getSuppliers,
  globalFilter,
  itemCategoryCellRenderer,
  itemCellRenderer,
  setItemCategories,
  setItems,
  setOrders,
  setSites,
  setSupplierCategories,
  setSuppliers,
  siteCellRenderer,
  supplierCellRenderer,
} from "../../../../component/cellRenderers/renderers";
import SideNav from "../../../../component/layouts/sideNav";
import { setAlertTitleText } from "../../../../component/modal/alertModal";
import {
  LIGHT_GREY_BACKGROUND,
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";
import {
  EXPORT,
  GENERATE,
  LABOUR_MANAGEMENT_REPORT,
  SUB_CONTRACTOR_BILLING_REPORT,
} from "../../../../resources/constant/strings/string";
import { PATH_SUB_CONTRACTOR_BILLING_REPORT } from "../../../../resources/constant/url/url";
import { APIUTILS } from "../../../../apihandler/utils";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  color: SPANISH_GREEN,
  transform: "translate(-50%, -50%)",
  zIndex: 2,
};

export default function SubContractorBillingReport() {
  const [itemDetails, setItemDetails] = useState([]);

  const [dataReady, setDataReady] = useState(true);

  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    console.log("dto", dto);
    setDataReady(false);
    APIREPORTS.SubContractorBillingReport(dto)
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode !== 200) {
          setDataReady(true);
          setAlertTitleText("Oops..", `An error occurred ${data.message}`);
          return;
        }

        try {
          setDataReady(true);
          gridRef1.current.api.setRowData([]);
        } catch (e) {}
        data.response.itemDetails.forEach((x) => {
          const reqdItem = items.filter((y) => y.value === x.itemId);
          x.itemCategoryId =
            reqdItem.length > 0 ? reqdItem[0].dependentvalue : null;
          x.item = reqdItem.length > 0 ? reqdItem[0].name : null;

          const reqdItemCategory = itemCategories.filter(
            (y) => y.value === x.itemCategoryId
          );
          x.itemCategory =
            reqdItemCategory.length > 0 ? reqdItemCategory[0].name : null;

          x.difference = x.subContractorBilled - x.customerBilled;
          if (x.salesOrderRate) {
            x.amount = x.salesOrderRate * x.difference;
          } else {
            x.salesOrderRate = 0;
            x.amount = 0;
          }
        });

        setItemDetails((r) => [].concat(data.response.itemDetails));
        gridRef1.current.api.applyTransaction({
          add: data.response.itemDetails,
        });

        setTimeout(() => {
          let pinnedBottomData = generatePinnedBottomData();
          gridRef1.current.api.setPinnedBottomRowData([pinnedBottomData]);
        }, 2000);
      })
      .catch((e) => {
        setAlertTitleText("Oops", "An error occurred");
        setDataReady(true);
        return;
      });
  }

  const generatePinnedBottomData = () => {
    // generate a row-data with null values
    let result = {};

    gridRef1.current.columnApi.getAllGridColumns().forEach((item) => {
      result[item.colId] = null;
    });
    return calculatePinnedBottomData(result);
  };

  const calculatePinnedBottomData = (target) => {
    //console.log(target);
    //**list of columns fo aggregation**
    let columnsWithAggregation = ["amount"];
    columnsWithAggregation.forEach((element) => {
      gridRef1.current.api.forEachNodeAfterFilter((rowNode) => {
        if (element === "id") {
          target[element] = "Grand Total";
          return;
        }
        if (rowNode.data[element] || rowNode.data[element] === 0)
          target[element] += Number.parseFloat(
            APIUTILS.Financial(rowNode.data[element])
          );
      });
      if (target[element]) {
        target[element] = `${APIUTILS.Financial(target[element])}`;
      }
    });
    return target;
  };

  const [dto, setDTO] = useState({
    id: 0,
    siteId: 0,
    tillDate: dayjs().endOf("month").format("YYYY-MM-DD"),
    salesOrderId: 0,
    salesOrderNumber: "",
    site: "",
  });

  // below code is for grid - 1
  const columnDefs1 = [
    {
      flex: 1.5,
      headerName: "Item Category",
      field: "itemCategoryId",
      valueGetter: (params) => {
        return itemCategoryCellRenderer({
          value: params.data.itemCategoryId,
        });
      },
      autoHeight: true,
      wrapText: true,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },

    {
      flex: 1.5,
      headerName: "Item Name",
      field: "itemId",
      valueGetter: (params) => {
        return itemCellRenderer({ value: params.data.itemId });
      },
      autoHeight: true,
      wrapText: true,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Sub Contractor Billed",
      field: "subContractorBilled",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Customer Billed",
      field: "customerBilled",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Difference",
      field: "difference",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Sales Order Rate",
      field: "salesOrderRate",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Amount",
      field: "amount",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
  ];
  const gridRef1 = useRef();
  const defaultColDef1 = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };
  const onGridReady1 = (params) => {
    gridRef1.current.api.setRowData([]);
    const p1 = APIMASTER.SupplierCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSupplierCategories(resp.response);
      });

    const p3 = APIMASTER.SupplierOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSuppliers(resp.response);
      });

    const p4 = APIMASTER.SiteOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSites(resp.response);
      });

    const p5 = APIMASTER.ItemCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setItemCategories(resp.response);
      });

    const p6 = APIMASTER.ItemOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setItems(resp.response);
      });

    const p7 = APISALES.SalesOrderOptions("all")
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setOrders(resp.response);
      });

    Promise.all([p1, p3, p4, p5, p6, p7]).then(() => {
      try {
        setDataReady(true);
        setIsGridReady(true);
        gridRef1.current.api.setRowData([]);
      } catch (e) {}
    });
  };
  const [selectedRowId1, setSelectedRowId1] = useState(0);
  const [isGridReady, setIsGridReady] = useState(false);
  const sites = getSites();
  const orders = getOrders();
  const orderProps = {
    options: orders,
    getOptionLabel: (option) => option?.name || "",
  };
  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };
  const itemCategories = getItemCategories();
  const items = getItems();

  const supplierCategories = getSupplierCategories().filter((x) => {
    return x.dependentvalue === "L";
  });
  const suppliers = getSuppliers().filter((x) => {
    return (
      supplierCategories.filter((y) => y.value === x.dependentvalue).length > 0
    );
  });

  useEffect(() => {
    if (isGridReady) {
      supplierCategories
        .filter((x) => false)
        .concat(
          getSupplierCategories().filter((x) => {
            return x.dependentvalue === "L";
          })
        );
      suppliers
        .filter((x) => false)
        .concat(
          getSuppliers().filter((x) => {
            return (
              supplierCategories.filter((y) => y.value === x.dependentvalue)
                .length > 0
            );
          })
        );
      sites.filter((x) => false).concat(getSites());
      itemCategories.filter((x) => false).concat(getItemCategories());
      items.filter((x) => false).concat(getItems());
      orders.filter((x) => false).concat(getOrders());
    }
  }, [isGridReady]);

  const onRowSelectionChanged1 = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId1(event.api.getSelectedRows()[0].id);
    else setSelectedRowId1(0);
  };
  // grid 1 code ends

  const supplierProps = {
    options: suppliers,
    getOptionLabel: (option) => option?.name || "",
  };
  return (
    <SideNav
      currentComponent={LABOUR_MANAGEMENT_REPORT}
      subComponent={SUB_CONTRACTOR_BILLING_REPORT}
      subComponentPath={PATH_SUB_CONTRACTOR_BILLING_REPORT}
      detailComponent=""
    >
      <div>
        <div style={style}>
          <ThreeCircles
            visible={!dataReady}
            height="256"
            width="256"
            outerCircleColor={SPANISH_GREEN}
            innerCircleColor="#eeeeee"
            middleCircleColor={SPANISH_GREEN}
            ariaLabel="eNirmaan-loading"
            color={SPANISH_GREEN}
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
        <form>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={onFormSubmit}
            >
              {GENERATE}
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                //backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={() => {
                let filename = "";
                dto.itemDetails = itemDetails;
                setDataReady(false);
                APIREPORTS.SubContractorBillingReportExport(dto)
                  .then((res) => {
                    setDataReady(true);

                    const contentType = res.headers.get("content-type");
                    if (contentType.startsWith("application/json")) {
                      return Promise.reject(res.json());
                    } else {
                      const header = res.headers.get("Content-Disposition");
                      if (header !== null) {
                        const parts = header.split(";");
                        filename = parts[1].split("=")[1].replaceAll('"', "");
                      } else {
                        filename = "Sub Contractor Billing Export Report.xlsx";
                      }

                      return res.blob();
                    }
                  })
                  .then((blob) => {
                    if (blob !== null) {
                      let url = window.URL.createObjectURL(blob);
                      let a = document.createElement("a");
                      a.href = url;
                      a.download = filename;
                      document.body.appendChild(a);
                      a.click();
                      a.remove();
                    }
                  })
                  .catch((m) => {
                    setAlertTitleText(
                      "Oops...",
                      `Something went wrong...${m.message}`
                    );
                  });
              }}
            >
              {EXPORT}
            </Button>
          </div>
          {/* button div ends */}
          {/* below code is for above container */}
          <div
            style={{
              backgroundColor: LIGHT_GREY_BACKGROUND,
              borderRadius: "5px",
              minWidth: "90%",
              minHeight: "50px",
              padding: 20,
              marginBottom: 10,
              borderStyle: "solid",
              border: "1x",
              borderColor: "#eaeaea",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <table style={{ width: "100%" }}>
              <TableBody>
                <tr>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Till Date"
                        inputFormat="DD-MM-YYYY"
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dto.tillDate}
                        onChange={(newValue) => {
                          setDTO({
                            ...dto,
                            tillDate: newValue.format("YYYY-MM-DD"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                  <td>
                    <Autocomplete
                      {...siteProps}
                      id="auto-complete-1"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="siteId"
                      defaultValue={
                        siteProps.options.find((v) => v.value === dto.siteId) ||
                        {}
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, siteId: v?.value, site: v?.name });
                          else
                            setDTO({
                              ...dto,
                              siteId: Number(v?.value),
                              site: v?.name,
                            });
                        } else {
                          setDTO({ ...dto, siteId: v?.value, site: v?.name });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Site"
                          variant="standard"
                          required
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Autocomplete
                      {...orderProps}
                      id="auto-complete-2"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="salesOrderId"
                      filterOptions={(o, s) => {
                        let options = globalFilter(o, s);
                        let newOptions = [];
                        options.forEach((element) => {
                          if (element.dependentvalue === dto.siteId)
                            newOptions.push(element);
                        });
                        return newOptions;
                      }}
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({
                              ...dto,
                              salesOrderId: v?.value,
                              salesOrderNumber: v?.name,
                            });
                          else
                            setDTO({
                              ...dto,
                              salesOrderId: Number(v?.value),
                              salesOrderNumber: v?.name,
                            });
                        } else {
                          setDTO({
                            ...dto,
                            salesOrderId: v?.value,
                            salesOrderNumber: v?.name,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sales Order No"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                </tr>
              </TableBody>
            </table>
          </div>
        </form>
      </div>

      {/* below code is for ag-grid -1 */}
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "570px" }}>
          <AgGridReact
            copyGroupHeadersToClipboard={true}
            enableRangeSelection={true}
            columnDefs={columnDefs1}
            ref={gridRef1}
            defaultColDef={defaultColDef1}
            onGridReady={onGridReady1}
            paginationAutoPageSize={true} pagination={true}
            onSelectionChanged={onRowSelectionChanged1}
          ></AgGridReact>
        </div>
      </div>
    </SideNav>
  );
}
