import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, IconButton, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import React, { useEffect, useRef, useState } from "react";
import SideNav from "../../../../component/layouts/sideNav";
import {
  ADD,
  DELETE,
  GO,
  INDENT,
  MATERIAL_MANAGEMENT,
} from "../../../../resources/constant/strings/string";

import {
  closureStatusRenderer,
  getItemList,
  getItems,
  getProjectManagers,
  getSites,
  getUoms,
  projectManagerCellRenderer,
  setItemCategories,
  setItemList,
  setItems,
  setProjectManagers,
  setSites,
  setUoms,
  siteCellRenderer,
} from "../../../../component/cellRenderers/renderers";
import {
  LIGHT_GREY_BACKGROUND,
  PRUSSIAN_BLUE,
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { APILOGIN } from "../../../../apihandler/login";
import { APIMASTER } from "../../../../apihandler/master";
import { APIMATERIALMGMT } from "../../../../apihandler/materialmgmt";
import {
  setAlertConfirmDeletion,
  setAlertRadioConfirmation,
  setAlertTitleText,
} from "../../../../component/modal/alertModal";
import {
  PATH_INDENT,
  PATH_INDENT_DETAILS,
  PATH_LOGIN,
} from "../../../../resources/constant/url/url";

export default function Indent() {
  let { date } = useParams();
  const [dateValue, setDateValue] = React.useState(null);
  const [toDateValue, setToDateValue] = React.useState(dayjs().endOf("month"));

  const navigate = useNavigate();

  const gridRef = useRef();

  useEffect(() => {
    if (!APILOGIN.GetIsLoggedIn()) navigate(PATH_LOGIN);
  }, []);

  const [selectedRowId, setSelectedRowId] = useState(0);

  const deleteModel = () => {
    if (selectedRowId <= 0) {
      setAlertTitleText("Oops...", `Please select a row...`);
      return;
    }

    setAlertConfirmDeletion(
      "Confirmation needed",
      "Do you confirm on proceeding to delete this record?"
    ).then((consent) => {
      if (!consent.isConfirmed) return;

      console.log("delete the selected id: ", selectedRowId);
      APIMATERIALMGMT.DeleteIndent(selectedRowId)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.statusCode === 204) {
            const selectedData = gridRef.current.api.getSelectedRows();
            gridRef.current.api.applyTransaction({ remove: selectedData });
            setAlertTitleText("Success", "The record was deleted successfully");
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
          }
        });
    });
  };

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId(event.api.getSelectedRows()[0].id);
    else setSelectedRowId(0);
  };

  const updateModel = (model) => {
    gridRef.current.api.applyTransaction({ update: [model] });
  };

  const addModel = (model) => {
    gridRef.current.api.applyTransaction({ add: [model], addIndex: 0 });
  };

  const handleEditData = (oldData) => {
    console.log(oldData);
  };
  const items = getItems();
  const itemList = getItemList();
  const uomList = getUoms();
  const pms = getProjectManagers();

  const projectManagerProps = {
    options: pms,
    getOptionLabel: (option) => option?.name || "",
  };

  const sites = getSites();

  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };
  const columnDefs = [
    {
      flex: 1.5,
      headerName: "Indent No",
      field: "indentNo",
      checkboxSelection: false,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      flex: 0.8,
      headerName: "Date",
      field: "date",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 1.5,
      headerName: "Project Manager",
      field: "projectManager",
      autoHeight: true,
      wrapText: true,
      valueGetter: (params) => {
        return projectManagerCellRenderer({
          value: params.data.projectManager,
        });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 1.5,
      headerName: "Site",
      field: "siteId",
      autoHeight: true,
      wrapText: true,
      valueGetter: (params) => {
        return siteCellRenderer({ value: params.data.siteId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Status",
      field: "status",
      autoHeight: true,
      wrapText: true,
      valueGetter: (params) => {
        return closureStatusRenderer({ value: params.data.status });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 1,
      headerName: "Details",
      field: "id",
      sortable: false,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => (
        <div style={{ display: "flex" }}>
          {params.data.status !== "C" ? (
            <IconButton
              aria-label="edit"
              onClick={() => {
                navigate(PATH_INDENT_DETAILS + params.data.id, {
                  state: { date: dateValue.format("YYYY-MM-DD"), isEdit: true },
                });
              }}
            >
              <EditIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
            </IconButton>
          ) : (
            ""
          )}
          <IconButton
            onClick={() => {
              navigate(PATH_INDENT_DETAILS + params.data.id, {
                state: { date: dateValue.format("YYYY-MM-DD"), isEdit: false },
              });
            }}
          >
            <VisibilityIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
          </IconButton>
          <IconButton
            aria-label="edit"
            onClick={() => {
              try {
                let localDto = {};
                APIMATERIALMGMT.Indent(params.data.id)
                  .then((resp) => resp.json())
                  .then((resp) => {
                    console.log("hola4", resp.response);
                    let itemDetails = resp.response.itemDetails;
                    itemDetails = itemDetails.sort((a, b) => a.id - b.id);
                    itemDetails.forEach((v, i) => {
                      const it = items.filter((y) => y.value === v.itemId);
                      if (it.length > 0) {
                        v.categoryId = it[0].dependentvalue;
                      }
                      v.rowId = i;
                      const itemsIn = itemList.filter((x) => x.id === v.itemId);
                      if (itemsIn.length > 0) {
                        v.quantityUom = `${v.quantity} ${
                          uomList.filter(
                            (x) => x.value === itemsIn[0].purchaseUOMId
                          )[0].name
                        }`;
                      }
                    });

                    const requiredProjectManager =
                      projectManagerProps.options.filter(
                        (x) => x.value === resp.response.projectManager
                      );
                    const projectManagerValue =
                      requiredProjectManager.length > 0
                        ? requiredProjectManager[0]
                        : { value: 0, name: "" };

                    const requiredProjectName = siteProps.options.filter(
                      (x) => x.value === resp.response.siteId
                    );
                    const siteNameValue =
                      requiredProjectName.length > 0
                        ? requiredProjectName[0]
                        : { value: 0, name: "" };
                    //  const requiredProjectManager =
                    //    projectManagerProps.options.filter(
                    //      (x) => x.value === resp.response.projectManager
                    //    );
                    //  setProjectManagerValue(
                    //    requiredProjectManager.length > 0
                    //      ? requiredProjectManager[0]
                    //      : { value: 0, name: "" }
                    //  );

                    //  const requiredProjectName = siteProps.options.filter(
                    //    (x) => x.value === resp.response.siteId
                    //  );
                    //  setProjectName(
                    //    requiredProjectName.length > 0
                    //      ? requiredProjectName[0]
                    //      : { value: 0, name: "" }
                    //  );

                    //  gridRef.current.api.applyTransaction({
                    //    add: itemDetails,
                    //  });
                    localDto = {
                      id: resp.response.id,
                      indentNo: resp.response.indentNo,
                      projectManager: resp.response.projectManager,
                      siteId: resp.response.siteId,
                      status: resp.response.status,
                      date: resp.response.date.split("-").reverse().join("-"),
                      itemDetails: itemDetails,
                      projectManagerName: projectManagerValue.name,
                      siteName: siteNameValue.name,
                      exportExcel: resp.response.exportExcel,
                    };

                    console.log("hola3", localDto);
                  });

                setAlertRadioConfirmation(
                  { excel: "Excel", pdf: "PDF" },
                  "Please select an option..."
                ).then((result) => {
                  if (!result.isConfirmed) return;

                  console.log(result);
                  let filename = "";
                  localDto.exportExcel = result.value === "excel";
                  console.log("preDto", localDto);
                  APIMATERIALMGMT.ExportIndent(localDto)
                    .then((res) => {
                      const contentType = res.headers.get("content-type");
                      if (contentType.startsWith("application/json")) {
                        return Promise.reject(res.json());
                      } else {
                        const header = res.headers.get("Content-Disposition");
                        if (header !== null) {
                          const parts = header.split(";");
                          filename = parts[1].split("=")[1].replaceAll('"', "");
                        } else {
                          filename = `indent_${params.data.id}.html`;
                        }

                        return res.blob();
                      }
                    })
                    .then((blob) => {
                      if (blob !== null) {
                        if (localDto.exportExcel) {
                          let url = window.URL.createObjectURL(blob);
                          let a = document.createElement("a");
                          a.href = url;
                          a.download = filename.replace(".html", ".xlsx");
                          document.body.appendChild(a);
                          a.click();
                          a.remove();
                        } else {
                          blob.text().then((x) => {
                            let html = x;
                            html = html.replace(
                              "<script>",
                              '<script type="text/javascript">'
                            );
                            let blob = new Blob([html], { type: "text/html" });
                            let url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
                          
                            let win = window.open(url, '_blank', '');
                            setTimeout(()=>{
                                win.print();
                            }, 2000);
                          });
                        }
                      }
                    })
                    .catch((m) => {
                      setAlertTitleText(
                        "Oops...",
                        `Something went wrong...${m.message}`
                      );
                    });
                });
              } catch (error) {
                setAlertTitleText(
                  "Oops...",
                  `Something went wrong...${error.message}`
                );
              }
            }}
          >
            <PrintIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
          </IconButton>
        </div>
      ),
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
  };

  const fetchIndents = (d1, d2) => {
    return APIMATERIALMGMT.Indents(
      d1.format("YYYY-MM-DD"),
      d2.format("YYYY-MM-DD")
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) {
          setAlertTitleText("Oops..", `An error occurred ${resp.message}`);
          return;
        }
        const data = resp.response.sort((a, b) => b.id - a.id);
        return Promise.resolve(data);
      });
  };

  const onGridReady = (params) => {
    if (date) {
      setDateValue(dayjs(date));
    } else {
      setDateValue(dayjs().startOf("month"));
    }
    APIMASTER.ItemCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setItemCategories(resp.response);
      });
    APIMASTER.SiteOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSites(resp.response);
      });
    APIMASTER.UomOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setUoms(resp.response);
      });
    APIMASTER.ProjectManagerOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setProjectManagers(resp.response);
      });
    APIMASTER.Items()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        let itemOptions = [];
        resp.response.forEach((element) => {
          itemOptions.push({
            name: element.itemName,
            value: element.id,
            dependentvalue: element.categoryId,
          });
        });
        setItems(itemOptions);
        setItemList(resp.response);
      });
    if (date) {
      fetchIndents(dayjs(date), dayjs(toDateValue)).then((data) => {
        params.api.applyTransaction({ add: data });
      });
    } else {
      fetchIndents(dayjs().startOf("month"), dayjs().endOf("month")).then(
        (data) => {
          params.api.applyTransaction({ add: data });
        }
      );
    }
  };

  return (
    <SideNav
      currentComponent={MATERIAL_MANAGEMENT}
      subComponent={INDENT}
      subComponentPath={PATH_INDENT}
      detailComponent=""
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginBottom: 10,
          marginRight: 10,
        }}
      >
        <Button
          style={{
            backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: WHITE,
            minWidth: "180px",
            minHeight: "45px",
          }}
          onClick={() => {
            navigate(PATH_INDENT_DETAILS + 0, {
              state: { date: dateValue.format("YYYY-MM-DD"), isEdit: null },
            });
          }}
        >
          {ADD}
        </Button>

        <Button
          onClick={() => {
            deleteModel();
          }}
          variant="outlined"
          sx={{
            borderColor: SPANISH_GREEN,
          }}
          style={{
            //backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: SPANISH_GREEN,
            minWidth: "180px",
            minHeight: "45px",
            marginRight: 10,
          }}
        >
          {DELETE}
        </Button>
      </div>
      {/* delete , add button div ends */}
      <div
        style={{
          backgroundColor: LIGHT_GREY_BACKGROUND,
          borderRadius: "5px",
          minWidth: "90%",
          minHeight: "50px",
          padding: 20,
          marginBottom: 10,
          borderStyle: "solid",
          border: "1x",
          borderColor: "#eaeaea",
          display: "flex",
        }}
      >
        <div style={{ display: "flex" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              id="dateId"
              label="From Date"
              value={dateValue ? dateValue : dateValue}
              //minDate={dayjs("2017-01-01")}
              onChange={(newValue) => {
                setDateValue(newValue);
                //setToDateValue(dayjs(newValue).endOf("month"));
              }}
              inputFormat="DD-MM-YYYY"
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              id="toDateId"
              label="To Date"
              value={toDateValue}
              //minDate={dayjs("2017-01-01")}
              onChange={(newValue) => {
                setToDateValue(newValue);
              }}
              inputFormat="DD-MM-YYYY"
              renderInput={(params) => (
                <TextField {...params} style={{ marginLeft: 10 }} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div
          align="right"
          style={{
            width: "100%",
            right: 0,
          }}
        >
          <Button
            style={{
              backgroundColor: SPANISH_GREEN,
              textTransform: "capitalize",
              fontSize: 14,
              color: WHITE,
              minWidth: "129px",
              minHeight: "45px",
              marginTop: 5,
            }}
            onClick={() => {
              fetchIndents(dateValue, toDateValue).then((data) => {
                try {
                  const rowData = [];
                  gridRef.current.api.forEachNode(function (node) {
                    rowData.push(node.data);
                  });
                  gridRef.current.api.applyTransaction({
                    remove: rowData,
                  });
                } catch (e) {}
                try {
                  gridRef.current.api.applyTransaction({ add: data });
                } catch (e) {}
              });
            }}
          >
            {GO}
          </Button>
        </div>
      </div>
      {/* aggrid div start */}
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "570px" }}>
          <AgGridReact
            copyGroupHeadersToClipboard={true}
            enableRangeSelection={true}
            columnDefs={columnDefs}
            ref={gridRef}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            paginationAutoPageSize={true}
            pagination={true}
            rowSelection="multiple"
            getRowId={(params) => params.data.id}
            onSelectionChanged={onRowSelectionChanged}
          ></AgGridReact>
        </div>
      </div>
    </SideNav>
  );
}
