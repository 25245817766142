import { Constants } from "./constants";

export const APIMASTER = {
  Menu: () => {},

  ItemExport: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/item/generate`, {
      method: "GET",
      credentials: "include",
    });
  },
  ItemOptions: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/items/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  ItemCategories: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/itemcategories/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  ItemCategoriesList: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/itemcategories`, {
      method: "GET",
      credentials: "include",
    });
  },
  AddItemCategory: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/itemcategory`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateItemCategory: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/itemcategory/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  DeleteItemCategory: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/itemcategory/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  SiteCategories: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/sitecategories/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  SiteCategoriesList: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/sitecategories`, {
      method: "GET",
      credentials: "include",
    });
  },
  DeleteSiteCategory: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/sitecategory/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  AddSiteCategory: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/sitecategory`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateSiteCategory: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/sitecategory/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  TaxCategories: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/taxcategories/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  SupplierCategories: () => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/suppliercategories/options`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  SupplierCategoriesList: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/suppliercategories`, {
      method: "GET",
      credentials: "include",
    });
  },
  AddSupplierCategory: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/suppliercategory`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateSupplierCategory: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/suppliercategory/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  DeleteSupplierCategory: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/suppliercategory/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  States: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/states/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  Countries: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/countries/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  UomTypes: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/uomtypes/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  UomOptions: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/uoms/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  ItemTypes: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/itemtypes/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  RoleOptions: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/role/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  ProjectPhaseOptions: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/projectphase/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  SiteOptions: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/site/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  SupplierOptions: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/supplier/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  ProjectManagerOptions: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/projectmanager/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  UserOptions: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/user/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  EmployeeOptions: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/employee/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  EmployeeTypeOptions: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/employeetype/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  DesignationOptions: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/designation/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  MartialStatusOptions: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/maritalstatus/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  CompanyOptions: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/company/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  TaxOptions: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/tax/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  LabourerTypeOptions: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/labourertype/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  InvoiceOptions: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/invoice/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  Items: () => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/items?page=0&pageSize=100000&order=item_name`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  AddItem: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/item`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateItem: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/item/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  DeleteItem: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/item/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  Employees: () => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/employees?page=0&pageSize=100000&order=first_name`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  AddEmployee: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/employee`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateEmployee: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/employee/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  DeleteEmployee: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/item/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  EmployeeTypes: () => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/employeetypes?page=0&pageSize=100000&order=employee_type_name`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  AddEmployeeType: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/employeetype`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateEmployeeType: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/employeetype/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  DeleteEmployeeType: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/employeetype/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  LabourerTypes: () => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/labourertypes?page=0&pageSize=100000&order=labourer_type_name`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  AddLabourerType: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/labourertype`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateLabourerType: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/labourertype/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  DeleteLabourerType: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/labourertype/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  EmployeeDesignations: () => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/designations?page=0&pageSize=100000&order=designation`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  AddEmployeeDesignation: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/designation`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateEmployeeDesignation: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/designation/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  DeleteEmployeeDesignation: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/designation/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  Sites: () => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/sites?page=0&pageSize=100000&order=customer_name`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  AddSite: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/site`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateSite: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/site/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  DeleteSite: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/site/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  Taxes: () => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/taxes?page=0&pageSize=100000&order=tax_name`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  AddTax: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/tax`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateTax: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/tax/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  DeleteTax: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/tax/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  Uoms: () => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/uoms?page=0&pageSize=100000&order=uom_name`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  AddUom: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/uom`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateUom: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/uom/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  DeleteUom: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/uom/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  Suppliers: () => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/suppliers?page=0&pageSize=100000&order=supplier_name`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  AddSupplier: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/supplier`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateSupplier: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/supplier/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  DeleteSupplier: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/supplier/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  Users: () => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/users?page=0&pageSize=100000&order=user_name`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  AddUser: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/user`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateUser: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/user/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  DeleteUser: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/user/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
};
