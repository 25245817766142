import { Constants } from "./constants";

export const APIMATERIALMGMT = {
  PurchasePriceConfigs: (d1, d2) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/purchase/configs?date=${d1}&toDate=${d2}&page=0&pageSize=100000`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },

  PurchaseOrderPrintPDF: (id) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/order/purchase/print/${id}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },

  AddPurchasePriceConfig: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/purchase/config`, {
      method: "POST",
      body: JSON.stringify(dto),
      credentials: "include",
    });
  },
  UpdatePurchasePriceConfig: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/purchase/config/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  DeletePurchasePriceConfig: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/purchase/config/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  PurchasePriceConfigDownloadTemplate: () => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/purchase/config/template/download`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  PurchasePriceConfigUpload: (formData) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/purchase/config/template/upload`,
      {
        method: "POST",
        body: formData,
        credentials: "include",
      }
    );
  },
  Indents: (d1, d2) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/indents?date=${d1}&toDate=${d2}&page=0&pageSize=100000`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  Indent: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/indent/${id}`, {
      method: "GET",
      credentials: "include",
    });
  },
  DeleteIndent: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/indent/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  GenerateIndent: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/indent/generate`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },
  ExportIndent: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/report/indent/generate`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },
  AddIndent: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/indent`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },
  UpdateIndent: (id, dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/indent/${id}`, {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },
  IndentOptions: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/indent/options/all`, {
      method: "GET",
      credentials: "include",
    });
  },
  GoodsReceiveds: (d1, d2) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/goodsreceiveds?date=${d1}&toDate=${d2}&page=0&pageSize=100000`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  GoodsReceived: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/goodsreceived/${id}`, {
      method: "GET",
      credentials: "include",
    });
  },
  GoodsReceivedOptions: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/goodsreceived/options`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },
  DeleteGoodsReceived: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/goodsreceived/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  SaveGoodsReceived: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/goodsreceived`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },
  UpdateGoodsReceived: (id, dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/goodsreceived/${id}`, {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },
  PurchaseOrders: (d1, d2) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/order/purchases?date=${d1}&toDate=${d2}&page=0&pageSize=100000`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  Rates: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/order/purchase/rate`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },
  PurchaseOrderOptions: (status) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/order/purchase/options/${status}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  PurchaseOrderIndent: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/order/purchase/indent`, {
      method: "POST",
      body: JSON.stringify(dto),
      credentials: "include",
    });
  },
  PurchaseOrder: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/order/purchase/${id}`, {
      method: "GET",
      credentials: "include",
    });
  },
  PurchaseOrderGRN: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/order/purchase/grn/${id}`, {
      method: "GET",
      credentials: "include",
    });
  },
  DeletePurchaseOrder: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/order/purchase/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  PurchaseOrderChangeStatus: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/order/purchase/status`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  SavePurchaseOrder: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/order/purchase`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },
  UpdatePurchaseOrder: (id, dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/order/purchase/${id}`, {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },
  CreditNote: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/creditnote/${id}`, {
      method: "GET",
      credentials: "include",
    });
  },
  CreditNotes: (d1, d2) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/creditnotes?date=${d1}&toDate=${d2}&page=0&pageSize=100000`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  SaveCrediteNote: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/creditnote`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },
  UpdateCrediteNote: (id, dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/creditnote/${id}`, {
      method: "PUT",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },
  DeleteCreditNote: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/creditnote/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  CrediteNoteGRN: (id) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/goodsreceived/creditnote/${id}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  MaterialTransfers: (d1, d2) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/materialtransfer?date=${d1}&toDate=${d2}&page=0&pageSize=100000`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  MaterialTransfer: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/materialtransfer/${id}`, {
      method: "GET",
      credentials: "include",
    });
  },
  SaveMaterialTransfer: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/materialtransfer`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },
  DeleteMaterialTransfer: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/materialtransfer/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  Stock: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/materialtransfer/stock`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },
  MaterialTransferGrn: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/materialtransfer/grn`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },
  StockConversion: (dto) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/goodsreceived/stock/quantity`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(dto),
      }
    );
  },
};
