import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TableBody,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { APIMASTER } from "../../../../apihandler/master";
import { APIREPORTS } from "../../../../apihandler/reports";
import {
  getItemCategories,
  getItemList,
  getItems,
  getSiteCategories,
  getSites,
  getSuppliers,
  getUoms,
  globalFilter,
  itemCellRenderer,
  setItemCategories,
  setItemList,
  setItems,
  setSiteCategories,
  setSites,
  setUoms,
  uomCellRenderer,
} from "../../../../component/cellRenderers/renderers";
import SideNav from "../../../../component/layouts/sideNav";
import { setAlertTitleText } from "../../../../component/modal/alertModal";
import {
  LIGHT_GREY_BACKGROUND,
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";
import {
  EXPORT,
  GENERATE,
  MATERIAL_MANAGEMENT_REPORT,
  STOCK_STATEMENT_REPORT,
} from "../../../../resources/constant/strings/string";
import { PATH_STOCK_STATEMENT_REPORT } from "../../../../resources/constant/url/url";
import { APIUTILS } from "../../../../apihandler/utils";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  color: SPANISH_GREEN,
  transform: "translate(-50%, -50%)",
  zIndex: 2,
};

export default function StockStatementReport() {
  const [itemDetails, setItemDetails] = useState([]);

  const [dataReady, setDataReady] = useState(true);
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    console.log("DTO: ", dto);
    setDataReady(false);
    APIREPORTS.StockStatementReport(dto)
      .then((response) => response.json())
      .then((data) => {
        setDataReady(true);
        if (data.statusCode !== 200) {
          setDataReady(true);
          setAlertTitleText("Oops..", `An error occurred ${data.message}`);
          return;
        }

        data.response.itemDetails = data.response.itemDetails.filter(
          (x) => x.closingStock > 0
        );

        data.response.itemDetails.forEach((e) => {
          const item = itemList.filter((v) => v.id === e.itemId);
          const item1 =
            item.length > 0 ? item[0] : { value: e.itemId, itemName: "" };

          e.item = item1.itemName;

          if (item.length > 0) {
            let uom = uoms.filter((v) => v.value === item1.stockUOMId);
            if (uom.length > 0) {
              e.uomId = uom[0].value;
              e.uom = uom[0].name;
            }
          }
        });

        try {
          gridRef1.current.api.setRowData([]);
        } catch (e) {}

        setItemDetails((r) => [].concat(data.response.itemDetails));
        gridRef1.current.api.applyTransaction({
          add: data.response.itemDetails,
        });
        setTimeout(() => {
          let pinnedBottomData = generatePinnedBottomData();
          gridRef1.current.api.setPinnedBottomRowData([pinnedBottomData]);
        }, 2000);
      })
      .catch((e) => {
        setAlertTitleText("Oops", "An error occurred");
        setDataReady(true);
        return;
      });
  }
  const [dto, setDTO] = useState({
    id: 0,
    siteCategoryId: null,
    siteId: null,
    itemCategoryId: null,
    itemId: null,
    fromDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    toDate: dayjs().endOf("month").format("YYYY-MM-DD"),
    closingStockZero: true,
    groupByDate: false,
    isBillable: 2,
  });

  const siteCategoryItems = getSiteCategories();
  const sites = getSites();
  const uoms = getUoms();

  const siteCategoryProps = {
    options: siteCategoryItems,
    getOptionLabel: (option) => option?.name || "",
  };
  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };
  const [groupByDate, setGroupByDate] = useState(false);

  const generatePinnedBottomData = () => {
    // generate a row-data with null values
    let result = {};

    gridRef1.current.columnApi.getAllGridColumns().forEach((item) => {
      result[item.colId] = null;
    });
    return calculatePinnedBottomData(result);
  };

  const calculatePinnedBottomData = (target) => {
    //console.log(target);
    //**list of columns fo aggregation**
    let columnsWithAggregation = [
      "openingStock",
      "purchase",
      "transferFrom",
      "consumption",
      "adjustment",
      "itemReturn",
      "closingStock",
    ];
    columnsWithAggregation.forEach((element) => {
      gridRef1.current.api.forEachNodeAfterFilter((rowNode) => {
        if (element === "id") {
          target[element] = "Grand Total";
          return;
        }
        if (rowNode.data[element] || rowNode.data[element] === 0)
          target[element] += Number.parseFloat(
            APIUTILS.Financial(rowNode.data[element])
          );
      });
      if (target[element]) {
        target[element] = `${APIUTILS.Financial(target[element])}`;
      }
    });
    return target;
  };

  // below code is for grid - 1
  const columnDefs1 = [
    {
      flex: 1.5,
      headerName: "Item Name",
      field: "itemId",
      valueGetter: (params) => {
        return itemCellRenderer({ value: params.data.itemId });
      },
      autoHeight: true,
      wrapText: true,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },

    {
      headerName: "UOM",
      field: "siteName",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      valueGetter: (params) => {
        return uomCellRenderer({ value: params.data.uomId });
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Opening Stock",
      field: "openingStock",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Purchase",
      field: "purchase",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Transfer From ",
      field: "transferFrom",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Transfer To ",
      field: "transferTo",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Consumption",
      field: "consumption",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Adjustment",
      field: "adjustment",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Item Return",
      field: "itemReturn",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Closing Stock",
      field: "closingStock",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Date",
      field: "date",
      autoHeight: true,
      wrapText: true,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
      hide: !groupByDate,
    },
  ];
  const gridRef1 = useRef();
  const defaultColDef1 = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };

  const onGridReady1 = (params) => {
    gridRef1.current.api.setRowData([]);

    const p2 = APIMASTER.SiteCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSiteCategories(resp.response);
      });

    const p4 = APIMASTER.SiteOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSites(resp.response);
      });

    const p5 = APIMASTER.ItemCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setItemCategories(resp.response);
      });

    const p6 = APIMASTER.Items()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        let itemOptions = [];
        resp.response.forEach((element) => {
          itemOptions.push({
            name: element.itemName,
            value: element.id,
            dependentvalue: element.categoryId,
          });
        });
        setItems(itemOptions);
        setItemList(resp.response);
      });

    const p7 = APIMASTER.UomOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setUoms(resp.response);
      });

    Promise.all([p2, p4, p5, p6, p7]).then(() => {
      setIsGridReady(true);
    });
  };
  const [selectedRowId1, setSelectedRowId1] = useState(0);

  const onRowSelectionChanged1 = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId1(event.api.getSelectedRows()[0].id);
    else setSelectedRowId1(0);
  };
  // grid 1 code ends

  const suppliers = getSuppliers().filter((v) => v.name !== "Opening Stock");
  const itemCategories = getItemCategories();
  const items = getItems();
  const itemList = getItemList();

  const [isGridReady, setIsGridReady] = useState(false);
  const supplierProps = {
    options: suppliers,
    getOptionLabel: (option) => option?.name || "",
  };
  const itemCategoryProps = {
    options: itemCategories,
    getOptionLabel: (option) => option?.name || "",
  };
  const itemProps = {
    options: items,
    getOptionLabel: (option) => option?.name || "",
  };

  useEffect(() => {
    if (isGridReady) {
      siteCategoryItems.filter((x) => false).concat(getSiteCategories());
      sites.filter((x) => false).concat(getSites());
      itemCategories.filter((x) => false).concat(getItemCategories());
      itemList.filter((x) => false).concat(getItemList());
      items.filter((x) => false).concat(getItems());
      uoms.filter((x) => false).concat(getUoms());
    }
  }, [isGridReady]);

  const [
    includeItemsWithZeroClosingStock,
    setIncludeItemsWithZeroCLosingStock,
  ] = useState(true);
  return (
    <SideNav
      currentComponent={MATERIAL_MANAGEMENT_REPORT}
      subComponent={STOCK_STATEMENT_REPORT}
      subComponentPath={PATH_STOCK_STATEMENT_REPORT}
      detailComponent=""
    >
      <div>
        <div style={style}>
          <ThreeCircles
            visible={!dataReady}
            height="256"
            width="256"
            outerCircleColor={SPANISH_GREEN}
            innerCircleColor="#eeeeee"
            middleCircleColor={SPANISH_GREEN}
            ariaLabel="eNirmaan-loading"
            color={SPANISH_GREEN}
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
        <form>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={onFormSubmit}
            >
              {GENERATE}
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                //backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={() => {
                let filename = "";
                dto.itemDetails = itemDetails;
                setDataReady(false);
                APIREPORTS.StockStatementReportExport(dto)
                  .then((res) => {
                    setDataReady(true);
                    const contentType = res.headers.get("content-type");
                    if (contentType.startsWith("application/json")) {
                      return Promise.reject(res.json());
                    } else {
                      const header = res.headers.get("Content-Disposition");
                      if (header !== null) {
                        const parts = header.split(";");
                        filename = parts[1].split("=")[1].replaceAll('"', "");
                      } else {
                        filename = "Stock Statement Export Report.xlsx";
                      }

                      return res.blob();
                    }
                  })
                  .then((blob) => {
                    if (blob !== null) {
                      let url = window.URL.createObjectURL(blob);
                      let a = document.createElement("a");
                      a.href = url;
                      a.download = filename;
                      document.body.appendChild(a);
                      a.click();
                      a.remove();
                    }
                  })
                  .catch((m) => {
                    setAlertTitleText(
                      "Oops...",
                      `Something went wrong...${m.message}`
                    );
                  });
              }}
            >
              {EXPORT}
            </Button>
          </div>
          {/* button div ends */}
          {/* below code is for above container */}
          <div
            style={{
              backgroundColor: LIGHT_GREY_BACKGROUND,
              borderRadius: "5px",
              minWidth: "90%",
              minHeight: "50px",
              padding: 20,
              marginBottom: 10,
              borderStyle: "solid",
              border: "1x",
              borderColor: "#eaeaea",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <table style={{ width: "100%" }}>
              <TableBody>
                <tr>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="From Date"
                        inputFormat="DD-MM-YYYY"
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dto.fromDate}
                        onChange={(newValue) => {
                          setDTO({
                            ...dto,
                            fromDate: newValue.format("YYYY-MM-DD"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="To Date"
                        inputFormat="DD-MM-YYYY"
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dto.toDate}
                        onChange={(newValue) => {
                          setDTO({
                            ...dto,
                            toDate: newValue.format("YYYY-MM-DD"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                  <td>
                    <Autocomplete
                      filterOptions={globalFilter}
                      {...siteCategoryProps}
                      options={siteCategoryItems}
                      id="auto-complete-1"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="siteCategoryId"
                      defaultValue={
                        siteCategoryProps.options.find(
                          (v) => v.value === dto.siteCategoryId
                        ) || {}
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, siteCategoryId: v?.value });
                          else
                            setDTO({
                              ...dto,
                              siteCategoryId: Number(v?.value),
                            });
                        } else {
                          setDTO({ ...dto, siteCategoryId: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Site Category"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            width: "200px",
                            minHeight: "48.5px",
                            height: "48.5px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Autocomplete
                      {...siteProps}
                      id="auto-complete-2"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="siteId"
                      defaultValue={
                        siteProps.options.find((v) => v.value === dto.siteId) ||
                        {}
                      }
                      filterOptions={(o, s) => {
                        let options = globalFilter(o, s);
                        let newOptions = [];
                        options.forEach((element) => {
                          if (element.dependentvalue === dto.siteCategoryId)
                            newOptions.push(element);
                        });
                        return newOptions;
                      }}
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, siteId: v?.value });
                          else setDTO({ ...dto, siteId: Number(v?.value) });
                        } else {
                          setDTO({ ...dto, siteId: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Site"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                  {/* <td>
                    <Autocomplete
                      {...supplierProps}
                      id="auto-complete-3"
                      autoComplete autoHighlight autoSelect
                      includeInputInList
                      name="parentCategory"
                      // isOptionEqualToValue={(o, v) => {
                      //   return o.value === v.value;
                      // }}
                      // filterOptions={(o, s) => {
                      //   let options = globalFilter(o, s);
                      //   let newOptions = [];
                      //   options.forEach((element) => {
                      //     if (element.dependentvalue === dto.supplierCategoryId)
                      //       newOptions.push(element);
                      //   });
                      //   return newOptions;
                      // }}
                      onChange={(e, v) => {}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Parent Category"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td> */}
                  <td>
                    <Autocomplete
                      filterOptions={globalFilter}
                      {...itemCategoryProps}
                      id={`autocomplete-6`}
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="itemCategoryId"
                      defaultValue={
                        itemCategoryProps.options.find(
                          (v) => v.value === dto.itemCategoryId
                        ) || {}
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, itemCategoryId: v?.value });
                          else
                            setDTO({
                              ...dto,
                              itemCategoryId: Number(v?.value),
                            });
                        } else {
                          setDTO({ ...dto, itemCategoryId: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Item Category"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "38.5px",
                            width: "200px",
                            height: "38.5px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Autocomplete
                      {...itemProps}
                      id={`autocomplete-7`}
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="itemId"
                      defaultValue={itemProps.options.find(
                        (v) => v.value === dto.itemId
                      )}
                      filterOptions={(o, s) => {
                        let options = globalFilter(o, s);
                        let newOptions = [];
                        options.forEach((element) => {
                          if (element.dependentvalue === dto.itemCategoryId)
                            newOptions.push(element);
                        });
                        return newOptions;
                      }}
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, itemId: v?.value });
                          else setDTO({ ...dto, itemId: Number(v?.value) });
                        } else {
                          setDTO({ ...dto, itemId: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Item"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "38.5px",
                            width: "200px",
                            height: "38.5px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel>Billability</InputLabel>
                      <Select
                        value={dto.isBillable}
                        name="isBillable"
                        label="Is Billable"
                        onChange={(e) => {
                          setDTO({
                            ...dto,
                            isBillable: e.target.value,
                          })
                        }}
                      >
                        <MenuItem value={2}>All</MenuItem>
                        <MenuItem value={1}>Billable</MenuItem>
                        <MenuItem value={0}>Non Billable</MenuItem>
                      </Select>
                    </FormControl>
                  </td>
                </tr>
              </TableBody>
            </table>
          </div>
        </form>
      </div>
      {/* below code is for ag-grid -1 */}
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "570px" }}>
          <AgGridReact
            copyGroupHeadersToClipboard={true}
            enableRangeSelection={true}
            columnDefs={columnDefs1}
            ref={gridRef1}
            defaultColDef={defaultColDef1}
            onGridReady={onGridReady1}
            onSelectionChanged={onRowSelectionChanged1}
          ></AgGridReact>
        </div>
      </div>
    </SideNav>
  );
}
