import React from "react";
import "./App.css";
import { ThreeCircles } from "react-loader-spinner";
import RoutesComponent from "./controller/routes/routesComponent";
import { BrowserRouter } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";
import {
  SPANISH_GREEN,
} from "./resources/constant/colors/theme";

export default function App() {
  const { loading, authenticated } = useAuth();
  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    color: SPANISH_GREEN,
    transform: "translate(-50%, -50%)",
  };

  return (
    <div className="App">
      {loading ? (
        <div style={style}>
          <ThreeCircles
            visible={loading}
            height="256"
            width="256"
            outerCircleColor={SPANISH_GREEN}
            innerCircleColor="#eeeeee"
            middleCircleColor={SPANISH_GREEN}
            ariaLabel="eNirmaan-loading"
            color={SPANISH_GREEN}
            wrapperStyle={{}}
            wrapperClass=""
          />
          Please wait while we finish loading...
        </div>
      ) : (
        <BrowserRouter>
          <RoutesComponent />
        </BrowserRouter>
      )}
    </div>
  );
}
