import { Autocomplete, Button, TableBody, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { APIMASTER } from "../../../../apihandler/master";
import { APIREPORTS } from "../../../../apihandler/reports";
import {
  getItemCategories,
  getItemList,
  getItems,
  getSites,
  getSuppliers,
  getUoms,
  itemCategoryCellRenderer,
  itemCellRenderer,
  setItemCategories,
  setItemList,
  setItems,
  setSites,
  setSuppliers,
  setUoms,
} from "../../../../component/cellRenderers/renderers";
import SideNav from "../../../../component/layouts/sideNav";
import { setAlertTitleText } from "../../../../component/modal/alertModal";
import {
  LIGHT_GREY_BACKGROUND,
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";
import {
  EXCAVATOR_SUMMARY_REPORT,
  EXPORT,
  GENERATE,
  LABOUR_MANAGEMENT_REPORT,
} from "../../../../resources/constant/strings/string";
import { PATH_EXCAVATOR_SUMMARY_REPORT } from "../../../../resources/constant/url/url";
import { APIUTILS } from "../../../../apihandler/utils";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  color: SPANISH_GREEN,
  transform: "translate(-50%, -50%)",
  zIndex: 2,
};

export default function ExcavatorSummaryReport() {
  const [itemDetails, setItemDetails] = useState([]);

  const [dataReady, setDataReady] = useState(true);

  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    console.log("dto", dto);
    setDataReady(false);
    APIREPORTS.ExcavatorSummartyReport(dto)
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode !== 200) {
          setDataReady(true);
          setAlertTitleText("Oops..", `An error occurred ${data.message}`);
          return;
        }

        setDataReady(true);
        try {
          const rowData = [];
          gridRef.current.api.forEachNode(function (node) {
            rowData.push(node.data);
          });
          gridRef.current.api.applyTransaction({
            remove: rowData,
          });
        } catch (e) {}

        data.response.itemDetails.forEach((x) => {
          const item = itemList.filter((v) => v.id === x.itemId);
          const item1 = item.length > 0 ? item[0] : {};

          if (item.length > 0) {
            x.item = item1.itemName;
            let uom = uoms.filter((v) => v.value === item1.stockUOMId);
            if (uom.length > 0) x.unit = uom[0].name;

            const reqdItemCategory = itemCategories.filter(
              (y) => y.value === item1.categoryId
            );
            x.itemCategory =
              reqdItemCategory.length > 0 ? reqdItemCategory[0].name : "";
          }
        });

        const reqdSite = sites.filter((y) => y.value === dto.siteId);
        dto.site = reqdSite.length > 0 ? reqdSite[0].name : "";
        const reqdSupplier = suppliers.filter(
          (y) => y.value === dto.subContractorId
        );
        dto.subContractor = reqdSupplier.length > 0 ? reqdSupplier[0].name : "";

        setItemDetails((r) => [].concat(data.response.itemDetails));
        gridRef.current.api.applyTransaction({
          add: data.response.itemDetails,
        });
        setTimeout(() => {
          let pinnedBottomData = generatePinnedBottomData();
          gridRef.current.api.setPinnedBottomRowData([pinnedBottomData]);
        }, 2000);
      })
      .catch((e) => {
        setAlertTitleText("Oops", "An error occurred");
        setDataReady(true);
        return;
      });
  }
  const [dto, setDTO] = useState({
    id: 0,
    siteId: 0,
    fromDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    toDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  // below code is for grid - 1
  const columnDefs1 = [
    {
      flex: 1.5,
      headerName: "Item Category",
      field: "itemCategory",
      autoHeight: true,
      wrapText: true,
      valueGetter: (params) => {
        if (params.data.itemCategory === "Grand Total") return "Grand Total";
        return params.data.itemCategory;
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },

    {
      flex: 1.5,
      headerName: "Item Name",
      field: "itemId",
      valueGetter: (params) => {
        return itemCellRenderer({ value: params.data.itemId });
      },
      autoHeight: true,
      wrapText: true,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Unit",
      field: "unit",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Qty",
      field: "quantity",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Amount",
      field: "rate",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
  ];
  const gridRef = useRef();
  const defaultColDef1 = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
  };
  const onGridReady1 = (params) => {
    const p3 = APIMASTER.SupplierOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSuppliers(resp.response);
      });

    const p4 = APIMASTER.SiteOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSites(resp.response);
      });

    const p5 = APIMASTER.ItemCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setItemCategories(resp.response);
      });

    const p6 = APIMASTER.Items()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        let itemOptions = [];
        resp.response.forEach((element) => {
          itemOptions.push({
            name: element.itemName,
            value: element.id,
            dependentvalue: element.categoryId,
          });
        });
        setItems(itemOptions);
        setItemList(resp.response);
      });
    const p7 = APIMASTER.UomOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setUoms(resp.response);
      });
    Promise.all([p3, p4, p5, p6, p7]).then(() => {
      try {
        setDataReady(true);
        setIsGridReady(true);
        gridRef.current.api.setRowData([]);
      } catch (e) {}
    });
  };
  const [selectedRowId1, setSelectedRowId1] = useState(0);
  const [isGridReady, setIsGridReady] = useState(false);
  const suppliers = getSuppliers().filter((v) => v.name !== "Opening Stock");
  const sites = getSites();
  const itemCategories = getItemCategories();
  const items = getItems();
  const itemList = getItemList();
  const uoms = getUoms();

  useEffect(() => {
    if (isGridReady) {
      suppliers
        .filter((x) => false)
        .concat(getSuppliers().filter((v) => v.name !== "Opening Stock"));
      sites.filter((x) => false).concat(getSites());
      itemCategories.filter((x) => false).concat(getItemCategories());
      itemList.filter((x) => false).concat(getItemList());
      items.filter((x) => false).concat(getItems());
      uoms.filter((x) => false).concat(getUoms());
    }
  }, [isGridReady]);

  const onRowSelectionChanged1 = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId1(event.api.getSelectedRows()[0].id);
    else setSelectedRowId1(0);
  };
  // grid 1 code ends

  const generatePinnedBottomData = () => {
    // generate a row-data with null values
    let result = {};

    gridRef.current.columnApi.getAllGridColumns().forEach((item) => {
      result[item.colId] = null;
    });
    return calculatePinnedBottomData(result);
  };

  const calculatePinnedBottomData = (target) => {
    //console.log(target);
    //**list of columns fo aggregation**
    let columnsWithAggregation = ["itemCategory", "quantity", "rate"];
    columnsWithAggregation.forEach((element) => {
      gridRef.current.api.forEachNodeAfterFilter((rowNode) => {
        if (element === "itemCategory") {
          target[element] = "Grand Total";
          return;
        }
        if (rowNode.data[element] || rowNode.data[element] === 0)
          target[element] += Number.parseFloat(
            APIUTILS.Financial(rowNode.data[element])
          );
      });
      if (target[element] && element !== "itemCategory") {
        target[element] = `${APIUTILS.Financial(target[element])}`;
      }
    });
    return target;
  };

  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };

  const supplierProps = {
    options: suppliers,
    getOptionLabel: (option) => option?.name || "",
  };
  return (
    <SideNav
      currentComponent={LABOUR_MANAGEMENT_REPORT}
      subComponent={EXCAVATOR_SUMMARY_REPORT}
      subComponentPath={PATH_EXCAVATOR_SUMMARY_REPORT}
      detailComponent=""
    >
      <div>
        <div style={style}>
          <ThreeCircles
            visible={!dataReady}
            height="256"
            width="256"
            outerCircleColor={SPANISH_GREEN}
            innerCircleColor="#eeeeee"
            middleCircleColor={SPANISH_GREEN}
            ariaLabel="eNirmaan-loading"
            color={SPANISH_GREEN}
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
        <form>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={onFormSubmit}
            >
              {GENERATE}
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                //backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={() => {
                let filename = "";
                dto.itemDetails = itemDetails;
                setDataReady(false);
                APIREPORTS.ExcavatorSummartyReportExport(dto)
                  .then((res) => {
                    setDataReady(true);

                    const contentType = res.headers.get("content-type");
                    if (contentType.startsWith("application/json")) {
                      return Promise.reject(res.json());
                    } else {
                      const header = res.headers.get("Content-Disposition");
                      if (header !== null) {
                        const parts = header.split(";");
                        filename = parts[1].split("=")[1].replaceAll('"', "");
                      } else {
                        filename = "Excavator Summary Export Report.xlsx";
                      }

                      return res.blob();
                    }
                  })
                  .then((blob) => {
                    if (blob !== null) {
                      let url = window.URL.createObjectURL(blob);
                      let a = document.createElement("a");
                      a.href = url;
                      a.download = filename;
                      document.body.appendChild(a);
                      a.click();
                      a.remove();
                    }
                  })
                  .catch((m) => {
                    setAlertTitleText(
                      "Oops...",
                      `Something went wrong...${m.message}`
                    );
                  });
              }}
            >
              {EXPORT}
            </Button>
          </div>
          {/* button div ends */}
          {/* below code is for above container */}
          <div
            style={{
              backgroundColor: LIGHT_GREY_BACKGROUND,
              borderRadius: "5px",
              minWidth: "90%",
              minHeight: "50px",
              padding: 20,
              marginBottom: 10,
              borderStyle: "solid",
              border: "1x",
              borderColor: "#eaeaea",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <table style={{ width: "100%" }}>
              <TableBody>
                <tr>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="From Date"
                        inputFormat="DD-MM-YYYY"
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dto.fromDate}
                        onChange={(newValue) => {
                          setDTO({
                            ...dto,
                            fromDate: newValue.format("YYYY-MM-DD"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="To Date"
                        inputFormat="DD-MM-YYYY"
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dto.toDate}
                        onChange={(newValue) => {
                          setDTO({
                            ...dto,
                            toDate: newValue.format("YYYY-MM-DD"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                  <td>
                    <Autocomplete
                      {...siteProps}
                      id="auto-complete-1"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="siteId"
                      defaultValue={
                        siteProps.options.find((v) => v.value === dto.siteId) ||
                        {}
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, siteId: v?.value });
                          else setDTO({ ...dto, siteId: Number(v?.value) });
                        } else {
                          setDTO({ ...dto, siteId: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Site"
                          required
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Autocomplete
                      {...supplierProps}
                      id="auto-complete-2"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="subContractor"
                      // isOptionEqualToValue={(o, v) => {
                      //   return o.value === v.value;
                      // }}
                      // filterOptions={(o, s) => {
                      //   let options = globalFilter(o, s);
                      //   let newOptions = [];
                      //   options.forEach((element) => {
                      //     if (element.dependentvalue === dto.supplierCategoryId)
                      //       newOptions.push(element);
                      //   });
                      //   return newOptions;
                      // }}
                      onChange={(e, v) => {}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sub Contractor"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                </tr>
              </TableBody>
            </table>
          </div>
        </form>
      </div>

      {/* below code is for ag-grid -1 */}
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "570px" }}>
          <AgGridReact
            copyGroupHeadersToClipboard={true}
            enableRangeSelection={true}
            columnDefs={columnDefs1}
            ref={gridRef}
            defaultColDef={defaultColDef1}
            onGridReady={onGridReady1}
            paginationAutoPageSize={true} pagination={true}
            onSelectionChanged={onRowSelectionChanged1}
          ></AgGridReact>
        </div>
      </div>
    </SideNav>
  );
}
