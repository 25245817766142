import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import React, { useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  getEmployees,
  getItemCategories,
  getItemList,
  getItems,
  getSiteCategories,
  getSites,
  getUoms,
  globalFilter,
  itemCategoryCellRenderer,
  itemCellRenderer,
} from "../../../../../component/cellRenderers/renderers";
import SideNav from "../../../../../component/layouts/sideNav";
import {
  CANCEL,
  CLEAR,
  GRN_NOS,
  ITEM_CATEGORY,
  ITEM_NAME,
  MATERIAL_MANAGEMENT,
  MATERIAL_TRANSFER,
  MATERIAL_TRANSFER_DETAIL,
  QTY,
  SAVE,
  TOTAL_STOCK,
  TRANSACTION_NO,
} from "../../../../../resources/constant/strings/string";

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { PATH_MATERIAL_TRANSFER } from "../../../../../resources/constant/url/url";

import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  TableBody,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { APIMATERIALMGMT } from "../../../../../apihandler/materialmgmt";
import {
  setAlertConfirmDeletion,
  setAlertTitleText,
} from "../../../../../component/modal/alertModal";
import minus_icon from "../../../../../resources/assets/images/minus_icon.svg";
import plus_icon from "../../../../../resources/assets/images/plus_icon.svg";
import {
  BLACK,
  CHARLESTON_GREEN,
  LIGHT_GREY_BACKGROUND,
  PRUSSIAN_BLUE,
  SPANISH_GREEN,
  WHITE,
} from "../../../../../resources/constant/colors/theme";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  color: SPANISH_GREEN,
  transform: "translate(-50%, -50%)",
  zIndex: 2,
};
export default function MaterialTransferDetail() {
  let { id } = useParams();
  const addModel = id <= 0;
  const editModel = id > 0;

  const location = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [disabled, setDisabled] = useState(editModel);

  const [dto, setDTO] = useState({
    id: 0,
    transactionNo: "Auto Generated",
    transactionDate: dayjs(),
    deliveryDate: dayjs(),
    fromSite: 0,
    toSite: 0,
    reportedBy: 0,
    customerId: 0,
    remarks: "",
  });
  const [rowItems, setRowItems] = useState([
    {
      rowId: -1*Number((Math.random() * 10000000).toFixed(0)),
      id: 0,
      itemCategoryId: 0,
      itemId: 0,
      uomName: 0,
      qty: 0,
      averageRate: 0,
      totalStock: 0,
      grnDetailId: 0,
      grnNumber: "",
      grnOptions: [],
    },
  ]);
  const [rowId, setRowId] = useState(2);
  const removeObjectFromArray = (rowId) => {
    const arr = rowItems.filter((x) => {
      return x.rowId !== rowId;
    });
    arr.forEach((e, i) => {
      e.rowId = i;
    });
    setRowItems((r) => [].concat(arr));
  };

  const onClear = () => {
    setAlertConfirmDeletion(
      "Confirmation needed",
      "Do you confirm on proceeding to clear?"
    ).then((consent) => {
      if (!consent.isConfirmed) return;

      window.location.reload();
    });
  };

  const clearArray = () => {
    setRowItems([...rowItems.filter((x) => false)]);
    console.log("Rows count on clear", rowItems.length);
  };

  const addObjectToArray = () => {
    let newArr = [...rowItems];
    newArr.push({
      rowId: -1*Number((Math.random() * 10000000).toFixed(0)),
      id: 0,
      itemCategoryId: 0,
      itemId: 0,
      uomName: 0,
      qty: 0,
      averageRate: 0,
      totalStock: 0,
      grnNumber: "",
      grnDetailId: 0,
      grnOptions: [],
    });
    setRowItems(newArr);
  };

  const itemCategories = getItemCategories();

  const itemCategoryProps = {
    options: itemCategories,
    getOptionLabel: (option) => option?.name || "",
  };

  const itemList = getItemList();
  const uomList = getUoms();

  const items = getItems();

  const itemProps = {
    options: items,
    getOptionLabel: (option) => option?.name || "",
  };

  const uomProps = {
    options: uomList,
    getOptionLabel: (option) => option?.name || "",
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };

  function validation() {
    if (dto.fromSite === dto.toSite) {
      setAlertTitleText("Source and destination sites cannot be idential");
      return;
    }

    dto.materialTransferDetail = rowItems;

    if (
      dto.materialTransferDetail.filter((x) => x.grnDetailId <= 0).length > 0
    ) {
      setAlertTitleText("GRN's not selected for transfer");
      return;
    }

    if (dto.reportedBy <= 0) {
      setAlertTitleText("Reported field is mandatory");
      return;
    }

    //api call to save goes here
    if (dto.id <= 0) {
      APIMATERIALMGMT.SaveMaterialTransfer(dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setAlertTitleText(
              "Success",
              "The record was inserted successfully"
            );
            navigate(PATH_MATERIAL_TRANSFER + "/" + location.state.date);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    } else {
      APIMATERIALMGMT.UpdateMaterialTransfer(dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            navigate(PATH_MATERIAL_TRANSFER + "/" + location.state.date);
            setAlertTitleText("Success", "The record was updated successfully");
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
          }
        });
    }
  }

  const employees = getEmployees();
  const employeeProps = {
    options: employees,
    getOptionLabel: (option) => option?.name || "",
  };

  const sites = getSites();
  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };

  const siteCategories = getSiteCategories();
  const siteCategoryProps = {
    options: siteCategories,
    getOptionLabel: (option) => option?.name || "",
  };

  const gridRef = useRef();
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [gridData, setGridData] = useState([]);

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId(event.api.getSelectedRows()[0].id);
    else setSelectedRowId(0);
  };

  const columnDefs = [
    {
      headerName: "Item Category",
      field: "itemCategoryId",
      valueGetter: (params) => {
        return itemCategoryCellRenderer({ value: params.data.itemCategoryId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Item Name",
      field: "itemId",
      valueGetter: (params) => {
        return itemCellRenderer({ value: params.data.itemId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Quantity",
      field: "qty",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
  };

  const onGridReady = (params) => {
    if (id > 0) {
      APIMATERIALMGMT.MaterialTransfer(id)
        .then((resp) => resp.json())
        .then((resp) => {
          resp.response.materialTransferDetail =
            resp.response.materialTransferDetail.sort((a, b) => b.id - a.id);
          resp.response.materialTransferDetail.forEach((e) => {
            const it = items.filter((v) => v.value === e.itemId);
            if (it.length > 0) {
              e.itemCategoryId = it[0].dependentvalue;
            }
          });

          const requiredFromSite = siteProps.options.filter(
            (x) => x.value === resp.response.fromSite
          );
          setFromSite(
            requiredFromSite.length > 0
              ? requiredFromSite[0]
              : { value: 0, name: "" }
          );

          const requiredFromSiteCategory = siteCategoryProps.options.filter(
            (x) =>
              x.value ===
              (requiredFromSite.length > 0
                ? requiredFromSite[0].dependentvalue
                : 0)
          );
          setFromSiteCategory(
            requiredFromSiteCategory.length > 0
              ? requiredFromSiteCategory[0]
              : { value: 0, name: "" }
          );

          const requiredToSite = siteProps.options.filter(
            (x) => x.value === resp.response.toSite
          );
          setToSite(
            requiredToSite.length > 0
              ? requiredToSite[0]
              : { value: 0, name: "" }
          );

          const requiredToSiteCategory = siteCategoryProps.options.filter(
            (x) =>
              x.value ===
              (requiredToSite.length > 0 ? requiredToSite[0].dependentvalue : 0)
          );
          setToSiteCategory(
            requiredToSiteCategory.length > 0
              ? requiredToSiteCategory[0]
              : { value: 0, name: "" }
          );

          const requiredEmployee = employeeProps.options.filter(
            (x) => x.value === resp.response.reportedBy
          );
          setEmployee(
            requiredEmployee.length > 0
              ? requiredEmployee[0]
              : { value: 0, name: "" }
          );

          console.log(resp.response.materialTransferDetail);
          gridRef.current.api.applyTransaction({
            add: resp.response.materialTransferDetail,
          });
          setDTO({
            ...dto,
            id: resp.response.id,
            transactionNo: resp.response.transactionNo,
            transactionDate: resp.response.transactionDate
              .split("-")
              .reverse()
              .join("-"),
            fromSite: resp.response.fromSite,
            toSite: resp.response.toSite,
            reportedBy: resp.response.reportedBy,
            customerId: resp.response.customerId,
            remarks: resp.response.remarks,
          });
        });
    } else {
      gridRef.current.api.applyTransaction({ add: gridData });
    }
  };

  const [dataReady, setDataReady] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [toSite, setToSite] = useState({});
  const [fromSite, setFromSite] = useState({});
  const [toSiteCategory, setToSiteCategory] = useState({});
  const [fromSiteCategory, setFromSiteCategory] = useState({});
  const [employee, setEmployee] = useState({});

  return (
    <SideNav
      currentComponent={MATERIAL_MANAGEMENT}
      subComponent={MATERIAL_TRANSFER}
      subComponentPath={PATH_MATERIAL_TRANSFER}
      detailComponent={MATERIAL_TRANSFER_DETAIL}
    >
      <div>
        <form>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginTop: 10,
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            {id <= 0 && (
              <Button
                variant="outlined"
                sx={{
                  borderColor: SPANISH_GREEN,
                }}
                style={{
                  backgroundColor: LIGHT_GREY_BACKGROUND,
                  textTransform: "capitalize",
                  fontSize: 14,
                  color: SPANISH_GREEN,
                  minWidth: "180px",
                  minHeight: "45px",
                  marginRight: 10,
                }}
                onClick={onClear}
              >
                {CLEAR}
              </Button>
            )}
            {addModel && (
              <Button
                style={{
                  backgroundColor: SPANISH_GREEN,
                  textTransform: "capitalize",
                  fontSize: 14,
                  color: WHITE,
                  minWidth: "180px",
                  minHeight: "45px",
                }}
                onClick={onFormSubmit}
              >
                {SAVE}
              </Button>
            )}
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                //backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={() => {
                navigate(PATH_MATERIAL_TRANSFER + "/" + location.state.date);
              }}
            >
              {CANCEL}
            </Button>
          </div>
          <div
            style={{
              backgroundColor: LIGHT_GREY_BACKGROUND,
              borderRadius: "5px",
              minWidth: "90%",
              minHeight: "50px",
              padding: 20,
              marginBottom: 10,
              borderStyle: "solid",
              border: "1x",
              borderColor: "#eaeaea",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography
                color={CHARLESTON_GREEN}
                style={{
                  fontWeight: 600,
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "left",
                }}
              >
                {TRANSACTION_NO} {dto.transactionNo}
              </Typography>
              <Typography
                color={CHARLESTON_GREEN}
                style={{
                  fontWeight: 600,
                  fontFamily: "Inter",
                  fontSize: "16px",
                  //fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "left",
                }}
              ></Typography>
            </div>
            <table style={{ width: "100%" }}>
              <TableBody>
                <tr>
                  <td>
                    {id > 0 ? (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...employeeProps}
                        id="auto-complete-1"
                        disabled={editModel}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="reportedBy"
                        value={employee}
                        isOptionEqualToValue={(o, v) => {
                          return o.value === v.value;
                        }}
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, reportedBy: v?.value });
                            else
                              setDTO({ ...dto, reportedBy: Number(v?.value) });
                          } else {
                            setDTO({ ...dto, reportedBy: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Reported By"
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...employeeProps}
                        id="auto-complete-1"
                        disabled={editModel}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="reportedBy"
                        defaultValue={
                          employeeProps.options.find(
                            (v) => v.value === dto.reportedBy
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, reportedBy: v?.value });
                            else
                              setDTO({ ...dto, reportedBy: Number(v?.value) });
                          } else {
                            setDTO({ ...dto, reportedBy: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Reported By"
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        disabled={editModel || id > 0 || disabled}
                        label="Transfer Date "
                        maxDate={dayjs()}
                        value={dto.transactionDate}
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        inputFormat="DD-MM-YYYY"
                        onChange={(newValue) => {
                          setDTO({
                            ...dto,
                            transactionDate: newValue.format("YYYY-MM-DD"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            variant="standard"
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                  <td>
                    {id > 0 ? (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...siteCategoryProps}
                        disabled={editModel || disabled}
                        id="auto-complete-2"
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="fromSiteCategory"
                        value={fromSiteCategory}
                        isOptionEqualToValue={(o, v) => {
                          return o.value === v.value;
                        }}
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, fromSiteCategory: v?.value });
                            else
                              setDTO({
                                ...dto,
                                fromSiteCategory: Number(v?.value),
                              });
                          } else {
                            setDTO({ ...dto, fromSiteCategory: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="From Site Category"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...siteCategoryProps}
                        disabled={editModel || disabled}
                        id="auto-complete-2"
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="fromSiteCategory"
                        defaultValue={
                          siteCategoryProps.options.find(
                            (v) => v.value === dto.fromSiteCategory
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, fromSiteCategory: v?.value });
                            else
                              setDTO({
                                ...dto,
                                fromSiteCategory: Number(v?.value),
                              });
                          } else {
                            setDTO({ ...dto, fromSiteCategory: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="From Site Category"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {id > 0 ? (
                      <Autocomplete
                        {...siteProps}
                        id="auto-complete-3"
                        disabled={editModel || disabled}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="fromSite"
                        filterOptions={(o, s) => {
                          let options = globalFilter(o, s);
                          let newOptions = [];
                          options.forEach((element) => {
                            if (element.dependentvalue === dto.fromSiteCategory)
                              newOptions.push(element);
                          });
                          return newOptions;
                        }}
                        value={fromSite}
                        isOptionEqualToValue={(o, v) => {
                          return o.value === v.value;
                        }}
                        onChange={(e, v) => {
                          if (v?.value === dto.toSite) {
                            setAlertTitleText(
                              "Source and destination sites cannot be idential"
                            );
                            return;
                          }

                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, fromSite: v?.value });
                            else setDTO({ ...dto, fromSite: Number(v?.value) });
                          } else {
                            setDTO({ ...dto, fromSite: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="From Site"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        {...siteProps}
                        id="auto-complete-3"
                        disabled={editModel || disabled}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="fromSite"
                        filterOptions={(o, s) => {
                          let options = globalFilter(o, s);
                          let newOptions = [];
                          options.forEach((element) => {
                            if (element.dependentvalue === dto.fromSiteCategory)
                              newOptions.push(element);
                          });
                          return newOptions;
                        }}
                        defaultValue={
                          siteProps.options.find(
                            (v) => v.value === dto.fromSite
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (v?.value === dto.toSite) {
                            setAlertTitleText(
                              "Source and destination sites cannot be idential"
                            );
                            return;
                          }

                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, fromSite: v?.value });
                            else setDTO({ ...dto, fromSite: Number(v?.value) });
                          } else {
                            setDTO({ ...dto, fromSite: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="From Site"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                  <td>
                    {id > 0 ? (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...siteCategoryProps}
                        id="auto-complete-4"
                        autoComplete
                        autoHighlight
                        autoSelect
                        disabled={editModel || disabled}
                        includeInputInList
                        name="toSiteCategory"
                        value={toSiteCategory}
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, toSiteCategory: v?.value });
                            else
                              setDTO({
                                ...dto,
                                toSiteCategory: Number(v?.value),
                              });
                          } else {
                            setDTO({ ...dto, toSiteCategory: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="To Site Category"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...siteCategoryProps}
                        id="auto-complete-4"
                        autoComplete
                        autoHighlight
                        autoSelect
                        disabled={editModel || disabled}
                        includeInputInList
                        name="toSiteCategory"
                        defaultValue={
                          siteCategoryProps.options.find(
                            (v) => v.value === dto.toSiteCategory
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, toSiteCategory: v?.value });
                            else
                              setDTO({
                                ...dto,
                                toSiteCategory: Number(v?.value),
                              });
                          } else {
                            setDTO({ ...dto, toSiteCategory: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="To Site Category"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                  <td>
                    {id > 0 ? (
                      <Autocomplete
                        {...siteProps}
                        id="auto-complete-5"
                        disabled={editModel || disabled}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="toSite"
                        filterOptions={(o, s) => {
                          let options = globalFilter(o, s);
                          let newOptions = [];
                          options.forEach((element) => {
                            if (element.dependentvalue === dto.toSiteCategory)
                              newOptions.push(element);
                          });
                          return newOptions;
                        }}
                        value={toSite}
                        onChange={(e, v) => {
                          if (dto.fromSite === v?.value) {
                            setAlertTitleText(
                              "Source and destination sites cannot be idential"
                            );
                            return;
                          }
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, toSite: v?.value });
                            else setDTO({ ...dto, toSite: Number(v?.value) });
                          } else {
                            setDTO({ ...dto, toSite: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="To Site"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        {...siteProps}
                        id="auto-complete-5"
                        disabled={editModel || disabled}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="toSite"
                        filterOptions={(o, s) => {
                          let options = globalFilter(o, s);
                          let newOptions = [];
                          options.forEach((element) => {
                            if (element.dependentvalue === dto.toSiteCategory)
                              newOptions.push(element);
                          });
                          return newOptions;
                        }}
                        defaultValue={
                          siteProps.options.find(
                            (v) => v.value === dto.toSite
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (dto.fromSite === v?.value) {
                            setAlertTitleText(
                              "Source and destination sites cannot be idential"
                            );
                            return;
                          }
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, toSite: v?.value });
                            else setDTO({ ...dto, toSite: Number(v?.value) });
                          } else {
                            setDTO({ ...dto, toSite: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="To Site"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextareaAutosize
                      variant="standard"
                      // disabled={viewModel}
                      disabled={editModel}
                      maxLength={128}
                      style={{
                        backgroundColor: LIGHT_GREY_BACKGROUND,
                        outlineColor: BLACK,
                        borderTop: "10px",
                        borderRight: "10px",
                        borderLeft: "10px",
                        marginTop: 15,
                        fontSize: 12,
                        fontFamily: "sans-serif",
                        minWidth: "200px",
                        minHeight: "33.5px",
                        width: "200px",
                        height: "33.5.5px",
                      }}
                      multiline="true"
                      maxRows={4}
                      minRows={2}
                      placeholder="Remarks"
                    ></TextareaAutosize>
                  </td>
                </tr>
              </TableBody>
            </table>
          </div>
        </form>
      </div>

      {/* below code is for purchase order dynamic row  addition deletion*/}
      {id > 0 ? (
        <div className="App">
          <div className="ag-theme-alpine" style={{ height: "570px" }}>
            <AgGridReact
              copyGroupHeadersToClipboard={true}
              enableRangeSelection={true}
              columnDefs={columnDefs}
              ref={gridRef}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              paginationAutoPageSize={true}
              pagination={true}
              rowSelection="multiple"
              getRowId={(params) => params.data.id}
              onSelectionChanged={onRowSelectionChanged}
            ></AgGridReact>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: WHITE,
            borderRadius: "5px",
            minWidth: "90%",
            minHeight: "50px",
            marginBottom: 10,
            border: "0.1px solid #eaeaea",
            display: "flex",
            flexDirection: "column",
            // width: "100%",
            overflowX: "auto",
          }}
        >
          <div
          // style={{
          //   width: "1000px",
          // }}
          >
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
              }}
            >
              <TableBody>
                <tr
                  style={{
                    backgroundColor: LIGHT_GREY_BACKGROUND,
                    borderBottom: "0.001pt solid #f0eff4",
                    borderTop: "0.001pt solid #f0eff4",
                  }}
                >
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {ITEM_CATEGORY}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {ITEM_NAME}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {GRN_NOS}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {TOTAL_STOCK}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {QTY}
                    </Typography>
                  </th>

                  <th></th>
                  <td></td>
                </tr>
                {rowItems?.map((item, index) => {
                  return (
                    <tr key={item.rowId}
                      style={{
                        borderBottom: "0.001pt solid #f0eff4",
                      }}
                    >
                      <td>
                        <Autocomplete
                          filterOptions={globalFilter}
                          id={`autocomplete-1-${index}`}
                          {...itemCategoryProps}
                          disabled={id > 0}
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          name="itemCategoryId"
                          defaultValue={
                            itemCategoryProps.options.find(
                              (v) => v.value === rowItems[index].itemCategoryId
                            ) || {}
                          }
                          onChange={(e, v) => {
                            setDisabled(true);
                            let newArr = [...rowItems];
                            newArr[index].itemCategoryId = Number(v?.value);
                            setRowItems(newArr);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Item Category"
                              variant="standard"
                              style={{
                                minWidth: "150px",
                                minHeight: "38.5px",
                                width: "150px",
                                height: "38.5px",
                                marginBottom: 5,
                                marginLeft: 5,
                              }}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 14 },
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <Autocomplete
                          id={`autocomplete-2-${index}`}
                          {...itemProps}
                          disabled={id > 0}
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          name="itemId"
                          defaultValue={
                            itemProps.options.find(
                              (v) => v?.value === rowItems[index].itemId
                            ) || {}
                          }
                          filterOptions={(o, s) => {
                            let options = globalFilter(o, s);
                            let newOptions = [];
                            options.forEach((element) => {
                              if (
                                element.dependentvalue ===
                                rowItems[index].itemCategoryId
                              )
                                newOptions.push(element);
                            });
                            return newOptions;
                          }}
                          onChange={(e, v) => {
                            let newArr = [...rowItems];
                            newArr[index].itemId = Number(v?.value);
                            const filteredItems = itemList.filter(
                              (x) => x.id === newArr[index].itemId
                            );
                            if (filteredItems.length > 0) {
                              uomList.forEach((element) => {
                                if (
                                  element.value ===
                                  filteredItems[0].purchaseUOMId
                                )
                                  newArr[index].uomName = element.name;
                              });
                            }

                            APIMATERIALMGMT.MaterialTransferGrn({
                              fromSite: dto.fromSite,
                              toSite: dto.toSite,
                              itemId: newArr[index].itemId,
                            })
                              .then((resp) => resp.json())
                              .then((resp) => {
                                if (resp.statusCode === 200) {
                                  newArr[index].grnOptions = resp.response.map(
                                    (x) => {
                                      return {
                                        name: x.grn.name,
                                        value: x.grn.value,
                                        itemId: x.itemId,
                                        stock: x.stock,
                                        rate: x.rate,
                                      };
                                    }
                                  );
                                  newArr[index].totalStock = 0;
                                  resp.response.forEach((x) => {
                                    newArr[index].totalStock += Number(x.stock);
                                  });
                                  setRowItems(newArr);
                                } else {
                                  setAlertTitleText(resp.message);
                                }
                              });
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Item Name"
                              variant="standard"
                              style={{
                                minWidth: "150px",
                                minHeight: "38.5px",
                                width: "150px",
                                height: "38.5px",
                                marginBottom: 5,
                                marginLeft: 5,
                              }}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 14 },
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <Autocomplete
                          id={`autocomplete-4-${index}`}
                          getOptionLabel={(option) => option?.name || ""}
                          disabled={id > 0}
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          name="grnDetailId"
                          options={rowItems[index].grnOptions}
                          defaultValue={
                            rowItems[index].grnOptions?.find(
                              (v) => v?.value === rowItems[index].grnDetailId
                            ) || {}
                          }
                          onChange={(e, v) => {
                            let newArr = [...rowItems];
                            newArr[index].grnDetailId = Number(v?.value);
                            newArr[index].grnNumber = v?.name;
                            newArr[index].totalStock = Number(v?.stock);
                            setRowItems(newArr);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="GRN No's"
                              variant="standard"
                              style={{
                                minWidth: "150px",
                                minHeight: "38.5px",
                                width: "150px",
                                height: "38.5px",
                                marginBottom: 5,
                                marginLeft: 5,
                              }}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 14 },
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <TextField
                          variant="standard"
                          disabled={true}
                          label="Total Stock"
                          
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "100px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "100px",
                            fontSize: 10,
                            marginBottom: 5,
                            marginLeft: 5,
                          }}
                          name="totalStock"
                          value={rowItems[index].totalStock}
                          onChange={(e) => {
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = (
                              e.target.value
                            );
                            setRowItems(newArr);
                          }}
                          onBlur={(e) => {
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = Number(
                              e.target?.value
                            );
                            setRowItems(newArr);
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {rowItems[index].uomName}
                              </InputAdornment>
                            ),
                            style: { fontSize: 14 },
                          }}
                        ></TextField>
                      </td>
                      <td>
                        <TextField
                          variant="standard"
                          disabled={id > 0}
                          label="Qty"
                          
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "100px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "100px",
                            fontSize: 10,
                            marginBottom: 5,
                            marginLeft: 5,
                          }}
                          name="qty"
                          value={rowItems[index].qty}
                          onChange={(e) => {
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = (
                              e.target.value
                            );
                            setRowItems(newArr);
                          }}
                          onBlur={(e) => {
                            if (e.target?.value > rowItems[index].totalStock) {
                              let newArr = [...rowItems];
                              newArr[index][e.target.name] = Number(
                                newArr[index].qty
                              );
                              setRowItems(newArr);
                              setAlertTitleText(
                                "Transfer quantity cannot be greater than total stock"
                              );
                              return;
                            }

                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = Number(
                              e.target?.value
                            );
                            setRowItems(newArr);
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            style: { fontSize: 14 },
                          }}
                        ></TextField>
                      </td>

                      <td>
                        {id <= 0 && index > 0 && (
                          <IconButton
                            style={{ marginTop: 10 }}
                            aria-label="minus"
                            onClick={() => {
                              removeObjectFromArray(item.rowId);
                            }}
                          >
                            <img src={minus_icon} alt="" />
                          </IconButton>
                        )}
                      </td>
                      <td>
                        {id <= 0 && (
                          <IconButton
                            style={{ marginTop: 10 }}
                            id={`button-plus-${index}`}
                            aria-label="plus"
                            onClick={() => {
                              addObjectToArray();
                            }}
                          >
                            <img src={plus_icon} alt="" />
                          </IconButton>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </TableBody>
            </table>
          </div>
        </div>
      )}
    </SideNav>
  );
}
