import React from "react";
import SideNav from "../../component/layouts/sideNav";
import { MASTER } from "../../resources/constant/strings/string";

export default function Home() {
  return (
    <SideNav
      currentComponent={MASTER}
      detailComponent=""
      subComponent=""
    ></SideNav>
  );
}
