import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  TableBody,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { APIMASTER } from "../../../../apihandler/master";
import { APIREPORTS } from "../../../../apihandler/reports";
import {
  closureStatusRenderer,
  getItemCategories,
  getItems,
  getSiteCategories,
  getSites,
  getSupplierCategories,
  getSuppliers,
  globalFilter,
  itemCategoryCellRenderer,
  itemCellRenderer,
  setItemCategories,
  setItems,
  setSiteCategories,
  setSites,
  setSupplierCategories,
  setSuppliers,
  siteCellRenderer,
  supplierCellRenderer,
} from "../../../../component/cellRenderers/renderers";
import SideNav from "../../../../component/layouts/sideNav";
import { setAlertTitleText } from "../../../../component/modal/alertModal";
import {
  LIGHT_GREY_BACKGROUND,
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";
import {
  EXPORT,
  GENERATE,
  MATERIAL_MANAGEMENT_REPORT,
  PURCHASE_ORDER_REPORT,
} from "../../../../resources/constant/strings/string";
import { PATH_PURCHASE_ORDER_REPORT } from "../../../../resources/constant/url/url";
import { APIUTILS } from "../../../../apihandler/utils";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  color: SPANISH_GREEN,
  transform: "translate(-50%, -50%)",
  zIndex: 2,
};

export default function PurchaseOrderReport() {
  const [itemDetails, setItemDetails] = useState([]);

  const [dataReady, setDataReady] = useState(true);
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    setDataReady(false);
    console.log("DTO: ", dto);

    APIREPORTS.PurchaseOrderReport(dto)
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode !== 200) {
          setDataReady(true);
          setAlertTitleText("Oops..", `An error occurred ${data.message}`);
          return;
        }

        setDataReady(true);
        try {
          const rowData = [];
          gridRef.current.api.forEachNode(function (node) {
            rowData.push(node.data);
          });
          gridRef.current.api.applyTransaction({
            remove: rowData,
          });
        } catch (e) {}

        data.response.itemDetails.forEach((x) => {
          const reqdItem = items.filter((y) => y.value === x.itemId);
          x.itemCategoryId =
            reqdItem.length > 0 ? reqdItem[0].dependentvalue : null;
          const reqdItemCategory = itemCategories.filter(
            (y) => y.value === x.itemCategoryId
          );
          x.itemCategory =
            reqdItemCategory.length > 0 ? reqdItemCategory[0].name : "";
          x.item = reqdItem.length > 0 ? reqdItem[0].name : "";
          const reqdSite = sites.filter((y) => y.value === x.customerId);
          x.siteCategoryId =
            reqdSite.length > 0 ? reqdSite[0].dependentvalue : null;
          const reqdSiteCategory = siteCategoryItems.filter(
            (y) => y.value === x.siteCategoryId
          );
          x.siteCategory =
            reqdSiteCategory.length > 0 ? reqdSiteCategory[0].name : "";
          x.site = reqdSite.length > 0 ? reqdSite[0].name : "";
          const reqdSupplier = suppliers.filter(
            (y) => y.value === x.supplierId
          );
          x.supplierCategoryId =
            reqdSupplier.length > 0 ? reqdSupplier[0].dependentvalue : null;
          const reqdSupplierCategory = supplierCategoryItems.filter(
            (y) => y.value === x.supplierCategoryId
          );
          x.supplierCategory =
            reqdSupplierCategory.length > 0 ? reqdSupplierCategory[0].name : "";
          x.supplier = reqdSupplier.length > 0 ? reqdSupplier[0].name : "";
        });

        data.response.itemDetails.forEach((x) => {
          if (x.status === "C" || x.status === "SC" || x.status === "MC") {
            x.balanceQty = 0.0;
          }
        });

        data.response.itemDetails.forEach((x) => {
          if (x.balanceQty < 0.0) x.balanceQty = 0.0;
        });

        setItemDetails((r) => [].concat(data.response.itemDetails));
        gridRef.current.api.applyTransaction({
          add: data.response.itemDetails,
        });
        setTimeout(() => {
          let pinnedBottomData = generatePinnedBottomData();
          gridRef.current.api.setPinnedBottomRowData([pinnedBottomData]);
        }, 2000);
      })
      .catch((e) => {
        setAlertTitleText("Oops", "An error occurred");
        setDataReady(true);
        return;
      });
  }

  const generatePinnedBottomData = () => {
    // generate a row-data with null values
    let result = {};

    gridRef.current.columnApi.getAllGridColumns().forEach((item) => {
      result[item.colId] = null;
    });
    return calculatePinnedBottomData(result);
  };

  const calculatePinnedBottomData = (target) => {
    let columnsWithAggregation = [
      "poDeliveryQty",
      "poValue",
      "purchaseQty",
      "purchaseValue",
      "balanceQty",
      "balanceValue",
    ];
    columnsWithAggregation.forEach((element) => {
      gridRef.current.api.forEachNodeAfterFilter((rowNode) => {
        if (element === "id") {
          target[element] = "Grand Total";
          return;
        }
        if (rowNode.data[element] || rowNode.data[element] === 0)
          target[element] += Number.parseFloat(
            APIUTILS.Financial(rowNode.data[element])
          );
      });
      if (target[element]) {
        target[element] = `${APIUTILS.Financial(target[element])}`;
      }
    });
    return target;
  };

  const [dto, setDTO] = useState({
    id: 0,
    supplierCategoryId: null,
    siteCategoryId: null,
    supplierId: null,
    siteId: null,
    itemCategoryId: null,
    itemId: null,
    isBillable: 0,
    fromDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    toDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });

  const [selectedRowId, setSelectedRowId] = useState(0);

  const supplierCategoryItems = getSupplierCategories();
  const siteCategoryItems = getSiteCategories();
  const suppliers = getSuppliers().filter((v) => v.name !== "Opening Stock");
  const sites = getSites();
  const itemCategories = getItemCategories();
  const items = getItems();
  const [isGridReady, setIsGridReady] = useState(false);

  useEffect(() => {
    if (isGridReady) {
      supplierCategoryItems
        .filter((x) => false)
        .concat(getSupplierCategories());
      siteCategoryItems.filter((x) => false).concat(getSiteCategories());
      suppliers
        .filter((x) => false)
        .concat(getSuppliers().filter((v) => v.name !== "Opening Stock"));
      sites.filter((x) => false).concat(getSites());
      itemCategories.filter((x) => false).concat(getItemCategories());
      items.filter((x) => false).concat(getItems());
    }
  }, [isGridReady]);

  const supplierCategoryProps = {
    options: supplierCategoryItems,
    getOptionLabel: (option) => option?.name || "",
  };
  const siteCategoryProps = {
    options: siteCategoryItems,
    getOptionLabel: (option) => option?.name || "",
  };

  const supplierProps = {
    options: suppliers,
    getOptionLabel: (option) => option?.name || "",
  };
  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };

  const itemCategoryProps = {
    options: itemCategories,
    getOptionLabel: (option) => option?.name || "",
  };

  const itemProps = {
    options: items,
    getOptionLabel: (option) => option?.name || "",
  };
  const gridRef = useRef();

  // below code is for grid - 1
  const columnDefs1 = [
    {
      headerName: "Supplier",
      field: "supplierId",
      valueGetter: (params) => {
        return supplierCellRenderer({ value: params.data.supplierId });
      },
      autoHeight: true,
      wrapText: true,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },

    {
      headerName: "Site Name",
      field: "siteName",
      valueGetter: (params) => {
        return siteCellRenderer({ value: params.data.siteId });
      },
      autoHeight: true,
      wrapText: true,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "PO No",
      field: "purchaseOrderNumber",

      autoHeight: true,
      wrapText: true,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "PO Date",
      field: "purchaseOrderDate",
      filter: false,
      floatingFilter: false,
      valueFormatter: (params) => {
        if (!params.value) return "";
        return dayjs(params.value).format("DD-MM-YYYY");
      },
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      autoHeight: true,
      wrapText: true,
    },
    {
      headerName: "Item Category",
      field: "itemCategoryId",
      autoHeight: true,
      wrapText: true,
      valueGetter: (params) => {
        return itemCategoryCellRenderer({
          value: params.data.itemCategoryId,
        });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Item Name",
      field: "itemId",
      valueGetter: (params) => {
        return itemCellRenderer({ value: params.data.itemId });
      },
      autoHeight: true,
      wrapText: true,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: " PO Qty",
      field: "poDeliveryQty",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "PO Value",
      field: "poValue",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Purchase Qty",
      field: "purchaseQty",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Purchase Value",
      field: "purchaseValue",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Status",
      field: "status",
      valueGetter: (params) => {
        return closureStatusRenderer({ value: params.data.status });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Balance Qty",
      field: "balanceQty",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
    {
      headerName: "Balance Value",
      field: "balanceValue",
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      filter: false,
      floatingFilter: false,
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
  };
  const onGridReady = (params) => {
    gridRef.current.api.setRowData([]);

    const p1 = APIMASTER.SupplierCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        console.log("Set supplier category props");
        setSupplierCategories(resp.response);
      });

    const p2 = APIMASTER.SiteCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSiteCategories(resp.response);
      });

    const p3 = APIMASTER.SupplierOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSuppliers(resp.response);
      });

    const p4 = APIMASTER.SiteOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSites(resp.response);
      });

    const p5 = APIMASTER.ItemCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setItemCategories(resp.response);
      });

    const p6 = APIMASTER.ItemOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setItems(resp.response);
      });

    Promise.all([p1, p2, p3, p4, p5, p6]).then(() => {
      setIsGridReady(true);
    });
  };

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId(event.api.getSelectedRows()[0].id);
    else setSelectedRowId(0);
  };
  // grid 1 code ends

  return (
    <SideNav
      currentComponent={MATERIAL_MANAGEMENT_REPORT}
      subComponent={PURCHASE_ORDER_REPORT}
      subComponentPath={PATH_PURCHASE_ORDER_REPORT}
      detailComponent=""
    >
      <div>
        <div style={style}>
          <ThreeCircles
            visible={!dataReady}
            height="256"
            width="256"
            outerCircleColor={SPANISH_GREEN}
            innerCircleColor="#eeeeee"
            middleCircleColor={SPANISH_GREEN}
            ariaLabel="eNirmaan-loading"
            color={SPANISH_GREEN}
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
        <form>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={onFormSubmit}
            >
              {GENERATE}
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                //backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={() => {
                let filename = "";
                dto.itemDetails = itemDetails;
                setDataReady(false);
                APIREPORTS.PurchaseOrderReportExport(dto)
                  .then((res) => {
                    setDataReady(true);

                    const contentType = res.headers.get("content-type");
                    if (contentType.startsWith("application/json")) {
                      return Promise.reject(res.json());
                    } else {
                      const header = res.headers.get("Content-Disposition");
                      if (header !== null) {
                        const parts = header.split(";");
                        filename = parts[1].split("=")[1].replaceAll('"', "");
                      } else {
                        filename = "Purchase Order Export Report.xlsx";
                      }

                      return res.blob();
                    }
                  })
                  .then((blob) => {
                    if (blob !== null) {
                      let url = window.URL.createObjectURL(blob);
                      let a = document.createElement("a");
                      a.href = url;
                      a.download = filename;
                      document.body.appendChild(a);
                      a.click();
                      a.remove();
                    }
                  })
                  .catch((m) => {
                    setAlertTitleText(
                      "Oops...",
                      `Something went wrong...${m.message}`
                    );
                  });
              }}
            >
              {EXPORT}
            </Button>
          </div>
          {/* button div ends */}
          {/* below code is for above container */}
          <div
            style={{
              backgroundColor: LIGHT_GREY_BACKGROUND,
              borderRadius: "5px",
              minWidth: "90%",
              minHeight: "50px",
              padding: 20,
              marginBottom: 10,
              borderStyle: "solid",
              border: "1x",
              borderColor: "#eaeaea",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <table style={{ width: "100%" }}>
              <TableBody>
                <tr>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="PO Date From "
                        inputFormat="DD-MM-YYYY"
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dto.fromDate}
                        onChange={(newValue) => {
                          setDTO({
                            ...dto,
                            fromDate: newValue.format("YYYY-MM-DD"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="PO Date To"
                        inputFormat="DD-MM-YYYY"
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dto.toDate}
                        onChange={(newValue) => {
                          setDTO({
                            ...dto,
                            toDate: newValue.format("YYYY-MM-DD"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                  <td>
                    <Autocomplete
                      filterOptions={globalFilter}
                      {...supplierCategoryProps}
                      options={supplierCategoryItems}
                      id="auto-complete-1"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="supplierCategoryId"
                      defaultValue={
                        supplierCategoryProps.options.find(
                          (v) => v.value === dto.supplierCategoryId
                        ) || {}
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, supplierCategoryId: v?.value });
                          else
                            setDTO({
                              ...dto,
                              supplierCategoryId: Number(v?.value),
                            });
                        } else {
                          setDTO({ ...dto, supplierCategoryId: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Supplier Category"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                            height: "48.5px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Autocomplete
                      {...supplierProps}
                      options={suppliers}
                      id="auto-complete-2"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="supplierId"
                      defaultValue={
                        supplierProps.options.find(
                          (v) => v.value === dto.supplierId
                        ) || {}
                      }
                      filterOptions={(o, s) => {
                        let options = globalFilter(o, s);
                        let newOptions = [];
                        options.forEach((element) => {
                          if (element.dependentvalue === dto.supplierCategoryId)
                            newOptions.push(element);
                        });
                        return newOptions;
                      }}
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, supplierId: v?.value });
                          else setDTO({ ...dto, supplierId: Number(v?.value) });
                        } else {
                          setDTO({ ...dto, supplierId: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Supplier"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            width: "200px",
                            minHeight: "48.5px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Autocomplete
                      filterOptions={globalFilter}
                      {...siteCategoryProps}
                      options={siteCategoryItems}
                      id="auto-complete-3"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="siteCategoryId"
                      defaultValue={
                        siteCategoryProps.options.find(
                          (v) => v.value === dto.siteCategoryId
                        ) || {}
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, siteCategoryId: v?.value });
                          else
                            setDTO({
                              ...dto,
                              siteCategoryId: Number(v?.value),
                            });
                        } else {
                          setDTO({ ...dto, siteCategoryId: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Site Category"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            width: "200px",
                            minHeight: "48.5px",
                            height: "48.5px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Autocomplete
                      {...siteProps}
                      id="auto-complete-4"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="siteId"
                      defaultValue={
                        siteProps.options.find((v) => v.value === dto.siteId) ||
                        {}
                      }
                      filterOptions={(o, s) => {
                        let options = globalFilter(o, s);
                        let newOptions = [];
                        options.forEach((element) => {
                          if (element.dependentvalue === dto.siteCategoryId)
                            newOptions.push(element);
                        });
                        return newOptions;
                      }}
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, siteId: v?.value });
                          else setDTO({ ...dto, siteId: Number(v?.value) });
                        } else {
                          setDTO({ ...dto, siteId: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Site"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  {/* <td>
                    <Autocomplete
                      {...itemCategoryProps}
                      id="auto-complete-5"
                      autoComplete autoHighlight autoSelect
                      includeInputInList
                      name="parentCategory"
                      onChange={(e, v) => {}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Parent Category"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td> */}
                  <td>
                    <Autocomplete
                      filterOptions={globalFilter}
                      {...itemCategoryProps}
                      id={`autocomplete-6`}
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="itemCategoryId"
                      defaultValue={
                        itemCategoryProps.options.find(
                          (v) => v.value === dto.itemCategoryId
                        ) || {}
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, itemCategoryId: v?.value });
                          else
                            setDTO({
                              ...dto,
                              itemCategoryId: Number(v?.value),
                            });
                        } else {
                          setDTO({ ...dto, itemCategoryId: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Item Category"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "38.5px",
                            width: "200px",
                            height: "38.5px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Autocomplete
                      {...itemProps}
                      id={`autocomplete-7`}
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="itemId"
                      defaultValue={itemProps.options.find(
                        (v) => v.value === dto.itemId
                      )}
                      filterOptions={(o, s) => {
                        let options = globalFilter(o, s);
                        let newOptions = [];
                        options.forEach((element) => {
                          if (element.dependentvalue === dto.itemCategoryId)
                            newOptions.push(element);
                        });
                        return newOptions;
                      }}
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, itemId: v?.value });
                          else setDTO({ ...dto, itemId: Number(v?.value) });
                        } else {
                          setDTO({ ...dto, itemId: v?.value });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Item"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "38.5px",
                            width: "200px",
                            height: "38.5px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <FormControlLabel
                      name="showCreditDetails"
                      value={dto.isBillable === 1}
                      checked={dto.isBillable === 1}
                      onChange={(e, v) =>
                        setDTO({
                          ...dto,
                          isBillable: e.target.checked ? 1 : 0,
                        })
                      }
                      control={<Checkbox color="success" />}
                      label="Is Billable"
                      style={{ userSelect: "none" }}
                    />
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    <Autocomplete
                      {...supplierProps}
                      id="auto-complete-8"
                      autoComplete autoHighlight autoSelect
                      includeInputInList
                      name="groupBy"
                      onChange={(e, v) => {}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Group By"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Autocomplete
                      {...supplierProps}
                      id="auto-complete-9"
                      autoComplete autoHighlight autoSelect
                      includeInputInList
                      name="isBillable"
                      onChange={(e, v) => {}}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Is Billable"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                </tr> */}
              </TableBody>
            </table>
          </div>
        </form>
      </div>
      {/* horizontal scroll div */}
      <div
        style={{
          //display:"flex",
          scrollbarWidth: "",
          overflowX: "auto",
          width: "100%",
        }}
      >
        {/* below code is for ag-grid -1 */}
        <div className="App">
          <div
            className="ag-theme-alpine"
            style={{ height: "570px", width: "1800px" }}
          >
            <AgGridReact
              copyGroupHeadersToClipboard={true}
              enableRangeSelection={true}
              columnDefs={columnDefs1}
              ref={gridRef}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              paginationAutoPageSize={true}
              pagination={true}
              onSelectionChanged={onRowSelectionChanged}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </SideNav>
  );
}
