export const SPANISH_GREEN = "#006FB6"; //"#009743"
export const WHITE = "#ffffff";
export const CHARLESTON_GREEN = "#282828"; //"#282828"
export const PRUSSIAN_BLUE = "#073150";
export const CADET_GREY = "#9aa0b3";
export const TRANSPARENT_WHITE_FOR_NAVIGATION_DIV = "#ffffff33";
export const BLACK = "#000000";
export const TRANSPARENT_WHITE_FOR_SUB_DIV = "#ffffff1a";
export const LIGHT_GREY_BACKGROUND = "#f8f9fd";
export const SPANISH_GRAY = "#9C9C9C";
export const WARM_GRAY = "#707070";
export const METALIC_RED = "#ae282e";
export const GHOST_WHITE = "#FDFCF8";
export const ANTI_FLASH_WHITE = "#f3f3f3";
