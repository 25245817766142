import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableBody,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";

import { APIMASTER } from "../../../../apihandler/master";
import { setAlertTitleText } from "../../../../component/modal/alertModal";
import "./modal.css";

function Modal({
  setOpenModal,
  model,
  formTitle,
  updateModel,
  addModel,
  viewModel,
}) {
  const [errorDesignation, setErrorDesignation] = useState("");
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    //only required so direct api call
    if (!errorDesignation) {
      if (dto.id <= 0)
        APIMASTER.AddEmployeeDesignation(dto)
          .then((response) => response.json())
          .then((data) => {
            if (data.statusCode === 200) {
              addModel(data.response);
              setAlertTitleText(
                "Success",
                "The record was inserted successfully"
              );
              setOpenModal(false);
            } else {
              setAlertTitleText(
                "Oops...",
                `Something went wrong... ${data.message}`
              );
            }
          });
      else
        APIMASTER.UpdateEmployeeDesignation(dto.id, dto)
          .then((response) => response.json())
          .then((data) => {
            if (data.statusCode === 200) {
              updateModel(data.response);
              setAlertTitleText(
                "Success",
                "The record was updated successfully"
              );
              setOpenModal(false);
            } else {
              setAlertTitleText(
                "Oops...",
                `Something went wrong...${data.message}`
              );
            }
          });
    }
  }

  const [dto, setDTO] = useState(model);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (isNaN(value) || name === "description" || name === "designation")
        setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    } else {
      setDTO({ ...dto, [name]: value });
    }
  };

  return (
    <div className="modalBackgroundForDesignation">
      {/* div for top right close button */}
      <form>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton
            style={{ width: "auto", align: "center", marginRight: 5 }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* top right close button div closed */}

        <div
          style={{
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <table style={{ width: "100%", margin: 5 }}>
            <TableBody>
              <tr>
                <td>
                  <Typography
                    fontSize={18}
                    fontWeight={600}
                    fontspacing={"normal"}
                    textTransform="capitalize"
                    align="left"
                  >
                    {formTitle}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    inputProps={{
                      maxlength: 128,
                    }}
                    variant="standard"
                    label="Designation"
                    disabled={viewModel}
                    required
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    name="designation"
                    value={dto.designation}
                    error={!!errorDesignation}
                    helperText={errorDesignation}
                    // onChange={handleInputChange}
                    onChange={(e) => {
                      if (e.target.value.length > 64) {
                        setErrorDesignation("Designation too long");
                      } else if (e.target.value.length <= 0) {
                        setErrorDesignation("Designation cannot be blank");
                      } else {
                        setErrorDesignation("");
                      }
                      handleInputChange(e);
                    }}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
                <td>
                  <FormControlLabel
                    name="isManager"
                    value={dto.isManager}
                    control={<Checkbox color="success" />}
                    label="Manager"
                    checked={dto.isManager}
                    onChange={(e) => {
                      setDTO({
                        ...dto,
                        isManager: e.target.checked ? 1 : 0,
                      });
                    }}
                    style={{ userSelect: "none" }}
                  />
                </td>
              </tr>
            </TableBody>
          </table>
        </div>

        {/* div for buttons */}
        {!viewModel ? (
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              marginTop: 10,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={onFormSubmit}
            >
              Save
            </Button>
          </div>
        ) : (
          <div />
        )}
        {/* div for buttons closed */}
      </form>
      {/* pop up div closed  */}
    </div>
  );
}

export default Modal;
