import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getItemList,
  getSites,
  itemCategoryCellRenderer,
  itemCellRenderer,
  uomCellRenderer,
} from "../../../../../component/cellRenderers/renderers";
import SideNav from "../../../../../component/layouts/sideNav";
import {
  CUSTOMER_MANAGEMENT,
  INVOICE,
  INVOICE_DETAIL,
} from "../../../../../resources/constant/strings/string";

import dayjs from "dayjs";
import { ThreeCircles } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { APILOGIN } from "../../../../../apihandler/login";
import { APISALES } from "../../../../../apihandler/sales";
import { APIUTILS } from "../../../../../apihandler/utils";
import {
  PATH_INVOICE,
  PATH_LOGIN,
} from "../../../../../resources/constant/url/url";
import InvoiceDetailComponent from "./invoiceDetailComponent";
import { SPANISH_GREEN } from "../../../../../resources/constant/colors/theme";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  color: SPANISH_GREEN,
  transform: "translate(-50%, -50%)",
  zIndex: 2,
};

export default function InvoiceDetail() {
  let { id } = useParams();

  const [dataReady, setDataReady] = useState(false);

  //below code is commented because by default side nav is not collapsed for now
  //const [SidebarStatus, setSidebarStatus] = useState(false);
  console.log("Invoice order id", id);

  const navigate = useNavigate();

  const gridRef = useRef();

  useEffect(() => {
    if (!APILOGIN.GetIsLoggedIn()) navigate(PATH_LOGIN);
  }, []);

  let initialValue = {
    id: 0,
    customerId: 0,
    categoryId: 0,
    invoiceNo: "",
    invoiceDate: dayjs().format("YYYY-MM-DD"),
    salesOrderDate: dayjs().format("YYYY-MM-DD"),
    salesOrderId: 0,
    totalSupplyAmount: 0.0,
    totalInstallationAmount: 0.0,
    totalAmount: 0.0,
    grandTotal: 0.0,
    invoiceDetail: [],
    paymentTermsQty: [],
  };

  const [formData, setFormData] = useState(initialValue);

  const [paymentTerms, setPaymentTerms] = useState({
    supplyTerms1: 0,
    supplyTerms2: 0,
    supplyTerms3: 0,
    supplyTerms4: 0,
    installationTerms1: 0,
    installationTerms2: 0,
    installationTerms3: 0,
    installationTerms4: 0,
  });

  const [selectedRowId, setSelectedRowId] = useState(0);

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId(event.api.getSelectedRows()[0].id);
    else setSelectedRowId(0);
  };

  const columnDefs = [
    {
      headerName: "WORK ORDER DETAILS",

      children: [
        {
          headerName: "",
          width: 400,
          children: [
            {
              flex: 2,
              headerName: "Category",
              field: "itemCategory",
              valueGetter: (params) => {
                return itemCategoryCellRenderer({
                  value: params.data.categoryId,
                });
              },
              wrapText: true,
              autoHeight: true,
              cellStyle: {
                "border-right-color": "#e2e2e2",
              },
            },
            {
              flex: 2,
              headerName: "Item",
              field: "itemId",
              valueGetter: (params) => {
                return itemCellRenderer({ value: params.data.itemId });
              },
              wrapText: true,
              autoHeight: true,
              cellStyle: {
                "border-right-color": "#e2e2e2",
              },
            },

            {
              flex: 1.5,
              headerName: "Qty",
              field: "uomId",

              valueGetter: (params) => {
                return (
                  params.data.qty +
                  "  " +
                  uomCellRenderer({ value: params.data.uomId })
                );
              },
              cellStyle: {
                "border-right-color": "#e2e2e2",
              },
            },
            {
              flex: 2,
              headerName: "Supply Rate",
              field: "supplyRate",
              filter: false,
              floatingFilter: false,
              cellStyle: {
                "text-align": "center",
                "border-right-color": "#e2e2e2",
              },
            },
            {
              flex: 2,
              headerName: "Inst Rate",
              field: "installationRate",
              filter: false,
              floatingFilter: false,
              cellStyle: {
                "text-align": "center",
                "border-right-color": "#e2e2e2",
              },
            },
          ],
        },
      ],
    },
    {
      headerName: "SEPL BILLED QTY",

      children: [
        {
          headerName: "SUPPLY QTY",
          children: [
            {
              headerValueGetter: (params) => `${paymentTerms.supplyTerms1}%`,
              field: "supplyQty1",
              filter: false,
              floatingFilter: false,
              cellStyle: {
                "text-align": "center",
                "border-right-color": "#e2e2e2",
              },
            },
            {
              headerValueGetter: (params) => `${paymentTerms.supplyTerms2}%`,
              field: "supplyQty2",
              filter: false,
              floatingFilter: false,
              cellStyle: {
                "text-align": "center",
                "border-right-color": "#e2e2e2",
              },
            },
            {
              headerValueGetter: (params) => `${paymentTerms.supplyTerms3}%`,
              field: "supplyQty3",
              filter: false,
              floatingFilter: false,
              cellStyle: {
                "text-align": "center",
                "border-right-color": "#e2e2e2",
              },
            },
            {
              headerValueGetter: (params) => `${paymentTerms.supplyTerms4}%`,
              field: "supplyQty4",
              filter: false,
              floatingFilter: false,
              cellStyle: {
                "text-align": "center",
                "border-right-color": "#e2e2e2",
              },
            },
          ],
        },
        {
          headerName: "INST QTY",

          children: [
            {
              headerValueGetter: (params) =>
                `${paymentTerms.installationTerms1}%`,
              field: "installationQty1",
              filter: false,
              floatingFilter: false,
              cellStyle: {
                "text-align": "center",
                "border-right-color": "#e2e2e2",
              },
            },
            {
              headerValueGetter: (params) =>
                `${paymentTerms.installationTerms2}%`,
              field: "installationQty2",
              filter: false,
              floatingFilter: false,
              cellStyle: {
                "text-align": "center",
                "border-right-color": "#e2e2e2",
              },
            },
            {
              headerValueGetter: (params) =>
                `${paymentTerms.installationTerms3}%`,
              field: "installationQty3",
              filter: false,
              floatingFilter: false,
              cellStyle: {
                "text-align": "center",
                "border-right-color": "#e2e2e2",
              },
            },
            {
              headerValueGetter: (params) =>
                `${paymentTerms.installationTerms4}%`,
              field: "installationQty4",
              filter: false,
              floatingFilter: false,
              cellStyle: {
                "text-align": "center",
                "border-right-color": "#e2e2e2",
              },
            },
          ],
        },
      ],
    },
    {
      headerName: "BILLED AMOUNT",
      children: [
        {
          headerName: "SUPPLY AMT",
          children: [
            {
              headerValueGetter: (params) => `${paymentTerms.supplyTerms1}%`,
              field: "supplyAmt1",
              filter: false,
              floatingFilter: false,
              cellStyle: {
                "text-align": "center",
                "border-right-color": "#e2e2e2",
              },
            },
            {
              headerValueGetter: (params) => `${paymentTerms.supplyTerms2}%`,
              field: "supplyAmt2",
              filter: false,
              floatingFilter: false,
              cellStyle: {
                "text-align": "center",
                "border-right-color": "#e2e2e2",
              },
            },
            {
              headerValueGetter: (params) => `${paymentTerms.supplyTerms3}%`,
              field: "supplyAmt3",
              filter: false,
              floatingFilter: false,
              cellStyle: {
                "text-align": "center",
                "border-right-color": "#e2e2e2",
              },
            },
            {
              headerValueGetter: (params) => `${paymentTerms.supplyTerms4}%`,
              field: "supplyAmt4",
              filter: false,
              floatingFilter: false,
              cellStyle: {
                "text-align": "center",
                "border-right-color": "#e2e2e2",
              },
            },
          ],
        },
        {
          headerName: "INST AMT",
          children: [
            {
              headerValueGetter: (params) =>
                `${paymentTerms.installationTerms1}%`,
              field: "installationAmt1",
              filter: false,
              floatingFilter: false,
              cellStyle: {
                "text-align": "center",
                "border-right-color": "#e2e2e2",
              },
            },
            {
              headerValueGetter: (params) =>
                `${paymentTerms.installationTerms2}%`,
              field: "installationAmt2",
              filter: false,
              floatingFilter: false,
              cellStyle: {
                "text-align": "center",
                "border-right-color": "#e2e2e2",
              },
            },
            {
              headerValueGetter: (params) =>
                `${paymentTerms.installationTerms3}%`,
              field: "installationAmt3",
              filter: false,
              floatingFilter: false,
              cellStyle: {
                "text-align": "center",
                "border-right-color": "#e2e2e2",
              },
            },
            {
              headerValueGetter: (params) =>
                `${paymentTerms.installationTerms4}%`,
              field: "installationAmt4",
              filter: false,
              floatingFilter: false,
              cellStyle: {
                "text-align": "center",
                "border-right-color": "#e2e2e2",
              },
            },
          ],
        },
      ],
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
  };

  const onOrderChanged = (data) => {
    setPaymentTerms(data);
    try {
      setTimeout(() => {
        gridRef.current.api.refreshHeader();
      }, 3000);
    } catch (e) {
      console.log(e);
    }
  };

  const onAdd = (data) => {
    let detailData = data;

    const items = getItemList();

    console.log("Items list count", items.length);

    detailData.forEach((e) => {
      if (!e.itemCode) {
        let item = items.filter((v) => {
          return v.id === e.itemId;
        });
        e.itemCode = item.length > 0 ? item[0].itemCode : 0;
        e.categoryId = item.length > 0 ? item[0].categoryId : 0;
      }
      e.supplyAmt1 = APIUTILS.Financial(e.supplyRate * e.supplyQty1);
      e.supplyAmt2 = APIUTILS.Financial(e.supplyRate * e.supplyQty2);
      e.supplyAmt3 = APIUTILS.Financial(e.supplyRate * e.supplyQty3);
      e.supplyAmt4 = APIUTILS.Financial(e.supplyRate * e.supplyQty4);
      e.installationAmt1 = APIUTILS.Financial(
        e.installationRate * e.installationQty1
      );
      e.installationAmt2 = APIUTILS.Financial(
        e.installationRate * e.installationQty2
      );
      e.installationAmt3 = APIUTILS.Financial(
        e.installationRate * e.installationQty3
      );
      e.installationAmt4 = APIUTILS.Financial(
        e.installationRate * e.installationQty4
      );
    });
    try {
      const rowData = [];
      gridRef.current.api.forEachNode(function (node) {
        rowData.push(node.data);
      });
      gridRef.current.api.applyTransaction({
        remove: rowData,
      });
    } catch (e) {}
    try {
      gridRef.current.api.applyTransaction({ add: detailData });
    } catch (e) {}
    return detailData;
  };

  const onGridReady = (params) => {
    // // below code is to have auto size for columns
    // const allColumnIds = [];
    // gridRef.current.columnApi.getColumns().forEach((column) => {
    //   allColumnIds.push(column.getId());
    // });
    // gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    // //auto size column code ends, false is to skip header autosize to true or false

    if (id <= 0) {
      params.api.setRowData([]);
      setDataReady(true);
      return;
    }

    APISALES.SalesInvoiceDetail(id)
      .then((resp) => resp.json())
      .then((resp) => {
        const sites = getSites();
        const selectedCustomer = sites.filter(
          (v, i) => v.value === resp.response.customerId
        );

        resp.response.invoiceDetail = resp.response.invoiceDetail.sort(
          (a, b) => b.id - a.id
        );

        let detailData = onAdd(resp.response.invoiceDetail);
        let totalSupplyAmount = 0.0;
        let totalInstallationAmount = 0.0;
        let totalAmount = 0.0;

        detailData.forEach((e) => {
          totalSupplyAmount += Number.parseFloat(
            APIUTILS.Financial(
              e.supplyAmount1 +
                e.supplyAmount2 +
                e.supplyAmount3 +
                e.supplyAmount4
            )
          );
          totalInstallationAmount += Number.parseFloat(
            APIUTILS.Financial(
              e.installationAmount1 +
                e.installationAmount2 +
                e.installationAmount3 +
                e.installationAmount4
            )
          );
        });

        let paymentTermsQty = [];
        detailData.forEach((e) => {
          paymentTermsQty.push({
            itemId: e.itemId,
            invoiceId: resp.response.id,
            supplyQty1: e.supplyQty1,
            supplyQty2: e.supplyQty2,
            supplyQty3: e.supplyQty3,
            supplyQty4: e.supplyQty4,
            installationQty1: e.installationQty1,
            installationQty2: e.installationQty2,
            installationQty3: e.installationQty3,
            installationQty4: e.installationQty4,
          });
        });

        totalAmount = APIUTILS.Financial(
          totalSupplyAmount + totalInstallationAmount
        );

        initialValue = {
          id: resp.response.id,
          customerId: resp.response.customerId,
          categoryId:
            selectedCustomer.length > 0
              ? selectedCustomer[0].dependentvalue
              : 0,
          invoiceNo: resp.response.invoiceNo,
          invoiceDate: resp.response.invoiceDate.split("-").reverse().join("-"),
          salesOrderId: resp.response.salesOrderId,
          totalSupplyAmount: APIUTILS.Financial(totalSupplyAmount),
          totalInstallationAmount: APIUTILS.Financial(totalInstallationAmount),
          totalAmount: totalAmount,
          grandTotal: totalAmount,
          invoiceDetail: detailData,
          paymentTermsQty: paymentTermsQty,
        };

        setFormData(initialValue);
        return Promise.resolve(resp.response.salesOrderId);
      })
      .then((x) => {
        APISALES.SalesOrderDetail(x)
          .then((resp) => resp.json())
          .then((json) => {
            onOrderChanged(json.response.paymentTerms);
            setDataReady(true);
          });
      });
    const allColumnIds = [];
    params.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });

    //params.columnApi.autoSizeColumns(allColumnIds, false);
  };

  return (
    <SideNav
      currentComponent={CUSTOMER_MANAGEMENT}
      subComponent={INVOICE}
      subComponentPath={PATH_INVOICE}
      detailComponent={INVOICE_DETAIL}
      //sideBarOpenStatus={SidebarStatus}
    >
      {dataReady ? (
        <InvoiceDetailComponent
          model={formData}
          onAdd={onAdd}
          onOrderChanged={onOrderChanged}
          addModel={id <= 0}
          editModel={id > 0}
        ></InvoiceDetailComponent>
      ) : (
        <div style={style}>
          <ThreeCircles
            visible="true"
            height="256"
            width="256"
            outerCircleColor={SPANISH_GREEN}
            innerCircleColor="#eeeeee"
            middleCircleColor={SPANISH_GREEN}
            ariaLabel="eNirmaan-loading"
            color={SPANISH_GREEN}
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
      {/* delete , add button div ends */}

      <div
        style={{
          //display:"flex",
          scrollbarWidth: "",
          overflowX: "auto",
          width: "100%",
        }}
      >
        {/* aggrid div start */}

        <div className="App">
          <div
            className="ag-theme-alpine"
            style={{
              height: "570px",
              width: "2500px",
            }}
          >
            <AgGridReact
              copyGroupHeadersToClipboard={true}
              enableRangeSelection={true}
              columnDefs={columnDefs}
              ref={gridRef}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              paginationAutoPageSize={true} pagination={true}
              rowSelection="multiple"
              onSelectionChanged={onRowSelectionChanged}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </SideNav>
  );
}
