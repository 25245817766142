import { Constants } from "./constants";

export const APILABOURMGMT = {
  WorkOrders: (d1, d2) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/workorders?date=${d1}&toDate=${d2}&page=0&pageSize=100000`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  SubContractorBillingExport: (id, exportExcel) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/subcontractorbilling/print/${id}?exportExcel=${exportExcel}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  FoodAdvancePDF: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/foodadvance/print/${id}`, {
      method: "GET",
      credentials: "include",
    });
  },

  AddWorkOrder: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/workorder`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateWorkOrder: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/workorder/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  WorkOrder: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/workorder/${id}`, {
      method: "GET",
      credentials: "include",
    });
  },
  WorkOrderDetailOt: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/workorder/otrate`, {
      method: "GET",
      credentials: "include",
    });
  },
  WorkOrderOptions: () => {
    return fetch(`${Constants.API_SERVER()}/api/v1/workorders/options`, {
      method: "GET",
      credentials: "include",
    });
  },
  DeleteWorkOrder: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/workorder/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  DailyWorkEntries: (d1, d2) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/dailyworks?date=${d1}&toDate=${d2}&page=0&pageSize=100000`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  DailyWorkEntry: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/dailywork/${id}`, {
      method: "GET",
      credentials: "include",
    });
  },
  DeleteDailyWorkEntry: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/dailywork/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  AddDailyWorkEntry: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/dailywork`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateDailyWorkEntry: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/dailywork/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  FoodAdvances: (d1, d2) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/foodadvances?date=${d1}&toDate=${d2}&page=0&pageSize=100000`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  FoodAdvance: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/foodadvance/${id}`, {
      method: "GET",
      credentials: "include",
    });
  },
  DeleteFoodAdvance: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/foodadvance/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  GenerateFoodAdvance: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/foodadvance/generate`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  AddFoodAdvance: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/foodadvance`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateFoodAdvance: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/foodadvance/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  SubcontractorBillings: (d1, d2) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/subcontactorbillings?date=${d1}&toDate=${d2}&page=0&pageSize=100000`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  SubContractorBilling: (id) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/subcontractorbilling/${id}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  DeleteSubContractorBilling: (id) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/subcontractorbilling/${id}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );
  },
  AddSubcontractorBilling: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/subcontractorbilling`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateSubcontractorBilling: (id, data) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/subcontractorbilling/${id}`,
      {
        method: "PUT",
        body: JSON.stringify(data),
        credentials: "include",
      }
    );
  },
  SubContractorAdvances: (d1, d2) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/subcontractoradvances?date=${d1}&toDate=${d2}&page=0&pageSize=100000`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  GenerateSubContractorAdvances: (data) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/subcontractoradvance/generate`,
      {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
      }
    );
  },
  SubContractorAdvance: (id) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/subcontractoradvance/${id}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  SubContractorAdvanceExport: (data) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/subcontractoradvance/print`,
      {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
      }
    );
  },
  AddSubContractorAdvance: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/subcontractoradvance`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateSubContractorAdvance: (id, data) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/subcontractoradvance/${id}`,
      {
        method: "PUT",
        body: JSON.stringify(data),
        credentials: "include",
      }
    );
  },
  DeleteSubContractorAdvance: (id) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/subcontractoradvance/${id}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );
  },
  SubcontractorBillingOptions: () => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/subcontactorbilling/options`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  GenerateSubcontractorBilling: (id, data) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/subcontractorbilling/generate/${id}`,
      {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
      }
    );
  },
  ManagerAssignments: () => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/managerassignments?page=0&pageSize=100000`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  AddManagerAssignment: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/managerassignment`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateManagerAssignment: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/managerassignment/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  DeleteManagerAssignment: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/managerassignment/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
};
