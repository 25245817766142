import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Button,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../src/resources/assets/images/newLogo.svg";
import { setAlertTitleText } from "../../component/modal/alertModal";
import { useAuth } from "../../hooks/useAuth";
import {
  CHARLESTON_GREEN,
  SPANISH_GREEN,
  WHITE,
} from "../../resources/constant/colors/theme";
import {
  FORGOT_PASSWORD,
  LOGIN,
  REMEMBER_ME,
  SIGN_IN,
} from "../../resources/constant/strings/string";
import { PATH_DASHBOARD } from "../../resources/constant/url/url";
import styles from "./login.module.css";

export default function Login() {
  const [visible, setVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorUsername, setErorUser] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const { login } = useAuth();
  const { state } = useLocation();
  const navigate = useNavigate();

  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();

    //
  };
  function validation() {
    if (username.length < 4) {
      setErorUser("Please enter minimum 4 characters");
    } else {
      login(username, password)
        .then(() => {
          navigate(state?.path || PATH_DASHBOARD);
        })
        .catch((e) => {
          setAlertTitleText("Sorry " + username + ",", "Cannot log you in!");
        });
    }
  }

  return (
    <div className={styles.main}>
      <div style={{ display: "flex", marginTop: 30, marginLeft: 30 }}>
        <img
          src={logo}
          alt=""
          style={{ height: 50, width: 200, objectFit: "fill" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "87vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={20} fontWeight="600" color={CHARLESTON_GREEN}>
              {LOGIN}
            </Typography>
            <div style={{ marginTop: 20 }}>
              <TextField
                variant="standard"
                label="Username"
                style={{ minWidth: 350 }}
                onChange={(e) => {
                  setUsername?.(e.target.value);
                  setErorUser?.("");
                }}
                helperText={errorUsername}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <TextField
                style={{ minWidth: 350 }}
                variant="standard"
                label="Password"
                value={password}
                helperText={errorPassword}
                onChange={(e) => {
                  setPassword?.(e.target.value);
                  setErrorPassword?.("");
                }}
                type={visible ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setVisible?.(!visible);
                        }}
                        size="small"
                        aria-label="toggle password"
                        edge="end"
                        style={{ paddingRight: 15 }}
                      >
                        {visible === true ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              minWidth: 350,
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Switch />
              <Typography fontSize={12}>{REMEMBER_ME}</Typography>
            </div>
            <div>
              <Button
                style={{
                  fontSize: 12,
                  color: SPANISH_GREEN,
                  textTransform: "capitalize",
                  margin: 0,
                  padding: 0,
                }}
              >
                {FORGOT_PASSWORD}
              </Button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              maxWidth: 350,
              width: "100%",
              marginTop: 20,
            }}
          >
            <Button
              fullWidth
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "100%",
                minHeight: 45,
                opacity: username && password ? 1 : 0.5,
              }}
              disabled={username && password ? false : true}
              onClick={onFormSubmit}
            >
              {SIGN_IN}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
