import React, { useEffect, useState } from "react";
import SideNav from "../../component/layouts/sideNav";
import {
  BALANCE_BILLING,
  BILLED_TILL_DATE,
  GO,
  TOTAL,
  TOTAL_LABOUR_BILLED_EXCAVATOR,
  TOTAL_PURCHASE,
  TOTAL_SUB_CONTRACTOR_BILLED,
} from "../../resources/constant/strings/string";

import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TableBody,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { APIDASHBOARD } from "../../apihandler/dashboard";
import { APIMASTER } from "../../apihandler/master";
import {
  getSiteCategories,
  getSites,
  globalFilter,
  setSiteCategories,
  setSites,
} from "../../component/cellRenderers/renderers";
import { setAlertTitleText } from "../../component/modal/alertModal";
import {
  CHARLESTON_GREEN,
  LIGHT_GREY_BACKGROUND,
  SPANISH_GREEN,
  WHITE,
} from "../../resources/constant/colors/theme";
import { DASHBOARD } from "../../resources/constant/strings/string";

export default function Home() {
  const fiscalYear = (date, opt_monthStart) => {
    opt_monthStart = opt_monthStart === undefined ? 3 : opt_monthStart;
    var month = date.getMonth(),
      year = date.getFullYear(),
      yearOffset = Math.floor((month - (opt_monthStart % 12 || 12)) / 12);

    return yearOffset + year;
  };

  const [dto, setDTO] = useState({
    totalSubContractor: 0,
    balanceBilling: 0,
    billedTillDate: 0,
    siteCategoryId: 0,
    siteId: 0,
    total: 0,
    totalLabour: 0,
    totalPurchase: 0,
    fromDate: dayjs(new Date(fiscalYear(new Date()), 3, 1)).format(
      "YYYY-MM-DD"
    ),
    toDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    console.log("DTO", dto);
    APIDASHBOARD.Dashboard(dto)
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode !== 200) {
          setAlertTitleText(data.message);
          return;
        }
        setDTO({
          ...dto,
          totalSubContractor: data.response.totalSubContractor,
          balanceBilling: data.response.balanceBilling,
          billedTillDate: data.response.billedTillDate,
          siteCategoryId: data.response.siteCategoryId,
          siteId: data.response.siteId,
          total: data.response.total,
          totalLabour: data.response.totalLabour,
          totalPurchase: data.response.totalPurchase,
        });
      });
    console.log("DTO", dto);
  }

  useEffect(() => {
    APIMASTER.SiteOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSites(resp.response);
      });
    APIMASTER.SiteCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSiteCategories(resp.response);
      });
  }, []);

  const siteCategoryItems = getSiteCategories();
  const sites = getSites();
  const siteCategoryProps = {
    options: siteCategoryItems,
    getOptionLabel: (option) => option?.name || "",
  };
  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };
  const [projectValue, setProjectValue] = React.useState("All Projects");
  const handleProjectChange = (event) => {
    setProjectValue(event.target.value);
  };
  const [filterValue, setFilterValue] = React.useState("From April upto Date");
  const handleFilterChange = (event) => {
    setFilterValue(event.target.value);
  };
  return (
    <SideNav
      currentComponent={DASHBOARD}
      subComponent={DASHBOARD}
      //subComponentPath={PATH_DASHBOARD}
      detailComponent=""
    >
      <div>
        <form>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={onFormSubmit}
            >
              {GO}
            </Button>
          </div>
          {/* button div ends */}
          {/* below code is for above container */}
          <div
            style={{
              backgroundColor: LIGHT_GREY_BACKGROUND,
              borderRadius: "5px",
              minWidth: "90%",
              minHeight: "50px",
              padding: 20,
              marginBottom: 10,
              borderStyle: "solid",
              border: "1x",
              borderColor: "#eaeaea",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <table style={{ width: "100%" }}>
              <TableBody>
                <tr>
                  <td>
                    <FormControl>
                      <FormLabel
                        id="row-radio-buttons-projects-group-label"
                        color="success"
                      ></FormLabel>
                      <RadioGroup
                        row
                        name="projectsGroup"
                        value={projectValue}
                        onChange={(event) => {
                          handleProjectChange(event);
                        }}
                      >
                        <FormControlLabel
                          value="All Projects"
                          control={<Radio color="success" />}
                          label="All Projects"
                          onChange={() => {
                            setDTO({
                              ...dto,
                              siteCategoryId: 0,
                              siteId: 0,
                            });
                          }}
                        />
                        <FormControlLabel
                          style={{ marginLeft: 30 }}
                          value="Specific Project"
                          control={<Radio color="success" />}
                          label="Specific Project"
                        />
                      </RadioGroup>
                    </FormControl>
                  </td>
                  <td>
                    {projectValue === "Specific Project" && (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...siteCategoryProps}
                        options={siteCategoryItems}
                        id="auto-complete-1"
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="siteCategoryId"
                        defaultValue={
                          siteCategoryProps.options.find(
                            (v) => v.value === dto.siteCategoryId
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, siteCategoryId: v?.value });
                            else
                              setDTO({
                                ...dto,
                                siteCategoryId: Number(v?.value),
                              });
                          } else {
                            setDTO({ ...dto, siteCategoryId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Site Category"
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              width: "200px",
                              minHeight: "48.5px",
                              height: "48.5px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey" },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                  <td>
                    {projectValue === "Specific Project" && (
                      <Autocomplete
                        {...siteProps}
                        id="auto-complete-2"
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="siteId"
                        defaultValue={
                          siteProps.options.find(
                            (v) => v.value === dto.siteId
                          ) || {}
                        }
                        filterOptions={(o, s) => {
                          let options = globalFilter(o, s);
                          let newOptions = [];
                          options.forEach((element) => {
                            if (element.dependentvalue === dto.siteCategoryId)
                              newOptions.push(element);
                          });
                          return newOptions;
                        }}
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, siteId: v?.value });
                            else setDTO({ ...dto, siteId: Number(v?.value) });
                          } else {
                            setDTO({ ...dto, siteId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Site"
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                </tr>
              </TableBody>
            </table>
            <Divider
              style={{ marginTop: 20, marginLeft: -20, marginRight: -20 }}
            />
            <div style={{ marginTop: 20 }}>
              {" "}
              <FormControl>
                <FormLabel
                  id="row-radio-buttons-projects-group-label"
                  style={{ color: CHARLESTON_GREEN }}
                >
                  Filter By:
                </FormLabel>
                <RadioGroup
                  row
                  name="filterGroup"
                  value={filterValue}
                  onChange={(event) => {
                    handleFilterChange(event);
                  }}
                >
                  <FormControlLabel
                    value="From April upto Date"
                    onChange={() => {
                      setDTO({
                        ...dto,
                        fromDate: dayjs(
                          new Date(fiscalYear(new Date()), 3, 1)
                        ).format("YYYY-MM-DD"),

                        toDate: dayjs().endOf("month").format("YYYY-MM-DD"),
                      });
                    }}
                    control={<Radio color="success" />}
                    label="From April upto Date"
                  />
                  <FormControlLabel
                    style={{ marginLeft: 30 }}
                    value="This Month"
                    onChange={() => {
                      setDTO({
                        ...dto,
                        // fromDate: dayjs(
                        //   new Date(fiscalYear(new Date()), 3, 1)
                        // ).format("YYYY-MM-DD"),
                        fromDate: dayjs().startOf("month").format("YYYY-MM-DD"),
                        toDate: dayjs().endOf("month").format("YYYY-MM-DD"),
                      });
                    }}
                    control={<Radio color="success" />}
                    label="This Month"
                  />
                  <FormControlLabel
                    style={{ marginLeft: 30 }}
                    value="Last Month"
                    control={<Radio color="success" />}
                    onChange={() => {
                      setDTO({
                        ...dto,
                        fromDate: dayjs()
                          .month(dayjs().month() - 1)
                          .startOf("month")
                          .format("YYYY-MM-DD"),
                        toDate: dayjs()
                          .month(dayjs().month() - 1)
                          .endOf("month")
                          .format("YYYY-MM-DD"),
                      });
                    }}
                    label="Last Month"
                  />
                  <FormControlLabel
                    style={{ marginLeft: 30 }}
                    value="Custom Dates"
                    control={<Radio color="success" />}
                    label="Custom  Dates"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <table width={"60%"} style={{ marginTop: 10 }}>
              <TableBody>
                <tr>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="From Date"
                        inputFormat="DD-MM-YYYY"
                        disabled={filterValue !== "Custom Dates"}
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dto.fromDate}
                        onChange={(newValue) => {
                          setDTO({
                            ...dto,
                            fromDate: newValue.format("YYYY-MM-DD"),
                            //toDate: dayjs(newValue).endOf("month"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="To Date"
                        inputFormat="DD-MM-YYYY"
                        disabled={filterValue !== "Custom Dates"}
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dto.toDate}
                        onChange={(newValue) => {
                          setDTO({
                            ...dto,
                            toDate: newValue.format("YYYY-MM-DD"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                </tr>
              </TableBody>
            </table>
          </div>
        </form>
      </div>
      {/* upper div ends */}
      {/* below div starts */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "5px",
          border: "solid 1px #eaeaea",
          //backgroundColor: "#f8f9fd",
          padding: 10,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 0,
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            position: "relative",
          }}
        >
          <Typography
            color={CHARLESTON_GREEN}
            style={{
              flexGrow: 0,
              fontFamily: "sans-serif",
              fontSize: "18px",
              fontWeight: 600,
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: 1.32,
              letterSpacing: "normal",
              textAlign: "left",
              color: SPANISH_GREEN,
              display: "inline-flex",
            }}
            sx={{ textDecoration: "underline" }}
          >
            {TOTAL_PURCHASE}
          </Typography>

          <Typography
            color={CHARLESTON_GREEN}
            style={{
              position: "absolute",
              right: 0,
              flexGrow: 0,
              fontFamily: "sans-serif",
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: 1.32,
              letterSpacing: "normal",
            }}
          >
            ₹ {dto.totalPurchase}
          </Typography>
        </div>
        <Divider
          style={{
            marginLeft: -20,
            marginRight: -20,
            marginTop: 10,
            marginBottom: 10,
          }}
        />
        <div
          style={{
            display: "flex",
            position: "relative",
          }}
        >
          <Typography
            color={CHARLESTON_GREEN}
            style={{
              flexGrow: 0,
              fontFamily: "sans-serif",
              fontSize: "18px",
              fontWeight: 600,
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: 1.32,
              letterSpacing: "normal",
              textAlign: "left",
              color: SPANISH_GREEN,
              display: "inline-flex",
            }}
            sx={{ textDecoration: "underline" }}
          >
            {TOTAL_LABOUR_BILLED_EXCAVATOR}
          </Typography>

          <Typography
            color={CHARLESTON_GREEN}
            style={{
              position: "absolute",
              right: 0,
              flexGrow: 0,
              fontFamily: "sans-serif",
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: 1.32,
              letterSpacing: "normal",
            }}
          >
            ₹ {dto.totalLabour}
          </Typography>
        </div>
        <Divider
          style={{
            marginLeft: -20,
            marginRight: -20,
            marginTop: 10,
            marginBottom: 10,
          }}
        />
        <div
          style={{
            display: "flex",
            position: "relative",
          }}
        >
          <Typography
            color={CHARLESTON_GREEN}
            style={{
              flexGrow: 0,
              fontFamily: "sans-serif",
              fontSize: "18px",
              fontWeight: 600,
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: 1.32,
              letterSpacing: "normal",
              textAlign: "left",
              color: SPANISH_GREEN,
              display: "inline-flex",
            }}
            sx={{ textDecoration: "underline" }}
          >
            {TOTAL_SUB_CONTRACTOR_BILLED}
          </Typography>

          <Typography
            color={CHARLESTON_GREEN}
            style={{
              position: "absolute",
              right: 0,
              flexGrow: 0,
              fontFamily: "sans-serif",
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: 1.32,
              letterSpacing: "normal",
            }}
          >
            ₹ {dto.totalSubContractor}
          </Typography>
        </div>
        <Divider
          style={{
            marginLeft: -20,
            marginRight: -20,
            marginTop: 10,
            marginBottom: 10,
          }}
        />
        <div
          style={{
            display: "flex",
            position: "relative",
          }}
        >
          <Typography
            color={CHARLESTON_GREEN}
            style={{
              flexGrow: 0,
              fontFamily: "sans-serif",
              fontSize: "18px",
              fontWeight: 600,
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: 1.32,
              letterSpacing: "normal",
              textAlign: "left",
              color: SPANISH_GREEN,
              display: "inline-flex",
            }}
            sx={{ textDecoration: "underline" }}
          >
            {TOTAL}
          </Typography>

          <Typography
            color={CHARLESTON_GREEN}
            style={{
              position: "absolute",
              right: 0,
              flexGrow: 0,
              fontFamily: "sans-serif",
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: 1.32,
              letterSpacing: "normal",
            }}
          >
            ₹ {dto.total}
          </Typography>
        </div>
        <Divider
          style={{
            marginLeft: -20,
            marginRight: -20,
            marginTop: 10,
            marginBottom: 10,
          }}
        />
        <div
          style={{
            display: "flex",
            position: "relative",
          }}
        >
          <Typography
            color={CHARLESTON_GREEN}
            style={{
              flexGrow: 0,
              fontFamily: "sans-serif",
              fontSize: "18px",
              fontWeight: 600,
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: 1.32,
              letterSpacing: "normal",
              textAlign: "left",
              color: SPANISH_GREEN,
              display: "inline-flex",
            }}
            sx={{ textDecoration: "underline" }}
          >
            {BILLED_TILL_DATE}
          </Typography>

          <Typography
            color={CHARLESTON_GREEN}
            style={{
              position: "absolute",
              right: 0,
              flexGrow: 0,
              fontFamily: "sans-serif",
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: 1.32,
              letterSpacing: "normal",
            }}
          >
            ₹ {dto.billedTillDate}
          </Typography>
        </div>
        <Divider
          style={{
            marginLeft: -20,
            marginRight: -20,
            marginTop: 10,
            marginBottom: 10,
          }}
        />
        <div
          style={{
            display: "flex",
            position: "relative",
          }}
        >
          <Typography
            color={CHARLESTON_GREEN}
            style={{
              flexGrow: 0,
              fontFamily: "sans-serif",
              fontSize: "18px",
              fontWeight: 600,
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: 1.32,
              letterSpacing: "normal",
              textAlign: "left",
              color: SPANISH_GREEN,
              display: "inline-flex",
            }}
            sx={{ textDecoration: "underline" }}
          >
            {BALANCE_BILLING}
          </Typography>

          <Typography
            color={CHARLESTON_GREEN}
            style={{
              position: "absolute",
              right: 0,
              flexGrow: 0,
              fontFamily: "sans-serif",
              fontSize: "16px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: 1.32,
              letterSpacing: "normal",
            }}
          >
            ₹ {dto.balanceBilling}
          </Typography>
        </div>
      </div>
    </SideNav>
  );
}
