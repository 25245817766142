import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  TableBody,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import React, { useEffect, useRef, useState } from "react";
import SideNav from "../../../../../component/layouts/sideNav";
import {
  AMOUNT_BILLED_TILL_DATE,
  AMOUNT_PAID_TO_DATE,
  CANCEL,
  EXPORT,
  LABOUR_MANAGEMENT,
  REFRESH,
  RETENTION_AMOUNT,
  RETENTION_BALANCE_AMOUNT,
  SAVE,
  SUB_CONTRACTOR_ADVANCE_REQUEST,
  SUB_CONTRACTOR_ADVANCE_REQUEST_DETAIL,
  TESTING_AND_COMMISSION_BALANCE_AMOUNT,
} from "../../../../../resources/constant/strings/string";

import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  getProjectManagers,
  getSites,
  getSupplierCategories,
  getSuppliers,
  globalFilter,
} from "../../../../../component/cellRenderers/renderers";
import {
  BLACK,
  CHARLESTON_GREEN,
  LIGHT_GREY_BACKGROUND,
  SPANISH_GREEN,
  WARM_GRAY,
  WHITE,
} from "../../../../../resources/constant/colors/theme";

import { Stack } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { APILABOURMGMT } from "../../../../../apihandler/labourmgmt";
import { APILOGIN } from "../../../../../apihandler/login";
import {
  setAlertRadioConfirmation,
  setAlertTitleText,
} from "../../../../../component/modal/alertModal";
import {
  PATH_LOGIN,
  PATH_SUB_CONTRACTOR_ADVANCE_REQUEST,
  PATH_SUB_CONTRACTOR_BILLING_DETAIL,
} from "../../../../../resources/constant/url/url";

export default function SubContractorAdvanceRequestDetail() {
  let { id } = useParams();
  const location = useLocation();
  const isEdit = location.state?.isEdit;
  const navigate = useNavigate();

  const gridRef = useRef();

  useEffect(() => {
    if (!APILOGIN.GetIsLoggedIn()) navigate(PATH_LOGIN);
  }, []);

  const initialValue = {
    id: 0,
    siteId: 0,
    contractorId: 0,
    projectManager: 0,
    date: dayjs().add(-1, "day"),
    reasonForAdvance: "",
    amountBilledTillDate: 0,
    pendingBilledAmount: 0,
    retentionAmount: 0,
    amountRecommended: 0,
    amountRequested: 0,
    numberOfInstallments: 0,
    paidAmount: 0,
    remark: "",
    advanceAmountPaid: 0,
    pendingAmount: 0,
    status: "",
    billListView: [],
    retentionBalanceAmount: 0,
    testingBalanceAmount: 0,
  };
  const [dto, setDTO] = useState(initialValue);

  const [selectedRowId, setSelectedRowId] = useState(0);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (
        isNaN(value) ||
        name === "remark" ||
        name === "reasonForAdvance" ||
        name === "status"
      )
        setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    }
  };

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId(event.api.getSelectedRows()[0].id);
    else setSelectedRowId(0);
  };

  const handleEditData = (oldData) => {
    console.log(oldData);
  };

  const columnDefs = [
    {
      headerName: "Bill No",
      field: "billNo",
    },
    {
      headerName: "Bill Date",
      field: "billDate",
      valueFormatter: (params) => {
        if (!params.value) return "";
        return dayjs(params.value).format("DD-MM-YYYY");
      },
    },
    {
      headerName: "Amount Recovered",
      field: "amountRecovered",
    },
    {
      headerName: "Details",
      field: "billId",
      sortable: false,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => (
        <div>
          <IconButton
            aria-label="edit"
            onClick={() => {
              navigate(
                PATH_SUB_CONTRACTOR_BILLING_DETAIL + params.data.billId,
                {
                  state: { isEdit: false },
                }
              );
            }}
          >
            <VisibilityIcon style={{ color: BLACK }} />
          </IconButton>
        </div>
      ),
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: false,
    floatingFilter: false,
  };

  const onGridReady = (params) => {
    try {
      const rowData = [];
      gridRef.current.api.forEachNode(function (node) {
        rowData.push(node.data);
      });
      gridRef.current.api.applyTransaction({
        remove: rowData,
      });
    } catch (e) {}
    try {
      gridRef.current.api.applyTransaction({ add: dto.billListView });
    } catch (e) {}
  };

  //below code is for sub Contractor advance request detail component
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };

  function validation() {
    if (id <= 0) {
      APILABOURMGMT.AddSubContractorAdvance(dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setAlertTitleText(
              "Success",
              "The record was inserted successfully"
            );
            navigate(
              PATH_SUB_CONTRACTOR_ADVANCE_REQUEST + "/" + location.state.date
            );
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    } else {
      APILABOURMGMT.UpdateSubContractorAdvance(id, dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setAlertTitleText("Success", "The record was updated successfully");
            navigate(
              PATH_SUB_CONTRACTOR_ADVANCE_REQUEST + "/" + location.state.date
            );
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    }
  }

  const [dataReady, setDataReady] = useState(false);

  const fetchAdvances = () => {
    APILABOURMGMT.GenerateSubContractorAdvances(dto)
      .then((resp) => resp.json())
      .then((resp) => {
        console.log(resp);

        setDTO({
          ...dto,
          paidAmount: resp.response.paidAmount,
          amountRecommended: resp.response.amountRecommended,
          retentionAmount: resp.response.retentionAmount,
          amountBilledTillDate: resp.response.amountBilledTillDate,
          retentionBalanceAmount: resp.response.retentionBalanceAmount,
          testingBalanceAmount: resp.response.testingBalanceAmount,
        });

        setDataReady(true);
      });
  };

  const supplierCategories = getSupplierCategories().filter((x) => {
    return x.dependentvalue === "L";
  });
  const suppliers = getSuppliers().filter((x) => {
    return (
      supplierCategories.filter((y) => y.value === x.dependentvalue).length > 0
    );
  });

  const supplierProps = {
    options: suppliers,
    getOptionLabel: (option) => option?.name || "",
  };
  const sites = getSites();

  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };
  const projectManagers = getProjectManagers();

  const projectManagerProps = {
    options: projectManagers,
    getOptionLabel: (option) => option?.name || "",
  };

  useEffect(() => {
    if (isEdit || id > 0) {
      APILABOURMGMT.SubContractorAdvance(id)
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.statusCode === 200) {
            setDTO({
              ...dto,
              id: resp.response.id,
              siteId: resp.response.siteId,
              contractorId: resp.response.contractorId,
              projectManager: resp.response.projectManager,
              date: resp.response.date.split("-").reverse().join("-"),
              reasonForAdvance: resp.response.reasonForAdvance,
              amountBilledTillDate: resp.response.amountBilledTillDate,
              pendingBilledAmount: resp.response.pendingBilledAmount,
              retentionAmount: resp.response.retentionAmount,
              amountRecommended: resp.response.amountRecommended,
              amountRequested: resp.response.amountRequested,
              numberOfInstallments: resp.response.numberOfInstallments,
              paidAmount: resp.response.paidAmount,
              remark: resp.response.remark,
              advanceAmountPaid: resp.response.advanceAmountPaid,
              pendingAmount: resp.response.pendingAmount,
              status: resp.response.status,
              billListView: resp.response.billListView,
              retentionBalanceAmount: resp.response.retentionBalanceAmount,
              testingBalanceAmount: resp.response.testingBalanceAmount,
            });

            setDataReady(true);
          }
        });
    } else {
      setDataReady(true);
    }
  }, [isEdit, id]);

  return (
    <SideNav
      currentComponent={LABOUR_MANAGEMENT}
      subComponent={SUB_CONTRACTOR_ADVANCE_REQUEST}
      subComponentPath={PATH_SUB_CONTRACTOR_ADVANCE_REQUEST}
      detailComponent={SUB_CONTRACTOR_ADVANCE_REQUEST_DETAIL}
    >
      {/* below code is of sub contractore advance request detail component */}
      {dataReady ? (
        <div>
          <div>
            <form>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  marginBottom: 10,
                  marginRight: 10,
                }}
              >
                {id <= 0 && (
                  <Button
                    style={{
                      backgroundColor: SPANISH_GREEN,
                      textTransform: "capitalize",
                      fontSize: 14,
                      color: WHITE,
                      minWidth: "180px",
                      minHeight: "45px",
                    }}
                    onClick={() => {
                      fetchAdvances();
                    }}
                  >
                    {REFRESH}
                  </Button>
                )}
                {id > 0 ? (
                  <Button
                    style={{
                      backgroundColor: SPANISH_GREEN,
                      textTransform: "capitalize",
                      fontSize: 14,
                      color: WHITE,
                      minWidth: "180px",
                      minHeight: "45px",
                      marginRight: 10,
                    }}
                    onClick={() => {
                      let filename = "";
                      const pm = projectManagerProps.options.filter(
                        (x) => x.value === dto.projectManager
                      );
                      dto.projectManagerName = pm.length > 0 ? pm[0].name : "";
                      const site = siteProps.options.filter(
                        (x) => x.value === dto.siteId
                      );
                      dto.siteName = site.length > 0 ? site[0].name : "";
                      const supplier = supplierProps.options.filter(
                        (x) => x.value === dto.contractorId
                      );
                      dto.contractorName =
                        supplier.length > 0 ? supplier[0].name : "";

                      APILABOURMGMT.SubContractorAdvanceExport(dto)
                        .then((res) => {
                          const contentType = res.headers.get("content-type");
                          if (contentType.startsWith("application/json")) {
                            return Promise.reject(res.json());
                          } else {
                            const header = res.headers.get(
                              "Content-Disposition"
                            );
                            if (header !== null) {
                              const parts = header.split(";");
                              filename = parts[1]
                                .split("=")[1]
                                .replaceAll('"', "");
                            } else {
                              filename = `subcontractorbilling_${id}.html`;
                            }

                            return res.blob();
                          }
                        })
                        .then((blob) => {
                          if (blob !== null) {
                            if (dto.exportExcel) {
                              let url = window.URL.createObjectURL(blob);
                              let a = document.createElement("a");
                              a.href = url;
                              a.download = filename.replace(".html", ".xlsx");
                              document.body.appendChild(a);
                              a.click();
                              a.remove();
                            } else {
                              blob.text().then((x) => {
                                let html = x;
                                html = html.replace(
                                  "<script>",
                                  '<script type="text/javascript">'
                                );
                                let blob = new Blob([html], { type: "text/html" });
                                let url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
                              
                                let win = window.open(url, '_blank', '');
                                setTimeout(()=>{
                                    win.print();
                                }, 2000);
                              });
                            }
                          }
                        })
                        .catch((m) => {
                          setAlertTitleText(
                            "Oops...",
                            `Something went wrong...${m.message}`
                          );
                        });
                    }}
                  >
                    {EXPORT}
                  </Button>
                ) : (
                  ""
                )}
              </div>

              <div
                style={{
                  backgroundColor: LIGHT_GREY_BACKGROUND,
                  borderRadius: "5px",
                  minWidth: "90%",
                  minHeight: "50px",
                  padding: 20,
                  marginBottom: 10,
                  borderStyle: "solid",
                  border: "1x",
                  borderColor: "#eaeaea",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <table style={{ width: "100%" }}>
                  <TableBody>
                    <tr>
                      <td>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label="Date"
                            maxDate={dayjs()}
                            disabled={
                              (isEdit &&
                                dto.status !== "P" &&
                                dto.status !== "") ||
                              (id > 0 && !isEdit)
                            }
                            style={{ minWidth: "272px", minHeight: "48.5px" }}
                            inputFormat="DD-MM-YYYY"
                            value={dto.date}
                            onChange={(newValue) => {
                              setDTO({
                                ...dto,
                                date: newValue.format("YYYY-MM-DD"),
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                style={{
                                  minWidth: "200px",
                                  minHeight: "48.5px",
                                  width: "200px",
                                }}
                                InputLabelProps={{
                                  style: { color: "grey", fontSize: 14 },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { fontSize: 14 },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </td>
                      <td>
                        <Autocomplete
                          filterOptions={globalFilter}
                          {...supplierProps}
                          id="auto-complete-1"
                          disabled={id > 0}
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          name="contractorName"
                          defaultValue={
                            supplierProps.options.find(
                              (v) => v.value === dto.contractorId
                            ) || {}
                          }
                          onChange={(e, v) => {
                            if (v) {
                              if (isNaN(v?.value))
                                setDTO({ ...dto, contractorId: v?.value });
                              else
                                setDTO({
                                  ...dto,
                                  contractorId: Number(v?.value),
                                });
                            } else {
                              setDTO({ ...dto, contractorId: v?.value });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Contractor Name"
                              required
                              variant="standard"
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 14 },
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <Autocomplete
                          filterOptions={globalFilter}
                          {...siteProps}
                          id="auto-complete-2"
                          disabled={id > 0}
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          name="site"
                          defaultValue={
                            siteProps.options.find(
                              (v) => v.value === dto.siteId
                            ) || {}
                          }
                          onChange={(e, v) => {
                            if (v) {
                              if (isNaN(v?.value))
                                setDTO({ ...dto, siteId: v?.value });
                              else setDTO({ ...dto, siteId: Number(v?.value) });
                            } else {
                              setDTO({ ...dto, siteId: v?.value });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Site"
                              required
                              variant="standard"
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 14 },
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <Autocomplete
                          filterOptions={(o, s) => {
                            let options = globalFilter(o, s);
                            let newOptions = [];
                            options.forEach((element) => {
                              if (element.dependentvalue === dto.siteId)
                                newOptions.push(element);
                            });
                            return newOptions;
                          }}
                          {...projectManagerProps}
                          id="auto-complete-3"
                          disabled={id > 0}
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          name="projectManager"
                          defaultValue={
                            projectManagerProps.options.find(
                              (v) => v.value === dto.projectManager
                            ) || {}
                          }
                          onChange={(e, v) => {
                            if (v) {
                              if (isNaN(v?.value))
                                setDTO({ ...dto, projectManager: v?.value });
                              else
                                setDTO({
                                  ...dto,
                                  projectManager: Number(v?.value),
                                });
                            } else {
                              setDTO({ ...dto, projectManager: v?.value });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Project Manager"
                              variant="standard"
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 14 },
                              }}
                            />
                          )}
                        />
                      </td>
                    </tr>
                  </TableBody>
                </table>
              </div>
              {dataReady ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      marginTop: 10,
                      marginBottom: 10,
                      marginRight: 10,
                    }}
                  >
                    {(id <= 0 || isEdit) && (
                      <Button
                        style={{
                          backgroundColor: SPANISH_GREEN,
                          textTransform: "capitalize",
                          fontSize: 14,
                          color: WHITE,
                          minWidth: "180px",
                          minHeight: "45px",
                        }}
                        onClick={onFormSubmit}
                      >
                        {SAVE}
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      sx={{
                        borderColor: SPANISH_GREEN,
                      }}
                      style={{
                        //backgroundColor: SPANISH_GREEN,
                        textTransform: "capitalize",
                        fontSize: 14,
                        color: SPANISH_GREEN,
                        minWidth: "180px",
                        minHeight: "45px",
                        marginRight: 10,
                      }}
                      onClick={() => {
                        navigate(
                          PATH_SUB_CONTRACTOR_ADVANCE_REQUEST +
                            "/" +
                            location.state.date
                        );
                      }}
                    >
                      {CANCEL}
                    </Button>
                  </div>
                  <div
                    style={{
                      backgroundColor: WHITE,
                      borderRadius: "5px",
                      minWidth: "90%",
                      minHeight: "50px",
                      padding: 20,
                      marginBottom: 10,
                      borderStyle: "solid",
                      border: "1x",
                      borderColor: "#eaeaea",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <table style={{ width: "100%" }}>
                      <TableBody>
                        <tr>
                          <td>
                            <div>
                              <Typography
                                color={WARM_GRAY}
                                style={{
                                  fontWeight: 600,
                                  fontFamily: "Inter",
                                  fontSize: "12px",
                                  //fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                }}
                              >
                                {AMOUNT_BILLED_TILL_DATE}
                              </Typography>
                              <Typography
                                color={CHARLESTON_GREEN}
                                style={{
                                  fontWeight: 600,
                                  fontFamily: "Inter",
                                  fontSize: "16px",
                                  //fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                }}
                              >
                                ₹ {dto.amountBilledTillDate}
                              </Typography>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div>
                              <Typography
                                color={WARM_GRAY}
                                style={{
                                  fontWeight: 600,
                                  fontFamily: "Inter",
                                  fontSize: "12px",
                                  //fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                }}
                              >
                                {RETENTION_AMOUNT}
                              </Typography>
                              <Typography
                                color={CHARLESTON_GREEN}
                                style={{
                                  fontWeight: 600,
                                  fontFamily: "Inter",
                                  fontSize: "16px",
                                  //fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                }}
                              >
                                ₹ {dto.retentionAmount}
                              </Typography>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div>
                              <Typography
                                color={WARM_GRAY}
                                style={{
                                  fontWeight: 600,
                                  fontFamily: "Inter",
                                  fontSize: "12px",
                                  //fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                }}
                              >
                                {AMOUNT_PAID_TO_DATE}
                              </Typography>
                              <Typography
                                color={CHARLESTON_GREEN}
                                style={{
                                  fontWeight: 600,
                                  fontFamily: "Inter",
                                  fontSize: "16px",
                                  //fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                }}
                              >
                                ₹ {dto.paidAmount}
                              </Typography>
                            </div>
                          </td>
                          <td />
                        </tr>
                        <tr>
                          <td>
                            <TextField
                              variant="standard"
                              label="Advance Amount Requested"
                              required
                              onChange={(e) => {
                                const { name, value } = e.target;
                                setDTO({ ...dto, [name]: value });
                              }}
                              onWheel={(event) => event.target.blur()}
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              name="amountRequested"
                              value={dto.amountRequested}
                              onBlur={handleInputChange}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                style: { fontSize: 14 },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                            ></TextField>
                          </td>
                          {/* as instructed by Nagendra Pangla conmmenting below code. */}

                          {/* <td>
                            <TextField
                              variant="standard"
                              label="Advance Pending to be Billed"
                              required
                              onChange={(e) => {
                                const { name, value } = e.target;
                                setDTO({ ...dto, [name]: value });
                              }}
                              onWheel={(event) => event.target.blur()}
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              name="pendingBilledAmount"
                              value={dto.pendingBilledAmount}
                              onBlur={handleInputChange}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                style: { fontSize: 14 },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                            ></TextField>
                          </td> */}

                          <td>
                            <TextField
                              variant="standard"
                              label="No of Installments"
                              onChange={(e) => {
                                const { name, value } = e.target;
                                setDTO({ ...dto, [name]: value });
                              }}
                              onWheel={(event) => event.target.blur()}
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              name="numberOfInstallments"
                              value={dto.numberOfInstallments}
                              onBlur={handleInputChange}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                style: { fontSize: 14 },
                              }}
                            ></TextField>
                          </td>
                          <td>
                            <TextField
                              variant="standard"
                              label="Amount Recomended"
                              required
                              onChange={(e) => {
                                const { name, value } = e.target;
                                setDTO({ ...dto, [name]: value });
                              }}
                              onWheel={(event) => event.target.blur()}
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              name="amountRecommended"
                              value={dto.amountRecommended}
                              onBlur={handleInputChange}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                style: { fontSize: 14 },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                            ></TextField>
                          </td>
                        </tr>
                        <tr>
                          {/* as instructed by Nagendra Pangla conmmenting below code. */}

                          {/* <td>
                            <TextField
                              variant="standard"
                              label="Advance Amount Paid"
                              onChange={(e) => {
                                const { name, value } = e.target;
                                setDTO({ ...dto, [name]: value });
                              }}
                              onWheel={(event) => event.target.blur()}
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              required
                              name="advanceAmountPaid"
                              value={dto.advanceAmountPaid}
                              onBlur={handleInputChange}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                style: { fontSize: 14 },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                            ></TextField>
                          </td> */}
                          <td>
                            {" "}
                            <div>
                              <Typography
                                color={WARM_GRAY}
                                style={{
                                  fontWeight: 600,
                                  fontFamily: "Inter",
                                  fontSize: "12px",
                                  //fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                }}
                              >
                                {RETENTION_BALANCE_AMOUNT}
                              </Typography>
                              <Typography
                                color={CHARLESTON_GREEN}
                                style={{
                                  fontWeight: 600,
                                  fontFamily: "Inter",
                                  fontSize: "16px",
                                  //fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                }}
                              >
                                ₹ {dto.retentionBalanceAmount}
                              </Typography>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div>
                              <Typography
                                color={WARM_GRAY}
                                style={{
                                  fontWeight: 600,
                                  fontFamily: "Inter",
                                  fontSize: "12px",
                                  //fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                }}
                              >
                                {TESTING_AND_COMMISSION_BALANCE_AMOUNT}
                              </Typography>
                              <Typography
                                color={CHARLESTON_GREEN}
                                style={{
                                  fontWeight: 600,
                                  fontFamily: "Inter",
                                  fontSize: "16px",
                                  //fontWeight: "normal",
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                }}
                              >
                                ₹ {dto.testingBalanceAmount}
                              </Typography>
                            </div>
                          </td>
                          <td />
                          <td />
                          <td />
                        </tr>
                        <tr>
                          <td colSpan={4}>
                            <Stack
                              direction="column"
                              justifyContent="center"
                              alignItems="stretch"
                              spacing={2}
                            >
                              <TextareaAutosize
                                variant="standard"
                                style={{
                                  outlineColor: BLACK,
                                  borderTop: "10px",
                                  borderRight: "10px",
                                  borderLeft: "10px",
                                  marginTop: 15,
                                  fontSize: 12,
                                  fontFamily: "sans-serif",
                                }}
                                multiline="true"
                                maxRows={4}
                                minRows={2}
                                value={dto.reasonForAdvance}
                                name="reasonForAdvance"
                                onChange={handleInputChange}
                                placeholder="Reason for Advance"
                              ></TextareaAutosize>
                            </Stack>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4}>
                            <Stack
                              direction="column"
                              justifyContent="center"
                              alignItems="stretch"
                              spacing={2}
                            >
                              <TextareaAutosize
                                variant="standard"
                                style={{
                                  outlineColor: BLACK,
                                  borderTop: "10px",
                                  borderRight: "10px",
                                  borderLeft: "10px",
                                  marginTop: 15,
                                  fontSize: 12,
                                  fontFamily: "sans-serif",
                                }}
                                multiline="true"
                                maxRows={4}
                                minRows={2}
                                value={dto.remark}
                                name="remark"
                                onChange={handleInputChange}
                                placeholder="Remark"
                              ></TextareaAutosize>
                            </Stack>
                          </td>
                        </tr>
                      </TableBody>
                    </table>
                  </div>
                </div>
              ) : (
                ""
              )}
            </form>
          </div>
          {/* aggrid div start */}
          {id > 0 ? (
            <div className="App">
              <div className="ag-theme-alpine" style={{ height: "570px" }}>
                <AgGridReact
                  copyGroupHeadersToClipboard={true}
                  enableRangeSelection={true}
                  columnDefs={columnDefs}
                  ref={gridRef}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  pagination={false}
                  rowSelection="multiple"
                  getRowId={(params) => params.data.id}
                  onSelectionChanged={onRowSelectionChanged}
                ></AgGridReact>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </SideNav>
  );
}
