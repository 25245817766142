import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  getCompanies,
  getIndents,
  getItemCategories,
  getItemList,
  getItems,
  getSites,
  getSupplierCategories,
  getSuppliers,
  getUoms,
  globalFilter,
  itemCategoryCellRenderer,
  itemCellRenderer,
} from "../../../../../component/cellRenderers/renderers";
import SideNav from "../../../../../component/layouts/sideNav";
import {
  BALANCE_QTY,
  BASIC_VALUE,
  CANCEL,
  CLEAR,
  FREIGHT_CHARGE,
  GRAND_TOTAL,
  INDENT_QTY,
  ITEM_CATEGORY,
  ITEM_DESCRIPTION,
  ITEM_NAME,
  MATERIAL_MANAGEMENT,
  PURCHASE_ORDER_INDENT,
  PURCHASE_ORDER_INDENT_DETAIL,
  QTY,
  RATE,
  TAX,
  TAX_VALUE,
  TOTAL,
  TOTAL_VALUE,
} from "../../../../../resources/constant/strings/string";

import VisibilityIcon from "@mui/icons-material/Visibility";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import print_icon from "../../../../../resources/assets/images/print_icon.svg";
import {
  BLACK,
  CHARLESTON_GREEN,
  LIGHT_GREY_BACKGROUND,
  SPANISH_GREEN,
  WHITE,
} from "../../../../../resources/constant/colors/theme";
import {
  PRINT,
  PURCHASE_ORDER_NO,
  SAVE,
} from "../../../../../resources/constant/strings/string";
import {
  PATH_PURCHASE_ORDER,
  PATH_PURCHASE_ORDER_DETAIL_WITHOUT_PARAM,
} from "../../../../../resources/constant/url/url";

import {
  Autocomplete,
  Button,
  Checkbox,
  createFilterOptions,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TableBody,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import apply_icon from "../../../../../resources/assets/images/apply_icon.svg";
import minus_icon from "../../../../../resources/assets/images/minus_icon.svg";
import plus_icon from "../../../../../resources/assets/images/plus_icon.svg";
import {
  ANTI_FLASH_WHITE,
  PRUSSIAN_BLUE,
} from "../../../../../resources/constant/colors/theme";

import { APIMATERIALMGMT } from "../../../../../apihandler/materialmgmt";
import { APIUTILS } from "../../../../../apihandler/utils";
import {
  setAlertConfirmDeletion,
  setAlertTitleText,
} from "../../../../../component/modal/alertModal";
import EditModal from "../editModal";
import Modal from "./modal";
import html2pdf from "html2pdf.js";
import { APISALES } from "../../../../../apihandler/sales";
const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  color: SPANISH_GREEN,
  transform: "translate(-50%, -50%)",
  zIndex: 2,
};

export default function PurchaseOrderDetail() {
  const navigate = useNavigate();
  let { id } = useParams();

  const [lastRowId, setLastRowId] = useState(0);
  const [indentItems, setIndentItems] = useState([]);
  const [disableFetch, setDisableFetch] = useState(false);
  const [isDateChanged, setIsDateChanged] = useState(false);
  const addModel = id <= 0;
  const editModel = id > 0;

  const location = useLocation();
  const isEdit = location.state?.isEdit;
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    //api call to save goes here
    if (dto.isIndent) {
      if (
        indentProps.options.filter(
          (x) => x.dependentvalue === dto.siteId && x.value === dto.indentId
        ).length <= 0
      ) {
        setAlertTitleText("Oops...", "Site and indent does not match");
        return;
      }
    }

    if (dto.id <= 0) {
      dto.poItems = rowItems.filter((m) => m.qty > 0);

      if (dto.poItems.filter((x) => x.qty > 0).length <= 0) {
        setAlertTitleText("No items have been selected for purchase");
        return;
      }

      if (dto.indentId > 0) dto.isIndent = true;
      APIMATERIALMGMT.SavePurchaseOrder(dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            if(data.message !== "OK"){
              setAlertTitleText("Success", `Record saved successfully... Warning ${data.message}`);
            } else {
              setAlertTitleText("Success", `Record saved successfully`);
            }
            navigate(PATH_PURCHASE_ORDER + "/" + location.state.date);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    } else {
      dto.poItems = rowItems.filter((m) => m.qty > 0);

      if (dto.poItems.filter((x) => x.qty > 0).length <= 0) {
        setAlertTitleText("No items have been selected for purchase");
        return;
      }

      APIMATERIALMGMT.UpdatePurchaseOrder(id, dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            if(data.message !== "OK"){
              setAlertTitleText("Success", `Record saved successfully... Warning ${data.message}`);
            } else {
              setAlertTitleText("Success", `Record saved successfully`);
            }

            navigate(PATH_PURCHASE_ORDER + "/" + location.state.date);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
          }
        });
    }
  }

  const companies = getCompanies();
  const supplierCategories = getSupplierCategories().filter(
    (v) => v.name !== "Opening Stock Category"
  );
  const suppliers = getSuppliers().filter((v) => v.name !== "Opening Stock");
  const sites = getSites();
  const indents = getIndents();

  const companyProps = {
    options: companies,
    getOptionLabel: (option) => option?.name || "",
  };

  const supplierCategoryProps = {
    options: supplierCategories,
    getOptionLabel: (option) => option?.name || "",
  };

  const supplierProps = {
    options: suppliers,
    getOptionLabel: (option) => option?.name || "",
  };

  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };

  const indentProps = {
    options: indents,
    getOptionLabel: (option) => option?.name || "",
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (
      value &&
      name !== "reference" &&
      name !== "description" &&
      name !== "termsAndConditions"
    ) {
      if (isNaN(value)) setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    } else {
      setDTO({ ...dto, [name]: value });
    }
  };

  const itemCategories = getItemCategories();

  const itemCategoryProps = {
    options: itemCategories,
    getOptionLabel: (option) => option?.name || "",
  };

  const itemList = getItemList();
  const uomList = getUoms();

  const items = getItems();

  const itemProps = {
    options: items,
    getOptionLabel: (option) => option?.name || "",
  };

  const uomProps = {
    options: uomList,
    getOptionLabel: (option) => option?.name || "",
  };

  const onClear = () => {
    setAlertConfirmDeletion(
      "Confirmation needed",
      "Do you confirm on proceeding to clear?"
    ).then((consent) => {
      if (!consent.isConfirmed) return;
      window.location.reload();
    });
  };

  const changeRates = (v) => {
    if(typeof v === "undefined"){
      v = dto.supplierId;
    }
    const itemIds = [];
    rowItems.forEach((x) => {
      if (x.itemId > 0) {
        itemIds.push(x.itemId);
      }
    });

    APIMATERIALMGMT.Rates({
      supplierId: v,
      date: dto.date,
      itemId: itemIds,
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) {
          //setAlertTitleText("Oops...", resp.message);
          return;
        }

        let grandTotal = 0.0;
        dto.basicValue = 0.0;
        dto.taxValue = 0.0;
        dto.totalValue = 0.0;

        const poItems = rowItems.map((x) => {
          if (x.itemId > 0) {
            const reqdRate = resp.response.filter((y) => y.itemId === x.itemId);
            if (reqdRate.length > 0) {
              x.rate = reqdRate[0].rate;
              x.basicValue = Number.parseFloat(
                APIUTILS.Financial(x.qty * x.rate)
              );
              x.taxValue = 0;
              x.totalValue = x.basicValue;
            } else {
              x.rate = 0;
              x.basicValue = 0;
              x.taxValue = 0;
              x.totalValue = 0;
            }
          }
          return x;
        });

        let taskArray = [];

        for (const e of poItems) {
          if (e.tax && e.tax.taxIds?.length <= 0) {
            dto.basicValue += Number.parseFloat(
              APIUTILS.Financial(e.basicValue)
            );
            dto.taxValue += Number.parseFloat(APIUTILS.Financial(e.taxValue));
            dto.totalValue += Number.parseFloat(
              APIUTILS.Financial(e.totalValue)
            );
            continue;
          }
          let taskN = APIUTILS.CalculateTax({
            taxIds: e.tax?.taxIds,
            basicAmount: e?.basicValue,
          })
            .then((r) => r.json())
            .then((d) => {
              if (d.statusCode === 200) {
                e.taxValue = Number.parseFloat(
                  APIUTILS.Financial(Number(d.response.taxValue))
                );
                e.totalValue = Number.parseFloat(
                  APIUTILS.Financial(Number(d.response.totalValue))
                );
              }

              dto.basicValue += Number.parseFloat(
                APIUTILS.Financial(e.basicValue)
              );
              dto.taxValue += Number.parseFloat(APIUTILS.Financial(e.taxValue));
              dto.totalValue += Number.parseFloat(
                APIUTILS.Financial(e.totalValue)
              );
            });
          taskArray.push(taskN);
        }

        Promise.all(taskArray).then(() => {
          for (const e of poItems) {
            grandTotal += Number.parseFloat(APIUTILS.Financial(e.totalValue));
          }

          dto.basicValue = Number.parseFloat(
            APIUTILS.Financial(dto.basicValue)
          );
          dto.taxValue = Number.parseFloat(APIUTILS.Financial(dto.taxValue));
          dto.totalValue = Number.parseFloat(
            APIUTILS.Financial(dto.totalValue)
          );

          setRowItems(poItems);

          grandTotal += Number.parseFloat(
            APIUTILS.Financial(dto.freightCharge)
          );

          setDTO({
            ...dto,
            supplierId: v,
            poItems: poItems,
            grandTotal: Number.parseFloat(APIUTILS.Financial(grandTotal)),
          });
          try {
            const rowData = [];
            gridRef.current.api.forEachNode(function (node) {
              rowData.push(node.data);
            });
            gridRef.current.api.applyTransaction({
              remove: rowData,
            });
          } catch (e) {}
          try {
            gridRef.current.api.applyTransaction({ add: poItems });
          } catch (e) {}
          if (isEdit) {
            setLastRowId(
              gridRef.current.api.getDisplayedRowAtIndex(
                gridRef.current.api.getLastDisplayedRow()
              ).data.rowId
            );
          }
        });
      });
  };

  const fetchIndent = (v) => {
    APIMATERIALMGMT.PurchaseOrderIndent({
      siteId: dto.siteId,
      indentId: v,
      supplierId: dto.supplierId,
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode === 200) {
          let grandTotal = 0.0;
          dto.basicValue = 0.0;
          dto.taxValue = 0.0;
          dto.totalValue = 0.0;
          const newArr = [];
          resp.response.forEach((e) => {
            e.basicValue = Number.parseFloat(APIUTILS.Financial(e.basicValue));
            e.taxValue = Number.parseFloat(APIUTILS.Financial(e.taxValue));
            e.totalValue = Number.parseFloat(APIUTILS.Financial(e.totalValue));
            grandTotal += Number.parseFloat(APIUTILS.Financial(e.totalValue));
          });

          const reqdIndentItems = [];
          resp.response.forEach((e, i) => {
            if (e.balanceQty <= 0) return;

            reqdIndentItems.push(e.itemId);
            e.tax = { taxIds: [] };
            e.rowId = i;
            e.balanceQty = Number.parseFloat(APIUTILS.Financial(e.balanceQty));
            const it = items.filter((v) => v.value === e.itemId);
            if (it.length > 0) {
              e.itemCategoryId = it[0].dependentvalue;
            }
            const itemsInIndent = itemList.filter((v) => v.id === e.itemId);
            if (itemsInIndent.length > 0) {
              let uom = uomList.filter(
                (v) => v.value === itemsInIndent[0].purchaseUOMId
              );
              if (uom.length > 0) e.uomName = uom[0].name;
            }
            newArr.push(e);
            dto.basicValue += Number.parseFloat(
              APIUTILS.Financial(e.basicValue)
            );
            dto.taxValue += Number.parseFloat(APIUTILS.Financial(e.taxValue));
            dto.totalValue += Number.parseFloat(
              APIUTILS.Financial(e.totalValue)
            );
          });

          grandTotal += Number.parseFloat(
            APIUTILS.Financial(dto.freightCharge)
          );

          dto.indentId = v;

          setDTO({
            ...dto,
            grandTotal: Number.parseFloat(APIUTILS.Financial(grandTotal)),
          });

          reqdIndentItems.sort((a, b) => b.id - a.id);
          newArr.sort((a, b) => b.id - a.id);

          setIndentItems((r) => [].concat(reqdIndentItems));
          setRowItems((r) => [].concat(newArr));
          setDisableFetch(true);
        } else {
          setAlertTitleText("Oops...", resp.message);
        }
      });
  };

  const onAdd = (data) => {
    APIMATERIALMGMT.Rates({
      supplierId: dto.supplierId,
      date: dto.date,
      itemId: [data.itemId],
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode === 200) {
          if (resp.response.length > 0) {
            data.rate = Number(resp.response[0].rate);
          } else {
            data.rate = 0;
          }
        }

        data.id = Number((Math.random() * 10000000).toFixed(0));
        data.basicValue = Number.parseFloat(
          APIUTILS.Financial(data.rate * data.qty)
        );
        let grandTotal = 0.0;
        dto.basicValue = 0.0;
        dto.taxValue = 0.0;
        dto.totalValue = 0.0;
        const poItems = rowItems.concat([data]);
        poItems.forEach((x, i) => {
          x.rowId = i;
        });
        setRowItems([...rowItems, data]);

        poItems.forEach((e) => {
          dto.basicValue += Number.parseFloat(APIUTILS.Financial(e.basicValue));
          dto.taxValue += Number.parseFloat(APIUTILS.Financial(e.taxValue));
          if(e.taxValue === 0)
            e.totalValue = e.basicValue;
          dto.totalValue += Number.parseFloat(APIUTILS.Financial(e.totalValue));
          grandTotal += Number.parseFloat(APIUTILS.Financial(e.totalValue));
        });

        dto.basicValue = Number.parseFloat(
          APIUTILS.Financial(dto.basicValue)
        );
        dto.taxValue = Number.parseFloat(APIUTILS.Financial(dto.taxValue));
        dto.totalValue = Number.parseFloat(
          APIUTILS.Financial(dto.totalValue)
        );

        grandTotal += Number.parseFloat(APIUTILS.Financial(dto.freightCharge));
        setDTO({
          ...dto,
          poItems: poItems,
          grandTotal: Number.parseFloat(APIUTILS.Financial(grandTotal)),
        });
        try {
          const rowData = [];
          gridRef.current.api.forEachNode(function (node) {
            rowData.push(node.data);
          });
          gridRef.current.api.applyTransaction({
            remove: rowData,
          });
        } catch (e) {}
        try {
          gridRef.current.api.applyTransaction({ add: poItems });
        } catch (e) {}
        setLastRowId(
          gridRef.current.api.getDisplayedRowAtIndex(
            gridRef.current.api.getLastDisplayedRow()
          ).data.rowId
        );
      });
  };

  const onUpdate = (data) => {
    data.basicValue = Number.parseFloat(
      APIUTILS.Financial(data.rate * data.qty)
    );

    if (data.tax.taxIds.length <= 0) {
      data.totalValue = data.basicValue;
      let grandTotal = 0.0;
      dto.basicValue = 0.0;
      dto.taxValue = 0.0;
      dto.totalValue = 0.0;
      const poItems = rowItems.map((obj) => {
        if (obj.rowId === data.rowId) {
          return { ...obj, ...data };
        }
        return obj;
      });

      poItems.forEach((x, i) => {
        x.rowId = i;
      });

      poItems.forEach((e) => {
        dto.basicValue += Number.parseFloat(APIUTILS.Financial(e.basicValue));
        dto.taxValue += Number.parseFloat(APIUTILS.Financial(e.taxValue));
        if(e.taxValue === 0)
            e.totalValue = e.basicValue;
        dto.totalValue += Number.parseFloat(APIUTILS.Financial(e.totalValue));
        grandTotal += Number.parseFloat(APIUTILS.Financial(e.totalValue));
      });

      setRowItems(poItems);

      grandTotal += Number.parseFloat(APIUTILS.Financial(dto.freightCharge));
      dto.basicValue = Number.parseFloat(
        APIUTILS.Financial(dto.basicValue)
      );
      dto.taxValue = Number.parseFloat(APIUTILS.Financial(dto.taxValue));
      dto.totalValue = Number.parseFloat(
        APIUTILS.Financial(dto.totalValue)
      );

      setDTO({
        ...dto,
        poItems: poItems,
        grandTotal: Number.parseFloat(APIUTILS.Financial(grandTotal)),
      });

      try {
        const rowData = [];
        gridRef.current.api.forEachNode(function (node) {
          rowData.push(node.data);
        });
        gridRef.current.api.applyTransaction({
          remove: rowData,
        });
      } catch (e) {}
      try {
        gridRef.current.api.applyTransaction({ add: poItems });
      } catch (e) {}
      setLastRowId(
        gridRef.current.api.getDisplayedRowAtIndex(
          gridRef.current.api.getLastDisplayedRow()
        ).data.rowId
      );
    } else {
      APIUTILS.CalculateTax({
        taxIds: data.tax.taxIds,
        basicAmount: data?.basicValue,
      })
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.statusCode !== 200) {
            setAlertTitleText("Oops...", "Cannot calculate tax");
            return;
          }

          data.tax = resp.response;
          data.taxValue = Number.parseFloat(
            APIUTILS.Financial(Number(resp.response.taxValue))
          );
          data.totalValue = Number.parseFloat(
            APIUTILS.Financial(Number(resp.response.totalValue))
          );

          let grandTotal = 0.0;
          dto.basicValue = 0.0;
          dto.taxValue = 0.0;
          dto.totalValue = 0.0;
          const poItems = rowItems.map((obj) => {
            if (obj.rowId === data.rowId) {
              return { ...obj, ...data };
            }
            return obj;
          });

          poItems.forEach((x, i) => {
            x.rowId = i;
          });

          poItems.forEach((e) => {
            dto.basicValue += Number.parseFloat(
              APIUTILS.Financial(e.basicValue)
            );
            dto.taxValue += Number.parseFloat(APIUTILS.Financial(e.taxValue));
            dto.totalValue += Number.parseFloat(
              APIUTILS.Financial(e.totalValue)
            );
            grandTotal += Number.parseFloat(APIUTILS.Financial(e.totalValue));
          });

          setRowItems(poItems);

          dto.basicValue = Number.parseFloat(
            APIUTILS.Financial(dto.basicValue)
          );
          dto.taxValue = Number.parseFloat(APIUTILS.Financial(dto.taxValue));
          dto.totalValue = Number.parseFloat(
            APIUTILS.Financial(dto.totalValue)
          );

          grandTotal += Number.parseFloat(
            APIUTILS.Financial(dto.freightCharge)
          );

          setDTO({
            ...dto,
            poItems: poItems,
            grandTotal: Number.parseFloat(APIUTILS.Financial(grandTotal)),
          });

          try {
            const rowData = [];
            gridRef.current.api.forEachNode(function (node) {
              rowData.push(node.data);
            });
            gridRef.current.api.applyTransaction({
              remove: rowData,
            });
          } catch (e) {}
          try {
            gridRef.current.api.applyTransaction({ add: poItems });
          } catch (e) {}
          setLastRowId(
            gridRef.current.api.getDisplayedRowAtIndex(
              gridRef.current.api.getLastDisplayedRow()
            ).data.rowId
          );
        });
    }
  };

  const [indentChecked, setIndentChecked] = useState(false);

  const [dto, setDTO] = useState({
    id: 0,
    isIndent: false,
    companyId: 0,
    poNumber: "Auto Generated",
    date: dayjs(),
    deliveryDate: null,
    supplierId: 0,
    supplierCategoryId: 0,
    siteId: 0,
    taxAmount: 0,
    grandTotal: 0,
    grnNo: "",
    status: "",
    modifiedBy: 0,
    reference: "",
    indentId: 0,
    description: "",
    termsAndConditions: "",
    freightCharge: 0.0,
    basicValue: 0.0,
    taxValue: 0.0,
    totalValue: 0.0,
    poItems: [],
  });
  const [rowItems, setRowItems] = useState([]);
  const [rowId, setRowId] = useState(2);

  function addObjectToArray() {
    const newArr = [...rowItems,{
      rowId: -1*Number((Math.random() * 10000000).toFixed(0)),
      id: 0,
      itemCategoryId: 0,
      itemId: 0,
      uomName: "",
      itemDescription: "",
      qty: 0,
      indentQty: 0,
      balanceQty: 0,
      rate: 0,
      discount: 0,
      basicValue: 0,
      taxValue: 0,
      tax: { taxIds: [] },
      totalValue: 0,
      status: "N",
    }];
    setRowItems(newArr);
  }

  const removeObjectFromArray = (rowId) => {
      setRowItems(c => c.filter((x) => {
        return x.rowId !== rowId;
      }));

      setTimeout(()=>{
      let grandTotal = 0.0;
      dto.basicValue = 0.0;
      dto.taxValue = 0.0;
      dto.totalValue = 0.0;

      rowItems.forEach((e) => {
        dto.basicValue += Number.parseFloat(APIUTILS.Financial(e.basicValue));
        dto.taxValue += Number.parseFloat(APIUTILS.Financial(e.taxValue));
        if(e.taxValue === 0)
              e.totalValue = e.basicValue;
        dto.totalValue += Number.parseFloat(APIUTILS.Financial(e.totalValue));
        grandTotal += Number.parseFloat(APIUTILS.Financial(e.totalValue));
      });
      dto.basicValue = Number(APIUTILS.Financial(dto.basicValue));
      dto.taxValue = Number(APIUTILS.Financial(dto.taxValue));
      dto.totalValue = Number(APIUTILS.Financial(dto.totalValue));

      setDTO({
        ...dto,
        grandTotal: Number.parseFloat(APIUTILS.Financial(grandTotal)),
      });
    },3000);
  }

  const clearArray = () => {
    setRowItems(rowItems.filter((x) => false));
    console.log("Rows count on clear", rowItems.length);
  };

  const onApplyValue = (v) => {
    const newArr = [...rowItems];
    newArr[currentIndex].taxValue = Number.parseFloat(
      APIUTILS.Financial(Number(v.taxValue))
    );
    newArr[currentIndex].totalValue = Number.parseFloat(
      APIUTILS.Financial(Number(v.totalValue))
    );
    newArr[currentIndex].tax = v;
    let grandTotal = 0.0;
    dto.basicValue = 0.0;
    dto.taxValue = 0.0;
    dto.totalValue = 0.0;

    newArr.forEach((e) => {
      e.basicValue = Number.parseFloat(APIUTILS.Financial(e.basicValue));
      e.taxValue = Number.parseFloat(APIUTILS.Financial(e.taxValue));
      e.totalValue = Number.parseFloat(APIUTILS.Financial(e.totalValue));

      dto.basicValue += Number.parseFloat(APIUTILS.Financial(e.basicValue));
      dto.taxValue += Number.parseFloat(APIUTILS.Financial(e.taxValue));
      if(e.taxValue === 0)
            e.totalValue = e.basicValue;
      dto.totalValue += Number.parseFloat(APIUTILS.Financial(e.totalValue));
      grandTotal += Number.parseFloat(APIUTILS.Financial(e.totalValue));
    });

    grandTotal += Number.parseFloat(APIUTILS.Financial(dto.freightCharge));
    setDTO({
      ...dto,
      grandTotal: Number.parseFloat(APIUTILS.Financial(grandTotal)),
    });
    setRowItems(newArr);
  };

  function isAfter(date1, date2) {
    return date1 > date2;
  }

  const gridRef = useRef();
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [gridData, setGridData] = useState([]);
  const [supplierCategory, setSupplierCategory] = useState({});
  const [supplier, setSupplier] = useState({});
  const [site, setSite] = useState({});
  const [company, setCompany] = useState({});

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId(event.api.getSelectedRows()[0].id);
    else setSelectedRowId(0);
  };
  const [openEditModal, setEditOpenModal] = useState(false);
  const [editFormTitle, setEditFormTitle] = useState("Add");
  const [editFormData, setEditFormData] = useState(null);

  const columnDefs = [
    {
      flex: 1.4,
      headerName: "Actions",
      field: "id",
      sortable: false,
      hide: !isEdit,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) =>
        lastRowId === params.data?.rowId ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              aria-label="Add"
              onClick={() => {
                setEditFormTitle("Add");
                setEditFormData({
                  id: 0,
                  isIndent: dto.isIndent,
                  itemCategoryId: 0,
                  itemId: 0,
                  uomName: "",
                  itemDescription: "",
                  qty: 0,
                  indentQty: 0,
                  balanceQty: 0,
                  rate: 0,
                  discount: 0,
                  basicValue: 0,
                  taxValue: 0,
                  tax: { taxIds: [] },
                  totalValue: 0,
                  status: "N",
                });
                setEditOpenModal(true);
              }}
            >
              <AddCircleOutlineIcon
                style={{ color: PRUSSIAN_BLUE, width: "20px" }}
              />
            </IconButton>
            <IconButton
              aria-label="edit"
              onClick={() => {
                setCurrentIndex(params.data.rowId);
                setEditFormTitle("Edit");
                setEditFormData(params.data);
                setEditOpenModal(true);
              }}
            >
              <EditIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => {
                setAlertConfirmDeletion(
                  "Confirmation needed",
                  "Do you want to delete? Please confirm"
                ).then((consent) => {
                  if (!consent.isConfirmed) return;

                  const selectedData = gridRef.current.api.getSelectedRows();
                  gridRef.current.api.applyTransaction({
                    remove: selectedData,
                  });

                  setRowItems(rowItems.filter((x) => x.id !== params.data?.id));

                  setLastRowId(
                    gridRef.current.api.getDisplayedRowAtIndex(
                      gridRef.current.api.getLastDisplayedRow()
                    ).data.rowId
                  );

                  let grandTotal = 0.0;
                  dto.basicValue = 0.0;
                  dto.taxValue = 0.0;
                  dto.totalValue = 0.0;

                  rowItems
                    .filter((x) => x.id !== params.data?.id)
                    .forEach((e) => {
                      dto.basicValue += Number.parseFloat(
                        APIUTILS.Financial(e.basicValue)
                      );
                      dto.taxValue += Number.parseFloat(
                        APIUTILS.Financial(e.taxValue)
                      );
                      dto.totalValue += Number.parseFloat(
                        APIUTILS.Financial(e.totalValue)
                      );
                      grandTotal += Number.parseFloat(
                        APIUTILS.Financial(e.totalValue)
                      );
                    });

                  grandTotal += Number.parseFloat(
                    APIUTILS.Financial(dto.freightCharge)
                  );
                  setDTO({
                    ...dto,
                    grandTotal: Number.parseFloat(
                      APIUTILS.Financial(grandTotal)
                    ),
                  });
                });
              }}
            >
              <DeleteForeverIcon
                style={{ color: PRUSSIAN_BLUE, width: "20px" }}
              />
            </IconButton>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IconButton
              aria-label="edit"
              onClick={() => {
                setCurrentIndex(params.data.rowId);
                setEditFormTitle("Edit");
                setEditFormData(params.data);
                setEditOpenModal(true);
              }}
            >
              <EditIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => {
                setAlertConfirmDeletion(
                  "Confirmation needed",
                  "Do you want to delete? Please confirm"
                ).then((consent) => {
                  if (!consent.isConfirmed) return;

                  const selectedData = gridRef.current.api.getSelectedRows();
                  gridRef.current.api.applyTransaction({
                    remove: selectedData,
                  });

                  setRowItems(rowItems.filter((x) => x.id !== params.data?.id));

                  setLastRowId(
                    gridRef.current.api.getDisplayedRowAtIndex(
                      gridRef.current.api.getLastDisplayedRow()
                    ).data.rowId
                  );

                  let grandTotal = 0.0;
                  dto.basicValue = 0.0;
                  dto.taxValue = 0.0;
                  dto.totalValue = 0.0;

                  rowItems
                    .filter((x) => x.id !== params.data?.id)
                    .forEach((e) => {
                      dto.basicValue += Number.parseFloat(
                        APIUTILS.Financial(e.basicValue)
                      );
                      dto.taxValue += Number.parseFloat(
                        APIUTILS.Financial(e.taxValue)
                      );
                      dto.totalValue += Number.parseFloat(
                        APIUTILS.Financial(e.totalValue)
                      );
                      grandTotal += Number.parseFloat(
                        APIUTILS.Financial(e.totalValue)
                      );
                    });

                  grandTotal += Number.parseFloat(
                    APIUTILS.Financial(dto.freightCharge)
                  );
                  setDTO({
                    ...dto,
                    grandTotal: Number.parseFloat(
                      APIUTILS.Financial(grandTotal)
                    ),
                  });
                });
              }}
            >
              <DeleteForeverIcon
                style={{ color: PRUSSIAN_BLUE, width: "20px" }}
              />
            </IconButton>
          </div>
        ),
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 1.6,
      headerName: "Item Category",
      field: "itemCategoryId",
      valueGetter: (params) => {
        return itemCategoryCellRenderer({ value: params.data.itemCategoryId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      flex: 1.6,
      headerName: "Item Name",
      field: "itemId",
      valueGetter: (params) => {
        return itemCellRenderer({ value: params.data.itemId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      flex: 1.6,
      headerName: "Item Description",
      field: "itemDescription",
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Quantity",
      field: "qty",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "UOM",
      field: "uomName",
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Rate",
      field: "rate",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Basic Value",
      field: "basicValue",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Tax Value",
      field: "taxValue",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Total Value",
      field: "totalValue",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 0.7,
      headerName: "Tax",
      field: "rowId",
      sortable: false,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            aria-label="edit"
            onClick={() => {
              setCurrentIndex(params.data.rowId);
              setFormData(params.data);
              setOpenModal(true);
            }}
          >
            <VisibilityIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
          </IconButton>
        </div>
      ),
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    filter: "agTextColumnFilter",
    floatingFilter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };

  const onGridReady = (params) => {
    if (id > 0) {
      APIMATERIALMGMT.PurchaseOrder(id)
        .then((resp) => resp.json())
        .then((resp) => {
          resp.response.poItems = resp.response.poItems.sort(
            (a, b) => a.id - b.id
          );
          resp.response.poItems.forEach((e, i) => {
            e.rowId = i;
            if (e.tax === null) {
              e.tax = { taxIds: [] };
            }
            const it = itemList.filter((v) => v.id === e.itemId);
            if (it.length > 0) {
              e.itemCategoryId = it[0].categoryId;
              if (e.itemDescription === null || e.itemDescription?.length <= 0)
                e.itemDescription = it[0].description;

              let uom = uomList.filter((v) => v.value === it[0].purchaseUOMId);
              if (uom.length > 0) e.uomName = uom[0].name;
            }
          });

          gridRef.current.api.applyTransaction({
            add: resp.response.poItems,
          });

          setLastRowId(
            gridRef.current.api.getDisplayedRowAtIndex(
              gridRef.current.api.getLastDisplayedRow()
            ).data.rowId
          );

          setIndentChecked(resp.response.isIndent);

          const requiredCompany = companyProps.options.filter(
            (x) => x.value === resp.response.companyId
          );
          setCompany(
            requiredCompany.length > 0
              ? requiredCompany[0]
              : { value: 0, name: "" }
          );

          const requiredSupplier = supplierProps.options.filter(
            (x) => x.value === resp.response.supplierId
          );
          setSupplier(
            requiredSupplier.length > 0
              ? requiredSupplier[0]
              : { value: 0, name: "" }
          );

          const requiredSupplierCategory = supplierCategoryProps.options.filter(
            (x) =>
              x.value ===
              (requiredSupplier.length > 0
                ? requiredSupplier[0].dependentvalue
                : 0)
          );
          setSupplierCategory(
            requiredSupplierCategory.length > 0
              ? requiredSupplierCategory[0]
              : { value: 0, name: "" }
          );

          const requiredSite = siteProps.options.filter(
            (x) => x.value === resp.response.siteId
          );
          setSite(
            requiredSite.length > 0 ? requiredSite[0] : { value: 0, name: "" }
          );

          resp.response.poItems.forEach((e) => {
            dto.basicValue += Number.parseFloat(
              APIUTILS.Financial(e.basicValue)
            );
            dto.taxValue += Number.parseFloat(APIUTILS.Financial(e.taxValue));
            dto.totalValue += Number.parseFloat(
              APIUTILS.Financial(e.totalValue)
            );
          });

          const supplierCategoryId =
            requiredSupplierCategory.length > 0
              ? requiredSupplierCategory[0].value
              : 0;

          setRowItems((rowItems) => [].concat(resp.response.poItems));
          if (resp.response.isIndent) {
            APIMATERIALMGMT.Indent(resp.response.indentId)
              .then((indentResponse) => indentResponse.json())
              .then((indentResponse) => {
                if (indentResponse.statusCode === 200) {
                  const arr = [];
                  indentResponse.response.itemDetails.forEach((e) => {
                    arr.push(e.itemId);
                  });
                  setIndentItems(indentItems.filter((x) => false));
                  setIndentItems((indentItems) => indentItems.concat(arr));
                }
              });
          }

          setDTO({
            ...dto,
            id: resp.response.id,
            isIndent: resp.response.isIndent,
            companyId: resp.response.companyId,
            poNumber: resp.response.poNumber,
            date: resp.response.date.split("-").reverse().join("-"),
            deliveryDate: resp.response.deliveryDate
              ?.split("-")
              .reverse()
              .join("-"),
            supplierId: resp.response.supplierId,
            supplierCategoryId: supplierCategoryId,
            siteId: resp.response.siteId,
            taxAmount: resp.response.taxAmount,
            grandTotal: resp.response.grandTotal,
            grnNo: resp.response.grnNo,
            status: resp.response.status,
            reference: resp.response.reference,
            indentId: resp.response.indentId,
            description: resp.response.description,
            termsAndConditions: resp.response.termsAndConditions,
            freightCharge: resp.response.freightCharge,
            basicValue: Number.parseFloat(APIUTILS.Financial(dto.basicValue)),
            taxValue: Number.parseFloat(APIUTILS.Financial(dto.taxValue)),
            totalValue: Number.parseFloat(
              APIUTILS.Financial(resp.response.grandTotal)
            ),
            poItems: resp.response.poItems,
          });
        });
      setDataReady(true);
    } else {
      setDataReady(true);
      gridRef.current.api.applyTransaction({ add: gridData });
      setLastRowId(
        gridRef.current.api.getDisplayedRowAtIndex(
          gridRef.current.api.getLastDisplayedRow()
        ).data.rowId
      );
    }
  };

  const [dataReady, setDataReady] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const [viewOnlyModel, setViewOnlyModel] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  return (
    <SideNav
      currentComponent={MATERIAL_MANAGEMENT}
      subComponent={PURCHASE_ORDER_INDENT}
      subComponentPath={PATH_PURCHASE_ORDER}
      detailComponent={PURCHASE_ORDER_INDENT_DETAIL}
    >
      {openModal && (
        <Modal
          style={{ align: "center" }}
          setOpenModal={setOpenModal}
          onApply={onApplyValue}
          rowIndex={currentIndex}
          viewModel={viewOnlyModel}
          model={formData}
          formTitle={"Tax"}
        ></Modal>
      )}
      {openEditModal && (
        <EditModal
          formTitle={editFormTitle}
          setOpenModal={setEditOpenModal}
          model={editFormData}
          onAdd={onAdd}
          onUpdate={onUpdate}
          itemsInOriginal={indentItems}
        ></EditModal>
      )}

      {/*Code for options*/}
      <div>
        <form>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            {(id <= 0 || isEdit) && (
              <Button
                style={{
                  backgroundColor: SPANISH_GREEN,
                  textTransform: "capitalize",
                  fontSize: 14,
                  color: WHITE,
                  minWidth: "180px",
                  minHeight: "45px",
                }}
                onClick={onFormSubmit}
              >
                {SAVE}
              </Button>
            )}
            {id <= 0 && (
              <Button
                variant="outlined"
                sx={{
                  borderColor: SPANISH_GREEN,
                }}
                style={{
                  backgroundColor: LIGHT_GREY_BACKGROUND,
                  textTransform: "capitalize",
                  fontSize: 14,
                  color: SPANISH_GREEN,
                  minWidth: "180px",
                  minHeight: "45px",
                  marginRight: 10,
                }}
                onClick={onClear}
              >
                {CLEAR}
              </Button>
            )}
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                //backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              startIcon={<img src={print_icon} alt="" />}
              onClick={() => {
                let filename = "";
                APIMATERIALMGMT.PurchaseOrderPrintPDF(id)
                  .then((res) => {
                    setAlertTitleText("Please wait...", "Generating print...");
                    const contentType = res.headers.get("content-type");
                    if (contentType.startsWith("application/json")) {
                      return Promise.reject(res.json());
                    } else {
                      const header = res.headers.get("Content-Disposition");
                      if (header !== null) {
                        const parts = header.split(";");
                        filename = parts[1].split("=")[1].replaceAll('"', "");
                      } else {
                        filename = "PurchaseOrder.html";
                      }

                      return res.blob();
                    }
                  })
                  .then((blob) => {
                    if (blob !== null) {
                      blob.text().then((x) => {
                        let html = x;
                        html = html.replace(
                          "<script>",
                          '<script type="text/javascript">'
                        );
                        let blob = new Blob([html], { type: "text/html" });
                        let url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
                      
                        let win = window.open(url, '_blank', '');
                        setTimeout(()=>{
                            win.print();
                        }, 2000);
                      });
                    }
                  })
                  .catch((m) => {
                    setAlertTitleText(
                      "Oops...",
                      `Something went wrong...${m.message}`
                    );
                  });
              }}
            >
              {PRINT}
            </Button>

            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                //backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={() => {
                navigate(PATH_PURCHASE_ORDER + "/" + location.state.date);
              }}
            >
              {CANCEL}
            </Button>
          </div>
          <div
            style={{
              backgroundColor: LIGHT_GREY_BACKGROUND,
              borderRadius: "5px",
              minWidth: "90%",
              minHeight: "50px",
              padding: 20,
              marginBottom: 10,
              borderStyle: "solid",
              border: "1x",
              borderColor: "#eaeaea",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Typography
                color={CHARLESTON_GREEN}
                style={{
                  fontWeight: 600,
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "left",
                }}
              >
                {PURCHASE_ORDER_NO}
                {id > 0 ? ` ${dto.poNumber}` : " Auto Generated"}
              </Typography>
              <Typography
                color={CHARLESTON_GREEN}
                style={{
                  fontWeight: 600,
                  fontFamily: "Inter",
                  fontSize: "16px",
                  //fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "left",
                }}
              ></Typography>
            </div>
            <table style={{ width: "100%" }}>
              <TableBody>
                <tr>
                  <td>
                    <FormControlLabel
                      name="isIndent"
                      disabled={dto.isIndent || id > 0}
                      value={dto.isIndent}
                      control={<Checkbox color="success" />}
                      label="Indent"
                      checked={indentChecked}
                      onChange={(e) => {
                        setDTO({ ...dto, isIndent: e.target.checked ? 1 : 0 });
                        setIndentChecked(!indentChecked);
                      }}
                      style={{ userSelect: "none" }}
                    />
                  </td>
                  <td>
                    {id > 0 ? (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...companyProps}
                        id="auto-complete-1"
                        disabled={isEdit}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="companyId"
                        value={company}
                        isOptionEqualToValue={(o, v) => o.value === v.value}
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, companyId: v?.value });
                            else
                              setDTO({
                                ...dto,
                                companyId: Number(v?.value),
                              });
                          } else {
                            setDTO({ ...dto, companyId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Company"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...companyProps}
                        id="auto-complete-1"
                        disabled={isEdit}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="companyId"
                        defaultValue={
                          companyProps.options.find(
                            (v) => v.value === dto.companyId
                          ) || { value: 0, name: "" }
                        }
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, companyId: v?.value });
                            else
                              setDTO({
                                ...dto,
                                companyId: Number(v?.value),
                              });
                          } else {
                            setDTO({ ...dto, companyId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Company"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                  <td>
                    {id > 0 ? (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...supplierCategoryProps}
                        id="auto-complete-2"
                        disabled={isEdit}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="supplierCategoryId"
                        value={supplierCategory}
                        isOptionEqualToValue={(o, v) => o.value === v.value}
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, supplierCategoryId: v?.value });
                            else
                              setDTO({
                                ...dto,
                                supplierCategoryId: Number(v?.value),
                              });
                          } else {
                            setDTO({ ...dto, supplierCategoryId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Supplier Category"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...supplierCategoryProps}
                        id="auto-complete-2"
                        disabled={isEdit}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="supplierCategoryId"
                        defaultValue={
                          supplierCategoryProps.options.find(
                            (v) => v.value === dto.supplierCategoryId
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, supplierCategoryId: v?.value });
                            else
                              setDTO({
                                ...dto,
                                supplierCategoryId: Number(v?.value),
                              });
                          } else {
                            setDTO({ ...dto, supplierCategoryId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Supplier Category"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    {id > 0 ? (
                      <Autocomplete
                        {...supplierProps}
                        id="auto-complete-3"
                        disabled={id > 0 && !isEdit && indentChecked}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="supplierId"
                        value={supplier}
                        isOptionEqualToValue={(o, v) => {
                          return o.value === v.value;
                        }}
                        filterOptions={(o, s) => {
                          let options = globalFilter(o, s);
                          let newOptions = [];
                          options.forEach((element) => {
                            if (
                              element.dependentvalue === dto.supplierCategoryId
                            )
                              newOptions.push(element);
                          });
                          return newOptions;
                        }}
                        onChange={(e, v) => {
                          if(v === null)
                            return;

                          if (v && dto.supplierId !== 0) {
                            setAlertConfirmDeletion(
                              "Confirmation needed",
                              "Rates will change. Do you want to confirm"
                            ).then((consent) => {
                              if (!consent.isConfirmed) return;

                              if (v) {
                                if (v.dependentvalue !== dto.supplierCategoryId)
                                  return;

                                if (isNaN(v?.value))
                                  setDTO({ ...dto, supplierId: v?.value });
                                else
                                  setDTO({
                                    ...dto,
                                    supplierId: Number(v?.value),
                                  });
                              } else {
                                setDTO({ ...dto, supplierId: v?.value });
                              }

                              const reqdSupplier = suppliers.filter(
                                (x) => x.value === v?.value
                              );
                              if (reqdSupplier.length > 0)
                                setSupplier(reqdSupplier[0]);

                              changeRates(v?.value);
                            });
                          } else {
                            if (v) {
                              if (v.dependentvalue !== dto.supplierCategoryId)
                                return;

                              if (isNaN(v?.value))
                                setDTO({ ...dto, supplierId: v?.value });
                              else
                                setDTO({
                                  ...dto,
                                  supplierId: Number(v?.value),
                                });
                            } else {
                              setDTO({ ...dto, supplierId: v?.value });
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Supplier Name"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        {...supplierProps}
                        id="auto-complete-3"
                        disabled={id > 0 && !isEdit}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="supplierId"
                        defaultValue={
                          supplierProps.options.find(
                            (v) => v.value === dto.supplierId
                          ) || {}
                        }
                        filterOptions={(o, s) => {
                          let options = globalFilter(o, s);
                          let newOptions = [];
                          options.forEach((element) => {
                            if (
                              element.dependentvalue === dto.supplierCategoryId
                            )
                              newOptions.push(element);
                          });
                          return newOptions;
                        }}
                        onChange={(e, v) => {
                          if (v && dto.supplierId !== 0) {
                            setAlertConfirmDeletion(
                              "Confirmation needed",
                              "Rates will change. Do you want to confirm"
                            ).then((consent) => {
                              if (!consent.isConfirmed) return;

                              if (v) {
                                if (v.dependentvalue !== dto.supplierCategoryId)
                                  return;

                                if (isNaN(v?.value))
                                  setDTO({ ...dto, supplierId: v?.value });
                                else
                                  setDTO({
                                    ...dto,
                                    supplierId: Number(v?.value),
                                  });
                              } else {
                                setDTO({ ...dto, supplierId: v?.value });
                              }

                              changeRates(v?.value);
                            });
                          } else {
                            if (v) {
                              if (v.dependentvalue !== dto.supplierCategoryId)
                                return;

                              if (isNaN(v?.value))
                                setDTO({ ...dto, supplierId: v?.value });
                              else
                                setDTO({
                                  ...dto,
                                  supplierId: Number(v?.value),
                                });
                            } else {
                              setDTO({ ...dto, supplierId: v?.value });
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Supplier Name"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                  <td>
                    <TextField
                      inputProps={{
                        maxlength: 128,
                        style: { fontSize: 14 },
                      }}
                      variant="standard"
                      label="Reference"
                      disabled={id > 0 && !isEdit}
                      style={{
                        minWidth: "200px",
                        minHeight: "48.5px",
                        width: "200px",
                      }}
                      name="reference"
                      value={dto.reference}
                      onChange={handleInputChange}
                      InputLabelProps={{
                        style: { color: "grey", fontSize: 14 },
                      }}
                    ></TextField>
                  </td>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Purchase Order Date "
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dto.date}
                        maxDate={dayjs()}
                        disabled={id > 0 && !isEdit}
                        onChange={(newValue) => {
                          setAlertConfirmDeletion(
                            "Confirmation needed",
                            "Rates will change. Do you want to confirm"
                          ).then((consent) => {
                            if (!consent.isConfirmed) return;
                            dto.date = newValue.format("YYYY-MM-DD");
                            setDTO({ ...dto, date: newValue.format("YYYY-MM-DD") });
                            setTimeout(()=>{
                              changeRates(dto.supplierId);
                              setDTO({ ...dto, date: newValue.format("YYYY-MM-DD") });
                            },3000);
                          });
                        }}
                        inputFormat="DD-MM-YYYY"
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                </tr>
                <tr>
                  <td>
                    {id > 0 ? (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...siteProps}
                        id="auto-complete-4"
                        disabled={dto.grnNo?.length > 5}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="siteId"
                        value={site}
                        isOptionEqualToValue={(o, v) => {
                          return o.value === v.value;
                        }}
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, siteId: v?.value });
                            else setDTO({ ...dto, siteId: Number(v?.value) });
                          } else {
                            setDTO({ ...dto, siteId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Site"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        filterOptions={globalFilter}
                        {...siteProps}
                        id="auto-complete-4"
                        disabled={isEdit}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        name="siteId"
                        defaultValue={
                          siteProps.options.find(
                            (v) => v.value === dto.siteId
                          ) || {}
                        }
                        onChange={(e, v) => {
                          if (v) {
                            if (isNaN(v?.value))
                              setDTO({ ...dto, siteId: v?.value });
                            else setDTO({ ...dto, siteId: Number(v?.value) });
                          } else {
                            setDTO({ ...dto, siteId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Site"
                            required
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Delivery Date "
                        disabled={id > 0 && !isEdit}
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dto.deliveryDate}
                        minDate={dayjs(dto.date)}
                        inputFormat="DD-MM-YYYY"
                        onChange={(newValue) => {
                          if (dayjs(dto.date).isBefore(dayjs(newValue))) {
                            setDTO({
                              ...dto,
                              deliveryDate: newValue.format("YYYY-MM-DD"),
                            });
                          } else {
                            setAlertTitleText(
                              "Warning",
                              "Select a valid Delivery Date..."
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                  <td>
                    {indentChecked && (
                      <Autocomplete
                        {...indentProps}
                        autoComplete
                        autoHighlight
                        autoSelect
                        includeInputInList
                        disabled={isEdit}
                        name="indentId"
                        defaultValue={
                          indentProps.options.find(
                            (v) => v.value === dto.indentId
                          ) || {}
                        }
                        filterOptions={(o, s) => {
                          let options = globalFilter(o, s);
                          let newOptions = [];
                          options.forEach((element) => {
                            if (element.dependentvalue === dto.siteId)
                              newOptions.push(element);
                          });
                          return newOptions;
                        }}
                        onChange={(e, v) => {
                          if (v) {
                            if (
                              v.dependentvalue !== dto.siteId ||
                              dto.supplierId <= 0
                            )
                              return;

                            const isIndentChange = dto.indentId > 0;
                            const prevIndentId = dto.indentId;
                            if (isNaN(v?.value))
                              setDTO({ ...dto, indentId: v?.value });
                            else setDTO({ ...dto, indentId: Number(v?.value) });
                            if (isIndentChange) {
                              setAlertConfirmDeletion(
                                "Confirmation needed",
                                "Items will change. Do you want to confirm?"
                              ).then((consent) => {
                                if (!consent.isConfirmed) {
                                  setDTO({ ...dto, indentId: prevIndentId });
                                  return;
                                }

                                fetchIndent(v?.value);
                              });
                            } else {
                              fetchIndent(v?.value);
                            }
                          } else {
                            setDTO({ ...dto, indentId: v?.value });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Indent"
                            variant="standard"
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextareaAutosize
                      variant="standard"
                      disabled={id > 0 && !isEdit}
                      name="description"
                      maxLength={128}
                      style={{
                        backgroundColor: LIGHT_GREY_BACKGROUND,
                        outlineColor: BLACK,
                        borderTop: "10px",
                        borderRight: "10px",
                        borderLeft: "10px",
                        marginTop: 15,
                        fontSize: 12,
                        fontFamily: "sans-serif",
                        minWidth: "200px",
                        minHeight: "33.5px",
                        width: "200px",
                        height: "33.5.5px",
                      }}
                      multiline="true"
                      value={dto.description}
                      onChange={handleInputChange}
                      maxRows={4}
                      minRows={2}
                      placeholder="Description"
                    ></TextareaAutosize>
                  </td>
                  <td>
                    <TextareaAutosize
                      variant="standard"
                      disabled={id > 0 && !isEdit}
                      value={dto.termsAndConditions}
                      onChange={handleInputChange}
                      name="termsAndConditions"
                      maxLength={128}
                      style={{
                        backgroundColor: LIGHT_GREY_BACKGROUND,
                        outlineColor: BLACK,
                        borderTop: "10px",
                        borderRight: "10px",
                        borderLeft: "10px",
                        marginTop: 15,
                        fontSize: 12,
                        fontFamily: "sans-serif",
                        minWidth: "200px",
                        minHeight: "33.5px",
                        width: "200px",
                        height: "33.5.5px",
                      }}
                      multiline="true"
                      maxRows={4}
                      minRows={2}
                      placeholder="Terms and Conditions"
                    ></TextareaAutosize>
                  </td>
                  <td>
                    <TextField
                      variant="standard"
                      label="Grand Total"
                      
                      onWheel={(event) => event.target.blur()}
                      disabled={true}
                      style={{
                        minWidth: "200px",
                        minHeight: "48.5px",
                        width: "200px",
                      }}
                      name="grandTotal"
                      value={dto.grandTotal}
                      InputLabelProps={{
                        style: { color: "grey", fontSize: 14 },
                      }}
                      InputProps={{ style: { fontSize: 14 } }}
                    ></TextField>
                  </td>
                </tr>
              </TableBody>
            </table>
          </div>
        </form>
      </div>

      {/* below code is for purchase order dynamic row  addition deletion*/}
      {id > 0 ? (
        <div className="App">
          <div className="ag-theme-alpine" style={{ height: "570px" }}>
            <AgGridReact
              copyGroupHeadersToClipboard={true}
              enableRangeSelection={true}
              columnDefs={columnDefs}
              ref={gridRef}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              paginationAutoPageSize={true}
              pagination={true}
              rowSelection="multiple"
              getRowId={(params) => params.data.id}
              onSelectionChanged={onRowSelectionChanged}
            ></AgGridReact>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: WHITE,
            borderRadius: "5px",
            minWidth: "90%",
            minHeight: "140px",
            marginBottom: 10,
            border: "0.1px solid #eaeaea",
            display: "flex",
            flexDirection: "column",
            // width: "100%",
            overflowX: "auto",
          }}
        >
          <div
            style={{
              width: "1300px",
            }}
          >
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
              }}
            >
              <TableBody>
                <tr
                  style={{
                    backgroundColor: LIGHT_GREY_BACKGROUND,
                    borderBottom: "0.001pt solid #f0eff4",
                    borderTop: "0.001pt solid #f0eff4",
                  }}
                >
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {ITEM_CATEGORY}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {ITEM_NAME}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {ITEM_DESCRIPTION}
                    </Typography>
                  </th>
                  {indentChecked && (
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "Inter",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {INDENT_QTY}
                      </Typography>
                    </th>
                  )}
                  {indentChecked && (
                    <th align="left">
                      <Typography
                        color={PRUSSIAN_BLUE}
                        style={{
                          flexGrow: 0,
                          fontFamily: "Inter",
                          fontSize: 14,
                          fontstretch: "normal",
                          fontStyle: "normal",
                          userSelect: "none",
                          lineHeight: 1.32,
                          letterSpacing: "normal",
                          marginTop: 20,
                          marginBottom: 10,
                          marginLeft: 5,
                          fontWeight: 600,
                        }}
                      >
                        {BALANCE_QTY}
                      </Typography>
                    </th>
                  )}
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {QTY}
                    </Typography>
                  </th>

                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {RATE}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {BASIC_VALUE}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,

                        fontWeight: 600,
                      }}
                    >
                      {TAX}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {TAX_VALUE}
                    </Typography>
                  </th>
                  <th align="left">
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "Inter",
                        fontSize: 14,
                        fontstretch: "normal",
                        fontStyle: "normal",
                        userSelect: "none",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        marginTop: 20,
                        marginBottom: 10,
                        marginLeft: 5,
                        fontWeight: 600,
                      }}
                    >
                      {TOTAL_VALUE}
                    </Typography>
                  </th>
                  <th></th>
                  <td></td>
                </tr>
                <tr>
                  {rowItems?.length <= 0 && (
                    <IconButton
                      style={{ marginTop: 10 }}
                      id={`button-plus-default`}
                      aria-label="plus"
                      onClick={() => {
                        addObjectToArray();
                      }}
                    >
                      <img src={plus_icon} alt="" />
                    </IconButton>
                  )}
                </tr>
                {rowItems?.map((item, index) => {
                  return (
                    <tr key={item.rowId}
                      style={{
                        borderBottom: "0.001pt solid #f0eff4",
                      }}
                    >
                      <td>
                        <Autocomplete
                          filterOptions={createFilterOptions({
                            ignoreCase: true,
                            matchFrom: "start",
                          })}
                          id={`autocomplete-1-${index}`}
                          {...itemCategoryProps}
                          variant="standard"
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          disabled={id > 0}
                          defaultValue={
                            itemCategoryProps.options.find(
                              (v) => v.value === rowItems[index].itemCategoryId
                            ) || {}
                          }
                          onChange={(e, v) => {
                            if (dto.supplierId <= 0) {
                              setAlertTitleText(
                                "Warning",
                                "Please Select Supplier..."
                              );
                              return;
                            }
                            let newArr = [...rowItems];
                            newArr[index].itemCategoryId = Number(v?.value);
                            setRowItems(newArr);
                          }}
                          renderInput={(params) => (
                            <TextField
                              size={"small"}
                              {...params}
                              label="Item Category"
                              variant="standard"
                              style={{
                                minWidth: "180px",
                                minHeight: "38.5px",
                                width: "180px",
                                height: "38.5px",
                                marginBottom: 5,
                                marginLeft: 5,
                              }}
                              {...params}
                              InputLabelProps={{
                                style: {
                                  color: "grey",
                                  fontSize: 12,
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 12 },
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <Autocomplete
                          id={`autocomplete-2-${index}`}
                          {...itemProps}
                          variant="standard"
                          autoComplete
                          autoHighlight
                          autoSelect
                          disabled={id > 0}
                          defaultValue={
                            itemProps.options.find(
                              (v) => v?.value === rowItems[index].itemId
                            ) || {}
                          }
                          filterOptions={(o, s) => {
                            let options = globalFilter(o, s);
                            let newOptions = [];
                            options.forEach((element) => {
                              if (
                                element.dependentvalue ===
                                rowItems[index].itemCategoryId
                              )
                                newOptions.push(element);
                            });

                            if (indentChecked) {
                              newOptions = newOptions.filter(
                                (x) => indentItems.indexOf(x.value) !== -1
                              );
                            }

                            return newOptions;
                          }}
                          onChange={(e, v) => {
                            let newArr = [...rowItems];
                            newArr[index].itemId = Number(v?.value);
                            const filteredItems = itemList.filter(
                              (x) => x.id === newArr[index].itemId
                            );
                            if (filteredItems.length > 0) {
                              newArr[index].itemDescription =
                                filteredItems[0].description;
                              uomList.forEach((element) => {
                                if (
                                  element.value ===
                                  filteredItems[0].purchaseUOMId
                                )
                                  newArr[index].uomName = element.name;
                              });
                            }

                            APIMATERIALMGMT.Rates({
                              supplierId: dto.supplierId,
                              date: dto.date,
                              itemId: [newArr[index].itemId],
                            })
                              .then((resp) => resp.json())
                              .then((resp) => {
                                if (resp.response.length > 0) {
                                  newArr[index].rate = Number(
                                    resp.response[0].rate
                                  );
                                } else {
                                  newArr[index].rate = 0;
                                }
                                newArr[index].basicValue = Number.parseFloat(
                                  APIUTILS.Financial(
                                    newArr[index].rate * newArr[index].qty
                                  )
                                );
                                setRowItems(newArr);
                                let grandTotal = 0.0;
                                dto.basicValue = 0.0;
                                dto.taxValue = 0.0;
                                dto.totalValue = 0.0;

                                newArr.forEach((e) => {
                                  dto.basicValue += Number.parseFloat(
                                    APIUTILS.Financial(e.basicValue)
                                  );
                                  dto.taxValue += Number.parseFloat(
                                    APIUTILS.Financial(e.taxValue)
                                  );
                                  dto.totalValue += Number.parseFloat(
                                    APIUTILS.Financial(e.totalValue)
                                  );
                                  grandTotal += Number.parseFloat(
                                    APIUTILS.Financial(e.totalValue)
                                  );
                                });

                                dto.basicValue = Number.parseFloat(
                                  APIUTILS.Financial(dto.basicValue)
                                );
                                dto.taxValue = Number.parseFloat(
                                  APIUTILS.Financial(dto.taxValue)
                                );
                                dto.totalValue = Number.parseFloat(
                                  APIUTILS.Financial(dto.totalValue)
                                );

                                grandTotal += Number.parseFloat(
                                  APIUTILS.Financial(dto.freightCharge)
                                );
                                setDTO({
                                  ...dto,
                                  grandTotal: Number.parseFloat(
                                    APIUTILS.Financial(grandTotal)
                                  ),
                                });
                              });
                          }}
                          name="itemId"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Item Name"
                              variant="standard"
                              style={{
                                minWidth: "180px",
                                minHeight: "38.5px",
                                width: "180px",
                                height: "38.5px",
                              }}
                              {...params}
                              InputLabelProps={{
                                style: {
                                  color: "grey",
                                  fontSize: 12,
                                },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 12 },
                              }}
                            />
                          )}
                        ></Autocomplete>
                      </td>

                      <td>
                        <TextareaAutosize
                          variant="standard"
                          label="Description"
                          maxLength={128}
                          disabled={id > 0}
                          style={{
                            outlineColor: BLACK,
                            borderTop: "10px",
                            borderRight: "10px",
                            borderLeft: "10px",
                            marginTop: 15,
                            fontSize: 12,
                            fontFamily: "sans-serif",
                            width: "120px",
                          }}
                          multiline="true"
                          maxRows={4}
                          minRows={2}
                          value={rowItems[index].itemDescription}
                          name="itemDescription"
                          onChange={(e) => {
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = e.target.value;
                            setRowItems(newArr);
                          }}
                          placeholder="Description"
                        ></TextareaAutosize>
                      </td>
                      {indentChecked && (
                        <td>
                          <TextField
                            variant="standard"
                            label="Indent Qty"
                            disabled={true}
                            
                            onWheel={(event) => event.target.blur()}
                            style={{
                              minWidth: "90px",
                              minHeight: "38.5px",
                              height: "38.5px",
                              width: "90px",
                              fontSize: 10,
                              marginBottom: 5,
                              marginLeft: 5,
                            }}
                            name="indentQty"
                            onBlur={(e) => {
                              let newArr = [...rowItems];
                              newArr[index][e.target.name] = Number(
                                e.target.value
                              );
                              setRowItems(newArr);
                            }}
                            value={rowItems[index].indentQty}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 12 },
                            }}
                            InputProps={{
                              style: { fontSize: 12 },
                            }}
                          ></TextField>
                        </td>
                      )}
                      {indentChecked && (
                        <td>
                          <TextField
                            variant="standard"
                            label="Balance Qty"
                            disabled={true}
                            
                            onWheel={(event) => event.target.blur()}
                            style={{
                              minWidth: "90px",
                              minHeight: "38.5px",
                              height: "38.5px",
                              width: "90px",
                              fontSize: 10,
                              marginBottom: 5,
                              marginLeft: 5,
                            }}
                            name="balanceQty"
                            onChange={(e) => {
                              let newArr = [...rowItems];
                              newArr[index][e.target.name] = (
                                e.target.value
                              );
                              setRowItems(newArr);
                            }}
                            onBlur={(e) => {
                              let newArr = [...rowItems];
                              newArr[index][e.target.name] = Number(
                                e.target.value
                              );
                              setRowItems(newArr);
                            }}
                            value={rowItems[index].balanceQty}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 12 },
                            }}
                            InputProps={{
                              style: { fontSize: 12 },
                            }}
                          ></TextField>
                        </td>
                      )}
                      <td>
                        <TextField
                          variant="standard"
                          label="Quantity"
                          disabled={id > 0}
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "90px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "90px",
                            fontSize: 10,
                          }}
                          name="qty"
                          onChange={(e) => {
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = (
                              e.target.value
                            );
                            setRowItems(newArr);
                          }}
                          onBlur={(e) => {
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = Number(
                              e.target.value
                            );

                            newArr[index].basicValue = Number.parseFloat(
                              APIUTILS.Financial(
                                Number(e.target.value) * newArr[index].rate
                              )
                            );

                            newArr[index].totalValue = newArr[index].basicValue;

                            if (
                              newArr[index].tax !== null &&
                              newArr[index].tax.taxIds.length > 0 &&
                              newArr[index].basicValue !== 0
                            ) {
                              APIUTILS.CalculateTax({
                                taxIds: newArr[index].tax.taxIds,
                                basicAmount: newArr[index].basicValue,
                              })
                                .then((resp) => resp.json())
                                .then((resp) => {
                                  if (resp.statusCode !== 200) {
                                    setAlertTitleText(
                                      "Oops...",
                                      "Cannot calculate tax"
                                    );
                                    return;
                                  }

                                  newArr[index].tax = resp.response;
                                  newArr[index].taxValue = Number.parseFloat(
                                    APIUTILS.Financial(
                                      Number(resp.response.taxValue)
                                    )
                                  );
                                  newArr[index].totalValue = Number.parseFloat(
                                    APIUTILS.Financial(
                                      Number(resp.response.totalValue)
                                    )
                                  );

                                  setRowItems(newArr);
                                  let grandTotal = 0.0;
                                  dto.basicValue = 0.0;
                                  dto.taxValue = 0.0;
                                  dto.totalValue = 0.0;

                                  newArr.forEach((e) => {
                                    dto.basicValue += Number.parseFloat(
                                      APIUTILS.Financial(e.basicValue)
                                    );
                                    dto.taxValue += Number.parseFloat(
                                      APIUTILS.Financial(e.taxValue)
                                    );
                                    dto.totalValue += Number.parseFloat(
                                      APIUTILS.Financial(e.totalValue)
                                    );
                                    grandTotal += Number.parseFloat(
                                      APIUTILS.Financial(e.totalValue)
                                    );
                                  });

                                  dto.basicValue = Number.parseFloat(
                                    APIUTILS.Financial(dto.basicValue)
                                  );
                                  dto.taxValue = Number.parseFloat(
                                    APIUTILS.Financial(dto.taxValue)
                                  );
                                  dto.totalValue = Number.parseFloat(
                                    APIUTILS.Financial(dto.totalValue)
                                  );

                                  grandTotal += Number.parseFloat(
                                    APIUTILS.Financial(dto.freightCharge)
                                  );
                                  setDTO({
                                    ...dto,
                                    grandTotal: Number.parseFloat(
                                      APIUTILS.Financial(grandTotal)
                                    ),
                                  });
                                });
                            } else {
                              setRowItems(newArr);
                              let grandTotal = 0.0;
                              dto.basicValue = 0.0;
                              dto.taxValue = 0.0;
                              dto.totalValue = 0.0;

                              newArr.forEach((e) => {
                                dto.basicValue += Number.parseFloat(
                                  APIUTILS.Financial(e.basicValue)
                                );
                                dto.taxValue += Number.parseFloat(
                                  APIUTILS.Financial(e.taxValue)
                                );
                                dto.totalValue += Number.parseFloat(
                                  APIUTILS.Financial(e.totalValue)
                                );
                                grandTotal += Number.parseFloat(
                                  APIUTILS.Financial(e.totalValue)
                                );
                              });

                              dto.basicValue = Number.parseFloat(
                                APIUTILS.Financial(dto.basicValue)
                              );
                              dto.taxValue = Number.parseFloat(
                                APIUTILS.Financial(dto.taxValue)
                              );
                              dto.totalValue = Number.parseFloat(
                                APIUTILS.Financial(dto.totalValue)
                              );

                              grandTotal += Number.parseFloat(
                                APIUTILS.Financial(dto.freightCharge)
                              );
                              setDTO({
                                ...dto,
                                grandTotal: Number.parseFloat(
                                  APIUTILS.Financial(grandTotal)
                                ),
                              });
                            }
                          }}
                          value={rowItems[index].qty}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 12 },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {rowItems[index].uomName}
                              </InputAdornment>
                            ),
                            style: { fontSize: 12 },
                          }}
                        ></TextField>
                      </td>

                      <td>
                        <TextField
                          id={`textfield-2-${index}`}
                          variant="standard"
                          disabled="true"
                          label="Rate"
                          
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "90px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "90px",
                            fontSize: 10,
                            marginBottom: 5,
                            marginLeft: 5,
                          }}
                          name="rate"
                          onChange={(e) => {
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = (
                              e.target.value
                            );
                            setRowItems(newArr);
                          }}
                          onBlur={(e) => {
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = Number(
                              e.target.value
                            );
                            newArr[index].basicValue = Number.parseFloat(
                              APIUTILS.Financial(
                                Number(e.target.value) * newArr[index].qty
                              )
                            );
                            setRowItems(newArr);
                            let grandTotal = 0.0;
                            dto.basicValue = 0.0;
                            dto.taxValue = 0.0;
                            dto.totalValue = 0.0;

                            newArr.forEach((e) => {
                              dto.basicValue += Number.parseFloat(
                                APIUTILS.Financial(e.basicValue)
                              );
                              dto.taxValue += Number.parseFloat(
                                APIUTILS.Financial(e.taxValue)
                              );
                              dto.totalValue += Number.parseFloat(
                                APIUTILS.Financial(e.totalValue)
                              );
                              grandTotal += Number.parseFloat(
                                APIUTILS.Financial(e.totalValue)
                              );
                            });

                            dto.basicValue = Number.parseFloat(
                              APIUTILS.Financial(dto.basicValue)
                            );
                            dto.taxValue = Number.parseFloat(
                              APIUTILS.Financial(dto.taxValue)
                            );
                            dto.totalValue = Number.parseFloat(
                              APIUTILS.Financial(dto.totalValue)
                            );

                            grandTotal += Number.parseFloat(
                              APIUTILS.Financial(dto.freightCharge)
                            );
                            setDTO({
                              ...dto,
                              grandTotal: Number.parseFloat(
                                APIUTILS.Financial(grandTotal)
                              ),
                            });
                          }}
                          value={rowItems[index].rate}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 12 },
                          }}
                          InputProps={{
                            style: { fontSize: 12 },
                          }}
                        ></TextField>
                      </td>
                      <td>
                        <TextField
                          id={`textfield-3-${index}`}
                          variant="standard"
                          disabled={true}
                          label="Basic Value"
                          
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "90px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "90px",
                            fontSize: 10,
                            marginBottom: 5,
                            marginLeft: 5,
                          }}
                          name="basicValue"
                          onChange={(e) => {
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = (
                              e.target.value
                            );
                            setRowItems(newArr);
                          }}
                          onBlur={(e) => {
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = Number(
                              e.target.value
                            );
                            setRowItems(newArr);
                          }}
                          value={rowItems[index].basicValue}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 12 },
                          }}
                          InputProps={{
                            style: { fontSize: 12 },
                          }}
                        ></TextField>
                      </td>
                      <td>
                        <IconButton
                          style={{
                            marginTop: 10,
                          }}
                          disabled={id > 0}
                          id={`button-tax-${index}`}
                          aria-label=""
                          onClick={() => {
                            setCurrentIndex(index);
                            if (id > 0 && !isEdit) {
                              setViewOnlyModel(true);
                            }
                            setFormData(rowItems[index]);
                            setOpenModal(true);
                          }}
                        >
                          <img src={apply_icon} alt="" />
                        </IconButton>
                      </td>
                      <td>
                        <TextField
                          id={`textfield-4-${index}`}
                          variant="standard"
                          label="Tax Value"
                          
                          onWheel={(event) => event.target.blur()}
                          disabled={true}
                          style={{
                            minWidth: "90px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "90px",
                            fontSize: 10,
                            marginBottom: 5,
                            marginLeft: 5,
                          }}
                          name="taxValue"
                          value={item.taxValue}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 12 },
                          }}
                          InputProps={{
                            style: { fontSize: 12 },
                          }}
                        ></TextField>
                      </td>
                      <td>
                        <TextField
                          id={`textfield-5-${index}`}
                          variant="standard"
                          label="Total Value"
                          disabled={true}
                          
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "90px",
                            minHeight: "38.5px",
                            height: "38.5px",
                            width: "90px",
                            fontSize: 10,
                            marginBottom: 5,
                            marginLeft: 5,
                          }}
                          name="totalValue"
                          value={item.totalValue}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 12 },
                          }}
                          InputProps={{
                            style: { fontSize: 12 },
                          }}
                        ></TextField>
                      </td>
                      <td>
                        {id <= 0 && index >= 0 && (
                          <IconButton
                            style={{ marginTop: 10 }}
                            aria-label="minus"
                            onClick={() => {
                              removeObjectFromArray(item.rowId);
                            }}
                          >
                            <img src={minus_icon} alt="" />
                          </IconButton>
                        )}
                      </td>
                      <td>
                        {id <= 0 && (
                          <IconButton
                            style={{ marginTop: 10 }}
                            id={`button-plus-${index}`}
                            aria-label="plus"
                            onClick={() => {
                              addObjectToArray();
                            }}
                          >
                            <img src={plus_icon} alt="" />
                          </IconButton>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </TableBody>
            </table>
          </div>
        </div>
      )}

      <div
        style={{
          backgroundColor: WHITE,
          borderRadius: "5px",
          minWidth: "90%",
          minHeight: "140px",
          marginBottom: 10,
          border: "0.1px solid #eaeaea",
          display: "flex",
          flexDirection: "column",
          // width: "100%",
          overflowX: "auto",
        }}
      >
        <div
          style={{
            width: "1300px",
          }}
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <TableBody>
              <tr style={{ height: "30px" }}></tr>
              <tr
                style={{
                  backgroundColor: ANTI_FLASH_WHITE,
                  height: "40px",
                }}
              >
                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      flexGrow: 0,
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                    }}
                  >
                    {FREIGHT_CHARGE}
                  </Typography>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <input
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setDTO({...dto, [name]: value});
                    }}
                    onWheel={(event) => event.target.blur()}
                    name="freightCharge"
                    value={dto.freightCharge}
                    onBlur={(m) => {
                      let grandTotal = 0.0;
                      dto.basicValue = 0.0;
                      dto.taxValue = 0.0;
                      dto.totalValue = 0.0;

                      rowItems.forEach((e) => {
                        dto.basicValue += Number.parseFloat(
                          APIUTILS.Financial(e.basicValue)
                        );
                        dto.taxValue += Number.parseFloat(
                          APIUTILS.Financial(e.taxValue)
                        );
                        dto.totalValue += Number.parseFloat(
                          APIUTILS.Financial(e.totalValue)
                        );
                        grandTotal += Number.parseFloat(
                          APIUTILS.Financial(e.totalValue)
                        );
                      });

                      dto.basicValue = Number.parseFloat(
                        APIUTILS.Financial(dto.basicValue)
                      );
                      dto.taxValue = Number.parseFloat(
                        APIUTILS.Financial(dto.taxValue)
                      );
                      dto.totalValue = Number.parseFloat(
                        APIUTILS.Financial(dto.totalValue)
                      );

                      if (!isNaN(m.target.value))
                        dto.freightCharge = Number(m.target.value);

                      grandTotal += Number.parseFloat(
                        APIUTILS.Financial(dto.freightCharge)
                      );
                      setDTO({
                        ...dto,
                        grandTotal: Number.parseFloat(
                          APIUTILS.Financial(grandTotal)
                        ),
                        freightCharge: Number.parseFloat(
                          APIUTILS.Financial(dto.freightCharge)
                        ),
                      });
                    }}
                    disabled={id > 0 && !isEdit}
                    style={{ width: "80px" }}
                  ></input>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr
                style={{
                  backgroundColor: ANTI_FLASH_WHITE,
                  height: "40px",
                }}
              >
                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      flexGrow: 0,
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                    }}
                  >
                    {TOTAL}
                  </Typography>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      marginTop: 10,
                      flexGrow: 0,
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                      //marginRight: 10,
                      marginLeft: 5,
                    }}
                  >
                    ₹ {dto.basicValue}
                  </Typography>
                </td>
                <td></td>
                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      marginTop: 10,
                      flexGrow: 0,
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                      marginLeft: 5,
                    }}
                  >
                    ₹ {dto.taxValue}
                  </Typography>
                </td>
                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      marginTop: 10,
                      flexGrow: 0,
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                      marginLeft: 5,
                    }}
                  >
                    ₹ {dto.totalValue}
                  </Typography>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr
                style={{
                  backgroundColor: ANTI_FLASH_WHITE,
                  height: "40px",
                }}
              >
                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      flexGrow: 0,
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                    }}
                  >
                    {GRAND_TOTAL}
                  </Typography>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <Typography
                    color={CHARLESTON_GREEN}
                    style={{
                      marginTop: 10,
                      flexGrow: 0,
                      fontFamily: "sans-serif",
                      fontSize: "18px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.32,
                      letterSpacing: "normal",
                      marginLeft: 5,
                    }}
                  >
                    ₹ {dto.grandTotal}
                  </Typography>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </TableBody>
          </table>
        </div>
      </div>
    </SideNav>
  );
}
