import { Button, IconButton, TableBody, Typography } from "@mui/material";
import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  SPANISH_GREEN,
  WHITE,
} from "../../../../../resources/constant/colors/theme";

import { ThreeCircles } from "react-loader-spinner";
import { APISALES } from "../../../../../apihandler/sales";
import { setAlertTitleText } from "../../../../../component/modal/alertModal";
import { UPLOAD } from "../../../../../resources/constant/strings/string";
import { UploadComponent } from "./uploadComponent";
import "./uploadModal.css";

function UploadModal({ setOpenUploadModal, addModel }) {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const style = {
    paddingTop: 10,
    position: "absolute",
    top: "50%",
    left: "50%",
    color: SPANISH_GREEN,
    transform: "translate(-50%, -50%)",
    zIndex: 2,
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();

    const formData = new FormData();
    formData.append("file", file, file.name);
    setUploading(true);

    return APISALES.SalesInvoiceUpload(formData)
      .then((resp) => {
        const contentType = resp.headers.get("content-type");
        setUploading(false);

        if (contentType.startsWith("application/json")) {
          resp.json().then((x) => {
            if (x.statusCode === 500) {
              setAlertTitleText(x.message);
              return;
            }
            setAlertTitleText("", "Uploaded Successfully");
            addModel(x.response.invoiceDetail);
          });
          setOpenUploadModal(false);
          return;
        }

        return Promise.reject(resp.blob());
      })
      .catch((c) => {
        c.then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "Errors.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
        });

        return Promise.resolve(true);
      });
  };

  function validation() {}

  return (
    <div className="modalBackgroundForInvoiceUploadModal">
      {uploading && (
        <div style={style}>
          <ThreeCircles
            visible={true}
            height="210"
            width="240"
            outerCircleColor={SPANISH_GREEN}
            innerCircleColor="#eeeeee"
            middleCircleColor={SPANISH_GREEN}
            ariaLabel="eNirmaan-loading"
            color={SPANISH_GREEN}
            wrapperStyle={{}}
            wrapperClass=""
          />
          Please wait while we upload the file
        </div>
      )}
      {/* div for top right close button */}
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <IconButton
          style={{ width: "auto", align: "center", marginRight: 5 }}
          onClick={() => setOpenUploadModal(false)}
        >
          <CloseIcon />
        </IconButton>
      </div>
      {/* top right close button div closed */}

      <div
        style={{
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <table style={{ width: "90%" }}>
          <TableBody>
            <tr>
              <td>
                <Typography
                  fontSize={18}
                  fontWeight={600}
                  fontspacing={"normal"}
                  textTransform="capitalize"
                  align="left"
                >
                  {UPLOAD}
                </Typography>
              </td>
            </tr>
          </TableBody>
        </table>
        {/* this is upload component to select files */}
        <UploadComponent uploadHandler={setFile} />
        {/* this is upload component to selec fiels */}
      </div>

      {/* div for buttons */}
      <div
        style={{
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          display: "flex",
          marginTop: 10,
        }}
      >
        <Button
          variant="outlined"
          sx={{
            borderColor: SPANISH_GREEN,
          }}
          style={{
            textTransform: "capitalize",
            fontSize: 14,
            color: SPANISH_GREEN,
            minWidth: "180px",
            minHeight: "45px",
            margin: 10,
          }}
          onClick={() => {
            setOpenUploadModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          style={{
            backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: WHITE,
            minWidth: "180px",
            minHeight: "45px",
            margin: 10,
          }}
          onClick={onFormSubmit}
        >
          Save
        </Button>
      </div>
      {/* div for buttons closed */}
      {/* pop up div closed  */}
    </div>
  );
}

export default UploadModal;
