import { Constants } from "./constants";
export const APIREPORTS = {
  PurchaseOrderReport: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/report/order/purchase`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },

  PurchaseOrderReportExport: (dto) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/report/order/purchase/generate`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(dto),
      }
    );
  },

  GoodsReceivedReport: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/report/goodsreceived`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },

  GoodsReceivedReportExport: (dto) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/report/goodsreceived/generate`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(dto),
      }
    );
  },

  StockStatementReport: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/report/stock`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },

  StockStatementReportExport: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/report/stock/generate`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },

  PurchaseRatioReportConfigs: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/report/ratio/purchase`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },

  PurchaseRatioReportExport: (dto) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/report/ratio/purchase/generate`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(dto),
      }
    );
  },

  ExcavatorSummartyReport: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/report/excavator`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },

  ExcavatorSummartyReportExport: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/report/excavator/generate`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },

  SubContractorBillingReport: (dto) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/report/subcontractor/billing`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(dto),
      }
    );
  },

  SubContractorBillingReportExport: (dto) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/report/subcontractor/billing/generate`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(dto),
      }
    );
  },

  BalanceBillingReport: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/report/balancebilling`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },

  BalanceBillingReportExport: (dto) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/report/balancebilling/generate`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(dto),
      }
    );
  },

  SubContractorFoodAdvanceReport: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/report/foodadvances`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },
  SubContractorFoodAdvanceReportExport: (dto) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/report/foodadvances/generate
`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(dto),
      }
    );
  },
  SubContractorChecklistReport: (dto) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/report/subcontractor/checklist`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(dto),
      }
    );
  },

  SubContractorAdvanceRequestSummaryReport: (dto) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/report/subcontractor/advancerequest`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(dto),
      }
    );
  },

  SubContractorAdvanceRequestSummaryReportExport: (dto) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/report/subcontractor/advancerequest/generate`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(dto),
      }
    );
  },

  AttendanceReport: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/report/attendance`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },

  AttendanceReportExport: (dto) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/report/attendance/generate`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(dto),
      }
    );
  },

  SubContractorBillingSummaryReport: (dto) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/report/subcontractor/billing/summary`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(dto),
      }
    );
  },
  SubContractorBillingSummaryReportExport: (dto) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/report/subcontractor/billing/summary/generate`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(dto),
      }
    );
  },

  SubContractorCheckListExport: (dto) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/report/subcontractor/checklist/generate`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(dto),
      }
    );
  },
};
