import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  getClosureStatuses,
  getItemCategories,
  getItemList,
  getItems,
  getSiteCategories,
  getSites,
  getUoms,
  globalFilter,
  itemCategoryCellRenderer,
  itemCellRenderer,
  uomCellRenderer,
} from "../../../../../component/cellRenderers/renderers";
import SideNav from "../../../../../component/layouts/sideNav";
import {
  CANCEL,
  CUSTOMER_MANAGEMENT,
  DOWNLOAD,
  PAYMENT_TERMS,
  SALES_ORDERS,
  SALES_ORDERS_DETAIL,
  SAVE,
  UPLOAD,
} from "../../../../../resources/constant/strings/string";

import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { ThreeCircles } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { APILOGIN } from "../../../../../apihandler/login";
import { APISALES } from "../../../../../apihandler/sales";
import { APIUTILS } from "../../../../../apihandler/utils";
import {
  LIGHT_GREY_BACKGROUND,
  PRUSSIAN_BLUE,
  SPANISH_GREEN,
  WHITE,
} from "../../../../../resources/constant/colors/theme";
import {
  PATH_LOGIN,
  PATH_SALES_ORDERS,
} from "../../../../../resources/constant/url/url";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  setAlertConfirmDeletion,
  setAlertTitleText,
} from "../../../../../component/modal/alertModal";
import EditModal from "./editModal";
import Modal from "./modal";
import UploadModal from "./uploadModal";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  color: SPANISH_GREEN,
  transform: "translate(-50%, -50%)",
  zIndex: 2,
};
export default function SalesOrdersDetail() {
  let { id } = useParams();

  const [dataFilledVariable, setDataFilledVariable] = useState(false);
  const [dataReady, setDataReady] = useState(false);

  const location = useLocation();
  const isEdit = location.state?.isEdit;

  let initialValue = {
    id: 0,
    salesOrderDate: dayjs(),
    salesOrderNo: "",
    customerId: 0,
    categoryId: 0,
    totalSupplyAmount: 0.0,
    totalInstallationAmount: 0.0,
    totalAmount: 0.0,
    grandTotal: 0.0,
    status: "N",
    modifiedBy: 0,
    salesOrderDetail: [],
    paymentTerms: {
      supplyTerms1: 0,
      supplyTerms2: 0,
      supplyTerms3: 0,
      supplyTerms4: 0,
      installationTerms1: 0,
      installationTerms2: 0,
      installationTerms3: 0,
      installationTerms4: 0,
    },
  };

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [formTitle, setFormTitle] = useState("Payment Terms ( In Percentage )");
  const [orderForm, setOrderForm] = useState(initialValue);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (isNaN(value)) setOrderForm({ ...orderForm, [name]: value });
      else setOrderForm({ ...orderForm, [name]: Number(value) });
    }
  };

  const handlePaymentTerms = (data) => {
    setOrderForm({
      ...orderForm,
      paymentTerms: data,
    });
  };

  const navigate = useNavigate();
  const onFormSubmit = (e) => {
    e.preventDefault();

    validation();
    //
  };
  function validation() {
    orderForm.salesOrderDetail.forEach((e,i)=>{
      if(e.id < 0)
        e.id=0;
    });

    //validation and other api calls to be made here
    if (orderForm.id <= 0){
      APISALES.AddSalesOrder(orderForm)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setAlertTitleText(
              "Success",
              "The record was inserted successfully"
            );
            navigate(PATH_SALES_ORDERS + "/" + location.state.date);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
      } else {
      setAlertConfirmDeletion(
        "Confirmation needed",
        "All the existing data will be overwritten, Please confirm"
      ).then((consent) => {
        if (!consent.isConfirmed) return;

        APISALES.UpdateSalesOrder(orderForm.id, orderForm)
          .then((response) => response.json())
          .then((data) => {
            if (data.statusCode === 200) {
              setAlertTitleText(
                "Success",
                "The record was updated successfully"
              );
              navigate(PATH_SALES_ORDERS + "/" + location.state.date);
            } else {
              setAlertTitleText(
                "Oops...",
                `Something went wrong...${data.message}`
              );
            }
          });
      });
    }
  }

  const [dateValue, setDateValue] = useState(dayjs());

  const gridRef = useRef();

  useEffect(() => {
    if (!APILOGIN.GetIsLoggedIn()) navigate(PATH_LOGIN);
  }, []);

  const onAddDetail = (data, updateRow = true) => {
    let detailData = onAdd(data);
    let totalSupplyAmount = 0.0;
    let totalInstallationAmount = 0.0;
    let totalAmount = 0.0;

    const salesOrderDetail = orderForm.salesOrderDetail.concat(detailData);

    salesOrderDetail.forEach((e, i) => {
      e.rowId = i;
      totalSupplyAmount += Number.parseFloat(APIUTILS.Financial(e.supplyAmt));
      totalInstallationAmount += Number.parseFloat(
        APIUTILS.Financial(e.installationAmt)
      );
    });

    try {
      if (!updateRow)
        gridRef.current.api.applyTransaction({ update: detailData });
    } catch (e) {}

    totalAmount = APIUTILS.Financial(
      totalSupplyAmount + totalInstallationAmount
    );
    setOrderForm({
      ...orderForm,
      totalAmount: Number(APIUTILS.Financial(totalAmount)),
      totalInstallationAmount: Number(
        APIUTILS.Financial(totalInstallationAmount)
      ),
      totalSupplyAmount: Number(APIUTILS.Financial(totalSupplyAmount)),
      grandTotal: Number(APIUTILS.Financial(totalAmount)),
      salesOrderDetail: salesOrderDetail,
    });

    return salesOrderDetail;
  };

  const fetchOrderDetail = () => {
    if (id <= 0) {
      return Promise.resolve([]);
    }

    return APISALES.SalesOrderDetail(id)
      .then((resp) => resp.json())
      .then((resp) => {
        if(!resp.status){
          setAlertTitleText(
            "Oops...",
            `Something went wrong...${resp.message}`
          );
          return Promise.resolve([]);
        }
        const sites = getSites();
        const selectedCustomer = sites.filter(
          (v, i) => v.value === resp.response.customerId
        );

        resp.response.salesOrderDetail = resp.response.salesOrderDetail.sort(
          (a, b) => b.id - a.id
        );

        const detailData = onAddDetail(resp.response.salesOrderDetail, false);

        setOrderForm({
          ...orderForm,
          id: resp.response.id,
          salesOrderDate: resp.response.salesOrderDate
            .split("-")
            .reverse()
            .join("-"),
          salesOrderNo: resp.response.salesOrderNo,
          customerId: resp.response.customerId,
          categoryId:
            selectedCustomer.length > 0
              ? selectedCustomer[0].dependentvalue
              : 0,
          totalSupplyAmount: resp.response.totalSupplyAmount,
          totalInstallationAmount: resp.response.totalInstallationAmount,
          totalAmount: resp.response.totalAmount,
          grandTotal: resp.response.grandTotal,
          status: resp.response.status,
          modifiedBy: resp.response.modifiedBy,
          salesOrderDetail: detailData,
          paymentTerms: resp.response.paymentTerms,
        });
        return Promise.resolve(detailData);
      });
  };

  const siteCategories = getSiteCategories();
  const sites = getSites();
  const closureStatuses = getClosureStatuses();

  const siteCategoryProps = {
    options: siteCategories,
    getOptionLabel: (option) => option?.name || "",
  };

  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };

  const closureStatusProps = {
    options: closureStatuses,
    getOptionLabel: (option) => option?.name || "",
  };

  const onUpdate = (model) => {
    model.forEach((e, i) => {
      //By item, get Uom/Category etc
      const items = itemList.filter((v, i) => {
        return v.id === e.itemId;
      });
      if (items.length <= 0) {
        return;
      }

      e.totalAmt = APIUTILS.Financial(e.supplyAmt + e.installationAmt);
    });

    if (isEdit) {
      try {
        gridRef.current.api.applyTransaction({ update: model });
      } catch (e) {}

      const salesOrderDetail = orderForm.salesOrderDetail.map((obj) => {
        if (model.filter((x) => x.id === obj.id).length > 0) {
          return { ...obj, ...model[0] };
        }
        return obj;
      });

      let totalSupplyAmount = 0.0;
      let totalInstallationAmount = 0.0;
      let totalAmount = 0.0;

      salesOrderDetail.forEach((e, i) => {
        totalSupplyAmount += Number.parseFloat(APIUTILS.Financial(e.supplyAmt));
        totalInstallationAmount += Number.parseFloat(
          APIUTILS.Financial(e.installationAmt)
        );
      });

      totalAmount = APIUTILS.Financial(
        totalSupplyAmount + totalInstallationAmount
      );
      setOrderForm({
        ...orderForm,
        totalAmount: Number(APIUTILS.Financial(totalAmount)),
        totalInstallationAmount: Number(
          APIUTILS.Financial(totalInstallationAmount)
        ),
        totalSupplyAmount: Number(APIUTILS.Financial(totalSupplyAmount)),
        grandTotal: Number(APIUTILS.Financial(totalAmount)),
        salesOrderDetail: salesOrderDetail,
      });
    }
  };

  const onAdd = (data) => {
    let detailData = [];

    data.forEach((e, i) => {
      //By item, get Uom/Category etc
      const items = itemList.filter((v, i) => {
        return v.id === e.itemId;
      });
      if (items.length <= 0) {
        return;
      }
      const item = items[0];
      const categoryId = item.categoryId;
      const uomId = item.stockUOMId;

      let rowData = [];
      gridRef.current.api.forEachNode((node) => rowData.push(node.data));

      const orderDetail = {
        id: e?.id > 0? e.id:Number((Math.random() * -10000000).toFixed(0)),
        itemId: e.itemId,
        itemCode: item.itemCode,
        qty: e.qty,
        invoiceQty: e?.invoiceQty,
        supplyRate: e.supplyRate,
        installationRate: e.installationRate,
        supplyAmt: APIUTILS.Financial(e.qty * e.supplyRate),
        installationAmt: APIUTILS.Financial(e.qty * e.installationRate),
        categoryId,
        uomId,
      };

      orderDetail.totalAmt = APIUTILS.Financial(
        orderDetail.supplyAmt + orderDetail.installationAmt
      );

      detailData.push(orderDetail);
    });

    if (!isEdit) {
      try {
        const rowData = [];
        gridRef.current.api.forEachNode(function (node) {
          rowData.push(node.data);
        });
        gridRef.current.api.applyTransaction({
          remove: rowData,
        });
      } catch (e) {}
    }

    gridRef.current.api.applyTransaction({ add: detailData });

    return detailData;
  };

  const [selectedRowId, setSelectedRowId] = useState(0);

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId(event.api.getSelectedRows()[0].id);
    else setSelectedRowId(0);
  };
  const [openEditModal, setEditOpenModal] = useState(false);
  const [editFormTitle, setEditFormTitle] = useState("Add");
  const [editFormData, setEditFormData] = useState(null);

  const columnDefs = [
    {
      flex: 1.4,
      headerName: "Actions",
      field: "id",
      sortable: false,
      filter: false,
      hide: !isEdit,
      floatingFilter: false,
      cellRenderer: (params) =>
        gridRef.current.api.getDisplayedRowAtIndex(
          gridRef.current.api.getLastDisplayedRow()
        ).data.rowId === params.data?.rowId ? (
          <div style={{ display: "flex", justifyContent: "left" }}>
            <IconButton
              aria-label="Add"
              onClick={() => {
                setEditFormTitle("Add");
                setEditFormData({
                  id: 0,
                  categoryId: 0,
                  itemCode: "",
                  itemId: 0,
                  qty: 0,
                  supplyRate: 0,
                  installationRate: 0,
                  uomId: 0,
                  uomName: "",
                  supplyAmt: 0,
                  installationAmt: 0,
                  totalAmount: 0,
                });
                setEditOpenModal(true);
              }}
            >
              {" "}
              <AddCircleOutlineIcon
                style={{ color: PRUSSIAN_BLUE, width: "20px" }}
              />
            </IconButton>
            <IconButton
              aria-label="edit"
              onClick={() => {
                setEditFormTitle("Edit");
                const m = orderForm.salesOrderDetail.filter(
                  (x) => x.id === params.data.id
                )[0];
                m.uomName = uomList.filter((x) => x.value === m.uomId)[0].name;
                setEditFormData(m);
                setEditOpenModal(true);
              }}
            >
              <EditIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
            </IconButton>
            {params.data.invoiceQty === null && (
              <IconButton
                aria-label="delete"
                onClick={() => {
                  setAlertConfirmDeletion(
                    "Confirmation needed",
                    "Do you want to delete? Please confirm"
                  ).then((consent) => {
                    if (!consent.isConfirmed) return;

                    const selectedData = gridRef.current.api.getSelectedRows();
                    gridRef.current.api.applyTransaction({
                      remove: selectedData,
                    });

                    let rowData = [];
                    gridRef.current.api.forEachNode((node) =>
                      rowData.push(node.data)
                    );

                    let totalSupplyAmount = 0.0;
                    let totalInstallationAmount = 0.0;
                    let totalAmount = 0.0;

                    rowData.forEach((e, i) => {
                      e.rowId = i;
                      totalSupplyAmount += Number.parseFloat(
                        APIUTILS.Financial(e.supplyAmt)
                      );
                      totalInstallationAmount += Number.parseFloat(
                        APIUTILS.Financial(e.installationAmt)
                      );
                    });

                    totalAmount = APIUTILS.Financial(
                      totalSupplyAmount + totalInstallationAmount
                    );
                    setOrderForm({
                      ...orderForm,
                      totalAmount: Number(APIUTILS.Financial(totalAmount)),
                      totalInstallationAmount: Number(
                        APIUTILS.Financial(totalInstallationAmount)
                      ),
                      totalSupplyAmount: Number(
                        APIUTILS.Financial(totalSupplyAmount)
                      ),
                      grandTotal: Number(APIUTILS.Financial(totalAmount)),
                      salesOrderDetail: rowData,
                    });
                  });
                }}
              >
                <DeleteForeverIcon
                  style={{ color: PRUSSIAN_BLUE, width: "20px" }}
                />
              </IconButton>
            )}
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "left" }}>
            <IconButton
              aria-label="edit"
              onClick={() => {
                setEditFormTitle("Edit");
                const m = orderForm.salesOrderDetail.filter(
                  (x) => x.id === params.data.id
                )[0];
                m.uomName = uomList.filter((x) => x.value === m.uomId)[0].name;
                setEditFormData(m);
                setEditOpenModal(true);
              }}
            >
              <EditIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
            </IconButton>
            {params.data.invoiceQty === null && (
              <IconButton
                aria-label="delete"
                onClick={() => {
                  setAlertConfirmDeletion(
                    "Confirmation needed",
                    "Do you want to delete? Please confirm"
                  ).then((consent) => {
                    if (!consent.isConfirmed) return;

                    const selectedData = gridRef.current.api.getSelectedRows();
                    gridRef.current.api.applyTransaction({
                      remove: selectedData,
                    });

                    let rowData = [];
                    gridRef.current.api.forEachNode((node) =>
                      rowData.push(node.data)
                    );

                    let totalSupplyAmount = 0.0;
                    let totalInstallationAmount = 0.0;
                    let totalAmount = 0.0;

                    rowData.forEach((e, i) => {
                      e.rowId = i;
                      totalSupplyAmount += Number.parseFloat(
                        APIUTILS.Financial(e.supplyAmt)
                      );
                      totalInstallationAmount += Number.parseFloat(
                        APIUTILS.Financial(e.installationAmt)
                      );
                    });

                    totalAmount = APIUTILS.Financial(
                      totalSupplyAmount + totalInstallationAmount
                    );
                    setOrderForm({
                      ...orderForm,
                      totalAmount: Number(APIUTILS.Financial(totalAmount)),
                      totalInstallationAmount: Number(
                        APIUTILS.Financial(totalInstallationAmount)
                      ),
                      totalSupplyAmount: Number(
                        APIUTILS.Financial(totalSupplyAmount)
                      ),
                      grandTotal: Number(APIUTILS.Financial(totalAmount)),
                      salesOrderDetail: rowData,
                    });
                  });
                }}
              >
                <DeleteForeverIcon
                  style={{ color: PRUSSIAN_BLUE, width: "20px" }}
                />
              </IconButton>
            )}
          </div>
        ),
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 1.5,
      headerName: "Item Category",
      field: "categoryId",
      valueGetter: (params) => {
        return itemCategoryCellRenderer({ value: params.data.categoryId });
      },
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },

    {
      flex: 1.5,
      headerName: "Item Name",
      field: "itemId",
      autoHeight: true,
      valueGetter: (params) => {
        return itemCellRenderer({ value: params.data.itemId });
      },
      wrapText: true,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },

    {
      headerName: "Qty",
      field: "uomId",
      valueGetter: (params) => {
        return (
          params.data.qty +
          "  " +
          uomCellRenderer({
            value: params.data.uomId,
          })
        );
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Invoiced Qty",
      field: "invoiceQty",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Supply Rate",
      field: "supplyRate",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Inst Rate",
      field: "installationRate",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Supply Amt",
      field: "supplyAmt",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Inst Amt",
      field: "installationAmt",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Total Amt",
      field: "totalAmt",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
  };

  const onGridReady = (params) => {
    if (id > 0) {
      fetchOrderDetail().then((detailData) => {
        setDataFilledVariable(true);
        setDataReady(true);
      });
    } else {
      try {
        const rowData = [];
        gridRef.current.api.forEachNode(function (node) {
          rowData.push(node.data);
        });
        gridRef.current.api.applyTransaction({
          remove: rowData,
        });
      } catch (e) {}
      setDataReady(true);
    }
  };
  const itemCategories = getItemCategories();

  const itemCategoryProps = {
    options: itemCategories,
    getOptionLabel: (option) => option?.name || "",
  };

  const items = getItems();

  const itemProps = {
    options: items,
    getOptionLabel: (option) => option?.name || "",
  };
  const itemList = getItemList();
  const uomList = getUoms();
  const uomProps = {
    options: uomList,
    getOptionLabel: (option) => option?.name || "",
  };

  const [rowItems, setRowItems] = useState([
    {
      id: 0,
      categoryId: 0,
      itemCode: "",
      itemId: 0,
      qty: 0,
      unit: 0,
      supplyRate: 0,
      instRate: 0,
      supplyAmt: 0,
      instAmount: 0,
      totalAmount: 0,
    },
  ]);
  const [rowId, setRowId] = useState(2);

  return (
    <SideNav
      currentComponent={CUSTOMER_MANAGEMENT}
      subComponent={SALES_ORDERS}
      subComponentPath={PATH_SALES_ORDERS}
      detailComponent={SALES_ORDERS_DETAIL}
    >
      {/* delete , add button div ends */}
      {openEditModal && (
        <EditModal
          formTitle={editFormTitle}
          setOpenModal={setEditOpenModal}
          model={editFormData}
          onAdd={onAddDetail}
          onUpdate={onUpdate}
        ></EditModal>
      )}
      {openUploadModal && (
        <UploadModal
          style={{ align: "center" }}
          addModel={onAddDetail}
          setOpenUploadModal={setOpenUploadModal}
        ></UploadModal>
      )}
      {openModal && (
        <Modal
          style={{ align: "center" }}
          setOpenModal={setOpenModal}
          model={orderForm.paymentTerms}
          onAdd={handlePaymentTerms}
          viewMode={id > 0 && !isEdit}
          formTitle={formTitle}
        ></Modal>
      )}
      {dataReady ? (
        <div>
          <form>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                marginBottom: 10,
                marginRight: 10,
              }}
            >
              <Button
                style={{
                  backgroundColor: SPANISH_GREEN,
                  textTransform: "capitalize",
                  fontSize: 14,
                  color: WHITE,
                  minWidth: "180px",
                  minHeight: "45px",
                }}
                onClick={() => {
                  navigate(PATH_SALES_ORDERS + "/" + location.state.date);
                }}
              >
                {CANCEL}
              </Button>

              {id <= 0 || isEdit ? (
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: SPANISH_GREEN,
                  }}
                  style={{
                    //backgroundColor: SPANISH_GREEN,
                    textTransform: "capitalize",
                    fontSize: 14,
                    color: SPANISH_GREEN,
                    minWidth: "180px",
                    minHeight: "45px",
                    marginRight: 10,
                  }}
                  onClick={onFormSubmit}
                >
                  {SAVE}
                </Button>
              ) : (
                ""
              )}
              {/* <Button
                variant="outlined"
                sx={{
                  borderColor: SPANISH_GREEN,
                }}
                style={{
                  //backgroundColor: SPANISH_GREEN,
                  textTransform: "capitalize",
                  fontSize: 14,
                  color: SPANISH_GREEN,
                  minWidth: "180px",
                  minHeight: "45px",
                  marginRight: 10,
                }}
                startIcon={<img src={print_icon} alt="" />}
                onClick={() => {}}
              >
                {PRINT}
              </Button> */}
              <Button
                style={{
                  backgroundColor: SPANISH_GREEN,
                  textTransform: "capitalize",
                  fontSize: 14,
                  color: WHITE,
                  minWidth: "180px",
                  minHeight: "45px",
                  marginRight: 10,
                }}
                onClick={() => {
                  let filename = "";
                  APISALES.SalesDownloadTemplate(orderForm.id)
                    .then((res) => {
                      const contentType = res.headers.get("content-type");
                      if (contentType.startsWith("application/json")) {
                        return Promise.reject(res.json());
                      } else {
                        const header = res.headers.get("Content-Disposition");
                        if (header !== null) {
                          const parts = header.split(";");
                          filename = parts[1].split("=")[1].replaceAll('"', "");
                        } else {
                          filename = "Sales Order BOQ Upload Template.xlsx";
                        }

                        return res.blob();
                      }
                    })
                    .then((blob) => {
                      if (blob !== null) {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        a.href = url;
                        a.download = filename;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                      }
                    })
                    .catch((m) => {
                      setAlertTitleText(
                        "Oops...",
                        `Something went wrong...${m.message}`
                      );
                    });
                }}
              >
                {DOWNLOAD}
              </Button>
            </div>
            <div
              style={{
                backgroundColor: LIGHT_GREY_BACKGROUND,
                borderRadius: "5px",
                minWidth: "90%",
                minHeight: "50px",
                padding: 20,
                marginBottom: 10,
                borderStyle: "solid",
                border: "1x",
                borderColor: "#eaeaea",
                display: "flex",
              }}
            >
              {/* inside grey background */}
              <div
                style={{
                  display: "flex",
                  minWidth: "100%",
                  flexDirection: "column",
                }}
              >
                <Table style={{ margin: 15 }}>
                  <TableBody>
                    <tr>
                      <td>
                        <TextField
                          variant="standard"
                          label="Sales Order No"
                          style={{
                            minWidth: "200px",
                            width: "200px",
                            minHeight: "48.5px",
                          }}
                          name="salesOrderNo"
                          disabled={id <= 0 || isEdit}
                          value={orderForm.salesOrderNo}
                          onChange={handleInputChange}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                        ></TextField>
                      </td>
                      <td>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label="Sales Order Date "
                            style={{
                              minWidth: "200px",
                              width: "200px",
                              minHeight: "48.5px",
                            }}
                            value={dayjs(orderForm.salesOrderDate)}
                            maxDate={dayjs()}
                            inputFormat="DD-MM-YYYY"
                            disabled={isEdit}
                            onChange={(newValue) => {
                              setDateValue(newValue);
                              setOrderForm({
                                ...orderForm,
                                salesOrderDate: newValue.format("YYYY-MM-DD"),
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                style={{
                                  minWidth: "200px",
                                  width: "200px",
                                  minHeight: "48.5px",
                                }}
                                InputLabelProps={{
                                  style: { color: "grey" },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </td>
                      <td>
                        <Autocomplete
                          filterOptions={globalFilter}
                          {...siteCategoryProps}
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          name="categoryId"
                          disabled={isEdit}
                          defaultValue={
                            siteCategoryProps.options.find(
                              (v) => v.value === orderForm.categoryId
                            ) || {}
                          }
                          onChange={(e, v) => {
                            if (v) {
                              if (isNaN(v?.value))
                                setOrderForm({
                                  ...orderForm,
                                  categoryId: v?.value,
                                });
                              else
                                setOrderForm({
                                  ...orderForm,
                                  categoryId: Number(v?.value),
                                });
                            } else {
                              setOrderForm({
                                ...orderForm,
                                categoryId: v?.value,
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Site Category"
                              required
                              variant="standard"
                              style={{
                                minWidth: "200px",
                                width: "200px",
                                minHeight: "48.5px",
                              }}
                              InputLabelProps={{
                                style: { color: "grey" },
                              }}
                            />
                          )}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Autocomplete
                          {...siteProps}
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          name="customerId"
                          disabled={isEdit}
                          defaultValue={
                            siteProps.options.find(
                              (v) => v.value === orderForm.customerId
                            ) || {}
                          }
                          filterOptions={(o, s) => {
                            let options = globalFilter(o, s);
                            let newOptions = [];
                            options.forEach((element) => {
                              if (
                                element.dependentvalue === orderForm.categoryId
                              )
                                newOptions.push(element);
                            });
                            return newOptions;
                          }}
                          onChange={(e, v) => {
                            if (v) {
                              if (v.dependentvalue !== orderForm.categoryId)
                                return;

                              if (isNaN(v?.value))
                                setOrderForm({
                                  ...orderForm,
                                  customerId: v?.value,
                                });
                              else
                                setOrderForm({
                                  ...orderForm,
                                  customerId: Number(v?.value),
                                });
                            } else {
                              setOrderForm({
                                ...orderForm,
                                customerId: v?.value,
                              });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Site Name"
                              required
                              variant="standard"
                              style={{
                                minWidth: "200px",
                                width: "200px",
                                minHeight: "48.5px",
                              }}
                              InputLabelProps={{
                                style: { color: "grey" },
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <Button
                          onClick={() => {
                            if (id > 0 && !isEdit) {
                              setAlertTitleText("Warning!", "Upload disabled");
                              return;
                            }
                            if (orderForm.customerId === 0) {
                              setAlertTitleText(
                                "Warning!",
                                "Please Select Site"
                              );
                            } else {
                              if (orderForm.id > 0) {
                                APISALES.SalesOrderInvoiceRaised(orderForm.id)
                                  .then((x) => x.json())
                                  .then((x) => {
                                    if (
                                      x.response.length > 0 ||
                                      orderForm.status === "SC" ||
                                      orderForm.status === "C"
                                    ) {
                                      setAlertTitleText("Upload disabled");
                                    } else {
                                      setOpenUploadModal(true);
                                    }
                                  });
                              } else {
                                setOpenUploadModal(true);
                              }
                            }
                          }}
                          variant="outlined"
                          sx={{
                            borderColor: SPANISH_GREEN,
                          }}
                          style={{
                            //backgroundColor: SPANISH_GREEN,
                            textTransform: "capitalize",
                            fontSize: 14,
                            color: SPANISH_GREEN,
                            minWidth: "180px",
                            minHeight: "45px",
                            marginRight: 10,
                          }}
                        >
                          {UPLOAD}
                        </Button>
                      </td>
                      <td>
                        {id <= 0 ? (
                          <Button
                            style={{
                              backgroundColor: SPANISH_GREEN,
                              textTransform: "capitalize",
                              fontSize: 14,
                              color: WHITE,
                              minWidth: "180px",
                              minHeight: "45px",
                              marginRight: 10,
                            }}
                            onClick={() => {
                              if (orderForm.customerId === 0) {
                                setAlertTitleText(
                                  "Warning!",
                                  "Please Select Site"
                                );
                              } else {
                                if (orderForm.id > 0) {
                                  APISALES.SalesOrderInvoiceRaised(orderForm.id)
                                    .then((x) => x.json())
                                    .then((x) => {
                                      if (
                                        x.response.length > 0 ||
                                        orderForm.status === "SC" ||
                                        orderForm.status === "C"
                                      ) {
                                        setAlertTitleText(
                                          "Payment terms cannot be changed"
                                        );
                                      } else {
                                        setOpenModal(true);
                                      }
                                    });
                                } else {
                                  setOpenModal(true);
                                }
                              }
                            }}
                          >
                            {PAYMENT_TERMS}
                          </Button>
                        ) : (
                          <Button
                            style={{
                              backgroundColor: SPANISH_GREEN,
                              textTransform: "capitalize",
                              fontSize: 14,
                              color: WHITE,
                              minWidth: "180px",
                              minHeight: "45px",
                              marginRight: 10,
                            }}
                            onClick={() => {
                              if (orderForm.categoryId === 0) {
                                setAlertTitleText(
                                  "Warning!",
                                  "Please Select Site"
                                );
                              } else {
                                if (orderForm.id > 0) {
                                  APISALES.SalesOrderInvoiceRaised(orderForm.id)
                                    .then((x) => x.json())
                                    .then((x) => {
                                      if (
                                        x.response.length > 0 ||
                                        orderForm.status === "SC" ||
                                        orderForm.status === "C"
                                      ) {
                                        setAlertTitleText(
                                          "Payment terms cannot be changed"
                                        );
                                      } else {
                                        setOpenModal(true);
                                      }
                                    });
                                } else {
                                  setOpenModal(true);
                                }
                              }
                            }}
                          >
                            {PAYMENT_TERMS}
                          </Button>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <TextField
                          variant="standard"
                          label="Total Amount "
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setOrderForm({ ...orderForm, [name]: value });
                          }}
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "200px",
                            width: "200px",
                            minHeight: "48.5px",
                          }}
                          name="totalAmount"
                          value={orderForm.totalAmount}
                          onBlur={handleInputChange}
                          disabled={id <= 0 || isEdit}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                ₹
                              </InputAdornment>
                            ),
                          }}
                        ></TextField>
                      </td>
                      <td>
                        <TextField
                          variant="standard"
                          label="Total Supply Amount "
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setOrderForm({ ...orderForm, [name]: value });
                          }}
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "200px",
                            width: "200px",
                            minHeight: "48.5px",
                          }}
                          name="totalSupplyAmount"
                          value={orderForm.totalSupplyAmount}
                          onBlur={handleInputChange}
                          disabled={id <= 0 || isEdit}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                ₹
                              </InputAdornment>
                            ),
                          }}
                        ></TextField>
                      </td>
                      <td>
                        {" "}
                        <TextField
                          variant="standard"
                          label="Total Installation Amount "
                          onChange={(e) => {
                            const { name, value } = e.target;
                            setOrderForm({ ...orderForm, [name]: value });
                          }}
                          onWheel={(event) => event.target.blur()}
                          style={{
                            minWidth: "200px",
                            width: "200px",
                            minHeight: "48.5px",
                          }}
                          name="totalInstallationAmount"
                          value={orderForm.totalInstallationAmount}
                          onBlur={handleInputChange}
                          disabled={id <= 0 || isEdit}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                ₹
                              </InputAdornment>
                            ),
                          }}
                        ></TextField>
                      </td>
                    </tr>
                  </TableBody>
                </Table>

                {/* inside grey background */}
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div style={style}>
          <ThreeCircles
            visible="true"
            height="256"
            width="256"
            outerCircleColor={SPANISH_GREEN}
            innerCircleColor="#eeeeee"
            middleCircleColor={SPANISH_GREEN}
            ariaLabel="eNirmaan-loading"
            color={SPANISH_GREEN}
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
      <div
        style={{
          backgroundColor: WHITE,
          borderRadius: "5px",
          minWidth: "90%",
          minHeight: "140px",
          marginBottom: 10,
          border: "0.1px solid #eaeaea",
          display: "flex",
          flexDirection: "column",
          // width: "100%",
          overflowX: "auto",
        }}
      >
        <div className="App">
          <div className="ag-theme-alpine" style={{ height: "570px" }}>
            <AgGridReact
              copyGroupHeadersToClipboard={true}
              enableRangeSelection={true}
              columnDefs={columnDefs}
              ref={gridRef}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              paginationAutoPageSize={true} pagination={true}
              rowSelection="multiple"
              //rowHeight={80}
              getRowId={(params) => params.data.id}
              onSelectionChanged={onRowSelectionChanged}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </SideNav>
  );
}
