import { Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { setAlertTitleText } from "../../../../component/modal/alertModal";

import selectCSV_icon from "../../../../resources/assets/images/selectCSV_icon.svg";
import { SPANISH_GRAY } from "../../../../resources/constant/colors/theme";
import {
  OR_DRAG_AND_DROP_IT_HERE,
  SELECT_CSV_FILE_TO_UPLOAD,
} from "../../../../resources/constant/strings/string";
export function UploadComponent({ setOpenModal, uploadHandler }) {
  const onDragEnter = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }, []);
  const onDragOver = useCallback((e) => {
    e.preventDefault();
    highlight(e);
    e.stopPropagation();
    return false;
  }, []);
  const onDragLeave = useCallback((e) => {
    e.stopPropagation();
    unHightLight(e);
    e.preventDefault();
    return false;
  }, []);
  const onDrop = useCallback((e) => {
    e.preventDefault();
    handleDrop(e);
    return false;
  }, []);

  useEffect(() => {
    window.addEventListener("mouseup", onDragLeave);
    window.addEventListener("dragenter", onDragEnter);
    window.addEventListener("dragover", onDragOver);
    window.addEventListener("drop", onDrop);
    return () => {
      window.removeEventListener("mouseup", onDragLeave);
      window.removeEventListener("dragenter", onDragEnter);
      window.removeEventListener("dragover", onDragOver);
      window.removeEventListener("drop", onDrop);
    };
  }, [onDragEnter, onDragLeave, onDragOver, onDrop]);

  const highlight = () => {
    const ele = document.querySelector(".upload-label");
    if (ele) {
      ele.style.backgroundColor = "#e9e9e9";
      ele.style.border = "2px dotted #999";
    }
  };

  const unHightLight = () => {
    const ele = document.querySelector(".upload-label");
    if (ele) {
      ele.style.backgroundColor = "#f6f6f6";
      ele.style.border = "unset";
    }
  };

  const handleDrop = (e) => {
    const dt = e.dataTransfer;
    const { files } = dt;
    handleFiles(files);
  };

  const handleFiles = (files) => {
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onloadend = function () {
      if (reader.EMPTY) {
        document.getElementById("fileName").textContent = "Select a file";
      } else {
        if (!files[0].name.toLowerCase().endsWith(".xlsx")) {
          setAlertTitleText("Invalid file");
          return;
        }
        uploadHandler(files[0]);
        document.getElementById("fileName").textContent =
          "Selected file: " + files[0].name;
      }
    };
  };

  return (
    <div id="drop-area" style={{ position: "relative" }}>
      <input
        type="file"
        id="fileElem"
        accept=".xlsx"
        onChange={(e) => {
          handleFiles(e.target.files);
        }}
      />
      <label className="upload-label" htmlFor="fileElem">
        <div className="upload-text">
          <img src={selectCSV_icon} alt="" />
        </div>
        <Typography
          style={{
            fontFamily: "Inter",
            fontSize: 16,
            fontstretch: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            fontStyle: "normal",
            userSelect: "none",
            marginTop: 20,
          }}
        >
          {SELECT_CSV_FILE_TO_UPLOAD}
        </Typography>
        <Typography
          style={{
            fontFamily: "Inter",
            fontSize: 12,
            fontstretch: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            fontStyle: "normal",
            userSelect: "none",
            marginTop: 10,
          }}
          color={SPANISH_GRAY}
        >
          {OR_DRAG_AND_DROP_IT_HERE}
        </Typography>
        <Typography
          id="fileName"
          style={{
            fontFamily: "Inter",
            fontSize: 12,
            fontstretch: "normal",
            lineHeight: "normal",
            letterSpacing: "normal",
            textAlign: "left",
            fontStyle: "normal",
            userSelect: "none",
            marginTop: 10,
          }}
          color="red"
        ></Typography>
      </label>
    </div>
  );
}
