import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, IconButton, TextField } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import React, { useEffect, useRef, useState } from "react";
import SideNav from "../../../../component/layouts/sideNav";
import {
  ADD,
  CHANGE_STATUS,
  DELETE,
  GO,
  MATERIAL_MANAGEMENT,
  PURCHASE_ORDER_INDENT,
} from "../../../../resources/constant/strings/string";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  closureStatusRenderer,
  setCompanies,
  setIndents,
  setItemCategories,
  setItemList,
  setItems,
  setSites,
  setSupplierCategories,
  setSuppliers,
  setTaxes,
  setUoms,
  setUsers,
  siteCellRenderer,
  supplierCellRenderer,
  userCellRenderer,
} from "../../../../component/cellRenderers/renderers";
import {
  LIGHT_GREY_BACKGROUND,
  PRUSSIAN_BLUE,
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { APILOGIN } from "../../../../apihandler/login";
import { APIMASTER } from "../../../../apihandler/master";
import { APIMATERIALMGMT } from "../../../../apihandler/materialmgmt";
import {
  setAlertConfirmDeletion,
  setAlertTitleText,
} from "../../../../component/modal/alertModal";
import {
  PATH_LOGIN,
  PATH_PURCHASE_ORDER,
  PATH_PURCHASE_ORDER_DETAIL_WITHOUT_PARAM,
} from "../../../../resources/constant/url/url";

export default function PurchaseOrder() {
  const changeStatusFunction = () => {
    if (selectedRowId <= 0) {
      setAlertTitleText("Oops...", `Please select a row...`);
      return;
    }

    Swal.fire({
      title: "Change Status",
      input: "select",
      inputOptions: {
        P: "Pending",
        MC: "Manually Closed",
      },
      inputPlaceholder: "Select",
      confirmButtonText: "Save",
      showCancelButton: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        APIMATERIALMGMT.PurchaseOrderChangeStatus({
          status: result.value,
          ids: [selectedRowId],
        })
          .then((x) => x.json())
          .then((x) => {
            if (x.statusCode === 200) {
              setAlertTitleText("Status Changed", "");
              fetchOrders(dateValue, toDateValue).then((data) => {
                try {
                  const rowData = [];
                  gridRef.current.api.forEachNode(function (node) {
                    rowData.push(node.data);
                  });
                  gridRef.current.api.applyTransaction({
                    remove: rowData,
                  });
                } catch (e) {}
                try {
                  gridRef.current.api.applyTransaction({ add: data });
                } catch (e) {}
              });
            } else {
              setAlertTitleText("Oops...", `Something went wrong...`);
            }
          });
        //api call to change the status in the database
      }
    });
  };
  let { date } = useParams();
  const [dateValue, setDateValue] = React.useState(null);
  const [toDateValue, setToDateValue] = React.useState(dayjs().endOf("month"));

  const navigate = useNavigate();

  const gridRef = useRef();

  useEffect(() => {
    if (!APILOGIN.GetIsLoggedIn()) navigate(PATH_LOGIN);
  }, []);

  const [selectedRowId, setSelectedRowId] = useState(0);

  const deleteModel = () => {
    if (selectedRowId <= 0) {
      return;
    }
    setAlertConfirmDeletion(
      "Confirmation needed",
      "Do you confirm on proceeding to delete this record?"
    ).then((consent) => {
      if (!consent.isConfirmed) return;

      console.log("delete the selected id: ", selectedRowId);
      APIMATERIALMGMT.DeletePurchaseOrder(selectedRowId)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.statusCode === 204) {
            const selectedData = gridRef.current.api.getSelectedRows();
            gridRef.current.api.applyTransaction({ remove: selectedData });
            setAlertTitleText("Success", "The record was deleted successfully");
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
          }
        });
    });
  };

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId(event.api.getSelectedRows()[0].id);
    else setSelectedRowId(0);
  };

  const columnDefs = [
    {
      autoHeight: true,
      wrapText: true,
      flex: 1.5,
      headerName: "PO NO",
      field: "poNumber",
      checkboxSelection: false,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      flex: 1,
      headerName: "PO Date",
      field: "date",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      autoHeight: true,
      wrapText: true,
    },
    {
      headerName: "Supplier Name",
      field: "supplierId",
      autoHeight: true,
      wrapText: true,
      valueGetter: (params) => {
        return supplierCellRenderer({ value: params.data.supplierId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Site",
      field: "siteId",
      autoHeight: true,
      wrapText: true,
      valueGetter: (params) => {
        return siteCellRenderer({ value: params.data.siteId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Grand Total",
      field: "grandTotal",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
      autoHeight: true,
      wrapText: true,
    },
    {
      headerName: "GRN No",
      field: "grnNo",
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      autoHeight: true,
      wrapText: true,
    },
    {
      headerName: "Status",
      field: "status",
      valueGetter: (params) => {
        return closureStatusRenderer({ value: params.data.status });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      autoHeight: true,
      wrapText: true,
    },
    {
      headerName: "Modified By",
      field: "modifiedBy",
      valueGetter: (params) => {
        return userCellRenderer({ value: params.data.modifiedBy });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
      autoHeight: true,
      wrapText: true,
    },
    {
      flex: 1,
      headerName: "Details",
      field: "id",
      sortable: false,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {params.data.status === "P" || params.data.status === "N" ? (
            <IconButton
              aria-label="edit"
              onClick={() => {
                navigate(
                  PATH_PURCHASE_ORDER_DETAIL_WITHOUT_PARAM + params.data.id,
                  {
                    state: {
                      date: dateValue.format("YYYY-MM-DD"),
                      isEdit: true,
                    },
                  }
                );
              }}
            >
              <EditIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
            </IconButton>
          ) : (
            ""
          )}
          <IconButton
            aria-label="view"
            onClick={() => {
              navigate(
                PATH_PURCHASE_ORDER_DETAIL_WITHOUT_PARAM + params.data.id,
                {
                  state: {
                    date: dateValue.format("YYYY-MM-DD"),
                    isEdit: false,
                  },
                }
              );
            }}
          >
            <VisibilityIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
          </IconButton>
          <IconButton
            aria-label="edit"
            onClick={() => {
              let filename = "";
              APIMATERIALMGMT.PurchaseOrderPrintPDF(params.data.id)
                .then((res) => {
                  setAlertTitleText("Please wait...", "Generating print...");
                  const contentType = res.headers.get("content-type");
                  if (contentType.startsWith("application/json")) {
                    return Promise.reject(res.json());
                  } else {
                    const header = res.headers.get("Content-Disposition");
                    if (header !== null) {
                      const parts = header.split(";");
                      filename = parts[1].split("=")[1].replaceAll('"', "");
                    } else {
                      filename = "PurchaseOrder.html";
                    }

                    return res.blob();
                  }
                })
                .then((blob) => {
                  if (blob !== null) {
                    blob.text().then((x) => {
                      let html = x;
                      html = html.replace(
                        "<script>",
                        '<script type="text/javascript">'
                      );
                      let blob = new Blob([html], { type: "text/html" });
                      let url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
                    
                      let win = window.open(url, '_blank', '');
                      setTimeout(()=>{
                          win.print();
                      }, 2000);
                    });
                  }
                })
                .catch((m) => {
                  setAlertTitleText(
                    "Oops...",
                    `Something went wrong...${m.message}`
                  );
                });
            }}
          >
            <PrintIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
          </IconButton>
        </div>
      ),
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,

    wrapHeaderText: true,
    autoHeaderHeight: true,
  };

  const fetchOrders = (d1, d2) => {
    return APIMATERIALMGMT.PurchaseOrders(
      d1.format("YYYY-MM-DD"),
      d2.format("YYYY-MM-DD")
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) {
          setAlertTitleText("Oops..", `An error occurred ${resp.message}`);
          return;
        }
        const data = resp.response.sort((a, b) => b.id - a.id);
        return Promise.resolve(data);
      });
  };

  const onGridReady = (params) => {
    if (date) {
      setDateValue(dayjs(date));
    } else {
      setDateValue(dayjs().startOf("month"));
    }

    APIMASTER.UomOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setUoms(resp.response);
      });

    APIMASTER.TaxOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setTaxes(resp.response);
      });

    APIMASTER.ItemCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setItemCategories(resp.response);
      });

    APIMASTER.SupplierCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSupplierCategories(resp.response);
      });

    APIMATERIALMGMT.IndentOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setIndents(resp.response);
      });

    APIMASTER.SupplierOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSuppliers(resp.response);
      });

    APIMASTER.SiteOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSites(resp.response);
      });

    APIMASTER.UserOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setUsers(resp.response);
      });

    APIMASTER.CompanyOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setCompanies(resp.response);
      });

    APIMASTER.Items()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        let itemOptions = [];
        resp.response.forEach((element) => {
          itemOptions.push({
            name: element.itemName,
            value: element.id,
            dependentvalue: element.categoryId,
          });
        });
        setItems(itemOptions);
        setItemList(resp.response);
      });

    if (date) {
      fetchOrders(dayjs(date), dayjs(toDateValue)).then((data) => {
        params.api.applyTransaction({ add: data });
      });
    } else {
      fetchOrders(dayjs().startOf("month"), dayjs().endOf("month")).then(
        (data) => {
          params.api.applyTransaction({ add: data });
        }
      );
    }
  };

  return (
    <SideNav
      currentComponent={MATERIAL_MANAGEMENT}
      subComponent={PURCHASE_ORDER_INDENT}
      subComponentPath={PATH_PURCHASE_ORDER}
      detailComponent=""
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginBottom: 10,
          marginRight: 10,
        }}
      >
        <Button
          style={{
            backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: WHITE,
            minWidth: "180px",
            minHeight: "45px",
          }}
          onClick={() => {
            navigate(PATH_PURCHASE_ORDER_DETAIL_WITHOUT_PARAM + 0, {
              state: { date: dateValue.format("YYYY-MM-DD"), isEdit: null },
            });
          }}
        >
          {ADD}
        </Button>

        <Button
          onClick={() => {
            deleteModel();
          }}
          variant="outlined"
          sx={{
            borderColor: SPANISH_GREEN,
          }}
          style={{
            //backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: SPANISH_GREEN,
            minWidth: "180px",
            minHeight: "45px",
            marginRight: 10,
          }}
        >
          {DELETE}
        </Button>
        <Button
          style={{
            backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: WHITE,
            minWidth: "180px",
            minHeight: "45px",
            marginRight: 10,
          }}
          onClick={() => {
            changeStatusFunction();
          }}
        >
          {CHANGE_STATUS}
        </Button>
      </div>
      {/* delete , add button div ends */}
      <div
        style={{
          backgroundColor: LIGHT_GREY_BACKGROUND,
          borderRadius: "5px",
          minWidth: "90%",
          minHeight: "50px",
          padding: 20,
          marginBottom: 10,
          borderStyle: "solid",
          border: "1x",
          borderColor: "#eaeaea",
          display: "flex",
        }}
      >
        <div style={{ display: "flex" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              id="dateId"
              label="From Date"
              value={dateValue ? dateValue : dateValue}
              //minDate={dayjs("2017-01-01")}
              onChange={(newValue) => {
                setDateValue(newValue);
                //setToDateValue(dayjs(newValue).endOf("month"));
              }}
              inputFormat="DD-MM-YYYY"
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              id="toDateId"
              label="To Date"
              value={toDateValue}
              //minDate={dayjs("2017-01-01")}
              onChange={(newValue) => {
                setToDateValue(newValue);
              }}
              inputFormat="DD-MM-YYYY"
              renderInput={(params) => (
                <TextField {...params} style={{ marginLeft: 10 }} />
              )}
            />
          </LocalizationProvider>
        </div>
        <div
          align="right"
          style={{
            width: "100%",
            right: 0,
          }}
        >
          <Button
            style={{
              backgroundColor: SPANISH_GREEN,
              textTransform: "capitalize",
              fontSize: 14,
              color: WHITE,
              minWidth: "129px",
              minHeight: "45px",
              marginTop: 5,
            }}
            onClick={() => {
              fetchOrders(dateValue, toDateValue).then((data) => {
                try {
                  const rowData = [];
                  gridRef.current.api.forEachNode(function (node) {
                    rowData.push(node.data);
                  });
                  gridRef.current.api.applyTransaction({
                    remove: rowData,
                  });
                } catch (e) {}
                try {
                  gridRef.current.api.applyTransaction({ add: data });
                } catch (e) {}
              });
            }}
          >
            {GO}
          </Button>
        </div>
      </div>
      {/* aggrid div start */}

      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "570px" }}>
          <AgGridReact
            copyGroupHeadersToClipboard={true}
            enableRangeSelection={true}
            columnDefs={columnDefs}
            ref={gridRef}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            paginationAutoPageSize={true}
            pagination={true}
            rowSelection="multiple"
            getRowId={(params) => params.data.id}
            onSelectionChanged={onRowSelectionChanged}
          ></AgGridReact>
        </div>
      </div>
    </SideNav>
  );
}
