import { Constants } from "./constants";

export const APISALES = {
  SalesOrderOptions: (status) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/order/sales/options/${status}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  SalesOrders: (d1, d2) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/order/sales?date=${d1}&toDate=${d2}&page=0&pageSize=100000`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  AddSalesOrder: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/order/sales`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateSalesOrder: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/order/sales/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  DeleteSalesOrder: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/order/sales/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  SalesOrderInvoiceRaised: (id) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/order/sales/invoice/status/${id}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  SalesOrderDetail: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/order/sales/${id}`, {
      method: "GET",
      credentials: "include",
    });
  },
  SalesOrderUpload: (formData) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/order/sales/upload`, {
      method: "POST",
      body: formData,
      credentials: "include",
    });
  },
  SalesOrderChangeStatus: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/order/sales/status/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  SalesDownloadTemplate: (id) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/order/sales/download/template/${id}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  DeleteInvoice: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/invoice/${id}`, {
      method: "DELETE",
      credentials: "include",
    });
  },
  AddInvoice: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/invoice`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  UpdateInvoice: (id, data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/invoice/${id}`, {
      method: "PUT",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  SalesInvoices: (d1, d2) => {
    return fetch(
      `${Constants.API_SERVER()}/api/v1/invoices?date=${d1}&toDate=${d2}&page=0&pageSize=100000`,
      {
        method: "GET",
        credentials: "include",
      }
    );
  },
  SalesInvoiceDetail: (id) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/invoice/${id}`, {
      method: "GET",
      credentials: "include",
    });
  },
  SalesInvoiceDownloadTemplate: (data) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/invoice/template/download`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    });
  },
  SalesInvoiceUpload: (formData) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/invoice/upload`, {
      method: "POST",
      body: formData,
      credentials: "include",
    });
  },
};
