import { Constants } from "./constants";
export const APIDASHBOARD = {
  Dashboard: (dto) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/dashboard`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(dto),
    });
  },
};
