import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  IconButton,
  Stack,
  TableBody,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  BLACK,
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";

import { APIMASTER } from "../../../../apihandler/master";
import {
  getItemTypes,
  globalFilter,
} from "../../../../component/cellRenderers/renderers";
import { setAlertTitleText } from "../../../../component/modal/alertModal";
import "./modal.css";

function Modal({
  setOpenModal,
  model,
  formTitle,
  updateModel,
  addModel,
  viewModel,
}) {
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    //no validation other than required so direct api call
    if (dto.id <= 0)
      APIMASTER.AddSupplierCategory(dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            addModel(data.response);
            setAlertTitleText(
              "Success",
              "The record was inserted successfully"
            );
            setOpenModal(false);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    else
      APIMASTER.UpdateSupplierCategory(dto.id, dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            updateModel(data.response);
            setAlertTitleText("Success", "The record was updated successfully");
            setOpenModal(false);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
          }
        });
  }

  const itemTypes = getItemTypes();

  const [dto, setDTO] = useState(model);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (isNaN(value) || name === "description")
        setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    } else {
      setDTO({ ...dto, [name]: value });
    }
  };

  const itemTypeProps = {
    options: itemTypes,
    getOptionLabel: (option) => option?.name || "",
  };

  return (
    <div className="modalBackgroundForSupplierCategories">
      {/* div for top right close button */}
      <form>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton
            style={{ width: "auto", align: "center", marginRight: 5 }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* top right close button div closed */}

        <div
          style={{
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <table style={{ width: "100%", margin: 5 }}>
            <TableBody>
              <tr>
                <td>
                  <Typography
                    fontSize={18}
                    fontWeight={600}
                    fontspacing={"normal"}
                    textTransform="capitalize"
                    align="left"
                  >
                    {formTitle}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    variant="standard"
                    label="Category Name"
                    required
                    disabled={viewModel}
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    name="categoryName"
                    value={dto.categoryName}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>

                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    {...itemTypeProps}
                    id="auto-complete-2"
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    disabled={viewModel}
                    name="supplierFor"
                    defaultValue={
                      itemTypeProps.options.find(
                        (v) => v.value === dto.supplierFor
                      ) || {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, supplierFor: v?.value });
                        else setDTO({ ...dto, supplierFor: Number(v?.value) });
                      } else {
                        setDTO({ ...dto, supplierFor: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={viewModel}
                        label="Supplier For"
                        variant="standard"
                        required
                        style={{
                          minWidth: "200px",
                          minHeight: "48.5px",
                          width: "200px",
                          height: "48.5px",
                        }}
                        InputLabelProps={{
                          style: { color: "grey" },
                        }}
                      />
                    )}
                  />
                </td>
                <td>
                  <TextField
                    variant="standard"
                    label="Prefix"
                    disabled={viewModel}
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    multiline="true"
                    value={dto.prefix}
                    name="prefix"
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                  >
                    <TextareaAutosize
                      variant="standard"
                      label="Description"
                      maxLength={128}
                      style={{
                        outlineColor: BLACK,
                        borderTop: "10px",
                        borderRight: "10px",
                        borderLeft: "10px",
                        marginTop: 15,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        maxWidth: "850px",
                      }}
                      disabled={viewModel}
                      multiline="true"
                      maxRows={4}
                      minRows={2}
                      //maxRows={2}
                      value={dto.description}
                      name="description"
                      onChange={handleInputChange}
                      placeholder="Description"
                    ></TextareaAutosize>
                  </Stack>
                </td>
              </tr>
            </TableBody>
          </table>
        </div>

        {/* div for buttons */}
        {!viewModel ? (
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              marginTop: 10,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={onFormSubmit}
            >
              Save
            </Button>
          </div>
        ) : (
          <div />
        )}
        {/* div for buttons closed */}
      </form>
      {/* pop up div closed  */}
    </div>
  );
}

export default Modal;
