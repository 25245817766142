import {
  Autocomplete,
  Button,
  IconButton,
  TableBody,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { ThreeCircles } from "react-loader-spinner";
import { APIMATERIALMGMT } from "../../../../apihandler/materialmgmt";
import {
  getSuppliers,
  globalFilter,
} from "../../../../component/cellRenderers/renderers";
import {
  setAlertConfirmDeletion,
  setAlertTitleText,
} from "../../../../component/modal/alertModal";
import { UploadComponent } from "./uploadComponent";
import "./uploadModal.css";

function UploadModal({
  setOpenUploadModal,
  model,
  uploadFormTitle,
  updateModel,
  addModel,
}) {
  const [file, setFile] = useState(null);
  const [dateValue, setDateValue] = React.useState(dayjs());
  const [uploadError, setUploadError] = useState("");
  const suppliers = getSuppliers().filter((v) => v.name !== "Opening Stock");
  const supplierProps = {
    options: suppliers,
    getOptionLabel: (option) => option?.name || "",
  };

  const style = {
    paddingTop: 10,
    position: "absolute",
    top: "50%",
    left: "50%",
    color: SPANISH_GREEN,
    transform: "translate(-50%, -50%)",
    zIndex: 2,
  };
  const [uploading, setUploading] = useState(false);

  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    if (!(file == null)) {
      setUploading(true);
      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("jsonData", JSON.stringify(dto));

      return APIMATERIALMGMT.PurchasePriceConfigUpload(formData)
        .then((resp) => {
          setUploading(false);

          const contentType = resp.headers.get("content-type");
          if (contentType.startsWith("application/json")) {
            resp.json().then((x) => {
              if (x.statusCode === 500) {
                setAlertTitleText(x.message);
                return;
              }

              if (x.response.message) {
                setAlertConfirmDeletion(
                  "Confirmation needed",
                  x.response.message
                ).then((consent) => {
                  if (!consent.isConfirmed) return;

                  dto.overwriteDataConfirmation = true;
                  setTimeout(() => {
                    onFormSubmit(e);
                  }, 4000);
                });
              } else {
                setUploading(false);
                setAlertTitleText("", "Uploaded Successfully");
                x.response.forEach((e) => {
                  e.effectiveDate = e.effectiveDate
                    .split("-")
                    .reverse()
                    .join("-");
                });
                updateModel(x.response);
              }
            });
            setOpenUploadModal(false);
            return;
          }

          return Promise.reject(resp.blob());
        })
        .catch((c) => {
          c.then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = "Errors.xlsx";
            document.body.appendChild(a);
            a.click();
            a.remove();
          });

          return Promise.resolve(true);
        });
    } else {
      setUploadError("Please upload a file");
      setTimeout(() => {
        // Code to be executed after 5 seconds
        setUploadError("");
      }, 2000);
    }
  };

  function validation() {}

  const [dto, setDTO] = useState({
    supplierId: 0,
    effectiveDate: dayjs(),
    overwriteDataConfirmation: false,
    dataFirstRow: 2,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (isNaN(value)) setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    }
  };

  return (
    <div className="modalBackgroundForPurchasePriceConfigurationUploadModal">
      {uploading && (
        <div style={style}>
          <ThreeCircles
            visible={true}
            height="210"
            width="240"
            outerCircleColor={SPANISH_GREEN}
            innerCircleColor="#eeeeee"
            middleCircleColor={SPANISH_GREEN}
            ariaLabel="eNirmaan-loading"
            color={SPANISH_GREEN}
            wrapperStyle={{}}
            wrapperClass=""
          />
          Please wait while we upload the file
        </div>
      )}

      {/* div for top right close button */}
      <form>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton
            style={{ width: "auto", align: "center", marginRight: 5 }}
            onClick={() => setOpenUploadModal(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* top right close button div closed */}

        <div
          style={{
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <table style={{ width: "90%" }}>
            <TableBody>
              <tr>
                <td>
                  <Typography
                    fontSize={18}
                    fontWeight={600}
                    fontspacing={"normal"}
                    textTransform="capitalize"
                    align="left"
                  >
                    {uploadFormTitle}
                  </Typography>
                </td>
              </tr>
            </TableBody>
          </table>
          {/* this is upload component to select files */}
          <UploadComponent uploadHandler={setFile} />
          {uploadError && (
            <div style={{ marginbottom: 10 }}>
              <Typography color={"red"}>{uploadError}</Typography>
            </div>
          )}
          {/* this is upload component to selec fiels */}
          <table style={{ width: "90%" }}>
            <TableBody>
              <tr>
                <td>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Effective Date "
                      inputFormat="DD-MM-YYYY"
                      style={{ minWidth: "272px", minHeight: "48.5px" }}
                      value={dayjs(dto.effectiveDate)}
                      onChange={(newValue) => {
                        setDateValue(newValue);
                        setDTO({
                          ...dto,
                          effectiveDate: newValue.format("YYYY-MM-DD"),
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          required
                          style={{ minWidth: "272px", minHeight: "48.5px" }}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </td>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    {...supplierProps}
                    id="auto-complete-3"
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    name="supplier"
                    defaultValue={
                      supplierProps.options.find(
                        (v) => v.value === dto.supplierId
                      ) || {}
                    }
                    onChange={(e, v) =>
                      setDTO({ ...dto, supplierId: v?.value })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Supplier"
                        variant="standard"
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        InputLabelProps={{
                          style: { color: "grey" },
                        }}
                      />
                    )}
                  />
                </td>
                <td>
                  <TextField
                    variant="standard"
                    label="Data First Row"
                    style={{ minWidth: "272px", minHeight: "48.5px" }}
                    name="dataFirstRow"
                    value={dto.dataFirstRow}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
              </tr>
            </TableBody>
          </table>
        </div>

        {/* div for buttons */}
        <div
          style={{
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            display: "flex",
            marginTop: 10,
          }}
        >
          <Button
            variant="outlined"
            sx={{
              borderColor: SPANISH_GREEN,
            }}
            style={{
              textTransform: "capitalize",
              fontSize: 14,
              color: SPANISH_GREEN,
              minWidth: "180px",
              minHeight: "45px",
              margin: 10,
            }}
            onClick={() => {
              setOpenUploadModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: SPANISH_GREEN,
              textTransform: "capitalize",
              fontSize: 14,
              color: WHITE,
              minWidth: "180px",
              minHeight: "45px",
              margin: 10,
            }}
            onClick={onFormSubmit}
          >
            Save
          </Button>
        </div>
        {/* div for buttons closed */}
      </form>
      {/* pop up div closed  */}
    </div>
  );
}

export default UploadModal;
