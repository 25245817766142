import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  TableBody,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  BLACK,
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";

import { Stack } from "@mui/system";
import { APIMASTER } from "../../../../apihandler/master";
import {
  getItemCategories,
  getItemTypes,
  globalFilter,
} from "../../../../component/cellRenderers/renderers";
import { setAlertTitleText } from "../../../../component/modal/alertModal";
import "./modal.css";

function Modal({
  setOpenModal,
  model,
  formTitle,
  updateModel,
  addModel,
  viewModel,
}) {
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    //only required fild validation so direct
    if (dto.id <= 0)
      APIMASTER.AddItemCategory(dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            addModel(data.response);
            setAlertTitleText(
              "Success",
              "The record was inserted successfully"
            );
            setOpenModal(false);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    else
      APIMASTER.UpdateItemCategory(dto.id, dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            updateModel(data.response);
            setAlertTitleText("Success", "The record was updated successfully");
            setOpenModal(false);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
          }
        });
  }
  const [dto, setDTO] = useState(model);

  const itemTypes = getItemTypes();
  const categoryItems = getItemCategories();

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (isNaN(value) || name === "description" || name === "prefix")
        setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    } else {
      setDTO({ ...dto, [name]: value });
    }
  };

  const categoryProps = {
    options: categoryItems,
    getOptionLabel: (option) => option?.name || "",
  };

  const itemTypeProps = {
    options: itemTypes,
    getOptionLabel: (option) => option?.name || "",
  };

  return (
    <div className="modalBackgroundForItemCategories">
      {/* div for top right close button */}
      <form>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton
            style={{ width: "auto", align: "center", marginRight: 5 }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* top right close button div closed */}

        <div
          style={{
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <table style={{ width: "100%", margin: 5 }}>
            <TableBody>
              <tr>
                <td>
                  <Typography
                    fontSize={18}
                    fontWeight={600}
                    fontspacing={"normal"}
                    textTransform="capitalize"
                    align="left"
                  >
                    {formTitle}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    variant="standard"
                    label="Category Name"
                    required
                    disabled={viewModel}
                    style={{
                      minWidth: "200px",
                      minHeight: "48.5px",
                      width: "200px",
                    }}
                    name="categoryName"
                    value={dto.categoryName}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>

                <td>
                  <TextField
                    variant="standard"
                    label="Prefix"
                    required
                    disabled={viewModel}
                    style={{
                      minWidth: "200px",
                      minHeight: "48.5px",
                      width: "200px",
                    }}
                    name="prefix"
                    value={dto.prefix}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    {...itemTypeProps}
                    id="auto-complete-1"
                    autoComplete
                    autoHighlight
                    autoSelect
                    disabled={viewModel}
                    includeInputInList
                    name="itemType"
                    defaultValue={
                      itemTypeProps.options.find(
                        (v) => v.value === dto.itemType
                      ) || {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, itemType: v?.value });
                        else setDTO({ ...dto, itemType: Number(v?.value) });
                      } else {
                        setDTO({ ...dto, itemType: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Item Type"
                        required
                        disabled={viewModel}
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          minHeight: "48.5px",
                          width: "200px",
                          height: "48.5px",
                        }}
                        InputLabelProps={{
                          style: { color: "grey" },
                        }}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    {...categoryProps}
                    id="auto-complete-2"
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    disabled={viewModel}
                    name="parentCategory"
                    defaultValue={
                      categoryProps.options.find(
                        (v) => v.value === dto.parentCategoryId
                      ) || {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, parentCategoryId: v?.value });
                        else
                          setDTO({
                            ...dto,
                            parentCategoryId: Number(v?.value),
                          });
                      } else {
                        setDTO({ ...dto, parentCategoryId: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Parent Category"
                        disabled={viewModel}
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          minHeight: "48.5px",
                          width: "200px",
                          height: "48.5px",
                        }}
                        InputLabelProps={{
                          style: { color: "grey" },
                        }}
                      />
                    )}
                  />
                </td>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    {...categoryProps}
                    id="auto-complete-3"
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    disabled={viewModel}
                    name="purchaseRatio"
                    defaultValue={
                      categoryProps.options.find(
                        (v) => v.value === dto.purchaseRatio
                      ) || {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, purchaseRatio: v?.value });
                        else
                          setDTO({ ...dto, purchaseRatio: Number(v?.value) });
                      } else {
                        setDTO({ ...dto, purchaseRatio: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Purchase Ratio Item Category"
                        disabled={viewModel}
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          minHeight: "48.5px",
                          width: "200px",
                          height: "48.5px",
                        }}
                        InputLabelProps={{
                          style: { color: "grey" },
                        }}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                  >
                    <TextareaAutosize
                      variant="standard"
                      label="Description"
                      maxLength={128}
                      disabled={viewModel}
                      style={{
                        outlineColor: BLACK,
                        borderTop: "10px",
                        borderRight: "10px",
                        borderLeft: "10px",
                        marginTop: 15,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        maxWidth: "840px",
                      }}
                      multiline="true"
                      maxRows={4}
                      minRows={2}
                      //maxRows={2}
                      value={dto.description}
                      name="description"
                      onChange={handleInputChange}
                      placeholder="Description"
                    ></TextareaAutosize>
                  </Stack>
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <FormControlLabel
                    name="isBillable"
                    disabled={viewModel}
                    checked={dto.isBillable}
                    value={dto.isBillable}
                    onChange={(e, v) =>
                      setDTO({
                        ...dto,
                        isBillable: e.target.checked ? 1 : 0,
                      })
                    }
                    control={<Checkbox color="success" />}
                    label="Is Billable"
                  />
                </td>
                <td>
                  {" "}
                  <FormControlLabel
                    name="isBaseCategory"
                    disabled={viewModel}
                    checked={dto.isBaseCategory}
                    value={dto.isBaseCategory}
                    onChange={(e, v) =>
                      setDTO({
                        ...dto,
                        isBaseCategory: e.target.checked ? 1 : 0,
                      })
                    }
                    control={<Checkbox color="success" />}
                    label="Is Base Category"
                  />
                </td>
              </tr>
            </TableBody>
          </table>
        </div>

        {/* div for buttons */}
        {!viewModel ? (
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              marginTop: 10,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={onFormSubmit}
            >
              Save
            </Button>
          </div>
        ) : (
          <div />
        )}
        {/* div for buttons closed */}
      </form>
      {/* pop up div closed  */}
    </div>
  );
}

export default Modal;
