import { Constants } from "./constants";

export const APIUTILS = {
  IsJsonString: (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },
  Financial: (x) => {
    return Number.parseFloat(x).toFixed(2);
  },
  CalculateTax: (x) => {
    return fetch(`${Constants.API_SERVER()}/api/v1/order/tax/calculate`, {
      method: "POST",
      body: JSON.stringify(x),
      credentials: "include",
    });
  },
};
