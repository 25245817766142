import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  TableBody,
  TextField,
  Typography,
} from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import React, { useEffect, useRef, useState } from "react";
import SideNav from "../../../../../component/layouts/sideNav";
import {
  CANCEL,
  EXPORT,
  GO,
  LABOUR_MANAGEMENT,
  PAYABLE,
  SAVE,
  SUB_CONTRACTOR_FOOD_ADVANCE,
  SUB_CONTRACTOR_FOOD_ADVANCE_DETAIL,
  TOTAL,
} from "../../../../../resources/constant/strings/string";

import {
  getSites,
  getSupplierCategories,
  getSuppliers,
  getWorkOrders,
  globalFilter,
  labourerTypesCellRenderer,
} from "../../../../../component/cellRenderers/renderers";
import {
  ANTI_FLASH_WHITE,
  CHARLESTON_GREEN,
  LIGHT_GREY_BACKGROUND,
  SPANISH_GREEN,
  WHITE,
} from "../../../../../resources/constant/colors/theme";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { APILABOURMGMT } from "../../../../../apihandler/labourmgmt";
import { APILOGIN } from "../../../../../apihandler/login";
import { APIUTILS } from "../../../../../apihandler/utils";
import { setAlertTitleText } from "../../../../../component/modal/alertModal";
import {
  PATH_LOGIN,
  PATH_SUB_CONTRACTOR_FOOD_ADVANCE,
} from "../../../../../resources/constant/url/url";

export default function SubContractorFoodAdvanceDetail() {
  let { id } = useParams();
  const location = useLocation();
  const isEdit = location.state?.isEdit;
  const navigate = useNavigate();

  const supplierCategories = getSupplierCategories().filter((x) => {
    return x.dependentvalue === "L";
  });
  const suppliers = getSuppliers().filter((x) => {
    return (
      supplierCategories.filter((y) => y.value === x.dependentvalue).length > 0
    );
  });
  const supplierProps = {
    options: suppliers,
    getOptionLabel: (option) => option?.name || "",
  };

  const sites = getSites();
  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };

  const workOrders = getWorkOrders();
  const workOrderProps = {
    options: workOrders,
    getOptionLabel: (option) => option?.name || "",
  };

  const initialValue = {
    id: 0,
    finalBilling: 1,
    transactionNumber: "Auto Generated",
    date: dayjs().add(-1, "day"),
    contractorId: 0,
    siteId: 0,
    fromDate: dayjs().add(-1, "day"),
    toDate: dayjs(),
    payable: 0,
    billNumber: 0,
    advancePayable: 0,
    workOrderNumber: 0,
    total: 0,
    foodAdvanceDetail: [],
    foodAdvanceDetailDatewise: [],
  };

  const [dto, setDTO] = useState(initialValue);
  const [dateValue, setDateValue] = useState(dayjs());
  const [dataReady, setDataReady] = useState(false);

  const [dataFilledVariable, setDataFilledVariable] = useState(false);
  const [disableDate, setDisableDate] = useState(false);

  const gridRef = useRef();

  useEffect(() => {
    if (!APILOGIN.GetIsLoggedIn()) navigate(PATH_LOGIN);
  }, []);

  const [selectedRowId, setSelectedRowId] = useState(0);

  const updateModel = (model) => {
    gridRef.current.api.applyTransaction({ update: [model] });
  };

  const addModel = (model) => {
    gridRef.current.api.applyTransaction({ add: [model], addIndex: 0 });
  };

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId(event.api.getSelectedRows()[0].id);
    else setSelectedRowId(0);
  };

  const handleEditData = (oldData) => {
    console.log(oldData);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (isNaN(value)) setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    }
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Labour Type",
      field: "labourerType",
      valueGetter: (params) => {
        return labourerTypesCellRenderer({ value: params.data.labourerType });
      },
    },
  ]);

  const columnDefs1 = [
    {
      headerName: "Total Qty",
      field: "totalQuantity",
    },
    {
      headerName: "Rate",
      field: "rate",
    },
    {
      headerName: "Wages",
      field: "wages",
    },
    {
      headerName: "Bus Fare",
      field: "busFare",
    },
    {
      headerName: "Food Fare",
      field: "foodFare",
    },
    {
      headerName: "Total Amount",
      field: "totalAmount",
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: false,
    floatingFilter: false,
  };

  const [rowData, setRowData] = useState([]);

  const onGridReady = (params) => {
    gridRef.current.api.setRowData(rowData);
  };
  // bellow code is subcontractor food advance detail component
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    if (
      workOrderProps.options.filter(
        (x) =>
          x.otherdependentvalue === dto.siteId &&
          x.value === dto.workOrderNumber
      ).length <= 0
    ) {
      setAlertTitleText("Oops...", `Work order is not valid for this site`);
      return;
    }

    //api call to save goes here
    if (id <= 0) {
      APILABOURMGMT.AddFoodAdvance(dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setAlertTitleText(
              "Success",
              "The record was inserted successfully"
            );
            navigate(
              PATH_SUB_CONTRACTOR_FOOD_ADVANCE + "/" + location.state.date
            );
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    } else {
      APILABOURMGMT.UpdateFoodAdvance(dto.id, dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setAlertTitleText("Success", "The record was updated successfully");
            navigate(
              PATH_SUB_CONTRACTOR_FOOD_ADVANCE + "/" + location.state.date
            );
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    }
  }

  const [isFetched, setIsFetched] = useState(false);
  const generateFoodAdvance = () => {
    if (dayjs(dto.toDate).isAfter(dayjs(dateValue))) {
      setAlertTitleText("Oops...", "Entry date cannot be greater than to date");
      return;
    }

    if (dayjs(dto.toDate).diff(dayjs(dto.fromDate), "days") > 15) {
      setAlertTitleText("Oops...", "Date difference cannot be greater than 15");
      return;
    }

    APILABOURMGMT.GenerateFoodAdvance(dto)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) {
          setAlertTitleText("Oops", resp.message);
          return;
        }

        const headers = [];
        let isOneLoopDone = false;
        resp.response.foodAdvanceDetail.forEach((x, i) => {
          if (isOneLoopDone) return;

          const reqdRow = resp.response.foodAdvanceDetailDatewise.filter(
            (m) => m.labourerType === x.labourerType
          );
          if (reqdRow.length > 0) {
            reqdRow.forEach((x) => {
              headers.push({
                headerName: x.date.split("-").slice(0, 2).join("/"),
                field: `v_${x.date.replace("-", "_")}`,
              });
            });
            isOneLoopDone = true;
          }
        });

        const data = [];

        resp.response.foodAdvanceDetail
          .sort((a, b) => b.id - a.id)
          .forEach((x, i) => {
            const reqdRow = resp.response.foodAdvanceDetailDatewise.filter(
              (m) => m.labourerType === x.labourerType
            );
            if (reqdRow.length > 0) {
              let obj = x;
              reqdRow.forEach((m) => {
                obj[`v_${m.date.replace("-", "_")}`] = m.quantity;
              });
              obj.id = i;
              data.push(obj);
            }
          });

        let total = 0;
        resp.response.foodAdvanceDetail.forEach((m) => {
          total += m.totalAmount;
        });

        setDTO({
          ...dto,
          foodAdvanceDetail: resp.response.foodAdvanceDetail,
          total: Number.parseFloat(APIUTILS.Financial(total)),
        });

        setColumnDefs([...columnDefs.concat(headers).concat(columnDefs1)]);
        setRowData(rowData.concat(data));

        setIsFetched(true);
        setDataFilledVariable(true);
      });
  };

  useEffect(() => {
    if (id > 0) {
      APILABOURMGMT.FoodAdvance(id)
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.statusCode === 200) {
            console.log(resp.response);
            const headers = [];
            let isOneLoopDone = false;
            resp.response.foodAdvanceDetail.forEach((x, i) => {
              if (isOneLoopDone) return;

              const reqdRow = resp.response.foodAdvanceDetailDatewise.filter(
                (m) => m.labourerType === x.labourerType
              );
              if (reqdRow.length > 0) {
                reqdRow.forEach((x) => {
                  headers.push({
                    headerName: x.date.split("-").slice(0, 2).join("/"),
                    field: `v_${x.date.replace("-", "_")}`,
                  });
                });
                isOneLoopDone = true;
              }
            });

            const data = [];

            resp.response.foodAdvanceDetail.forEach((x, i) => {
              const reqdRow = resp.response.foodAdvanceDetailDatewise.filter(
                (m) => m.labourerType === x.labourerType
              );
              if (reqdRow.length > 0) {
                let obj = x;
                reqdRow.forEach((m) => {
                  obj[`v_${m.date.replace("-", "_")}`] = m.quantity;
                });
                obj.id = i;
                data.push(obj);
              }
            });

            let total = 0;
            resp.response.foodAdvanceDetail.forEach((m) => {
              total += m.totalAmount;
            });

            setDTO({
              ...dto,
              transactionNumber: resp.response.transactionNumber,
              id: resp.response.id,
              finalBilling: resp.response.finalBilling,
              date: resp.response.date.split("-").reverse().join("-"),
              contractorId: resp.response.contractorId,
              siteId: resp.response.siteId,
              fromDate: resp.response.fromDate.split("-").reverse().join("-"),
              toDate: resp.response.toDate.split("-").reverse().join("-"),
              payable: resp.response.payable,
              billNumber: resp.response.billNumber,
              advancePayable: resp.response.advancePayable,
              workOrderNumber: resp.response.workOrderNumber,
              foodAdvanceDetailDatewise:
                resp.response.foodAdvanceDetailDatewise,
              foodAdvanceDetail: resp.response.foodAdvanceDetail,
              total: Number.parseFloat(APIUTILS.Financial(total)),
            });

            setColumnDefs([
              ...columnDefs
                .filter((x) => x.false)
                .concat(headers)
                .concat(columnDefs1),
            ]);
            setRowData(rowData.filter((x) => false).concat(data));

            setIsFetched(true);
            setDataFilledVariable(true);
            setDataReady(true);
          }
        });
    } else {
      setDataReady(true);
    }
  }, [id]);

  return (
    <SideNav
      currentComponent={LABOUR_MANAGEMENT}
      subComponent={SUB_CONTRACTOR_FOOD_ADVANCE}
      subComponentPath={PATH_SUB_CONTRACTOR_FOOD_ADVANCE}
      detailComponent={SUB_CONTRACTOR_FOOD_ADVANCE_DETAIL}
    >
      {/* below code is subContractorFoodAdvanceDetail component */}
      {dataReady ? (
        <div>
          <div>
            <form>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  marginBottom: 10,
                  marginRight: 10,
                }}
              >
                <Button
                  style={{
                    backgroundColor: SPANISH_GREEN,
                    textTransform: "capitalize",
                    fontSize: 14,
                    color: WHITE,
                    minWidth: "180px",
                    minHeight: "45px",
                  }}
                  onClick={() => {
                    let filename = "";
                    APILABOURMGMT.FoodAdvancePDF(id)
                      .then((res) => {
                        const contentType = res.headers.get("content-type");
                        if (contentType.startsWith("application/json")) {
                          return Promise.reject(res.json());
                        } else {
                          const header = res.headers.get("Content-Disposition");
                          if (header !== null) {
                            const parts = header.split(";");
                            filename = parts[1]
                              .split("=")[1]
                              .replaceAll('"', "");
                          } else {
                            filename = "FoodAdvance.html";
                          }

                          return res.blob();
                        }
                      })
                      .then((blob) => {
                        if (blob !== null) {
                          blob.text().then((x) => {
                            let html = x;
                            html = html.replace(
                              "<script>",
                              '<script type="text/javascript">'
                            );
                            let blob = new Blob([html], { type: "text/html" });
                            let url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
                          
                            let win = window.open(url, '_blank', '');
                            setTimeout(()=>{
                                win.print();
                            }, 2000);
                          });
                        }
                      })
                      .catch((m) => {
                        setAlertTitleText(
                          "Oops...",
                          `Something went wrong...${m.message}`
                        );
                      });
                  }}
                >
                  {EXPORT}
                </Button>
              </div>
              <div
                style={{
                  backgroundColor: LIGHT_GREY_BACKGROUND,
                  borderRadius: "5px",
                  minWidth: "90%",
                  minHeight: "50px",
                  padding: 20,
                  marginBottom: 10,
                  borderStyle: "solid",
                  border: "1x",
                  borderColor: "#eaeaea",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <table style={{ width: "100%" }}>
                  <TableBody>
                    <tr>
                      <td>
                        <FormControlLabel
                          name="finalBillings"
                          control={<Checkbox color="success" />}
                          label="Final Billing"
                          value={dto.finalBilling}
                          checked={dto.finalBilling}
                          onChange={(e, v) =>
                            setDTO({
                              ...dto,
                              finalBilling: e.target.checked ? 1 : 0,
                            })
                          }
                          style={{ userSelect: "none" }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <TextField
                          variant="standard"
                          label="Transaction No"
                          disabled={true}
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          name="transactionNo"
                          value={dto.transactionNumber}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{ style: { fontSize: 14 } }}
                        ></TextField>
                      </td>
                      <td>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label="Date"
                            style={{ minWidth: "272px", minHeight: "48.5px" }}
                            value={dayjs(dto.date)}
                            disabled={(isEdit && dto.billNumber > 0) || disableDate || (id > 0 && !isEdit)}
                            maxDate={dayjs()}
                            inputFormat="DD-MM-YYYY"
                            onChange={(newValue) => {
                              setDateValue(newValue);
                              setDTO({
                                ...dto,
                                date: newValue.format("YYYY-MM-DD"),
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                required
                                {...params}
                                style={{
                                  minWidth: "200px",
                                  minHeight: "48.5px",
                                  width: "200px",
                                }}
                                InputLabelProps={{
                                  style: { color: "grey", fontSize: 14 },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { fontSize: 14 },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </td>
                      <td>
                        <Autocomplete
                          filterOptions={globalFilter}
                          {...supplierProps}
                          id="auto-complete-1"
                          disabled={id > 0}
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          name="contractorName"
                          defaultValue={
                            supplierProps.options.find(
                              (v) => v.value === dto.contractorId
                            ) || {}
                          }
                          onChange={(e, v) => {
                            if (v) {
                              if (isNaN(v?.value))
                                setDTO({ ...dto, contractorId: v?.value });
                              else
                                setDTO({
                                  ...dto,
                                  contractorId: Number(v?.value),
                                });
                            } else {
                              setDTO({ ...dto, contractorId: v?.value });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Contractor Name"
                              required
                              variant="standard"
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 14 },
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <Autocomplete
                          filterOptions={globalFilter}
                          {...siteProps}
                          id="auto-complete-2"
                          disabled={id > 0}
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          name="site"
                          defaultValue={
                            siteProps.options.find(
                              (v) => v.value === dto.siteId
                            ) || {}
                          }
                          onChange={(e, v) => {
                            if (v) {
                              if (isNaN(v?.value))
                                setDTO({ ...dto, siteId: v?.value });
                              else setDTO({ ...dto, siteId: Number(v?.value) });
                            } else {
                              setDTO({ ...dto, siteId: v?.value });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Site"
                              required
                              variant="standard"
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 14 },
                              }}
                            />
                          )}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Autocomplete
                          {...workOrderProps}
                          id="auto-complete-3"
                          disabled={id > 0}
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          name="workOrder"
                          defaultValue={
                            workOrderProps.options.find(
                              (v) => v.value === dto.workOrderNumber
                            ) || {}
                          }
                          onChange={(e, v) => {
                            if (v) {
                              if (isNaN(v?.value))
                                setDTO({ ...dto, workOrderNumber: v?.value });
                              else
                                setDTO({
                                  ...dto,
                                  workOrderNumber: Number(v?.value),
                                });

                              setDisableDate(true);
                            } else {
                              setDTO({ ...dto, workOrderNumber: v?.value });
                            }
                          }}
                          filterOptions={(o, s) => {
                            let options = globalFilter(o, s);
                            let newOptions = [];
                            options.forEach((element) => {
                              if (
                                element.dependentvalue === dto.contractorId &&
                                element.otherdependentvalue === dto.siteId
                              ) {
                                if (
                                  element.date &&
                                  (dayjs(dto.toDate).isAfter(element.date) ||
                                    dayjs(dto.toDate).isSame(element.date))
                                )
                                  newOptions.push(element);
                              }
                            });
                            return newOptions;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Work order No"
                              required
                              variant="standard"
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 14 },
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label="From Date "
                            style={{ minWidth: "272px", minHeight: "48.5px" }}
                            inputFormat="DD-MM-YYYY"
                            value={dto.fromDate}
                            disabled={(isEdit && dto.billNumber > 0) || (id > 0 && !isEdit)}
                            maxDate={dayjs()}
                            onChange={(newValue) => {
                              setDTO({
                                ...dto,
                                fromDate: newValue.format("YYYY-MM-DD"),
                                toDate: dayjs(newValue).endOf("month"),
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                required
                                {...params}
                                style={{
                                  minWidth: "200px",
                                  minHeight: "48.5px",
                                  width: "200px",
                                }}
                                InputLabelProps={{
                                  style: { color: "grey", fontSize: 14 },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { fontSize: 14 },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </td>
                      <td>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label="To Date "
                            inputFormat="DD-MM-YYYY"
                            maxDate={dayjs()}
                            disabled={(isEdit && dto.billNumber > 0) || (id > 0 && !isEdit)}
                            style={{ minWidth: "272px", minHeight: "48.5px" }}
                            value={dto.toDate}
                            onChange={(newValue) => {
                              setDTO({
                                ...dto,
                                toDate: newValue.format("YYYY-MM-DD"),
                              });
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                required
                                {...params}
                                style={{
                                  minWidth: "200px",
                                  minHeight: "48.5px",
                                  width: "200px",
                                }}
                                InputLabelProps={{
                                  style: { color: "grey", fontSize: 14 },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { fontSize: 14 },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </td>
                      <td>
                        <FormControlLabel
                          name="advancePayable"
                          checked={dto.advancePayable}
                          value={dto.advancePayable}
                          onChange={(e, v) => {
                            let sumTotal = 0;
                            if (e.target.checked) {
                              dto.foodAdvanceDetail.forEach((x) => {
                                sumTotal += x.totalAmount;
                              });
                            }
                            setDTO({
                              ...dto,
                              advancePayable: e.target.checked ? 1 : 0,
                              payable: Number.parseFloat(
                                APIUTILS.Financial(sumTotal)
                              ),
                            });
                          }}
                          control={<Checkbox color="success" />}
                          label="Advance Payable"
                          style={{ userSelect: "none" }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {!isFetched ? (
                          <Button
                            variant="outlined"
                            style={{
                              backgroundColor: SPANISH_GREEN,
                              textTransform: "capitalize",
                              fontSize: 14,
                              color: WHITE,
                              minWidth: "180px",
                              minHeight: "45px",
                            }}
                            onClick={() => {
                              generateFoodAdvance();
                            }}
                          >
                            {GO}
                          </Button>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  </TableBody>
                </table>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  marginTop: 10,
                  marginBottom: 10,
                  marginRight: 10,
                }}
              >
                {(id <= 0 || isEdit) && (
                  <Button
                    style={{
                      backgroundColor: SPANISH_GREEN,
                      textTransform: "capitalize",
                      fontSize: 14,
                      color: WHITE,
                      minWidth: "180px",
                      minHeight: "45px",
                    }}
                    onClick={onFormSubmit}
                  >
                    {SAVE}
                  </Button>
                )}
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: SPANISH_GREEN,
                  }}
                  style={{
                    //backgroundColor: SPANISH_GREEN,
                    textTransform: "capitalize",
                    fontSize: 14,
                    color: SPANISH_GREEN,
                    minWidth: "180px",
                    minHeight: "45px",
                    marginRight: 10,
                  }}
                  onClick={() => {
                    navigate(
                      PATH_SUB_CONTRACTOR_FOOD_ADVANCE +
                        "/" +
                        location.state.date
                    );
                  }}
                >
                  {CANCEL}
                </Button>
              </div>
            </form>
          </div>
          {/* aggrid div start */}
          {dataFilledVariable ? (
            <div className="App">
              <div className="ag-theme-alpine" style={{ height: "270px" }}>
                <AgGridReact
                  copyGroupHeadersToClipboard={true}
                  enableRangeSelection={true}
                  columnDefs={columnDefs}
                  ref={gridRef}
                  defaultColDef={defaultColDef}
                  onGridReady={onGridReady}
                  pagination={false}
                  rowSelection="multiple"
                  getRowId={(params) => params.data.id}
                  onSelectionChanged={onRowSelectionChanged}
                ></AgGridReact>
              </div>
              <div
                style={{
                  backgroundColor: ANTI_FLASH_WHITE,
                  borderRadius: "2px",
                  minWidth: "90%",
                  minHeight: "50px",
                  padding: 5,
                  marginBottom: 10,
                  borderStyle: "solid",
                  border: "0.1px",
                  marginTop: 5,
                  borderColor: "#eaeaea",
                  display: "flex",
                }}
              >
                <div style={{ display: "flex", flex: 1 }}>
                  <table>
                    <TableBody>
                      <tr>
                        <td>
                          <Typography
                            color={CHARLESTON_GREEN}
                            style={{
                              flexGrow: 0,
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: 1.32,
                              letterSpacing: "normal",
                            }}
                          >
                            {TOTAL}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Typography
                            color={CHARLESTON_GREEN}
                            style={{
                              marginTop: 10,
                              flexGrow: 0,
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: 1.32,
                              letterSpacing: "normal",
                            }}
                          >
                            {PAYABLE}
                          </Typography>
                        </td>
                      </tr>
                    </TableBody>
                  </table>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <table>
                    <TableBody>
                      <tr>
                        <td>
                          <Typography
                            color={CHARLESTON_GREEN}
                            style={{
                              flexGrow: 0,
                              fontFamily: "Inter",
                              fontSize: "14px",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: 1.32,
                              letterSpacing: "normal",
                            }}
                          >
                            {dto.total}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input
                            onChange={(e) => {
                              const { name, value } = e.target;
                              setDTO({...dto, [name]: value});
                            }}
                            disabled={id > 0 && !isEdit}
                            onWheel={(event) => event.target.blur()}
                            name="payable"
                            value={dto.payable}
                            onBlur={handleInputChange}
                            style={{ width: "100px" }}
                          ></input>
                        </td>
                      </tr>
                    </TableBody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </SideNav>
  );
}
