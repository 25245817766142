import {
  Autocomplete,
  Button,
  IconButton,
  TableBody,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { APIMASTER } from "../../../../apihandler/master";
import {
  getBloodGroups,
  getDesignations,
  getEmployeeTypes,
  getEmploymentStatuses,
  getGenders,
  getMaritalStatuses,
  globalFilter,
} from "../../../../component/cellRenderers/renderers";
import { setAlertTitleText } from "../../../../component/modal/alertModal";
import "./modal.css";

function Modal({
  setOpenModal,
  model,
  formTitle,
  updateModel,
  addModel,
  viewModel,
}) {
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    //only required so direct api call
    if (dto.id <= 0)
      APIMASTER.AddEmployee(dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            addModel(data.response);
            setAlertTitleText(
              "Success",
              "The record was inserted successfully"
            );
            setOpenModal(false);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    else
      APIMASTER.UpdateEmployee(dto.id, dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            updateModel(data.response);
            setAlertTitleText("Success", "The record was updated successfully");
            setOpenModal(false);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
          }
        });
  }

  const [dto, setDTO] = useState(model);
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (isNaN(value) || name === "description")
        setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    } else {
      setDTO({ ...dto, [name]: value });
    }
  };

  const designations = getDesignations();
  const maritalStatus = getMaritalStatuses();
  const employeeTypes = getEmployeeTypes();
  const genders = getGenders();
  const bloodGroups = getBloodGroups();
  const employmentStatus = getEmploymentStatuses();

  const designationsProps = {
    options: designations,
    getOptionLabel: (option) => option?.name || "",
  };
  const maritalStatusProps = {
    options: maritalStatus,
    getOptionLabel: (option) => option?.name || "",
  };
  const employeeTypeProps = {
    options: employeeTypes,
    getOptionLabel: (option) => option?.name || "",
  };
  const genderProps = {
    options: genders,
    getOptionLabel: (option) => option?.name || "",
  };
  const bloodGroupProps = {
    options: bloodGroups,
    getOptionLabel: (option) => option?.name || "",
  };
  const employmentStatusProps = {
    options: employmentStatus,
    getOptionLabel: (option) => option?.name || "",
  };
  return (
    <div className="modalBackgroundForAddEmployee">
      {/* div for top right close button */}
      <form>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton
            style={{ width: "auto", align: "center", marginRight: 5 }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* top right close button div closed */}

        <div
          style={{
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <table style={{ width: "100%", margin: 5 }}>
            <TableBody>
              <tr>
                <td>
                  <Typography
                    fontSize={18}
                    fontWeight={600}
                    fontspacing={"normal"}
                    textTransform="capitalize"
                    align="left"
                  >
                    {formTitle}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    variant="standard"
                    label="Employee No"
                    disabled={true}
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    name="EmployeeNo"
                    value={dto.employeeNo}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
                <td>
                  <TextField
                    inputProps={{
                      maxlength: 128,
                    }}
                    variant="standard"
                    label="First Name"
                    disabled={viewModel}
                    required
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    name="firstName"
                    value={dto.firstName}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
                <td>
                  <TextField
                    inputProps={{
                      maxlength: 128,
                    }}
                    variant="standard"
                    label="Last Name"
                    disabled={viewModel}
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    name="lastName"
                    value={dto.lastName}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
              </tr>
              <tr>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    {...designationsProps}
                    id="auto-complete-1"
                    autoComplete
                    autoHighlight
                    autoSelect
                    disabled={viewModel}
                    includeInputInList
                    name="designationId"
                    defaultValue={
                      designationsProps.options.find(
                        (v) => v.value === dto.designationId
                      ) || {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, designationId: v?.value });
                        else
                          setDTO({ ...dto, designationId: Number(v?.value) });
                      } else {
                        setDTO({ ...dto, designationId: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Designation"
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          minHeight: "48.5px",
                          width: "200px",
                        }}
                      />
                    )}
                  />
                </td>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    {...genderProps}
                    id="auto-complete-2"
                    autoComplete
                    autoHighlight
                    autoSelect
                    disabled={viewModel}
                    includeInputInList
                    name="gender"
                    defaultValue={
                      genderProps.options.find((v) => v.value === dto.gender) ||
                      {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, gender: v?.value });
                        else setDTO({ ...dto, gender: Number(v?.value) });
                      } else {
                        setDTO({ ...dto, gender: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Gender"
                        required
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          minHeight: "48.5px",
                          width: "200px",
                        }}
                      />
                    )}
                  />
                </td>
                <td>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Date Of Birth"
                      disabled={viewModel}
                      style={{ minWidth: "272px", minHeight: "48.5px" }}
                      inputFormat="DD-MM-YYYY"
                      value={dayjs(dto.dateOfBirth, "DD-MM-YYYY")}
                      onChange={(newValue) => {
                        setDTO({
                          ...dto,
                          dateOfBirth: newValue.format("DD-MM-YYYY"),
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          required
                          style={{
                            minWidth: "200px",
                            width: "200px",
                            minHeight: "48.5px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Date Of Joining"
                      disabled={viewModel}
                      style={{
                        minWidth: "200px",
                        width: "200px",
                        minHeight: "48.5px",
                      }}
                      inputFormat="DD-MM-YYYY"
                      value={dayjs(dto.dateOfJoining, "DD-MM-YYYY")}
                      onChange={(newValue) => {
                        setDTO({
                          ...dto,
                          dateOfJoining: newValue.format("DD-MM-YYYY"),
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="standard"
                          {...params}
                          required
                          style={{
                            minWidth: "200px",
                            width: "200px",
                            minHeight: "48.5px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </td>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    {...maritalStatusProps}
                    id="auto-complete-3"
                    disabled={viewModel}
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    name="maritalStatusId"
                    defaultValue={
                      maritalStatusProps.options.find(
                        (v) => v.value === dto.maritalStatusId
                      ) || {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, maritalStatusId: v?.value });
                        else
                          setDTO({ ...dto, maritalStatusId: Number(v?.value) });
                      } else {
                        setDTO({ ...dto, maritalStatusId: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="MaritalStatus"
                        required
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          minHeight: "48.5px",
                          width: "200px",
                        }}
                      />
                    )}
                  />
                </td>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    {...bloodGroupProps}
                    id="auto-complete-4"
                    disabled={viewModel}
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    name="bloodGroup"
                    defaultValue={
                      bloodGroupProps.options.find(
                        (v) => v.value === dto.bloodGroup
                      ) || {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, bloodGroup: v?.value });
                        else setDTO({ ...dto, bloodGroup: Number(v?.value) });
                      } else {
                        setDTO({ ...dto, bloodGroup: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Blood Group"
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          minHeight: "48.5px",
                          width: "200px",
                        }}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    {...employmentStatusProps}
                    id="auto-complete-5"
                    disabled={viewModel}
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    name="employmentStatus"
                    defaultValue={
                      employmentStatusProps.options.find(
                        (v) => v.value === dto.employmentStatus
                      ) || {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, employmentStatus: v?.value });
                        else
                          setDTO({
                            ...dto,
                            employmentStatus: Number(v?.value),
                          });
                      } else {
                        setDTO({ ...dto, employmentStatus: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Employement Status"
                        required
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          minHeight: "48.5px",
                          width: "200px",
                        }}
                      />
                    )}
                  />
                </td>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    {...employeeTypeProps}
                    id="auto-complete-6"
                    disabled={viewModel}
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    name="employmentTypeId"
                    defaultValue={
                      employeeTypeProps.options.find(
                        (v) => v.value === dto.employmentTypeId
                      ) || {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, employmentTypeId: v?.value });
                        else
                          setDTO({
                            ...dto,
                            employmentTypeId: Number(v?.value),
                          });
                      } else {
                        setDTO({ ...dto, employmentTypeId: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Employement Type"
                        required
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          minHeight: "48.5px",
                          width: "200px",
                        }}
                      />
                    )}
                  />
                </td>
              </tr>
            </TableBody>
          </table>
        </div>

        {/* div for buttons */}
        {!viewModel ? (
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              marginTop: 10,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={onFormSubmit}
            >
              Save
            </Button>
          </div>
        ) : (
          <div />
        )}
        {/* div for buttons closed */}
      </form>
      {/* pop up div closed  */}
    </div>
  );
}

export default Modal;
