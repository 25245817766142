import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton } from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import dayjs from "dayjs";
import React, { useRef, useState } from "react";
import { APILABOURMGMT } from "../../../../apihandler/labourmgmt";
import { APIMASTER } from "../../../../apihandler/master";
import {
  employeeCellRenderer,
  getItemTypes,
  getSiteCategories,
  getSites,
  setEmployees,
  setSiteCategories,
  setSites,
  siteCellRenderer,
} from "../../../../component/cellRenderers/renderers";
import SideNav from "../../../../component/layouts/sideNav";
import {
  setAlertConfirmDeletion,
  setAlertTitleText,
} from "../../../../component/modal/alertModal";
import {
  PRUSSIAN_BLUE,
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";
import {
  ADD,
  DELETE,
  LABOUR_MANAGEMENT,
  MANAGER_ASSIGNMENT,
  MASTER,
} from "../../../../resources/constant/strings/string";
import { PATH_MANAGER_ASSIGNMENT } from "../../../../resources/constant/url/url";
import Modal from "./modal";

export default function ManagerAssignment() {
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    setFilledData(true);
    //the above validation is to show the add and delete button once the user enters site category and site
  }

  const [filledData, setFilledData] = useState(false);
  const gridRef = useRef();

  const [selectedRowId, setSelectedRowId] = useState(0);

  let initialValue = {};

  const setInitialValueForAdd = () => {
    initialValue = {
      id: 0,
      categoryId: 0,
      employeeId: 0,
      locationId: 0,
      effectiveDate: dayjs().startOf("month"),
    };
  };

  const deleteModel = () => {
    if (selectedRowId <= 0) {
      setAlertTitleText("Oops...", `Select a row to delete`);
      return;
    }
    setAlertConfirmDeletion(
      "Confirmation needed",
      "Do you confirm on proceeding to delete this record?"
    ).then((consent) => {
      if (!consent.isConfirmed) return;

      console.log("delete the selected id: ", selectedRowId);
      APILABOURMGMT.DeleteManagerAssignment(selectedRowId)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.statusCode === 204) {
            const selectedData = gridRef.current.api.getSelectedRows();
            gridRef.current.api.applyTransaction({ remove: selectedData });
            setAlertTitleText("Success", "The record was deleted successfully");
            setOpenModal(false);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
            setOpenModal(false);
          }
        });
    });
  };

  const updateModel = (model) => {
    gridRef.current.api.applyTransaction({ update: [model] });
  };

  const addModel = (model) => {
    gridRef.current.api.applyTransaction({ add: [model], addIndex: 0 });
  };

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId(event.api.getSelectedRows()[0].id);
    else setSelectedRowId(0);
  };

  const [formTitle, setFormTitle] = useState("Add Manager Assignment");
  const handleEditData = (oldData) => {
    console.log(oldData);
    initialValue = {
      id: oldData.id,
      categoryId: oldData.categoryId,
      employeeId: oldData.employeeId,
      locationId: oldData.locationId,
      effectiveDate: oldData.effectiveDate,
    };
    setFormData(initialValue);
    setViewOnlyModel(false);
    setFormTitle("Edit Manager Assignment");
    setOpenModal(true);
  };

  const columnDefs = [
    {
      wrapText: true,
      autoHeight: true,
      headerName: "Project Manager",
      field: "employeeId",
      valueGetter: (params) => {
        return employeeCellRenderer({ value: params.data.employeeId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      wrapText: true,
      autoHeight: true,
      headerName: "Site",
      field: "locationId",
      valueGetter: (params) => {
        return siteCellRenderer({ value: params.data.locationId });
      },
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      wrapText: true,
      autoHeight: true,
      flex: 0.4,
      headerName: "Effective Date",
      field: "effectiveDate",
      filter: false,
      floatingFilter: false,
      cellStyle: {
        "text-align": "center",
        "border-right-color": "#e2e2e2",
      },
    },

    {
      flex: 0.3,
      headerName: "Edit",
      field: "id",
      sortable: false,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconButton
            aria-label="edit"
            onClick={() => {
              handleEditData(params.data);
            }}
          >
            <EditIcon style={{ color: PRUSSIAN_BLUE, width: "20px" }} />
          </IconButton>
        </div>
      ),
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
  };

  const itemTypes = getItemTypes();

  const itemTypeProps = {
    options: itemTypes,
    getOptionLabel: (option) => option?.name || "",
  };
  const onGridReady = (params) => {
    APIMASTER.SiteCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSiteCategories(resp.response);
      })
      .then((x) => {
        APIMASTER.SiteOptions()
          .then((resp) => resp.json())
          .then((resp) => {
            if (resp.statusCode !== 200) return;
            setSites(resp.response);
            APILABOURMGMT.ManagerAssignments()
              .then((resp) => resp.json())
              .then((resp) => {
                if (resp.statusCode !== 200) {
                  setAlertTitleText(
                    "Oops..",
                    `An error occurred ${resp.message}`
                  );
                  return;
                }

                const reqdCategories = getSiteCategories();
                const reqdSites = getSites();

                resp.response.forEach((element) => {
                  const reqdSite = reqdSites.filter(
                    (x) => x.value === element.locationId
                  );
                  if (reqdSite.length > 0) {
                    const reqdCategory = reqdCategories.filter(
                      (x) => x.value === reqdSite[0].dependentvalue
                    );
                    if (reqdCategory.length > 0) {
                      element.categoryId = reqdCategory[0].value;
                    }
                  }
                });

                const data = resp.response.sort((a, b) => b.id - a.id);
                params.api.applyTransaction({ add: data });
              });
          });
      });

    APIMASTER.EmployeeOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setEmployees(resp.response);
      });
  };

  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState(initialValue);
  const [viewOnlyModel, setViewOnlyModel] = useState(false);

  return (
    <SideNav
      currentComponent={MASTER}
      subComponent={MANAGER_ASSIGNMENT}
      subComponentPath={PATH_MANAGER_ASSIGNMENT}
      detailComponent=""
    >
      {openModal && (
        <Modal
          style={{ align: "center" }}
          setOpenModal={setOpenModal}
          updateModel={updateModel}
          viewModel={viewOnlyModel}
          addModel={addModel}
          model={formData}
          formTitle={formTitle}
        ></Modal>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginBottom: 10,
          marginRight: 10,
        }}
      >
        <Button
          style={{
            backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: WHITE,
            minWidth: "180px",
            minHeight: "45px",
          }}
          onClick={() => {
            setInitialValueForAdd();
            setFormData(initialValue);
            setViewOnlyModel(false);
            setFormTitle("Add Manager Assignment");
            setOpenModal(true);
          }}
        >
          {ADD}
        </Button>

        <Button
          onClick={() => {
            deleteModel();
          }}
          variant="outlined"
          sx={{
            borderColor: SPANISH_GREEN,
          }}
          style={{
            //backgroundColor: SPANISH_GREEN,
            textTransform: "capitalize",
            fontSize: 14,
            color: SPANISH_GREEN,
            minWidth: "180px",
            minHeight: "45px",
            marginRight: 10,
          }}
        >
          {DELETE}
        </Button>
      </div>
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "570px" }}>
          <AgGridReact
            copyGroupHeadersToClipboard={true}
            enableRangeSelection={true}
            columnDefs={columnDefs}
            ref={gridRef}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            paginationAutoPageSize={true}
            pagination={true}
            rowSelection="multiple"
            getRowId={(params) => params.data.id}
            onSelectionChanged={onRowSelectionChanged}
          ></AgGridReact>
        </div>
      </div>
    </SideNav>
  );
}
