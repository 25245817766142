import { Autocomplete, Button, Checkbox, FormControlLabel, TableBody, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { APIMASTER } from "../../../../apihandler/master";
import { APIREPORTS } from "../../../../apihandler/reports";
import { APIUTILS } from "../../../../apihandler/utils";
import {
  getItemList,
  getSiteCategories,
  getSites,
  getSupplierCategories,
  getSuppliers,
  getUoms,
  globalFilter,
  setItemList,
  setSiteCategories,
  setSites,
  setSupplierCategories,
  setSuppliers,
  setUoms,
} from "../../../../component/cellRenderers/renderers";
import SideNav from "../../../../component/layouts/sideNav";
import { setAlertTitleText } from "../../../../component/modal/alertModal";
import {
  LIGHT_GREY_BACKGROUND,
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";
import {
  CUSTOMER_MANAGEMENT,
  CUSTOMER_MANAGEMENT_REPORT,
  EXPORT,
  GENERATE,
  LABOUR_MANAGEMENT_REPORT,
  SUB_CONTRACTOR_BILLED_QTY,
} from "../../../../resources/constant/strings/string";
import { PATH_SUB_CONTRACTOR_BILLED_QTY } from "../../../../resources/constant/url/url";
import { CheckBox } from "@mui/icons-material";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  color: SPANISH_GREEN,
  transform: "translate(-50%, -50%)",
  zIndex: 2,
};

export default function SubContractorBilledQty() {
  const [itemDetails, setItemDetails] = useState([]);

  const [dataReady, setDataReady] = useState(true);
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  const cyrb53 = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }

    h1 =
      Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
      Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 =
      Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
      Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  };

  function validation() {
    setDataReady(false);
    APIREPORTS.SubContractorChecklistReport(dto)
      .then((resp) => resp.json())
      .then((data) => {
        setDataReady(true);
        if (data.statusCode !== 200) {
          setAlertTitleText("Oops..", `An error occurred ${data.message}`);
          return;
        }

        try {
          gridRef1.current.api.setRowData([]);
        } catch (e) {}

        const allItems = [];
        data.response.itemDetails.forEach((x) => {
          if (allItems.filter((v) => v === x.itemId).length <= 0) {
            allItems.push(x.itemId);
          }
        });

        const newArr = [];
        const headers = [];
        allItems.forEach((i) => {
          const m = data.response.itemDetails.filter((y) => y.itemId === i);
          let totalQty = 0;
          const row = m[0];

          m.forEach((x) => {
            const subcontractor = suppliers.filter(
              (y) => y.value === x.subContractorId
            );
            if (subcontractor.length <= 0) return;

            row.subContractor = subcontractor[0].name;

            const header = `${subcontractor[0].name} \n${dayjs(x.date).format(
              "DD-MM-YYYY"
            )} \n${x.billNo}`;
            const headerHash = `v_${cyrb53(header)}`;
            if (headers.filter((v) => v.headerName === header).length <= 0) {
              headers.push({
                headerName: header,
                field: headerHash,
              });
            }

            row[headerHash] = x.subContractorQty;
            totalQty += x.subContractorQty;
          });

          const reqdItem = items.filter((v) => v.id === i);
          if (reqdItem.length > 0) {
            const reqdUom = uoms.filter(
              (x) => x.value === reqdItem[0].stockUOMId
            );
            if (reqdUom.length > 0) row.unit = reqdUom[0].name;
          }

          row.total = totalQty;
          row.amount = APIUTILS.Financial(totalQty * row.contractorRate);

          row.purchaseSeplInvoiceQty = row.purchaseQty - row.invoiceQty;
          row.purchaseContractorQty = row.purchaseQty - row.total;
          row.billedContractorQty = row.invoiceQty - row.total;
          row.differenceAmount = APIUTILS.Financial(
            row.billedContractorQty * row.contractorRate
          );
          newArr.push(row);
        });

        dto.numberOfSubContractor = headers.length;

        setItemDetails((r) => [].concat(newArr));

        setColumnDefs([
          ...columnDefs
            .filter((x) => false)
            .concat(columnDefs1)
            .concat(headers)
            .concat(columnDefs2),
        ]);
        if(dto.isSummary === 0){
          gridRef1.current.columnApi.setColumnsVisible(["item"], true);
        } else {
          gridRef1.current.columnApi.setColumnsVisible(["item"], false);
        }
        gridRef1.current.api.applyTransaction({
          add: newArr,
        });
        setTimeout(() => {
          let pinnedBottomData = generatePinnedBottomData();
          gridRef1.current.api.setPinnedBottomRowData([pinnedBottomData]);
        }, 2000);
      })
      .catch((e) => {
        setAlertTitleText("Oops", "An error occurred");
        setDataReady(true);
        return;
      });
  }

  const generatePinnedBottomData = () => {
    // generate a row-data with null values
    let result = {};

    gridRef1.current.columnApi.getAllGridColumns().forEach((item) => {
      result[item.colId] = null;
    });
    return calculatePinnedBottomData(result);
  };

  const calculatePinnedBottomData = (target) => {
    let columnsWithAggregation = [
      "total",
      "amount",
      "purchaseQty",
      "invoiceQty",
      "purchaseSeplInvoiceQty",
      "purchaseContractorQty",
      "billedContractorQty",
      "differenceAmount"
    ];
    columnsWithAggregation.forEach((element) => {
      gridRef1.current.api.forEachNodeAfterFilter((rowNode) => {
        if (element === "itemCategory") {
          target[element] = "Grand Total";
          return;
        }
        if (rowNode.data[element] || rowNode.data[element] === 0)
          target[element] += Number.parseFloat(
            APIUTILS.Financial(rowNode.data[element])
          );
      });
      if (target[element]) {
        target[element] = `${APIUTILS.Financial(target[element])}`;
      }
    });
    return target;
  };

  const [dto, setDTO] = useState({
    id: 0,
    siteCategoryId: 0,
    siteCategory: "",
    siteId: 0,
    site: "",
    isSummary: 0,
    numberOfSubContractor: 0,
    tillDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  const items = getItemList();
  const sites = getSites();
  const uoms = getUoms();
  const siteCategoryItems = getSiteCategories();
  const siteCategoryProps = {
    options: siteCategoryItems,
    getOptionLabel: (option) => option?.name || "",
  };
  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };
  // below code is for grid - 1
  const columnDefs1 = [
    {
      flex: 2,
      headerName: "Parent Item",
      field: "parentItem",
      wrapText: true,
      autoHeight: true,
      cellStyle: {
        "border-right-color": "#e2e2e2",
      },
    },
    {
      headerName: "Item Name",
      field: "item",
      autoHeight: true,
      hide: dto.isSummary !== 0,
      wrapText: true,
    },
    {
      headerName: "Unit",
      field: "unit",
    },
  ];

  const columnDefs2 = [
    {
      headerName: "Total",
      field: "total",
    },
    {
      headerName: "Cont Rate",
      field: "contractorRate",
    },
    {
      headerName: "Amount",
      field: "amount",
    },
    {
      headerName: "Purchase Qty",
      field: "purchaseQty",
    },
    {
      headerName: "SEPL Billed Qty",
      field: "invoiceQty",
    },
    {
      headerName: "Purchase Qty SEPL Billed Qty",
      field: "purchaseSeplInvoiceQty",
    },
    {
      headerName: "Purchase Qty Cont Qty",
      field: "purchaseContractorQty",
    },
    {
      headerName: "SEPL Billed Cont Billed Qty",
      field: "billedContractorQty",
    },
    {
      headerName: "Difference Amount",
      field: "differenceAmount",
    },
  ];

  const [columnDefs, setColumnDefs] = useState(columnDefs1);

  const gridRef1 = useRef();
  const defaultColDef1 = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: "agTextColumnFilter",
    filterParams: {
      caseSensitive: false,
      defaultOption: "startsWith",
    },
    floatingFilter: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };

  const [isGridReady, setIsGridReady] = useState(false);
  useEffect(() => {
    if (isGridReady) {
      supplierCategories
        .filter((x) => false)
        .concat(
          getSupplierCategories().filter((x) => {
            return x.dependentvalue === "L";
          })
        );
      suppliers
        .filter((x) => false)
        .concat(
          getSuppliers().filter((x) => {
            return (
              supplierCategories.filter((y) => y.value === x.dependentvalue)
                .length > 0
            );
          })
        );
      sites.filter((x) => false).concat(getSites());
    }
  }, [isGridReady]);

  const onGridReady1 = (params) => {
    gridRef1.current.api.setRowData([]);

    const p1 = APIMASTER.SupplierCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        console.log("Set supplier category props");
        setSupplierCategories(resp.response);
      });
    const p2 = APIMASTER.SiteCategories()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSiteCategories(resp.response);
      });

    const p4 = APIMASTER.SiteOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSites(resp.response);
      });

    const p5 = APIMASTER.SupplierOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setSuppliers(resp.response);
      });

    const p6 = APIMASTER.UomOptions()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setUoms(resp.response);
      });

    const p7 = APIMASTER.Items()
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) return;

        setItemList(resp.response);
      });

    Promise.all([p1, p2, p4, p5, p6, p7]).then(() => {
      try {
        setDataReady(true);
        setIsGridReady(true);
        gridRef1.current.api.setRowData([]);
      } catch (e) {}
    });
  };
  const [selectedRowId1, setSelectedRowId1] = useState(0);

  const onRowSelectionChanged1 = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId1(event.api.getSelectedRows()[0].id);
    else setSelectedRowId1(0);
  };
  // grid 1 code ends

  const supplierCategories = getSupplierCategories().filter((x) => {
    return x.dependentvalue === "L";
  });
  const suppliers = getSuppliers().filter((x) => {
    return (
      supplierCategories.filter((y) => y.value === x.dependentvalue).length > 0
    );
  });

  const supplierProps = {
    options: suppliers,
    getOptionLabel: (option) => option?.name || "",
  };
  return (
    <SideNav
      currentComponent={CUSTOMER_MANAGEMENT_REPORT}
      subComponent={SUB_CONTRACTOR_BILLED_QTY}
      subComponentPath={PATH_SUB_CONTRACTOR_BILLED_QTY}
      detailComponent=""
    >
      <div>
        <div style={style}>
          <ThreeCircles
            visible={!dataReady}
            height="256"
            width="256"
            outerCircleColor={SPANISH_GREEN}
            innerCircleColor="#eeeeee"
            middleCircleColor={SPANISH_GREEN}
            ariaLabel="eNirmaan-loading"
            color={SPANISH_GREEN}
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
        <form>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={onFormSubmit}
            >
              {GENERATE}
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                //backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                marginRight: 10,
              }}
              onClick={() => {
                let filename = "";
                dto.itemDetails = itemDetails;
                setDataReady(false);
                APIREPORTS.SubContractorCheckListExport(dto)
                  .then((res) => {
                    setDataReady(true);

                    const contentType = res.headers.get("content-type");
                    if (contentType.startsWith("application/json")) {
                      return Promise.reject(res.json());
                    } else {
                      const header = res.headers.get("Content-Disposition");
                      if (header !== null) {
                        const parts = header.split(";");
                        filename = parts[1].split("=")[1].replaceAll('"', "");
                      } else {
                        filename =
                          "Sub Contractor Check List Export Report.xlsx";
                      }

                      return res.blob();
                    }
                  })
                  .then((blob) => {
                    if (blob !== null) {
                      let url = window.URL.createObjectURL(blob);
                      let a = document.createElement("a");
                      a.href = url;
                      a.download = filename;
                      document.body.appendChild(a);
                      a.click();
                      a.remove();
                    }
                  })
                  .catch((m) => {
                    setAlertTitleText(
                      "Oops...",
                      `Something went wrong...${m.message}`
                    );
                  });
              }}
            >
              {EXPORT}
            </Button>
          </div>
          {/* button div ends */}
          {/* below code is for above container */}
          <div
            style={{
              backgroundColor: LIGHT_GREY_BACKGROUND,
              borderRadius: "5px",
              minWidth: "90%",
              minHeight: "50px",
              padding: 20,
              marginBottom: 10,
              borderStyle: "solid",
              border: "1x",
              borderColor: "#eaeaea",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <table style={{ width: "100%" }}>
              <TableBody>
                <tr>
                  <td>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Till Date"
                        inputFormat="DD-MM-YYYY"
                        style={{ minWidth: "272px", minHeight: "48.5px" }}
                        value={dto.tillDate}
                        onChange={(newValue) => {
                          setDTO({
                            ...dto,
                            tillDate: newValue.format("YYYY-MM-DD"),
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            style={{
                              minWidth: "200px",
                              minHeight: "48.5px",
                              width: "200px",
                            }}
                            InputLabelProps={{
                              style: { color: "grey", fontSize: 14 },
                            }}
                            InputProps={{
                              ...params.InputProps,
                              style: { fontSize: 14 },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </td>
                  <td>
                    <Autocomplete
                      filterOptions={globalFilter}
                      {...siteCategoryProps}
                      options={siteCategoryItems}
                      id="auto-complete-1"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="siteCategoryId"
                      defaultValue={
                        siteCategoryProps.options.find(
                          (v) => v.value === dto.siteCategoryId
                        ) || {}
                      }
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({
                              ...dto,
                              siteCategoryId: v?.value,
                              siteCategory: v?.name,
                            });
                          else
                            setDTO({
                              ...dto,
                              siteCategoryId: Number(v?.value),
                              siteCategory: v?.name,
                            });
                        } else {
                          setDTO({
                            ...dto,
                            siteCategoryId: v?.value,
                            siteCategory: v?.name,
                          });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Site Category"
                          required
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            width: "200px",
                            minHeight: "48.5px",
                            height: "48.5px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey" },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>
                  <td>
                    <Autocomplete
                      {...siteProps}
                      id="auto-complete-2"
                      autoComplete
                      autoHighlight
                      autoSelect
                      includeInputInList
                      name="siteId"
                      defaultValue={
                        siteProps.options.find((v) => v.value === dto.siteId) ||
                        {}
                      }
                      filterOptions={(o, s) => {
                        let options = globalFilter(o, s);
                        let newOptions = [];
                        options.forEach((element) => {
                          if (element.dependentvalue === dto.siteCategoryId)
                            newOptions.push(element);
                        });
                        return newOptions;
                      }}
                      onChange={(e, v) => {
                        if (v) {
                          if (isNaN(v?.value))
                            setDTO({ ...dto, siteId: v?.value, site: v?.name });
                          else
                            setDTO({
                              ...dto,
                              siteId: Number(v?.value),
                              site: v?.name,
                            });
                        } else {
                          setDTO({ ...dto, siteId: v?.value, site: v?.name });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Site"
                          variant="standard"
                          style={{
                            minWidth: "200px",
                            minHeight: "48.5px",
                            width: "200px",
                          }}
                          InputLabelProps={{
                            style: { color: "grey", fontSize: 14 },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            style: { fontSize: 14 },
                          }}
                        />
                      )}
                    />
                  </td>

                  <td>
                    <FormControlLabel
                      name="isSummary"
                      style={{ userSelect: "none" }}
                      control={<Checkbox size="small" color="success" />}
                      onChange={(e, v) =>
                        setDTO({
                          ...dto,
                          isSummary: e.target.checked ? 1 : 0,
                        })
                      }
                      checked={dto.isSummary!==0} 
                      label="Is Summary"
                    />
                  </td>
                </tr>
              </TableBody>
            </table>
          </div>
        </form>
      </div>

      {/* below code is for ag-grid -1 */}
      <div className="App">
        <div className="ag-theme-alpine" style={{ height: "570px" }}>
          <AgGridReact
            copyGroupHeadersToClipboard={true}
            enableRangeSelection={true}
            columnDefs={columnDefs}
            ref={gridRef1}
            defaultColDef={defaultColDef1}
            onGridReady={onGridReady1}
            paginationAutoPageSize={true} pagination={true}
            onSelectionChanged={onRowSelectionChanged1}
          ></AgGridReact>
        </div>
      </div>
    </SideNav>
  );
}
