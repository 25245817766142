import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  TableBody,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { APIMASTER } from "../../../../apihandler/master";
import {
  getCountries,
  getSiteCategories,
  getStates,
  globalFilter,
} from "../../../../component/cellRenderers/renderers";
import { setAlertTitleText } from "../../../../component/modal/alertModal";
import {
  SPANISH_GREEN,
  WHITE,
} from "../../../../resources/constant/colors/theme";
import { isValidEmail } from "../../../../resources/constant/utilities/util";
import "./modal.css";

function Modal({
  setOpenModal,
  model,
  formTitle,
  updateModel,
  addModel,
  viewModel,
}) {
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    if (errorEmail) {
      setErrorEmail("Enter a valid Email");
      return;
    }
    if (errorMobile) {
      setErrorMobile("Enter a valid Number");
      return;
    }
    if (dto.countryId <= 0 || dto.stateId <= 0) {
      setAlertTitleText("Oops...", `Please select state and country`);
      return;
    }
    //api call
    if (dto.id <= 0)
      APIMASTER.AddSite(dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            addModel(data.response);
            setAlertTitleText(
              "Success",
              "The record was inserted successfully"
            );
            setOpenModal(false);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    else
      APIMASTER.UpdateSite(dto.id, dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            updateModel(data.response);
            setAlertTitleText("Success", "The record was updated successfully");
            setOpenModal(false);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
          }
        });
  }

  const [errorEmail, setErrorEmail] = useState("");
  const [errorMobile, setErrorMobile] = useState("");
  const categoryItems = getSiteCategories();
  const countries = getCountries();
  const states = getStates();

  const [dto, setDTO] = useState(model);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (
        isNaN(value) ||
        name === "phone" ||
        name === "mobile" ||
        name === "pin" ||
        name === "accountNo" ||
        name === "panNo" ||
        name === "rtgsCode" ||
        name === "gstn" ||
        name === "address1" ||
        name === "address2" ||
        name === "address3" ||
        name === "sstGst"
      )
        setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    } else {
      setDTO({ ...dto, [name]: value });
    }
  };

  const categoryProps = {
    options: categoryItems,
    getOptionLabel: (option) => option?.name || "",
  };

  const countryProps = {
    options: countries,
    getOptionLabel: (option) => option?.name || "",
  };

  const stateProps = {
    options: states,
    getOptionLabel: (option) => option?.name || "",
  };

  return (
    <div className="modalBackgroundForSite">
      {/* div for top right close button */}
      <form>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton
            style={{ width: "auto", align: "center", marginRight: 5 }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* top right close button div closed */}

        <div
          style={{
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <table style={{ width: "100%", margin: 5 }}>
            <TableBody>
              <tr>
                <td>
                  <Typography
                    fontSize={18}
                    fontWeight={600}
                    fontspacing={"normal"}
                    textTransform="capitalize"
                    align="left"
                  >
                    {formTitle}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    variant="standard"
                    label="Site Code"
                    name="customerCode"
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    value={dto.customerCode}
                    disabled
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
                <td>
                  <TextField
                    inputProps={{
                      maxlength: 128,
                    }}
                    variant="standard"
                    label="Site Name"
                    required
                    disabled={viewModel}
                    name="customerName"
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    value={dto.customerName}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    {...categoryProps}
                    id="auto-complete-3"
                    autoComplete
                    autoHighlight
                    autoSelect
                    disabled={viewModel}
                    includeInputInList
                    name="categoryId"
                    defaultValue={
                      categoryProps.options.find(
                        (v) => v.value === dto.categoryId
                      ) || {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, categoryId: v?.value });
                        else setDTO({ ...dto, categoryId: Number(v?.value) });
                      } else {
                        setDTO({ ...dto, categoryId: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={viewModel}
                        label="Category"
                        variant="standard"
                        required
                        style={{
                          minWidth: "200px",
                          width: "200px",
                          minHeight: "48.5px",
                          height: "48.5px",
                        }}
                        InputLabelProps={{
                          style: { color: "grey" },
                        }}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    inputProps={{
                      maxlength: 128,
                    }}
                    variant="standard"
                    label="Address 1"
                    disabled={viewModel}
                    required
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    value={dto.address1}
                    name="address1"
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
                <td>
                  <TextField
                    inputProps={{
                      maxlength: 128,
                    }}
                    variant="standard"
                    label="Address 2"
                    disabled={viewModel}
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    value={dto.address2}
                    name="address2"
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>

                <td>
                  <TextField
                    inputProps={{
                      maxlength: 128,
                    }}
                    variant="standard"
                    label="Address 3"
                    disabled={viewModel}
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    value={dto.address3}
                    name="address3"
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    inputProps={{
                      maxlength: 128,
                    }}
                    variant="standard"
                    label="Prefix"
                    name="prefix"
                    disabled={viewModel}
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    value={dto.prefix}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
                <td>
                  <TextField
                    inputProps={{
                      maxlength: 128,
                    }}
                    variant="standard"
                    label="City"
                    disabled={viewModel}
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    value={dto.city}
                    name="city"
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    {...stateProps}
                    id="auto-complete-2"
                    disabled={viewModel}
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    name="stateId"
                    defaultValue={
                      stateProps.options.find((v) => v.value === dto.stateId) ||
                      {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v.value))
                          setDTO({
                            ...dto,
                            stateId: v.value,
                            countryId: v.dependentvalue,
                          });
                        else
                          setDTO({
                            ...dto,
                            stateId: Number(v.value),
                            countryId: Number(v.dependentvalue),
                          });
                      } else {
                        setDTO({
                          ...dto,
                          stateId: v?.value,
                          countryId: v?.dependentvalue,
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={viewModel}
                        label="State"
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          minHeight: "48.5px",
                          width: "200px",
                          height: "48.5px",
                        }}
                        InputLabelProps={{
                          style: { color: "grey" },
                        }}
                      />
                    )}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    {...countryProps}
                    id="auto-complete-1"
                    disabled={viewModel}
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    name="countryId"
                    value={
                      countryProps.options.find(
                        (v) => v.value === dto.countryId
                      ) || {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, countryId: v?.value });
                        else setDTO({ ...dto, countryId: Number(v?.value) });
                      } else {
                        setDTO({ ...dto, countryId: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled={viewModel}
                        label="Country"
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          minHeight: "48.5px",
                          width: "200px",
                          height: "48.5px",
                        }}
                        InputLabelProps={{
                          style: { color: "grey" },
                        }}
                      />
                    )}
                  />
                </td>
                <td>
                  <TextField
                    inputProps={{
                      maxlength: 16,
                    }}
                    variant="standard"
                    label="PIN"
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    value={dto.pin}
                    name="pin"
                    disabled={viewModel}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
                <td>
                  <TextField
                    inputProps={{
                      maxlength: 128,
                    }}
                    variant="standard"
                    label="Email ID"
                    type="email"
                    disabled={viewModel}
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    value={dto.email}
                    name="email"
                    helperText={errorEmail}
                    error={!!errorEmail}
                    onChange={(e, v) => {
                      if (!isValidEmail(e.target.value)) {
                        setErrorEmail("Enter valid email");
                      } else {
                        setErrorEmail("");
                      }
                      handleInputChange(e, v);
                    }}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    variant="standard"
                    label="Ph no"
                    disabled={viewModel}
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    value={dto.phone?.toString()}
                    name="phone"
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
                <td>
                  <TextField
                    variant="standard"
                    label="MOB"
                    disabled={viewModel}
                    helperText={errorMobile}
                    error={!!errorMobile}
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    value={dto.mobile}
                    name="mobile"
                    onChange={(e, v) => {
                      if (e.target.value.length !== 10) {
                        setErrorMobile("Enter a valid mobile number");
                      } else {
                        setErrorMobile("");
                      }
                      handleInputChange(e, v);
                    }}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+91</InputAdornment>
                      ),
                    }}
                  ></TextField>
                </td>
                <td>
                  <TextField
                    inputProps={{
                      maxlength: 128,
                    }}
                    variant="standard"
                    label="PAN"
                    disabled={viewModel}
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    value={dto.panNo}
                    name="panNo"
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    inputProps={{
                      maxlength: 16,
                    }}
                    disabled={viewModel}
                    variant="standard"
                    label="GST no"
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    name="gstn"
                    value={dto.gstn}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
                <td>
                  <TextField
                    inputProps={{
                      maxlength: 128,
                    }}
                    disabled={viewModel}
                    variant="standard"
                    label="Contact Person"
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    name="contactPerson"
                    value={dto.contactPerson}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
                <td>
                  <TextField
                    inputProps={{
                      maxlength: 128,
                    }}
                    disabled={viewModel}
                    variant="standard"
                    label="Work Order Prefix"
                    name="woPrefix"
                    style={{
                      minWidth: "200px",
                      width: "200px",
                      minHeight: "48.5px",
                    }}
                    value={dto.woPrefix}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
              </tr>
              {/* <tr>
                <td>
                  <FormControlLabel
                    disabled={viewModel}
                    name="isStore"
                    checked={dto.isStore}
                    value={dto.isStore}
                    onChange={(e, v) =>
                      setDTO({ ...dto, ["isStore"]: e.target.checked ? 1 : 0 })
                    }
                    control={<Checkbox color="success" />}
                    label="Is Billable"
                  />
                </td>
              </tr> */}
            </TableBody>
          </table>
        </div>

        {/* div for buttons */}
        {!viewModel ? (
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              marginTop: 10,
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={onFormSubmit}
            >
              Save
            </Button>
          </div>
        ) : (
          <div />
        )}
        {/* div for buttons closed */}

        {/* pop up div closed  */}
      </form>
    </div>
  );
}

export default Modal;
