import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  IconButton,
  TableBody,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  SPANISH_GREEN,
  WHITE,
} from "../../../../../resources/constant/colors/theme";

import { APIUTILS } from "../../../../../apihandler/utils";
import {
  getItemCategories,
  getItemList,
  getItems,
  getUoms,
  globalFilter,
} from "../../../../../component/cellRenderers/renderers";
import { setAlertTitleText } from "../../../../../component/modal/alertModal";
import "./editModal.css";

function EditModal({
  setOpenModal,
  model,
  formTitle,
  updateModel,
  addModel,
  viewModel,
  onAdd,
  onUpdate,
}) {
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
  };
  function validation() {
    if (dto.invoiceQty !== null && dto.qty < dto.invoiceQty) {
      setAlertTitleText(
        "Oops...",
        "Updated quantity cannot be less than invoiced quantity"
      );
      return;
    }

    //only required field validation so direct api call
    if (dto.id <= 0) onAdd([dto]);
    else onUpdate([dto]);

    setOpenModal(false);
  }

  const itemCategories = getItemCategories();

  const itemCategoryProps = {
    options: itemCategories,
    getOptionLabel: (option) => option?.name || "",
  };

  const items = getItems();

  const itemProps = {
    options: items,
    getOptionLabel: (option) => option?.name || "",
  };
  const itemList = getItemList();
  const uomList = getUoms();
  const uomProps = {
    options: uomList,
    getOptionLabel: (option) => option?.name || "",
  };

  const [dto, setDTO] = useState(model);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (isNaN(value) || name === "description")
        setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    } else {
      setDTO({ ...dto, [name]: value });
    }
  };

  return (
    <div className="modalBackgroundForSOEdit">
      {/* div for top right close button */}
      <form>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton
            style={{ width: "auto", align: "center", marginRight: 5 }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* top right close button div closed */}

        <div
          style={{
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <table style={{ width: "100%", margin: 5 }}>
            <TableBody>
              <tr>
                <td>
                  <Typography
                    fontSize={18}
                    fontWeight={600}
                    fontspacing={"normal"}
                    textTransform="capitalize"
                    align="left"
                  >
                    {formTitle}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    id={`autocomplete-1`}
                    {...itemCategoryProps}
                    variant="standard"
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    defaultValue={
                      itemCategoryProps.options.find(
                        (v) => v?.value === dto.categoryId
                      ) || {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, categoryId: v?.value });
                        else setDTO({ ...dto, categoryId: Number(v?.value) });
                      } else {
                        setDTO({ ...dto, categoryId: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Item Category"
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          minHeight: "48.5px",
                          width: "200px",
                          height: "48.5px",
                          marginBottom: 5,
                          marginLeft: 5,
                        }}
                        InputLabelProps={{
                          style: { color: "grey" },
                        }}
                      />
                    )}
                  />
                </td>
                <td>
                  <Autocomplete
                    {...itemProps}
                    autoComplete
                    autoHighlight
                    autoSelect
                    variant="standard"
                    disabled={false}
                    style={{
                      minWidth: "200px",
                      minHeight: "38.5px",
                      width: "200px",
                      height: "38.5px",
                    }}
                    filterOptions={(o, s) => {
                      let options = globalFilter(o, s);
                      let newOptions = [];
                      options.forEach((element) => {
                        if (element.dependentvalue === dto.categoryId)
                          newOptions.push(element);
                      });
                      return newOptions;
                    }}
                    defaultValue={
                      itemProps.options.find((v) => v?.value === dto.itemId) ||
                      {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        let itemCode = "",
                          uomName = "";
                        let uomId = 0;
                        const items = itemList.filter((a, i) => {
                          return a.id === v?.value;
                        });

                        if (items.length > 0) {
                          itemCode = items[0].itemCode;
                          uomId = items[0].stockUOMId;
                          uomName = uomList.filter((x) => x.value === uomId)[0]
                            .name;
                        }
                        if (isNaN(v?.value))
                          setDTO({
                            ...dto,
                            itemId: v?.value,
                            itemCode: itemCode,
                            uomId: uomId,
                            uomName: uomName,
                          });
                        else
                          setDTO({
                            ...dto,
                            itemId: Number(v?.value),
                            itemCode: itemCode,
                            uomId: uomId,
                            uomName: uomName,
                          });
                      } else {
                        let itemCode = "",
                          uomName = "";
                        let uomId = 0;
                        const items = itemList.filter((a, i) => {
                          return a.id === v?.value;
                        });
                        if (items.length > 0) {
                          itemCode = items[0].itemCode;
                          uomId = items[0].stockUOMId;
                          uomName = uomList.filter((x) => x.value === uomId)[0]
                            .name;
                        }
                        setDTO({
                          ...dto,
                          itemId: v?.value,
                          itemCode: itemCode,
                          uomId: uomId,
                          uomName: uomName,
                        });
                      }
                    }}
                    name="itemId"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Item Name"
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          minHeight: "38.5px",
                          width: "200px",
                          height: "38.5px",
                        }}
                        InputLabelProps={{
                          style: { color: "grey", fontSize: 14 },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          style: { fontSize: 14 },
                        }}
                      />
                    )}
                  ></Autocomplete>
                </td>
                <td>
                  <TextField
                    variant="standard"
                    label="Item Code"
                    disabled={true}
                    style={{
                      minWidth: "200px",
                      minHeight: "38.5px",
                      height: "38.5px",
                      width: "200px",
                      fontSize: 10,
                    }}
                    name="itemCode"
                    value={dto.itemCode}
                    InputLabelProps={{
                      style: { color: "grey", fontSize: 14 },
                    }}
                    InputProps={{
                      style: { fontSize: 14 },
                    }}
                  ></TextField>
                </td>
                <td>
                  <TextField
                    variant="standard"
                    label="Quantity"
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setDTO({ ...dto, [name]: value });
                    }}
                    onWheel={(event) => event.target.blur()}
                    style={{
                      minWidth: "200px",
                      minHeight: "38.5px",
                      height: "38.5px",
                      width: "200px",
                      fontSize: 10,
                    }}
                    name="qty"
                    onBlur={(e) => {
                      setDTO({
                        ...dto,
                        qty: Number(e.target.value),
                        supplyAmt: Number.parseFloat(
                          APIUTILS.Financial(
                            Number(e.target.value) * dto.supplyRate
                          )
                        ),
                        installationAmt: Number.parseFloat(
                          APIUTILS.Financial(
                            Number(e.target.value) * dto.installationRate
                          )
                        ),
                      });
                    }}
                    value={dto.qty}
                    InputLabelProps={{
                      style: { color: "grey", fontSize: 14 },
                    }}
                    InputProps={{
                      style: { fontSize: 14 },
                    }}
                  ></TextField>
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    variant="standard"
                    label="Item Units"
                    style={{
                      minWidth: "200px",
                      minHeight: "38.5px",
                      height: "38.5px",
                      width: "200px",
                      fontSize: 10,
                    }}
                    name="uomName"
                    value={dto.uomName}
                    InputLabelProps={{
                      style: { color: "grey", fontSize: 14 },
                    }}
                    InputProps={{
                      style: { fontSize: 14 },
                    }}
                  ></TextField>
                </td>
                <td>
                  <TextField
                    variant="standard"
                    label="Supply Rate"
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setDTO({ ...dto, [name]: value });
                    }}
                    onWheel={(event) => event.target.blur()}
                    style={{
                      minWidth: "100px",
                      minHeight: "38.5px",
                      height: "38.5px",
                      width: "100px",
                      fontSize: 10,
                      marginBottom: 5,
                      marginLeft: 5,
                    }}
                    name="supplyRate"
                    onBlur={(e) => {
                      setDTO({
                        ...dto,
                        supplyRate: Number(e.target.value),
                        supplyAmt: Number.parseFloat(
                          APIUTILS.Financial(dto.qty * Number(e.target.value))
                        ),
                        installationAmt: Number.parseFloat(
                          APIUTILS.Financial(dto.qty * dto.installationRate)
                        ),
                      });
                    }}
                    value={dto.supplyRate}
                    InputLabelProps={{
                      style: { color: "grey", fontSize: 14 },
                    }}
                    InputProps={{
                      style: { fontSize: 14 },
                    }}
                  ></TextField>
                </td>
                <td>
                  <TextField
                    variant="standard"
                    label="Inst Rate"
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setDTO({ ...dto, [name]: value });
                    }}
                    onWheel={(event) => event.target.blur()}
                    style={{
                      minWidth: "100px",
                      minHeight: "38.5px",
                      height: "38.5px",
                      width: "100px",
                      fontSize: 10,
                      marginBottom: 5,
                      marginLeft: 5,
                    }}
                    name="installationRate"
                    onBlur={(e) => {
                      setDTO({
                        ...dto,
                        installationRate: Number(e.target.value),
                        supplyAmt: Number.parseFloat(
                          APIUTILS.Financial(dto.qty * dto.supplyRate)
                        ),
                        installationAmt: Number.parseFloat(
                          APIUTILS.Financial(dto.qty * Number(e.target.value))
                        ),
                      });
                    }}
                    value={dto.installationRate}
                    InputLabelProps={{
                      style: { color: "grey", fontSize: 14 },
                    }}
                    InputProps={{
                      style: { fontSize: 14 },
                    }}
                  ></TextField>
                </td>
                <td>
                  <TextField
                    variant="standard"
                    label="Supply Amount"
                    disabled={true}
                    
                    onWheel={(event) => event.target.blur()}
                    value={dto.supplyAmt}
                    style={{
                      minWidth: "200px",
                      minHeight: "48.5px",
                      height: "48.5px",
                      width: "200px",
                      fontSize: 10,
                    }}
                    name="supplyAmount"
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
              </tr>
              <tr>
                <td>
                  <TextField
                    variant="standard"
                    label="Inst. Amount"
                    disabled={true}
                    
                    onWheel={(event) => event.target.blur()}
                    style={{
                      minWidth: "200px",
                      minHeight: "48.5px",
                      height: "48.5px",
                      width: "200px",
                      fontSize: 10,
                    }}
                    value={dto.installationAmt}
                    name="installationAmt"
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
              </tr>
            </TableBody>
          </table>
        </div>

        {/* div for buttons */}
        {!viewModel ? (
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              marginTop: "20px",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={onFormSubmit}
            >
              Save
            </Button>
          </div>
        ) : (
          <div />
        )}
        {/* div for buttons closed */}
      </form>
      {/* pop up div closed  */}
    </div>
  );
}

export default EditModal;
