import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  IconButton,
  InputAdornment,
  TableBody,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  BLACK,
  SPANISH_GREEN,
  WHITE,
} from "../../../../../../resources/constant/colors/theme";

import {
  getItemCategories,
  getItemList,
  getItems,
  getUoms,
  getUomTypes,
  globalFilter,
} from "../../../../../../component/cellRenderers/renderers";
import "./editModal.css";
import { APIMATERIALMGMT } from "../../../../../../apihandler/materialmgmt";
import { APIUTILS } from "../../../../../../apihandler/utils";
import { setAlertTitleText } from "../../../../../../component/modal/alertModal";
import { Stack } from "@mui/system";

function EditModal({
  setOpenModal,
  model,
  formTitle,
  updateModel,
  addModel,
  viewModel,
  onAdd,
  onUpdate,
  itemsInOriginal,
}) {
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    if (dto.id <= 0) onAdd(dto);
    else onUpdate(dto);

    setOpenModal(false);
  }
  const categoryItems = getUomTypes();

  const [dto, setDTO] = useState(model);
  const [poItems, setPOItems] = useState(itemsInOriginal);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (value) {
      if (isNaN(value) || name === "itemDescription")
        setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    } else {
      setDTO({ ...dto, [name]: value });
    }
  };

  const categoryProps = {
    options: categoryItems,
    getOptionLabel: (option) => option?.name || "",
  };

  const itemCategories = getItemCategories();

  const itemCategoryProps = {
    options: itemCategories,
    getOptionLabel: (option) => option?.name || "",
  };
  const itemList = getItemList();
  const items = getItems();
  const uomList = getUoms();
  const uomProps = {
    options: uomList,
    getOptionLabel: (option) => option?.name || "",
  };

  const itemProps = {
    options: items,
    getOptionLabel: (option) => option?.name || "",
  };

  return (
    <div className="modalBackgroundForGRNEdit">
      {/* div for top right close button */}
      <form>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton
            style={{ width: "auto", align: "center", marginRight: 5 }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* top right close button div closed */}

        <div
          style={{
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <table style={{ width: "100%", margin: 5 }}>
            <TableBody>
              <tr>
                <td>
                  <Typography
                    fontSize={18}
                    fontWeight={600}
                    fontspacing={"normal"}
                    textTransform="capitalize"
                    align="left"
                  >
                    {formTitle}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Autocomplete
                    filterOptions={globalFilter}
                    id={`autocomplete-1`}
                    {...itemCategoryProps}
                    disabled={dto.id > 0}
                    variant="standard"
                    autoComplete
                    autoHighlight
                    autoSelect
                    includeInputInList
                    defaultValue={
                      itemCategoryProps.options.find(
                        (v) => v?.value === dto.itemCategoryId
                      ) || {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        if (isNaN(v?.value))
                          setDTO({ ...dto, itemCategoryId: v?.value });
                        else
                          setDTO({ ...dto, itemCategoryId: Number(v?.value) });
                      } else {
                        setDTO({ ...dto, itemCategoryId: v?.value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Item Category"
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          minHeight: "48.5px",
                          width: "200px",
                          height: "48.5px",
                          marginBottom: 5,
                          marginLeft: 5,
                        }}
                        InputLabelProps={{
                          style: { color: "grey" },
                        }}
                      />
                    )}
                  />
                </td>
                <td>
                  <Autocomplete
                    id={`autocomplete-2`}
                    {...itemProps}
                    autoComplete
                    autoHighlight
                    autoSelect
                    disabled={dto.id > 0}
                    variant="standard"
                    filterOptions={(o, s) => {
                      let options = globalFilter(o, s);
                      let newOptions = [];
                      options.forEach((element) => {
                        if (element.dependentvalue === dto.itemCategoryId)
                          newOptions.push(element);
                      });
                      if (dto.isPOItem) {
                        newOptions = newOptions.filter(
                          (x) => poItems.indexOf(x.value) !== -1
                        );
                      }
                      return newOptions;
                    }}
                    defaultValue={
                      itemProps.options.find((v) => v?.value === dto.itemId) ||
                      {}
                    }
                    onChange={(e, v) => {
                      if (v) {
                        let uomName = "";
                        let description = "";
                        const items = itemList.filter((a, i) => {
                          return a.id === v?.value;
                        });

                        if (items.length > 0) {
                          description = items[0].description;
                          uomList.forEach((element) => {
                            if (element.value === items[0].purchaseUOMId)
                              uomName = element.name;
                          });
                        }

                        if (isNaN(v?.value))
                          setDTO({
                            ...dto,
                            itemId: v?.value,
                            uomName: uomName,
                            itemDescription: description,
                          });
                        else
                          setDTO({
                            ...dto,
                            itemId: Number(v?.value),
                            uomName: uomName,
                            itemDescription: description,
                          });
                      } else {
                        let uomName = "";
                        let description = "";
                        const items = itemList.filter((a, i) => {
                          return a.id === v?.value;
                        });
                        if (items.length > 0) {
                          description = items[0].description;
                          uomList.forEach((element) => {
                            if (element.value === items[0].purchaseUOMId)
                              uomName = element.name;
                          });
                        }
                        setDTO({
                          ...dto,
                          itemId: v?.value,
                          uomName: uomName,
                          itemDescription: description,
                        });
                      }
                    }}
                    name="itemId"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Item Name"
                        variant="standard"
                        style={{
                          minWidth: "200px",
                          minHeight: "48.5px",
                          width: "200px",
                          height: "48.5px",
                        }}
                        {...params}
                        InputLabelProps={{
                          style: {
                            color: "grey",
                          },
                        }}
                      />
                    )}
                  ></Autocomplete>
                </td>

                <td>
                  <TextField
                    variant="standard"
                    label="Quantity"
                    name="purchaseQty"
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setDTO({...dto, [name]: value});
                    }}
                    onBlur={(e) => {
                      if (dto.isPOItem) {
                        if (
                          dto.purchaseQty > dto.balanceQty &&
                          dto.balanceQty !== 0
                        ) {
                          setAlertTitleText(
                            "Oops...",
                            "Quantity cannot be greater than the balance"
                          );
                          setDTO({
                            ...dto,
                            purchaseQty: Number(e.target.value),
                          });
                          return;
                        }
                      }
                      const basicCost = Number(
                        APIUTILS.Financial(Number(e.target.value) * dto.rate)
                      );
                      handleInputChange(e);
                      dto.purchaseQty = Number(e.target.value);
                      if (dto.tax.taxIds.length > 0) {
                        APIUTILS.CalculateTax({
                          taxIds: dto.tax.taxIds,
                          basicAmount: basicCost,
                        })
                          .then((resp) => resp.json())
                          .then((resp) => {
                            if (resp.statusCode !== 200) {
                              setAlertTitleText(
                                "Oops...",
                                "Cannot calculate tax"
                              );
                              return;
                            }

                            dto.tax = resp.response;
                            const taxCost = Number.parseFloat(
                              APIUTILS.Financial(Number(resp.response.taxValue))
                            );
                            const totalCost = Number.parseFloat(
                              APIUTILS.Financial(
                                Number(resp.response.totalValue)
                              )
                            );

                            APIMATERIALMGMT.StockConversion({
                              items: [
                                {
                                  itemId: dto.itemId,
                                  purchaseQty: dto.purchaseQty,
                                  stockQty: 0,
                                  balanceQty: dto.balanceQty,
                                },
                              ],
                            })
                              .then((stk) => stk.json())
                              .then((stk) => {
                                if (stk.statusCode === 200) {
                                  setDTO({
                                    ...dto,
                                    stockQty: stk.response.items[0].stockQty,
                                    basicCost: basicCost,
                                    taxCost: taxCost,
                                    totalCost: totalCost,
                                  });
                                }
                              });
                          });
                      } else {
                        APIMATERIALMGMT.StockConversion({
                          items: [
                            {
                              itemId: dto.itemId,
                              purchaseQty: Number(e.target.value),
                              stockQty: 0,
                              balanceQty: dto.balanceQty,
                            },
                          ],
                        })
                          .then((resp) => resp.json())
                          .then((resp) => {
                            if (resp.statusCode === 200) {
                              const taxCost = 0;
                              const totalCost = basicCost;

                              setDTO({
                                ...dto,
                                stockQty: resp.response.items[0].stockQty,
                                basicCost: basicCost,
                                taxCost: taxCost,
                                totalCost: totalCost,
                              });
                            }
                          });
                      }
                    }}
                    value={dto.purchaseQty}
                    InputLabelProps={{
                      style: { color: "grey", fontSize: 14 },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {dto.uomName}
                        </InputAdornment>
                      ),
                      style: { fontSize: 14 },
                    }}
                    
                    onWheel={(event) => event.target.blur()}
                    style={{
                      minWidth: "200px",
                      minHeight: "48.5px",
                      height: "48.5px",
                      width: "200px",
                      fontSize: 10,
                    }}
                  ></TextField>
                </td>
                <td>
                  <TextField
                    variant="standard"
                    label="Stock Qty"
                    disabled={true}
                    value={dto.stockQty}
                    name="stockQty"
                    
                    onWheel={(event) => event.target.blur()}
                    style={{
                      minWidth: "200px",
                      minHeight: "48.5px",
                      height: "48.5px",
                      width: "200px",
                      fontSize: 10,
                      marginBottom: 5,
                      marginLeft: 5,
                    }}
                    InputLabelProps={{
                      style: { color: "grey" },
                    }}
                  ></TextField>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={2}
                  >
                    <TextareaAutosize
                      variant="standard"
                      label="Description"
                      maxLength={128}
                      onChange={handleInputChange}
                      value={dto.itemDescription}
                      style={{
                        outlineColor: BLACK,
                        borderTop: "10px",
                        borderRight: "10px",
                        borderLeft: "10px",
                        marginTop: 15,
                        fontSize: 12,
                        fontFamily: "sans-serif",
                        maxWidth: "930px",
                      }}
                      multiline="true"
                      maxRows={4}
                      minRows={2}
                      name="itemDescription"
                      placeholder="Item Description"
                    ></TextareaAutosize>
                  </Stack>
                </td>
              </tr>
              <tr>
                {(
                  <td>
                    <TextField
                      variant="standard"
                      label="Rate"
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setDTO({...dto, [name]: value});
                      }}
                      onBlur={(e) => {
                        const basicCost = Number(
                          APIUTILS.Financial(Number(e.target.value) * dto.purchaseQty)
                        );
                        handleInputChange(e);
                        dto.rate = Number(e.target.value);
                        if (dto.tax.taxIds.length > 0) {
                          APIUTILS.CalculateTax({
                            taxIds: dto.tax.taxIds,
                            basicAmount: basicCost,
                          })
                            .then((resp) => resp.json())
                            .then((resp) => {
                              if (resp.statusCode !== 200) {
                                setAlertTitleText(
                                  "Oops...",
                                  "Cannot calculate tax"
                                );
                                return;
                              }
  
                              dto.tax = resp.response;
                              const taxCost = Number.parseFloat(
                                APIUTILS.Financial(Number(resp.response.taxValue))
                              );
                              const totalCost = Number.parseFloat(
                                APIUTILS.Financial(
                                  Number(resp.response.totalValue)
                                )
                              );
  
                              setDTO({
                                ...dto,
                                basicCost: basicCost,
                                taxCost: taxCost,
                                totalCost: totalCost,
                              });
                            });
                        } else {
                          setDTO({
                            ...dto,
                            basicCost: basicCost,
                            taxCost: 0,
                            totalCost: basicCost,
                          });
                        }
                      }}
                      value={dto.rate}
                      disabled={dto.isPOItem}
                      onWheel={(event) => event.target.blur()}
                      style={{
                        minWidth: "200px",
                        minHeight: "48.5px",
                        height: "48.5px",
                        width: "200px",
                        fontSize: 10,
                      }}
                      name="rate"
                      InputLabelProps={{
                        style: { color: "grey" },
                      }}
                    ></TextField>
                  </td>
                )}
                {(
                  <td>
                    <TextField
                      variant="standard"
                      label="Basic Cost"
                      disabled={true}
                      
                      onWheel={(event) => event.target.blur()}
                      style={{
                        minWidth: "200px",
                        minHeight: "48.5px",
                        height: "48.5px",
                        width: "200px",
                        fontSize: 10,
                        marginBottom: 5,
                        marginLeft: 5,
                      }}
                      name="basicCost"
                      value={dto.basicCost}
                      InputLabelProps={{
                        style: { color: "grey" },
                      }}
                    ></TextField>
                  </td>
                )}
                {(
                  <td>
                    <TextField
                      variant="standard"
                      label="Tax Cost"
                      disabled={true}
                      
                      onWheel={(event) => event.target.blur()}
                      style={{
                        minWidth: "200px",
                        minHeight: "48.5px",
                        height: "48.5px",
                        width: "200px",
                        fontSize: 10,
                      }}
                      name="taxCost"
                      value={dto.taxCost}
                      InputLabelProps={{
                        style: { color: "grey" },
                      }}
                    ></TextField>
                  </td>
                )}
                {(
                  <td>
                    <TextField
                      variant="standard"
                      label="Total Cost"
                      
                      onWheel={(event) => event.target.blur()}
                      disabled={true}
                      style={{
                        minWidth: "200px",
                        minHeight: "48.5px",
                        height: "48.5px",
                        width: "200px",
                        fontSize: 10,
                        marginBottom: 5,
                        marginLeft: 5,
                      }}
                      value={dto.totalCost}
                      name="totalCost"
                      InputLabelProps={{
                        style: { color: "grey" },
                      }}
                    ></TextField>
                  </td>
                )}
              </tr>
            </TableBody>
          </table>
        </div>

        {/* div for buttons */}
        {!viewModel ? (
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
              marginTop: "20px",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={onFormSubmit}
            >
              Save
            </Button>
          </div>
        ) : (
          <div />
        )}
        {/* div for buttons closed */}
      </form>
      {/* pop up div closed  */}
    </div>
  );
}

export default EditModal;
