import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  TableBody,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { APIUTILS } from "../../../../../../apihandler/utils";
import { getTaxes } from "../../../../../../component/cellRenderers/renderers";
import iconLeft from "../../../../../../resources/assets/images/iconLeft.svg";
import iconRight from "../../../../../../resources/assets/images/iconRight.svg";
import {
  LIGHT_GREY_BACKGROUND,
  SPANISH_GREEN,
  WHITE,
} from "../../../../../../resources/constant/colors/theme";
import {
  APPLY,
  SOURCE,
  TARGET,
} from "../../../../../../resources/constant/strings/string";
import "./modal.css";

function Modal({ setOpenModal, model, onApply, formTitle, viewModel }) {
  const taxes = getTaxes();

  const checkboxItemsSourceStructure = [];
  taxes.forEach((e, i) => {
    checkboxItemsSourceStructure.push({
      id: i + 1,
      taxId: e.value,
      name: e.name,
      status: false,
    });
  });
  const checkboxItemsTargetStructure = [];
  model?.tax?.taxIds?.forEach((e, i) => {
    var reqTax = taxes.filter((x) => x.value === e);
    checkboxItemsTargetStructure.push({
      id: i + 1,
      taxId: e,
      name: reqTax.length > 0 ? reqTax[0].name : "",
      status: false,
    });
  });

  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    const taxIds = [];
    targetCheckboxitems.forEach((e, i) => {
      const taxReqd = taxes.filter((x) => x.name === e.name);
      if (taxReqd.length > 0) {
        taxIds.push(taxReqd[0].value);
      }
    });
    APIUTILS.CalculateTax({
      taxIds: taxIds,
      basicAmount: dto?.basicCost,
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if(!resp.status){
          resp.response = {taxValue: 0, totalValue: dto?.basicValue};
        }
        onApply(resp.response);
        setOpenModal(false);
      });
  }

  const updateObjectInSourceArray = (rowId, statusPassed, itemNamePassed) => {
    setSourceCheckboxItems(
      sourceCheckboxitems.map((item) => {
        if (item.id === rowId) {
          return {
            id: rowId,
            status: statusPassed,
            name: itemNamePassed,
          };
        }

        return item;
      })
    );
  };
  const updateStatusInSourceArray = (rowId, statusPassed, itemNamePassed) => {
    setSourceCheckboxItems(
      sourceCheckboxitems.map((item) => {
        return {
          id: item.id,
          status: sourceSelectAllCheckbox,
          name: item.name,
        };

        //return item;
      })
    );
  };
  const updateObjectInTargetArray = (rowId, statusPassed, itemNamePassed) => {
    setTargetCheckboxItems(
      targetCheckboxitems.map((item) => {
        if (item.id === rowId) {
          return {
            id: rowId,
            status: statusPassed,
            name: itemNamePassed,
          };
        }

        return item;
      })
    );
  };
  const updateStatusInTargetArray = (rowId, statusPassed, itemNamePassed) => {
    setTargetCheckboxItems(
      targetCheckboxitems.map((item) => {
        return {
          id: item.id,
          status: targetSelectAllCheckbox,
          name: item.name,
        };

        //return item;
      })
    );
  };

  function movetoTarget() {
    const arr = sourceCheckboxitems.filter((x) => x.status === true);
    const newArr = [...targetCheckboxitems];

    arr.forEach((item) => {
      if (item.status === true) {
        newArr.push({
          id: newArr.length + 1,
          name: item.name,
          status: false,
        });
      }
    });

    setTargetCheckboxItems(newArr);

    let newArr1 = [...sourceCheckboxitems];
    arr.forEach((item) => {
      if (item.status === true) {
        newArr1 = newArr1.filter((rowItems) => {
          return rowItems.name !== item.name;
        });
      }
    });

    setSourceCheckboxItems(newArr1);
  }

  function movetoSource() {
    const arr = targetCheckboxitems.filter((x) => x.status === true);
    const newArr = [...sourceCheckboxitems];

    arr.forEach((item) => {
      if (item.status === true) {
        newArr.push({
          id: newArr.length + 1,
          name: item.name,
          status: false,
        });
      }
    });

    newArr.forEach((x, i) => {
      x.id = i;
    });

    setSourceCheckboxItems(newArr);

    let newArr1 = [...targetCheckboxitems];
    arr.forEach((item) => {
      if (item.status === true) {
        newArr1 = newArr1.filter((rowItems) => {
          return rowItems.name !== item.name;
        });
      }
    });

    setTargetCheckboxItems(newArr1);
  }

  const [dto, setDTO] = useState(model);
  const [sourceSelectAllCheckbox, setSourceSelectAllCheckbox] = useState(true);
  const [sourceCheckboxitems, setSourceCheckboxItems] = useState(
    checkboxItemsSourceStructure
  );
  const [targetSelectAllCheckbox, setTargetSelectAllCheckbox] = useState(true);

  const [targetCheckboxitems, setTargetCheckboxItems] = useState(
    checkboxItemsTargetStructure
  );

  return (
    <div className="modalBackgroundForGrnTax">
      {/* div for top right close button */}
      <form>
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton
            style={{ width: "auto", align: "center", marginRight: 5 }}
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* top right close button div closed */}

        <div
          style={{
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <table style={{ width: "100%", margin: 10 }}>
            <TableBody>
              <tr>
                <td>
                  <Typography
                    fontSize={20}
                    fontWeight={600}
                    fontspacing={"normal"}
                    textTransform="capitalize"
                    align="left"
                    style={{ marginLeft: 60 }}
                  >
                    {formTitle}
                  </Typography>
                </td>
              </tr>
            </TableBody>
          </table>
        </div>

        {/* div for checkbox */}
        <div
          style={{
            display: "flex",
            marginLeft: 60,
            marginRight: 60,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              backgroundColor: WHITE,
              borderRadius: "5px",

              //padding: 20,
              marginBottom: 10,

              margin: 20,
              boxShadow: "0 2px 3px 0  ",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 10,
                position: "relative",
              }}
            >
              <FormControlLabel
                style={{ userSelect: "none" }}
                control={<Checkbox color="success" size="small" />}
                label={sourceCheckboxitems.length + " Items"}
                onChange={() => {
                  setSourceSelectAllCheckbox(!sourceSelectAllCheckbox);
                  updateStatusInSourceArray();
                }}
              />
              <Typography
                style={{
                  position: "absolute",
                  right: 0,
                  margin: 5,
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: 1.87,
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#3a3a3aad",
                }}
              >
                {SOURCE}
              </Typography>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 10,
                height: "200px",
                overflowY: "auto",
              }}
            >
              {sourceCheckboxitems?.map((item) => {
                return (
                  <FormControlLabel
                    style={{ userSelect: "none" }}
                    control={<Checkbox size="small" color="success" />}
                    label={item.name}
                    id={"checkbox" + item.id}
                    //name="temp"
                    checked={item.status}
                    onChange={(event) => {
                      updateObjectInSourceArray(
                        item.id,
                        event.target.checked,
                        item.name
                      );
                    }}
                    //value={true}
                  />
                );
              })}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 0.5,
              justifyContent: "center",
              alignItems: "center",
              padding: 20,
            }}
          >
            <Button
              style={{
                backgroundColor: LIGHT_GREY_BACKGROUND,
                boxShadow: "0 1px 2px 0",
                marginBottom: 15,
                height: "34px",
                width: "34px",
                color: SPANISH_GREEN,
                paddingLeft: 20,
              }}
              startIcon={<img src={iconRight} alt="" />}
              onClick={() => {
                movetoTarget();
              }}
            ></Button>

            <Button
              style={{
                backgroundColor: LIGHT_GREY_BACKGROUND,
                boxShadow: "0 1px 2px 0",
                marginBottom: 15,
                height: "34px",
                width: "34px",
                color: SPANISH_GREEN,
                paddingLeft: 20,
              }}
              onClick={() => {
                movetoSource();
              }}
              startIcon={<img src={iconLeft} alt="" />}
            ></Button>
          </div>
          {/* icon button div ends and target div starts */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              backgroundColor: WHITE,
              borderRadius: "5px",

              //padding: 20,
              marginBottom: 10,

              margin: 20,
              boxShadow: "0 2px 3px 0  ",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 10,
                position: "relative",
              }}
            >
              <FormControlLabel
                style={{ userSelect: "none" }}
                control={<Checkbox color="success" size="small" />}
                label={targetCheckboxitems.length + " Items"}
                onChange={() => {
                  setTargetSelectAllCheckbox(!targetSelectAllCheckbox);
                  updateStatusInTargetArray();
                }}
              />
              <Typography
                style={{
                  position: "absolute",
                  right: 0,
                  margin: 5,
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: 1.87,
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#3a3a3aad",
                }}
              >
                {TARGET}
              </Typography>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 10,
                height: "200px",
                overflowY: "auto",
              }}
            >
              {targetCheckboxitems?.map((item) => {
                return (
                  <FormControlLabel
                    style={{ userSelect: "none" }}
                    control={<Checkbox size="small" color="success" />}
                    label={item.name}
                    id={"checkbox" + item.id}
                    name="temp"
                    checked={item.status}
                    onChange={(event) => {
                      updateObjectInTargetArray(
                        item.id,
                        event.target.checked,
                        item.name
                      );
                    }}
                    //value={true}
                  />
                );
              })}
            </div>
          </div>
          {/* target div ends */}
        </div>
        {/* <div style={{ display: "flex", padding: 10, flexDirection: "row" }}>
          <div style={{ flex: 1 }}>hello</div>
          <div style={{ flex: 1 }}>gello</div>
        </div> */}
        {/* div for checkbox ends */}

        {/* div for buttons */}
        {!viewModel ? (
          <div
            style={{
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: SPANISH_GREEN,
              }}
              style={{
                textTransform: "capitalize",
                fontSize: 14,
                color: SPANISH_GREEN,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: SPANISH_GREEN,
                textTransform: "capitalize",
                fontSize: 14,
                color: WHITE,
                minWidth: "180px",
                minHeight: "45px",
                margin: 10,
              }}
              onClick={onFormSubmit}
            >
              {APPLY}
            </Button>
          </div>
        ) : (
          <div />
        )}
        {/* div for buttons closed */}

        {/* pop up div closed  */}
      </form>
    </div>
  );
}

export default Modal;
