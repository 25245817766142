import {
  Autocomplete,
  Button,
  IconButton,
  TableBody,
  TextField,
  Typography,
} from "@mui/material";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import React, { useEffect, useRef, useState } from "react";
import SideNav from "../../../../../../component/layouts/sideNav";
import minus_icon from "../../../../../../resources/assets/images/minus_icon.svg";
import plus_icon from "../../../../../../resources/assets/images/plus_icon.svg";
import {
  BALANCED_AMOUNT,
  CALCULATE,
  CANCEL,
  DOWNLOAD,
  EXPORT,
  GRAND_TOTAL,
  GRAND_TOTAL_AMOUNT_ACTUAL_PAID,
  LABOUR_MANAGEMENT,
  LESS_ADVANCE_DEDUCTIBLE,
  LESS_AMOUNT_PAID_TILL_DATE,
  LESS_RETENTION_AMOUNT_5,
  LESS_TESTING_AND_COMMISION_AMOUNT_5,
  PREVIOUS_BILL_AMOUNT_PAID,
  RELEASE_LESS_RETENTION,
  RELEASE_TESTING_AND_COMMISION,
  RETENTION,
  RETENTION_AND_TESTING_AMOUNT_SUMMARY,
  SAVE,
  SUB_CONTRACTOR_BILLING,
  SUB_CONTRACTOR_BILLING_DETAIL,
  TESTING_AND_COMMISION,
  TOTAL,
  TOTAL_AMOUNT_PAID,
  TOTAL_BILL_AMOUNT,
  TOTAL_DEDUCTED,
  TOTAL_PRESENT_BILL_AMOUNT,
  TOTAL_RELEASED,
} from "../../../../../../resources/constant/strings/string";

import {
  getItemList,
  getSites,
  getSupplierCategories,
  getSuppliers,
  getUoms,
  getWorkOrders,
  globalFilter,
  itemCellRenderer,
  uomCellRenderer,
} from "../../../../../../component/cellRenderers/renderers";
import {
  ANTI_FLASH_WHITE,
  CHARLESTON_GREEN,
  LIGHT_GREY_BACKGROUND,
  PRUSSIAN_BLUE,
  SPANISH_GREEN,
  WHITE,
} from "../../../../../../resources/constant/colors/theme";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { APILABOURMGMT } from "../../../../../../apihandler/labourmgmt";
import { APILOGIN } from "../../../../../../apihandler/login";
import { APIUTILS } from "../../../../../../apihandler/utils";
import {
  setAlertConfirmDeletion,
  setAlertRadioConfirmation,
  setAlertTitleText,
} from "../../../../../../component/modal/alertModal";
import {
  PATH_LOGIN,
  PATH_SUB_CONTRACTOR_BILLING,
} from "../../../../../../resources/constant/url/url";

export default function SubContractorBillingDetail() {
  let { id } = useParams();

  const location = useLocation();
  const isEdit = location.state?.isEdit;
  const navigate = useNavigate();

  const [gridData, setGridData] = useState([]);

  const inititialValue = {
    id: 0,
    advanceRequest: [],
    entryDate: dayjs().add(-1, "day"),
    referenceNumber: "",
    contractorId: 0,
    siteId: 0,
    workOrderId: 0,
    foodAdvance: 0,
    amountTotal: 0,
    amountRetention: 0,
    amountCommission: 0,
    amountTotalBill: 0,
    retentionPercentage: 0,
    commissionPercentage: 0,
    releaseRetention: 0,
    releaseCommission: 0,
    totalPresentBillAmount: 0,
    previousBillAmountPaid: 0,
    lessAmountPaidTillDate: 0,
    lessAdvanceDeductible: 0,
    totalAmountPaid: 0,
    grandTotal: 0,
    amountActualPaid: 0,
    totalDeducted: 0,
    retentionReleaseTotal: 0,
    commissionReleaseTotal: 0,
    releaseTotal: 0,
    retentionBalanceAmount: 0,
    commissionBalanceAmount: 0,
    balanceTotal: 0,
    additionalRelease: [],
    items: [],
    utilizationId: [],
    foodAdvanceId: [],
    totalPrevAmount: 0,
    totalPresentAmount: 0,
    totalAmount: 0,
    lessRetentionPrevAmount: 0,
    lessRetentionPresentAmount: 0,
    lessRetentionAmount: 0,
    lessCommissionPrevAmount: 0,
    lessCommissionPresentAmount: 0,
    lessCommissionAmount: 0,
  };

  const [dataReady, setDataReady] = useState(false);
  const [dataFetch, setDataFetch] = useState(false);
  const [disableDate, setDisableDate] = useState(false);
  const [dto, setDTO] = useState(inititialValue);

  const gridRef = useRef();

  useEffect(() => {
    if (!APILOGIN.GetIsLoggedIn()) navigate(PATH_LOGIN);
  }, []);

  const [selectedRowId, setSelectedRowId] = useState(0);

  const deleteModel = () => {
    if (selectedRowId <= 0) {
      return;
    }
    setAlertConfirmDeletion(
      "Confirmation needed",
      "Do you confirm on proceeding to delete this record?"
    ).then((consent) => {
      if (!consent.isConfirmed) return;

      console.log("delete the selected id: ", selectedRowId);
      APILABOURMGMT.DeleteSubContractorBilling(selectedRowId)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.statusCode === 204) {
            const selectedData = gridRef.current.api.getSelectedRows();
            gridRef.current.api.applyTransaction({ remove: selectedData });
            setAlertTitleText("Success", "The record was deleted successfully");
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong...${data.message}`
            );
          }
        });
    });
  };

  const supplierCategories = getSupplierCategories().filter((x) => {
    return x.dependentvalue === "L";
  });
  const suppliers = getSuppliers().filter((x) => {
    return (
      supplierCategories.filter((y) => y.value === x.dependentvalue).length > 0
    );
  });
  const supplierProps = {
    options: suppliers,
    getOptionLabel: (option) => option?.name || "",
  };
  const items = getItemList();

  const sites = getSites();
  const siteProps = {
    options: sites,
    getOptionLabel: (option) => option?.name || "",
  };

  const workOrders = getWorkOrders();
  const workOrderProps = {
    options: workOrders,
    getOptionLabel: (option) => option?.name || "",
  };

  const updateModel = (model) => {
    gridRef.current.api.applyTransaction({ update: [model] });
  };

  const addModel = (model) => {
    gridRef.current.api.applyTransaction({ add: [model], addIndex: 0 });
  };

  const onRowSelectionChanged = (event) => {
    if (event.api.getSelectedRows().length > 0)
      setSelectedRowId(event.api.getSelectedRows()[0].id);
    else setSelectedRowId(0);
  };

  const handleEditData = (oldData) => {
    console.log(oldData);
  };

  const columnDefs = [
    {
      headerName: "Item/Labour",
      field: "itemId",
      autoHeight: true,
      wrapText: true,
      valueGetter: (params) => {
        return itemCellRenderer({
          value: params.data.itemId,
        });
      },
    },
    {
      headerName: "Previous Qty",
      field: "previousQty",
    },
    {
      headerName: "Present Qty",
      field: "presentQty",
    },
    {
      headerName: "Grand Total Qty",
      field: "grandTotalQty",
    },
    {
      headerName: "Unit",
      field: "unit",
      valueGetter: (params) => {
        return uomCellRenderer({
          value: params.data.unit,
        });
      },
    },
    {
      headerName: "Rate",
      field: "rate",
    },
    {
      headerName: "Previous Amount",
      field: "previousAmount",
    },
    {
      headerName: "Present Amount",
      field: "presentAmount",
    },
    {
      headerName: "Amount",
      field: "amount",
    },
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    editable: false,
    flex: 1,
    filter: false,
    floatingFilter: false,
  };

  const onGridReady = (params) => {
    try {
      const rowData = [];
      gridRef.current.api.forEachNode(function (node) {
        rowData.push(node.data);
      });
      gridRef.current.api.applyTransaction({
        remove: rowData,
      });
    } catch (e) {}
    try {
      gridRef.current.api.applyTransaction({ add: gridData });
    } catch (e) {}
  };

  useEffect(() => {
    if (id > 0) {
      APILABOURMGMT.SubContractorBilling(id)
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.statusCode === 200) {
            let arrayData = [];
            for (const key in resp.response?.items) {
              resp.response.items[key]?.forEach((e) => {
                e.itemId = Number(key);
                e.previousQty = Number.parseFloat(
                  APIUTILS.Financial(e.previousQty)
                );
                e.presentQty = Number.parseFloat(
                  APIUTILS.Financial(e.presentQty)
                );
                e.grandTotalQty = Number.parseFloat(
                  APIUTILS.Financial(e.grandTotalQty)
                );
                e.id = Math.random() * 100000;
                arrayData.push(e);
              });
            }

            let totalPrevAmount = 0,
              totalPresentAmount = 0,
              totalAmount = 0,
              lessRetentionPrevAmount = 0,
              lessRetentionPresentAmount = 0,
              lessRetentionAmount = 0,
              lessCommissionPrevAmount = 0,
              lessCommissionPresentAmount = 0,
              lessCommissionAmount = 0;

            arrayData.forEach((e) => {
              e.previousAmount = Number.parseFloat(
                APIUTILS.Financial(e.previousAmount)
              );
              e.presentAmount = Number.parseFloat(
                APIUTILS.Financial(e.presentAmount)
              );
              e.amount = Number.parseFloat(APIUTILS.Financial(e.amount));
              totalPrevAmount += Number.parseFloat(
                APIUTILS.Financial(e.previousAmount)
              );
              totalPresentAmount += Number.parseFloat(
                APIUTILS.Financial(e.presentAmount)
              );
              totalAmount += Number.parseFloat(APIUTILS.Financial(e.amount));
              lessRetentionPrevAmount +=
                (e.previousAmount * resp.response.retentionPercentage) / 100;
              lessRetentionPresentAmount +=
                (e.presentAmount * resp.response.retentionPercentage) / 100;
              lessRetentionAmount +=
                (e.amount * resp.response.retentionPercentage) / 100;
              lessCommissionPrevAmount +=
                (e.previousAmount * resp.response.commissionPercentage) / 100;
              lessCommissionPresentAmount +=
                (e.presentAmount * resp.response.commissionPercentage) / 100;
              lessCommissionAmount +=
                (e.amount * resp.response.commissionPercentage) / 100;
            });

            setDTO({
              ...dto,
              contractorId: resp.response.contractorId,
              siteId: resp.response.siteId,
              workOrderId: resp.response.workOrderId,
              id: resp.response.id,
              entryDate: resp.response.entryDate.split("-").reverse().join("-"),
              advanceRequest: resp.response.advanceRequest,
              referenceNumber: resp.response.referenceNumber,
              foodAdvance: Number.parseFloat(
                APIUTILS.Financial(resp.response.foodAdvance)
              ),
              amountTotal: Number.parseFloat(
                APIUTILS.Financial(resp.response.amountTotal)
              ),
              amountRetention: Number.parseFloat(
                APIUTILS.Financial(resp.response.amountRetention)
              ),
              amountCommission: Number.parseFloat(
                APIUTILS.Financial(resp.response.amountCommission)
              ),
              amountTotalBill: Number.parseFloat(
                APIUTILS.Financial(resp.response.amountTotalBill)
              ),
              retentionPercentage: resp.response.retentionPercentage,
              commissionPercentage: resp.response.commissionPercentage,
              releaseRetention: Number.parseFloat(
                APIUTILS.Financial(resp.response.releaseRetention)
              ),
              releaseCommission: Number.parseFloat(
                APIUTILS.Financial(resp.response.releaseCommission)
              ),
              totalPresentBillAmount: Number.parseFloat(
                APIUTILS.Financial(resp.response.totalPresentBillAmount)
              ),
              previousBillAmountPaid: Number.parseFloat(
                APIUTILS.Financial(resp.response.previousBillAmountPaid)
              ),
              lessAmountPaidTillDate: Number.parseFloat(
                APIUTILS.Financial(resp.response.lessAmountPaidTillDate)
              ),
              lessAdvanceDeductible: Number.parseFloat(
                APIUTILS.Financial(resp.response.lessAdvanceDeductible)
              ),
              totalAmountPaid: Number.parseFloat(
                APIUTILS.Financial(resp.response.totalAmountPaid)
              ),
              grandTotal: Number.parseFloat(
                APIUTILS.Financial(resp.response.grandTotal)
              ),
              amountActualPaid: Number.parseFloat(
                APIUTILS.Financial(resp.response.amountActualPaid)
              ),
              totalDeducted: Number.parseFloat(
                APIUTILS.Financial(resp.response.totalDeducted)
              ),
              retentionReleaseTotal: Number.parseFloat(
                APIUTILS.Financial(resp.response.retentionReleaseTotal)
              ),
              commissionReleaseTotal: Number.parseFloat(
                APIUTILS.Financial(resp.response.commissionReleaseTotal)
              ),
              releaseTotal: Number.parseFloat(
                APIUTILS.Financial(resp.response.releaseTotal)
              ),
              retentionBalanceAmount: Number.parseFloat(
                APIUTILS.Financial(resp.response.retentionBalanceAmount)
              ),
              commissionBalanceAmount: Number.parseFloat(
                APIUTILS.Financial(resp.response.commissionBalanceAmount)
              ),
              balanceTotal: Number.parseFloat(
                APIUTILS.Financial(resp.response.balanceTotal)
              ),
              additionalRelease: resp.response.additionalRelease,
              items: resp.response.items,
              utilizationId: resp.response.utilizationId,
              foodAdvanceId: resp.response.foodAdvanceId,
              totalPrevAmount: Number.parseFloat(
                APIUTILS.Financial(totalPrevAmount)
              ),
              totalPresentAmount: Number.parseFloat(
                APIUTILS.Financial(totalPresentAmount)
              ),
              totalAmount: Number.parseFloat(APIUTILS.Financial(totalAmount)),
              lessRetentionPrevAmount: Number.parseFloat(
                APIUTILS.Financial(lessRetentionPrevAmount)
              ),
              lessRetentionPresentAmount: Number.parseFloat(
                APIUTILS.Financial(lessRetentionPresentAmount)
              ),
              lessRetentionAmount: Number.parseFloat(
                APIUTILS.Financial(lessRetentionAmount)
              ),
              lessCommissionPrevAmount: Number.parseFloat(
                APIUTILS.Financial(lessCommissionPrevAmount)
              ),
              lessCommissionPresentAmount: Number.parseFloat(
                APIUTILS.Financial(lessCommissionPresentAmount)
              ),
              lessCommissionAmount: Number.parseFloat(
                APIUTILS.Financial(lessCommissionAmount)
              ),
            });

            resp.response.additionalRelease?.forEach((v, i) => {
              v.rowId = i;
            });

            setRowItems((r) =>
              [].concat(resp.response.additionalRelease?.length <= 0 ? [
                {
                  id: 0,
                  rowId: 0,
                  reason: "",
                  amount: 0,
                },
              ]:resp.response.additionalRelease)
            );
            setGridData((r) => [].concat(arrayData));
            setDataReady(true);
          }
        });
    } else {
      setDataReady(true);
    }
  }, [id]);

  //below code is for sub Contractor advance request detail component
  const onFormSubmit = (e) => {
    e.preventDefault();
    validation();
    //
  };
  function validation() {
    if (
      workOrderProps.options.filter(
        (x) =>
          x.otherdependentvalue === dto.siteId && x.value === dto.workOrderId
      ).length <= 0
    ) {
      setAlertTitleText("Oops...", `Work order is not valid for this site`);
      return;
    }

    //api call to save goes here
    dto.additionalRelease = rowItems;
    if (id <= 0) {
      APILABOURMGMT.AddSubcontractorBilling(dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setAlertTitleText(
              "Success",
              "The record was inserted successfully"
            );
            navigate(PATH_SUB_CONTRACTOR_BILLING + "/" + location.state.date);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    } else {
      APILABOURMGMT.UpdateSubcontractorBilling(dto.id, dto)
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 200) {
            setAlertTitleText("Success", "The record was updated successfully");
            navigate(PATH_SUB_CONTRACTOR_BILLING + "/" + location.state.date);
          } else {
            setAlertTitleText(
              "Oops...",
              `Something went wrong... ${data.message}`
            );
          }
        });
    }
  }

  const fetchBillings = () => {
    APILABOURMGMT.GenerateSubcontractorBilling(dto.workOrderId, dto)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.statusCode !== 200) {
          setAlertTitleText("Oops..", resp.message);
          return;
        }
        let arrayData = [];
        for (const key in resp.response?.items) {
          resp.response.items[key].forEach((e, i) => {
            e.itemId = Number(key);

            const reqdItem = items.filter((x) => x.id === e.itemId);
            if (reqdItem.length > 0) {
              e.unit = reqdItem[0].stockUOMId;
            }

            e.id = i;
            arrayData.push(e);
          });
        }

        let totalPrevAmount = 0,
          totalPresentAmount = 0,
          totalAmount = 0,
          lessRetentionPrevAmount = 0,
          lessRetentionPresentAmount = 0,
          lessRetentionAmount = 0,
          lessCommissionPrevAmount = 0,
          lessCommissionPresentAmount = 0,
          lessCommissionAmount = 0;

        arrayData.forEach((e, i) => {
          e.id = i;
          e.previousQty = Number.parseFloat(APIUTILS.Financial(e.previousQty));
          e.presentQty = Number.parseFloat(APIUTILS.Financial(e.presentQty));
          e.grandTotalQty = Number.parseFloat(
            APIUTILS.Financial(e.grandTotalQty)
          );
          e.previousAmount = Number.parseFloat(
            APIUTILS.Financial(e.previousAmount)
          );
          e.presentAmount = Number.parseFloat(
            APIUTILS.Financial(e.presentAmount)
          );
          e.amount = Number.parseFloat(APIUTILS.Financial(e.amount));
          totalPrevAmount += Number.parseFloat(
            APIUTILS.Financial(e.previousAmount)
          );
          totalPresentAmount += Number.parseFloat(
            APIUTILS.Financial(e.presentAmount)
          );
          totalAmount += Number.parseFloat(APIUTILS.Financial(e.amount));
          lessRetentionPrevAmount +=
            (e.previousAmount * resp.response.retentionPercentage) / 100;
          lessRetentionPresentAmount +=
            (e.presentAmount * resp.response.retentionPercentage) / 100;
          lessRetentionAmount +=
            (e.amount * resp.response.retentionPercentage) / 100;
          lessCommissionPrevAmount +=
            (e.previousAmount * resp.response.commissionPercentage) / 100;
          lessCommissionPresentAmount +=
            (e.presentAmount * resp.response.commissionPercentage) / 100;
          lessCommissionAmount +=
            (e.amount * resp.response.commissionPercentage) / 100;
        });

        setDTO({
          ...dto,
          id: 0,
          advanceRequest: resp.response.advanceRequest,
          referenceNumber: resp.response.referenceNumber,
          foodAdvance: Number.parseFloat(
            APIUTILS.Financial(resp.response.foodAdvance)
          ),
          amountTotal: Number.parseFloat(
            APIUTILS.Financial(resp.response.amountTotal)
          ),
          amountRetention: Number.parseFloat(
            APIUTILS.Financial(resp.response.amountRetention)
          ),
          amountCommission: Number.parseFloat(
            APIUTILS.Financial(resp.response.amountCommission)
          ),
          amountTotalBill: Number.parseFloat(
            APIUTILS.Financial(resp.response.amountTotalBill)
          ),
          retentionPercentage: resp.response.retentionPercentage,
          commissionPercentage: resp.response.commissionPercentage,
          releaseRetention: Number.parseFloat(
            APIUTILS.Financial(resp.response.releaseRetention)
          ),
          releaseCommission: Number.parseFloat(
            APIUTILS.Financial(resp.response.releaseCommission)
          ),
          totalPresentBillAmount: Number.parseFloat(
            APIUTILS.Financial(resp.response.totalPresentBillAmount)
          ),
          previousBillAmountPaid: Number.parseFloat(
            APIUTILS.Financial(resp.response.previousBillAmountPaid)
          ),
          lessAmountPaidTillDate: Number.parseFloat(
            APIUTILS.Financial(resp.response.lessAmountPaidTillDate)
          ),
          lessAdvanceDeductible: Number.parseFloat(
            APIUTILS.Financial(resp.response.lessAdvanceDeductible)
          ),
          totalAmountPaid: Number.parseFloat(
            APIUTILS.Financial(resp.response.totalAmountPaid)
          ),
          grandTotal: Number.parseFloat(
            APIUTILS.Financial(resp.response.grandTotal)
          ),
          amountActualPaid: Number.parseFloat(
            APIUTILS.Financial(resp.response.amountActualPaid)
          ),
          totalDeducted: Number.parseFloat(
            APIUTILS.Financial(resp.response.totalDeducted)
          ),
          retentionReleaseTotal: Number.parseFloat(
            APIUTILS.Financial(resp.response.retentionReleaseTotal)
          ),
          commissionReleaseTotal: Number.parseFloat(
            APIUTILS.Financial(resp.response.commissionReleaseTotal)
          ),
          releaseTotal: Number.parseFloat(
            APIUTILS.Financial(resp.response.releaseTotal)
          ),
          retentionBalanceAmount: Number.parseFloat(
            APIUTILS.Financial(resp.response.retentionBalanceAmount)
          ),
          commissionBalanceAmount: Number.parseFloat(
            APIUTILS.Financial(resp.response.commissionBalanceAmount)
          ),
          balanceTotal: Number.parseFloat(
            APIUTILS.Financial(resp.response.balanceTotal)
          ),
          additionalRelease: resp.response.additionalRelease || [],
          items: resp.response.items,
          utilizationId: resp.response.utilizationId,
          foodAdvanceId: resp.response.foodAdvanceId,
          totalPrevAmount: Number.parseFloat(
            APIUTILS.Financial(totalPrevAmount)
          ),
          totalPresentAmount: Number.parseFloat(
            APIUTILS.Financial(totalPresentAmount)
          ),
          totalAmount: Number.parseFloat(APIUTILS.Financial(totalAmount)),
          lessRetentionPrevAmount: Number.parseFloat(
            APIUTILS.Financial(lessRetentionPrevAmount)
          ),
          lessRetentionPresentAmount: Number.parseFloat(
            APIUTILS.Financial(lessRetentionPresentAmount)
          ),
          lessRetentionAmount: Number.parseFloat(
            APIUTILS.Financial(lessRetentionAmount)
          ),
          lessCommissionPrevAmount: Number.parseFloat(
            APIUTILS.Financial(lessCommissionPrevAmount)
          ),
          lessCommissionPresentAmount: Number.parseFloat(
            APIUTILS.Financial(lessCommissionPresentAmount)
          ),
          lessCommissionAmount: Number.parseFloat(
            APIUTILS.Financial(lessCommissionAmount)
          ),
        });
        setGridData([...gridData.filter((x) => false).concat(arrayData)]);
        try {
          const rowData = [];
          gridRef.current.api.forEachNode(function (node) {
            rowData.push(node.data);
          });
          gridRef.current.api.applyTransaction({
            remove: rowData,
          });
        } catch (e) {}
        try {
          gridRef.current.api.applyTransaction({ add: arrayData });
        } catch (e) {}
        setDataReady(true);
      });
  };

  const doTotal = (e) => {
    let totalAmount = dto.amountTotalBill;
    rowItems.forEach((e) => {
      totalAmount += Number.parseFloat(APIUTILS.Financial(e.amount));
    });
    
    const { name, value } = e.target;
    if (name === "releaseRetention"){
      totalAmount += Number.parseFloat(dto.releaseCommission);
      totalAmount += Number.parseFloat(APIUTILS.Financial(value));
    }
    else if (name === "releaseCommission"){
      totalAmount += Number.parseFloat(dto.releaseRetention);
      totalAmount += Number.parseFloat(APIUTILS.Financial(value));
    }

    setDTO({
      ...dto,
      [name]: Number(value),
      totalPresentBillAmount: Number.parseFloat(
        APIUTILS.Financial(totalAmount)
      ),
      grandTotal: Number.parseFloat(
        APIUTILS.Financial(totalAmount - dto.totalAmountPaid)
      ),
      amountActualPaid: Number.parseFloat(
        APIUTILS.Financial(totalAmount - dto.totalAmountPaid)
      ),
    });
  };

  const [rowItems, setRowItems] = useState([
    {
      id: 0,
      rowId: 0,
      reason: "",
      amount: 0,
    },
  ]);
  const addObjectToArray = () => {
    let newArr = [...rowItems];
    newArr.push({
      id: 0,
      rowId: newArr.length,
      reason: "",
      amount: 0,
    });
    setRowItems(newArr);
  };
  const removeObjectFromArray = (rowId) => {
    setRowItems(
      rowItems.filter((x) => {
        return x.rowId !== rowId;
      })
    );
    rowItems.forEach((e, i) => {
      e.rowId = i;
    });
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (value) {
      if (isNaN(value)) setDTO({ ...dto, [name]: value });
      else setDTO({ ...dto, [name]: Number(value) });
    } else {
      setDTO({ ...dto, [name]: value });
    }
  };
  return (
    <SideNav
      currentComponent={LABOUR_MANAGEMENT}
      subComponent={SUB_CONTRACTOR_BILLING}
      subComponentPath={PATH_SUB_CONTRACTOR_BILLING}
      detailComponent={SUB_CONTRACTOR_BILLING_DETAIL}
    >
      {/* below code is of sub contractore advance request detail component */}
      {dataReady ? (
        <div>
          <div>
            <form>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  marginBottom: 10,
                  //marginRight: 10,
                }}
              >
                {id <= 0 && dataFetch ? (
                  <Button
                    style={{
                      backgroundColor: SPANISH_GREEN,
                      textTransform: "capitalize",
                      fontSize: 14,
                      color: WHITE,
                      minWidth: "180px",
                      minHeight: "45px",
                    }}
                    onClick={() => {
                      fetchBillings();
                    }}
                  >
                    {CALCULATE}
                  </Button>
                ) : (
                  ""
                )}
                {id > 0 ? (
                  <Button
                    style={{
                      backgroundColor: SPANISH_GREEN,
                      textTransform: "capitalize",
                      fontSize: 14,
                      color: WHITE,
                      minWidth: "180px",
                      minHeight: "45px",
                      marginRight: 10,
                    }}
                    onClick={() => {
                      setAlertRadioConfirmation(
                        { excel: "Excel", pdf: "PDF" },
                        "Please select an option..."
                      ).then((result) => {
                        if (!result.isConfirmed) return;

                        console.log(result);
                        let filename = "";
                        dto.exportExcel = result.value === "excel";
                        APILABOURMGMT.SubContractorBillingExport(
                          id,
                          result.value === "excel"
                        )
                          .then((res) => {
                            const contentType = res.headers.get("content-type");
                            if (contentType.startsWith("application/json")) {
                              return Promise.reject(res.json());
                            } else {
                              const header = res.headers.get(
                                "Content-Disposition"
                              );
                              if (header !== null) {
                                const parts = header.split(";");
                                filename = parts[1]
                                  .split("=")[1]
                                  .replaceAll('"', "");
                              } else {
                                filename = `subcontractorbilling_${id}.html`;
                              }

                              return res.blob();
                            }
                          })
                          .then((blob) => {
                            if (blob !== null) {
                              if (dto.exportExcel) {
                                let url = window.URL.createObjectURL(blob);
                                let a = document.createElement("a");
                                a.href = url;
                                a.download = filename.replace(".html", ".xlsx");
                                document.body.appendChild(a);
                                a.click();
                                a.remove();
                              } else {
                                blob.text().then((x) => {
                                  let html = x;
                                  html = html.replace(
                                    "<script>",
                                    '<script type="text/javascript">'
                                  );
                                  let blob = new Blob([html], { type: "text/html" });
                                  let url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
                                
                                  let win = window.open(url, '_blank', '');
                                  setTimeout(()=>{
                                      win.print();
                                  }, 2000);
                                });
                              }
                            }
                          })
                          .catch((m) => {
                            setAlertTitleText(
                              "Oops...",
                              `Something went wrong...${m.message}`
                            );
                          });
                      });
                    }}
                  >
                    {EXPORT}
                  </Button>
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  backgroundColor: LIGHT_GREY_BACKGROUND,
                  borderRadius: "5px",
                  minWidth: "90%",
                  minHeight: "50px",
                  padding: 20,
                  marginBottom: 10,
                  borderStyle: "solid",
                  border: "1x",
                  borderColor: "#eaeaea",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <table style={{ width: "100%" }}>
                  <TableBody>
                    <tr>
                      <td>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label="Date"
                            maxDate={dayjs()}
                            style={{ minWidth: "272px", minHeight: "48.5px" }}
                            inputFormat="DD-MM-YYYY"
                            disabled={id > 0 || disableDate}
                            onChange={(newValue) => {
                              setDTO({
                                ...dto,
                                entryDate: newValue.format("YYYY-MM-DD"),
                              });
                            }}
                            value={dto.entryDate}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                style={{
                                  minWidth: "200px",
                                  minHeight: "48.5px",
                                  width: "200px",
                                }}
                                InputLabelProps={{
                                  style: { color: "grey", fontSize: 14 },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  style: { fontSize: 14 },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </td>
                      <td>
                        <Autocomplete
                          filterOptions={globalFilter}
                          {...supplierProps}
                          id="auto-complete-1"
                          disabled={id > 0}
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          name="contractorName"
                          defaultValue={
                            supplierProps.options.find(
                              (v) => v.value === dto.contractorId
                            ) || {}
                          }
                          onChange={(e, v) => {
                            if (v) {
                              if (isNaN(v?.value))
                                setDTO({ ...dto, contractorId: v?.value });
                              else
                                setDTO({
                                  ...dto,
                                  contractorId: Number(v?.value),
                                });
                            } else {
                              setDTO({ ...dto, contractorId: v?.value });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Contractor Name"
                              required
                              variant="standard"
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 14 },
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <Autocomplete
                          filterOptions={globalFilter}
                          {...siteProps}
                          id="auto-complete-2"
                          autoComplete
                          autoHighlight
                          autoSelect
                          disabled={id > 0}
                          includeInputInList
                          name="site"
                          defaultValue={
                            siteProps.options.find(
                              (v) => v.value === dto.siteId
                            ) || {}
                          }
                          onChange={(e, v) => {
                            if (v) {
                              if (isNaN(v?.value))
                                setDTO({ ...dto, siteId: v?.value });
                              else setDTO({ ...dto, siteId: Number(v?.value) });
                            } else {
                              setDTO({ ...dto, siteId: v?.value });
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Site"
                              required
                              variant="standard"
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 14 },
                              }}
                            />
                          )}
                        />
                      </td>
                      <td>
                        <Autocomplete
                          {...workOrderProps}
                          id="auto-complete-3"
                          autoComplete
                          autoHighlight
                          autoSelect
                          includeInputInList
                          disabled={id > 0}
                          name="workOrderNo"
                          defaultValue={
                            workOrderProps.options.find(
                              (v) => v.value === dto.workOrderId
                            ) || {}
                          }
                          filterOptions={(o, s) => {
                            let options = globalFilter(o, s);
                            let newOptions = [];
                            options.forEach((element) => {
                              if (
                                element.dependentvalue === dto.contractorId &&
                                element.otherdependentvalue === dto.siteId
                              ) {
                                if (
                                  element.date &&
                                  (dayjs(dto.entryDate).isAfter(element.date) ||
                                    dayjs(dto.entryDate).isSame(element.date))
                                )
                                  newOptions.push(element);
                              }
                            });
                            return newOptions;
                          }}
                          onChange={(e, v) => {
                            if (v) {
                              if (isNaN(v?.value))
                                setDTO({ ...dto, workOrderId: v?.value });
                              else
                                setDTO({
                                  ...dto,
                                  workOrderId: Number(v?.value),
                                });
                            } else {
                              setDTO({ ...dto, workOrderId: v?.value });
                            }
                            setDisableDate(true);
                            setDataFetch(true);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Work Order No"
                              required
                              variant="standard"
                              style={{
                                minWidth: "200px",
                                minHeight: "48.5px",
                                width: "200px",
                              }}
                              InputLabelProps={{
                                style: { color: "grey", fontSize: 14 },
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: { fontSize: 14 },
                              }}
                            />
                          )}
                        />
                      </td>
                    </tr>
                  </TableBody>
                </table>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  marginTop: 10,
                  marginBottom: 10,
                  marginRight: 10,
                }}
              >
                {dataReady && (id <= 0 || isEdit) ? (
                  <Button
                    style={{
                      backgroundColor: SPANISH_GREEN,
                      textTransform: "capitalize",
                      fontSize: 14,
                      color: WHITE,
                      minWidth: "180px",
                      minHeight: "45px",
                    }}
                    onClick={onFormSubmit}
                  >
                    {SAVE}
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: SPANISH_GREEN,
                  }}
                  style={{
                    //backgroundColor: SPANISH_GREEN,
                    textTransform: "capitalize",
                    fontSize: 14,
                    color: SPANISH_GREEN,
                    minWidth: "180px",
                    minHeight: "45px",
                    marginRight: 10,
                  }}
                  onClick={() => {
                    navigate(
                      PATH_SUB_CONTRACTOR_BILLING + "/" + location.state.date
                    );
                  }}
                >
                  {CANCEL}
                </Button>
              </div>
            </form>
          </div>
          {/* aggrid div start */}
          <div className="App">
            <div className="ag-theme-alpine" style={{ height: "570px" }}>
              <AgGridReact
                copyGroupHeadersToClipboard={true}
                enableRangeSelection={true}
                columnDefs={columnDefs}
                ref={gridRef}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                pagination={false}
                rowSelection="multiple"
                getRowId={(params) => params.data.id}
                onSelectionChanged={onRowSelectionChanged}
              ></AgGridReact>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
              }}
            >
              <TableBody>
                <tr style={{ height: "30px" }}></tr>
                <tr
                  style={{
                    backgroundColor: ANTI_FLASH_WHITE,
                    height: "40px",
                  }}
                >
                  <td style={{ width: "600px" }}>
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {TOTAL}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.totalPrevAmount}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.totalPresentAmount}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.totalAmount}
                    </Typography>
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: ANTI_FLASH_WHITE,
                    height: "40px",
                  }}
                >
                  <td style={{ width: "600px" }}>
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {LESS_RETENTION_AMOUNT_5}
                      {dto.retentionPercentage}%
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.lessRetentionPrevAmount}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.lessRetentionPresentAmount}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.lessRetentionAmount}
                    </Typography>
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: ANTI_FLASH_WHITE,
                    height: "40px",
                  }}
                >
                  <td style={{ width: "600px" }}>
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {LESS_TESTING_AND_COMMISION_AMOUNT_5}
                      {dto.commissionPercentage}%
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.lessCommissionPrevAmount}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.lessCommissionPresentAmount}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.lessCommissionAmount}
                    </Typography>
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: ANTI_FLASH_WHITE,
                    height: "40px",
                  }}
                >
                  <td style={{ width: "600px" }}>
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {TOTAL_BILL_AMOUNT}
                    </Typography>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.amountTotalBill}
                    </Typography>
                  </td>
                </tr>
                {rowItems?.map((item, index) => {
                  return (
                    <tr
                      style={{
                        backgroundColor: ANTI_FLASH_WHITE,
                        borderBottom: "0.001pt solid #f0eff4",
                      }}
                    >
                      <td style={{ width: "600px" }}>
                        <input
                          name="reason"
                          value={rowItems[index].reason}
                          onChange={(e) => {
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = e.target.value;
                            setRowItems(newArr);
                            doTotal(e);
                          }}
                          disabled={id > 0 && !isEdit}
                          style={{ width: "300px" }}
                        ></input>
                      </td>
                      <td>
                        {" "}
                        <input
                          onChange={(e) => {
                            const { name, value } = e.target;
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = (
                              e.target.value
                            );
                            setRowItems(newArr);
                          }}
                          onWheel={(event) => event.target.blur()}
                          name="amount"
                          value={rowItems[index].amount}
                          onBlur={(e) => {
                            let newArr = [...rowItems];
                            newArr[index][e.target.name] = Number(
                              e.target.value
                            );
                            setRowItems(newArr);
                            doTotal(e);
                          }}
                          disabled={id > 0 && !isEdit}
                          style={{ width: "100px" }}
                        ></input>
                      </td>
                      <td>
                        {(id <= 0 || isEdit) && index >= 0 && (
                          <IconButton
                            style={{ marginTop: 10 }}
                            aria-label="minus"
                            onClick={() => {
                              if (rowItems.length > 1) {
                                removeObjectFromArray(item.rowId);
                              }
                            }}
                          >
                            <img src={minus_icon} alt="" />
                          </IconButton>
                        )}
                      </td>
                      <td>
                      {(id <= 0 || isEdit) && (
                        <IconButton
                          style={{ marginTop: 10 }}
                          id={`button-plus-${index}`}
                          aria-label="plus"
                          onClick={() => {
                            addObjectToArray();
                          }}
                        >
                          <img src={plus_icon} alt="" />
                        </IconButton>
                      )}
                      </td>
                    </tr>
                  );
                })}
                <tr
                  style={{
                    backgroundColor: ANTI_FLASH_WHITE,
                    height: "40px",
                  }}
                >
                  <td style={{ width: "600px" }}>
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {RELEASE_LESS_RETENTION}
                    </Typography>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <input
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setDTO({...dto, [name]: value});
                      }}
                      onWheel={(event) => event.target.blur()}
                      value={dto.releaseRetention}
                      name="releaseRetention"
                      onBlur={(e) => {
                        const { name, value } = e.target;
                        setDTO({...dto, [name]: Number(value)});
                        doTotal(e);
                      }}
                      disabled={id > 0 && !isEdit}
                      style={{ width: "100px" }}
                    ></input>
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: ANTI_FLASH_WHITE,
                    height: "40px",
                  }}
                >
                  <td style={{ width: "600px" }}>
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {RELEASE_TESTING_AND_COMMISION}
                    </Typography>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <input
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setDTO({...dto, [name]: value});
                      }}
                      onWheel={(event) => event.target.blur()}
                      value={dto.releaseCommission}
                      name="releaseCommission"
                      onBlur={(e) => {
                        const { name, value } = e.target;
                        setDTO({...dto, [name]: Number(value)});
                        doTotal(e);
                      }}
                      disabled={id > 0 && !isEdit}
                      style={{ width: "100px" }}
                    ></input>
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: ANTI_FLASH_WHITE,
                    height: "40px",
                  }}
                >
                  <td style={{ width: "600px" }}>
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {TOTAL_PRESENT_BILL_AMOUNT}
                    </Typography>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.totalPresentBillAmount}
                    </Typography>
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: ANTI_FLASH_WHITE,
                    height: "40px",
                  }}
                >
                  <td style={{ width: "600px" }}>
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {PREVIOUS_BILL_AMOUNT_PAID}
                    </Typography>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.previousBillAmountPaid}
                    </Typography>
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: ANTI_FLASH_WHITE,
                    height: "40px",
                  }}
                >
                  <td style={{ width: "600px" }}>
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {LESS_AMOUNT_PAID_TILL_DATE}
                    </Typography>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.lessAmountPaidTillDate}
                    </Typography>
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: ANTI_FLASH_WHITE,
                    height: "40px",
                  }}
                >
                  <td style={{ width: "600px" }}>
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {LESS_ADVANCE_DEDUCTIBLE}
                    </Typography>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.lessAdvanceDeductible}
                    </Typography>
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: ANTI_FLASH_WHITE,
                    height: "40px",
                  }}
                >
                  <td style={{ width: "600px" }}>
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {TOTAL_AMOUNT_PAID}
                    </Typography>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.totalAmountPaid}
                    </Typography>
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: ANTI_FLASH_WHITE,
                    height: "40px",
                  }}
                >
                  <td style={{ width: "600px" }}>
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {GRAND_TOTAL}
                    </Typography>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.grandTotal}
                    </Typography>
                  </td>
                </tr>
                <tr
                  style={{
                    backgroundColor: ANTI_FLASH_WHITE,
                    height: "40px",
                  }}
                >
                  <td style={{ width: "600px" }}>
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {GRAND_TOTAL_AMOUNT_ACTUAL_PAID}
                    </Typography>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <input
                      onChange={(e) => {
                        const { name, value } = e.target;
                        setDTO({...dto, [name]: value});
                      }}
                      onWheel={(event) => event.target.blur()}
                      value={dto.amountActualPaid}
                      name="amountActualPaid"
                      onBlur={handleInputChange}
                      disabled={id > 0 && !isEdit}
                      style={{ width: "100px" }}
                    ></input>
                  </td>
                </tr>
                <tr style={{ height: "30px" }}></tr>
                <tr
                  style={{
                    height: "40px",
                  }}
                >
                  <td style={{ width: "600px" }}>
                    <Typography
                      color={PRUSSIAN_BLUE}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {RETENTION_AND_TESTING_AMOUNT_SUMMARY}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {TOTAL_DEDUCTED}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {TOTAL_RELEASED}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {BALANCED_AMOUNT}
                    </Typography>
                  </td>
                </tr>
                <tr
                  style={{
                    height: "40px",
                  }}
                >
                  <td style={{ width: "600px" }}>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {RETENTION}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.amountRetention}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.releaseRetention}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.retentionBalanceAmount}
                    </Typography>
                  </td>
                </tr>
                <tr
                  style={{
                    height: "40px",
                  }}
                >
                  <td style={{ width: "600px" }}>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {TESTING_AND_COMMISION}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.amountCommission}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.releaseCommission}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.commissionBalanceAmount}
                    </Typography>
                  </td>
                </tr>
                <tr
                  style={{
                    height: "40px",
                  }}
                >
                  <td style={{ width: "600px" }}>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "14px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                      }}
                    >
                      {TOTAL}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.totalDeducted}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.releaseTotal}
                    </Typography>
                  </td>
                  <td>
                    <Typography
                      color={CHARLESTON_GREEN}
                      style={{
                        marginTop: 10,
                        flexGrow: 0,
                        fontFamily: "sans-serif",
                        fontSize: "16px",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.32,
                        letterSpacing: "normal",
                        //marginRight: 10,
                        marginLeft: 5,
                      }}
                    >
                      ₹ {dto.balanceTotal}
                    </Typography>
                  </td>
                </tr>
              </TableBody>
            </table>
          </div>
        </div>
      ) : (
        ""
      )}
    </SideNav>
  );
}